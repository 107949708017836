const languages = [
  { name: 'Arabic', key: 'arabic', code: 'ae' },
  { name: 'Bahasa', key: 'bahasa', code: 'id' },
  { name: 'Bengali', key: 'bengali', code: 'bd' },
  { name: 'Burmese', key: 'burmese', code: 'mm' },
  { name: 'English', key: 'english', code: 'en-us' },
  { name: 'Finnish', key: 'finnish', code: 'fi' },
  { name: 'French', key: 'french', code: 'fr' },
  { name: 'German', key: 'german', code: 'de' },
  { name: 'Greek', key: 'greek', code: 'gr' },
  { name: 'Hebrew', key: 'hebrew', code: 'il' },
  { name: 'Hindi', key: 'hindi', code: 'in' },
  { name: 'Italy', key: 'italy', code: 'it' },
  { name: 'Japanese', key: 'japanese', code: 'jp' },
  { name: 'Korean', key: 'korean', code: 'kor' },
  { name: 'Malay', key: 'malay', code: 'ms' },
  { name: 'Mandarin', key: 'mandarin', code: 'cn' },
  { name: 'Mongolian', key: 'mongolia', code: 'mn' },
  { name: 'Portuguese', key: 'portuguese', code: 'pt' },
  { name: 'Russian', key: 'russian', code: 'ru' },
  { name: 'Serbian', key: 'serbia', code: 'rs' },
  { name: 'Spanish EU', key: 'spanishEU', code: 'es' },
  { name: 'Spanish US', key: 'spanishUS', code: 'es-us' },
  { name: 'Swahili', key: 'swahili', code: 'af' },
  { name: 'Thai', key: 'thai', code: 'th' },
  { name: 'Turkish', key: 'turkish', code: 'tr' },
  { name: 'Traditional Chinese', key: 'traditional_chinese', code: 'tw' },
  { name: 'Vietnamese', key: 'vietnamese', code: 'vn' }
]

export default languages
