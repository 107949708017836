import React, { Component } from 'react'
import { Container } from './styled'
import { Spin } from 'antd'
import { withTranslation } from 'react-i18next'

class Loading extends Component {
  render () {
    const { isFetching, t } = this.props

    return (
      <Container active={isFetching}>
        <Spin size='large' tip={t('general:loading')}/>
      </Container>
    )
  }
}

export default withTranslation()(Loading)
