import React from 'react'
import ScenarioConfirmationImage from '../../img/scenario-paper.svg'
import { Button, Modal } from 'antd'

import './styles.scss'

function ScenarioConfirmationModal({ onEdit, onCreate, onCancel }) {
  const handleClick = type => {
    const handler = type === 'edit' ? onEdit : onCreate
    if (handler) handler()
  }

  return (
    <Modal visible footer={null} closable centered onCancel={onCancel}>
      <div className="scenario-confirmation">
        <img src={ScenarioConfirmationImage} alt="" />

        <p>
          Do you want to edit the existing scenario or to create a new
          scenario?. If you want to create a new scenario, you will loose the
          old scenario."
        </p>

        <div className="btn-group">
          <Button
            type="primary"
            shape="round"
            onClick={() => handleClick('edit')}
          >
            Edit
          </Button>
          <Button
            type="green"
            shape="round"
            onClick={() => handleClick('create')}
          >
            Create
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default ScenarioConfirmationModal
