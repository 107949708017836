import React from 'react'
import { Row, Col, Checkbox, Divider } from 'antd'
import './AddAudioLanguage.scss'
import AiLanguageSelect from '../../../../../components/AiLanguageSelect'
import { useTranslation } from 'react-i18next'

const AddAudioLanguage = ({
  isAiEnabled,
  audioUrl,
  handleAiLanguage,
  changeIsAiEnabled
}) => {
  const { t } = useTranslation()
  return (
    <>
      <div className="podcast-section_title">
        {t('v3:podcastLanguageTitle')}
      </div>
      <Row gutter={[8, 8]} align="middle" className="podcast-section">
        <Divider style={{ marginBottom: 0 }} />
        <Col xs={24} sm={24} md={24} lg={14}>
          <div className="podcast-section_ai">
            <div>
              <Checkbox
                style={{ borderRadius: '50%' }}
                onChange={e => {
                  e.preventDefault()
                  changeIsAiEnabled(e.target.value)
                }}
                checked={isAiEnabled || audioUrl.is_ai_enabled}
              >
                {t('v3:podcastLanguageCheckbox')}
              </Checkbox>
            </div>
            <div>
              &nbsp;
              <AiLanguageSelect handleAiLanguage={handleAiLanguage} />
            </div>
          </div>
        </Col>
        <Divider style={{ marginTop: 0 }} />
      </Row>
    </>
  )
}

export default AddAudioLanguage
