import React, { useState } from 'react'
import { Icon, Avatar, Tooltip } from 'antd'
import { CustomCol } from '../styled'
import { userImage } from '../images'

const style = {
  color: '#0068FF',
  marginTop: ' 10px'
}

const ParticipantSlider = ({ participantSize, participants }) => {
  const [calcNextSize, setCalcSize] = useState(0)

  const clickPrev = () => {
    if (calcNextSize > 0) {
      setCalcSize(calcNextSize - 550)
    }
  }

  const clickNext = () => {
    if (participantSize > calcNextSize + 550) setCalcSize(calcNextSize + 550)
  }

  return (
    <CustomCol lg={20} size={participantSize} next={calcNextSize}>
      <div id="enroll-wrapper">
        {participants.length > 8 && (
          <Icon
            type="left"
            style={style}
            onClick={() => {
              clickPrev()
            }}
          />
        )}
        <div className="inner-wrapper">
          <div className="overflow-wrapper scrolling">
            {participants.map(i => {
              const imgSrc = i.avatar
                ? i.avatar.link
                  ? i.avatar.link
                  : i.avatar
                : userImage
              return (
                <Tooltip
                  placement="topLeft"
                  title={i.username}
                  key={`participant_${i.id}`}
                >
                  <Avatar
                    style={{
                      marginRight: '18px',
                      marginLeft: '18px'
                    }}
                    key={i.id}
                    src={`${imgSrc}`}
                    alt={i.username}
                    className="avatar"
                  />
                </Tooltip>
              )
            })}
          </div>
        </div>
        {participants.length > 8 && (
          <Icon
            type="right"
            style={style}
            onClick={() => {
              clickNext()
            }}
          />
        )}
      </div>
    </CustomCol>
  )
}

export default ParticipantSlider
