import { connect } from 'react-redux'

import actions from '../../../../../../store/actions'

import ModalAddGroups from './ModalAddGroups'

export default connect(
  state => ({}),
  dispatch => ({
    upload: (file, opts) => dispatch(actions.library.upload(file, opts))
  })
)(ModalAddGroups)
