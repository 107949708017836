import NotesForm from './Form'
import actions from '../../../store/actions'
import { connect } from 'react-redux'

const mapStateToProps = state => ({
  user: state.user,
  isLoading: state.notes.isLoading
})

const mapDispatchToProps = dispatch => ({
  postNote: (id, type, parentId, body) => actions.notes.addNote({
    id,
    type,
    parentId,
    body
  })(dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotesForm)
