import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Content } from '../../styled'
import { Form, Button, Input, Spin, Icon } from 'antd'
import { withTranslation } from 'react-i18next'
import { api } from '../../../../services'
import classnames from 'classnames'

class ContactUs extends Component {
  constructor (props) {
    super(props)
    this.state = {
      subject: '',
      content: '',
      isSent: false,
      isSending: false,
      error: false,
      showRequired: []
    }
  }

  handleUpdate = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  handleCancel = () => {
    this.setState({
      subject: '',
      content: ''
    })
  }

  handleSubmit = () => {
    const data = {
      topic: this.state.subject,
      message: this.state.content
    }
    this.setState({
      isSending: true
    })
    api.help.contact(data)
      .then(() =>
        this.setState({
          isSent: true,
          subject: '',
          content: '',
          isSending: false
        }))
      .then(() => {
        setTimeout(() => {
          this.setState({
            isSent: false
          })
        }, 3000)
      })
      .catch((error) => {
        this.setState({
          error: error,
          subject: '',
          content: ''
        })
      })
  }

  handleBlurValidation = (event) => {
    if (!this.state.showRequired.includes('event.target.name]')) {
      this.setState({
        showRequired: [...this.state.showRequired, event.target.name]
      })
    }
  }

  render () {
    const {
      t
    } = this.props
    const {
      isSent,
      subject,
      isSending,
      content
    } = this.state
    return (
      <Content>
        <Content.Heading>{t('contact_us.title')}</Content.Heading>
        <Content.Form>
          {isSent ? <p><Icon type='check' />{t('contact_us.sent')}</p> : (
            <>
              <Form.Item>
                {
                  this.state.showRequired.includes('subject') && subject.length <= 0 && (
                    <span className='required-warning-text'>{t('contact_us.subject_required')}</span>                  )
                }
                <Input
                  type='text'
                  placeholder={t('contact_us.subject_placeholder')}
                  size='large'
                  name='subject'
                  value={subject}
                  onChange={(event) => this.handleUpdate(event)}
                  onBlur={(event) => this.handleBlurValidation(event)}
                  disabled={isSending}
                  className={classnames({ 'required-warning': this.state.showRequired.includes('subject') && subject.length <= 0 })}
                />
                {
                  this.state.showRequired.includes('content') && content.length <= 0 && (
                    <span className='required-warning-text'>{t('contact_us.content_required')}</span>                  )
                }
                <Input.TextArea
                  rows={4}
                  name='content'
                  value={content}
                  placeholder={t('contact_us.content_placeholder')}
                  style={{ resize: 'none' }}
                  onChange={(event) => this.handleUpdate(event)}
                  onBlur={(event) => this.handleBlurValidation(event)}
                  disabled={isSending}
                  className={classnames({ 'required-warning': this.state.showRequired.includes('content') && content.length <= 0 })}
                />
              </Form.Item>
              <div className='form-footer'>
                <Button
                  type='default'
                  size='default'
                  className='ant-btn'
                  onClick={this.handleCancel}
                  disabled={isSending || !subject || !content}
                >
                  {t('contact_us.cancel')}
                </Button>
                <Button
                  type='primary'
                  size='default'
                  className='ant-btn ant-btn-primary'
                  onClick={this.handleSubmit}
                  disabled={isSending || !subject || !content}
                >
                  {
                    isSending ? (<Spin spinning={isSending}>{t('contact_us.submit')}</Spin>) : t('contact_us.submit')
                  }
                </Button>
              </div>
            </>
          )}
        </Content.Form>
      </Content>
    )
  }
}

export default connect()(withTranslation('help_center')(ContactUs))
