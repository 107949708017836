export default [
  {
    title: 'Asia',
    list: {
      asia_pacific_australia: 'Australia',
      asia_pacific_india: 'India',
      asia_pacific_japan: 'Japan',
      asia_pacific_s_korea: 'South Korea',
      asia_pacific_singapore: 'Singapore',
      asia_pacific_taiwan: 'Taiwan'
    }
  },
  {
    title: 'Europe',
    list: {
      eu_belgium: 'Belgium',
      eu_germany: 'Germany',
      eu_ireland: 'Ireland'
    }
  },
  {
    title: 'North America',
    list: {
      us_east_s_carolina: 'South Carolina',
      us_central_iowa: 'Iowa',
      us_east_virginia: 'Virginia',
      us_west_california: 'California',
      us_west_oregon: 'Oregon'
    }
  },
  {
    title: 'South America',
    list: {
      south_america_brazil: 'Brazil'
    }
  }
]
