import React from 'react'
import { Button } from 'antd'

import { Container, Pages } from '../../../../../styled'
import { Builder } from '../../../../../../../../components'

const PageViewOption = ({
  pages,
  toPage,
  upload,
  handleData,
  currentPage,
  handleParams,
  pageStructure,
  onChangeLandscape,
  handleMultipleDrop
}) => {
  return (
    <Container>
      <span style={{ marginLeft: '64em' }}>
        <Button
          type="dashed"
          shape="round"
          size="default"
          style={{ bottom: '24px' }}
          className="ant-btn rounded"
          onClick={onChangeLandscape}
        >
          View as Landscape
        </Button>
      </span>
      <Pages>
        <Pages.List>
          {currentPage > 1 ? (
            <Pages.Item>
              <div className="wrap_page">
                <div
                  className="page page_previous"
                  onClick={() => toPage('previous')}
                />
              </div>
            </Pages.Item>
          ) : (
            <Pages.Item>
              <div className="wrap_page">
                <div className="page page_hidden" />
              </div>
            </Pages.Item>
          )}

          <Pages.Item>
            <Builder.Page
              upload={upload}
              handleData={handleData}
              handleParams={handleParams}
              pageStructure={pageStructure}
              handleMultipleDrop={handleMultipleDrop}
            />
          </Pages.Item>
          {pages.length === currentPage ? (
            <Pages.Item>
              <div className="wrap_page">
                <div className="page page_new" onClick={() => toPage('new')} />
              </div>
            </Pages.Item>
          ) : (
            <Pages.Item>
              <div className="wrap_page">
                <div
                  className="page page_next"
                  onClick={() => toPage('next')}
                />
              </div>
            </Pages.Item>
          )}
        </Pages.List>
      </Pages>
    </Container>
  )
}

export default PageViewOption
