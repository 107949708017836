export default {
  /* USER REPORT */

  PAGE_NUMBER: 1,
  PAGE_SIZE: 10,
  LIVE_ASSESSMENTS: 'Live Assessments',
  COACHING_SESSION: 'Coaching Session',
  PUBLIC_CONFERENCE: 'Public Conference',
  RECENT_ACTIVITY: 'Recent Activity',
  AGO: 'ago',
  WEBINAR: 'Webinar',
  WORKSHOPS: 'Workshops',
  PARTICIPANTS: 'Participants',
  COURSES: 'Courses',
  LIVE_EVENTS: 'Live Events',
  QUIZZES: 'Quizzes',
  OVERVIEW: 'Overview',
  COURSE: 'Course',
  EVENT: 'Event',
  SKILL_JOURNEY: 'Skill Journey',
  BY: 'by',
  STATUS: 'Status',
  COURSE_SCREEN: 'Course screen',
  POLL: 'Poll',
  COMMENT: 'Comment',
  OVERALL_EXPERIENCE: 'Overall experience',
  LIVE_DATE: 'Live Date',
  MAZE: 'Maze', // to translate
  NO_COMMENTS: 'No Comments',
  NO_POLLS: 'No Polls',
  SCORE: 'Score',
  QUESTION: 'Question',
  SUBMIT: 'Submit',
  ANSWER_NOT_AVAILABLE: 'Answer not available',
  HOST: 'Host',
  TAB3: '3',
  TAB2: '2',
  VIEWS: 'Views',
  USER_TYPE_HOST: 'host',
  USER_TYPE_PARTICIPANT: 'participant',
  USER_LIST: 'userLists',
  HOST_USER: 'hostUser',
  PARTICIPANT_USER: 'participantUser',
  LIVE_EVENT: 'LiveEvent',
  LIVE_ASSESSMENT: 'LiveAssessment',
  ASSESSMENT: 'Assessment',
  QUIZ: 'Quiz',
  NO_DATA_FOUND: 'No data found',
  USERS: 'Users',
  CHANNELS: 'Channels',
  TRIVIA: 'Trivia'
}
