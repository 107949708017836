import React, { Component, Fragment } from 'react'
import { Icon, Tabs } from 'antd'
import moment from 'moment/moment'

import {
  CustomTabs,
  // CustomCard,
  CustomDownloadButton
} from '../../styled.js'
import { withTranslation } from 'react-i18next'
import { Quiz, Analytic } from './internal'
import { generateCSV } from '../../../../utils'
import './style.css'
import { isSafari } from 'react-device-detect'
import DownloadModal from '../../components/DownloadModal'
const { TabPane } = Tabs

class Details extends Component {
  state = {
    startValue: moment(new Date(), 'YYYY-MM-DD').subtract(1, 'month'),
    endValue: moment(new Date(), 'YYYY-MM-DD'),
    downloadType: 0,
    activeTab: 2,
    showOption: [{ key: 0, name: 'Completed' }],
    isQuizDetail: false
  }

  showDrawer = () => {
    const { handlingData } = this.props

    handlingData('visible', true)
  }

  onTabChange = tabKey => {
    const key = parseInt(tabKey)
    this.setState(() => {
      if (key === 2) {
        return {
          activeTab: key,
          showOption: [{ key: 0, name: 'Completed' }],
          startValue: moment(new Date(), 'YYYY-MM-DD').subtract(1, 'month'),
          endValue: moment(new Date(), 'YYYY-MM-DD')
        }
      }
      if (key === 3) {
        return {
          activeTab: key,
          showOption: [{ key: 0, name: 'Quiz list' }],
          startValue: moment(new Date(), 'YYYY-MM-DD').subtract(1, 'month'),
          endValue: moment(new Date(), 'YYYY-MM-DD')
        }
      }
      return { isQuizDetail: false }
    })
  }

  switchQuizStatus = () => {
    this.setState({ isQuizDetail: true })
  }
  // componentDidMount() {
  //   const {
  //     match: { params }
  //   } = this.props
  //   this.props.getLessonReport(params.lesson_id, {
  //     type: 'Analytics',
  //     course_id: params.id
  //   })
  // }

  onClose = () => {
    const { handlingData } = this.props
    handlingData('visible', false)
  }

  onChange = (field, value) => {
    const { handlingData } = this.props
    this.setState({ [field]: value }, () => {
      handlingData([field], value)
    })
  }
  onStartChange = value => {
    this.onChange('startValue', value)
  }

  onEndChange = value => {
    this.onChange('endValue', value)
  }
  changeDownloadType = value => {
    this.onChange('downloadType', value)
  }

  download = () => {
    const { activeTab } = this.state
    if (activeTab === 2) {
      this.downloadAnalytics()
    }
    if (activeTab === 3) {
      this.downloadQuiz()
    }
  }

  downloadQuizDetail = () => {
    const {
      data: { questions }
    } = this.props.data.overAll
    let dataCSV = []
    let header = []
    let answerHeaders = []
    let answers = {}
    dataCSV = questions
      .map(x => {
        // if( moment(x.date_time).isBetween(this.state.startValue,this.state.endValue.add(1,'days')))
        if (x.quiz_answer.length > 0) {
          answerHeaders = x.quiz_answer.map((item, index) => {
            return `Option ` + (index + 1)
          })
          answers = Object.fromEntries(
            x.quiz_answer.map((e, index) => [`option${index + 1}`, e.answer])
          )
        }
        return {
          id: x.uid,
          question: x.quiz_question,
          point: x.point,
          type: x.type,
          ...answers,
          answer: x.quiz_answer
            .map(ans => {
              if (ans.correct) {
                return ans.answer
              }
              return null
            })
            .join('')
        }
      })
      .filter(x => {
        return x !== null
      })
    header = [
      '#',
      'Question',
      'Point',
      'Type',
      ...answerHeaders,
      'Correct Answer'
    ]

    if (!isSafari) {
      let ele = document.createElement('a')
      ele.setAttribute(
        'href',
        'data:text/csv;charset=utf-8,' +
          encodeURIComponent(generateCSV(dataCSV, header))
      )
      ele.setAttribute('download', 'lesson-quiz-detail-report.csv')
      document.body.appendChild(ele)
      ele.click()
      // generateCSV(dataCSV, header)
    } else {
      let ele = document.createElement('a')
      ele.setAttribute(
        'href',
        'data:text/csv;charset=utf-8,' +
          encodeURIComponent(generateCSV(dataCSV, header))
      )
      ele.setAttribute('download', 'lesson-quiz-detail-report.csv')
      ele.click()
    }
  }

  downloadQuiz = () => {
    const {
      data: { quizzes = [] }
    } = this.props.data.Quiz
    let dataCSV = []
    let header = []
    if (this.state.downloadType === 0) {
      dataCSV = quizzes
        .map(x => {
          // if( moment(x.date_time).isBetween(this.state.startValue,this.state.endValue.add(1,'days')))
          return {
            id: x.id,
            title: x.title,
            status: x.status,
            question: x.questions_count,
            submit: x.submit
          }
        })
        .filter(x => {
          return x !== null
        })
      header = ['#', 'Title', 'Status', 'Question', 'Submit']
    }
    if (!isSafari) {
      let ele = document.createElement('a')
      ele.setAttribute(
        'href',
        'data:text/csv;charset=utf-8,' +
          encodeURIComponent(generateCSV(dataCSV, header))
      )
      ele.setAttribute('download', 'lesson-quiz-report.csv')
      document.body.appendChild(ele)
      ele.click()
      // generateCSV(dataCSV, header)
    } else {
      let ele = document.createElement('a')
      ele.setAttribute(
        'href',
        'data:text/csv;charset=utf-8,' +
          encodeURIComponent(generateCSV(dataCSV, header))
      )
      ele.setAttribute('download', 'lesson-quiz-report.csv')
      ele.click()
    }
  }

  downloadAnalytics = () => {
    const {
      data: { completed_users = [] }
    } = this.props.data.Analytics
    let dataCSV = []
    let header = []
    if (this.state.downloadType === 0) {
      dataCSV = completed_users
        .filter(x =>
          moment(x.date_time).isBetween(
            this.state.startValue,
            this.state.endValue.add(1, 'days')
          )
        )
        .map(x => {
          return {
            id: x.id,
            username: x.username,
            date: moment(x.date_time).format('YYYY-MM-DD')
          }
        })
        .filter(x => {
          return x !== null
        })
      header = ['#', 'User Name', 'Date']
    }
    if (!isSafari) {
      let ele = document.createElement('a')
      ele.setAttribute(
        'href',
        'data:text/csv;charset=utf-8,' +
          encodeURIComponent(generateCSV(dataCSV, header))
      )
      ele.setAttribute('download', 'lesson-analytics-report.csv')
      document.body.appendChild(ele)
      ele.click()
      // generateCSV(dataCSV, header)
    } else {
      let ele = document.createElement('a')
      ele.setAttribute(
        'href',
        'data:text/csv;charset=utf-8,' +
          encodeURIComponent(generateCSV(dataCSV, header))
      )
      ele.setAttribute('download', 'lesson-analytics-report.csv')
      ele.click()
    }
  }

  render() {
    const {
      match: { params },
      data: { visible },
      t
    } = this.props

    console.log('this.props detail lesson:', this.props.data)

    const { startValue, endValue, showOption, isQuizDetail } = this.state
    const {
      data: { cover = [] }
    } = this.props.data.Analytics

    return (
      <Fragment>
        <div className="content-warp">
          <div className="report-warp" style={{ direction: 'ltr' }}>
            <CustomTabs
              defaultActiveKey="2"
              tabBarGutter={10}
              tabBarExtraContent={
                <CustomDownloadButton>
                  <button
                    className="download-report button"
                    onClick={
                      !isQuizDetail ? this.showDrawer : this.downloadQuizDetail
                    }
                  >
                    {t('reports:report_download')}
                  </button>
                </CustomDownloadButton>
              }
              onTabClick={key => {
                if (key === '0') {
                  this.props.history.push(`/reports/course/` + params.id)
                } else {
                  this.onTabChange(key)
                }
              }}
            >
              <TabPane
                tab={<Icon type="left" style={{ fontSize: 18 }} />}
                key="0"
              />
              <TabPane
                disabled
                tab={
                  <img
                    style={{ height: 50, width: 70, borderRadius: '11px' }}
                    src={cover.link}
                    alt=""
                  />
                }
                key="1"
              />
              <TabPane tab={t('reports:analytic')} key="2">
                <Analytic lesson={params.lesson_id} course={params.id} />
              </TabPane>
              <TabPane tab={t('reports:quiz_list')} key="3">
                <Quiz
                  lesson={params.lesson_id}
                  course={params.id}
                  switchQuizStatus={this.switchQuizStatus}
                />
              </TabPane>
            </CustomTabs>
          </div>
        </div>
        <DownloadModal
          title={t('reports:report')}
          handleClose={this.onClose}
          visible={visible}
          startDate={startValue}
          endDate={endValue}
          handleStartChange={this.onStartChange}
          handleEndChange={this.onEndChange}
          handleChangeDownloadType={this.changeDownloadType}
          options={showOption}
          handleDownload={this.download}
          defaultValue={t('reports:select_one')}
        />
      </Fragment>
    )
  }
}
export default withTranslation('reports')(Details)
