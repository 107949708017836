import AddMediaDetail from './AddMediaDetail'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import actions from '../../../../store/actions'

const mapStateToProps = state => ({
  user: state.user,
  library: state.library
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateTopicList: actions.topics.updateTopicList
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(AddMediaDetail)
