import React from 'react'
import { Col, Icon, Form, Input, Select, TreeSelect } from 'antd'
import CreatableSelect from 'react-select/creatable'
import './index.css'
import { CustomSwitch } from './CustomSwitch'
import { api } from '../../../../../../services'
import TrainerSection from '../../../../../../components/TrainerSection'

const { Option } = Select
const { TreeNode } = TreeSelect

class MarketPlaceMidSection extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isCourseFree: false,
      orgs: [],
      groups: [],
      categories: [],
      isLoading: false,
      value: '',
      isValueLoading: false
    }
  }

  componentDidMount() {
    this.fetchOrganization()
    this.fetchCategories()
    this.handleSwitch()
    this.handleGroupSelect()
  }

  fetchOrganization = () => {
    api.organizations.get().then(res => {
      this.setState({
        orgs: res.data.rows.map(item => ({
          title: item.title,
          orgId: item.id,
          groups: item.groups
        }))
      })
      this.fetchGroups()
    })
  }

  fetchGroups = () => {
    const {
      courseBuilder: { courseOrganization, courseGroup }
    } = this.props

    if (courseOrganization) {
      this.setState({
        groups: this.state.orgs
          .filter(item => courseOrganization === item.orgId)
          .map(item => item.groups)[0]
          .map(item => ({
            id: item.id,
            value: item.title,
            label: item.title
          }))
      })
    }

    if (courseGroup) {
      this.setState({
        value: this.state.groups.filter(group => group.id === courseGroup)[0]
      })
    }

    if (this.state.selectedGroup) {
      this.setState({
        value: this.state.groups.filter(
          group => group.id === this.state.selectedGroup
        )[0],
        isValueLoading: false
      })
    }
  }

  fetchCategories = () => {
    api.courses
      .fetchCategory()
      .then(res =>
        this.setState({
          categories: res.data
        })
      )
      .catch(err => {
        console.log('Error in fetching categories')
      })
  }
  handleOrganizationSelect = id => {
    const { onOrganizationSelect } = this.props
    this.setState({
      groups: this.state.orgs
        .filter(item => id === item.orgId)
        .map(item => item.groups)[0]
        .map(item => ({
          id: item.id,
          value: item.title,
          label: item.title
        }))
    })
    onOrganizationSelect(id)
  }

  LoadingIndicator = props => (
    <Icon style={{ paddingRight: '10px' }} type="loading" />
  )

  handleGroupSelect = newValue => {
    const {
      onGroupSelect,
      courseBuilder: { courseGroup }
    } = this.props

    if (!newValue && courseGroup) {
      onGroupSelect(courseGroup)
      return
    }
    if (!newValue) return
    onGroupSelect(newValue.id)
    this.setState({
      value: newValue
    })
  }

  handleCreate = val => {
    const {
      createGroup,
      courseBuilder: { courseOrganization },
      onGroupSelect
    } = this.props

    this.setState({ isValueLoading: true })

    createGroup(courseOrganization, { title: val }).then(async res => {
      this.setState({ selectedGroup: res.id })
      onGroupSelect(this.state.selectedGroup)
      await this.fetchOrganization()
    })
  }

  handleSwitch = () => {
    const { onSwitch, courseIsFree } = this.props
    if (courseIsFree === null) {
      onSwitch(false)
      return
    }
    this.setState({ isCourseFree: !this.state.isCourseFree })
    onSwitch(this.state.isCourseFree)
  }

  handleCategorySelect = id => {
    const { onCategory } = this.props
    onCategory(id)
  }

  render() {
    const {
      t,
      form: { getFieldDecorator },
      onPrice,
      onVideoLink,
      onNoOfVideo,
      onNoOfLessons,
      courseBuilder: {
        courseCategory,
        courseOrganization,
        coursePrice,
        courseVideoLink,
        courseIsFree,
        courseNoOfVideos,
        courseNoOfLessons
      }
    } = this.props
    const { orgs, groups, categories, value, isValueLoading } = this.state

    const customStyles = {
      control: (base, state) => ({
        ...base,
        borderRadius: 0,
        borderColor: value ? '#d9d9d9' : 'red'
      }),
      menu: base => ({
        ...base,
        borderRadius: 0,
        marginTop: 0,
        textAlign: 'left'
      }),
      menuList: base => ({
        ...base,
        padding: 0
      })
    }

    return (
      <>
        <TrainerSection />
        <div className="mp-top_section_row">
          <Col md={11}>
            <Form.Item
              colon={false}
              required={false}
              label={t('labels:category')}
            >
              {getFieldDecorator('category_id', {
                rules: [{ required: true, message: t('v4:select_category') }],
                initialValue: courseCategory
              })(
                <TreeSelect
                  size="large"
                  style={{ width: '100%' }}
                  dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                  placeholder="Please select"
                  allowClear
                  onChange={this.handleCategorySelect}
                >
                  {categories &&
                    categories.map(category => (
                      <TreeNode
                        value={category.id}
                        title={category.name}
                        key={category.id}
                      >
                        {category.children &&
                          category.children.map(option => (
                            <TreeNode
                              value={option.id}
                              title={option.name}
                              key={option.id}
                            />
                          ))}
                      </TreeNode>
                    ))}
                </TreeSelect>
              )}
            </Form.Item>
          </Col>
          <Col md={11}>
            <Form.Item
              colon={false}
              required={false}
              label={t('v4:promotional_video_link')}
            >
              {getFieldDecorator('video_link', {
                rules: [{ required: true, message: t('v4:input_video_link') }],
                initialValue: courseVideoLink
              })(
                <Input
                  size="large"
                  maxLength={100}
                  onChange={e => onVideoLink(e.target.value)}
                />
              )}
            </Form.Item>
          </Col>
        </div>
        <div className="mp-top_section_row">
          <Col md={11}>
            <div className="div_row">
              <Form.Item colon={false} required={false}>
                {getFieldDecorator('is_free', {
                  initialValue: courseIsFree || false
                })(
                  <CustomSwitch
                    label={this.label}
                    onChanged={e => this.handleSwitch(e)}
                  />
                )}
              </Form.Item>
              <Form.Item
                colon={false}
                required={false}
                className="price_style"
                label={t('labels:price (usd)')}
              >
                {getFieldDecorator('price', {
                  initialValue: coursePrice,
                  rules: [
                    {
                      validator: (rule, value, cb) => value >= 5,
                      message: t('v4:enter_price')
                    }
                  ]
                })(
                  <Input
                    size="large"
                    type="number"
                    min={5}
                    maxLength={100}
                    onChange={e => onPrice(e.target.value)}
                    disabled={courseIsFree === null ? false : courseIsFree}
                  />
                )}
              </Form.Item>
            </div>
          </Col>
          <Col md={11}>
            <Form.Item
              colon={false}
              required={false}
              label={t('labels:number of video')}
            >
              {getFieldDecorator('no_of_video', {
                rules: [{ required: true, message: t('v4:error_video') }],
                initialValue: courseNoOfVideos
              })(
                <Input
                  size="large"
                  type="number"
                  min={0}
                  maxLength={100}
                  onChange={e => onNoOfVideo(e.target.value)}
                />
              )}
            </Form.Item>
          </Col>
        </div>
        <div className="mp-top_section_row">
          <Col md={11}>
            <Form.Item
              colon={false}
              required={false}
              label={t('labels:organization (for paid course/webinar)')}
            >
              {getFieldDecorator('organization_id', {
                initialValue: courseOrganization,
                rules: [{ required: true, message: t('v4:organization') }]
              })(
                <Select
                  size="large"
                  placeholder="Select an organization"
                  onChange={this.handleOrganizationSelect}
                >
                  {orgs &&
                    orgs.map(org => (
                      <Option key={org.orgId} value={org.orgId}>
                        {org.title}
                      </Option>
                    ))}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col md={11}>
            <Form.Item
              colon={false}
              required={false}
              label={t('labels:number of lessons')}
            >
              {getFieldDecorator('no_of_lesson', {
                rules: [{ required: true, message: t('v4:lessons') }],
                initialValue: courseNoOfLessons
              })(
                <Input
                  min={0}
                  size="large"
                  type="number"
                  maxLength={100}
                  onChange={e => onNoOfLessons(e.target.value)}
                />
              )}
            </Form.Item>
          </Col>
        </div>
        <div className="mp-top_section_row">
          <Col md={11}>
            <div style={{ marginBottom: '4px', fontSize: '1.2rem' }}>
              {t('labels:group (for paid course/webinar)').toUpperCase()}
            </div>
            <CreatableSelect
              styles={customStyles}
              placeholder="Select or create a group"
              components={{
                ClearIndicator: null,
                LoadingIndicator: this.LoadingIndicator
              }}
              isLoading={isValueLoading}
              onChange={this.handleGroupSelect}
              onCreateOption={this.handleCreate}
              options={groups}
              value={value}
              isDisabled={isValueLoading}
            />
          </Col>
        </div>
      </>
    )
  }
}

export { MarketPlaceMidSection }
