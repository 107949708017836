import { Typography } from 'antd'
import React, { useState } from 'react'

import './switch.scss'
const { Text } = Typography

const Switch = ({ label, onChanged, value = false }) => {
  const [isOn, setIsOn] = useState(value)
  const toggleSwitch = () => {
    setIsOn(prev => !prev)
    onChanged(!isOn)
  }
  return (
    <div className="margin-bottom">
      {label && (
        <div className="margin-bottom line-height">
          <Text className="switch-label">{label.toUpperCase()}</Text>
        </div>
      )}
      <div
        onClick={toggleSwitch}
        className={isOn ? 'switch-bg_active' : 'switch-bg_inactive'}
      >
        <div className="switch-knob" />
      </div>
    </div>
  )
}

export default Switch
