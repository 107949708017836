/*
* @Description: In User Settings Edit
* @Author: your name
* @Date: 2019-08-09 16:41:54
* @LastEditTime: 2019-12-19 10:53:49
* @LastEditTime: 2019-09-09 11:43:43
* @LastEditors: Please set LastEditors
*/

export default {
  general: {
    save: 'guardar',
    autosaved: 'auto guardado',
    back_to: 'regresar a',
    by: 'por',
    chat: 'chat',
    completed: 'completado',
    canceled: 'Cancelado',
    component: 'Componente',
    confirm: 'Confirmado',
    course: 'Curso',
    date: 'Fecha',
    details: 'Detalles',
    empty: 'No hay datos',
    enrolled: 'Inscrito',
    free: 'Gratis',
    graph: 'Grafica',
    loading: 'Cargando...',
    lesson: 'lección',
    lessons: 'lecciones',
    live_assessment: 'Evaluación en vivo',
    live_event: 'Evento en vivo',
    no: 'No',
    of: 'De',
    or: 'O',
    organization: 'Organización',
    page: 'Pàgina',
    pages: 'Paginas',
    personal: 'Personal',
    poll: 'Censo',
    rating: 'Raiting',
    reset: 'Reiniciar',
    signin: 'Iniciar Sesión',
    signup: 'Registrarse',
    soon: 'Pronto',
    type: 'Type',
    uploading: 'Subiendo...',
    yes: 'Yes',
    something_went_wrong: 'Algo salió mal!',
    view_all: 'Ver todo',
    upload_successful: 'La subida del archive fue exitosa',
    upload_successful_message:
'Sus documentos de Excel fueron subidos exitosamente y están siendo procesados. Esto puedo tomar un algo de tiempo.',
    check_email_upload_successful:
'Por Favor <0>verifique su correo electrónico</0> para el <1>report</1> de la lista de usuarios que fue subida.',
    is_private: 'Privado'
  },

  account: {
    title: 'Cuenta',
    subtitle: 'Maneje sus ajustes e información de su cuenta.',
    saved: 'La cuenta fue cambiada exitosamente'
  },

  assessments: {
    title: 'Evaluaciones',
    subtitle: 'Crear una evaluación',
    finished: 'La evaluación ha terminado',
    countdown: 'La evaluación en vivo será transmitida en',
    create: 'Crear Evaluación',
    list_title: 'Sus Evaluaciones',
    delete_warning: '¿Está seguro de que quiere borrar este artículo?',
    details_title: 'Añadir detalles de evaluación',
    details_subtitle:
  'Nombre su evaluación y dígale a su audiencia lo que deben de esperar aprender de ella.',
    disclaimer: `Yo certifico que mi evaluación no va a:
    - infringir con los derechos de ninguna otra entidad
    - contener ilegalidades incluyendo contenido difamatorio, obsceno, indecente, lascivo, pornográfico, violento, abusivo, insultante, amenazante, o discriminatorio, aunque esté censurado con asteriscos 
    - contener ningún ataque personal a otras personas
    - contener imitaciones de otros participantes, figuras públicas o celebridades
    - contener grabaciones o información personal de ningún individuo sin el consentimiento de dicho individuo y no estará violando ninguna regulación de datos en ninguna parte del mundo
    - contener ninguna solicitación comercial o alguna forma de “spam”'`
  },

  buildersettings: {
    title: 'Ajustes de Publicación',
    subtitle:
    'Añada ajustes a su evento para determinar quién puede ver e interactuar con su evento.'
  },

  buttons: {
    market_place: 'Market Place',
    back_to_normal: 'Back To Normal',
    add: 'Añadir',
    add_page: 'Añadir página',
    add_lesson: 'Añadir lecciones',
    add_answer: 'Añadir nueva respuesta',
    add_group: 'Añadir Grupo',
    add_organization: 'Añadir Organización',
    add_question: 'Añadir Pregunta',
    add_quiz: 'Añadir Quiz',
    add_users: 'Añadir Usuarios',
    back: 'Regresar',
    back_to_course: 'Regresar a la visión general del curso',
    back_to_lesson: 'Regresar a la visión general de la lección',
    back_to_media: 'Regresar a la librería multimedia',
    back_to_details: 'Regresar a detalles',
    cancel: 'Cancelar',
    clear: 'Borrar',
    close: 'Cerrar',
    clone: 'Clonar',
    close_lesson: 'Cerrar Lección',
    clone_course: 'Clonar Curso',
    confirm: 'Confirmar',
    create: 'Crear',
    download: 'Descargar',
    download_mass_user_tamplate: 'Descargar Plantilla',
    add_mass_users: 'Añadir Usuarios en Masa',
    upload_excel_list: 'Subir Lista de Excel',
    delete: 'Borrar Media',
    edit: 'Editar',
    edit_course: 'Editar Curso',
    enroll: 'Inscribirse',
    finish: 'Terminar',
    upload_image: 'Subir Imagen',
    upload_image_video: 'Subir imagen o video',
    select_media_library: 'Seleccionar librería multimedia',
    make_public: 'Hacer Publico',
    make_private: 'Hacer Privado',
    make_org: 'Hacer Organización',
    media: 'Librería Multimedia',
    my_theme: 'Mi tema',
    next: 'Próximo',
    next_lesson: 'Next Lesson',
    next_step: 'Próximo paso',
    previous_step: 'Paso Anterior',
    publish: 'Publicar',
    publish_assessment: 'Publicar su evaluación',
    publish_course: 'Publicar su curso',
    publish_event: 'Publicar su evento',
    reset: 'Reset',
    reset_elements: 'Reset elements',
    save: 'Guardar',
    creating: 'Creando...',
    updating: 'Actualizando...',
    saved: 'Guardado',
    save_for_later: 'Guardar para después',
    save_settings: 'Guardar ajustes',
    select_files: 'Seleccionar archivos',
    select_theme: 'Seleccionar tema',
    start_lesson: 'Comenzar Lección',
    update: 'Actualizar',
    upload: 'Subir',
    upload_audio: 'Subir Audio',
    upload_photo: 'Subir foto de perfil',
    upload_video: 'Subir Video',
    enrolled: 'Inscrito',
    unenroll: 'Des inscribirse',
    from_library: 'Librería multimedia',
    remove: 'Remover',
    removed: 'Removido',
    start_broadcast: 'Iniciar Transmisión',
    end_broadcast: 'Terminar Transmisión',
    camera: 'Cámara',
    screen: 'Pantalla',
    screen_with_camera: 'Pantalla con Cámara'
  },

  card: {
    quick_add: 'Añado rápido',
    add: 'Añadir',
    clone: 'Clonar',
    completed: 'lecciones completadas'
  },

  course: {
    upcoming_course: 'Curso Próximo',
    unPublishEvent: 'Despublicar Evento',
    unPublishAssessment: 'Despublicar Evaluación',
    pastCourse: 'Curso de Entrenamiento',
    upcoming: 'Evento en vivo próximos',
    pastEvent: 'Eventos en vivo pasados',
    pastAssessment: 'Evaluaciones pasadas',
    upcomingAssessment: 'Evaluaciones en vivo próximas',
    created: 'Fecha creada:',
    assignees: 'Cesionarios',
    organisations:
  ' {{ organizaciones }} Organizaciones, <2> {{ usuarios }} usuarios</2>',
    event_date: 'Fecha de evento en vivo <1> {{ fecha }} </1>',
    delete_warning: '¿Está seguro que desea borrar esto?',
    clonning_no_lesson:
  'Por favor añada al menos una lección para clonar el curso.',
    clone_warning: '¿Está seguro que desea clonar esto?',
    clone_success: 'está en proceso de clonación',
    disclaimer: `Yo certifico que mi evaluación no va a:
    - infringir con los derechos de ninguna otra entidad
    - contener ilegalidades incluyendo contenido difamatorio, obsceno, indecente, lascivo, pornográfico, violento, abusivo, insultante, amenazante, o discriminatorio, aunque esté censurado con asteriscos 
    - contener ningún ataque personal a otras personas
    - contener imitaciones de otros participantes, figuras públicas o celebridades
    - contener grabaciones o información personal de ningún individuo sin el consentimiento de dicho individuo y no estará violando ninguna regulación de datos en ninguna parte del mundo
    - contener ninguna solicitación comercial o alguna forma de “spam”'`
  },

  coursecreate: {
    dropzone_text: 'Inserte multimedia',
    sidebar_text: 'Seleccione un tema <1/> para su <3/> lección',
    delete_warning: '¿Está seguro que desea borrar esta lección?'
  },

  coursepage: {
    quizview_loading: 'Verificando respuestas. Por favor espere...',
    quizview_answer: 'Su respuesta'
  },

  coursequiz: {
    title: 'Quizes en todas las lecciones'
  },

  dashboard: {
    tooltip_title: 'No mostrar este video nuevamente',
    page_title: 'Estadísticas de sus cursos',
    page_subtitle: 'Estatus de cursos creados',
    profile_add_info:
  'Añadir su <1>{{uncompleted Text}}</1> para completar su perfil.',
    profile_build:
  'Construir su perfil <1>here</1>, díganos sobre sus intereses y habilidades.',
    profile_title: 'Fuerza de Perfil',
    more_details: 'Más Detalles'
  },

  errors: {
    answer: 'Por favor entre su respuesta',
    answer_correct: 'Seleccione la respuesta correcta',
    code: 'Por favor entre su código',
    criterion: 'Por favor entre criterio',
    duration: 'Duración es requerido',
    element: 'Por favor entre elemento',
    email: 'Por favor entre correo electrónico',
    email_invalid: 'El correo electrónico no es válido',
    feedback_correct: 'Por favor entre el feedback correcto',
    feedback_incorrect: 'Por favor entre el feedback incorrecto',
    host: 'Por favor entre anfitrión del evento',
    host_assessment: 'Por favor entre anfitrión de la evaluación',
    image: 'Por favor suba una imagen',
    location: 'Por favor entre su localización',
    live_location: 'Por favor entre localización de la transmisión',
    limit: 'Por favor entre minutos de transmisión en vivo. ',
    min_3char: '3 caracteres mínimo',
    number: 'por favor entre números positivos.',
    host_email_require: `Correo electrónico del anfitrión es requerido.`,
    attendee_email_require: `Correo electrónico de los asistentes es requerido.`,
    name: 'Por favor entre su nombre',
    not_found_course: 'Curso no fue encontrado',
    not_found_event: 'Evento no fue encontrado',
    not_found_assessment: 'Evaluación no fue encontrada',
    password: 'Por favor entre su contraseña',
    password_digit: 'Al menos un digito',
    password_short: 'Contraseña muy corta, mínimo de 10 caracteres',
    password_special: 'Al menos 1 caracter especial',
    password_uppercase: 'Al menos una letra mayúscula',
    password_lowercase: 'Al menos una letra minúscula',
    phone: 'Por favor entre teléfono',
    phone_invalid: 'Número de teléfono inválido',
    position: 'Por favor entre su posición',
    question: 'Por favor entre pregunta',
    start_date: 'Fecha & Hora de comienzo son requeridos',
    tag: 'Por favor seleccione una etiqueta',
    topics: 'Por favor seleccione al menos un tópico.',
    live_topics: 'Por favor añada tópico',
    title: 'Por favor entre título',
    title_assessment: 'Por favor entre título de evaluación',
    title_course: 'Por favor entre título de curso',
    title_empty: 'El título no puede estar vacío',
    title_event: 'Por favor entre el título del evento',
    title_lesson: 'Por favor entre título de la lección',
    title_quiz: 'Por favor entre nombre del quiz',
    username: 'Por favor entre su nombre de usuario',
    upload_error: 'Error – archivo no compatible!',
    upload_more_than_10: 'Solo puede subir un máximo de 10 archivos.',
    topics_max: 'Por favor añada al máximo 3 tópicos .',
    upload_excel: 'Por favor asegúrese que subió el archivo correcto.',
    title_excel: 'Error subiendo plantilla:',
    file_size_exceed: 'Tamaño de archivo fue excedido',
    subtitle_excel:
  'Por favor asegúrese de usar la plantilla prevista y que las columnas estén llenas correctamente.',
    select_broadcast_option: 'Por favor seleccione una opción para transmitir este evento.',
    multi_hosts_number_error:
  'No. de presentadores no puede exceder al especificado para el evento.',
    conference_people_number_error: `No. de asistentes no puede exceder al especificado para el evento.`,
    select_max_host: 'Seleccione al menos un presentador para el evento.',
    select_max_conference: 'Seleccione al menos un asistente para el evento.',
    stream_stop_error: 'La Transmisión se Detuvo Inesperadamente!',
    unsecure_context:
  'Fatal Error: El navegador de internet no puede tener acceso a la cámara y micrófono por un contexto inseguro. Por favor instale SSL y acceder por https',
    ws_not_supported: 'Fatal Error: WebSocket not supported in this browser'
  },

  events: {
    title: 'Añadir Detalles del Evento en vivo',
    change_host: 'Cambiar Anfitrión',
    finished: 'Este evento ha terminado',
    countdown: 'Evento será transmitido en',
    subtitle:
  'Nombre su evaluación y dígale a su audiencia lo que deben de esperar aprender de ella.',
    poll_disabled: 'El censo no está disponible en este evento',
    disclaimer: `Yo certifico que mi evaluación no va a:
    - infringir con los derechos de ninguna otra entidad
    - contener ilegalidades incluyendo contenido difamatorio, obsceno, indecente, lascivo, pornográfico, violento, abusivo, insultante, amenazante, o discriminatorio, aunque esté censurado con asteriscos 
    - contener ningún ataque personal a otras personas
    - contener imitaciones de otros participantes, figuras públicas o celebridades
    - contener grabaciones o información personal de ningún individuo sin el consentimiento de dicho individuo y no estará violando ninguna regulación de datos en ninguna parte del mundo
    - contener ninguna solicitación comercial o alguna forma de “spam”'`,
    prompt_message:
  'Salir de esta página terminará su evento. ¿Está seguro que desea salir de la página?',
    duration_exceed_message:
  'Su tiempo de transmisión fue excedido, el App va a detener la transmisión.',
    before_schedule_message:
  'Estará por comenzar la transmisión antes de la hora programada. Por favor note que si detiene la transmisión cancelará el evento.',
    browser_unsupportive_message:
  'Su navegador de internet no apoya compartir su pantalla. Puede ver una lista de navegadores apoyados en este enlace.',
    cancel_switch_host_message:
  'El anfitrión no está disponible. El evento continuará de su lado.',
    coaching_prompt_message:
  'No puede salir de esta página hasta que el evento sea finalizado.',
    event_modal_warn: '¿Desea salir de este evento?',
    confirm_message: 'El evento terminará al hacer clic en OK.',
    screen_share_denied: 'A denegado compartir la pantalla',
    media_not_found:
  'La cámara o micrófono no fueron encontrados o no están permitidos en su dispositivo.',
    media_not_access:
  'La cámara o micrófono están siendo usados por otro proceso que no permite leer dispositivos',
    constraint_error:
  'No se encontró ningún dispositivo que se ajusten a sus limitaciones de audio y video. Puede cambiar las limitaciones de audio y video.',
    media_access_denied: 'No está permitido acceder la cámara o micrófono.'
  },

  filters: {
    days7: 'Ultimo 7 días',
    days15: 'Ultimo 15 días',
    days30: 'Ultimo 30 días',
    days365: 'Ultimo 365 días',
    date: 'Fecha creado',
    relevance: 'Relevancia',
    popularity: 'Popularidad',
    name: 'Nombre',
    group: 'Grupo',
    Newest_to_Oldest: 'Mas Nuevo a Mas Viejo',
    Oldest_to_Newest: 'Mas Viejo a Mas Nuevo',
    A_Z: 'A to Z',
    Z_A: 'Z to A'
  },

  helpers: {
    password:
    'Para asegurar la seguridad de su cuenta requerimos que su contraseña tenga al menos una letra minúscula y un número. También necesita tener un mínimo de 10 caracteres de largo.',
    phone: 'Por favor incluya su código de país de su teléfono, por ejemplo: +65',
    username:
  'Su nombre de usuario será tratado como información pública y compartida con otros usuarios como sea necesario. Por lo tanto, le recomendamos que no use información personal en su nombre de usuario.',
    public_description_normal:
  'Su <0><0/></0> ha sido guardado y es considerado privado por defecto. Esto significa que solamente usted puede ver el contenido. Para publicar este contenido puede seleccionar una de las opciones siguientes. Publicar el curso en público significa que será disponibles a todos los usuarios de la plataforma. La Opción de Usuario Específico le permitirá compartir su curso con usuarios específicos utilizando el correo electrónico que tienen asociado con su cuenta de Sharelook.',
    public_description_admin:
  'Esta <0><0/></0> ha sido guardada y es considerada privada por defecto. Esto significa que solamente usted (y el creador del contenido) pueden ver el contenido. Para publicar este contenido puede seleccionar una de las siguientes opciones. Publicar su curso al público significa que estará disponible a todos los usuarios de la plataforma incluyendo aquellos fuera de su organización. Publicarlo a la organización significa que todos los usuarios de la organización podrán acceder el curso. También puede publicarlo a 1 o más grupos, significando que solo los usuarios dentro de dichos grupos podrán acceder el curso. Finalmente, tiene la opción de publicar para usuarios específicos dentro y fuera de su organización usando el correo electrónico asociado con sus cuentas de Sharelook.'
  },

  labels: {
    add: 'Añadir',
    portrait: 'Crop Portrait',
    landscape: 'Crop Landscape',
    answer_type: 'Answer type',
    answers: 'Contestaciones',
    back_to_quiz: 'Regresar a lista de Quiz',
    back_to_question: 'Regresar a lista de preguntas',
    chat: 'Chat',
    chat_available: 'Chat no está disponible en este evento',
    chat_report: 'Chat Report',
    choice_single: 'Elección única',
    choice_multiple: 'Elección multiple',
    choice_free: ' Elección "Libre"',
    choice_sorting: 'Elección "Ordenación"',
    choice_matrix: 'Elección "Ordenación de matriz "',
    clone_lesson_permission: 'Permiso de clonar',
    code: 'Código',
    correct: 'Correcto',
    created: 'Fecha creado',
    clone_modal: 'Clonar curso',
    cloning_course: 'Clonando curso...',
    cloning: 'Clonando...',
    cloning_course_desc: 'Este proceso tomará unos minutos. Por favor tenga paciencia.',
    cloning_success: 'Curso "<0/>" ha sido clonado exitosamente!',
    description: 'Descripción',
    disclaimer: 'Disclaimer',
    drop_file: 'Suelte archivo aquí...',
    duration: 'Duración',
    edit: 'Editar',
    email: 'Correo Electrónico',
    event: 'Evento',
    event_all: 'Todos sus Eventos',
    event_date: 'Fecha/Hora Local del Evento',
    event_this: 'Este Evento',
    expire: 'Expira en',
    expiry_date: 'Fecha de expiración',
    forgot: '¿Se le olvidó la contraseña?',
    group: 'Grupo',
    groups_list: 'Lista de grupos',
    host: 'Anfitrión',
    id_annotation: 'id de anotación',
    id_quiz: 'Quiz id',
    incorrect: 'Incorrecto',
    interests: 'Intereses',
    invited: 'Invitado',
    live_chat: 'Discusión en vivo',
    live_stream_limit: 'Minutos de transmisión en vivo',
    contact_text: 'Para cambiar su límite mensual de transmisión por favor contacte',
    contact_admin:
  '<0>Si desea cambiar su límite de transmisión por favor contacte</0> <1>admin@sharelook.com</1>',
    limit_stream_text:
  'Minutos de transmisión en vivo restantes: <0>{{ limit }}</0> minutos <1/><2/> <3> Para cambiar su límite mensual de transmisión por favor contacte </3> <4>admin@sharelook.com</4>',
    location: 'Localización',
    lessons: 'Lecciones',
    location_broadcast: 'Host Server Location',
    location_broadcast_subtitle:
  'Añada una localización que sea cercana a la transmisión del anfitrión para reducir demoras audiovisuales.',
    media_manager: 'Manager de Multimedia',
    minutes: 'Minutos',
    name: 'Nombre',
    online_quiz: 'Online Quiz',
    organiser: 'Autor',
    organization: 'Organización',
    overall_emotion: 'Experiencia General:',
    organizations_list: 'Lista de Organizaciones',
    password: 'Contraseña',
    participants: 'Participantes',
    picture: 'Picture',
    phone: 'Teléfono',
    photo: 'Foto',
    poll: 'Censo',
    position: 'Posición',
    processing: 'Procesando',
    privacy_settings: 'Ajustes de Privacidad',
    question: 'Pregunta',
    quiz_create: 'Crear Quiz Nuevo',
    quiz_name: 'Nombre de Quiz',
    quiz_update: 'Actualizar quiz',
    select_organization: 'Selecciona una organización',
    settings: 'Ajustes',
    show: 'Show',
    show_graph: 'Show graph result',
    signin: 'Inicio',
    signup: 'Inscribirse',
    sort_by: 'Ordenar por',
    start_date: 'Fecha de comienzo',
    tags: 'Tags',
    terms: 'Estoy de acuerdo con estos <1>términos y condiciones</1>.',
    title: 'Título',
    topics: 'Tópicos',
    topic: 'Tópico',
    total_comments: 'Total Comentarios',
    username: 'Nombre de usuario',
    users_list: 'Lista de usuarios',
    users_emails: 'Inserte los correos eléctronicos de sus usuarios específicos',
    description_specifiction_email:
  'Por favor entre 1 correo electrónico por línea para compartir con múltiples usuarios específicos.',
    message_specific_email: 'Por favor entre el email del usuario específico.',
    viewers: 'Espectadores',
    edit_course: 'Editar',
    edit_assessment: 'Editar',
    edit_event: 'Editar',
    delete_course: 'Borrar',
    delete_event: 'Borrar',
    delete_assessment: 'Borrar',
    view_course: 'View',
    view_event: 'View',
    view_assessment: 'View',
    date_event: 'Fecha de evento en vivo',
    date_assessment: 'Fecha de evaluación en vivo',
    clone_of: 'Clonar',
    clone_course:
  'Cree un clon de este curso que le permita modificar independientemente del original, incluye todas las lecciones y páginas.',
    make: 'Crear',
    available_to: 'disponible para cualquier usuario de ShareLook',
    uploaded_by: 'Subido por: ',
    course: 'Curso',
    assessment: 'Evaluación',
    user_not_exist: 'El usuario no existe, ¿le gustaría enviar una invitación?',
    check_user: 'Noticia',
    user_not_exist_in_org:
  'El usuario no existe en esta organización, ¿le gustaría enviar una invitación?',
    title_modal_stream: 'Cambiar el Límite de Transmisión en Vivo de la Organización',
    des_warning_stream: 'Esta por cambiar',
    monthly_stream: 'límite de transmisión en vivo mensual:',
    min_per_month: 'mins/mes',
    broadcast_label: 'Yo quiero hacer:',
    hosts_emails: `Añada correo electrónico específico de anfitriones`,
    attendee_emails: `Añada correo específico de asistentes`,
    description_host_specifiction_email:
  'Por favor entre 1 correo electrónico por línea.',
    presenter_label: 'Presentador',
    presenter_label_conference: 'No. de Personas',
    event_privacy: 'Modo de Conferencia'
  },

  broadcastOptions: {
    stream_from_phone: 'Transmisión en vivo con mi teléfono',
    other_webrtc: 'Webinar desde mi PC',
    multihost: 'Multidifundir webinar desde mi PC',
    peer2peer: 'Sesión de Coaching',
    conference: 'Conferencia en Vivo'
  },

  lessoncreate: {
    title: 'Añadir detalles de la lección',
    subtitle:
  'Nombre su lección y dígale a su audiencia lo que deben esperar aprender de ella.',
    input_label_title: 'Título de la lección',
    input_label_topics: 'Tópicos de la lección',
    input_label_description: 'Descripción de la lección',
    delete_warning: '¿Esta seguro que desea borrar esta página?'
  },

  lessonoverview: {
    title: 'Anadir lección',
    subtitle: 'Añadir lección existente o crear una nueva',
    new_lesson: 'crear una nueva lección',
    new_lesson_sub: 'Crear una nueva lección desde cero!',
    existing_lesson: 'Seleccione una lección existente',
    existing_lesson_sub:
  'Enlace a una lección existente, los cambios hechos en esta lección también afectaran esta lección en otros cursos.',
    clone_lesson: 'duplicar lección existente',
    clone_lesson_sub:
  'Cree una copia única de la lección en este curso, cambios hechos a esta lección no afectaran la lección original.',
    clone_lesson_modal_sub:
  'Por favor haga clic en el botón de clonar debajo de la lección que quiera añadir a su curso.'
  },

  management: {
    groups_title: 'Manejar Grupos',
    delete_warning: '¿Está seguro?',
    organizations_title: 'Mi Organización',
    organizations_subtitle: 'Añadir organizaciones a su lista',
    users_title: 'Manejar Usuarios',
    users_position: 'Senior Account Manager',
    user_not_found: 'El usuario no fue encontrado',
    select_org: 'Por favor busque y seleccione una organización para manejar usuarios'
  },

  audio: {
    modal_title: 'Añadir Audio',
    list_view: 'List View',
    create_audio_modal_title: 'Crear Audio',
    edit_audio_modal_title: 'Editar Audio',
    public_audio_not_found: 'Audio no fue encontrado.',
    private_audio_not_found: 'Todavía no ha subido ningún audio',
    are_you_sure_to_delete: '¿Está seguro que quiere borrar?',
    type_something: 'Escriba algo',
    select_voice: 'Seleccione',
    file_name: 'Nombre de Archivo',
    description: 'Descripción',
    topic: 'Tópico',
    key_words_tag: 'Tag de palabras claves',
    mp3_audio: 'MP3 Audio',
    created: 'Creado',
    duration: 'Duración',
    close: 'cerrar'
  },

  image: {
    modal_title: 'Añadir imagen',
    image: 'Imagen',
    pixel: 'Pixel',
    dimension: 'Dimension',
    public_image_not_found: 'Imagen no fue encontrada.',
    private_image_not_found: 'Todavía no ha subido ninguna imagen'
  },

  annotation: {
    modal_title: 'Annotation'
  },

  text: {
    modal_title: 'Text Editor'
  },

  video: {
    modal_title: 'Añadir Video',
    image: 'Video',
    mp4_video: 'MP4 Video',
    public_video_not_found: 'Video no fue encontrado.',
    private_video_not_found: 'Todavía no ha subido ningún video.'
  },

  media: {
    title: 'Librería Multimedia',
    subtitle: 'Librería de sus Archivos',
    modal_title: 'Subir media',
    delete_warning: 'Confirmar Borrar',
    delete_message:
  'La multimedia que eliminó aun aparecerá en aquellas páginas que ya fueron utilizadas',
    dropzone_text: 'Arrastrar y soltar',
    delete_success: 'Media fue borrada exitosamente',
    upload_file: 'Subir Archivo',
    drag_drop: 'o Arrastrar y soltar',
    import_file: 'Añadir archivos de su computadora',
    no_media: 'Todavía no ha añadido ningún multimedia',
    media_type: 'Media type',
    video: 'Video',
    image: 'Imagen',
    audio: 'Audio',
    gif: 'GIF',
    transcribed_text: 'Transcribed Text',
    media_not_found: 'Media no se encontró.',
    of: 'de',
    disclaimer: ` Yo certifico que mi evaluación no va a:
    - infringir con los derechos de ninguna otra entidad
    - contener ilegalidades incluyendo contenido difamatorio, obsceno, indecente, lascivo, pornográfico, violento, abusivo, insultante, amenazante, o discriminatorio, aunque esté censurado con asteriscos 
    - contener ningún ataque personal a otras personas
    - contener imitaciones de otros participantes, figuras públicas o celebridades
    - contener grabaciones o información personal de ningún individuo sin el consentimiento de dicho individuo y no estará violando ninguna regulación de datos en ninguna parte del mundo
    - contener ninguna solicitación comercial o alguna forma de “spam”'`
  },

  navigation: {
    account: 'Cuenta',
    assessments: 'Evaluaciones',
    dashboard: 'Dashboard',
    groups: 'Manejo de Grupo',
    logout: 'Log out',
    organisations: 'Organizaciones',
    organizations_users: 'Organizaciones & Usarios',
    reports: 'Reportes',
    schedule: 'Itinerario',
    training: 'Entrenamiento',
    nowLive: 'Transmitir',
    users: 'Usarios',
    workshop: 'Mi Taller'
  },

  onboarding: {
    step1_title: 'Paso 1 : Díganos un poco acerca de usted',
    step1_subtitle:
  '¿Cuál es su nombre? ¿De dónde es? ¿Cuál es su profesión?',
    step2_title: 'Paso 2 : Selecciona alguna habilidad que necesites o puedas mejorar',
    step2_subtitle:
  'Próximo, díganos que le gustaría entender mejor. ¿Quizás algo que aún no has aprendido? ¿Un nuevo campo de estudio? ¿Lo que hacen tus seres queridos?'
  },

  pagebuilder: {
    title: 'Crear Lección',
    subtitle:
  'Es tiempo de crear el contenido para que su audiencia aprenda de usted, puede usar fotos, videos o gráficas.',
    select_layout: 'Seleccione un diseño de página',
    template1: 'Blank',
    template2: 'Título y subtitulo',
    template3: 'Título & Texto',
    template4: 'Título, Media & Texto',
    template5: 'Título & Media',
    template6: 'Título & Quiz'
  },

  pageconstructor: {
    title: 'Crear Y Clonar Lección',
    subtitle:
  'Es tiempo de crear el contenido para que su audiencia aprenda de usted, puede usar fotos, videos o gráficas.'
  },

  passwordreset: {
    success_coded:
    'Hemos enviado un código de confirmación a la dirección provista, por favor copie el código en el campo debajo para programar una nueva contraseña.',
    success_changed:
  'A programado una nueva contraseña exitosamente. Ahora puede entrar al sistema.'
  },

  placeholders: {
    search: 'Buscar',
    answer: 'Contestación',
    chat: 'Decir algo...',
    code: 'Código',
    criterion: 'Criterio',
    date_event: 'Seleccione Fecha y Hora',
    date_start: 'Fecha de comienzo',
    date_expire: 'Fecha de expiración',
    description: 'Descripción',
    elements: 'Sort elements',
    email: 'Correo Electrónico',
    feedback_correct: 'Feedback Correcto',
    feedback_incorrect: 'Feedback Incorrecto',
    host: 'Anfitrión',
    keywords: 'Search keyword(s)',
    lesson_description: 'Descripción de la lección',
    location: 'Localización',
    minutes: 'Minutos',
    name: 'Nombre',
    organiser: 'Autor',
    organization: 'Organización',
    password: 'Contraseña',
    phone: 'Teléfono',
    photo: 'Foto',
    picture: 'Imagen',
    point: 'Punto',
    position: 'Posición',
    question: 'Pregunta',
    quiz: 'Nombre de Quiz',
    search_course: 'Buscar por curso',
    search_media: 'Buscar media',
    search_name: 'Buscar por Nombre',
    search_title: 'Buscar por título',
    search_training: 'Buscar entrenamiento',
    settings: 'Ajustes',
    tags: 'Tags',
    title: 'Título',
    topics: 'Tópicos',
    username: 'Nombre de Usuario',
    keyword: 'Palabras clave',
    max_500_characters: 'Max 500 Caracteres ',
    no_description: 'Este archivo no tiene una descripción',
    no_keyword: 'Este archive no tiene palabras claves',
    no_topics: 'Este archive no se le ha puesto un tópico',
    feedback: 'Mensaje',
    contact: '¿Cómo podemos ayudar?'
  },

  publishsettings: {
    title: 'Publicar Ajustes',
    event_subtitle:
  'Añada ajustes a su evento, quién puede ver e interactuar con su evento',
    assessment_subtitile:
  'Nombre su evaluación y dígale a su audiencia lo que deben esperar aprender de ella.',
    course_subtitle:
  'Añada ajustes a su curso, quién puede ver e interactuar con su curso',
    disclaimer:
  ` 1. Registración

  Al registrarse como un usuario o al crear una cuenta usted está de acuerdo con nuestro [User Agreement] y reconoce haber leído nuestra [Privacy Policy]

  2.	Crear Curso

  Al usar nuestras herramientas y desarrollar contenido de curso, usted está de acuerdo con nuestro [User Agreement] y reconoce haber leído nuestra [Privacy Policy]

  3.	Crear evento

  Al usar nuestras herramientas y desarrollar contenido de curso, usted está de acuerdo con nuestro [User Agreement] y reconoce haber leído nuestra [Privacy Policy]

  4.	Crear evaluación

  Al usar nuestras herramientas y desarrollar contenido de curso, usted está de acuerdo con nuestro [User Agreement] y reconoce haber leído nuestra [Privacy Policy] `,
    dates_validation_error: 'Ambas fechas deben existir.',
    emails_validation_error: 'Lista de correos es inválida.',
    specific_email_validation_error: 'Solamente se permite un correo electrónico.',
    i_agree_to_these: 'Estoy de acuerdo con estos ',
    terms_and_conditions: 'términos y condiciones.',
    PublicDescription:
  'Usuarios independientes gratis: (opciones privadas y públicas) Su [Curso|Evento|Evaluación] ha sido guardado y es considerado privado por defecto. Esto significa que solo usted puede ver este contenido. Para publicar el contenido puede seleccionar hacerlo público abajo. Esto significa que curso estará disponible para todos los usuarios de la plataforma.',
    OrganisationsDescription:
  'Usuarios de Org: (En vez de ""Finalizar"" use ""Publicar"" y tráigalos a la página de publicación igual que a otros usuarios pero sin ninguna de la opciones de publicación.) Su [Curso|Evento|Evaluación] ha sido guardado y es privado. Esto significa que solo usted y los Administradores en su organización pueden verlo. Como un usuario, no puede publicar su contenido dentro de la organización ya que esto requiere derechos de administrador. Por favor contacte uno de sus administradores y pida que publiquen su contenido públicamente a toda la organización, a grupos específicos dentro de su organización o a usuarios específicos.',
    OrganisationGroupsDescription:
  'Org Admins: (Para contenido hecho dentro de la organización: Privado, público, organización, usuario específico): Este [Curso|Evento|Evaluación] ha sido guardado y es considerado privado por defecto. Esto significa que solo usted (y el creador del contenido) pueden ver este contenido. Para publicar este contenido puede seleccionar una de las siguientes opciones. Publicar su curso al público significa que estará disponible a todos los usuarios de la plataforma incluyendo aquellos fuera de su organización. Publicarlo a la organización significa que todos los usuarios de la organización podrán ver el contenido. También puede publicar a 1 o más grupos, significando que solo los usuarios dentro de esos grupos podrán ver el curso. Finalmente, tiene la opción de publicar a usuarios específicos dentro o fuera de su organización usando sus correos electrónicos asociados con la cuenta de Sherlook.',
    SpecificUsersDescription:
  'Usuarios Independientes pagados: (privados, públicos y específico) Su [Curso|Evento|Evaluación] ha sido guardado y es considerado privado por defecto. Esto significa que solo usted puede ver este contenido. Para publicar este contenido puede seleccionar una de las siguientes opciones. Publicar su curso al público significa que estará disponible a todos los usuarios de la plataforma. La opción de Usuario Específico le permitirá compartir su curso con usuarios específicos usando sus correos electrónicos asociados con sus cuentas de Sharelook.'
  },

  quiz: {
    quiz_empty: 'Todavía no ha creado un quiz',
    questions_empty: 'Todavía no ha creado una pregunta'
  },

  regions: {
    asia_pacific_australia: 'Australia',
    asia_pacific_india: 'India',
    asia_pacific_japan: 'Japan',
    asia_pacific_s_korea: 'South Korea',
    asia_pacific_singapore: 'Singapore',
    asia_pacific_taiwan: 'Taiwan',
    eu_belgium: 'Belgium',
    eu_germany: 'Germany',
    eu_ireland: 'Ireland',
    south_america_brazil: 'Brazil',
    us_central_iowa: 'Iowa',
    us_east_s_carolina: 'South Carolina',
    us_east_virginia: 'Virginia',
    us_west_california: 'California',
    us_west_oregon: 'Oregon'
  },

  reports: {
    title: 'Reportes'
  },

  signup: {
    success_step1:
    'Hemos enviado un código de confirmación a la dirección provista, por favor copie el código en el campo debajo para programar una nueva contraseña ',
    success_step2: 'Gracias. Está registrado exitosamente.',
    privacy: `Estoy de acuerdo con Sharelook's <1> términos & condiciones</1> and <3> privacy policy</3>`
  },

  stream: {
    emotion_impressive: 'Impresionante',
    emotion_unimpressive: 'No impresionante',
    message_not_started: 'Pronto',
    message_running: 'En Vivo',
    message_processing:
  'Estamos procesando la grabación en vivo para ser reproducida. Por favor verifique más tarde para poder ver el video y para continuar añadiendo ratings y feedback.'
  },

  tabs: {
    audience: 'Audiencia',
    course: 'Visión del Curso',
    details: 'Detalles del Evento en Vivo',
    description: 'Descripción',
    host: 'Anfitrión',
    lesson: 'Visión de la Lección',
    lessons: 'Lecciones',
    overview: 'Visión',
    page: 'Constructor de Página',
    publish: 'Publicar',
    settings: 'Ajustes de Publicación',
    total_views: 'Total Views',
    live_participates: 'Participantes en Vivo',
    live_chats: 'Chats en Vivo',
    live_quiz: 'Censo en Vivo',
    trainer_rating: 'Rating del Entrenador',
    trainer_times: 'Tiempo del Entrenador',
    courses_quizzes: 'Quizzes del Curso',
    user_list: 'Lista de Usuario',
    discussion: 'Discusión',
    notes: 'Mis Notas'
  },

  topics: {
    SALES_TRAINING: 'Entrenamiento de Ventas',
    NEGOTIATION_TRAINING: 'Entrenamiento de negociaciones',
    CROSS_CULTURAL_AWARENESS: 'Conciencia Intercultural',
    NETWORKING_SKILLS: 'Habilidades de Networking',
    SOFTWARE_ARCHITECTURE: 'Arquitectura de Software',
    PROJECT_MANAGEMENT: 'Gestión de Proyectos',
    DIGITAL_TRANSFORMATION: 'Transformación Digital',
    IT_CONSULTING: 'Consultoría de IT',
    MANAGEMENT_CONSULTING: 'Consultoría de Gerencia',
    HR_CONSULTING: 'Consultoría de HR',
    INTERNET_OF_THINGS: 'Internet of Things (IoT)',
    CYBERSECURITY: 'Cybersecurity',
    PRIVACY_AND_GDRP: 'Privacy and GDPR'
  },

  training: {
    title: 'Entrenamiento',
    title_assessment: 'Evaluaciones',
    subtitle: 'Cursos y Eventos en Vivo',
    enrolled_title: 'Cursos y Eventos Inscritos',
    enrolled_subtitle: 'Cursos y Eventos en los que se ha inscrito',
    enrolled_message:
  'Actualmente no está inscrito en ningún curso o evento.',
    past_live_event_title: 'Eventos En Vivo Pasados',
    past_live_event_subTitle: 'Eventos En Vivo Terminados',
    past_live_event: 'Actualmente no hay eventos pasados.',
    recomended_title: 'Cursos y Eventos Recomendados',
    now_live_event_title: 'Ahora En Vivo',
    now_live_event_subTitle: 'Eventos que están en vivo o estarán muy pronto',
    now_live_event: 'Actualmente no hay evento en vivo.',
    webinar_event_title: 'Canal Webinar',
    webinar_event_subTitle: 'Webinars Terminados',
    webinar_event: 'Actualmente no hay webinar.',
    p2p_event_title: 'Sesiones de Coaching',
    p2p_event_subTitle: ' Sesiones de Coaching Terminadas',
    p2p_event: 'Actualmente no hay sesiones de coaching.',

    now_live_assessment_title: 'Ahora en vivo',
    now_live_assessment_subTitle:
  'Evaluaciones que están en vivo o estarán muy pronto',
    now_live_assessment_message: 'Actualmente no hay evaluaciones en vivo',

    upcoming_assessment_title: 'Próximas Evaluaciones en Vivo',
    upcoming_assessment_subtitle: 'Próximas Evaluaciones en Vivo',
    upcoming_assessment_message:
  'Actualmente no hay evaluaciones próximas.',

    past_live_assessment_title: 'Evaluaciones En Vivo Pasadas',
    past_live_assessment_subTitle: 'Evaluaciones Terminadas',
    past_live_assessment_message:
  'Actualmente no hay evaluaciones pasadas',

    /**
     * <0/> get index 0 of data from Trans component
     * exp -->
     * <Trans i18nKey='training:recomended_subtitle' >
     *    <Navlink>{...something}</Navlink> --> is index 0 of Trans component
     *    <h1>{...something}</h1> --> is index 1 of Trans component
     * </Trans>
     */
    recomended_subtitle:
  'Hemos seleccionado cursos y eventos en vivo basados en su <0><0/></0>',
    /**
     * and we also can get data from index to replace in recommended_subtitle by like above string.
     * for exp -->
     * at the end of the recomended_subtitle above will get data from index of component to display
     * <0><0/></0> mean get data index 0 of Trans component above = <Navlink> and <0/> get data index 0 from <Navlink> = {...something}
     */
    search: 'Buscar Entrenamiento',
    search_assessment: 'Buscar Evaluaciones',
    recomended_message: 'Actualmente no hay cursos o eventos recomendados.'
  },

  warnings: {
    delete: '¿Está seguro?'
  },

  workshop: {
    title: 'Mi Taller',
    subtitle: 'Crea tu propio cuso o evento en vivo',
    new_course: 'Crea un nuevo curso',
    new_event: 'Crea un nuevo evento',
    new_assessment: 'Crea una nueva evaluación en vivo',
    courses_title: 'Cursos y Evaluaciones en Vivo Creadas',
    courses_subtitle: 'Editar & Publicar sus cursos y planear eventos en vivo',
    tags_course: 'Curso',
    tags_live: 'Evento en vivo',
    tags_assessment: 'Evaluación en vivo'
  },

  help_center: {
    sidebar: {
      title: '¿Cómo Podemos ayudarlo?',
      back_to: 'Dashboard'
    },
    menu: {
      help_center: 'Centro de Ayuda',
      whats_new: "What's new",
      feedback: 'Feedback',
      feedback_title: 'Enviar feedback para sharelook',
      terms_and_conditions: 'Términos & Condiciones',
      privacy_policy: 'Privacy Policy',
      contact_us: 'Contáctanos'
    },
    get_started: {
      title: 'Empezando con share look',
      description: {
        main: 'HEMOS INTEGRADO VIDEO EN VIVO DENTRO DE UN CONSTRUCTOR DE CURSO QUE ES FACIL DE UTILIZAR.',
        tagline:
      'Con el sistema patentado de ShareLook, podrá crear compromisos, aprendizajes automatizados y maximizar la distribución del conocimiento.'
      }
    },
    whats_new: {
      title: 'Empezando con share look',
      description: {
        main:
        'Sabía usted que un aprendizaje estilo narrativo puede ser creado en una poca cantidad de tiempo y no es costoso?  Debería combinar el constructor de curso de ShareLook y los talentos respectivos. Pregúntenos "COMO',
        tagline: ''
      }
    },
    create_workshop: 'Cómo Crear un Taller',
    create_course: 'Cómo Crear un Curso',
    create_user: 'Cómo Crear un Usuario',
    create_group: 'Cómo Crear un Grupo',
    create_webinar: 'Cómo Crear un Webinar',
    create_live_event: 'Cómo Crear un Evento en Vivo',
    create_live_assessment: 'Cómo Crear una Evaluación en Vivo',
    create_live_on_mobile: 'Crear en Vivo en Mobil',
    personalize_profile: 'Personificar el Perfil',
    manage_group: 'Manajar Grupos',
    report: 'Reportes',
    add_annotations: 'Cómo Añadir Anotaciones Popups',
    add_audio: 'Cómo Añadir Audio',
    add_header_and_footer: 'Añadir Header & Footer',
    add_image: 'Cómo Añadir Imagen',
    add_quiz_content: 'Cómo Añadir Contenido de Quiz',
    add_text_content: 'Cómo Añadir Contenido de Texto',
    add_user: 'Cómo Añadir Usuario',
    add_video: 'Cómo Añadir Video',
    clone_course: 'Cómo Clonar el Curso',
    find_live_broadcast: 'Cómo Encontrar Transmisiones en Vivo',
    contact_us: {
      title: 'Contactenos',
      subject_placeholder: 'Entre un tema*',
      content_placeholder: 'Entre mensaje*',
      subject_required: '*Tema es requerido',
      content_required: '*Mensaje es requerido',
      submit: 'Enviar',
      cancel: 'Cancelar',
      sent: 'Enviado'
    },
    feedback: {
      title: 'Enviar feedback para sharelook',
      attach_title: 'Añada Archivo',
      choose: 'Escoja un archivo',
      detail: 'El archive será compartido con el equipo de soporte',
      content_placeholder: 'Enter a message*',
      content_required: '*Message is required',
      submit: 'Enviar',
      cancel: 'Cancelar',
      sent: 'Enviado'
    }
  },
  discussion: {
    join_discussion: 'Join in discussion'
  },
  notes: {
    title: 'Mis Notas',
    add_note: 'Añadir nueva nota'
  }
}
