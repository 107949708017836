import React, { useState, useEffect, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Loading } from 'react-simple-chatbot'
import { api } from '../../../../services'
import uuidv4 from 'uuid/v4'
import { Button, Modal } from 'antd'
import _ from 'lodash'

const shareBotResources = {
  organization: 'ORGANIZATION',
  worldKnowledge: 'WORLD_KNOWLEDGE'
}

const botModalDefault = {
  visible: false,
  file: {}
}

function ChatBotView({ steps, user, triggerNext, chatBot }) {
  const [loading, setLoading] = useState(true)
  const [result, setResult] = useState([])
  const [error, setError] = useState(false)
  const [botModal, setBotModal] = useState(botModalDefault)

  useEffect(() => {
    const search = steps.search.value
    let org_id = user.info.active_organization_id

    const temp = {
      org_id: String(org_id),
      question: search
    }

    let tryCount = 0
    const maxTries = 2

    const { activeBotResource } = chatBot.current.props
    const request =
      activeBotResource === shareBotResources.organization
        ? api.audioSynthesizer.questionBot(temp)
        : api.audioSynthesizer.openAIBot(temp)
    const makeRequest = () => {
      request
        .then(res => {
          const body =
            activeBotResource === shareBotResources.organization
              ? res.data.body[0]
              : res.data.body && res.data.body[0]
          setResult({
            ...body,
            id: uuidv4()
          })
          // setResult(
          //   res.data.body.map((item, index) => ({
          //     ...item,
          //     id: uuidv4()
          //   }))
          // )
          setLoading(false)
          setError(false)
          triggerNext()
        })
        .catch(err => {
          if (tryCount < maxTries) {
            tryCount++
            makeRequest()
          } else {
            setResult([])
            setLoading(false)
            setError(true)
            triggerNext()
          }
        })
    }

    makeRequest()
  }, [])

  const handleOpenReferenceFile = useCallback(async () => {
    const {
      file: { signed_url }
    } = botModal

    window.open(signed_url, '_blank')
  }, [botModal])

  const reference = useMemo(
    () => (_.isEmpty(result.reference) ? result.context_v1 : result.reference),

    [result]
  )

  const showModalContent = () => {
    const {
      visible,
      file: { context, file_title, pdf_name }
    } = botModal
    return (
      <Modal
        onCancel={() => setBotModal(botModalDefault)}
        visible={visible}
        title={file_title || pdf_name}
        centered
        footer={[
          <Button
            shape="round"
            key="submit"
            type="primary"
            onClick={handleOpenReferenceFile}
          >
            Open File
          </Button>
        ]}
        width="800px"
      >
        <div className="reference-content-container">
          <h1 className="reference-content-title">Contexts</h1>
          <div className="reference-content-all">
            {context.map((item, index) => (
              <div className="reference-content">
                <h3>{index + 1}.</h3>
                <span>{item}</span>
              </div>
            ))}
          </div>
        </div>
      </Modal>
    )
  }

  return (
    <div className="sharebot">
      {loading ? (
        <Loading />
      ) : error ? (
        'Network Error. Please Try Again !'
      ) : !result && result.answer ? (
        'No answers found. Try a different question.'
      ) : (
        <div className="bot-container">
          <div className="question">{result.question || '-->'}</div>
          <div className="answer-container" id={`answer${result.id}`}>
            <div className="answer-top">
              <div className="answer-main">{result.answer}</div>
            </div>

            {!_.isEmpty(reference) && (
              <div className="reference">
                <span>Reference</span> <br />
                <ul>
                  {Object.keys(reference).map(item => {
                    const fileItem = reference[item]
                    return (
                      <li
                        onClick={() =>
                          setBotModal({
                            visible: true,
                            file: fileItem
                          })
                        }
                      >
                        [{item}] {fileItem.file_title || fileItem.pdf_name}
                      </li>
                    )
                  })}
                </ul>
              </div>
            )}
          </div>
        </div>
      )}

      {botModal.visible && showModalContent()}
    </div>
  )
}

ChatBotView.propTypes = {
  steps: PropTypes.object,
  triggerNextStep: PropTypes.func
}

ChatBotView.defaultProps = {
  steps: undefined,
  triggerNextStep: undefined
}

export default ChatBotView
