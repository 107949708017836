import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import actions from '../../../store/actions'
import List from './List'

const mapStateToProps = state => ({
  assessments: state.assessments,
  user: state.user
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getPastLiveAssessment: params =>
        actions.assessments.getPastLiveAssessment(params),
      getUpcomingLiveAssessment: params =>
        actions.assessments.getUpcomingLiveAssessment(params),
      getNowLiveAssessment: params =>
        actions.assessments.getNowLiveAssessment(params)
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(List)
