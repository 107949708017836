import React from 'react'
import { useTranslation } from 'react-i18next'
import '../styles.scss'
import NewHeader from '../../../../../components/NewHeader'

function TrainingListContainer({ children, title }) {
  console.log(title, 'check title')
  const { t } = useTranslation()
  return (
    <div className="training-list-container">
      <div className="training-list-header">
        <div>
          <span>
            {t('v4:training')} {'>'}
          </span>{' '}
          {title}{' '}
        </div>
        <div>
          <NewHeader />
        </div>
      </div>

      <div className="training-list-content">{children}</div>
    </div>
  )
}

export default TrainingListContainer
