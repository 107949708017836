import PublishSettings from './PublishSettings'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import actions from '../../../../../store/actions'

const mapStateToProps = state => ({
  list: state.organizations.list.rows,
  isFetching: state.organizations.isFetching,
  courseBuilder: state.courseBuilder,
  user: state.user,
  courses: state.courses
})

const mapDispatchToProps = dispatch => bindActionCreators({
  update: actions.courseBuilder.update,
  get: actions.organizations.get,
  getCourseById: actions.courses.getCourseById,
  resetCourseById: actions.courses.resetCourseById
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PublishSettings)
