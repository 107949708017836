import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import LessonCreate from './LessonCreate'

import actions from '../../../../../store/actions'

import * as lessonBuilderActions from '../../../../../store/actions/lessonBuilder'

const { handleLessonClone } = actions.lessonBuilder

export default connect(
  state => ({
    lesson: state.lessonBuilder,
    courseId: state.courseBuilder.courseId,
    courseMarketPlaceDescription: state.courseBuilder.courseMarketPlaceDescription,
    topics: state.topics
  }),
  dispatch => ({
    lessonBuilderActions: bindActionCreators(lessonBuilderActions, dispatch),
    handleLessonClone: bindActionCreators(handleLessonClone, dispatch),
    upload: (file, opts) => dispatch(actions.library.upload(file, opts)),
    updateTopicList: (newTopic) => dispatch(actions.topics.updateTopicList(newTopic))
  })
)(LessonCreate)
