import React from 'react'
import { Spin } from 'antd'
import moment from 'moment'
import { isPublish, handleEdit, handlePublish } from './internal/Controller'

import { Footer, CourseExpire, Btn } from './styled'

const ViewContentFooter = ({ App, isOwner }) => {
  const {
    course: {
      item: {
        id,
        exp_date: expDate,
        enrollment,
        publisher_id: publisherId,
        is_access: isAccess,
        host_ids: hostIds,
        creator_id: creatorId,
        privacy,
        is_restricted: isRestricted
      },
      item
    },
    history,
    user: {
      info: { isAdmin, isSuperAdmin, id: userId }
    },
    isCourseEnrollment,
    t
  } = App.props

  const hasEditPermission = privacy && privacy.edit_permission

  const hasAccessToEdit =
    (hostIds && hostIds.includes(userId)) ||
    userId === creatorId ||
    isSuperAdmin ||
    hasEditPermission

  const allowClone = isOwner
    ? true
    : isRestricted
    ? false
    : isAdmin || isSuperAdmin

  return (
    <Footer>
      <Footer.Wrapp>
        {expDate && (
          <CourseExpire>
            {t('labels:expire')} {moment(expDate).format('L')}
          </CourseExpire>
        )}
        {allowClone && (
          <Btn
            type="primary"
            className="rounded"
            onClick={() => App.cloneCourse(item)}
          >
            {t('buttons:clone_course')}
          </Btn>
        )}
        {isAccess && hasAccessToEdit && (
          <Btn
            type="primary"
            className="rounded"
            onClick={() => handleEdit(history, id)}
          >
            {t('buttons:edit')}
          </Btn>
        )}

        {isPublish(isAdmin, isOwner, isSuperAdmin) && (
          <Btn
            type="primary"
            className="rounded"
            onClick={() => handlePublish(history, id)}
          >
            {t('buttons:publish')}
          </Btn>
        )}

        <Spin spinning={isCourseEnrollment}>
          {enrollment ? (
            <Btn
              type="primary"
              className="rounded"
              disabled={!publisherId}
              onClick={App.unenroll}
            >
              {t('buttons:unenroll')}
            </Btn>
          ) : (
            <Btn
              type="primary"
              className="rounded"
              disabled={!publisherId}
              onClick={App.enroll}
            >
              {t('buttons:enroll')}
            </Btn>
          )}
        </Spin>
      </Footer.Wrapp>
    </Footer>
  )
}

export default ViewContentFooter
