import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { styleguide } from '../../../../constants'

const { colors } = styleguide

const Item = styled.div``

Item.Link = styled(NavLink)`
  position: relative;
  display: block;

  &:link,
  &:hover,
  &:visited {
    text-decoration: none;
  }

  &.active {
    border-bottom: 2px solid ${colors.blue};
  }
`

Item.Inner = styled.div`
  display: flex;
  align-items: center;
`

Item.Icon = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 7.2rem;
  height: 7.2rem;

  & > div,
  & > div > div {
    display: flex;
    align-items: center;
  }

  svg,
  path {
    transition: fill 0.25s;
  }
`

Item.Heading = styled.div`
  flex-shrink: 0;
  font-weight: 600;
  font-size: 1.4rem;
  margin: 0 2.4rem 0.7rem 2.4rem;
  color: ${colors.black};
  transition: color 0.25s;

  ${Item.Link}:hover &,
  ${Item.Link}.active & {
    color: ${colors.blue};
  }
`

export { Item }
