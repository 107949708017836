import { Auth } from 'aws-amplify'
import { config } from '../../constants'
import http, {
  httpCustomInstance,
  httpCustomSummarizedInstance
} from './http'
class shlStorage {
  static getKey(keyValue) {
    const accessKeys = [
      'refreshToken',
      'accessToken',
      'idToken',
      'LastAuthUser'
    ]
    const accessItem = accessKeys.find(item => keyValue.includes(item))
    return accessItem
  }

  static setItem(key, value) {
    const accessItem = this.getKey(key)
    localStorage.setItem(accessItem || key, value)
  }

  // get item with the key
  static getItem(key) {
    const accessItem = this.getKey(key)
    const item = localStorage.getItem(accessItem || key)
    return item
  }

  // remove item with the key
  static removeItem(key) {
    const item = this.getKey(key)
    localStorage.removeItem(item || key)
  }

  // clear out the storage
  static clear() {
    localStorage.clear()
  }
}

Auth.configure({
  storage: shlStorage,
  Auth: {
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    userPoolWebClientId: config.cognito.USER_POOL_WEB_CLIENT_ID,
    mandatorySignIn: config.cognito.MANDATORY_SIGN_IN,
    authenticationFlowType: config.cognito.AUTHENTICATION_FLOW_TYPE,
    identityPoolId: config.cognito.IDENTITY_POOL_ID
  }
})
const verifyPasswordResetCode = data => {
  data.ClientId = config.cognito.USER_POOL_WEB_CLIENT_ID
  return http({
    method: 'POST',
    url: `/verify-user-email`,
    data
  })
}
function getPasswordResetCode(email) {
  return Auth.forgotPassword(email)
}

function passwordReset(values) {
  return Auth.forgotPasswordSubmit(
    values.username,
    values.code,
    values.password
  )
}

function signIn(email, password) {
  return Auth.signIn(email, password)
}

function deleteAllCookies() {
  document.cookie.split(';').forEach(function (c) {
    document.cookie = c
      .replace(/^ +/, '')
      .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/')
  })
}

function signUp(values) {
  const { username, password, ...rest } = values
  const params = {
    username,
    password,
    attributes: rest
  }
  console.log(params, 'paramas datas..')
  return Auth.signUp(params)
}

function signOut() {
  return Auth.signOut()
}

function confirmSignUp(values) {
  return Auth.confirmSignUp(values.username, values.code)
}

function getSession() {
  return Auth.currentSession()
}

function resendConfirmationCode(username) {
  return Auth.resendSignUp(username)
}

function getUser() {
  return Promise.all([Auth.currentUserInfo(), getDbUser()]).then(
    ([user, dbUser]) => {
      const {
        'custom:country': country,
        'custom:position': position,
        'custom:timezone': timezone,
        address,
        phone_number: phoneNumber,
        'custom:linkedin': linkedin,
        'custom:bio': bio,
        name
      } = user.attributes

      return {
        ...dbUser.data,
        name,
        address,
        phone_number: phoneNumber,
        country,
        position,
        timezone,
        bio,
        linkedin
      }
    }
  )
}

function updateUser(id, attributes, dbAttributes) {
  return Promise.resolve()
    .then(() => {
      if (attributes) {
        return Auth.currentAuthenticatedUser().then(user =>
          Auth.updateUserAttributes(user, attributes)
        )
      }
      return null
    })
    .then(() => {
      if (dbAttributes) {
        return updateDbUser(id, dbAttributes)
      }
      return null
    })
}

function authenticate() {
  deleteAllCookies()
  return Auth.currentAuthenticatedUser({
    bypassCache: false
  })
}

const getDbUser = (id = 'me') => {
  return http({
    method: 'GET',
    url: `/users/${id}`
  })
}

const updateDbUser = (id, data) => {
  return http({
    method: 'PUT',
    url: `/users/${id}`,
    data
  })
}

const inviteUser = token => {
  return http({
    method: 'GET',
    url: `/users/invite?token=${token}`
  })
}

const resetUserFlag = () => {
  return http({
    method: 'PUT',
    url: `/users/flag`
  })
}

const stripeConnect = (id, pathName) => {
  return http({
    method: 'POST',
    url: `/users/${id}/connect`,
    data: {
      path_name: pathName
    }
  })
}

const uploadMassUser = data => {
  return http({
    method: 'POST',
    url: '/users/import_users',
    data
  })
}

const sendCertificateByEmail = params => {
  return http({
    method: 'POST',
    url: `/sendCert/${params.course_id}/${params.library_id}`
  })
}

const updateScheduleTime = data => {
  return httpCustomInstance({
    method: 'PUT',
    url: `/ondemand/schedule`,
    data
  })
}

const getScheduleTime = () => {
  return httpCustomInstance({
    method: 'GET',
    url: `/ondemand/schedule`
  })
}

const getIntegrationStatus = () => {
  return http({
    method: 'GET',
    url: '/vendor/zoom/integration/any'
  })
}

const revokeIntegration = () => {
  return http({
    method: 'DELETE',
    url: '/vendor/zoom/integration/revoke'
  })
}

const getSharelookVoices = () => {
  return httpCustomSummarizedInstance({
    method: 'GET',
    url: `nvidia-english/`
  })
}

const getEnglishAudioPreview = data => {
  return httpCustomSummarizedInstance({
    method: 'POST',
    url: `nvidia-english/tts`,
    data
  })
}

const getNonEnglishAudioPreview = data => {
  return httpCustomSummarizedInstance({
    method: 'POST',
    url: `non-english-coqui-tts/non_english_tts`,
    data
  })
}

const deleteUser = id => {
  return http({
    method: 'DELETE',
    url: `/users/${id}`
  })
}
export {
  getPasswordResetCode,
  passwordReset,
  signIn,
  signUp,
  signOut,
  confirmSignUp,
  authenticate,
  getSession,
  getUser,
  updateUser,
  deleteUser,
  updateDbUser,
  getDbUser,
  inviteUser,
  resetUserFlag,
  uploadMassUser,
  sendCertificateByEmail,
  updateScheduleTime,
  getScheduleTime,
  getSharelookVoices,
  getIntegrationStatus,
  revokeIntegration,
  resendConfirmationCode,
  getEnglishAudioPreview,
  getNonEnglishAudioPreview,
  stripeConnect,
  verifyPasswordResetCode
}
