import React from 'react'
import { Item } from './styled'

export default props => {
  const { url, exact, heading } = props
  const to = url || '/'

  return (
    <Item>
      <Item.Link to={to} exact={exact}>
        <Item.Inner>
          <Item.Heading>{heading}</Item.Heading>
        </Item.Inner>
      </Item.Link>
    </Item>
  )
}
