import React, { Fragment } from 'react'
import { Col, Input, Row } from 'antd'
import { withTranslation } from 'react-i18next'
import { Filter } from '../../../../../../../../../../components'
import './FilterVideo.scss'

const { Search } = Input

const FilterVideo = props => {
  const { t, type, onSearch, tab, onFilter } = props
  const filterData = [
    { value: 'NEWEST', text: t('filters:Newest_to_Oldest') },
    { value: 'OLDEST', text: t('filters:Oldest_to_Newest') },
    { value: 'ASC', text: t('filters:A_Z') },
    { value: 'DESC', text: t('filters:Z_A') }
  ]

  return (
    <Fragment>
      <div className="filter filter-spacing workshop-head-wrapper">
        <Row>
          <Col span={12}>
            <Search
              placeholder={t('placeholders:search')}
              onChange={e => onSearch(e.target.value, type)}
            />
          </Col>
          <Col span={12}>
            {/*<div className="list">
              <Icon type="unordered-list" /> {t('audio:list_view')}
            </div>*/}
            <div className="sort">
              <Filter
                data={filterData}
                defaultValue={filterData[0].text}
                callback={item => onFilter(item.value, tab)}
                label={t('labels:sort_by')}
              />
            </div>
          </Col>
        </Row>
      </div>
    </Fragment>
  )
}

export default withTranslation()(FilterVideo)
