import React, { Component, createRef } from 'react'
import _ from 'lodash'
import {
  Form,
  Button,
  Input,
  Popconfirm,
  Icon,
  Spin,
  Modal,
  Row,
  Col
} from 'antd'

import { EditImageContainer } from '../../../../../components/TextOnImage'
import { Editor } from '../../../../../components/ImageEditor'
import { PopupMedia } from '../../../../../views/Library/internal'
import {
  Wrapper,
  Main,
  FormItem,
  HR,
  Stage,
  RightAside,
  LeftContent,
  InputContainer,
  InputLabel,
  SLInput,
  ErrorMessage
} from '../../styled'
import { Lessons } from '../../../../../styles/blocks'
import { config, general } from '../../../../../constants'
import {
  SortableContainer,
  SortableElement,
  sortableHandle,
  arrayMove
} from 'react-sortable-hoc'
import { modal_style } from './styled'
import './LessonCreate.scss'
import { withTranslation } from 'react-i18next'
import api from '../../../../../services/api'
import CreatableSelect from 'react-select/creatable'
import ImageSelect from '../../../../../components/Form/ImageSelect/ImageSelect'
const DragHandle = sortableHandle(() => <div className="b-page__drag" />)
const Page = SortableElement(({ children }) => {
  return <li className="b-pages__item">{children}</li>
})

const Pages = SortableContainer(({ children }) => {
  return <ul className="b-pages">{children}</ul>
})

class LessonCreate extends Component {
  constructor(props) {
    super(props)
    this.loadOptions = _.debounce(this.loadOptions, 500)
    this.state = {
      isCover: false,
      isCoverLoading: props.lessonId && true,
      cloneLesson: null,
      options: [],
      value: [],
      inputValue: '',
      isTopicLoading: false,
      isTopicsMax: false,
      newImageUrl: null,
      showTextModal: false,
      selectedContent: '',
      content: '',
      contentHTML: '',
      textPosition: {
        height: 200,
        width: 250,
        x: 23,
        y: 100
      },
      applyChanges: false,
      isImageLoading: false,
      isBackgroundLoading: false,
      coverObj: null,
      backgroundObj: null
    }
    this.imageRef = createRef()
  }

  componentDidMount() {
    const {
      lesson: { selectedLesson, lessonId },
      match: { params }
    } = this.props
    if (params) {
      const { sub_id: id } = params
      const lesson = lessonId || id
      if (lesson) {
        api.lessons.getLessonById(lesson).then(response => {
          const {
            data: { topics }
          } = response
          this.setState({
            coverObj: response.data.cover,
            backgroundObj: response.data.background_cover
          })
          this.getOldTopics(topics)
        })
      }
    }
    if (selectedLesson) {
      this.autogenerateCloneLesson(selectedLesson)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { showTextModal, contentHTML } = this.state
    const div = document.getElementById('showtext')
    if (!showTextModal && contentHTML && div) {
      div.innerHTML = contentHTML
      return div
    }
  }

  autogenerateCloneLesson = selectedLesson => {
    const {
      lessonBuilderActions: { handleLessonInput }
    } = this.props

    const {
      title,
      topics,
      pages,
      description,
      id: lessonId,
      cover: { link, id }
    } = selectedLesson

    const customPages = pages.map(({ data, size, uid, id, ordering }) => ({
      data,
      size,
      uid,
      id,
      ordering
    }))

    handleLessonInput('lessonTitle', `Copy of ${title}`)
    handleLessonInput('lessonTags', topics)
    handleLessonInput('pages', customPages)
    handleLessonInput('lessonDescription', description || '')
    handleLessonInput('lessonImg', link)
    handleLessonInput('lessonId', lessonId)
    handleLessonInput('lessonImgId', id)
    handleLessonInput('saving', false)
  }
  handleAutoSave = () => {
    const { lessonSubmit } = this.refs
    lessonSubmit && lessonSubmit.buttonNode.click()
  }

  handleValue = (field, value) => {
    const { lessonBuilderActions } = this.props
    lessonBuilderActions.handleLessonInput(field, value)
    this.handleAutoSave()
  }

  handleTitle = _.debounce(v => this.handleValue('lessonTitle', v), 1000)
  handleTopics = _.debounce(v => this.handleValue('lessonTags', v), 1000)
  handleDescription = _.debounce(
    v => this.handleValue('lessonDescription', v),
    1000
  )

  onDrop = (
    file,
    isImageBackground,
    handleAfterUpload,
    handlebeforeUpload,
    type
  ) => {
    if (type === 'lessonCover') {
    }
    const image = URL.createObjectURL(file)
    this.handleAfterUpload = handleAfterUpload
    this.handlebeforeUpload = handlebeforeUpload
    this.setState({
      file,
      isImageBackground,
      image
    })
  }

  handleImageSuccess = (res, type) => {
    const { id } = res
    if (id) {
      this.setState(
        {
          [type === 'background' ? 'backgroundObj' : 'coverObj']: res,
          isCover: false,
          isCoverLoading: false,
          newImageUrl: null,
          showTextModal: false,
          selectedContent: '',
          content: '',
          contentHTML: '',
          textPosition: {
            height: 200,
            width: 250,
            x: 23,
            y: 100
          },
          applyChanges: false,
          isBackgroundLoading: false
        },
        () => {
          const {
            lesson: {
              lessonBckgrndImg,
              lessonBckgrndImgId,
              pages,
              pages_landscape
            },
            lessonBuilderActions: { handleLessonInput }
          } = this.props
          const pagesTemp = [...pages]
          const pagesLandscapeTemp = [...pages_landscape]
          let tempPages = []
          pagesTemp.forEach(item => {
            const indexVal = item.data.findIndex(
              item => item.type === 'BACKGROUND_IMAGE_AUTO'
            )
            if (indexVal !== -1 && lessonBckgrndImg !== null) {
              item.data[indexVal].params.content = lessonBckgrndImg
              item.data[indexVal].params.content_id = lessonBckgrndImgId
            }
            tempPages.push(item)
          })

          let tempLndScape = []
          pagesLandscapeTemp.forEach(item => {
            const indexVal = item.data.findIndex(
              item => item.type === 'BACKGROUND_IMAGE_AUTO'
            )
            if (indexVal !== -1 && lessonBckgrndImg !== null) {
              item.data[indexVal].params.content = lessonBckgrndImg
              item.data[indexVal].params.content_id = lessonBckgrndImgId
            }
            tempLndScape.push(item)
          })
          handleLessonInput('pages', tempPages)
          handleLessonInput('pages_landscape', tempLndScape)
          this.handleAfterUpload && this.handleAfterUpload()
        }
      )
    }
    this.handleAutoSave()
  }

  handleUploadImage = file => {
    const {
      upload,
      lessonBuilderActions,
      courseMarketPlaceDescription
    } = this.props
    const { isImageBackground } = this.state
    this.handlebeforeUpload && this.handlebeforeUpload()
    this.setState({
      isCoverLoading: !isImageBackground,
      isBackgroundLoading: isImageBackground
    })
    return upload(file, { category: courseMarketPlaceDescription })
      .then(res => {
        const { id, link } = res
        const { isImageBackground } = this.state
        if (isImageBackground) {
          lessonBuilderActions.handleLessonInput('lessonBckgrndImgId', id)
          lessonBuilderActions.handleLessonInput('lessonBckgrndImg', link)
        } else {
          lessonBuilderActions.handleLessonInput('lessonImgId', id)
          lessonBuilderActions.handleLessonInput('lessonImg', link)
        }
        this.handleImageSuccess(res)
      })
      .catch(err => {
        console.log('upload error: ', JSON.stringify(err))
        this.handleAfterUpload && this.handleAfterUpload()
      })
  }

  handleImageUploadSuccess = (res, type) => {
    const { id, link } = res
    const { lessonBuilderActions } = this.props
    const isImageBackground = type === 'background'
    if (isImageBackground) {
      lessonBuilderActions.handleLessonInput('lessonBckgrndImgId', id)
      lessonBuilderActions.handleLessonInput('lessonBckgrndImg', link)
    } else {
      lessonBuilderActions.handleLessonInput('lessonImgId', id)
      lessonBuilderActions.handleLessonInput('lessonImg', link)
    }
    this.handleImageSuccess(res, type)
  }

  hideModal = () => {
    this.setState({
      modalOpen: false
    })
  }

  handleClosePopup = type => {
    if (type === general.SAVE) {
      this.setState({
        image: null
      })
    } else {
      this.setState({
        image: null,
        file: null
      })
      this.handleAfterUpload()
    }
  }

  hidePreviewModal = () => {
    this.setState({
      selectedItem: null
    })
  }

  handleInsertData = (privateMedia, publicMedia, orgMedia) => {
    let tempData = []

    if (privateMedia) {
      const { rows: privateData } = privateMedia
      privateData.forEach(data => {
        tempData.push(data)
      })
    }

    if (publicMedia) {
      const { rows: publicData } = publicMedia
      publicData.forEach(publicData => {
        tempData.push(publicData)
      })
    }

    if (orgMedia) {
      const { rows: orgData } = orgMedia
      orgData.forEach(orgData => {
        tempData.push(orgData)
      })
    }

    const selectedItem = _.head(tempData.filter(value => value.isSelected))

    this.setState({
      selectedItem
    })
  }

  handleFileInsert = item => {
    const imageEditorInst = this.imageRef.current.imageEditorInst
    const isEdited = !imageEditorInst.isEmptyUndoStack()
    const { isImageBackground } = this.state
    if (isEdited === true) {
      // Upload In this Case
      const data = imageEditorInst.toDataURL()
      const body = {
        croppedImage: data,
        lastModifiedDate: new Date(),
        name: item.filename,
        size: item.file_size,
        type: item.media_mime_type
      }
      this.setState(
        {
          isCoverLoading: !isImageBackground,
          isBackgroundLoading: isImageBackground,
          modalOpen: false,
          selectedItem: null
        },
        () => {
          this.handleUploadImage(body)
        }
      )
    } else {
      // Just Use Data URL
      const { lessonBuilderActions } = this.props
      const { id, link } = item
      const { isImageBackground } = this.state
      if (isImageBackground) {
        lessonBuilderActions.handleLessonInput('lessonBckgrndImgId', id)
        lessonBuilderActions.handleLessonInput('lessonBckgrndImg', link)
      } else {
        lessonBuilderActions.handleLessonInput('lessonImgId', id)
        lessonBuilderActions.handleLessonInput('lessonImg', link)
      }
      this.setState(
        {
          modalOpen: false,
          selectedItem: null
        },
        () => {
          this.handleImageSuccess(item)
        }
      )
    }
  }

  goToLessonPage = number => {
    const {
      lessonBuilderActions: { handleLessonInput },
      history,
      rootPath,
      lesson: { lessonTitle }
    } = this.props

    if (!lessonTitle) {
      return
    }

    handleLessonInput('currentPage', number + 1)
    history.push(`${rootPath}/page-constructor`)
  }

  removeLessonPage = (e, number) => {
    e.stopPropagation()
    const {
      lessonBuilderActions: { handleLessonInput, updateLesson },
      lesson: {
        pages,
        lessonTitle,
        lessonDescription,
        lessonImgId,
        lessonBckgrndImgId,
        lessonTags,
        lessonId,
        pages_landscape
      }
    } = this.props
    const newPages = [...pages]
    const newLandscapePages = [...pages_landscape]

    let data = {}
    if (lessonBckgrndImgId !== null) {
      data = {
        title: lessonTitle,
        description: lessonDescription,
        cover_id: lessonImgId,
        background_id: lessonBckgrndImgId,
        topics: lessonTags || [],
        pages: newPages,
        pages_landscape: newLandscapePages
      }
    } else {
      data = {
        title: lessonTitle,
        description: lessonDescription,
        cover_id: lessonImgId,
        topics: lessonTags || [],
        pages: newPages,
        pages_landscape: newLandscapePages
      }
    }

    newPages.splice(number, 1)
    newLandscapePages.splice(number, 1)
    handleLessonInput('pages', newPages)
    handleLessonInput('pages_landscape', newLandscapePages)
    updateLesson(data, lessonId)
  }

  backToCoursePage = () => {
    const {
      history,
      lessonBuilderActions: { resetLessonBuilder },
      rootPath
    } = this.props

    resetLessonBuilder()

    history.push(rootPath)
  }

  handleSubmit = (e = null, isClone = false) => {
    if (e) {
      e.preventDefault()
    }
    const {
      form,
      lessonBuilderActions: { updateLesson, createLesson },
      courseId,
      lesson: {
        lessonImgId,
        lessonBckgrndImgId,
        lessonBckgrndImg,
        pages,
        pages_landscape,
        lessonTags,
        lessonId,
        saving,
        selectedLesson
      },
      handleLessonClone
    } = this.props

    const { value } = this.state
    form.validateFields((err, values) => {
      if (!lessonImgId) {
        this.setState({ isCover: true })
        return
      }

      if (!err) {
        let lessonData = {}
        if (lessonBckgrndImg !== null) {
          lessonData = {
            ...values,
            cover_id: lessonImgId,
            background_id: lessonBckgrndImgId,
            pages,
            pages_landscape
          }
        } else {
          lessonData = {
            ...values,
            cover_id: lessonImgId,
            pages,
            pages_landscape
          }
        }

        if (isClone) {
          lessonData.topics = lessonTags || []
        } else {
          lessonData.topics = _.map(value, 'id')
        }

        if (lessonId) {
          updateLesson(lessonData, lessonId)
        } else {
          if (!saving) {
            createLesson(lessonData, courseId)
            if (selectedLesson) {
              handleLessonClone(null)
            }
          }
        }
      }
    })
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    const {
      lesson,
      lesson: { pages, lessonId, pages_landscape },
      lessonBuilderActions
    } = this.props
    const newPages = arrayMove(pages, oldIndex, newIndex)
    const newLandscapePages = arrayMove(pages_landscape, oldIndex, newIndex)

    let data = {}
    if (lesson.lessonBckgrndImg !== null) {
      data = {
        title: lesson.lessonTitle,
        description: lesson.lessonDescription,
        cover_id: lesson.lessonImgId,
        background_id: lesson.lessonBckgrndImgId,
        topics: lesson.lessonTags || [],
        pages: lesson.pages,
        pages_landscape: lesson.pages_landscape
      }
    } else {
      data = {
        title: lesson.lessonTitle,
        description: lesson.lessonDescription,
        cover_id: lesson.lessonImgId,
        topics: lesson.lessonTags || [],
        pages: lesson.pages,
        pages_landscape: lesson.pages_landscape
      }
    }

    lessonBuilderActions.handleLessonInput('pages', newPages)
    lessonBuilderActions.handleLessonInput('pages_landscape', newLandscapePages)
    lessonBuilderActions.updateLesson(data, lessonId)
  }

  getOldTopics = topics => {
    const {
      topics: { list: topicList, isFetching }
    } = this.props
    if (!isFetching) {
      const topic = topics.map(item =>
        _.head(topicList.filter(topic => topic.id === item))
      )
      const newValue = topic.map(item => item && this.createOption(item))
      this.setState({
        value: newValue
      })
    }
  }

  handleChange = (newValue, actionMeta) => {
    if (actionMeta.removedValue) {
      this.setState(
        {
          value: newValue,
          inputValue: '',
          isTopicLoading: false,
          isTopicsMax: false
        },
        () => {
          this.handleSubmit()
          this.handleValue('lessonTags', _.map(newValue, 'id'))
        }
      )
    } else if (!_.isEmpty(newValue) && newValue.length > 3) {
      this.setState({
        isTopicsMax: true
      })
    } else {
      this.setState(
        {
          value: newValue,
          inputValue: '',
          isTopicLoading: false,
          isTopicsMax: false
        },
        () => {
          this.handleSubmit()
          this.handleValue('lessonTags', _.map(newValue, 'id'))
        }
      )
    }
    this.isModalSelectOpen()
  }
  handleCreate = inputValue => {
    this.setState({ isTopicLoading: true })
    const { options, value } = this.state
    const topic = { title: inputValue }
    if (!_.isEmpty(value) && value.length >= 3) {
      this.setState({
        isTopicsMax: true,
        isTopicLoading: false
      })
    } else {
      api.topics.createTopics(topic).then(({ data }) => {
        if (!_.isEmpty(data[0])) {
          const newOption = this.createOption(data[0])
          this.props.updateTopicList(data[0])

          this.setState(
            {
              isTopicLoading: false,
              options: [...options, newOption],
              isTopicsMax: false,
              value: [...value, newOption],
              inputValue: ''
            },
            () => {
              this.handleSubmit()
              this.handleValue('lessonTags', _.map(this.state.value, 'id'))
            }
          )
        }
      })
    }
    this.isModalSelectOpen()
  }

  createOption = topic => {
    const option = {
      label: topic.title,
      value: topic.title,
      id: topic.id
    }
    return option
  }

  createOptionList = list => {
    const options = list.map(topic => this.createOption(topic))
    this.setState({
      options,
      isTopicLoading: false
    })
  }

  loadOptions = inputValue => {
    if (inputValue.length > 0 && inputValue.length <= 50) {
      this.setState({
        isTopicLoading: true
      })
      const params = {
        search: inputValue.trim(),
        limit: 10,
        offset: 0
      }
      api.topics.getTopics(params).then(({ data }) => {
        this.setState({
          inputValue
        })
        this.createOptionList(data)
      })
    }
  }

  LoadingIndicator = () => (
    <Icon style={{ paddingRight: '10px' }} type="loading" />
  )

  isModalSelectOpen = () => {
    const { inputValue, value, isTopicsMax } = this.state
    return !_.isEmpty(value) && value.length >= 3 && isTopicsMax
      ? false
      : inputValue.length > 0
  }

  getEditedImg = (image, content, textPosition) => {
    const canvas = document.createElement('canvas')
    canvas.width = image.clientWidth
    canvas.height = image.clientHeight
    const srcLink = image.style.backgroundImage.split('"')[1]
    canvas.setAttribute('crossorigin', 'anonymous')
    const ctx = canvas.getContext('2d')
    const imageObj = new Image()
    imageObj.crossOrigin = 'anonymous'
    imageObj.src = srcLink
    imageObj.onload = () => {
      ctx.drawImage(imageObj, 0, 0)

      if (content && content.length > 0) {
        let font = '8px Arial'
        let color = 'black'
        let x = textPosition.x
        let y = textPosition.y
        let newYPixel = 10

        if (content.length === 1) {
          const str = content[0].insert.split('\n')
          str.forEach(s => {
            ctx.font = font
            ctx.fillStyle = color
            ctx.fillText(s, x, y)
            y = y + 10
            x = textPosition.x
          })
        } else {
          content.forEach(each => {
            let attributes = []
            if (each.attributes) {
              attributes = Object.entries(each.attributes)
            }
            attributes.length > 0 &&
              attributes.forEach(a => {
                if (a[0] === 'size') {
                  font = `${a[1]} Arial`
                  const px = a[1].split('px')
                  newYPixel =
                    parseInt(px[0]) > 15
                      ? newYPixel + parseInt(px[0])
                      : newYPixel
                } else if (a[0] === 'color') {
                  color = a[1]
                } else {
                  const newFont = font.replace(/^/, `${a[0]} `)
                  font = newFont
                }
              })

            ctx.font = font
            ctx.fillStyle = color
            ctx.fillText(each.insert, x, y)
            const textWidth = ctx.measureText(each.insert)
            if (each.insert.match(/\n/g)) {
              y = y + newYPixel
              x = textPosition.x
            } else {
              x = x + textWidth.width
            }
          })
        }
      }
      this.fileUrl = canvas.toDataURL('image/jpeg')
      this.setState({ newImageUrl: this.fileUrl })
    }
  }

  handleShowTextModal = () => {
    this.setState({ showTextModal: !this.state.showTextModal })
  }

  handleAddText = async () => {
    const { applyChanges, textPosition, selectedContent } = this.state
    if (!applyChanges) {
      this.setState({ applyChanges: true }, () => {
        this.handleShowTextModal()
      })
    } else {
      this.getEditedImg(this.imageRef.current, selectedContent, textPosition)
    }
  }

  handleCloseTextModal = () => {
    this.setState(
      () => {
        if (this.state.contentHTML) {
          return {
            applyChanges: true
          }
        } else {
          return { applyChanges: false }
        }
      },
      () => {
        this.handleShowTextModal()
      }
    )
  }

  handleTextAdded = () => {
    this.handleShowTextModal()
  }

  setTextContent = (content, delta, source, editor) => {
    const selectedContent = editor.getContents().ops
    const contentHTML = editor.getHTML()

    this.setState({
      content: content,
      selectedContent,
      contentHTML
    })
  }

  useCanva = type => {
    var script = document.createElement('script')
    const url = 'https://sdk.canva.com/designbutton/v2/api.js'
    script.src = url
    script.onload = () => {
      if (window.Canva && window.Canva.DesignButton) {
        window.Canva.DesignButton.initialize({
          apiKey: config.canvaKey
        }).then(api => {
          api.createDesign({
            design: {
              type: type
            },
            onDesignPublish: ({ exportUrl, designTitle }) => {
              this.handleImageUrl(exportUrl, designTitle, type)
            }
          })
        })
      }
    }
    document.body.appendChild(script)
  }

  handleImageUrl = async (url, title) => {
    let blob = await fetch(url).then(r => r.blob())
    let data = await new Promise(resolve => {
      let reader = new FileReader()
      reader.onload = () => resolve(reader.result)
      reader.readAsDataURL(blob)
    })
    const body = {
      croppedImage: data,
      lastModifiedDate: new Date(),
      name: title,
      size: blob.size,
      type: blob.type
    }
    this.handleUploadImage(body)
  }
  handleOpenCanva = e => {
    if (e) {
      e.stopPropagation()
    }
    this.setState(
      {
        isImageBackground: false
      },
      () => {
        this.useCanva('Presentation')
      }
    )
  }

  render() {
    const {
      lesson: {
        lessonTitle,
        lessonDescription,
        lessonImg,
        lessonImgId,
        lessonBckgrndImgId,
        lessonBckgrndImg,
        pages,
        lessonId,
        saving,
        fetch,
        lessonBckgrndImgName,
        lessonImgName,
        lessonBckgrndImgSize,
        lessonImgSize
      },
      form: { getFieldDecorator },
      history,
      rootPath,
      t
    } = this.props
    const {
      isCover,
      isCoverLoading,
      value,
      options,
      isTopicLoading,
      isTopicsMax,
      selectedItem,
      modalOpen,
      image,
      file,
      coverObj,
      backgroundObj
    } = this.state

    const selectInput = document.getElementById('react-select-2-input')
    if (selectInput) {
      selectInput.maxLength = 50
    }
    const customStyles = {
      container: provided => ({
        ...provided,
        width: '40.5rem'
      }),
      control: provided => ({
        ...provided,
        borderRadius: '0px',
        maxWidth: '40.5rem',
        transition: 'transparent 0.3s cubic-bezier(0.215, 0.61, 0.355, 1)'
      }),
      menu: provided => ({
        ...provided,
        maxHeight: '185px'
      }),
      menuList: provided => ({
        ...provided,
        maxHeight: '185px'
      })
    }
    return (
      <Spin className="global_ant_spin" spinning={fetch}>
        <Wrapper>
          <Form onSubmit={this.handleSubmit}>
            <Main>
              <div className="head">
                <div className="title">{t('title')}</div>
                <div className="subtitle">{t('subtitle')}</div>
              </div>

              <Stage>
                <div className="lesson-create-main-wrap">
                  <Row justify="center" align="middle" gutter={[24, 0]}>
                    <Col xs={24} sm={24} md={14} lg={18}>
                      <Row justify="center" align="middle" gutter={[24, 24]}>
                        <Col span={24}>
                          <div className="lesson-create-main-inner">
                            <FormItem
                              label={
                                <span style={{ fontSize: 12 }}>
                                  {t('input_label_title').toUpperCase()}
                                </span>
                              }
                              colon={false}
                              required={false}
                            >
                              <InputContainer>
                                {getFieldDecorator('title', {
                                  rules: [
                                    {
                                      required: true,
                                      message: t('errors:title_lesson')
                                    },
                                    {
                                      pattern: '.{3,}',
                                      message: t('errors:min_3char')
                                    }
                                  ],
                                  initialValue: lessonTitle
                                })(
                                  <SLInput
                                    size="large"
                                    maxLength={100}
                                    onChange={e =>
                                      this.handleTitle(e.target.value)
                                    }
                                    placeholder="Create lession"
                                  />
                                )}
                              </InputContainer>
                            </FormItem>
                            <div className="lesson-create-box">
                              <div>
                                <div className="lession-topic-label">
                                  {t('labels:topics').toUpperCase()}
                                </div>
                                <CreatableSelect
                                  styles={customStyles}
                                  components={{
                                    ClearIndicator: null,
                                    DropdownIndicator: null,
                                    LoadingIndicator: this.LoadingIndicator
                                  }}
                                  isMulti
                                  placeholder="Topics"
                                  inputId="react-select-2-input"
                                  isLoading={isTopicLoading}
                                  menuIsOpen={this.isModalSelectOpen()}
                                  onBlur={() =>
                                    this.setState({ inputValue: '' })
                                  }
                                  onChange={this.handleChange}
                                  onCreateOption={this.handleCreate}
                                  onInputChange={this.loadOptions}
                                  options={options}
                                  value={value}
                                />
                              </div>
                              {isTopicsMax && (
                                <div className="error_message">
                                  {t('errors:topics_max')}
                                </div>
                              )}
                            </div>
                          </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                          <ImageSelect
                            key={'lessonUploadcoverImg'}
                            required
                            form={this.props.form}
                            label={t('v2:lesson_cover')}
                            coverInput={coverObj}
                            onCoverChange={a =>
                              this.handleImageUploadSuccess(a)
                            }
                          />
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                          <ImageSelect
                            isBackground
                            key={'lessonUploadbackgroundImg'}
                            required={false}
                            form={this.props.form}
                            label={t('v2:page_bg')}
                            coverInput={backgroundObj}
                            onCoverChange={a =>
                              this.handleImageUploadSuccess(a, 'background')
                            }
                          />
                        </Col>
                        <Col span={24}>
                          <FormItem>
                            <InputLabel vertical>
                              {t('input_label_description')}
                            </InputLabel>
                            {getFieldDecorator('description', {
                              initialValue: lessonDescription
                            })(
                              <Input.TextArea
                                placeholder={t(
                                  'placeholders:lesson_description'
                                )}
                                rows={4}
                                onKeyDown={e => {
                                  if (
                                    e.keyCode === 32 &&
                                    (e.target.value.charAt(
                                      e.target.selectionStart - 1
                                    ) === ' ' ||
                                      e.target.value.charAt(
                                        e.target.selectionStart
                                      ) === ' ')
                                  ) {
                                    e.preventDefault()
                                  }
                                }}
                                onChange={e =>
                                  this.handleDescription(e.target.value)
                                }
                                style={{
                                  marginBottom: '0',
                                  resize: 'none'
                                }}
                              />
                            )}
                          </FormItem>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={24} sm={24} md={10} lg={6}>
                      <Lessons>
                        <Lessons.Count left darkBorder>
                          {t('general:pages')} ({pages.length})
                        </Lessons.Count>

                        <Lessons.List dashed>
                          <Pages
                            useDragHandle
                            onSortEnd={this.onSortEnd}
                            helperClass="_drag"
                          >
                            {pages.map((page, index) => (
                              <Page key={`item-${index}`} index={index}>
                                <div className="b-page">
                                  <div className="b-page__handler">
                                    <DragHandle />
                                  </div>
                                  <div
                                    className="b-page__info"
                                    onClick={() => this.goToLessonPage(index)}
                                  >
                                    <div className="b-page__name">
                                      {t('general:page')}{' '}
                                      {page.ordering || index + 1}
                                    </div>
                                    <div className="b-page__control">
                                      <Popconfirm
                                        title={t('delete_warning')}
                                        okText={t('general:yes')}
                                        cancelText={t('general:no')}
                                        placement="leftTop"
                                        onClick={e => e.stopPropagation()}
                                        onCancel={e => e.stopPropagation()}
                                        onConfirm={e =>
                                          this.removeLessonPage(e, index)
                                        }
                                        icon={false}
                                      >
                                        <Icon type="delete" />
                                      </Popconfirm>
                                    </div>
                                  </div>
                                </div>
                              </Page>
                            ))}
                          </Pages>
                          <Row type="flex" align="middle" justify="center">
                            <Col>
                              <Button
                                type="primary"
                                className="rounded"
                                style={{ marginTop: '2rem' }}
                                onClick={() =>
                                  history.push(`${rootPath}/page-builder`)
                                }
                                disabled={
                                  !lessonId ||
                                  saving ||
                                  isCoverLoading ||
                                  !lessonTitle
                                }
                              >
                                {t('buttons:add_page')}
                              </Button>
                            </Col>
                          </Row>
                        </Lessons.List>
                      </Lessons>
                    </Col>
                  </Row>
                </div>
              </Stage>
            </Main>

            <div className="p-footer">
              <div className="p-footer__inner">
                <div className="p-footer__item">
                  <Button className="rounded" onClick={this.backToCoursePage}>
                    {t('buttons:back_to_course')}
                  </Button>
                </div>
                <div className="p-footer__item">
                  <Button
                    className="rounded"
                    htmlType="submit"
                    type="primary"
                    ref="lessonSubmit"
                    loading={saving}
                  >
                    {`${lessonId ? t('buttons:update') : t('buttons:add')} ${t(
                      'general:lesson'
                    )}`}
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </Wrapper>
        {image && (
          <EditImageContainer
            image={image}
            handleUploadImage={this.handleUploadImage}
            handleClosePopup={this.handleClosePopup}
            file={file}
          />
        )}
        {modalOpen && (
          <Modal
            visible={modalOpen}
            onCancel={this.hideModal}
            footer={null}
            width={'80%'}
            style={modal_style}
          >
            <div className="wrap_modal_tabs">
              <PopupMedia
                isPageBuilder
                handleInsertData={this.handleInsertData}
                types="images"
              />
            </div>
            {selectedItem && (
              <Modal
                visible={selectedItem}
                onCancel={this.hidePreviewModal}
                footer={null}
                width={'85%'}
              >
                <Editor library={selectedItem} ref={this.imageRef} />
                <div className="wrap_btn_actions">
                  <Button
                    type="danger"
                    size="large"
                    className="action_button"
                    onClick={this.hidePreviewModal}
                  >
                    {general.CANCEL}
                  </Button>
                  <Button
                    type="primary"
                    size="large"
                    className="action_button"
                    onClick={() => this.handleFileInsert(selectedItem)}
                  >
                    {general.INSERT}
                  </Button>
                </div>
              </Modal>
            )}
          </Modal>
        )}
      </Spin>
    )
  }
}

export default Form.create()(withTranslation('lessoncreate')(LessonCreate))
