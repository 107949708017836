import styled from 'styled-components'
import { styleguide } from '../../../../../constants'

const { colors } = styleguide

const Types = styled.div``

Types.Heading = styled.div`
  font-weight: 500;
  font-size: 2.8rem;
  margin: 4rem;
`

Types.List = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -1.6rem;
  margin-right: -1.6rem;
`

Types.Item = styled.div`
  width: 25%;
  padding: 0 1.6rem;
`

const Type = styled.div`
  padding-bottom: 3.2rem;
  cursor: pointer;
`

Type.Title = styled.div`
  font-weight: 300;
  font-size: 1.2rem;
  margin-bottom: .4rem;
  transition: color .2s;

  ${Type}:hover & {
    color: ${colors.blue};
  }
`

Type.Content = styled.div`
  border: .2rem solid ${colors.darkGreyOne};
  overflow: hidden;
  background: ${colors.white};
  transition: border-color .2s, box-shadow .2s;
  box-shadow: 0 .2rem .5rem rgba(0, 0, 0, 0.05);

  ${Type}:hover & {
    border-color: ${colors.blue};
    box-shadow: 0 .3rem .8rem rgba(0, 0, 0, 0.15);
  }
`

Type.Thumb = styled.img`
  display: block;
  width: 100%;
  height: auto;
 
`

export {
  Types,
  Type
}
