import {CmiCore} from './CmiCore.js'

import { ScormSco } from './Scorm_Sco.js'

export class Activity{
	cmiCore
	cmiData

	scormSco
	hasDirtyData = false
	
	childActivities = null
	menuItem

	constructor(data){
		if(data){
			if(data.cmi_core){
				this.cmiCore = new CmiCore(data.cmi_core)
			}
			this.scormSco = new ScormSco(data.scorm_sco)
		}

		console.log('cmiCore', this.cmiCore, 'scormSco', this.scormSco, 'data', data )
		this.childActivities = []
	}


	setHasDirtyData(){
		this.hasDirtyData = true
	}

	setDirtyDataPosted(){
		this.hasDirtyData = false
	}

	getTitle(){
		return this.scormSco ? this.scormSco.title : ""
	}

	getIdentifier(){
		return this.scormSco ? this.scormSco.identifier : ""
	}

	getChildren(){
		return this.childActivities
	}

	getScormType(){
		return this.scormSco ? this.scormSco.scormtype : ""
	}

	getScoId(){
		return this.scormSco ? this.scormSco.id : ""
	}

	getLaunch(){
		console.log('this.scormSco', this.scormSco)
		return this.scormSco ? this.scormSco.href + (this.scormSco ? this.scormSco.parameters : "") : ""
	}
}