import { BADGE_GET_BY_ID } from '../types'

const initialState = {
  list: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    case BADGE_GET_BY_ID:
      return {
        ...state,
        list: action.payload
      }

    default:
      return state
  }
}
