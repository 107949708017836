import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'

import {
  Privacy,
  TermsConditions
} from './internal'

class Pages extends Component {
  render () {
    return (
      <Switch>
        <Route path='/pages/privacy' render={(props) => <Privacy {...props} />}/>
        <Route path='/pages/terms-conditions' render={(props) => <TermsConditions {...props} />}/>
      </Switch>
    )
  }
}

export default Pages
