import styled from 'styled-components'
import { Input } from 'antd'

import { styleguide } from '../../constants'

import 'antd/lib/button/style/'

import IconSearch from './images/search.svg'

const { base } = styleguide

export const SearchInput = styled(Input.Search)`
  && {
    width: 100%;
    height: 4rem;
    margin:${(props) => props.indent};

    input {
      font-weight: ${base.fontWeight};
      font-size: ${base.fontSize};
      font-style: normal;
      border-radius: 2rem;
      border-color: transparent;
      padding: 1.5rem;
      background-color: #F7F7F7;

      &::-webkit-input-placeholder {
        font-style: italic;
      }
    }

    .ant-input-suffix {
      width: 2rem;
      height: 2rem;
      background: url(${IconSearch}) center center no-repeat;
      background-size: contain;

      i {
        display: none;
      }
    }
  }
`

export const SearchRectangular = styled(Input.Search)`
  && {
    width: 100%;
    height: 4rem;
    margin:${(props) => props.indent};

    input {
      font-weight: ${base.fontWeight};
      font-size: ${base.fontSize};
      font-style: normal;
      border-color: transparent;
      border-radius: 0.6rem;
      padding: 1.5rem;
      border: 1px solid #B9B9B9;
      &::-webkit-input-placeholder {
        font-style: italic;
      }
    }

    .ant-input-suffix {
      width: 2rem;
      height: 2rem;
      background: url(${IconSearch}) center center no-repeat;
      background-size: contain;

      i {
        display: none;
      }
    }
  }
`
