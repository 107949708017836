import { connect } from 'react-redux'

import actions from '../../../../store/actions'

import Category from './Category'

export default connect(
  state => ({
    organizations: state.organizations,
    user: state.user.info
  }),
  dispatch => ({
    getCategories: () =>
      dispatch(actions.organizations.getCategories()),
    deleteCategory: (id) =>
      dispatch(actions.organizations.deleteCategory(id)),
    createCategory: (data) =>
      dispatch(actions.organizations.createCategory(data)),
    updateCategory: (id, data) =>
      dispatch(actions.organizations.updateCategory(id, data)),
    searchCategory: data => dispatch(actions.organizations.searchCategory(data))
  })
)(Category)
