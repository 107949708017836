import React, { Component, Fragment } from 'react'
import {
  ReportTable,
  HeaderTabs,
  CustomTabPane,
  PaginationRightAligned,
  SearchEventMargin
} from '../../styled'
import { Icon, Tabs, Row, Pagination, Col, Divider } from 'antd'
import { Search } from '../../../../components'
import { generateCSV } from '../../../../utils'
import moment from 'moment'
import { isSafari } from 'react-device-detect'
import { report } from '../../../../constants'
import _ from 'lodash'
import { withTranslation } from 'react-i18next'
import ReportHeader from '../../components/ReportHeader'
import TypeSelect from '../../components/TypeSelect'

const { TabPane } = Tabs
const columns = t => [
  {
    title: t('reports:course'),
    dataIndex: 'cover',
    render: cover => {
      return (
        <img
          style={{ width: '70px', height: '50px' }}
          src={cover.resizes && cover.resizes.thumbnail}
          alt="cover"
        />
      )
    },
    width: 70
  },
  {
    dataIndex: 'title',
    textWrap: 'word-break'
  },
  // {
  //   title: t('v4:type'),
  //   dataIndex: 'course_type',
  // },
  {
    title: t('reports:created_date'),
    dataIndex: 'created_at',
    render: x => {
      return moment(x).format('ll')
    },
    sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
    sortDirections: ['descend', 'ascend']
  },
  {
    title: t('reports:status'),
    dataIndex: 'status',
    sorter: (a, b) =>
      a.status ? a.status.length : '' - b.status ? b.status.length : '',
    sortDirections: ['descend', 'ascend']
  },
  // {
  //   title: t('reports:participants'),
  //   dataIndex: 'participants',
  // },
  {
    title: t('reports:lessons'),
    dataIndex: 'lessons',
    sorter: (a, b) => a.lessons - b.lessons,
    sortDirections: ['descend', 'ascend']
  },

  {
    title: t('reports:viewers'),
    dataIndex: 'viewers',
    sorter: (a, b) => a.viewers - b.viewers,
    sortDirections: ['descend', 'ascend']
  },
  {
    title: t('reports:completed'),
    dataIndex: 'completed',
    sorter: (a, b) => a.completed - b.completed,
    sortDirections: ['descend', 'ascend']
  }
  // {
  //     title: 'Quize',
  //     dataIndex: 'quize',
  //
  // },
  // {
  //     title: 'Survey',
  //     dataIndex: 'survey',
  //
  // }
]

const dateType = {
  allTime: '5',
  today: '1',
  week: '7',
  month: '30',
  year: '12',
  other: '2'
}

const reverseDateType = {
  5: 'all',
  1: 'today',
  7: 'week',
  30: 'month',
  12: 'year',
  2: 'other'
}

class Preview extends Component {
  _isMount = false
  state = {
    page: report.PAGE_NUMBER,
    pageSize: report.PAGE_SIZE,
    // hasFetched: true,
    search: '',
    sortBy: '',
    workshopDateType: '5',
    workshopSearchType: '',
    // enableCustomDateSelect: false,
    dateTo: null,
    dateFrom: null,
    types: '',
    position: ['left', 'right']
  }

  componentDidMount() {
    const { pageSize, page } = this.state
    this._isMount = true

    const state = this.props.history.location.state

    if (state && state.searchType) {
      this.setState(
        {
          workshopDateType: dateType[state.searchType],
          workshopSearchType: state.searchType,
          dateFrom: state.dateFrom,
          dateTo: state.dateTo
        },
        () => {
          if (state.dateFrom && state.dateTo !== '') {
            this.fetchData()
          } else {
            this.handleSortByChange(dateType[state.searchType])
          }
        }
      )
      return
    }

    // if (state && state.searchType) {
    //   this.setState(
    //     {
    //       workshopDateType: dateType[state.searchType],
    //       workshopSearchType: state.searchType
    //     },
    //     () => {
    //       this.handleSortByChange(dateType[state.searchType])
    //     }
    //   )
    //   return
    // }

    // this.setState(
    //   { hasFetched: false }, () => {
    this.props.getReport({
      type: 'courseLists',
      offset: this.createOffset(page, pageSize),
      limit: pageSize,
      date: 'all',
      search: '',
      from: '',
      to: ''
    })
    // })
  }

  // componentDidUpdate(prevProps) {
  //   if (prevProps.data.courseLists.list !== this.props.data.courseLists.list) {
  //     this.setState({ hasFetched: true })
  //   }
  // }

  download = () => {
    const { list } = this.props.data.courseLists
    const courses = list && list.courses
    const dataCSV = courses.map(x => {
      return {
        id: x.id,
        title: x.title,
        created_at: moment(x.created_at).format('YYYY-MM-DD'),
        status: x.status,
        lessons: x.lessons,
        views: x.viewers,
        Completed: x.completed
      }
    })
    const header = [
      '#',
      'Title',
      'Created at',
      'Status',
      'Lesson',
      'Views',
      'Completed'
    ]
    if (!isSafari) {
      let ele = document.createElement('a')
      ele.setAttribute(
        'href',
        'data:text/csv;charset=utf-8,' +
          encodeURIComponent(generateCSV(dataCSV, header))
      )
      ele.setAttribute('download', 'course-report.csv')
      document.body.appendChild(ele)
      ele.click()
      // generateCSV(dataCSV, header)
    } else {
      let ele = document.createElement('a')
      ele.setAttribute(
        'href',
        'data:text/csv;charset=utf-8,' +
          encodeURIComponent(generateCSV(dataCSV, header))
      )
      ele.setAttribute('download', 'course-report.csv')
      ele.click()
    }
  }

  onRowSelect(selectedRecord) {
    this.setState({ selectedRecord })
  }

  createOffset = (page, pageSize) => page * pageSize - pageSize

  onChangePagination = page => {
    const {
      pageSize,
      workshopSearchType,
      search,
      workshopDateType,
      dateFrom,
      dateTo
    } = this.state
    this.setState(
      {
        page: page,
        pageSize: pageSize
        // hasFetched: false
      },
      () => {
        this.props.getReport({
          type: 'courseLists',
          offset: this.createOffset(page, pageSize),
          limit: pageSize,
          ...(workshopSearchType && {
            date: workshopSearchType === 'allTime' ? 'all' : workshopSearchType
          }),
          search: search,
          date:
            reverseDateType[workshopDateType] === 'other'
              ? ''
              : reverseDateType[workshopDateType],
          from: reverseDateType[workshopDateType] === 'other' ? dateFrom : '',
          to: reverseDateType[workshopDateType] === 'other' ? dateTo : ''
        })
      }
    )
  }

  componentWillUnmount() {
    this._isMount = false
  }

  onSearch = _.debounce(value => {
    if (this._isMount) {
      const { sortBy, dateFrom, dateTo } = this.state

      this.setState({ search: value }, () => {
        this.props.getReport({
          type: 'courseLists',
          // offset: this.createOffset(page, pageSize),
          // limit: pageSize,
          offset: 0,
          limit: 10,
          search: value,
          date: sortBy === 'other' ? '' : sortBy,
          from: sortBy === 'other' ? dateFrom : '',
          to: sortBy === 'other' ? dateTo : ''
        })
      })
    }
  }, 1000)

  handleSortByChange(value) {
    const { search, pageSize, page } = this.state
    const defaultProps = {
      type: 'courseLists',
      offset: this.createOffset(page, pageSize),
      limit: pageSize,
      date: 'today',
      search: search,
      from: '',
      to: ''
    }
    if (value === '1') {
      if (this._isMount) {
        this.setState(
          {
            sortBy: 'today',
            workshopDateType: value
            // enableCustomDateSelect: false,
          },
          () => {
            this.props.getReport({
              ...defaultProps
            })
          }
        )
      }
    } else if (value === '7') {
      if (this._isMount) {
        this.setState(
          {
            sortBy: 'week',
            workshopDateType: value
            // enableCustomDateSelect: false,
          },
          () => {
            this.props.getReport({
              ...defaultProps,
              date: 'week'
            })
          }
        )
      }
    } else if (value === '30') {
      if (this._isMount) {
        this.setState(
          {
            sortBy: 'month',
            workshopDateType: value
            // enableCustomDateSelect: false,
          },
          () => {
            this.props.getReport({
              ...defaultProps,
              date: 'month'
            })
          }
        )
      }
    } else if (value === '12') {
      if (this._isMount) {
        this.setState(
          {
            sortBy: 'year',
            workshopDateType: value
            // enableCustomDateSelect: false,
          },
          () => {
            this.props.getReport({
              ...defaultProps,
              date: 'year'
            })
          }
        )
      }
    } else if (value === '5') {
      if (this._isMount) {
        this.setState(
          {
            sortBy: 'all',
            workshopDateType: value
            // enableCustomDateSelect: false,
          },
          () => {
            this.props.getReport({
              ...defaultProps,
              date: 'all'
            })
          }
        )
      }
    } else if (value === '2') {
      if (this._isMount) {
        this.setState({
          sortBy: 'other',
          workshopDateType: value
          // enableCustomDateSelect: true,
        })
      }
    }
  }

  handleDate = val => {
    this.setState(
      {
        dateFrom: val[0],
        dateTo: val[1]
      },
      this.fetchData
    )
  }

  fetchData = () => {
    const { search, pageSize, page, dateFrom, dateTo } = this.state
    this.props.getReport({
      type: 'courseLists',
      offset: this.createOffset(page, pageSize),
      limit: pageSize,
      date: '',
      from: dateFrom,
      to: dateTo,
      search: search
    })
  }

  callbackTabClicked = (key, event) => {
    console.log(key)
    console.log(event)
    if (key === '0') {
      this.props.history.push(`/reports`)
    } else if (key === '2') {
      console.log('propsss,', this.props)
      // history.push('/[pathToSomeWhere]', yourData);
      this.props.history.push('/reports', 'user')
      console.log('tab 2 clicked...')
    } else if (key === '3') {
      this.props.history.push('/reports', 'marketplace')
    }
  }

  // handleSearch = () => {
  //   const { search, pageSize, page, dateFrom, dateTo} = this.state
  //   this.setState(
  //     {
  //       // workshopDateType: null
  //       enableCustomDateSelect: true,
  //       sortBy:'other'
  //     },
  //     () =>
  //         this.props.getReport({
  //         type: 'courseLists',
  //         offset: this.createOffset(page, pageSize),
  //         limit: pageSize,
  //         date: '',
  //         from:dateFrom,
  //         to:dateTo,
  //         search: search
  //     })
  //   )
  // }

  render() {
    const { page, pageSize, workshopDateType, dateFrom, dateTo } = this.state
    const {
      t,
      user: {
        info: { organization }
      }
    } = this.props
    const { list, fetch } = this.props.data.courseLists
    console.log('courseprops', this.props)
    console.log(fetch, 'tttt.....>')
    const courses = list && list.courses
    const total = list && list.pagination ? list.pagination.total : 0
    return (
      <Fragment>
        <div className="content-warp">
          <div
            className="report-warp sharelook-table"
            style={{ direction: 'ltr' }}
          >
            {/* <PageHeaderReport
              backIcon={<Icon type="left" />}
              onBack={e => this.props.history.push('/')}
              title={'Course'}
              extra={[
                <CustomDownloadButton>
                  <button key="1" onClick={this.download}>
                    Report Download
                  </button>
                </CustomDownloadButton>
              ]}
            /> */}
            <HeaderTabs
              defaultActiveKey="1"
              animated={false}
              onChange={this.changeTab}
              onTabClick={this.callbackTabClicked}
              // onTabClick={key => {
              //   if (key === '0') {
              //     this.props.history.push(`/reports`)
              //   }
              // }}
              tabBarExtraContent={
                <ReportHeader
                  // backIcon={<Icon type="left" />}
                  // onBack={e => this.props.history.push('/')}
                  workshopDateType={workshopDateType}
                  dateChange={value => this.handleSortByChange(value)}
                  download={this.download}
                  handleDate={(date, dateString) => this.handleDate(dateString)}
                  // backToDashboard={e => this.props.history.push('/reports')}
                  dateFrom={dateFrom}
                  dateTo={dateTo}
                />
              }
            >
              <TabPane
                tab={
                  <CustomTabPane>
                    <Icon type="left" style={{ fontSize: '18px' }} />
                  </CustomTabPane>
                }
                key="0"
              />
              <TabPane tab={t('reports:workshop_report')} key="1">
                <React.Fragment>
                  <TypeSelect
                    defaultValue="courses"
                    enableMaze={organization && organization.enable_maze}
                  />

                  {/* {!hasFetched ? (
                    <Spinner />
                  ) : ( */}
                  <Row>
                    <ReportTable
                      rowKey="id"
                      columns={columns(t)}
                      dataSource={courses}
                      pagination={false}
                      loading={fetch}
                      borderRadius
                      /* pagination={{
                            itemRender: RenderArrows,
                            pageSize: records,
                            onChange: (e) => this.onChangePage()
                          }} */
                      onRow={record => {
                        return {
                          onClick: () =>
                            this.props.history.push(
                              `/reports/course/` + record.id
                            )
                        }
                      }}
                      title={() => (
                        <>
                          <Row
                            className="sharelook-course-wrapper"
                            style={{ marginLeft: '30px', marginTop: '3px' }}
                          >
                            <Col span={5}>
                              <SearchEventMargin>
                                <Search
                                  placeholder={t('reports:search_course')}
                                  onChange={e => this.onSearch(e.target.value)}
                                />
                              </SearchEventMargin>
                            </Col>
                            <Col span={11} />
                            <Col className="report-title" span={3} push={1}>
                              {t('v4:total')} {total} {t('v4:report_courses')}
                            </Col>
                            <Col span={4} style={{ marginTop: '14px' }}>
                              <PaginationRightAligned margin>
                                <Pagination
                                  total={total}
                                  current={page}
                                  pageSize={pageSize}
                                  onChange={this.onChangePagination}
                                  simple
                                />
                              </PaginationRightAligned>
                            </Col>
                          </Row>
                          <Divider style={{ margin: '0px' }} />
                        </>
                      )}
                    />
                  </Row>
                  {/* )}  */}
                </React.Fragment>
              </TabPane>
              <TabPane tab={t('reports:user_report')} key="2"></TabPane>
              <TabPane tab={t('v4:marketplace_report')} key="3"></TabPane>
            </HeaderTabs>
          </div>
        </div>
      </Fragment>
    )
  }
}
export default withTranslation('report')(Preview)
