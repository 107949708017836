import http from './http'

const getMarketplaceReport = params => {
  return http({
    method: 'GET',
    url: `reports/product/owner?type=workshop&offset=0&limit=6&date=allTime&from=&to=`
  })
}

const getMarketplaceCourseReport = (id, type) => {
  return http({
    method: 'GET',
    url: 'reports/product/owner?type=course&offset=0&limit=10',
    params: {
      type
    }
  })
}

export { getMarketplaceReport, getMarketplaceCourseReport }
