import styled from 'styled-components'
import { Select, DatePicker } from 'antd'

const Page = styled.div`
  padding: 4rem;
`

Page.Title = styled.div`
  font-weight: 600;
  font-size: 20px;
  color: #2e70f2;
  display: flex;
  margin-bottom: 25px;
`

Page.Title.SubText = styled.div`
  color: #000000;
  margin-left: 10px;
`

Page.Select = styled(Select)`
  border-radius: 10px;
  width: 185px;
  height: 28px;
  margin: 0 15px;
`
Page.SelectContainer = styled.div`
  width: 100%;
  display: flex;
  height: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 10px;
`
const Row = styled.div`
  width: 750px;
  display: flex;
`
const EmailButton = styled.button`
  padding: 5px 20px;
  cursor: pointer;
  background: white;
  border: 2px solid #2e70f2;
  border-radius: 20px;
  color: #2e70f2;
  &:focus {
    outline: none;
  }
`

Page.DatePicker = styled(DatePicker)`
  width: 125px;
  font-size: 12px;
  margin-right: 15px !important;
`
Page.SearchContainer = styled.div`
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  border-radius: 100%;
  background: #f4f4f4;
  color: #2e70f2;
  cursor: pointer;
`

export { Page, Row, EmailButton }
