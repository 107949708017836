import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import actions from '../../../../store/actions'

import Details from './Details'

export default connect(
  state => ({
    data: state.reports
  }),
  dispatch => ({
    handlingData: bindActionCreators(actions.reports.handlingData, dispatch),
    getLessonReport: bindActionCreators(
      actions.reports.getLessonReport,
      dispatch
    ),
    getQuizReport: bindActionCreators(actions.reports.getQuizReport, dispatch)
  })
)(Details)
