import React, { useState, useEffect } from 'react';
import './styles.scss';

import { Spin, Select, Row, Col, Icon } from 'antd';
import ElevenLabsControls from './ElevenLabsControls';

import GalleryIcon from '../../../../../../assets/gallery_icon.png';
import AvatarIcon from '../../../../../../assets/avatarIcon2.png'
import SoundIcon from '../../../../../../assets/sound_icon.png'

const { Option } = Select;

const MazeRightPanel = ({
  audio,
  onAddVoice,
  background,
  onAddBackground,
  isBackgroundLoading,
  mazeAvatar,
  backgroundError,
  onAvatarChange,
  handleElevenLabsVoiceSelect,
  voice_type,
  voice_accent,
  voice_name,
  mazeAvatarId
}) => {
  const [selectedVoiceType, setSelectedVoiceType] = useState(voice_type);
  const handleVoiceTypeChange = (value) => {
    setSelectedVoiceType(value);
  };

  useEffect(() => {
    setSelectedVoiceType(voice_type);
  }, [voice_type]);

  const renderControls = () => {
    if (selectedVoiceType == "elevenlabs") {
      return (
        <ElevenLabsControls
          selectedVoiceType={selectedVoiceType}
          handleElevenLabsVoiceSelect={handleElevenLabsVoiceSelect}
          mazeVoiceAccent={voice_accent}
          voice_name={voice_name}
          mazeAvatarId={mazeAvatarId}
        />
      );
    } else {
      return (<>
        <div
          className="maze_action column"
          style={{
            backgroundImage: mazeAvatar ? `url('${mazeAvatar.link}')` : 'none',
            borderColor: backgroundError ? 'red' : '#d7d4d4'
          }}
          onClick={() => onAvatarChange()}
        >
          {isBackgroundLoading && <Spin spinning={true} />}
          {!isBackgroundLoading && (
            <>
              <img
                alt=""
                src={AvatarIcon}
                style={{ objectFit: 'cover', marginTop: '4px', marginBottom: '-8px' }}
                height="80px"
                width="80px"
              />
              <span
                style={{
                  color: background ? 'white' : ' #136cfb',
                  backgroundColor: background ? '#136cfb' : 'none'
                }}
              >
                {mazeAvatar ? 'Avatar added' : 'Select Avatar'}
              </span>
            </>
          )}
        </div>
        <br />
        <div
          className={`maze_action column ${background ? '' : 'div_disabled'}`}
          onClick={() => (background ? onAddVoice() : null)}
        >
          {audio ? (
            <Icon
              type="check-circle"
              style={{ color: '#136cfb', fontSize: '30px' }}
            />
          ) : (
            <img alt="" src={SoundIcon} />
          )}
          <span style={{ textAlign: 'center' }}>
            {audio ? `Audio added \n (${audio.toUpperCase()})` : 'Add audio'}
          </span>
        </div>
      </>)
    }
  };

  return (
    <div className="maze_right">
      <div className="maze_right_bottom">
        <div
          className="maze_action column"
          style={{
            backgroundImage: background ? `url('${background.link}')` : 'none',
            borderColor: backgroundError ? 'red' : '#d7d4d4'
          }}
          onClick={() => onAddBackground()}
        >
          {isBackgroundLoading && <Spin spinning={true} />}
          {!isBackgroundLoading && (
            <>
              <img alt="" src={GalleryIcon} style={{ objectFit: 'cover' }} />
              <span
                style={{
                  color: background ? 'white' : ' #136cfb',
                  backgroundColor: background ? '#136cfb' : 'none'
                }}
              >
                {background ? 'Background added' : 'Add Background'}
              </span>
            </>
          )}
        </div>
        <br />
        <Row gutter={16} align="middle" style={{ marginBottom: '16px' }}>
          <Col span={6}>
            <label style={{ marginTop: '0.5em' }}>Voice Type</label>
          </Col>
          <Col span={18}>
            <Select
              placeholder="Select Voice Type"
              onChange={handleVoiceTypeChange}
              value={selectedVoiceType}
              style={{ width: '100%' }}
            >
              <Option value="elevenlabs">Sharelook Premium</Option>
              <Option value="traditional">Sharelook Standard</Option>
            </Select>
          </Col>
        </Row>
        {renderControls()}
      </div>
    </div>
  );
};

export default MazeRightPanel;
