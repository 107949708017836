import AddTopicCard from './AddTopicCard'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import actions from '../../../../store/actions'

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => bindActionCreators({
  updateTopicList: actions.topics.updateTopicList
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddTopicCard)
