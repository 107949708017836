import {
  WORKSHOP_EVENTS_SUCCESS,
  WORKSHOP_EVENTS_FETCHING,
  WORKSHOP_EVENTS_HAS_MORE,
  WORKSHOP_PODCAST_SUCCESS,
  WORKSHOP_PODCAST_FETCHING,
  WORKSHOP_PODCAST_HAS_MORE,
  TRAINING_EVENTS_SUCCESS,
  TRAINING_EVENTS_FETCHING,
  TRAINING_EVENTS_HAS_MORE,
  ENROLLED_EVENTS_FETCHING,
  ENROLLED_EVENTS_SUCCESS,
  PAST_EVENTS_FETCHING,
  PAST_EVENTS_SUCCESS,
  NOW_LIVE_EVENTS_FETCHING,
  NOW_LIVE_EVENTS_SUCCESS,
  DELETE_EVENT_SUCCESS,
  DELETE_PODCAST_SUCCESS,
  WEBINAR_EVENTS_FETCHING,
  WEBINAR_EVENTS_SUCCESS,
  PEER_TO_PEER_FETCHING,
  PEER_TO_PEER_SUCCESS,
  CONFERENCE_FETCHING,
  CONFERENCE_SUCCESS,
  TRAINING_EVENTS_WITH_ASSESSMENTS_SUCCESS,
  TRAINING_EVENTS_WITH_ASSESSMENTS_FETCHING
} from '../types'

const initialState = {
  workshopEvents: {
    list: [],
    fetch: false,
    hasMore: true
  },
  trainingEvents: {
    list: [],
    fetch: false,
    hasMore: true
  },
  enrolledEvents: {
    list: [],
    fetch: false
  },
  pastEvents: {
    list: [],
    fetch: false
  },
  nowLiveEvents: {
    list: [],
    fetch: false
  },
  webinarEvents: {
    list: [],
    fetch: false
  },
  peerToPeer: {
    list: [],
    fetch: false
  },
  conferenceEvents: {
    list: [],
    fetch: false
  },
  eventsWithAssessments: {
    list: [],
    fetch: false
  },
  workshopPodcasts: {
    list: [],
    fetch: false,
    hasMore: true
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case WORKSHOP_EVENTS_SUCCESS:
      return {
        ...state,
        workshopEvents: {
          ...state.workshopEvents,
          list: action.payload
        }
      }
    case WORKSHOP_EVENTS_FETCHING:
      return {
        ...state,
        workshopEvents: {
          ...state.workshopEvents,
          fetch: action.payload
        }
      }
    case WORKSHOP_EVENTS_HAS_MORE:
      return {
        ...state,
        workshopEvents: {
          ...state.workshopEvents,
          hasMore: action.payload
        }
      }
    case WORKSHOP_PODCAST_SUCCESS:
      return {
        ...state,
        workshopPodcasts: {
          ...state.workshopPodcasts,
          list: action.payload
        }
      }
    case WORKSHOP_PODCAST_FETCHING:
      return {
        ...state,
        workshopPodcasts: {
          ...state.workshopPodcasts,
          fetch: action.payload
        }
      }
    case WORKSHOP_PODCAST_HAS_MORE:
      return {
        ...state,
        workshopPodcasts: {
          ...state.workshopPodcasts,
          hasMore: action.payload
        }
      }

    case TRAINING_EVENTS_SUCCESS:
      return {
        ...state,
        trainingEvents: {
          ...state.trainingEvents,
          list: action.payload
        }
      }
    case TRAINING_EVENTS_FETCHING:
      return {
        ...state,
        trainingEvents: {
          ...state.trainingEvents,
          fetch: action.payload
        }
      }
    case TRAINING_EVENTS_HAS_MORE:
      return {
        ...state,
        trainingEvents: {
          ...state.trainingEvents,
          hasMore: action.payload
        }
      }

    case ENROLLED_EVENTS_SUCCESS:
      return {
        ...state,
        enrolledEvents: {
          ...state.enrolledEvents,
          list: action.payload
        }
      }
    case ENROLLED_EVENTS_FETCHING:
      return {
        ...state,
        enrolledEvents: {
          ...state.enrolledEvents,
          fetch: action.payload
        }
      }

    // get pastLiveEvents State
    case PAST_EVENTS_FETCHING:
      return {
        ...state,
        pastEvents: {
          ...state.pastEvents,
          fetch: action.payload
        }
      }

    case PAST_EVENTS_SUCCESS:
      return {
        ...state,
        pastEvents: {
          ...state.pastEvents,
          list: action.payload
        }
      }

    // get nowLiveEvents State
    case NOW_LIVE_EVENTS_FETCHING:
      return {
        ...state,
        nowLiveEvents: {
          ...state.nowLiveEvents,
          fetch: action.payload
        }
      }

    case NOW_LIVE_EVENTS_SUCCESS:
      return {
        ...state,
        nowLiveEvents: {
          ...state.nowLiveEvents,
          list: action.payload
        }
      }

    // get webinar Events State
    case WEBINAR_EVENTS_FETCHING:
      return {
        ...state,
        webinarEvents: {
          ...state.webinarEvents,
          fetch: action.payload
        }
      }

    case WEBINAR_EVENTS_SUCCESS:
      return {
        ...state,
        webinarEvents: {
          ...state.webinarEvents,
          list: action.payload
        }
      }

    // get coaching(p2p) Events State
    case PEER_TO_PEER_FETCHING:
      return {
        ...state,
        peerToPeer: {
          ...state.peerToPeer,
          fetch: action.payload
        }
      }

    case PEER_TO_PEER_SUCCESS:
      return {
        ...state,
        peerToPeer: {
          ...state.peerToPeer,
          list: action.payload
        }
      }

    // get conference Events State
    case CONFERENCE_FETCHING:
      return {
        ...state,
        conferenceEvents: {
          ...state.conferenceEvents,
          fetch: action.payload
        }
      }

    case CONFERENCE_SUCCESS:
      return {
        ...state,
        conferenceEvents: {
          ...state.conferenceEvents,
          list: action.payload
        }
      }

    case DELETE_EVENT_SUCCESS:
      return {
        ...state,
        workshopEvents: {
          ...state.workshopEvents,
          list: state.workshopEvents.list.filter(i => i.id !== action.payload)
        }
      }
    case DELETE_PODCAST_SUCCESS:
      return {
        ...state,
        workshopPodcasts: {
          ...state.workshopPodcasts,
          list: state.workshopPodcasts.list.filter(i => i.id !== action.payload)
        }
      }

    case TRAINING_EVENTS_WITH_ASSESSMENTS_FETCHING:
      return {
        ...state,
        eventsWithAssessments: {
          ...state.eventsWithAssessments,
          fetch: action.payload
        }
      }
    case TRAINING_EVENTS_WITH_ASSESSMENTS_SUCCESS:
      return {
        ...state,
        eventsWithAssessments: {
          ...state.eventsWithAssessments,
          list: action.payload
        }
      }

    default:
      return state
  }
}
