import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Statistic, Icon, Rate, Spin } from 'antd'

import { useFetchReviews } from '../../../../../components/Reviews/Reviews'

import './PreviewStatus.scss'
import { useSelector } from 'react-redux'

const { Countdown } = Statistic

function PreviewStatus(props) {
  const {
    App,
    showJoinButton,
    onJoin,
    isStreamStopped,
    isCoachingSession,
    isConferencePublic,
    hasBreakoutRoom
  } = props
  const {
    props: { t },
    state: { event }
  } = App
  useFetchReviews(event.id, 'event')

  const isZoom = event.encoder === 'zoom_meeting'

  const isLive =
    !event.is_finished &&
    moment(event.start_date_time).isBefore(moment.utc().toDate())

  const isStreamEnded = isZoom
    ? event.is_finished || isStreamStopped
    : event.is_finished

  const allowEventReview = isCoachingSession || isConferencePublic

  console.log('onPreview', App.displayCountdownTime(event))

  const original = moment(event.start_date_time).fromNow()
  const resultTime = original.substr(original.indexOf(' ') + 1)

  return (
    <div className="event__status" style={{ position: 'relative' }}>
      <div
        className="event__status-container"
        style={{ flex: showJoinButton && !isStreamEnded ? null : 1 }}
      >
        {App.displayCountdownTime(event) && (
          <div className="__count_down">
            {App.displayCountdownTime(event)}
            {isLive && (
              <div className="live__status">
                <Icon type="wifi" style={{ color: '#fff', fontSize: '20px' }} />
                <h1>{t('v2:live_now')}</h1>
              </div>
            )}
            {isStreamEnded && t('events:finished')}
            {!isLive && !isStreamEnded && (
              <>
                {t('events:countdown')}
                <Countdown
                  value={event.start_date_time}
                  style={{ marginLeft: '0.5rem' }}
                  valueStyle={{
                    color: '#fff',
                    fontSize: '1.8rem'
                  }}
                  onFinish={App.onCountDownFinish}
                />
              </>
            )}
          </div>
        )}
        {!App.displayCountdownTime(event) && !isStreamEnded && (
          <div className="__count_down">
            {event.start_date_time ? (
              <>
                {t('events:countdown')}
                {moment(event.start_date_time).isSame(new Date(), 'day') ? (
                  <Countdown
                    value={event.start_date_time}
                    style={{ marginLeft: '0.5rem' }}
                    valueStyle={{
                      color: '#fff',
                      fontSize: '1.8rem'
                    }}
                    onFinish={App.onCountDownFinish}
                  />
                ) : (
                  <span>&nbsp;{resultTime}</span>
                )}
              </>
            ) : (
              <span>Event is not scheduled.</span>
            )}
          </div>
        )}
      </div>
      {showJoinButton && !isStreamEnded && (
        <div
          className="join_button"
          onClick={() => onJoin()}
          style={{ flex: isLive ? 8 : 3 }}
        >
          {hasBreakoutRoom ? t('v4:join_breakout_room') : t('v4:join')}
        </div>
      )}

      {isStreamEnded && allowEventReview && <ReviewStatus t={t} App={App} />}
    </div>
  )
}

const ReviewStatus = props => {
  const reviews = useSelector(state => state.reviews)

  const { reviews: allReviews = [], avg_rating, isFetching } = reviews

  const [averageRating, setAverageRating] = useState(avg_rating)

  useEffect(() => {
    const rating = allReviews.reduce((acc, item) => acc + item.rating, 0)
    setAverageRating(rating / allReviews.length || 0)
  }, [allReviews.length])

  const { t } = props
  return (
    <Spin spinning={isFetching}>
      <div className="review__container">
        <span>{t('v2:average_rating')}</span>
        <Rate disabled value={Number(averageRating)} />
        <span>{averageRating && Number(averageRating).toFixed(1)} </span>
        <span
          className="review__total"
          onClick={() => props.App.onHeaderItemClick('RATING')}
        >
          {t('v2:review')}({allReviews && allReviews.length})
        </span>
      </div>
    </Spin>
  )
}

export default PreviewStatus
