import React, { useEffect, useRef } from 'react'
import {
  LocalVideo,
  useRemoteVideoTileState,
  RemoteVideo,
  ContentShare,
  useContentShareState,
  useRosterState,
  useLocalVideo,
  useActiveSpeakersState,
  MicrophoneActivity
} from 'amazon-chime-sdk-component-library-react'
import { Icon } from 'antd'
import { Card } from '../../../../../components/Card/styled'
import '../../../../../components/Card/Card.scss'
import userImage from '../../assets/user.svg'
import Whiteboard from '../Whiteboard/App'

const Chime = ({
  isConfCreator,
  isConferenceHost,
  sendMute,
  loading,
  t,
  participants,
  isGridActive,
  isWhiteboardSharing,
  sendRealTimeMessage,
  meetingManager,
  eventId,
  whiteboardRequest,
  whiteboardResponse,
  setWhiteboardRequest,
  setWhiteboardResponse,
  userId,
  consumerId,
  onClickWhiteboardSharing
}) => {
  const {
    tiles,
    attendeeIdToTileId
  } = useRemoteVideoTileState()
  const { sharingAttendeeId } = useContentShareState()
  const prevTileIdRef = useRef()
  const { roster } = useRosterState()

  const activeSpeakers = useActiveSpeakersState()
  const activeTileId = activeSpeakers[0]

  const ATTENDEES = Object.values(roster)
  const attendees = ATTENDEES.filter(
    item =>
      item.externalUserId.split('#')[1] !=='recording' &&
      !item.externalUserId.split('#')[0].includes('passive')
  )

  const { isVideoEnabled } = useLocalVideo()

  const localUserId = attendees[0] && attendees[0].externalUserId.split('#')[1]
  const localChimeId = attendees[0] && attendees[0].chimeAttendeeId

  useEffect(() => {
    prevTileIdRef.current = activeTileId
  })
  const prevTileId = prevTileIdRef.current

  useEffect(() => {
    if (sharingAttendeeId || isGridActive || isWhiteboardSharing) {
      return
    }
    const localVideoElements = document.querySelectorAll('.local_video')
    const remoteVideoElement = document.querySelector(
      `.chimeId_${activeTileId}`
    )
    const previousVideoElement = document.querySelectorAll(
      `.chime_main__view.chimeId_${prevTileId}`
    )

    const activeSpeaker = attendees.filter(
      item => item.chimeAttendeeId === activeTileId
    )[0]

    if (activeTileId === localChimeId || !activeTileId || !activeSpeaker) {
      removeFromActiveSpeaker(previousVideoElement[0])
      localVideoElements.forEach(element => {
        makeActiveSpeaker(element)
      })
    } else if (activeTileId !==localChimeId) {
      makeActiveSpeaker(remoteVideoElement)

      if (previousVideoElement && previousVideoElement.length > 0) {
        previousVideoElement.forEach(item => {
          removeFromActiveSpeaker(item)
        })
      } else {
        localVideoElements.forEach(element => {
          removeFromActiveSpeaker(element)
        })
      }
    }
  }, [activeTileId, sharingAttendeeId, isWhiteboardSharing])

  useEffect(() => {
    if (sharingAttendeeId) {
      const screenShareElement = document.getElementById('sharevideo')
      const localVideoElements = document.querySelectorAll('.local_video')
      const previousVideoElement = document.querySelectorAll(
        `.chime_main__view.chimeId_${prevTileId}`
      )

      makeActiveSpeaker(screenShareElement)
      if (previousVideoElement && previousVideoElement.length > 0) {
        previousVideoElement.forEach(item => {
          removeFromActiveSpeaker(item)
        })
      } else {
        localVideoElements.forEach(element => {
          removeFromActiveSpeaker(element)
        })
      }
    }
  }, [sharingAttendeeId])

  useEffect(() => {
    if (isWhiteboardSharing) {
      const whiteboardShareElement = document.getElementById('sharewhiteboard')
      const localVideoElements = document.querySelectorAll('.local_video')
      const previousVideoElement = document.querySelectorAll(
        `.chime_main__view.chimeId_${prevTileId}`
      )

      makeActiveSpeaker(whiteboardShareElement)
      if (previousVideoElement && previousVideoElement.length > 0) {
        previousVideoElement.forEach(item => {
          removeFromActiveSpeaker(item)
        })
      } else {
        localVideoElements.forEach(element => {
          removeFromActiveSpeaker(element)
        })
      }
    }
  }, [isWhiteboardSharing])

  const makeActiveSpeaker = element => {
    if (element) {
      if (!element.classList.contains('chime_main__view')) {
        element.classList.add('chime_main__view')
      }
      if (element.classList.contains('remoteChimePlayer')) {
        element.classList.remove('remoteChimePlayer')
      }
    }
  }

  const removeFromActiveSpeaker = element => {
    if (element) {
      if (element.classList.contains('chime_main__view')) {
        element.classList.remove('chime_main__view')
      }
      if (!element.classList.contains('remoteChimePlayer')) {
        element.classList.add('remoteChimePlayer')
      }
    }
  }

  const toggleMute = userAttendee => sendMute(userAttendee)

  const attendeesToRender = isConferenceHost ? attendees.slice(1) : attendees

  const userSharingScreenId =
    sharingAttendeeId && sharingAttendeeId.split('#')[0]

  const len = attendees.length
  var MINWIDTH =
    len <= 4
      ? '40%'
      : len > 4 && len < 10
      ? '30%'
      : len >= 10 && len < 17
      ? '20%'
      : len >= 17 && len < 25
      ? '15%'
      : '13%'

  const checkIsActive = id => {
    const isActive = activeSpeakers.find(item => item === id)
    return isActive
  }

  return (
    <div className="conference_chime" id="chime_fullScreen">
      <>
        {!loading &&
          isGridActive &&
          !sharingAttendeeId &&
          !isWhiteboardSharing && (
            <div className="grid__view">
              {isConferenceHost && (
                <div
                  className={`grid__view__item ${
                    activeSpeakers.find(item => item === localChimeId)
                      ? '_active-speaker'
                      : 'null'
                  }`}
                  style={{ minWidth: MINWIDTH }}
                >
                  {isVideoEnabled ? (
                    <LocalVideo />
                  ) : (
                    <DisabledContainer
                      participants={participants}
                      id={localUserId}
                    />
                  )}
                  <VolumeControls
                    id={localUserId}
                    name={'You'}
                    chimeId={localChimeId}
                    toggleMute={toggleMute}
                    isConfCreator={isConfCreator}
                  />
                </div>
              )}

              {attendeesToRender &&
                attendeesToRender.map(item => {
                  const remoteTileId = attendeeIdToTileId[item.chimeAttendeeId]

                  const chimeId = item.chimeAttendeeId
                  const userParam = item.externalUserId.split('#')
                  const name = userParam[0]
                  const id = userParam[1]
                  return (
                    <div
                      className={`grid__view__item ${
                        checkIsActive(item.chimeAttendeeId)
                          ? '_active-speaker'
                          : 'null'
                      }`}
                      key={remoteTileId}
                      style={{ minWidth: MINWIDTH }}
                    >
                      {remoteTileId ? (
                        <RemoteVideo tileId={remoteTileId} />
                      ) : (
                        <DisabledContainer
                          participants={participants}
                          id={id}
                        />
                      )}
                      <VolumeControls
                        id={id}
                        name={name}
                        chimeId={chimeId}
                        toggleMute={toggleMute}
                        isConfCreator={isConfCreator}
                      />
                    </div>
                  )
                })}
            </div>
          )}
        {(!isGridActive ||
          (isGridActive && sharingAttendeeId) ||
          (isGridActive && isWhiteboardSharing)) && (
          <div className="scrollbar_chime">
            {loading ? (
              <video autoPlay muted playsInline className="default_element" />
            ) : (
              <>
                {isConferenceHost && (
                  <div
                    className={`local_video chime_main__view`}
                    style={{
                      order: userSharingScreenId === localChimeId ? -1 : 0
                    }}
                  >
                    {isVideoEnabled ? (
                      <LocalVideo />
                    ) : (
                      <DisabledContainer
                        participants={participants}
                        id={localUserId}
                      />
                    )}
                    <VolumeControls
                      id={localUserId}
                      name={'You'}
                      chimeId={localChimeId}
                      toggleMute={toggleMute}
                      isConfCreator={isConfCreator}
                    />
                  </div>
                )}

                {!isConferenceHost &&
                  attendeesToRender &&
                  attendeesToRender.length === 0 && (
                    <>
                      <div className="chime_main__view">
                        <div className="disabled__container">
                          <h1 style={{ color: 'white', fontWeight: 'bolder' }}>
                            Waiting for host to join...
                          </h1>
                        </div>
                      </div>
                    </>
                  )}

                {attendeesToRender &&
                  attendeesToRender.map((item, index) => {
                    const remoteTileId =
                      attendeeIdToTileId[item.chimeAttendeeId]

                    const chimeId = item.chimeAttendeeId
                    const tileItem = tiles.filter(item => item === remoteTileId)

                    const isRemoteVideoEnabled = tileItem && tileItem.length > 0

                    const userParam = item.externalUserId.split('#')
                    const name = userParam[0]
                    const id = userParam[1]
                    return (
                      <div
                        className={`${
                          !isConferenceHost && index === 0
                            ? 'local_video chime_main__view'
                            : 'remoteChimePlayer'
                        } chimeId_${chimeId}`}
                        style={{
                          order: userSharingScreenId === chimeId ? -1 : 0
                        }}
                        key={chimeId}
                      >
                        {isRemoteVideoEnabled ? (
                          <RemoteVideo tileId={remoteTileId} />
                        ) : (
                          <DisabledContainer
                            participants={participants}
                            id={id}
                          />
                        )}
                        <VolumeControls
                          id={id}
                          name={name}
                          chimeId={chimeId}
                          toggleMute={toggleMute}
                          isConfCreator={isConfCreator}
                        />
                      </div>
                    )
                  })}
                <ContentShare id="sharevideo" />

                {isWhiteboardSharing && (
                  <Whiteboard
                    sendRealTimeMessage={sendRealTimeMessage}
                    meetingManager={meetingManager}
                    eventId={eventId}
                    whiteboardRequest={whiteboardRequest}
                    whiteboardResponse={whiteboardResponse}
                    setWhiteboardRequest={setWhiteboardRequest}
                    setWhiteboardResponse={setWhiteboardResponse}
                    userId={userId}
                    consumerId={consumerId}
                    onClickWhiteboardSharing={onClickWhiteboardSharing}
                  />
                )}
              </>
            )}

            {loading && (
              <Card.OverlayPlayer>{t('general:loading')}</Card.OverlayPlayer>
            )}
          </div>
        )}
      </>
    </div>
  )
}

export const VolumeControls = ({
  id,
  toggleMute,
  chimeId,
  name,
  isConfCreator
}) => (
  <div className="volume__controls">
    <button
      className={`volume_button ${!isConfCreator ? 'disable_mute' : ''}`}
      onClick={() => {
        toggleMute(id)
      }}
    >
      <MicrophoneActivity attendeeId={chimeId} />
    </button>
    {''}
    <span>{name}</span>
  </div>
)

export const DisabledContainer = ({ id, participants }) => {
  const disabledParticipant = participants.filter(item => item.id === id)

  const avatar =
    disabledParticipant &&
    disabledParticipant[0] &&
    disabledParticipant[0].avatar
  const cover = avatar && avatar.link ? avatar.link : userImage
  return (
    <div className="disabled__container">
      <img alt="" src={cover} className="disabled__video__image" />
      <div className="disabled__video__icon">
        <Icon className="video_off_icon" type="video-camera" />
      </div>
    </div>
  )
}

export default Chime
