import React from 'react'
import { Content } from '../../styled'
import { withTranslation } from 'react-i18next'

const WhatsNew = (props) => {
  const {
    t
  } = props
  return (
    <Content>
      <Content.Heading>{t('menu.whats_new')}</Content.Heading>
      <Content.Video dangerouslySetInnerHTML={{ __html: props.video }} />
      {
        props.description ? (
          <Content.Description>
            <strong>{props.t(props.description.main)}</strong>
            <p>{props.t(props.description.tagline)}</p>
          </Content.Description>
        ) : null
      }
    </Content>
  )
}

export default withTranslation('help_center')(WhatsNew)
