/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-13 14:27:15
 * @LastEditTime: 2019-12-11 16:01:59
 * @LastEditors: Please set LastEditors
 */
const style = color => {
  return `background-color: ${color}; width: 1rem; height: 1rem; border-radius: 50%`
}
const element = (value, index) => {
  if (!value[index]) return ''
  return `
      <div style="display: flex; align-items: center; justify-content: space-between; flex-direction: row">
        <div style="${style(value[index].color)}"></div>
        <div style="margin-left: 1rem">
          ${value[index].seriesName}: ${value[index].data}
        </div>
      </div>
    `
}
let timeout = null

const options = (graphDuration, app, graphEmotionsData) => {
  return {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        // 坐标轴指示器，坐标轴触发有效
        type: 'none' // 默认为直线，可选为：'line' | 'shadow'
      },
      // formatter:
      //   '{a4}: {c4}<br/> {a3}: {c3}<br/> {a2}: {c2}<br/> {a1}: {c1}<br/> {a0}: {c0}'
      formatter: function(value) {
        return `
        <div>
          ${element(value, 4)}
          ${element(value, 3)}
          ${element(value, 2)}
          ${element(value, 1)}
          ${element(value, 0)}
        </div>`
      }
    },
    legend: {
      selectedMode: false,
      itemHeight: 24,
      data: [
        {
          name: 'Angry',
          icon: 'image:///images/icons/emotions/color/angry.svg'
        },
        {
          name: 'Sad',
          icon: 'image:///images/icons/emotions/color/sad.svg'
        },
        {
          name: 'Neutral',
          icon: 'image:///images/icons/emotions/color/neutral.svg'
        },
        {
          name: 'Smile',
          icon: 'image:///images/icons/emotions/color/smile.svg'
        },
        {
          name: 'Happy',
          icon: 'image:///images/icons/emotions/color/happy.svg'
        }
      ],
      bottom: -5
    },
    grid: {
      left: '0%',
      // right: '0%',
      bottom: '25%',
      top: '10%',
      containLabel: true
    },
    xAxis: {
      type: 'category',
      name: 'Minutes',
      data: graphDuration,
      boundaryGap: true,
      axisTick: {
        show: false
        // alignWithLabel: true
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: '#DDDDDD'
        }
      },
      triggerEvent: true,
      axisLabel: {
        show: false,
        fontSize: 8,
        interval: 5
      }
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        interval: 2,
        formatter: (value, index) => {
          if (timeout) clearTimeout(timeout)
          timeout = setTimeout(() => {
            app.yAxisValue = value
          }, 500)
          return [`{yAxieStyle|${value}}`].join('\n')
        },
        // align: 'left',
        rich: {
          yAxieStyle: {
            color: 'black'
          }
        }
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: '#DDDDDD'
        }
      }
    },
    series: [
      {
        name: 'Happy',
        type: 'bar',
        stack: 'stack',
        itemStyle: {
          color: '#3E9CD4'
        },
        label: {
          normal: {
            // show: true,
            position: 'insideRight'
          }
        },
        data: graphEmotionsData['happy']
      },
      {
        name: 'Smile',
        type: 'bar',
        stack: 'stack',
        itemStyle: {
          color: '#00BFE1'
        },
        label: {
          normal: {
            // show: true,
            position: 'insideRight'
          }
        },
        data: graphEmotionsData['smile']
      },
      {
        name: 'Neutral',
        type: 'bar',
        stack: 'stack',
        itemStyle: {
          color: '#A9FAFF'
        },
        label: {
          normal: {
            // show: true,
            position: 'insideRight'
          }
        },
        data: graphEmotionsData['neutral']
      },
      {
        name: 'Sad',
        type: 'bar',
        stack: 'stack',
        itemStyle: {
          color: '#F9F871'
        },
        label: {
          normal: {
            // show: true,
            position: 'insideRight'
          }
        },
        data: graphEmotionsData['sad']
      },
      {
        name: 'Angry',
        type: 'bar',
        stack: 'stack',
        itemStyle: {
          color: '#F9A271'
        },
        label: {
          normal: {
            // show: true,
            position: 'insideRight'
          }
        },
        data: graphEmotionsData['angry']
      }
    ]
  }
}

const reactChartStyles = {
  height: '20rem',
  margin: 0,
  padding: 0,
  width: '85rem',
  position: 'relative',
  left: 0
}

const legendStyles = {
  width: '850px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '1rem'
}

export { options, reactChartStyles, legendStyles }
