import { connect } from 'react-redux'
import actions from '../../store/actions'
import Account from './Account'

const mapStateToProps = state => ({
  user: state.user,
  topics: state.topics,
  badge: state.badge
})

const mapDispatchToProps = dispatch => ({
  updateUser: (id, data, dbData) =>
    dispatch(actions.user.updateUser(id, data, dbData)),
  upload: (file, opts) => dispatch(actions.library.upload(file, opts)),
  getLibraryList: query => dispatch(actions.library.getLibraryList(query)),
  getBadgesById: id => dispatch(actions.badge.getBadgesById(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(Account)
