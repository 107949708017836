import { connect } from 'react-redux'

import ModalScormContent from './ModalScormContent'

export default connect(
  state => ({
    lesson: state.courses.lessonById,
    user: state.user
  }),
  dispatch => ({})
)(ModalScormContent)
