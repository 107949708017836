import React, { Component, Fragment } from 'react'
import moment from 'moment'
import _ from 'lodash'
import { Page } from './styled'
import { Field } from '../../../../../styles'
import {
  DatePicker,
  PrivacySelector,
  OrganizationPicker,
  GroupPicker
} from './components'
import { Input, Checkbox, Button, Spin, message, Modal } from 'antd'
import history from '../../../../../history'
import { withTranslation, Trans } from 'react-i18next'
import { general } from '../../../../../constants'
import { api } from '../../../../../services'
import ReactSVG from 'react-svg'

class PublishSettings extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedType: 0,
      startValue: moment(),
      endValue: null,
      selectedOrganizations: [],
      selectedGroups: [],
      emails: [],
      isEmailsValid: false,
      checkedTerms: false,
      isAdminOnly: false,
      isDisable: true,
      isPublish: false,
      isSendMail: false,
      isCheckModal: false,
      isNotExist: [],
      isNotInOrg: [],
      enableMarketplace: true
    }
    this.handleInsertUsers = _.debounce(this.handleInsertUsers, 1000)
    this.handleCheckUser = _.debounce(this.handleCheckUser, 500)
  }

  componentDidMount() {
    const {
      courseBuilder: { courseExpDate, courseStartDate },
      get,
      courses: { courseById }
    } = this.props
    if (courseStartDate) {
      this.setState(
        {
          startValue: moment(courseStartDate),
          endValue: courseExpDate && moment(courseExpDate)
        },
        () => {
          this.getOldSetting(courseById)
        }
      )
    }
    if (get) {
      get()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      this.checkEnableMarketPlace()
    }
  }

  checkEnableMarketPlace = () => {
    const {
      courseBuilder: {
        courseId,
        coursePassScore,
        courseTitle,
        courseImgId,
        courseOrganiser,
        courseCategory,
        courseIsFree,
        courseMarketPlaceDescription,
        courseNoOfLessons,
        courseNoOfVideos,
        courseObjectives,
        courseOrganization,
        courseOutline,
        coursePrice,
        courseGroup,
        courseVideoLink
      }
    } = this.props
    let data = {
      courseId,
      courseTitle,
      courseImgId,
      courseOrganiser,
      courseCategory,
      courseIsFree,
      courseMarketPlaceDescription,
      courseNoOfVideos,
      courseObjectives,
      courseOrganization,
      courseOutline,
      courseNoOfLessons,
      coursePrice,
      courseGroup,
      courseVideoLink,
      coursePassScore
    }
    let errorData = Object.keys(data).filter(k => {
      return data[k] === '' || data[k] === undefined || data[k] === null
    })

    console.log('error in marketplace', errorData)

    if (errorData.length === 0) {
      this.setState({
        enableMarketplace: true
      })
    }
  }

  getOldSetting = courseById => {
    const {
      item: { setting }
    } = courseById
    if (setting) {
      const {
        group_ids,
        publish_option,
        organization_ids,
        emails,
        is_admin_only: isAdminOnly
      } = setting
      if (publish_option === general.ORGANIZATION) {
        this.handleSelectedType(2)
      } else if (publish_option === general.ORGANIZATION_GROUP) {
        this.handleSelectedType(3)
      } else if (publish_option === general.SPECIFIC_USER) {
        this.handleSelectedType(4)
      } else if (publish_option === general.PUBLIC) {
        this.handleSelectedType(1)
      } else if (publish_option === general.MARKETPLACE) {
        this.handleSelectedType(5)
      }
      this.handleInsertUsers(_.uniq(emails).join('\n'))
      this.setState({
        selectedOrganizations: organization_ids,
        selectedGroups: group_ids,
        emails,
        isAdminOnly
      })
    }
  }

  getLastOption = () => {
    const { selectedType } = this.state
    if (selectedType === 1) {
      return general.PUBLIC
    } else if (selectedType === 2) {
      return general.ORGANIZATION
    } else if (selectedType === 3) {
      return general.ORGANIZATION_GROUP
    } else if (selectedType === 5) {
      return general.MARKETPLACE
    } else {
      return general.SPECIFIC_USER
    }
  }
  handlePublish = () => {
    const {
      courseBuilder: {
        courseId,
        courseTitle,
        courseDescription,
        courseImgId,
        courseTags,
        courseOrganiser,
        courseCategory,
        courseIsFree,
        courseMarketPlaceDescription,
        courseNoOfLessons,
        courseNoOfVideos,
        courseObjectives,
        courseOrganization,
        courseOutline,
        coursePrice,
        courseGroup,
        courseVideoLink,
        coursePassScore,
        courseBadgeId,
        courseCertId,
        courseType
      },
      update,
      t,
      user: {
        info: { username }
      },
      resetCourseById
    } = this.props

    const {
      selectedType,
      selectedOrganizations,
      selectedGroups,
      emails,
      isEmailsValid,
      startValue,
      endValue,
      isAdminOnly: is_admin_only,
      isSendMail
    } = this.state

    const removedSpaceEmail = _.map(emails, email => email.trim().toLowerCase())

    let privacy = {
      is_public: selectedType === 1,
      is_admin_only: selectedType === 1 ? is_admin_only : false,
      organization_ids: selectedOrganizations,
      group_ids: selectedGroups,
      emails: _.uniq([...removedSpaceEmail]),
      publish_option: this.getLastOption(),
      is_marketplace: selectedType === 5
    }

    const data = {
      title: courseTitle,
      description: courseDescription,
      organiser: courseOrganiser,
      topics: courseTags,
      cover_id: courseImgId,
      start_date: startValue,
      pass_score: coursePassScore,
      exp_date: endValue,
      cert_id: courseCertId,
      badge_id: courseBadgeId,
      ...(courseCategory && {
        marketplace: {
          course_id: courseId,
          username: username,
          category_id: courseCategory,
          is_free: courseIsFree,
          price: coursePrice,
          video_link: courseVideoLink,
          no_of_video: courseNoOfVideos,
          no_of_lesson: Number(courseNoOfLessons),
          organization_id: courseOrganization,
          group_id: courseGroup,
          course_obj: courseObjectives,
          course_outline: courseOutline,
          course_description: courseMarketPlaceDescription
        }
      }),
      privacy,
      isSendMail,
      course_type: courseType
    }

    if (!startValue) {
      message.error(t('dates_validation_error'))
      return
    }

    if (!_.isEmpty(emails) && !isEmailsValid) {
      message.error(t('emails_validation_error'))
      return
    }

    if (update) {
      this.checkedPublishButtonDisabling()
      update(data, courseId).then(() => {
        resetCourseById()
        history.push('/scorm/' + courseId)
      })
    }
  }

  handleSelectedDate = (field, value) => {
    this.setState({
      [field]: value
    })
  }

  handleSelectedType = id => {
    this.setState({
      selectedType: id
    })
  }

  handleSelectedOrganizations = id => {
    const { selectedOrganizations } = this.state

    if (selectedOrganizations.includes(id)) {
      this.setState({
        selectedOrganizations: selectedOrganizations.filter(
          organizationId => organizationId !== id
        )
      })
    } else {
      this.setState({
        selectedOrganizations: [...selectedOrganizations, id]
      })
    }
  }

  handleSelectedGroups = groupId => {
    const { selectedGroups } = this.state

    this.setState({
      selectedGroups: selectedGroups.includes(groupId)
        ? selectedGroups.filter(i => i !== groupId)
        : selectedGroups.concat([groupId])
    })
  }

  componentWillReceiveProps(nextProps) {
    const {
      courseBuilder: { courseStartDate, courseExpDate }
    } = this.props
    const {
      courseBuilder: {
        courseStartDate: nextStartDate,
        courseExpDate: nextExpDate
      }
    } = nextProps

    if (!courseStartDate && nextStartDate) {
      this.setState({ startValue: moment(nextStartDate) })
    }

    if (!courseExpDate && nextExpDate) {
      this.setState({ endValue: moment(nextExpDate) })
    }
  }

  handleSelectedAllOrganizations = id => {
    const { list } = this.props
    const { selectedGroups } = this.state

    const { groups = [] } = list.find(i => i.id === id)
    const groupIds = groups.map(i => i.id)

    const selectedOrgGroups = groupIds.filter(i => selectedGroups.includes(i))

    if (groupIds.length === selectedOrgGroups.length) {
      this.setState({
        selectedGroups: selectedGroups.filter(
          i => !selectedOrgGroups.includes(i)
        )
      })
    } else {
      this.setState({
        selectedGroups: selectedGroups
          .filter(i => !groupIds.includes(i))
          .concat(groupIds)
      })
    }
  }

  handleChangeTerms = () => {
    const { isPublish } = this.state
    if (!isPublish) {
      this.setState(state => ({
        checkedTerms: !state.checkedTerms,
        isDisable: !state.isDisable
      }))
    }
  }

  checkedPublishButtonDisabling = () => {
    this.setState({
      isDisable: true,
      isPublish: true
    })
  }

  handleInsertUsers = value => {
    const emails = value.replace(/(\r\n|\n|\r)/gm, ',').split(',')
    const check = _.map(_.compact(emails), email =>
      /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email.trim())
    )
    const isSetValid = _.filter(check, item => item)
    if (isSetValid.length === check.length) {
      this.setState({
        emails: value
          .trim()
          .split('\n')
          .filter(i => !!i),
        isEmailsValid: true
      })
    } else {
      this.setState({
        emails: value
          .trim()
          .split('\n')
          .filter(i => !!i),
        isEmailsValid: false
      })
    }
  }

  onSendEmail = () => {
    this.setState(state => ({
      isSendMail: !state.isSendMail
    }))
  }

  handleCheckUser = () => {
    this.setState({
      isSaving: true
    })
    const { emails } = this.state
    if (!_.isEmpty(emails)) {
      if (this.state.isEmailsValid) {
        const removedSpaceEmail = _.map(emails, email => email.trim())
        const myJSON = JSON.stringify(removedSpaceEmail)
        const {
          user: {
            info: { active_organization_id: activeOrganization }
          }
        } = this.props
        api.events
          .checkUser(myJSON)
          .then(response => {
            const { data } = response
            const isNotExist = data.filter(email => !email.is_exist)
            const isNotInOrg = data.filter(
              email => email.is_exist && !email.in_organization
            )
            this.checkPersonalSpace(activeOrganization, isNotExist, isNotInOrg)
          })
          .catch(() => {
            const { t } = this.props
            message.error(t('emails_validation_error'))
          })
      } else {
        const { t } = this.props
        message.error(t('emails_validation_error'))
      }
    } else {
      this.handlePublish()
    }
  }
  checkPersonalSpace = (activeOrganization, isNotExist, isNotInOrg) => {
    if (activeOrganization) {
      if (_.isEmpty(isNotExist) && _.isEmpty(isNotInOrg)) {
        this.handlePublish()
      } else {
        this.setState({
          isCheckModal: true,
          isNotExist,
          isNotInOrg,
          isLoading: false
        })
      }
    } else {
      if (_.isEmpty(isNotExist)) {
        this.handlePublish()
      } else {
        this.setState({
          isCheckModal: true,
          isNotExist,
          isNotInOrg: [],
          isLoading: false
        })
      }
    }
  }

  handleSendEmail = () => {
    this.handlePublish()
    this.setState({
      isCheckModal: false,
      isNotExist: [],
      isNotInOrg: []
    })
  }

  closeCheckModal = () => {
    const inputHost = document.getElementById('emails')
    if (inputHost) {
      inputHost.focus()
    }
    this.setState({
      isCheckModal: false
    })
  }

  onAdminOnly = () => {
    this.setState(state => ({
      isAdminOnly: !state.isAdminOnly
    }))
  }

  render() {
    const {
      list,
      isFetching,
      user: {
        info: { isAdmin, isSuperAdmin }
      },
      courses: {
        courseById: { isFetching: isItemFetching }
      },
      rootPath,
      t
    } = this.props
    const {
      selectedType,
      selectedOrganizations,
      selectedGroups,
      startValue,
      endValue,
      checkedTerms,
      emails,
      isDisable,
      isAdminOnly,
      isSendMail,
      isCheckModal,
      isNotExist,
      isNotInOrg
    } = this.state
    const privacyTypes = [
      isSuperAdmin && {
        id: 1,
        name: t('v4:public'),
        description: 'PublicDescription'
      },
      (isSuperAdmin || isAdmin) && {
        id: 2,
        name: t('v4:organisations'),
        description: 'OrganisationsDescription'
      },
      (isSuperAdmin || isAdmin) && {
        id: 3,
        name: t('v4:org_groups'),
        description: 'OrganisationGroupsDescription'
      },
      {
        id: 4,
        name: t('v4:specific_user'),
        description: 'SpecificUserDescription'
      },
      {
        id: 5,
        name: 'Marketplace',
        description: 'MarketPlaceDescription',
        disabled: this.state.enableMarketplace
      }
    ].filter(i => !!i)

    const filteredList =
      !_.isEmpty(list) &&
      list.filter(i => isSuperAdmin || (i.role && i.role.name === 'Admin'))

    console.log('course setting', this.props.courseBuilder)
    return (
      <Fragment>
        <section className="section">
          <div className="head head_nopadding">
            <div className="head__title">{t('title')}</div>
            <div className="head__subtitle">{t('course_subtitle')}</div>
          </div>
        </section>

        <section className="section section_divider">
          <Spin spinning={isFetching || isItemFetching}>
            <Page style={{ maxWidth: '120rem' }}>
              <Page.Field>
                <DatePicker
                  onChange={this.handleSelectedDate}
                  startValue={startValue}
                  endValue={endValue}
                />
              </Page.Field>
              <Page.Field>
                <PrivacySelector
                  selected={selectedType}
                  types={privacyTypes}
                  isAdmin={isSuperAdmin || isAdmin}
                  onChange={this.handleSelectedType}
                />
              </Page.Field>
              {selectedType === 2 && (
                <Page.Field>
                  <OrganizationPicker
                    list={filteredList}
                    selectedOrganizations={selectedOrganizations}
                    onChange={this.handleSelectedOrganizations}
                  />
                </Page.Field>
              )}
              {selectedType === 3 && (
                <Page.Field>
                  <GroupPicker
                    list={filteredList}
                    selectedGroups={selectedGroups}
                    onChange={this.handleSelectedGroups}
                    onChangeAll={this.handleSelectedAllOrganizations}
                  />
                </Page.Field>
              )}
              {selectedType === 4 && (
                <Page.Field>
                  <Field>
                    <Field.Label>{t('labels:users_emails')}</Field.Label>
                    <Field.Control>
                      <Input.TextArea
                        id="emails"
                        rows={3}
                        onChange={e => this.handleInsertUsers(e.target.value)}
                        defaultValue={emails.join('\n')}
                      />
                    </Field.Control>
                    <span>{t('labels:description_specifiction_email')}</span>
                  </Field>
                </Page.Field>
              )}
              {selectedType > 0 && (
                <Page.Field>
                  <Field>
                    <Field.Label>{t('labels:disclaimer')}</Field.Label>
                    <Field.Control>
                      <Input.TextArea
                        disabled
                        rows={6}
                        style={{ resize: 'none' }}
                        value={t('course:disclaimer')}
                      />
                    </Field.Control>
                  </Field>
                  <Field>
                    <Field.Label>
                      <Checkbox
                        value={checkedTerms}
                        onChange={this.handleChangeTerms}
                      >
                        <Trans i18nKey="labels:terms">
                          {t('i_agree_to_these')}
                          <a href="/pages/terms-conditions" target="_blank">
                            {t('terms_and_conditions')}
                          </a>
                        </Trans>
                      </Checkbox>
                    </Field.Label>
                  </Field>
                </Page.Field>
              )}
            </Page>
          </Spin>
        </section>
        {isCheckModal && (
          <Modal
            visible={isCheckModal}
            centered
            title={
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <ReactSVG src="/images/icons/menu/bells.svg" />
                <div style={{ paddingLeft: '2rem' }}>
                  {t('labels:check_user')}
                </div>
              </div>
            }
            onCancel={this.closeCheckModal}
            footer={[
              <Button key="back" shape="round" onClick={this.closeCheckModal}>
                {t('v4:no')}
              </Button>,
              <Button
                key="submit"
                shape="round"
                type="primary"
                onClick={this.handleSendEmail}
              >
                {t('v4:yes')}
              </Button>
            ]}
          >
            {!_.isEmpty(isNotExist) && (
              <div style={{ marginBottom: '1rem' }}>
                <p style={{ color: '#333333' }}>{t('labels:user_not_exist')}</p>
                <div
                  style={{
                    backgroundColor: '#f1f1f1',
                    borderRadius: '.5rem',
                    padding: '1rem'
                  }}
                >
                  {!_.isEmpty(isNotExist) &&
                    isNotExist.map((item, index) => (
                      <div className="selectedMedia">
                        <div className="titleMedia">
                          {index + 1}. {item.email}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            )}
            {!_.isEmpty(isNotInOrg) && (
              <div>
                <p style={{ color: '#333333' }}>
                  {t('labels:user_not_exist_in_org')}
                </p>
                <div
                  style={{
                    backgroundColor: '#f1f1f1',
                    borderRadius: '.5rem',
                    padding: '1rem'
                  }}
                >
                  {!_.isEmpty(isNotInOrg) &&
                    isNotInOrg.map((item, index) => (
                      <div className="selectedMedia">
                        <div className="titleMedia">
                          {index + 1}. {item.email}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            )}
          </Modal>
        )}
        <Page.Footer>
          <div className="p-footer">
            <div className="p-footer__inner">
              {selectedType === 1 && (
                <div className="p-footer__item1">
                  <Checkbox onChange={this.onAdminOnly} checked={isAdminOnly}>
                    {general.admin_only}
                  </Checkbox>
                </div>
              )}
              {(selectedType === 2 || selectedType === 3) && (
                <div className="p-footer__item1">
                  <Checkbox onChange={this.onSendEmail} checked={isSendMail}>
                    {general.send_email}
                  </Checkbox>
                </div>
              )}

              <div className="p-footer__item">
                <Button
                  className="rounded"
                  size="large"
                  onClick={() => history.push(`${rootPath}`)}
                >
                  {t('buttons:back_to_course')}
                </Button>
              </div>
              <div className="p-footer__item">
                <Button
                  className="rounded"
                  size="large"
                  type="green"
                  onClick={this.handleCheckUser}
                  disabled={isDisable}
                >
                  {t('v4:publish_scorm_course')}
                </Button>
              </div>
            </div>
          </div>
        </Page.Footer>
      </Fragment>
    )
  }
}

export default withTranslation('publishsettings')(PublishSettings)
