// import { Communication } from "./Communication.js"
import { MenuItem } from "./MenuItem.js"
import { ActivityRepository } from "./ActivityRepository.js"
import { Integration } from "./Integration.js"
import { config } from '../../../constants'
import { CmiCore } from "./CmiCore.js"

export default class Controller {
	activityRepository
	communication
	scormData

	currentActivity
	constructor(communication) {
		this.communication = communication
	}

	Initialize(scorm) {
		this.scormData = scorm
		this.activityRepository = new ActivityRepository()
		this.activityRepository.InitializeFromScorm(scorm)
		this.createMenuItem(null, this.activityRepository.activityTree, window.document);
		this.renderMenuItem(this.activityRepository.activityTree);

		if(this.activityRepository.hasMultipleSco()){
			Integration.ShowMenu()
		}
		const ac = this.activityRepository.getFirstLaunchActivity()

		this.choiceRequest(ac.getIdentifier())
	}


	createMenuItem(parentMenuItem, activity, documentObject) {
		activity.menuItem = new MenuItem(this, parentMenuItem, activity, documentObject)

		if (parentMenuItem !== null) {
			parentMenuItem.children[parentMenuItem.children.length] = activity.menuItem;
		}

		const availableChildren = activity.getChildren()
		for (let childActivity in availableChildren) {
			if (availableChildren.hasOwnProperty(childActivity)) {
				this.createMenuItem(activity.menuItem, availableChildren[childActivity], documentObject);
			}
		}
	}

	renderMenuItem(activity) {
		var childActivities,
			childActivity;
		
		//
		// This seems odd, but the activity is now a param to Render(). The reason being is that,
		// while the activity has an associated MenuItem, it's now possible that different activity
		// data can be used to render it (main sequencer or lookahead). As such, the activity object
		// is no longer a local member of MenuItem, thus we need to pass it in.
		//
		activity.menuItem.render(activity);

		childActivities = activity.getChildren();

		for (childActivity in childActivities) {
			if (childActivities.hasOwnProperty(childActivity)) {
				this.renderMenuItem(childActivities[childActivity]);
			}
		}
	}

	choiceRequest(activityIdentifier) {
		const cActivity = this.activityRepository.activityList.find(x => x.getIdentifier() === activityIdentifier)
		if (!cActivity) return
		if (this.currentActivity) {
			this.communication.setDataToServer(true)
		}
		
		var href = cActivity && cActivity.scormSco ? cActivity.scormSco.href : '' 
		var parameter = cActivity && cActivity.scormSco ? cActivity.scormSco.parameters : '' 
		var scormurl = this.scormData.root ? parameter ?
		`${this.scormData.cloud_name}/${this.scormData.course}/${this.scormData.root}/${href}?${parameter}`
		: `${this.scormData.cloud_name}/${this.scormData.course}/${this.scormData.root}/${href}`
			: `${this.scormData.cloud_name}/${this.scormData.course}/${href}`
		
		console.log('cActivity', cActivity, 'scormurl', scormurl);
		
		if (!cActivity.cmiCore) {
			this.communication.getRuntime({ sco_id: cActivity.getScoId(), user_id: window.lmsInfo.userId }, {
				success: (cmi_core) => {
					cActivity.cmiCore = new CmiCore(cmi_core.data)
					if (window.API) {
						window.API.InitializeForDelivery(cActivity)
						this.communication.setActivity(cActivity)
						Integration.SetCurrentActivity(`${config.scormURL}/${scormurl}`) //change base location later
					}
				}
			}
			)
		} else {
			if (window.API) {
				window.API.InitializeForDelivery(cActivity)
				this.communication.setActivity(cActivity)
				Integration.SetCurrentActivity(`${config.scormURL}/${scormurl}`)//change base location later

			}
		}

	}
}
