import React, { Component, Fragment } from 'react'
import { Form, Input } from 'antd'
import { withTranslation } from 'react-i18next'
import _ from 'lodash'

class NotesForm extends Component {
  onPressEnter = e => {
    if (e.shiftKey === false) {
      const value = this.props.form.getFieldValue('note')
      if (value !== '') {
        this.props.postNote(
          this.props.noteId,
          this.props.noteType,
          this.props.parentId,
          value
        )
      }
      this.props.form.setFieldsValue({ note: '' })
      e.preventDefault()
    }
  }

  getSmallProfileImage = image => {
    if (image) {
      const { link, resizes } = image
      if (_.isEmpty(resizes)) {
        return link
      } else {
        return resizes.thumbnail
      }
    }
    return ''
  }

  render() {
    const {
      user,
      form: { getFieldDecorator },
      t,
      isLoading
    } = this.props

    return (
      <Fragment>
        <Form>
          <Form.Item>
            {getFieldDecorator('note')(
              <Input.TextArea
                name="note"
                placeholder={t('add_note')}
                style={{
                  paddingLeft: '60px',
                  backgroundImage: `url(${this.getSmallProfileImage(
                    user.info.avatar
                  )})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: '10px 10px',
                  backgroundSize: '40px auto'
                }}
                disabled={isLoading}
                onPressEnter={this.onPressEnter}
                autoSize={{ minRows: 3 }}
              />
            )}
          </Form.Item>
        </Form>
      </Fragment>
    )
  }
}

export default Form.create()(withTranslation('notes')(NotesForm))
