import {
  LIST_QUESTIONS,
  LIST_QUESTIONS_PENDING,
} from '../types'
const initialState = {
  isLoading: false,
  triviaList: []
}
const triviaReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIST_QUESTIONS_PENDING:
      return {
        ...state,
        isLoading: action.payload
      }
    case LIST_QUESTIONS:
      return {
        ...state,
        triviaList: action.payload,
        isLoading: false
      }
    default:
      return state
  }
}

export default triviaReducer
