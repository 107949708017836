import { connect } from 'react-redux'
import Loading from './Loading'

const mapStateToProps = state => ({
  isFetching: state.user.isFetchingUser
})

const mapDispatchToProps = dispatch => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Loading)
