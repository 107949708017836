import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

import { Head } from '../../../styles'

import { styleguide } from '../../../constants'

const { colors } = styleguide

const Page = styled.div`
  width: 70%;
`

Page.Container = styled.div`
  border-bottom: .1rem solid #ddd;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2.3rem;

  ${props => props.dark && `
    background-color: #ddd;
    padding: 1.4rem 2.3rem;
  `}
`

Page.Head = styled.div`
`

Page.Head.Wrapper = styled(Head)`
  width: 100%;
  margin-bottom: 0;
`

Page.Head.Title = styled.div`
  font-weight: 700;
`

Page.Head.SubTitle = styled.div`
`

Page.Head.SubTitle.Link = styled(NavLink)`
  font-weight: 700;
`

Page.Video = styled.div`
  display: flex;
  justify-content: center;
`

Page.Video.Item = styled.div`
  width: 100%;
  height: 45rem;
  background-color: ${colors.darkGreyOne};
  position: relative;
`

Page.Video.Close = styled.a`
  color: ${colors.white};
  font-size: 2rem;
  position: absolute;
  top: 0;
  right: 0;
`

export {
  Page
}
