/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-08-09 16:41:54
 * @LastEditTime: 2019-12-19 10:53:49
 * @LastEditTime: 2019-09-09 11:43:43
 * @LastEditors: Please set LastEditors
 */

export default {
  general: {
    save: 'Salva',
    autosaved: 'Autosalvataggio',
    back_to: 'Torna A',
    by: 'Da',
    chat: 'Chat',
    completed: 'Completato',
    canceled: 'Cancellato',
    component: 'Componente',
    confirm: 'Conferma',
    course: 'Corso',
    date: 'Data',
    details: 'Dettagli',
    empty: 'No dati',
    enrolled: 'Registrato',
    free: 'Gratuito',
    graph: 'Grafico',
    loading: 'Caricamento…',
    lesson: 'Lezione',
    lessons: 'Lezioni',
    live_assessment: 'Valutazione Diretta',
    live_event: 'Evento Diretta',
    no: 'No',
    of: 'Di',
    or: 'O',
    organization: 'Organizzazione',
    page: 'Pagina',
    pages: 'Pagine',
    personal: 'Personale',
    poll: 'Sondaggio',
    rating: 'Valutazione',
    reset: 'Ripristina',
    signin: 'Registrati',
    signup: 'Regsitrati',
    soon: 'Presto',
    type: 'Tipo',
    uploading: 'Caricamento In Corso…',
    yes: 'Si',
    something_went_wrong: ' Qualcosa È Andato Storto!',
    view_all: 'Vedi Tutto',
    upload_successful: 'Caricato Con Successo',
    upload_successful_message: `I Vostri Documenti Excel Sono Stati Caricati Con Successo E Sono In Fase Di Elaborazione. Questo Potrebbe Richiedere Un Po' Di Tempo.`,
    check_email_upload_successful:
      'Per Favore <0>Controlla La Tua Email</0> Per Il <1>Report</1> Della Lista Degli Utenti Caricati',
    is_private: 'Privato'
  },

  account: {
    title: 'Account',
    subtitle: 'Gestisci Le Tue Impostazioni E Le Informazioni Sul Conto.',
    saved: 'Account Cambiato Con Successo'
  },

  assessments: {
    title: 'Valutazioni',
    subtitle: 'Crea Una Valutazione',
    finished: 'La Valutazione È Finita',
    countdown: 'La Valutazione In Diretta Verrà Trasmessa In',
    create: 'Crea Valutazione',
    list_title: 'Crea Le Tue Valutazioni',
    delete_warning: 'Sei Sicuro Di Voler Cancellare Questo Elemento?',
    details_title: 'Aggiungi Dettagli Valutazione',
    details_subtitle:
      'Date Un Nome Alla Vostra Valutazione E Dite Al Vostro Pubblico Cosa Si Aspetta Di Imparare Da Essa',
    disclaimer: `Garantisco Che La Mia Valutazione Registrata Non Farà Le Seguenti:
  - Violi I Diritti Di Terzi
  - Contenere Contenuti Illegali, Compresi Quelli Diffamatori, Osceni, Indecenti, Lascivi, Pornografici, Violenti, Abusivi, Ingiuriosi, Minacciosi O Discriminatori, Anche Travestiti Da Asterischi
  - Contenere Eventuali Attacchi Personali Ad Altre Persone
  - Contenere L'eventuale Impersonificazione Di Altri Partecipanti, Personaggi Pubblici O Celebrità
  - Contenere Qualsiasi Registrazione O Informazione Personale Su Qualsiasi Individuo Senza Il Consenso Di Tale Individuo E Non Viola Alcuna Normativa Sulla Protezione Dei Dati In Nessuna Parte Del Mondo
  - Contenere Qualsiasi Sollecitazione Commerciale O Qualsiasi Forma Di "Spam"`,
    assessment_details: 'Dettagli Valutazione',

    assessment_settings: 'Impostazioni Valutazione'
  },

  buildersettings: {
    title: 'Impostazioni Di Pubblicazione',
    subtitle:
      'Aggiungete le impostazioni al vostro evento, che possono visualizzare e interagire con il vostro evento.'
  },

  buttons: {
    market_place: 'Market Place',
    back_to_normal: 'Back To Normal',
    add: 'Aggiungi',
    add_page: 'Aggiungi Pagina',
    add_lesson: 'Aggiungi Lezioni',
    add_answer: 'Aggiungi Nuova Risposta',
    add_group: 'Aggiungi Gruppo',
    add_organization: 'Aggiungi Organizzazione',
    add_question: 'Aggiungi Domanda',
    add_quiz: 'Aggiungi Quiz',
    add_users: 'Aggiungi Utenti',
    back: 'Dietro',
    back_to_course: 'Torna Alla Panoramica Del Corso',
    back_to_lesson: 'Torna Alla Panoramica Della Lezione',
    back_to_media: 'Torna Alla Libreria Media',
    back_to_details: 'Torna Ai Dettagli',
    cancel: 'Cancella',
    clear: 'Pulisci',
    close: 'Chiudi',
    clone: 'Clona',
    close_lesson: 'Chiudi Lezione',
    clone_course: 'Chiudi Clone',
    confirm: 'Conferma',
    create: 'Crea',
    download: 'Scarica',
    download_mass_user_tamplate: 'Scarica Stampo',
    add_mass_users: 'Aggiungere Utenti Di Massa',
    upload_excel_list: 'Carica Lista Excel',
    delete: 'Cancella Media',
    edit: 'Modifica',
    edit_course: 'Modifica Corso',
    enroll: 'Iscriviti',
    finish: 'Finisci',
    upload_image: 'Carica Immagine',
    upload_image_video: 'Carica Immagine O Video',
    select_media_library: 'Seleziona Libreria Media',
    make_public: 'Rendi Pubblico',
    make_private: 'Rendi Privato',
    make_org: 'Crea Organizzazione',
    media: 'Libreria Media',
    my_theme: 'Il Mio Tema',
    next: 'Prossimo',
    next_lesson: 'Prossima Lezione',
    next_step: 'Prossima Fase',
    previous_step: 'Fase Precedenti',
    publish: 'Pubblica',
    publish_assessment: 'Pubblica La Tua Valutazione',
    publish_course: 'Pubblica Il Tuo Corso',
    publish_event: 'Pubblica Il Tuo Evento',
    reset: 'Resetta',
    reset_elements: 'Resetta elementi',
    save: 'Salva',
    creating: 'Creando...',
    updating: 'Aggiornando…',
    saved: 'Salvato',
    save_for_later: 'Salva Per Dopo',
    save_settings: 'Salva Impostazioni',
    select_files: 'Seleziona Files',
    select_theme: 'Seleziona Tema',
    start_lesson: 'Inizia Lezione',
    update: 'Aggiorna',
    upload: 'Carica',
    upload_audio: 'Carica Audio',
    upload_photo: 'Carica Una Foto Profilo',
    upload_video: 'Carica Video',
    enrolled: 'Iscriviti',
    unenroll: 'Disiscriviti',
    from_library: 'Libreria Media',
    remove: 'Rimuovi',
    removed: 'Rimosso',
    start_broadcast: 'Inizia Trasmissione',
    end_broadcast: 'Fine Trasmissione',
    camera: 'Camera',
    screen: 'Scherno',
    screen_with_camera: 'Scherno con Camera',
    insert: 'inserito',

    inserting: 'Inserimento'
  },

  card: {
    quick_add: 'Aggiunta Veloce',
    add: 'Aggiungi',
    clone: 'Clona',
    completed: 'Lezioni Completate'
  },

  course: {
    upcoming_course: 'Prossimo Corso',
    unPublishEvent: 'Evento Non pubblicato',
    unPublishAssessment: 'Valutazione Non pubblicata',
    pastCourse: 'Corso Di Formazione',
    upcoming: 'Prossimo Evento Diretta',
    pastEvent: 'Evento Diretta Passato',
    pastAssessment: 'Valutazione Diretta Passata',
    upcomingAssessment: 'Prossima Valutazione Diretta',
    created: 'Data Creata',
    assignees: 'Assegnatari',
    organisations:
      '{{{ Organizzazioni }}} Organizzazioni, <2> {{ Utenti }} Utenti</2>',
    event_date: `Data Dell'evento Diretta <1> {{ Data }} </1>`,
    delete_warning: 'Sei Sicuro Di Volerlo Cancellare',
    clonning_no_lesson:
      'Si Prega Di Aggiungere Almeno Una Lezione Per Clonare Questo Corso',
    clone_warning: 'Sei Sicuro Di Voler Clonare Questo',
    clone_success: 'È In Processo Di Clonazione',
    disclaimer: `Garantisco Che Il Mio Corso Non Farà Le Seguenti:
  - Violare I Diritti Di Terzi
  - Contenere Contenuti Illegali, Compresi Quelli Diffamatori, Osceni, Indecenti, Lascivi, Pornografici, Violenti, Abusivi, Ingiuriosi, Minacciosi O Discriminatori, Anche Travestiti Da Asterischi
  - Contenere Eventuali Attacchi Personali Ad Altre Persone
  - Contenere L'eventuale Impersonificazione Di Altri Partecipanti, Personaggi Pubblici O Celebrità
  - Contengano Informazioni Personali Su Qualsiasi Persona Senza Il Consenso Di Quest'ultima E Non Violino Le Norme Sulla Protezione Dei Dati Personali In Nessuna Parte Del Mondo
  - Contenere Qualsiasi Sollecitazione Commerciale O Qualsiasi Forma Di "Spam"`
  },

  coursecreate: {
    dropzone_text: 'Inserire Media',
    sidebar_text: 'Seleziona Un Tema <1/> Per Le Tue Lezioni <3/>',
    delete_warning: 'Sei Sicuro Di Voler Cancellare Questa Lezione?'
  },

  coursepage: {
    quizview_loading: 'Controllo Delle Risposte. Si Prega Di Attendere...',
    quizview_answer: 'La Tua Risposta'
  },

  coursequiz: {
    title: 'Quiz In Tutte Le Lezioni'
  },

  dashboard: {
    tooltip_title: 'Non Mostrare Più Questo Video',
    page_title: 'La Statistica Dei Tuoi Corsi',
    page_subtitle: 'Stato Dei Corsi Da Voi Creati',
    profile_add_info:
      'Aggiungi Il Tuo <1>{{{{Uncompletedtext}}</1> Per Completare Il Tuo Profilo',
    profile_build:
      'Costruisci Il Tuo Profilo <1>Qui</1>, Facci Conoscere I Tuoi Interessi E Le Tue Capacità',
    profile_title: 'Forza Del Profilo',
    more_details: 'Più Dettagli'
  },

  errors: {
    answer: 'Si Prega Di Inserire La Risposta',
    answer_correct: 'Scegliere La Risposta Corretta',
    code: 'Si Prega Di Inserire Il Codice',
    criterion: 'Si Prega Di Inserire Il Criterio',
    duration: 'La Durata È Obbligatoria',
    element: `Si Prega Di Inserire L'elemento`,
    email: 'Inserisci La Tua E-Mail',
    email_invalid: `'L'input Non È Un'e-Mail Valida`,
    feedback_correct: 'Si Prega Di Inserire Il Feedback Corretto',
    feedback_incorrect: 'Il Feedback Inserito È Errato',
    host: `Si Prega Di Inserire L'ospitante Dell'evento`,
    host_assessment: 'Si Prega Di Inserire L’ospitante Di Valutazione',
    image: `Si Prega Di Caricare Un'immagine`,
    location: 'Inserisci La Tua Posizione',
    live_location: 'Si Prega Di Scegliere Un Luogo Di Trasmissione',
    limit: 'Si Prega Di Inserire I Minuti Di Live-Streaming',
    min_3char: 'Minimo 3 Caratteri',
    number: 'Si Prega Di Inserire Il Numero Positivo',
    host_email_require: `Le E-Mail Dell'ospitante Sono Necessarie`,
    attendee_email_require: 'Le Email Dei Partecipanti Sono Richieste',
    name: 'Per Favore Inserisci Il Tuo Nome',
    not_found_course: 'Corso Non Trovato',
    not_found_event: 'Evento Non Trovato',
    not_found_assessment: 'Valutazione Non Trovata',
    password: 'Per Favore Inserisci La Tua Pasword',
    password_digit: 'Almeno Una Cifra',
    password_short: 'La Password È Troppo Corta, Minimo 10 Caratteri',
    password_special: 'Almeno Un Carattere Speciale',
    password_uppercase: 'Almeno Un Carattere In Maiuscolo',
    password_lowercase: 'Almeno Un Carattere In Minuscolo',
    phone: 'Per Favore Inserisci Il Tuo Telefono',
    phone_invalid: 'Numero Di Telefono Non Valido',
    position: 'Inserisci La Tua Posizione',
    question: 'Si Prega Di Inserire La Domanda',
    start_date: 'Data E Tempo Di Inizio Sono Richiesti',
    tag: `Si Prega Di Selezionare Un'etichetta`,
    topics: 'Si Prega Di Selezionare Almeno Un Argomento',
    live_topics: 'Si Prega Di Aggiungere Argomenti',
    title: 'Si Prega Di Inserire Il Titolo',
    title_assessment: 'Si Prega Di Inserire Il Titolo Di Valutazione',
    title_course: 'Si Prega Di Inserire Il Titolo Del Corso',
    title_empty: 'Il Titolo Non Può Essere Vuoto',
    title_event: `Si Prega Di Inserire Il Titolo Dell'evento`,
    title_lesson: 'Si Prega Di Inserire Il Titolo Della Lezione',
    title_quiz: 'Si Prega Di Inserire Il Nome Del Quiz',
    username: 'Inserisci Il Tuo Nome Utente',
    upload_error: ' Errore - File Non Compatibile',
    upload_more_than_10:
      'È Possibile Caricare Solo Un Massimo Di 10 File Alla Volta',
    topics_max: 'Si Prega Di Aggiungere 3 Argomenti Al Massimo',
    upload_excel: 'Si Prega Di Assicurarsi Di Aver Caricato Il File Corretto',
    title_excel: 'Errore Nel Caricamento Del Modello',
    file_size_exceed: 'La Dimensione Del File Supera Il Limite',
    subtitle_excel:
      'Si Prega Di Assicurarsi Di Utilizzare Il Modello Fornito E Di Compilare Correttamente Le Colonne',
    select_broadcast_option: `Si Prega Di Selezionare Un'opzione Per La Trasmissione Di Questo Evento`,
    multi_hosts_number_error: `Il Numero Del Presentatore Non Può Superare Quello Specificato Per L'evento`,
    conference_people_number_error: `Il Numero Di Partecipanti Non Può Superare Quello Specificato Per L'evento`,
    select_max_host: `Selezionare Almeno 1 Presentatore Per L'evento`,
    select_max_conference: `Selezionare Almeno 1 Partecipante Per L'evento`,
    stream_stop_error: 'Lo Streaming Si È Fermato Inaspettatamente!',
    unsecure_context: `Errore Fatale: Il Browser Non Può Accedere Alla Fotocamera E Al Microfono A Causa Del Contesto Non Sicuro. Si Prega Di Installare SSL E L'accesso Tramite Https`,
    ws_not_supported:
      'Errore Fatale: Websocket Non È Supportato In Questo Browser',
    no_org: 'Selezionare almeno 1 organizzazione per procedere',
    no_org_group:
      'Selezionare almeno 1 gruppo di organizzazioni per procedere.',
    waiting_to_join: 'Aspetto che qualcuno si unisca!'
  },

  events: {
    title: `Aggiungi Dettagli Dell'evento In Diretta`,
    change_host: 'Cambia Ospite',
    finished: `L'evento È Finito`,
    countdown: `L'evento In Diretta Sarà Trasmesso In`,
    subtitle:
      'Date Un Nome Al Vostro Evento Diretta E Dite Al Vostro Pubblico Cosa Si Aspetta Di Imparare Da Esso',
    poll_disabled: `Garantisco Che Il Mio Evento Registrato Non Farà Le Seguenti:
  - Infrangere I Diritti Di Terzi
  - Contenere Contenuti Illegali, Compresi Quelli Diffamatori, Osceni, Indecenti, Lascivi, Pornografici, Violenti, Abusivi, Ingiuriosi, Minacciosi O Discriminatori, Anche Travestiti Da Asterischi
  - Contenere Eventuali Attacchi Personali Ad Altre Persone
  - Contenere L'eventuale Impersonificazione Di Altri Partecipanti, Personaggi Pubblici O Celebrità
  - Contenere Qualsiasi Registrazione O Informazione Personale Su Qualsiasi Individuo Senza Il Consenso Di Tale Individuo E Non Viola Alcuna Normativa Sulla Protezione Dei Dati In Nessuna Parte Del Mondo
  - Contengono Qualsiasi Sollecitazione Commerciale O Qualsiasi Forma Di "Spam"`,

    prompt_message:
      'Lasciando Questa Pagina, Il Vostro Evento Sarà Terminato. Sei Sicuro Di Voler Lasciare Questa Pagina?',
    duration_exceed_message: `Il Tuo Tempo Di Trasmissione Supera, l'App Smetterà Di Trasmettere.`,
    before_schedule_message: `State Per Iniziare La Trasmissione Prima Dell'orario Previsto Per L'evento. Si Prega Di Notare Che L'interruzione Della Trasmissione Porrà Fine All'evento.`,
    browser_unsupportive_message:
      'Il Tuo Browser Non Supporta La Condivisione Dello Schermo. Potete Vedere I Browser Supportati In Questo Link',
    cancel_switch_host_message: `L'host Non È Disponibile. L'evento Continuerà Dalla Vostra Fine`,
    coaching_prompt_message: `Non È Possibile Lasciare Questa Pagina Fino Alla Fine Dell'evento`,
    event_modal_warn: 'Vuoi Lasciare Questo Evento?',
    confirm_message: 'Il Vostro Evento Terminerà Se Cliccate Su OK',
    screen_share_denied: 'Hai Negato Di Condividere Il Tuo Schermo',
    media_not_found:
      'La Fotocamera O Il Microfono Non Si Trovano O Non Sono Ammessi Nel Dispositivo',
    Media_Not_Access:
      'La Fotocamera O Il Microfono È Utilizzato Da Qualche Altro Processo Che Non Permette di leggere i dispositivi',
    constraint_error:
      'Non È Stato Trovato Alcun Dispositivo Che Si Adatti Ai Vostri Vincoli Video E Audio. È Possibile Modificare I Vincoli Video E Audio',
    media_access_denied: `Non È Consentito L'accesso Alla Telecamera E Al Microfono.`
  },

  filters: {
    days7: 'Ultimi 7 Giorni',
    days15: 'Ultimi 15 Giorni',
    days30: 'Ultimi 30 Giorni',
    days365: 'Ultimi 365 Giorni',
    date: 'Data Creata',
    relevance: 'Rilivanza',
    popularity: 'Popolarità',
    name: 'Nome',
    group: 'Gruppo',
    Newest_to_Oldest: 'Dal più Nuovo al più Vecchio',
    Oldest_to_Newest: 'Dal più Vecchio al più Nuovo',
    A_Z: 'A alla Z',
    Z_A: 'Z a A'
  },

  helpers: {
    password:
      'Per Garantire La Sicurezza Del Tuo Conto Abbiamo Bisogno Di Almeno Un Numero Minuscolo Nella Tua Password. È Inoltre Necessario Avere Una Lunghezza Minima Di 10 Caratteri',
    phone:
      'Si Prega Di Includere Ad Esempio Il Prefisso Del Paese In Cui Si Trova Il Telefono: +65',
    username:
      'Il Tuo Nome Utente Viene Trattato Come Informazione Pubblica E Condiviso Con Altri Utenti A Seconda Delle Necessità. Come Tale, Si Consiglia Di Non Utilizzare Informazioni Personali Nel Proprio Nome Utente',
    public_description_normal: `Il Tuo <0><0/></0> È Stato Salvato Ed È Considerato Privato Per Impostazione Predefinita. Ciò Significa Che Solo Tu Puoi Vedere Questo Contenuto. Per Pubblicare Questo Contenuto È Possibile Selezionare Una Delle Opzioni Seguenti. Pubblicare Il Tuo Corso Al Pubblico Significa Che Sarà Disponibile Per Tutti Gli Utenti Della Piattaforma. L'opzione Utenti Specifici Ti Permetterà Di Condividere Il Tuo Corso Con Utenti Specifici Utilizzando L'indirizzo E-Mail Associato Al Loro Account Sharelook.`,
    public_description_admin: `Questo <0><0/></0> È Stato Salvato Ed È Considerato Privato Per Impostazione Predefinita. Questo Significa Che Solo Tu (E Il Creatore Del Contenuto) Puoi Vedere Questo Contenuto. Per Pubblicare Questo Contenuto È Possibile Selezionare Una Delle Opzioni Seguenti. Pubblicare Il Tuo Corso Al Pubblico Significa Che Sarà Disponibile Per Tutti Gli Utenti Della Piattaforma - Compresi Quelli Al Di Fuori Della Tua Organizzazione. Pubblicarlo All'organizzazione Significa Che Tutti Gli Utenti All'interno Dell'organizzazione Potranno Vederlo. Potete Anche Pubblicarlo A 1 O Più Gruppi, Il Che Significa Che Solo Gli Utenti All'interno Di Tali Gruppi Potranno Vederlo.  Infine, Avete La Possibilità Di Pubblicarlo A Specifici Utenti All'interno E All'esterno Della Vostra Organizzazione Utilizzando L'indirizzo E-Mail Del Loro Account Sharelook.`
  },

  labels: {
    add: 'Aggiungi',
    portrait: 'Crop Portrait',
    landscape: 'Crop Landscape',
    answer_type: 'Tipo Di Risposta',
    answers: 'Risposte',
    back_to_quiz: 'Torna Alla Lista Quiz',
    back_to_question: 'Torna Alla Lista Domande',
    chat: 'Chat',
    chat_available: 'La Chat Non È Disponibile In Questo Evento',
    chat_report: 'Chat Report',
    choice_single: 'Scelta Singola',
    choice_multiple: 'Scelta Multipla',
    choice_free: 'Scelta “Libera”',
    choice_sorting: 'Scelta “Ordinamento”',
    choice_matrix: 'Scelta “Ordinamento Matrice”',
    clone_lesson_permission: 'Permesso Di Clonazione',
    code: 'Codice',
    correct: 'Correto',
    created: 'Data Creata',
    clone_modal: 'Clona Corso',
    cloning_course: 'Clonando Corso...',
    cloning: ' Clonando…',
    cloning_course_desc:
      'Questo Processo Richiederà Del Tempo. Per Favore Sii Paziente',
    cloning_success: 'Il Corso "<0/>" È Stato Clonato Con Successo!',
    description: 'Descrizione',
    disclaimer: 'Avvertenza',
    drop_file: ' Lascia File Qui…',
    duration: 'Durata',
    edit: 'Modifica',
    email: 'Email',
    event: 'Evento',
    event_all: 'Tutti I Suoi Eventi',
    event_date: 'Data/Tempo Locale Evento Diretta',
    event_this: 'Quest’ Evento',
    expire: 'Scade Il',
    expiry_date: 'Data Di Scadenza',
    forgot: 'Password Dimenticata?',
    group: 'Gruppo',
    groups_list: 'Lista Di Gruppi',
    host: 'Ospitante',
    id_annotation: 'Annotazione ID',
    id_quiz: 'Quiz ID',
    incorrect: 'Incorreto',
    interests: 'Interessi',
    invited: 'Invitato',
    live_chat: 'Discussione Diretta',
    live_stream_limit: 'Minuti Live-streaming',
    contact_text:
      'Per Modificare Il Limite Mensile Di Live-Streaming Si Prega Di Contattare',
    contact_admin:
      '<0>Se Desiderate Modificare Il Vostro Limite Mensile Di Live-Streaming Contattate</0> <1>Admin@Sharelook.Com</1>',
    limit_stream_text:
      ' Live-Streaming Minuti Rimanenti: <0>{{ Limite }}}</0> Minuti <1/><2/> <3> Per Modificare Il Limite Mensile Di Live-Streaming Si Prega Di Contattare</3> <4>Admin@Sharelook.Com</4>',
    location: 'Locazione',
    lessons: 'Lezioni',
    location_broadcast: 'Posizione Del Server Host',
    location_broadcast_subtitle: `Aggiungete Una Posizione Che Sia La Più Vicina Alla Posizione Dell'ospite Della Trasmissione Per Ridurre Il Ritardo Audiovisivo`,
    media_manager: 'Gestore Media',
    minutes: 'Minuti',
    name: 'Nome',
    online_quiz: 'Quiz Online',
    organiser: 'Autore',
    organization: 'Organizzazione',
    overall_emotion: 'Esperienza In Generale',
    organizations_list: 'Lista Organizzazioni',
    password: 'Password',
    participants: 'Partecipanti',
    picture: 'Immagine (16:9)',
    phone: 'Telefono',
    photo: 'Foto',
    poll: 'Sondaggio',
    position: 'Posizione',
    processing: 'Processamento',
    privacy_settings: 'Impostazioni Privacy',
    question: 'Domanda',
    quiz_create: 'Crea Nuovo Quiz',
    quiz_name: 'Nome Quiz',
    quiz_update: 'Aggiorna Quiz',
    select_organization: 'Seleziona Organizzazione',
    settings: 'Impostazioni',
    show: 'Mostra',
    show_graph: 'Mostra Risultato Grafico',
    signin: 'Segnati',
    signup: 'Iscriviti',
    sort_by: 'Ordina Per',
    start_date: 'Data Inizio',
    tags: 'Etichette',
    terms: 'Accetto Questi <1>Termi E Condizioni</1>',
    title: 'Titolo',
    topics: 'Argomenti',
    topic: 'Argomento',
    total_comments: 'Commenti Totali',
    username: 'Nome Utente',
    users_list: 'Lista Utenti',
    users_emails: 'Inserire Email Specifiche Per Gli Utenti',
    description_specifiction_email:
      'Si Prega Di Inserire 1 Indirizzo E-Mail Per Riga Per Condividerlo Con Più Utenti Specifici.',
    message_specific_email: `Si prega di inserire un'e-mail dell'utente specifico.`,
    viewers: 'Visitatori',
    edit_course: 'Modifica',
    edit_assessment: 'Modifica',
    edit_event: 'Modifica',
    delete_course: 'Cancella',
    delete_event: 'Cancella',
    delete_assessment: 'Cancella',
    view_course: 'Vedi',
    view_event: 'Vedi',
    view_assessment: 'Vedi',
    date_event: 'Data evento Diretta',
    date_assessment: 'Data Valutazione Diretta',
    clone_of: 'Clona',
    clone_course: `Crea Un Clone Di Questo Corso Che Puoi Modificare Indipendentemente Dall'originale, Include Tutte Le Lezioni E Le Pagine`,
    make: 'Crea',
    available_to: 'Disponibile Per Qualsiasi Utente Di Sharelook',
    uploaded_by: 'Caricata Da',
    course: 'Corso',
    assessment: 'Valutazione',
    user_not_exist: `L'utente Non Esiste, Vuoi Inviare Un Invito?`,
    check_user: 'Avviso',
    user_not_exist_in_org: `L'utente Non Esiste In Questa Organizzazione, Volete Inviare Un Invito? `,
    title_modal_stream: `Cambiamento Del Limite Dell'organizzazione Livestream`,
    des_warning_stream: 'Stai Per Cambiare',
    monthly_stream: 'Limite Mensile Di Live-Streaming',
    min_per_month: 'Minuti/Mese',
    broadcast_label: 'Voglio Fare',
    hosts_emails: `Inserire L'indirizzo E-Mail Specifico Dell'host`,
    attendee_emails: 'Inserire Le E-Mail Dei Partecipanti',
    description_host_specifiction_email:
      'Si Prega Di Inserire 1 Indirizzo E-Mail Per Riga',
    presenter_label: 'Presentatore',
    presenter_label_conference: 'No. Di Persone',
    event_privacy: 'Modalità Conferenza'
  },

  broadcastOptions: {
    stream_from_phone: 'Trasmissione In Diretta Con Il Mio Telefono',
    other_webrtc: 'Webinar Dal Mio PC',
    multihost: 'Webinar Multicast Dal Mio PC',
    peer2peer: 'Sessione Di Coaching',
    conference: 'Conferenza Diretta'
  },

  lessoncreate: {
    title: 'Aggiungi Dettagli Lezione',
    subtitle:
      'Date Un Nome Alla Vostra Lezione E Dite Al Vostro Pubblico Cosa Si Aspetta Di Imparare Da Essa',
    input_label_title: 'Titolo Lezione',
    input_label_topics: 'Argomenti Lezione',
    input_label_description: 'Descrizione Lezione',
    delete_warning: 'Sei Sicuro Di Voler Cancellare Questa Pagina? '
  },

  lessonoverview: {
    title: 'Aggiungi Lezione',
    subtitle: 'Aggiungere Una Lezione Esistente O Crearne Una Nuova',
    new_lesson: 'Creare Una Nuova Lezione',
    new_lesson_sub: 'Creare Una Lezione Nuova Di Zecca Da Zero!',
    existing_lesson: 'Selezionare La Lezione Esistente',
    existing_lesson_sub:
      'Collegamento A Una Lezione Esistente, Le Modifiche Apportate A Questa Lezione Influenzeranno Anche Questa Lezione In Altri Corsi.',
    clone_lesson: 'Duplicare La Lezione Esistente',
    clone_lesson_sub:
      'Fai Una Copia Unica Della Lezione In Questo Corso, Le Modifiche Apportate A Questa Lezione Non Influenzeranno La Lezione Originale',
    clone_lesson_modal_sub:
      'Clicca Sul Pulsante "Clona" Sotto La Lezione Che Vuoi Aggiungere Al Tuo Corso.'
  },

  management: {
    groups_title: 'Gestisci Gruppi',
    delete_warning: 'Sei Sicuro?',
    organizations_title: 'Le Mie Organizzazioni',
    organizations_subtitle: 'Aggiungi Organizzazioni Alla Tua Lista',
    users_title: 'Gestire Gli Utenti',
    users_position: 'Gestore Account Senior',
    user_not_found: 'Utente Non Trovato',
    select_org: `Trovare E Scegliere Un'organizzazione Per La Gestione Degli Utenti`
  },

  audio: {
    modal_title: 'Inserisci Audio',
    list_view: 'Vista Lista',
    create_audio_modal_title: 'Crea Audio',
    edit_audio_modal_title: 'Modifica Audio',
    public_audio_not_found: 'Audio Non Trovato',
    private_audio_not_found: `Non Hai Ancora Caricato L'audio`,
    are_you_sure_to_delete: 'Sei Sicuro Di Voler Cancellare?',
    type_something: 'Digita Qualcosa',
    select_voice: 'Seleziona',
    file_name: 'Nome File',
    description: 'Descrizione',
    topic: 'Argomento',
    key_words_tag: 'Etichetta Parole chiave',
    mp3_audio: 'Audio Mp3',
    created: 'Creato',
    duration: 'Durata',
    close: ' chiudi '
  },

  image: {
    modal_title: 'Inserisci Immagine',
    image: 'Immagine',
    pixel: 'Pixel',
    dimension: 'Dimensione',
    public_image_not_found: 'Imagine Non Trovata',
    private_image_not_found: ' Non Hai Ancora Caricato Nessuna Immagine'
  },

  annotation: {
    modal_title: 'Annotazione'
  },

  text: {
    modal_title: 'Editor Testo'
  },

  video: {
    modal_title: 'Inserire Video',
    image: 'Video',
    mp4_video: 'Video Mp4',
    public_video_not_found: 'Video Non Trovato',
    private_video_not_found: 'Non Hai Ancora Caricato Alcun Video'
  },

  media: {
    title: 'Libreria Media',
    subtitle: 'Libreria Dei Tuoi File',
    modal_title: 'Carica Media',
    delete_warning: 'Conferma Cancella',
    delete_message:
      ' I Media Cancellati Appariranno Ancora Nelle Pagine In Cui Sono Stati Utilizzati',
    dropzone_text: 'O Trascina E Rilascia',
    delete_success: 'I Media Sono Stati Cancellati Con Successo',
    upload_file: 'Carica File',
    drag_drop: 'O Trascina E Rilascia',
    import_file: 'Importare Il/I File/I Dal Computer',
    no_media: 'Non Hai Ancora Caricato Alcun Media',
    media_type: 'Tipo Di Media',
    video: 'Video',
    image: 'Immagine',
    audio: 'Audio',
    gif: 'GIF',
    transcribed_text: 'Transcribed Text',
    media_not_found: 'Media Non Trovato.',
    of: 'Di',
    disclaimer: `Garantisco Che I Miei Media Non Lo Fanno:
  - Violare I Diritti Di Terzi
  - Contenere Contenuti Illegali, Compresi Quelli Diffamatori, Osceni, Indecenti, Lascivi, Pornografici, Violenti, Abusivi, Ingiuriosi, Minacciosi O Discriminatori, Anche Travestiti Da Asterischi
  - Contenere Eventuali Attacchi Personali Ad Altre Persone
  - Contenere L'eventuale Impersonificazione Di Altri Partecipanti, Personaggi Pubblici O Celebrità
  - Contengano Informazioni Personali Su Qualsiasi Persona Senza Il Consenso Di Quest'ultima E Non Violino Le Norme Sulla Protezione Dei Dati Personali In Nessuna Parte Del Mondo
  - Contenere Qualsiasi Sollecitazione Commerciale O Qualsiasi Forma Di "Spam".`,
    my_media: 'Il Mio Media',
    public_library: 'Libreria Pubblica',
    organization_library: 'Organizzazione Libreria',
    upload_from_computer: 'Aggiorna dal Computer',
    create: 'Crea',
    all: 'Tutto',
    male: 'Maschio',
    female: 'Femmina',
    processing_audio: 'Processamento Audio',
    create_annotation: 'Crea Annotazione',
    popup_over_lesson_text: `L'annotazione apparirà sulla pagina della lezione dopo il click`,
    edit_detail: 'Modifica Dettaglio',
    delete: 'Cancella',
    go_create_audio: 'Vai a Crea Audio',
    show_more_details: 'mostra più dettagli',
    show_less_details: 'mostra meno dettagli',
    drop_files: 'Lascia file e dove aggiornare',
    or: 'O',
    import_files: 'Importa file dal computer'
  },
  navigation: {
    account: 'Account',
    assessments: 'Valutazioni',
    dashboard: 'Dashboard',
    groups: 'Gestione Gruppo',
    logout: 'Disconnettiti',
    organisations: 'Organizzazioni',
    organizations_users: 'Organizzazioni & Utenti',
    reports: 'Reports',
    schedule: 'Programma',
    training: 'Formazione',
    users: 'Utenti',
    workshop: ' Il Mio Workshop',
    nowLive: 'Trasmissione'
  },

  onboarding: {
    step1_title: `Passo 1: Raccontaci un po' di te`,
    step1_subtitle:
      'Come Ti Chiami? Da Dove Vieni? Qual È Il Tuo Titolo Di Lavoro?',
    step2_title:
      'Fase 2 : Selezionare Alcune Abilità Che Si Possono Migliorare',
    step2_subtitle:
      'Diteci Poi Cosa Vorreste Capire Meglio. Forse Qualcosa Che Non Avete Ancora Capito? Un Nuovo Campo Di Studio? Cosa Fa La Persona Che Ami?'
  },

  pagebuilder: {
    title: 'Crea Lezione',
    subtitle: `E' Tempo Di Creare I Contenuti Per Il Vostro Pubblico Per Imparare Da Voi, È Possibile Immagini, Video E Infografiche`,
    select_layout: 'Selezionare Un Layout Di Pagina',
    template1: 'Vuoto',
    template2: 'Titolo E Sottotitolo',
    template3: 'Titolo E Testo',
    template4: 'Titolo, Media E Testo',
    template5: 'Titolo E Media',
    template6: 'Titolo E Quiz'
  },

  pageconstructor: {
    title: 'Crea E Clona Lezione',
    subtitle: `E' Il Momento Di Creare I Contenuti Che Il Vostro Pubblico Può Imparare Da Voi, Potete Aggiungere Immagini, Video E Infografiche`
  },

  passwordreset: {
    success_coded: `Abbiamo Inviato Un Codice Di Conferma Via E-Mail All'indirizzo Da Voi Fornito, Vi Preghiamo Di Copiare Il Codice Nel Campo Sottostante Per Impostare Una Nuova Password`,
    success_changed:
      'Hai Impostato Con Successo Una Nuova Password. Puoi Accedere Ora'
  },

  placeholders: {
    search: 'Cerrca',
    answer: 'Rispondi',
    chat: 'Dì Qualcosa…',
    code: 'Codice',
    criterion: 'Criterio',
    date_event: 'Seleziona Data E Ora',
    date_start: 'Data Inizio',
    date_expire: 'Data Scadenza',
    description: 'Descrizione',
    elements: 'Ordina Elementi',
    email: 'Email',
    feedback_correct: 'Feedback Corretto',
    feedback_incorrect: 'Feedback Incorretto',
    host: 'Ospitante',
    keywords: 'Cerca Parola/E Chiave',
    lesson_description: 'Descrizione Lezione',
    location: 'Locazione',
    minutes: 'Minuti',
    name: 'Nome',
    organiser: 'Autore',
    organization: 'Organizzazione',
    password: 'Password',
    phone: 'Telefono',
    photo: 'Foto',
    picture: 'Immagine',
    point: 'Punto',
    position: 'Posizione',
    question: 'Domanda',
    quiz: 'Nome quiz',
    search_course: 'Cerca Per Corso',
    search_media: 'Cerca Media',
    search_name: 'Cerca Per Nome',
    search_title: 'Cerca Per Titolo',
    search_training: 'Cerca Per Formazione',
    settings: 'Impostazioni',
    tags: 'Etichette',
    title: 'Titolo',
    topics: 'Argomenti',
    username: 'Nome Utente',
    keyword: 'Parole Chiave',
    max_500_characters: 'Massimo 500 caratteri',
    no_description: 'Questo File Non Ha Una Descrizione',
    no_keyword: 'Questo File Non Contiene Parole Chiave',
    no_topics: 'Questo File Non Ha Un Set Di Argomenti',
    feedback: 'Messaggio',
    contact: 'Come Possiamo Aiutare?'
  },

  publishsettings: {
    title: 'Impostazioni pubblicazioni',
    event_subtitle:
      'Aggiungete le impostazioni al vostro evento, che possono visualizzare e interagire con il vostro evento',
    assessment_subtitile:
      'Date un nome alla vostra valutazione e dite al vostro pubblico cosa si aspetta di imparare da essa',
    course_subtitle:
      'Aggiungete le impostazioni al vostro corso, che possono visualizzare e interagire con il vostro corso',
    disclaimer: `•	Registrazione
  Registrandosi Come Utente/Creando Un Account, L'utente Accetta Il Nostro [Accordo Per Gli Utenti] E Riconosce Di Aver Letto La Nostra [Informativa Sulla Privacy].,
	•	Creare Il Corso
  Utilizzando I Nostri Strumenti E Creando Il Contenuto Del Corso, L'utente Accetta Il Nostro [Accordo Con L'utente] E Riconosce Di Aver Letto La Nostra [Informativa Sulla Privacy].,
•	Crea Evento
  Utilizzando I Nostri Strumenti E Creando Un Evento, L'utente Accetta Il Nostro [Accordo Per Gli Utenti] E Riconosce Di Aver Letto La Nostra [Informativa Sulla Privacy].,
	•	Creare Una Valutazione
  Utilizzando I Nostri Strumenti E Creando Una Valutazione, L'utente Accetta Il Nostro [Accordo Per Gli Utenti] E Riconosce Di Aver Letto La Nostra [Informativa Sulla Privacy].`,

    dates_validation_error: 'Entrambe Le Date Devono Essere Compilate',
    emails_validation_error: 'La Lista Delle Email Non È Valida',
    specific_email_validation_error: `E' Consentita Una Sola E-Mail`,
    i_agree_to_these: `Sono D'accordo Con Queste`,
    terms_and_conditions: ' Termini E Condizioni ',
    PublicDescription: `Utenti Indipendenti E Gratuiti: (Opzioni Private E Pubbliche)Il Tuo [Corso|Evento|Valutazione] È Stato Salvato Ed È Considerato Privato Per Impostazione Predefinita. Ciò Significa Che Solo Tu Puoi Vedere Questo Contenuto. Per Pubblicare Questo Contenuto Puoi Scegliere Di Renderlo Pubblico Qui Sotto. Ciò Significa Che Il Corso Sarà Disponibile Per Tutti Gli Utenti Della Piattaforma`,
    OrganisationsDescription: `Utenti Dell'org: (Invece Di ""Finire"" Utilizzare ""Pubblicare"" E Portarli Alla Pagina Di Pubblicazione Come Gli Altri Utenti, Ma Senza Alcuna Delle Opzioni Di Pubblicazione) Il Tuo [Corso|Evento|Valutazione] È Stato Salvato Ed È Privato. Questo Significa Che Solo Tu E Gli Amministratori Della Tua Organizzazione Potete Vederlo. In Quanto Utente, Non Sei In Grado Di Pubblicare I Tuoi Contenuti All'interno Della Tua Organizzazione In Quanto Ciò Richiede Diritti Di Amministratore. Contattate Uno Dei Vostri Amministratori E Chiedete Loro Di Pubblicare I Vostri Contenuti Pubblicamente, All'intera Organizzazione, A Gruppi Specifici All'interno Della Vostra Organizzazione O A Specifici Utenti`,
    OrganisationGroupsDescription: `Org Admins: (Per I Contenuti Realizzati All' interno Dell'organizzazione: Privato, Pubblico, Organizzazione, Gruppi, Utenti Specifici):Questo [Corso|Evento|Valutazione] È Stato Salvato Ed È Considerato Privato Per Impostazione Predefinita. Ciò Significa Che Solo Tu (E Il Creatore Del Contenuto) Puoi Vedere Questo Contenuto . Per Pubblicare Questo Contenuto È Possibile Selezionare Una Delle Opzioni Seguenti. Pubblicare Il Tuo Corso Al Pubblico Significa Che Sarà Disponibile Per Tutti Gli Utenti Della Piattaforma - Compresi Quelli Al Di Fuori Della Tua Organizzazione. Pubblicarlo All'organizzazione Significa Che Tutti Gli Utenti All'interno Dell'organizzazione Potranno Vederlo. Potete Anche Pubblicarlo A 1 O Più Gruppi, Il Che Significa Che Solo Gli Utenti All'interno Di Tali Gruppi Potranno Vederlo.  Infine, Avete La Possibilità Di Pubblicarlo A Specifici Utenti All'interno E All'esterno Della Vostra Organizzazione Utilizzando L'indirizzo E-Mail Del Loro Account Sharelook`,
    SpecificUsersDescription: `Utenti Indipendenti A Pagamento: (Utenti Privati, Pubblici E Specifici)Il Tuo [Corso|Evento|Valutazione] È Stato Salvato Ed È Considerato Privato Per Impostazione Predefinita. Ciò Significa Che Solo Tu Puoi Vedere Questo Contenuto. Per Pubblicare Questo Contenuto È Possibile Selezionare Una Delle Opzioni Seguenti. Pubblicare Il Tuo Corso Al Pubblico Significa Che Sarà Disponibile Per Tutti Gli Utenti Della Piattaforma. L'opzione Utenti Specifici Ti Permetterà Di Condividere Il Tuo Corso Con Utenti Specifici Utilizzando L'indirizzo E-Mail Associato Al Loro Account Sharelook.`
  },

  quiz: {
    quiz_empty: 'Non Hai Ancora Creato Un Quiz',
    questions_empty: 'Non Hai Ancora Creato Una Domanda'
  },

  regions: {
    asia_pacific_australia: 'Australia',
    asia_pacific_india: 'India',
    asia_pacific_japan: 'Giappone',
    asia_pacific_s_korea: 'Corea del Sud',
    asia_pacific_singapore: 'Singapore',
    asia_pacific_taiwan: 'Taiwan',
    eu_belgium: 'Belgio',
    eu_germany: 'Germania',
    eu_ireland: 'Irlanda',
    south_america_brazil: 'Brasile',
    us_central_iowa: 'Iowa',
    us_east_s_carolina: 'Carolina Del Sud',
    us_east_virginia: 'Virginia',
    us_west_california: 'California',
    us_west_oregon: 'Oregon'
  },

  reports: {
    title: 'Reports',
    workshop_report: 'Workshop Report',
    user_report: 'Report Utente',
    organization_workshop: 'Organizzazione workshop',
    course: 'Corso',
    live_event: 'Evento Diretta',
    workShop_graph: 'Grafico workshop',
    organization_account: 'Organizzazione account',
    all: 'Tutti',
    name: 'Nome',
    id_number: 'Numero ID',
    email: 'Email',
    search_user_report: 'Cercare Report Utente',
    participant: 'Partecipante',
    participants: 'Partecipanti',
    host: 'Ospitante',
    username: 'Nome utente',
    event: 'Evento',
    quiz: 'Quiz',
    assessment: 'Valutazione',
    time: 'Tempo',
    no_recent_activity: 'Nessuna Attività Recente',
    workshops: 'Workshops',
    courses: 'Corsi',
    live_events: 'Eventi Diretta',
    live_assessments: 'Valutazioni Diretta',
    quizzes: 'Quiz',
    overview: 'Panoramica',
    by: 'Da',
    views: 'Visualizzazioni',
    status: 'Stato',
    poll: 'Sondaggio',
    comment: 'Commento',
    comments: 'Commenti',
    no_comments: 'Nessun Commento',
    no_polls: 'No Sondaggi',
    live_date: 'Data Diretta',
    score: 'Punteggio',
    question: 'Domanda',
    submit: 'Invia',
    recent_activity: 'Attività recente',
    ago: 'Fa',
    no_data_found: 'Nessun dato trovato',
    start_end_live: 'Inizio/ Fine diretta',
    join_leave_date: 'Unisciti/Esci',
    watched_time: 'Tempo Guardato',
    complete: 'Completo',
    in_complete: 'In Completo',
    today: 'Oggi',
    seven_days: 'Giorni',
    last_month: `'L'ultimo Mese`,
    one_year: '1 Anno',
    all_time: 'Tutto il Tempo',
    report: 'Report',
    from_date: 'Dalla Data',
    to_date: 'Alla Data',
    all_workshop: 'Tutto il Workshop',
    all_participants: 'Tutti i Partecipanti',
    live_assessment: 'Valutazione Diretta',
    users: 'Utenti',
    download: 'Scarica',
    recent: 'Recente',
    WorkShop: 'Workshop',
    Activity: 'Attività',
    created_date: 'Crea data',
    lessons: 'Lezioni',
    lesson: 'Lezione',
    completed: 'Completato',
    report_download: 'Scarica report',
    this_month: 'Questo mese',
    search_course: 'Cerca corso',
    participants_graph: 'Grafico partecipanti',
    viewed: 'Visto',
    enrolled: 'Iscritto',
    description: 'Descrizione',
    topic: 'Argomento',
    analytic: 'Analitica',
    quiz_list: 'Lista quiz',
    select_one: 'Seleziona Uno',
    group_status: 'Stato gruppo',
    duration_min: 'Durata(min)',
    submit_poll: 'Invia sondaggio',
    download_events: 'Scarica Eventi',
    search_event: 'Cerca evento',
    duration: 'Durata',
    total_participants: 'Partecipanti totali',
    message: 'Messaggio',
    avg_message: 'Messaggio medio',
    per_message: 'UNA PERCENTUALE DI MESSAGGI/EVENTO'
  },

  signup: {
    success_step1: `Abbiamo Inviato Un Codice Di Verifica Via E-Mail All'indirizzo Da Voi Fornito, Vi Preghiamo Di Copiare Il Codice Nel Campo Sottostante Per Confermare La Vostra Registrazione`,
    success_step2: 'Grazie. Sei Registrato Con Successo',
    privacy:
      'Accetto I Termini E Le Condizioni <1> Di Sharelook</1> E <3> Sulla Privacy</3>'
  },

  stream: {
    emotion_impressive: 'Impressionante',
    emotion_unimpressive: 'Impressionante',
    message_not_started: 'Prossimamente',
    message_running: 'Diretta',
    message_processing:
      'Attualmente Stiamo Elaborando La Registrazione Dal Vivo Per La Riproduzione. Si Prega Di Controllare Più Tardi Per Visualizzare Il Video E Continuare Ad Aggiungere Valutazioni E Feedback'
  },

  tabs: {
    audience: 'Pubblico',
    course: 'Panoramica Corso',
    details: 'Dettagli Evento Diretta',
    description: 'Descrizione',
    host: 'Ospitante',
    lesson: 'Panoramica Lezione',
    lessons: 'Lezioni',
    overview: 'Panoramica',
    page: 'Costruttore Pagina',
    publish: 'Pubblica',
    settings: 'Impostazioni Pubblicazione',
    total_views: 'Totale Visioni',
    live_participates: 'Partecipanti In Diretta',
    live_chats: 'Chat In Diretta',
    live_quiz: 'Sondaggi In Diretta',
    trainer_rating: 'Valutazione Allenatore',
    trainer_times: 'Tempi Allenatore',
    courses_quizzes: 'Quiz Dei Corsi',
    user_list: 'Lista Utente',
    discussion: 'Discussione',
    notes: 'Le Mie Note'
  },

  topics: {
    sales_training: 'Formazione Alla Vendita',
    negotiation_training: 'Formazione Alla Negoziazione',
    cross_cultural_awareness: 'Consapevolezza Interculturale',
    networking_skills: 'Abilità Di Networking',
    software_architecture: 'Architettura Software',
    project_management: 'Gestione Progetto',
    digital_transformation: 'Trasformazione Digitale',
    it_consulting: 'Consulenza IT',
    management_consulting: 'Consulenza Gestione',
    hr_consulting: 'Consulenza HR',
    internet_of_things: 'Internet of Things (IoT)',
    cybersecurity: 'Cybersicurezza',
    privacy_and_gdrp: 'Privacy E GDPR'
  },

  training: {
    title: 'Formazione',
    title_assessment: 'Valutazioni',
    subtitle: 'Corsi Ed Eventi In Diretta',
    enrolled_title: 'Corsi Iscritti Ed Eventi In Diretta',
    enrolled_subtitle: 'Corsi Ed Eventi In Diretta Cui Ti Sei Iscritto',
    enrolled_message: 'Attualmente Non Sei Iscritto Ad Alcun Corso O Evento',
    past_live_event_title: 'Eventi Diretta Passati',
    past_live_event_subTitle: 'Eventi Diretta Finiti',
    past_live_event: 'Attualmente Non Ci Sono Eventi Passati In Diretta',
    recomended_title: 'Corsi Consigliati Ed Eventi Diretta',
    now_live_event_title: 'Ora In Diretta',
    now_live_event_subTitle:
      'Eventi Che Sono In Diretta O Lo Saranno Molto Presto',
    now_live_event: `Attualmente Non C'è Nessun Evento In Diretta`,
    webinar_event_title: 'Canale Webinar',
    webinar_event_subTitle: 'Webinari Finiti',
    webinar_event: 'Attualmente Non Ci Sono Webinar',
    p2p_event_title: 'Sessioni Di Coaching',
    p2p_event_subTitle: 'Sessioni Di Coaching Finite',
    p2p_event: 'Attualmente Non Ci Sono Sessioni Di Coaching',

    now_live_assessment_title: 'Ora In Diretta',
    now_live_assessment_subTitle:
      ' Valutazione Che Sono In Diretta O Lo Saranno Molto Presto',
    now_live_assessment_message: `Attualmente Non C'è Una Valutazione In Diretta`,

    upcoming_assessment_title: 'Prossime Valutazioni In Diretta',
    upcoming_assessment_subtitle: 'Prossime Valutazioni In Diretta',
    upcoming_assessment_message:
      'Al Momento Non È Prevista Una Valutazione In Diretta',

    past_live_assessment_title: 'Valutazioni In Diretta Del Passato',
    past_live_assessment_subTitle: 'Valutazioni In Diretta Finite',
    past_live_assessment_message:
      'Attualmente Non Ci Sono Valutazioni In Diretta Del Passato',

    /**
     * <0/> get index 0 of data from Trans component
     * exp -->
     * <Trans i18nKey='training:recomended_subtitle' >
     *    <Navlink>{...something}</Navlink> --> is index 0 of Trans component
     *    <h1>{...something}</h1> --> is index 1 of Trans component
     * </Trans>
     */
    recomended_subtitle:
      ' Abbiamo curato corsi ed eventi dal vivo sulla base dei vostri <0><0/></0>',
    /**
     * and we also can get data from index to replace in recommended_subtitle by like above string.
     * for exp -->
     * at the end of the recomended_subtitle above will get data from index of component to display
     * <0><0/></0> mean get data index 0 of Trans component above = <Navlink> and <0/> get data index 0 from <Navlink> = {...something}
     */
    search: 'Ricerca Formazione',
    search_assessment: 'Ricerca Valutazione',
    recomended_message: 'Attualmente Non Ci Sono Corsi Ed Eventi Consigliati'
  },

  warnings: {
    delete: 'Sei Sicuro?'
  },

  workshop: {
    title: 'Il Mio Workshop',
    subtitle: 'Creare Un Corso O Ospitare Un Evento Dal Vivo',
    new_course: 'Creare Un Nuovo Corso',
    new_event: 'Creare Un Nuovo Evento Dal Vivo',
    new_assessment: 'Creare Una Nuova Valutazione Dal Vivo',
    courses_title: 'Corsi Creati Ed Eventi Dal Vivo',
    courses_subtitle:
      'Modifica E Pubblica Il Corso Creato E Pianifica Eventi Dal Vivo',
    tags_course: 'Corso',
    tags_live: 'Evento Diretta',
    tags_assessment: 'Valutazione Diretta'
  },

  help_center: {
    sidebar: {
      title: ' Come Possiamo Aiutarti?',
      back_to: 'Dashboard'
    },
    menu: {
      help_center: 'Centro Aiuti',
      whats_new: `Cosa C'è Di Nuovo`,
      feedback: 'Risposta',
      feedback_title: 'Invia Un Feedback A Sharelook',
      terms_and_conditions: 'Termini E Condizioni',
      privacy_policy: 'Informativa Privacy',
      contact_us: 'Contattaci'
    },
    get_started: {
      title: 'Iniziare Con Lo Share Look',
      description: {
        main:
          'Abbiamo Integrato Il Video In Diretta In Un Costruttore Corso Facile Da Usare',
        tagline: `Con Il Sistema Di Trasmissione In Diretta Brevettato Da Sharelook, È Possibile Creare Coinvolgimento, Automatizzare L'apprendimento E Massimizzare La Condivisione Delle Conoscenze`
      }
    },
    whats_new: {
      title: 'Iniziare Con Lo Share Look',
      description: {
        main: `Sapevate Che Si Può Creare L'apprendimento Dello Stile Di Storytelling Breve Tempo E Non È Così Costoso?  Dovresti Combinare Il Costruttore Del Corso Sharelook E I Rispettivi Talenti. Puoi Chiederci "COME"`,
        tagline: ''
      }
    },
    create_workshop: 'Come Creare Un Workshop',
    create_course: 'Come Creare Un Corso',
    create_user: 'Come Creare Un Utente',
    create_group: 'Come Creare Un Gruppo',
    create_webinar: 'Come Creare Un Webinar',
    create_live_event: 'Come Creare Un Evento Diretta',
    create_live_assessment: 'Come Creare Una Valutazione Diretta',
    create_live_on_mobile: 'Crea Diretta Su Mobile',
    personalize_profile: 'Personalizzare Un Profilo',
    manage_group: 'Gestisci gruppo',
    report: 'Report',
    add_annotations: 'Come Aggiungere Annotazioni/Popups',
    add_audio: 'Come Aggiungere Audio',
    add_header_and_footer: 'Aggiungere Intestazione E Piè Di Pagina',
    add_image: 'Come Aggiungere Immagine',
    add_quiz_content: 'Come Aggiungere Contenuti Del Quiz',
    add_text_content: 'Come Aggiungere Contenuti Testuali',
    add_user: 'Come Aggiungere Un Utente',
    add_video: 'Come Aggiungere Video',
    clone_course: 'Come Clonare Corso',
    find_live_broadcast: 'Come Trovare Trasmissione Diretta',
    contact_us: {
      title: 'Contattaci',
      subject_placeholder: 'Inserisci Una Materia*',
      content_placeholder: 'Inserisci Un Messaggio*',
      subject_required: '*Materia È Richeista',
      content_required: '*Messaggio È Richiesto',
      submit: 'Invia',
      cancel: 'Cancella',
      sent: 'Sent'
    },
    feedback: {
      title: 'Invia Un Feedback A Sharelook',
      attach_title: 'Allega Un File',
      choose: 'Scegli Un File',
      detail: 'Il File Sarà Condiviso Con Il Team Di Supporto',
      content_placeholder: 'Inserisci Messaggio*',
      content_required: '*Messaggio È Richiesto',
      submit: 'Invia',
      cancel: 'Cancella',
      sent: 'Inviato'
    }
  },
  discussion: {
    join_discussion: 'Partecipare Alla Discussione'
  },
  notes: {
    title: 'Le Mie Note',
    add_note: 'Aggiungi Nuova Nota'
  }
}
