import React from 'react'
import { Col, Form, Input, TreeSelect } from 'antd'
import './index.css'
import { CustomSwitch } from './CustomSwitch'
import { api } from '../../../../../../services'
import TrainerSection from '../../../../../../components/TrainerSection'

const { TreeNode } = TreeSelect

class MiddleSection extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isMazeFree: false,
      categories: []
    }
  }

  componentDidMount() {
    this.fetchCategories()
    this.handleSwitch()
  }

  fetchCategories = () => {
    api.courses
      .fetchCategory()
      .then(res =>
        this.setState({
          categories: res.data
        })
      )
      .catch(err => {
        console.log('Error in fetching categories')
      })
  }

  handleSwitch = () => {
    const { onSwitch, mazeIsFree } = this.props
    if (mazeIsFree === null) {
      return
    }
    this.setState({ isMazeFree: !this.state.isMazeFree }, () => {
      onSwitch(this.state.isMazeFree)
    })
  }

  handleCategorySelect = id => {
    const { onCategory } = this.props
    onCategory(id)
  }

  validatePrice = (rule, value, callback) => {
    const { t } = this.props
    if (value && value < 5) {
      callback(t('v4:enter_price'))
    }
    callback()
  }

  onPriceChange = price => {
    if (price && price > 4) {
      this.props.onPrice(price)
    }
  }

  render() {
    const {
      t,
      form: { getFieldDecorator },
      onVideoLink,
      mazeBuilder: { mazeCategory, mazePrice, mazeVideoLink, mazeIsFree }
    } = this.props
    const { categories } = this.state

    console.log('check maze free', mazeIsFree)

    return (
      <>
        <TrainerSection />
        <div className="mp-top_section_row">
          <Col md={11}>
            <Form.Item
              colon={false}
              required={false}
              label={t('labels:category')}
            >
              {getFieldDecorator('category_id', {
                rules: [{ required: true, message: t('v4:select_category') }],
                initialValue: mazeCategory
              })(
                <TreeSelect
                  size="large"
                  style={{ width: '100%' }}
                  dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                  placeholder="Please select"
                  allowClear
                  onChange={this.handleCategorySelect}
                >
                  {categories &&
                    categories.map(category => (
                      <TreeNode
                        value={category.id}
                        title={category.name}
                        key={category.id}
                      >
                        {category.children &&
                          category.children.map(option => (
                            <TreeNode
                              value={option.id}
                              title={option.name}
                              key={option.id}
                            />
                          ))}
                      </TreeNode>
                    ))}
                </TreeSelect>
              )}
            </Form.Item>
          </Col>
          <Col md={11}>
            <Form.Item
              colon={false}
              required={false}
              label={t('v4:promotional_video_link')}
            >
              {getFieldDecorator('video_link', {
                rules: [{ required: true, message: t('v4:input_video_link') }],
                initialValue: mazeVideoLink
              })(
                <Input
                  size="large"
                  maxLength={100}
                  onChange={e => onVideoLink(e.target.value)}
                />
              )}
            </Form.Item>
          </Col>
        </div>
        <div className="mp-top_section_row">
          <Col md={11}>
            <div className="div_row">
              <Form.Item colon={false} required={false}>
                {getFieldDecorator('is_free', {
                  initialValue: mazeIsFree
                })(
                  <CustomSwitch
                    label={` ${t('general:free')}  ${t('v4:maze')}`}
                    value={mazeIsFree}
                    onChanged={e => this.handleSwitch(e)}
                  />
                )}
              </Form.Item>
              <Form.Item
                colon={false}
                required={false}
                className="price_style"
                label={t('labels:price (usd)')}
              >
                {getFieldDecorator('price', {
                  initialValue: mazePrice || 5,
                  rules: [
                    {
                      validator: this.validatePrice
                    }
                  ]
                })(
                  <Input
                    size="large"
                    type="number"
                    min={5}
                    maxLength={100}
                    onChange={e => this.onPriceChange(e.target.value)}
                    disabled={Boolean(mazeIsFree)}
                  />
                )}
              </Form.Item>
            </div>
          </Col>
        </div>
      </>
    )
  }
}

export { MiddleSection }
