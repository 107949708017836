import { connect } from 'react-redux'

import actions from '../../../../../../store/actions'

import ModalAddUsers from './ModalAddUsers'

export default connect(
  state => ({
    roles: state.roles.list
  }),
  dispatch => ({
    createOrganizationUser: (id, data) => dispatch(actions.organizations.createOrganizationUser(id, data)),
    updateOrganizationUser: (orgId, userId, data) => dispatch(actions.organizations.updateOrganizationUser(orgId, userId, data))
  })
)(ModalAddUsers)
