import styled from 'styled-components'
import { styleguide } from '../../../../constants'

const { colors } = styleguide

const Page = styled.div`
  padding-bottom: 8rem;
`

Page.Field = styled.div`
  margin-bottom: 4rem;
`

Page.Label = styled.div`
  font-weight: 300;
  text-transform: uppercase;
`

Page.Footer = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 2.4rem 4rem;
  background: ${colors.white};
  border-top: 1px solid ${colors.darkGreyThree};
`

export { Page }
