import React from 'react'
import { Modal, Button, Spin } from 'antd'

import { styleTitle } from './style'
import FooterActions from './FooterActions'
import { Pages } from '../../../../../styled'
import {
  textIconRectangle,
  textIconInsideRectangle
} from '../../../../PageBuilder/Components/Text/images'
import { Builder } from '../../../../../../../../components'

const LandscapeMode = ({
  t,
  upload,
  loading,
  handleData,
  isUploading,
  isLandScape,
  updateLesson,
  handleParams,
  pageStructure,
  handleMultipleDrop,
  onHideLandscapeMode,
  handleShowTextModal,
  handleShowQuizModal,
  handleShowAudioModal,
  handleShowVideoModal,
  onClickImportElement,
  handleShowImageModal,
  handleChangeBackground,
  handleShowAnnotationModal
}) => {
  const styleLandscape = {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflow: 'hidden'
  }

  const title = (
    <div className="toolbar__item-icon" style={styleTitle}>
      <div className="wrap-video-icons">
        <img src={textIconRectangle} alt="" />{' '}
        <span style={{ color: '#67686C' }}> Landscape Mode</span>
        <img
          alt=""
          src={textIconInsideRectangle}
          className="text-icon-inside-rectangle"
        />
      </div>
      <Button
        type="primary"
        shape="round"
        size="default"
        onClick={onClickImportElement}
        style={{ left: '55em', bottom: '0.5em' }}
        className="ant-btn rounded ant-btn-primary"
      >
        {t('v4:import')}
      </Button>
      <Builder.Toolbar
        mode={'PAGE'}
        upload={upload}
        handleData={handleData}
        isLandScape={isLandScape}
        pageStructure={pageStructure}
        handleShowTextModal={handleShowTextModal}
        handleShowQuizModal={handleShowQuizModal}
        handleShowAudioModal={handleShowAudioModal}
        handleShowVideoModal={handleShowVideoModal}
        handleShowImageModal={handleShowImageModal}
        handleChangeBackground={handleChangeBackground}
        handleShowAnnotationModal={handleShowAnnotationModal}
      />
    </div>
  )

  return (
    <Modal
      transparent
      title={title}
      width={'90%'}
      visible={isLandScape}
      style={styleLandscape}
      className="custom-ant-modal"
      onCancel={onHideLandscapeMode}
      footer={
        <FooterActions
          t={t}
          loading={loading}
          title={t('buttons:update')}
          onClickTitle={updateLesson}
          onClickCancel={onHideLandscapeMode}
        />
      }
    >
      <Spin className="ant_spin" spinning={isUploading}>
        <Pages.Item>
          <Builder.Page
            isLandScape
            upload={upload}
            handleData={handleData}
            handleParams={handleParams}
            pageStructure={pageStructure}
            handleMultipleDrop={handleMultipleDrop}
          />
        </Pages.Item>
      </Spin>
    </Modal>
  )
}

export default LandscapeMode
