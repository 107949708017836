import http, {
  httpCustomSummarizedInstance,
  questionAnswerAiInstance
} from './http'
/**
 * Get list of quizes.
 */
function getQuizesList() {
  return http({
    method: 'GET',
    url: '/quizes'
  })
}

/**
 * Get single quiz.
 * @param {number} id
 */
function getQuizById(id) {
  return http({
    method: 'GET',
    url: `/quizes/${id}`
  })
}

/**
 * Get single quiz with answers.
 * @param {number} id
 */
function getQuizByIdAnswers(id) {
  return http({
    method: 'GET',
    url: `/quizes/${id}/answers`
  })
}

/**
 * Send quiz to validate.
 * @param {obj} data
 * @param {number} id
 */
function quizValidate(data, id) {
  return http({
    method: 'POST',
    url: `/quizes/${id}/validate`,
    data
  })
}

/**
 * Create new quiz.
 * @param {object} data
 */
function quizCreate(data) {
  return http({
    method: 'POST',
    url: '/quizes',
    data
  })
}

/**
 * Update quiz.
 * @param {object} data
 * @param {number} id
 */
function quizUpdate(data, id) {
  return http({
    method: 'PUT',
    url: `/quizes/${id}`,
    data
  })
}

/**
 * Delete quiz.
 * @param {number} id
 */
function quizDelete(id) {
  return http({
    method: 'DELETE',
    url: `/quizes/${id}`
  })
}

/**
 * Delete quiz.
 * @param {array} param
 */
function quizDeleteAttempts(param) {
  return http({
    method: 'DELETE',
    url: `/quizes/attempts/${param.quiz_id}/${param.user_id}/${param.course_id}/${param.lesson_id}`
  })
}

function generateQuizByAI(data) {
  return httpCustomSummarizedInstance({
    method: 'POST',
    url: `/haystack-qg/qg`,
    data
  })
}

function generateAiQuiz(data) {
  return questionAnswerAiInstance({
    method: 'POST',
    url: `/?service=quiz-generation`,
    data
  })
}

function getSimulationInformation(params) {
  const { simulationId, courseId, userId } = params
  return http({
    method: 'GET',
    url: `/simulation/${simulationId}/course/${courseId}/user/${userId}`
  })
}

export {
  getQuizesList,
  getQuizById,
  getQuizByIdAnswers,
  quizValidate,
  quizCreate,
  quizUpdate,
  quizDelete,
  quizDeleteAttempts,
  generateQuizByAI,
  generateAiQuiz,
  getSimulationInformation
}
