import classNames from 'classnames'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Dropzone from 'react-dropzone'

import uploadMedia from '../../assets/upload_media.svg'
import { bytesToSize, getFileExtension, getFileType } from '../../utils'
import { AddMediaDetail } from '../../views/Library/components'
import { useTranslation } from 'react-i18next'
import { config, general } from '../../constants'
import { useDispatch, useSelector } from 'react-redux'
import { Alert, Button, Icon, Input, Popover, Tabs, message } from 'antd'
import _ from 'lodash'
import actions from '../../store/actions'
import { api } from '../../services'

import './UploadVideo.scss'
import YoutubeVideosList from './YoutubeVideosList'
import { clearUploadList } from '../../store/actions/library'

import YoutubeChannelImage from '../../assets/youtube_channelId.png'

const YOUTUBE_URL = 'https://youtube.googleapis.com/youtube/v3/'

function UploadVideo(props) {
  const [files, setFiles] = useState([])
  const [tempForUploadedFiles, setTempForUploadedFiles] = useState([])
  const [enableProgress, setEnableProgress] = useState(false)
  const [fileNotCompatible, setFileNotCompatible] = useState(false)
  const [isRemovingMedia, setIsRemovingMedia] = useState(false)
  const [youtubeChannelId, setYoutubeChannelId] = useState('')
  const [isYoutubeFetching, setIsYoutubeFetching] = useState(false)
  const [youtubeData, setYoutubeData] = useState(null)
  const [youtubeApiError, setYoutubeApiError] = useState(false)

  const library = useSelector(state => state.library)
  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      setEnableProgress(false)
      setFileNotCompatible(false)
      setFiles([])
      dispatch(clearUploadList())
    }
  }, [])

  const { t } = useTranslation()

  const { uploadProgress, uploadSuccess, uploadFetching } = library

  const { onUploadedVideos, showYoutube = false, isMultiple = false } = props
  const onDrop = dropped => {
    const hasFileThatIsNotAVideo = dropped.some(
      file => !file.type.includes('video')
    )

    if (hasFileThatIsNotAVideo) {
      setFileNotCompatible(true)
      return
    }

    if (dropped.length === 0) {
      setFileNotCompatible(true)
    }

    setTempForUploadedFiles(dropped)
    setEnableProgress(true)

    let newFiles = [dropped]

    dropped.forEach((file, index) => {
      dispatch(actions.library.upload(file)).then(fileRes => {
        newFiles[index] = fileRes
        if (index + 1 === dropped.length) {
          setFiles(newFiles)
        }
      })
    })
  }

  const uploadListClassName = list => {
    return list.length <= 0 ? 'upload-modal__list-wrapper__no-border' : ''
  }

  const getThumbnail = (files, ind) => {
    return (
      <div className="upload-modal__thumbnail__audio-video">
        <Icon
          type={'play-circle'}
          style={{ color: '#0068FF', fontSize: '35px' }}
        />
      </div>
    )
  }

  const checkedCompletUpload = item => {
    const filterSuccessFiles = _.filter(
      uploadSuccess,
      ele => ele.id === item.id
    )

    if (uploadSuccess.length && filterSuccessFiles.length) {
      return false
    }
    return true
  }

  const handleCancelUpload = mediaItem => {
    if (mediaItem.source) {
      mediaItem.source.cancel()
    }
  }

  const handleRemoveMedia = async (mediaId, ind) => {
    setIsRemovingMedia(true)
    const data = {
      mediaIds: [mediaId]
    }
    try {
      await api.library.removeMedias(data)
      const updatedFiles = [...files]
      updatedFiles.splice(ind, 1)
      setFiles(updatedFiles)

      const updatedTempForUploadedFiles = [...tempForUploadedFiles]
      updatedTempForUploadedFiles.splice(ind, 1)
      setTempForUploadedFiles(updatedTempForUploadedFiles)

      setIsRemovingMedia(false)
      if (updatedFiles.length === files.length) {
        setFiles(updatedFiles)
      }

      setTempForUploadedFiles(
        tempForUploadedFiles.map((ele, index) => {
          if (index === ind) {
            ele.removed = true
          }
          return ele
        })
      )
    } catch (error) {}
  }

  const onConfirm = () => {
    onUploadedVideos(files)
    setFiles([])
    setTempForUploadedFiles([])
  }

  const enableCloseModal = (progress, isFetching) => {
    const filterCancelProgressUpload = _.filter(
      progress,
      item => item.status === general.CANCELED
    )
    const checkCancelUpload =
      filterCancelProgressUpload.length === progress.length

    if (isFetching) {
      return !checkCancelUpload
    } else if (isRemovingMedia) {
      return true
    }

    return false
  }

  const onChangeChanneId = async e => {
    const channelId = e.target.value
    setYoutubeChannelId(channelId)
  }

  const getVideosFromYoutubeChannel = (paramType = '', value) => {
    if (!youtubeChannelId) {
      message.error(t('v4:required_channel_id'))
      return
    }

    setIsYoutubeFetching(true)
    const isMore = paramType === 'MORE'
    const isSearchLoadMore = isMore && value

    let url = `${YOUTUBE_URL}search?part=snippet,id&channelId=${youtubeChannelId}&key=${config.youtubeApiKey}&type=video`

    if (isMore || isSearchLoadMore) {
      url += `&pageToken=${youtubeData.nextPageToken}`
    }

    if (value && value.trim()) {
      url += `&q=${value}`
    }

    fetch(url)
      .then(response => response.json())
      .then(res => {
        setIsYoutubeFetching(false)
        if (res.error) {
          setYoutubeApiError(true)
          return
        }
        setYoutubeData(prevData => {
          if (isMore) {
            return {
              ...res,
              items: [...prevData.items, ...res.items]
            }
          }
          return res
        })
      })
      .catch(err => {
        setIsYoutubeFetching(false)
        setYoutubeApiError(true)
      })
  }

  const handleSelection = (etag, isChecked) => {
    const newItems = youtubeData.items.map(item => {
      if (item.etag === etag) {
        return {
          ...item,
          selected: isChecked
        }
      }
      return item
    })

    setYoutubeData({
      ...youtubeData,
      items: newItems
    })
  }

  const handleSelectAll = isChecked => {
    const newItems = youtubeData.items.map(item => {
      return {
        ...item,
        selected: isChecked
      }
    })

    setYoutubeData({
      ...youtubeData,
      items: newItems
    })
  }

  const handleEnableAI = (etag, isChecked) => {
    const newItems = youtubeData.items.map(item => {
      if (item.etag === etag) {
        const newObject = {
          ...item,
          isAiEnabled: isChecked,
          ...(isChecked && {
            ai_language: 'en-US' // default language
          })
        }

        if (!isChecked) {
          delete newObject['isAiEnabled']
          delete newObject['ai_language']
        }

        return newObject
      }
      return item
    })

    setYoutubeData({
      ...youtubeData,
      items: newItems
    })
  }

  const handleInsertVideo = () => {
    const { onYoutubeVideos } = props
    const { items } = youtubeData

    const selectedVideos = items
      .filter(item => item.selected)
      .map(item => ({
        id: item.id.videoId,
        title: item.snippet.title,
        ...(item.isAiEnabled && {
          isAiEnabled: item.isAiEnabled,
          ai_language: item.ai_language
        })
      }))
    onYoutubeVideos(selectedVideos)
  }

  const onYoutubeSearch = useCallback(
    value => {
      getVideosFromYoutubeChannel('SEARCH', value)
    },
    [getVideosFromYoutubeChannel]
  )

  const showLoadMore = useMemo(() => {
    if (youtubeData) {
      const {
        items,
        pageInfo: { totalResults }
      } = youtubeData
      return items.length < totalResults
    }

    return false
  }, [youtubeData])

  const videosHasSelection = useMemo(() => {
    if (youtubeData) {
      const { items } = youtubeData
      return items.some(item => item.selected)
    }
    return false
  }, [youtubeData])

  const channelInfo = (
    <div className="channelId_image">
      <img src={YoutubeChannelImage} alt="" />
    </div>
  )

  const handleAiLanguage = useCallback(
    (etag, language) => {
      const newItems = youtubeData.items.map(item => {
        if (item.etag === etag) {
          return {
            ...item,
            ai_language: language
          }
        }
        return item
      })
      setYoutubeData({
        ...youtubeData,
        items: newItems
      })
    },
    [youtubeData]
  )

  return (
    <>
      <Tabs defaultActiveKey="1" className="video-channel-modal">
        <Tabs.TabPane tab={t('buttons:upload_video')} key="1">
          <div className="upload-modal">
            <div className="upload-modal__inner">
              {!enableProgress && (
                <div>
                  <h3 className="upload-modal__text">
                    {t('media:import_file')}
                  </h3>
                  <div className="upload-modal__dropzone">
                    <Dropzone
                      multiple={isMultiple}
                      accept={['video/*']}
                      onDrop={onDrop}
                    >
                      {({ getRootProps, getInputProps, isDragActive }) => (
                        <div
                          className={classNames('upload-dropzone', {
                            'upload-dropzone_active': isDragActive
                          })}
                        >
                          <div className="upload-dropzone__text-wrapper">
                            <img src={uploadMedia} alt="" />
                            <p className="upload-dropzone__text">
                              <span>{t('media:upload_file')}</span>{' '}
                              {t('media:drag_drop')}
                            </p>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                  </div>
                </div>
              )}
              <div
                className={`upload-modal__list-wrapper ${uploadListClassName(
                  uploadProgress
                )}`}
              >
                <div className="upload-modal__list-container">
                  {fileNotCompatible && (
                    <div className="upload-modal__error-item-wrapper">
                      <div className="upload-modal__error-message-container">
                        <div className="upload-modal__error-bar" />
                        <div className="upload-modal__error-message">
                          {t('errors:upload_error')}
                        </div>
                      </div>

                      <Button
                        className="upload-modal__cancel-error"
                        type="primary"
                        onClick={() => setFileNotCompatible(false)}
                      >
                        {t('buttons:cancel')}
                      </Button>
                    </div>
                  )}

                  {enableProgress && uploadProgress.length > 0 && (
                    <div className="upload-modal__list">
                      {uploadProgress.map((item, ind) => (
                        <div key={item.uid} className="upload-modal__item">
                          <div className="upload-modal__thumbnail">
                            {getThumbnail(tempForUploadedFiles, ind)}
                            <div className="upload-modal__thumbnail__details">
                              <span className="upload-modal__thumbnail__media-type">
                                {getFileType(
                                  tempForUploadedFiles[ind] &&
                                    tempForUploadedFiles[ind].type
                                )}
                              </span>
                              <span className="upload-modal__thumbnail__extention">
                                {getFileExtension(
                                  tempForUploadedFiles[ind] &&
                                    tempForUploadedFiles[ind].type
                                )}
                              </span>
                              <span className="upload-modal__thumbnail__size">
                                {bytesToSize(
                                  tempForUploadedFiles[ind] &&
                                    tempForUploadedFiles[ind].size
                                )}
                              </span>
                            </div>
                          </div>
                          <div className="upload-modal-item">
                            <div className="upload-modal-item__percent-border">
                              <div
                                className={`upload-modal-item__percent ${
                                  item.status === general.CANCELED
                                    ? 'upload-modal-item__percent__cancel'
                                    : ''
                                }`}
                                style={{ width: item.percent + '%' }}
                              />
                            </div>

                            {item.status !== general.CANCELED && (
                              <div className="upload-modal-item__weight">
                                {checkedCompletUpload(item) && (
                                  <span>
                                    {bytesToSize(item.loaded)} {t('of')}{' '}
                                    {bytesToSize(item.total)}
                                  </span>
                                )}
                                {!checkedCompletUpload(item) && (
                                  <span className="upload-modal-item__weight__complete">
                                    {tempForUploadedFiles[ind] &&
                                    tempForUploadedFiles[ind].removed
                                      ? t('general:removed')
                                      : t('general:completed')}
                                  </span>
                                )}
                              </div>
                            )}

                            {item.status === general.CANCELED && (
                              <div className="upload-modal-item__weight">
                                <span className="upload-modal-item__weight__canceled">
                                  {t('general:canceled')}
                                </span>
                              </div>
                            )}

                            <AddMediaDetail
                              status={item.status}
                              mediaId={item.id}
                              fileType={getFileType(
                                tempForUploadedFiles[ind] &&
                                  tempForUploadedFiles[ind].type
                              )}
                              isLibrary
                              isChannel
                              ctrlHandleCancel={() => handleCancelUpload(item)}
                              isRemovingMedia={isRemovingMedia}
                              mediaRemoved={
                                tempForUploadedFiles[ind] &&
                                tempForUploadedFiles[ind].removed
                              }
                              ctrlHandleRemove={() =>
                                handleRemoveMedia(item.id, ind)
                              }
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="upload-modal__footer">
              <Button
                type="primary"
                shape="round"
                size="large"
                onClick={onConfirm}
                disabled={enableCloseModal(uploadProgress, uploadFetching)}
              >
                {t('general:confirm')}
              </Button>
            </div>
          </div>
        </Tabs.TabPane>
        {showYoutube && (
          <Tabs.TabPane tab="Import from Youtube" key="2">
            <div className="youtube_channeId__container">
              <div className="youtube_channeId">
                <span>{t('v3:channel_id')}</span>{' '}
                <Input
                  disabled={isYoutubeFetching}
                  type="text"
                  size="large"
                  onChange={onChangeChanneId}
                />
                <Button
                  loading={isYoutubeFetching}
                  type="primary"
                  shape="round"
                  size="medium"
                  onClick={() => getVideosFromYoutubeChannel('ALL')}
                >
                  Send
                </Button>
              </div>
              <div className="youtube_channeId_info">
                <Popover content={channelInfo} trigger="hover">
                  <Icon type="info-circle" />
                </Popover>
              </div>
            </div>

            {youtubeApiError && (
              <Alert type="error" message={'Error ! Please Try Again.'} />
            )}

            {youtubeData && youtubeData.pageInfo && (
              <YoutubeVideosList
                loading={isYoutubeFetching}
                videos={youtubeData.items}
                onLoadMore={(type, val) =>
                  getVideosFromYoutubeChannel('MORE', val)
                }
                showLoadMore={showLoadMore}
                handleSelection={handleSelection}
                handleSelectAll={handleSelectAll}
                onYoutubeSearch={onYoutubeSearch}
                handleEnableAI={handleEnableAI}
                handleAiLanguage={handleAiLanguage}
              />
            )}

            {videosHasSelection && (
              <>
                <hr />
                <div className="youtube_insert_button">
                  <Button
                    type="primary"
                    shape="round"
                    size="medium"
                    onClick={handleInsertVideo}
                  >
                    {t('general:insert').toUpperCase()}
                  </Button>
                </div>
              </>
            )}
          </Tabs.TabPane>
        )}
      </Tabs>
    </>
  )
}

export default UploadVideo
