import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import actions from '../../../store/actions'

import Edit from './Edit'

export default connect(
  state => ({
    course: state.courses.courseById
  }),
  dispatch => ({
    getCourseById: id => dispatch(actions.courses.getCourseById(id)),
    courseBuilderActions: bindActionCreators(actions.courseBuilder, dispatch)
  })
)(Edit)
