import http from './http'
import { message } from 'antd'

function getAll (params) {
  return http({
    method: 'GET',
    url: '/assessments',
    params
  })
}

function getById (id) {
  return http({
    method: 'GET',
    url: `/assessments/${id}`
  }).catch(error => {
    const data = error.response.data
    message.error(data.errorMessage)
    throw error
  })
}

function getEmotionsById (id) {
  return http({
    method: 'GET',
    url: `/assessments/${id}/emotions`
  })
}

function getChatById (id) {
  return http({
    method: 'GET',
    url: `/assessments/${id}/messages`
  })
}

function create (data) {
  return http({
    method: 'POST',
    url: '/assessments',
    data
  })
}

function updateById (id, data) {
  const { isSendMail, isSendMailToHost } = data
  const query = `?isSendMail=${isSendMail ||
  false}&isSendMailToHost=${isSendMailToHost || false}`
  return http({
    method: 'PUT',
    url: `/assessments/${id}${query}`,
    data
  })
}

function deleteById (id) {
  return http({
    method: 'DELETE',
    url: `/assessments/${id}`
  })
}

const viewAssessments = assessmentId => {
  return http({
    method: 'POST',
    url: `/assessments/${assessmentId}/view`
  })
}

export {
  getAll,
  create,
  getById,
  getChatById,
  getEmotionsById,
  updateById,
  deleteById,
  viewAssessments
}
