/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-06-19 10:54:34
 * @LastEditTime: 2019-08-27 14:02:30
 * @LastEditors: Please set LastEditors
 */
import { FETCH_LESSONS_REQUEST, LOAD_LESSONS, CLEAR_LESSONS } from '../types'

const defaultState = {
  list: [],
  fetch: false
}

const lessons = (state = defaultState, action) => {
  switch (action.type) {
    case LOAD_LESSONS:
      return {
        ...state,
        list: action.payload
      }

    case CLEAR_LESSONS:
      return {
        ...state,
        list: action.payload
      }

    case FETCH_LESSONS_REQUEST:
      return {
        ...state,
        fetch: action.payload
      }

    default:
      return state
  }
}

export default lessons
