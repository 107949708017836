import styled from 'styled-components'

const MyNotesContainer = styled.div`
  position: relative;
`

MyNotesContainer.Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`

export { MyNotesContainer }
