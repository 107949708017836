import React from 'react'
import { Button, Typography, Tooltip, Menu, Icon } from 'antd'

import './index.css'
import { courses, coursesAI, marketplace } from '../images'
import ReactSVG from 'react-svg'
import './style.scss'

const { Title } = Typography
const creationTypes = {
  human: 'human',
  machine: 'machine'
}

const FormHeader = ({
  t,
  isMarketPlace,
  onToggleMarketPlace,
  courseId,
  isAdmin,
  isSuperAdmin,
  isRestricted = false,
  isCreatingCourseByHuman,
  onToggleCreatingCourseByHuman,
  isCourseAiEnabled = false,
  isAiGenerated = false
}) => {
  const handleMenuClick = ({ key }) =>
    onToggleCreatingCourseByHuman(key === creationTypes.human)

  const adminStatus = isAdmin || isSuperAdmin
  const isMarketplaceDisabled = !adminStatus || !courseId
  const creationType = isCreatingCourseByHuman
    ? creationTypes.human
    : creationTypes.machine

  const showAiGenerationOption = isCourseAiEnabled && !isAiGenerated

  const createCourseText = isAiGenerated
    ? t('v3:ai_generated_course')
    : t('workshop:new_course')

  return (
    <>
      <div className="create-course">
        <div className="create-course__content">
          <Title level={3}>
            {createCourseText}
            {isMarketPlace ? (
              <span className="corse-market-place">
                {t('v4:market_place_space')}
              </span>
            ) : (
              ''
            )}
          </Title>
          <p>{t('v4:created_course_plan')}</p>
        </div>
        {!isRestricted && (
          <div className="button-marker_place-div">
            <Tooltip
              placement="top"
              title={
                !adminStatus
                  ? t('v4:not_admin')
                  : courseId
                  ? ''
                  : t('v4:no_course')
              }
            >
              <Button
                size="large"
                shape="round"
                onClick={onToggleMarketPlace}
                className="shl-primary-btn"
                disabled={isMarketplaceDisabled}
              >
                {isMarketPlace ? (
                  <>Save & Continue</>
                ) : (
                  <>
                    <ReactSVG src={marketplace} className="svg-icon" />
                    {t('buttons:market_place').toUpperCase()}
                  </>
                )}
              </Button>
            </Tooltip>
          </div>
        )}
      </div>
      <div className="create-course-menu">
        {showAiGenerationOption && (
          <Menu
            defaultSelectedKeys={[creationType]}
            onClick={handleMenuClick}
            mode="horizontal"
          >
            <Menu.Item key={creationTypes.human}>
              <div className="create-course-box">
                <ReactSVG src={courses} />
                {t('v3:create_course_on_our_own')}
              </div>
            </Menu.Item>
            <Menu.Item key={creationTypes.machine}>
              <div className="create-course-box">
                <ReactSVG src={coursesAI} />
                {t('v3:ai_Assisted_ourse_reation')}
              </div>
            </Menu.Item>
          </Menu>
        )}
      </div>
    </>
  )
}

export { FormHeader }
