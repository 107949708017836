/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-08-07 15:55:38
 * @LastEditTime: 2019-09-24 13:24:59
 * @LastEditors: Please set LastEditors
 */
export default {
  LIVE_EVENT: 'Live Event',
  PODCAST: 'Podcast',
  LIVE_ASSESSMENT: 'Live Assessment',
  TEXT: 'TEXT',
  VIDEO: 'VIDEO',
  TRANSCRIBED_TEXT: 'Transcribed Text',
  VIDEO_STREAM: 'VIDEO_STREAM',
  AUDIO: 'AUDIO',
  IMAGE: 'IMAGE',
  ARIMAGE: 'ARIMAGE',
  QUIZ: 'QUIZ',
  SCENARIO: 'SCENARIO',
  ANNOTATION: 'ANNOTATION',
  GIF: 'GIF',
  PDF: 'APPLICATION',
  TOPICS: 'topics',
  KEYWORD: 'keywords',
  DESC: 'description',
  multiple_drop_failed: 'File not compatible!',
  no_profile: 'Name is required. Please finish setting up your profile!',
  duration_error: 'Durations is required.',
  date_time_error: 'Event start time must be at least 10 minutes from now.',
  date_time_error_peer: 'Event start time must be at least 1 minute from now.',
  date_time_error_assessment:
    'Assessment start time must be at least 10 min from now.',
  event_duration_message: 'Max 300 minutes(5hrs)',
  SAVE: 'save',
  PUBLIC: 'public',
  ORG: 'org',
  PRIVATE: 'private',
  MAKE_PUBLIC: 'Make Public',
  MAKE_ORG: 'Make Organization',
  MAKE_PRIVATE: 'Make Private',
  MY_MEDIA: 'My Media',
  PUBLIC_MEDIA: 'Public Media',
  MAZE_SCENES: 'Maze Scenes',
  CANCEL: 'Cancel',
  GAMES: 'Games',
  INSERT: 'Insert',
  INSERTING: 'Inserting...',
  UPDATE: 'Update',
  acceptType: [
    'video/AVI',
    'video/WMV',
    'video/MP4',
    'video/MOV',
    'video/FLV',
    'video/*'
  ],
  CANCELED: 'CANCELED',
  send_email: 'Send E-mail Notification',
  auto_enroll: 'Auto-Enrolled Course',
  admin_only: 'Admin Only',
  ORGANIZATION: 'organization',
  ORGANIZATION_GROUP: 'organization_group',
  SPECIFIC_USER: 'specific_user',

  MY_MEDIA_LIBRARY: 'My Media Library' /*MAHESH_ADDED*/,
  MARKETPLACE: 'marketplace', // to translate,
  ORGANIZATION_AND_MARKETPLACE: 'organization_marketplace',
  PUBLIC_MEDIA_LIBRARY: 'Public Library',
  ORGANIZATION_LIBRARY: 'Organization Library',
  IMAGES_FROM_PEXELS: 'Pexel Images',
  CANVA_EDIT: 'Do it from Canva',
  CREATE_AUDIO: 'Create',
  EDIT_AUDIO: 'Edit',
  PROCESSING_AUDIO: 'Processing Audio',
  UPLOAD_FROM_COMPUTER: 'Upload from Computer',
  IMPORT_FROM_COMPUTER: 'Import files from computer',
  SHOW_MORE_DETAILS: 'show more details',
  SHOW_LESS_DETAILS: 'show less details',
  ALL: 'All',
  MALE: 'Male',
  FEMALE: 'Female',
  NUMBER_OF_PEOPLE_CONF: {
    uptoTen: 10,
    uptoTwenty: 20,
    uptoTwentyFive: 25
  },
  NUMBER_OF_ATTENDEES_CONF: {
    uptoTwoHundredFifty: 250
    // uptoHundred: 100,
    // uptoTwoHundred: 200
  }
}
