import http from './http'

function getRoles() {
  return http({
    method: 'GET',
    url: '/roles'
  })
}

function resistrictMediaApi() {
  return http({
    method: 'GET',
    url: '/library?offset=0&limit=20&search=&sort=NEWEST&type=&isActiveOrg=true'
  })
}

export { getRoles, resistrictMediaApi }
