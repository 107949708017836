import * as types from '../types'

const getReviewsRequest = (id, type) => dispatch => {
  dispatch({
    type: types.GET_ALL_REVIEWS_REQUEST,
    payload: { id, type }
  })
}
const getReviewsSucess = data => dispatch => {
  dispatch({
    type: types.GET_ALL_REVIEWS_SUCCESS,
    payload: data
  })
}
const getReviewsFailure = () => dispatch => {
  dispatch({
    type: types.GET_ALL_REVIEWS_FAILURE
  })
}

const clearReviews = () => dispatch => {
  dispatch({
    type: types.CLEAR_REVIEWS_REQUEST
  })
}
const addReviewsRequest = (id, type, data) => dispatch => {
  dispatch({
    type: types.ADD_REVIEW_REQUEST,
    payload: { id, type, data }
  })
}
const addReviewsSuccess = data => dispatch => {
  dispatch({
    type: types.ADD_REVIEW_SUCCESS,
    payload: data
  })
}
const addReviewsFailure = () => dispatch => {
  dispatch({
    type: types.ADD_REVIEW_FAILURE
  })
}

const editReviewsRequest = (id, data) => dispatch => {
  dispatch({
    type: types.EDIT_REVIEW_REQUEST,
    payload: { id, data }
  })
}
const editReviewsSuccess = data => dispatch => {
  dispatch({
    type: types.EDIT_REVIEW_SUCCESS,
    payload: data
  })
}

export {
  getReviewsRequest,
  getReviewsSucess,
  clearReviews,
  addReviewsRequest,
  addReviewsSuccess,
  addReviewsFailure,
  editReviewsRequest,
  editReviewsSuccess,
  getReviewsFailure
}
