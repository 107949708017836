/*
 * @Author: your name
 * @Date: 2019-11-01 10:43:27
 * @LastEditTime: 2019-11-01 14:01:38
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /frontend/src/views/Library/internal/PopupMedia/index.js
 */
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import actions from '../../../../store/actions'
import PopupMedia from './PopupMedia'

const mapStateToProps = state => ({
  library: state.library,
  user: state.user,
  topics: state.topics,
  courseMarketPlaceOption: state.courseBuilder.courseMarketPlaceOption,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getLibraryList: actions.library.getLibraryList,
      getPublicList: actions.library.getPublicList,
      getOrgList: actions.library.getOrgList,
      upload: actions.library.upload,
      clearUploadList: actions.library.clearUploadList,
      updateLibraryStatus: actions.library.updateLibraryStatus,
      updateOrgLib: actions.library.updateOrgLib,
      handleMakeType: actions.library.handleMakeType,
      updateTopicList: actions.topics.updateTopicList
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PopupMedia)
