import http from './http'

function getSharebot(organizationId) {
  return http({
    method: 'GET',
    url: `/subscriptions/organization/${organizationId}`
  })
}

function createTrialSubscription(body) {
  return http({
    method: 'POST',
    url: '/trial',
    data: body
  })
}

function createSharebotSubscription(body) {
  return http({
    method: 'POST',
    url: '/subscription',
    data: body
  })
}
const sharebotUnsubscribeList = organizationId => {
  return http({
    method: 'GET',
    url: `/subscriptions`
  })
}
function unsubscribeSharebot(organizationId) {
  return http({
    method: 'DELETE',
    url: `/subscription/${organizationId}`
  })
}

export {
  getSharebot,
  createTrialSubscription,
  createSharebotSubscription,
  unsubscribeSharebot,
  sharebotUnsubscribeList
}
