import Notes from './Notes'
import actions from '../../store/actions'
import { connect } from 'react-redux'

const mapStateToProps = state => ({
  user: state.user,
  notes: state.notes.notes,
  isLoading: state.notes.isLoading
})

const mapDispatchToProps = dispatch => ({
  addNote: (id, type, parent, comment) => dispatch(actions.notes.addComment({ id, type, parent, comment })),
  getNotes: (id, type) => dispatch(actions.notes.fetchNotes({ id, type }))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Notes)
