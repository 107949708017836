/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-07-08 11:28:55
 * @LastEditTime: 2019-07-08 11:28:55
 * @LastEditors: your name
 */
import { Icon } from 'antd'
import styled from 'styled-components'
import { styleguide } from '../../../../constants'

const { colors } = styleguide

const Profile = styled.div`
  position: relative;
  height: 100%;
`
Profile.Icon = styled(Icon)`
  margin-left: 6.4rem;
`

Profile.Head = styled.div`
  display: flex;
  align-items: center;

  color: ${colors.black};

  transition: background 0.2s;
  cursor: pointer;
  border: 1.5px solid #e3e3e3;
  padding: 3.5px 10px;
  border-radius: 30px;
  margin-left: 10px;
  background: ${colors.white};

  &:hover {
    background: #fff;
    border-top: 1.5px solid #698bf2;
    border-right: 1.5px solid #698bf2;
    border-bottom: 1.5px solid #5057d5;
    border-left: 1.5px solid #5057d5;
  }

  // &::after {
  //   content: '';
  //   position: absolute;
  //   top: 50%;
  //   right: 2.4rem;
  //   width: 1.6rem;
  //   height: 1.6rem;
  //   background-image: url('/images/icons/menu/chevron.svg');
  //   background-repeat: no-repeat;
  //   background-position: center;
  //   background-size: 1.6rem;
  //   transform: translateY(-50%);
  //   opacity: 0.8;
  //   transition: transform 0.2s;
  // }

  ${props =>
    props.active &&
    `
    background: rgba(255, 255, 255, .1);

    &::after {
      transform: translateY(-50%) rotate(-180deg);
    }
  `}
`

Profile.Thumb = styled.div`
  position: relative;
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 100%;
  overflow: hidden;
  margin-right: 1.6rem;
  border: 1px solid #edf2f5;
  flex-shrink: 0;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

Profile.Info = styled.div`
  flex-shrink: 0;
`
Profile.Type = styled.div`
  font-weight: 300;
  font-size: 1rem;
  text-transform: uppercase;
  line-height: 1.3;
`
Profile.Name = styled.div`
  font-weight: 700;
  font-size: 1.4rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 13rem;
  line-height: 1.3;
`

Profile.Dropdown = styled.div`
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  width: 38rem;
  border-top: 1px solid #ddd;
  background: ${colors.black};
  box-shadow: -0.2rem 0 0.6rem rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  z-index: 9999;
  overflow: hidden;
  margin-top: 12px;

  ${props =>
    props.active &&
    `
    display: block;
  `}
`

Profile.List = styled.div``

Profile.Item = styled.div`
  padding: 1.6rem 3.2rem;

  display: flex;
  align-items: center;
  transition: background 0.2s;
  cursor: pointer;
  border-top: 1px solid #28303b;
  &:hover {
    background: #28303b;
  }

  &:active {
    background: #28303b;
  }
`

Profile.Account = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 1.6rem 2.4rem;
  color: ${colors.white};

  transition: background 0.2s;
  cursor: pointer;
  border-bottom: 1px solid #212121;

  ${props =>
    !props.selected &&
    `
    order: 2;
    background: ${colors.darkGreyFour};
    color: ${colors.white};
    &:hover {
      background: ${colors.darkGreyFour};
    &::after {

        color: ${colors.white}
    }
    }
  `}

  ${props =>
    props.selected &&
    `
    order: 1;
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 2.4rem;
      width: 1.6rem;
      height: 1.6rem;
      background-image: url(/images/icons/menu/check.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 1.6rem;
      transform: translateY(-50%);
    }

    &:hover {
      background: rgba(255, 255, 255, .1);
    }
  `}
`
Profile.Switch = styled.div`
  margin-left: auto;
  font-weight: 300;
  color: ${colors.darkGreyOne};
`

Profile.Item.Icon = styled.img`
  display: block;
  width: 2.4rem;
  height: 2.4rem;
  margin-right: 2.4rem;
  color: ${colors.white};
`

Profile.Item.Title = styled.div`
  color: ${colors.white};
`

Profile.Item.DefaultOrgImage = styled.div`
  width: 3.5rem;
  height: 3.5rem;
  margin-right: 1.6rem;
  border-radius: 100%;
  background: #2d6ff2;
  font-size: 18px;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`

export { Profile }
