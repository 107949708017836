import React, { Fragment } from 'react'
import _ from 'lodash'
import Dropzone from 'react-dropzone'
import { withTranslation } from 'react-i18next'
import { api } from '../../../../../../../../../../services'
import className from 'classnames'
import { isSafari } from 'react-device-detect'
import { deactiveSoundIcon } from '../../../images'
import { Button, Col, Row } from 'antd'
import AudioPlayer from '../../../components/AudioPlayer'
import { AudioPlayerStyle } from '../../../components/AudioPlayer/styled'
import { general } from '../../../../../../../../../../constants'
import { bytesToSize } from '../../../../../../../../../../utils'
import { AddDetailAudio } from '../../Create'
import './DropzoneAudio.scss'

const getMediaDetail = (id, props) => {
  api.library.getMediaInfoById(id).then(data => {
    props.getSelectedFile([data.data])
  })
}

const DropzoneAudio = props => {
  const {
    t,
    uploadProgress,
    fileNotCompatible,
    fileSizeExceed,
    getSelectedFile,
    onDrop,
    uploadListClassName,
    checkedCompleteUpload,
    resetFileInCompatibleMessage,
    src
  } = props

  const item = uploadProgress[0]

  if (item && (item.percent === 100)) {
    getMediaDetail(item.id, props)
  }

  return (
    <Fragment>
      <div className="upload-modal insert-audio-detail">
        <div className="upload-modal__inner">
          {uploadProgress.length === 0 && (
            <div className="upload-modal__dropzone">
              <Dropzone accept={isSafari ? 'audio/*' : 'audio/mpeg, audio/mp3, audio/wav'} multiple={false} onDrop={onDrop}>
                {({ getRootProps, getInputProps, isDragActive }) => (
                  <div
                    className={className('upload-dropzone', {
                      'upload-dropzone_active': isDragActive
                    })}
                  >
                    <div className="upload-dropzone__text-wrapper">
                      <img src={deactiveSoundIcon} alt=""/>
                      <p>{t('media:drop_files')}</p>
                      <p>{t('media:or')}</p>
                      <Button>{t('media:import_files')}</Button>
                    </div>
                  </div>
                )}
              </Dropzone>
            </div>
          )}

          <div
            className={`upload-modal__list-wrapper ${uploadListClassName(
              uploadProgress
            )}`}
          >
            <div className="upload-modal__list-container">
              {fileNotCompatible && (
                <div className="upload-modal__error-item-wrapper">
                  <div className="upload-modal__error-message-container">
                    <div className="upload-modal__error-bar"/>
                    <div className="upload-modal__error-message">
                      {t('errors:upload_error')}
                    </div>
                  </div>

                  <Button
                    className="upload-modal__cancel-error"
                    type="primary"
                    onClick={() => resetFileInCompatibleMessage()}
                  >
                    {t('buttons:ok')}
                  </Button>
                </div>
              )}

              {fileSizeExceed && (
                <div className="upload-modal__error-item-wrapper">
                  <div className="upload-modal__error-message-container">
                    <div className="upload-modal__error-bar"/>
                    <div className="upload-modal__error-message">
                      {t('errors:file_size_exceed')}
                    </div>
                  </div>

                  <Button
                    className="upload-modal__cancel-error"
                    type="primary"
                    onClick={() => resetFileInCompatibleMessage()}
                  >
                    {t('buttons:ok')}
                  </Button>
                </div>
              )}

              {uploadProgress.length > 0 && (
                <div className="upload-modal__list">
                  <div key={item.uid} className="upload-modal__item">
                    <Row>
                      <Col span={12}>
                        <AudioPlayerStyle.Center>
                          {_.isEmpty(src) && <AudioPlayer src=""/>}{' '}
                          {!_.isEmpty(src) && <AudioPlayer src={src}/>}
                        </AudioPlayerStyle.Center>
                      </Col>
                      <Col span={12}>
                        <div className="gutter-box">
                          <div className="upload-modal-item__percent-border">
                            <div
                              className={`upload-modal-item__percent ${
                                item.status === general.CANCELED
                                  ? 'upload-modal-item__percent__cancel'
                                  : ''
                                }`}
                              style={{ width: item.percent + '%' }}
                            />
                          </div>

                          {item.status !== general.CANCELED && (
                            <div className="upload-modal-item__weight">
                              {checkedCompleteUpload(item) && (
                                <span>
                                  {bytesToSize(item.loaded)} {t('of')}{' '}
                                  {bytesToSize(item.total)}
                                </span>
                              )}
                              {!checkedCompleteUpload(item) && (
                                <span className="upload-modal-item__weight__complete">
                                  {t('general:completed')}
                                </span>
                              )}
                            </div>
                          )}
                          <AddDetailAudio
                            getSelectedFile={getSelectedFile}
                            mediaId={item.id}
                            fileName={item.name}
                            src={src}
                            uploadComplete={checkedCompleteUpload(item)}
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default withTranslation('audio')(DropzoneAudio)
