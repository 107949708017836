import React from 'react'

import { Col, Row, InputNumber, Alert, Button, message, Form } from 'antd'
import './LiveAssessment.scss'

import moment from 'moment'
import _ from 'lodash'
import { api } from '../../../../../services'
import { withTranslation } from 'react-i18next'
class LiveAssessment extends React.Component {
  state = {
    isFormDisabled: true,
    assessmentsLog: [],
    score: this.props.scores.assessment_score || {},
    isSubmitted: false,
    isSubmittingScore: false
  }

  componentDidUpdate(prevProps, prevState) {
    const { socket, id, isFinished, t } = this.props

    if (prevProps.socket !== socket) {
      if (socket.readyState === socket.OPEN) {
        socket.send(
          JSON.stringify({
            action: 'liveAssessment',
            assessment_action: 'getAssessmentTime',
            channel_id: id
          })
        )

        socket.onmessage = event => {
          const data = JSON.parse(event.data)
          if (data.event === 'getAssessmentTime') {
            this.setState({
              isFormDisabled: false,
              assessmentsLog: data.assessment_time
            })

            if (data.assessment_time.length === 0) {
              document.querySelectorAll('.assessment_score').forEach(item => {
                if (item.checked && !isFinished)
                  message.warning(t('recording_is_starting'))
                return (item.checked = false)
              })
            }
          }
        }
      }
    }
  }

  handleAssessment = index => {
    const { id } = this.props
    this.send({
      action: 'liveAssessment',
      assessment_action: 'putAssessmentTime',
      channel_id: id,
      content: { [`${index}`]: moment.utc().toDate() }
    })
  }

  send = obj => {
    const { socket } = this.props
    socket.send(JSON.stringify(obj))
  }

  handleQuestionClick = index => {
    const { isFinished, logs = [], seekVideoToTime } = this.props

    if (!isFinished || logs.length === 0) return

    const indexItem = logs.find(item => Object.keys(item)[0] === index)

    if (!indexItem) return
    seekVideoToTime(indexItem[index])
  }

  handleAssessmentScore = _.debounce((v, w) => this.handleScore(v, w), 1000)

  handleScore = (val, index) => {
    const { id, form } = this.props

    if (val && (isNaN(val.toString()) || val <= 0 || val > 100)) {
      form.validateFields()
      return
    }

    let scoreObj = this.state.score

    if (val) {
      scoreObj[index] = val
    } else {
      delete scoreObj[index]
    }
    this.setState(
      {
        score: scoreObj
      },
      () => {
        api.events
          .putScoreInAssessment(
            {
              id,
              is_submitted: false
            },
            scoreObj
          )
          .then(res => {
            console.log(res)
          })
      }
    )
  }

  handleSubmit = () => {
    const { id, assessments = [], t } = this.props
    const { score } = this.state
    if (assessments.length !== Object.keys(score).length) {
      message.warning(t('please_input_scores_before_submitting'))
      return
    }

    this.setState(
      {
        isSubmittingScore: true
      },
      () => {
        api.events
          .putScoreInAssessment(
            {
              id,
              is_submitted: true
            },
            score
          )
          .then(res =>
            this.setState({
              isSubmitted: true,
              isSubmittingScore: false
            })
          )
      }
    )
  }

  render() {
    const {
      assessments = [],
      isFinished,
      isHost,
      scores: { assessment_score = {}, is_submitted, total_submitted },
      t,
      form: { getFieldDecorator },
      broadcast
    } = this.props
    const {
      isFormDisabled,
      assessmentsLog,
      score,
      isSubmitted,
      isSubmittingScore
    } = this.state


    const formDisabled = isFinished ? false : isFormDisabled

    return (
      <>
        {assessments.length === 0 && isHost && (
          <Alert message={t('no_questions_added_till_now')} type="info" />
        )}
        {assessments.length > 0 && (
          <div className="live-assessment__container">
            <Row type="flex" justify="space-between" align="middle">
              <Col span={20}>
                <h2>{t('question')}</h2>
              </Col>
              <Col span={4}>
                <h2>{t('score')}</h2>
              </Col>
            </Row>

            <div className="live-assessment__container_questions">
              {assessments.map((assessment, index) => (
                <Row
                  type="flex"
                  justify="space-between"
                  style={{ marginTop: '1rem' }}
                >
                  <Col span={20}>
                    {isHost && (
                      <input
                        type="checkbox"
                        className="assessment_score"
                        checked={assessmentsLog.find(
                          item => Object.keys(item)[0] === index
                        )}
                        style={{ marginTop: '3px' }}
                        disabled={
                          formDisabled ||
                          assessmentsLog.find(
                            item => Object.keys(item)[0] === index
                          ) ||
                          assessment_score[index] ||
                          !broadcast
                        }
                        onChange={() => this.handleAssessment(index)}
                      />
                    )}
                    <span className="live-assessment_index">{index + 1}.</span>
                    <span
                      className="live-assessment_questions"
                      onClick={() => this.handleQuestionClick(index)}
                    >
                      {assessment}
                    </span>
                  </Col>
                  <Col span={4}>
                    <Form.Item colon={false} required={true}>
                      {getFieldDecorator(`assessment_score_${index}`, {
                        initialValue:
                          (assessment_score &&
                            Math.round(assessment_score[index])) ||
                          Math.round(score[index]) ||
                          '',
                        rules: [
                          {
                            validator: (rule, value, cb) =>
                              value > 0 && value <= 100,
                            message: '1-100'
                          }
                        ]
                      })(
                        <InputNumber
                          className="live-assessment_scores"
                          disabled={
                            formDisabled ||
                            !isHost ||
                            is_submitted ||
                            isSubmitted
                          }
                          onChange={val =>
                            this.handleAssessmentScore(val, index)
                          }
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              ))}
            </div>
            <div className="live-assessment__footer">
              {isHost && !total_submitted && (
                <Button
                  type="primary"
                  shape="round"
                  className="live-assessment__footer_submit"
                  onClick={this.handleSubmit}
                  loading={isSubmittingScore}
                  disabled={isSubmitted || is_submitted}
                >
                  {isSubmitted || is_submitted
                    ? t('all_scores_submitted')
                    : t('submit')}
                </Button>
              )}

              {total_submitted && (
                <Alert
                  message={`${total_submitted} ${t(
                    'persons_have_submitted_their_scores'
                  )}`}
                  type="info"
                  className="live-assessment__footer_message"
                />
              )}
            </div>
          </div>
        )}
      </>
    )
  }
}

export default Form.create()(withTranslation('v2')(LiveAssessment))
