import React, { Component, Fragment } from 'react'
import { Popconfirm, Spin } from 'antd'
import _ from 'lodash'
import { Page, Column, Table } from './styled'
import { ModalAddGroups } from './internal'

import { Spinner } from '../../../../components'

import { pencil, trash } from '../../images'
import { withTranslation } from 'react-i18next'
// import api from '../../../../services/api'

class Groups extends Component {
  state = {
    selectedOrgId: null,
    deletedGroupId: null,
    activeGroup: null,
    isModalVisible: false
    // search: '',
    // searchList: []
  }

  fetchOrganizations = org => {
    this.setState({
      selectedOrgId: org.id
    })
  }

  setModalVisible = value => {
    this.setState({
      isModalVisible: value
    })
  }

  cancel = () => {
    this.setState({
      isCreation: false,
      isModalVisible: false,
      activeGroup: null
    })
  }

  deleteGroupById = (e, orgId, groupId) => {
    e.stopPropagation()

    this.setState({
      deletedGroupId: groupId
    })

    this.props.deleteGroup(orgId, groupId).then(() => {
      this.setState({
        deletedGroupId: null
      })
    })
  }

  editGroup = activeGroup => {
    this.setState({
      activeGroup,
      isModalVisible: true
    })
  }

  create = (data, orgId) => {
    const { createGroup } = this.props

    this.setState({
      isCreation: true
    })

    return createGroup(orgId, data).then(() => {
      this.setState({
        isModalVisible: false,
        isCreation: false,
        activeGroup: null
      })
    })
  }

  update = (data, orgId) => {
    const { updateGroup } = this.props
    const { activeGroup } = this.state

    this.setState({
      isEditing: true
    })
    return updateGroup(activeGroup.id, orgId, data).then(() => {
      this.setState({
        isModalVisible: false,
        isEditing: false,
        activeGroup: null
      })
    })
  }

  // onSearch = _.debounce(
  //   value =>
  //     this.setState(
  //       {
  //         search: value
  //         // loading: true
  //       },
  //       () => {
  //         this.fetchData(true)
  //       }
  //     ),
  //   1000
  // )

  // fetchData = () => {
  //   const { search } = this.state
  //   if (search) {
  //     return api.organizations.searchOrgGroup(search).then(res => {
  //       this.setState({ searchList: res.data.rows })
  //     })
  //   }
  // }

  render() {
    const {
      organizations: {
        isFetching,
        list: { rows: list }
      },
      user: { active_organization_id: activeOrganizationId, isSuperAdmin },
      t
    } = this.props
    const {
      selectedOrgId,
      deletedGroupId,
      isModalVisible,
      activeGroup,
      isCreation,
      isEditing
      // searchList,
      // search
    } = this.state
    let orgList = []
    if (!_.isEmpty(list)) {
      orgList = isSuperAdmin
        ? list
        : list.filter(i => i.role && i.role.name === 'Admin')
    }

    const orgId =
      selectedOrgId ||
      activeOrganizationId ||
      (!!orgList.length && orgList[0].id)

    const selectedOrg = orgList.find(i => i.id === orgId) || {}

    const isOrgAdmin =
      selectedOrg && selectedOrg.role && selectedOrg.role.name === 'Admin'
    const groups = selectedOrg.groups || []

    // let sortedList = groups
    // if (search) {
    //   sortedList = _.sortBy(searchList, [
    //     function(o) {
    //       return o.title
    //     }
    //   ])
    // } else {
    //   sortedList = _.sortBy(groups, [
    //     function(o) {
    //       return o.title
    //     }
    //   ])
    // }

    return (
      <Page>
        {isFetching ? (
          <Spinner />
        ) : (
          <Fragment>
            <Page.Head>
              <Column>
                <h2>{t('groups_title')}</h2>

                <Page.Filter
                  data={orgList}
                  defaultValue={selectedOrg.title}
                  label={t('labels:organization')}
                  noIndent
                  propKey="title"
                  callback={this.fetchOrganizations}
                />
              </Column>
              {/* <Column>
                <Search
                  placeholder={t('v4:search_group')}
                  onChange={e => this.onSearch(e.target.value)}
                />
              </Column> */}

              {(isSuperAdmin || isOrgAdmin) && (
                <Column>
                  <Spin spinning={isFetching}>
                    <Page.Head.Button
                      type="primary"
                      className="rounded"
                      onClick={() => this.setModalVisible(true)}
                    >
                      {t('buttons:add_group')}
                    </Page.Head.Button>
                  </Spin>
                  {isModalVisible && (
                    <ModalAddGroups
                      visible={isModalVisible}
                      onCancel={() => this.cancel()}
                      onSubmit={(data, isEdit) =>
                        isEdit
                          ? this.update(data, orgId)
                          : this.create(data, orgId)
                      }
                      loading={isCreation || isEditing}
                      group={activeGroup}
                    />
                  )}
                </Column>
              )}
            </Page.Head>

            <Table style={{ paddingBottom: '10rem' }}>
              <Table.Header>
                <Table.Label>{t('labels:groups_list')}</Table.Label>
              </Table.Header>

              <Table.Body>
                <Table.Head>
                  <Table.Head.Column>{t('v4:id_number')}</Table.Head.Column>
                  <Table.Head.Column>{t('labels:name')}</Table.Head.Column>
                </Table.Head>

                {groups.map(group => (
                  <Spin
                    spinning={deletedGroupId && deletedGroupId === group.id}
                    key={group.id}
                  >
                    <Table.Item>
                      <Table.Column>
                        <Table.Item.Info>
                          <Table.Item.Organisation>
                            {group.id}
                          </Table.Item.Organisation>
                        </Table.Item.Info>
                      </Table.Column>
                      <Table.Column>
                        <Table.Item.Info>
                          <Table.Item.Organisation>
                            {group.title}
                          </Table.Item.Organisation>
                        </Table.Item.Info>
                      </Table.Column>

                      <Table.Events>
                        <Table.EventLink>
                          <Table.EventIcon
                            src={pencil}
                            onClick={() => this.editGroup(group)}
                          />
                        </Table.EventLink>

                        {(isSuperAdmin || isOrgAdmin) && (
                          <Popconfirm
                            title={t('management:delete_warning')}
                            okText={t('general:yes')}
                            cancelText={t('general:no')}
                            placement="right"
                            onClick={e => e.stopPropagation()}
                            onCancel={e => e.stopPropagation()}
                            onConfirm={e =>
                              this.deleteGroupById(e, orgId, group.id)
                            }
                            icon={false}
                          >
                            <Table.EventLink>
                              <Table.EventIcon src={trash} />
                            </Table.EventLink>
                          </Popconfirm>
                        )}
                      </Table.Events>
                    </Table.Item>
                  </Spin>
                ))}
              </Table.Body>
            </Table>
          </Fragment>
        )}
      </Page>
    )
  }
}

export default withTranslation('management')(Groups)
