/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-08-09 16:41:54
 * @LastEditTime: 2019-12-19 10:53:49
 * @LastEditTime: 2019-09-09 11:43:43
 * @LastEditors: Please set LastEditors
 */

export default {
  general: {
    save: 'içeriği kaydet',
    autosaved: 'otomatik kaydedildi',
    back_to: 'geri dön',
    by: 'tarafından',
    chat: 'sohbet',
    completed: 'Tamamlandı',
    canceled: 'İptal Edildi',
    component: 'Bileşen',
    confirm: 'Onayla',
    course: 'Kurs',
    date: 'Tarih',
    details: 'Detaylar',
    empty: 'Veri yok',
    enrolled: 'Kaydedildi',
    free: 'Ücretsiz',
    graph: 'Grafik',
    loading: 'Yükleniyor...',
    lesson: 'ders',
    lessons: 'dersler',
    live_assessment: 'Canlı değerlendirme',
    live_event: 'Canlı etkinlik',
    no: 'Hayır',
    of: 'ın',
    or: 'veya',
    organization: 'organizasyon',
    page: 'Sayfa',
    pages: 'Sayfalar',
    personal: 'Kişisel',
    poll: 'Anket',
    rating: 'Rating',
    reset: 'Sıfırla',
    signin: 'Oturum Aç',
    signup: 'Kayıt ol',
    soon: 'Yakında',
    type: 'Tür',
    uploading: 'Yükleniyor...',
    yes: 'Evet',
    something_went_wrong: 'Bir şeyler ters gitti!',
    view_all: 'Tümünü görüntüle',
    upload_successful: 'Yükleme Başarılı',
    upload_successful_message:
      'Excel dökümanlarınız başarılı bir şekilde yüklendi ve şu anda işleniyor. Bu biraz sürebilir.',
    check_email_upload_successful:
      'Lütfen<0>emailinizi kontrol edin</0> ve yüklenen kullanıcı listesinin <1>raporuna</1> göz atın.',
    is_private: 'Gizli'
  },

  account: {
    title: 'Hesap',
    subtitle: 'Ayarlarınızı ve hesap bilgilerinizi yönetin.',
    saved: 'Hesap başarılı bir şekilde değiştirildi'
  },

  assessments: {
    title: 'Değerlendirmeler',
    subtitle: 'Bir değerlendirme oluştur',
    finished: 'Değerlendirme bitti',
    countdown: 'Canlı değerlendirme birazdan yayında olacak',
    create: 'Değerlendirme Oluştur',
    list_title: 'Değerlendirmeleriniz',
    delete_warning: 'Bu öğeyi silmek istediğinizden emin misiniz?',
    details_title: 'Değerlendirme Detaylarını Ekle',
    details_subtitle:
      'Değerlendirmenize isim verip hedef kitlenize ne öğrenmelerini beklediğinizi söyleyin.',
    disclaimer: `Kayıtlı Değerlendirme aşağıdakileri yapmayacağını garanti ediyorum:
    - herhangi bir üçüncü tarafın haklarını ihlal etmeyecek
    - herhangi bir yasadışı eylem barındırmayacak, gurur zedeleyici, müstehcen, uygunsuz, açık saçık, pornografik, şiddet içeren, küfürlü, aşağılayıcı, tehditkâr, ayrımcı içerikleri veya yıldız işareti ile gizlenmiş olsa dahi içermeyecek
    - diğer kişiler üzerine kişisel saldırıları barındırmayacak
    - diğer katılımcıların, halka mal olmuş kişilerin veya ünlülerin herhangi bir taklidini içermeyecek
    - kişinin rızası olmadan o kişiyle ilgili kayıtlar veya kişisel bilgiler barındırılmayacak ve dünyanın herhangi bir yerindeki verilerin korunması düzenlemeleri ihlal etmeyecek
    - herhangi bir ticari talebi veya “spam” şeklini barındırmayacak`
  },

  buildersettings: {
    title: 'Yayınlama Ayarları',
    subtitle:
      'Ayarları etkinliğinize ekleyerek etkinliğinizi görüntüleyebilir veya etkileşime geçebilirsiniz'
  },

  buttons: {
    market_place: 'Market Place',
    back_to_normal: 'Back To Normal',
    add: 'Ekle',
    add_page: 'Sayfa ekle',
    add_lesson: 'Ders ekle',
    add_answer: 'Yeni cevap ekle',
    add_group: 'Grup Ekle',
    add_organization: 'Organizasyon Ekle',
    add_question: 'Soru ekle',
    add_quiz: 'Test Ekle',
    add_users: 'Kullanıcı Ekle',
    back: 'Geri',
    back_to_course: 'Kurs Genel Bakışına Geri Dön',
    back_to_lesson: 'Ders genel bakışına geri dön',
    back_to_media: 'Medya Kütüphanesine Geri Dön',
    back_to_details: 'Detaylara geri dön',
    cancel: 'İptal Et',
    clear: 'Temizle',
    close: 'Kapat',
    clone: 'Çoğalt',
    close_lesson: 'Dersi Çoğalt',
    clone_course: 'Kursu Çoğalt',
    confirm: 'Onayla',
    create: 'Oluştur',
    download: 'İndir',
    download_mass_user_tamplate: 'Şablon İndir',
    add_mass_users: 'Toplu Biçimde Kullanıcı Ekle',
    upload_excel_list: 'Excel Listesi Yükle',
    delete: 'Medyayı Sil',
    edit: 'Düzenle',
    edit_course: 'Kursu Düzenle',
    enroll: 'Kaydol',
    finish: 'Bitir',
    upload_image: 'Resim yükle',
    upload_image_video: 'Resim veya video yükle',
    select_media_library: 'Medya kütüphanesi seç',
    make_public: 'Halka açık yap',
    make_private: 'Özel yap',
    make_org: 'Organizasyon yap',
    media: 'Medya Kütüphanesi',
    my_theme: 'Temam',
    next: 'İlerle',
    next_lesson: 'Sonraki Ders',
    next_step: 'Sonraki adım',
    previous_step: 'Önceki adım',
    publish: 'Yayınla',
    publish_assessment: 'Değerlendirmeni yayınla',
    publish_course: 'Kursunu yayınla',
    publish_event: 'Etkinliğini yayınla',
    reset: 'Sıfırla',
    reset_elements: 'Öğeleri sıfırla',
    save: 'Kaydet',
    creating: 'Oluşturuluyor...',
    updating: 'Güncelleniyor...',
    saved: 'Kaydedildi',
    save_for_later: 'Sonrası için kaydet',
    save_settings: 'Ayarları kaydet',
    select_files: 'Dosyaları seç',
    select_theme: 'Tema seç',
    start_lesson: 'Derse Başla',
    update: 'Güncelle',
    upload: 'Yükle',
    upload_audio: 'Ses Dosyası Yükle',
    upload_photo: 'Bir profil fotoğrafı yükle',
    upload_video: 'Video Yükle',
    enrolled: 'Kaydedildi',
    unenroll: 'Kayıt silindi',
    from_library: 'Medya Kütüphanesi',
    remove: 'Kaldır',
    removed: 'Kaldırıldı',
    start_broadcast: 'Yayınlamaya Başla',
    end_broadcast: 'Yayını Sonlandır',
    camera: 'Kamera',
    screen: 'Ekran',
    screen_with_camera: 'Kamerayla Ekran'
  },

  card: {
    quick_add: 'Hızlı ekle',
    add: 'Ekle',
    clone: 'Çoğalt',
    completed: 'dersler tamamlandı'
  },

  course: {
    upcoming_course: 'Yaklaşan Kurslar',
    unPublishEvent: 'Etkinliği Yayımdan Kaldır',
    unPublishAssessment: 'Değerlendirmeyi Yayımdan Kaldır',
    pastCourse: 'Alıştırma Kursu',
    upcoming: 'Yaklaşan Canlı etkinlik',
    pastEvent: 'Geçmiş Canlı Etkinlik',
    pastAssessment: 'Geçmiş Canlı Değerlendirme',
    upcomingAssessment: 'Yaklaşan Canlı Değerlendirme',
    created: 'Tarih Oluşturuldu:',
    assignees: 'vekiller',
    organisations:
      ' {{ organizasyonlar}} Organizasyonlar, <2> {{ kullanıcılar }} kullanıcılar</2>',
    event_date: 'Canlı etkinlik tarihi <1> {{ tarih }} </1>',
    delete_warning: 'Bunu silmek istediğinizden emin misiniz',
    clonning_no_lesson:
      'Lütfen bu kursu çoğaltmak için en az bir ders ekleyin.',
    clone_warning: 'Bunu çoğaltmak istediğinizden emin misiniz',
    clone_success: 'çoğaltma sürecinde',
    disclaimer: `Kursumun aşağıdakilere sebep olmayacağını garanti ederim:
    - herhangi bir üçüncü tarafın haklarını ihlal etmeyecek
    - herhangi bir yasadışı eylem barındırmayacak, gurur zedeleyici, müstehcen, uygunsuz, açık saçık, pornografik, şiddet içeren, küfürlü, aşağılayıcı, tehditkâr, ayrımcı içerikleri veya yıldız işareti ile gizlenmiş olsa dahi içermeyecek
    - diğer kişiler üzerine kişisel saldırıları barındırmayacak
    - diğer katılımcıların, halka mal olmuş kişilerin veya ünlülerin herhangi bir taklidini içermeyecek
    - kişinin rızası olmadan o kişiyle ilgili kayıtlar veya kişisel bilgiler barındırılmayacak ve dünyanın herhangi bir yerindeki verilerin korunması düzenlemeleri ihlal
    - herhangi bir ticari talebi veya “spam” şeklini barındırmayacak`
  },

  coursecreate: {
    dropzone_text: 'Medya Ekle',
    sidebar_text: '<1/> sizin <3/> dersleriniz için bir tema seçin',
    delete_warning: 'Silmek istediğinizden emin misiniz?'
  },

  coursepage: {
    quizview_loading: 'Cevaplar kontrol ediliyor. Lütfen bekleyiniz...',
    quizview_answer: 'Cevabınız'
  },

  coursequiz: {
    title: 'Tüm derslerdeki Quizler'
  },

  dashboard: {
    tooltip_title: 'Bu videoyu bir daha gösterme',
    page_title: 'Kurslarınızın İstatistikleri',
    page_subtitle: 'Oluşturduğunuz kursların durumu',
    profile_add_info:
      'Profilinizi tamamlamak için <1>{{tamamlanmamışMetin}}</1> ekleyin.',
    profile_build:
      'Profilinizi <1>burada</1> oluşturun, let us know your interests and skills.',
    profile_title: 'Profil Gücü',
    more_details: 'Daha fazla detay'
  },

  errors: {
    answer: 'Lütfen cevap girin',
    answer_correct: 'Doğru cevabı seçin',
    code: 'Lütfen kodunuzu girin',
    criterion: 'Lütfen kriter girin',
    duration: 'Süre gerekli',
    element: 'Lütfen öğe girin',
    email: 'Lütfen email adresinizi girin',
    email_invalid: 'Girilen email geçersiz',
    feedback_correct: 'Lütfen geri bildirimi doğru girin',
    feedback_incorrect: 'Lütfen yanlış geri bildirimi düzeltin',
    host: 'Lütfen etkinlik sahibini girin',
    host_assessment: 'Lütfen değerlendirme sahibini girin',
    image: 'Lütfen bir resim seçin',
    location: 'Lütfen konumunuzu girin',
    live_location: 'Lütfen bir yayın konumu seçin',
    limit: 'Lütfen canlı yayında geçen dakikaları girin',
    min_3char: 'minimum 3 karakter',
    number: 'lütfen pozitif sayı girin.',
    host_email_require: `Oturum sahibinin email adresi gerekli.`,
    attendee_email_require: `Katılımcının email adresi gerekli.`,
    name: 'Lütfen isminizi girin',
    not_found_course: 'Kurs bulunamadı',
    not_found_event: 'Etkinlik bulunamadı',
    not_found_assessment: 'Değerlendirme bulunamadı',
    password: 'Lütfen şifrenizi girin',
    password_digit: 'En az bir rakam olmalı',
    password_short: 'Şifre çok kısa, en az 10 karakter',
    password_special: 'En az bir özel karakter',
    password_uppercase: 'En az bir büyük harfli karakter',
    password_lowercase: 'En az bir küçük harfli karakter',
    phone: 'Lütfen telefon numaranızı girin',
    phone_invalid: 'Geçersiz telefon numarası',
    position: 'Lütfen pozisyonunuzu girin',
    question: 'Lütfen soru girin',
    start_date: 'Başlangıç Tarihi & Saati gerekli',
    tag: 'Lütfen bir etiket seçin',
    topics: 'Lütfen en az bir konu seçin.',
    live_topics: 'Lütfen konu ekleyin',
    title: 'Lütfen başlık ekleyin',
    title_assessment: 'Lütfen değerlendirme başlığını girin',
    title_course: 'Lütfen kurs başlığını girin',
    title_empty: 'Başlık boş kalamaz',
    title_event: 'Lütfen etkinlik başlığı girin',
    title_lesson: 'Lütfen ders başlığı girin',
    title_quiz: 'Lütfen quiz ismi girin',
    username: 'Lütfen kullanıcı adı girin',
    upload_error: 'Hata - Dosya uyumlu değil!',
    upload_more_than_10:
      'Bir defada sadece maksimum 10 dosya yükleyebilirsiniz.',
    topics_max: 'Lütfen maksimum 3 konu ekleyin.',
    upload_excel: 'Lütfen doğru dosyayı yüklediğinizden emin olun.',
    title_excel: 'Şablon yüklerken hata:',
    file_size_exceed: 'Dosya boyutu fazla büyük',
    subtitle_excel:
      'Lütfen size sağlanan şablonu kullandığınızdan ve sütunların doğru bir şekilde doldurulduğundan emin olun.',
    select_broadcast_option:
      'Lütfen bu etkinliği yayımlayacak bir seçenek seçin',
    multi_hosts_number_error:
      'Sunucuların sayısı bu etkinlik için belirlenenden fazla olamaz',
    conference_people_number_error: `Katılımcıların sayısı bu etkinlik için belirlenenden fazla olamaz.`,
    select_max_host: 'Bu etkinlik için en az 1 sunucu seçin.',
    select_max_conference: 'Bu etkinlik için en az 1 katılımcı seçin.',
    stream_stop_error: 'Yayın beklenmedik bir şekilde durdu!',
    unsecure_context:
      'Önemli Hata: Tarayıcı güvenli olmayan bir içerik yüzünden kamera ve mikrofona erişemiyor. Lütfen SSL kurup https aracılığıyla erişim sağlayın',
    ws_not_supported: 'Önemli Hata: WebSocket bu tarayıcıda desteklenmiyor'
  },

  events: {
    title: 'Canlı Etkinlik Detayları Ekle',
    change_host: 'Oturum Sahibini Değiştir',
    finished: 'Etkinlik sona erdi',
    countdown: 'Canlı etkinlik yayında olacak ',
    subtitle:
      'Canlı etkinliğinize isim verin ve hedef kitlenin ne öğrenmeyi beklemeleri gerektiğini belirtin.',
    poll_disabled: 'Bu etkinlikte anket mevcut değil',
    disclaimer: `Kayıtlı etkinliğimin aşağıdakileri yapmayacağını garanti ediyorum:
    - herhangi bir üçüncü tarafın haklarını ihlal etmeyecek
  - herhangi bir yasadışı eylem barındırmayacak, gurur zedeleyici, müstehcen, uygunsuz, açık saçık, pornografik, şiddet içeren, küfürlü, aşağılayıcı, tehditkâr, ayrımcı içerikleri veya yıldız işareti ile gizlenmiş olsa dahi içermeyecek
  - diğer kişiler üzerine kişisel saldırıları barındırmayacak
  - diğer katılımcıların, halka mal olmuş kişilerin veya ünlülerin herhangi bir taklidini içermeyecek
  - kişinin rızası olmadan o kişiyle ilgili kayıtlar veya kişisel bilgiler barındırılmayacak ve dünyanın herhangi bir yerindeki verilerin korunması düzenlemeleri ihlal etmeyecek
  - herhangi bir ticari talebi veya “spam” şeklini barındırmayacak'`,
    prompt_message:
      'Bu sayfayı terk etmek etkinliğe son verecek. Bu sayfayı terk etmek istediğinize emin misiniz?',
    duration_exceed_message:
      'Yayın süreniz sınırı aşıyor, uygulama yayınlamayı bırakacak.',
    before_schedule_message:
      'Ayarlanmış etkinlik saatinden önce yayını başlatmak üzeresiniz. Lütfen yayını durdurmanın etkinliğe son vereceğini unutmayın.',
    browser_unsupportive_message:
      'Tarayıcınız ekran paylaşımını desteklemiyor. Desteklenen tarayıcıları bu linkte bulabilirsiniz',
    cancel_switch_host_message:
      'Oturum sahibi mevcut değil. Etkinlik siz tarafınca devam ettirilecek.',
    coaching_prompt_message: 'Etkinlik bitene kadar bu sayfayı terk etmeyiniz.',
    event_modal_warn: 'Bu etkinliği terk etmek mi istiyorsunuz?',
    confirm_message: 'Tamam’a tıklarsanız etkinliğiniz sona erecek.',
    screen_share_denied: 'Ekranınızı paylaşmayı reddettiniz',
    media_not_found:
      'Cihazınızda kamera veya mikrofon bulunmuyor veya çalıştırılmasına izin verilmiyor.',
    media_not_access:
      'Kamera veya mikrofon başka bir uygulama tarafından kullanılıyor ki bu da cihazların görünmesine izin vermiyor',
    constraint_error:
      'Video ve ses kısıtlamalarına uyan bir cihaz bulunamadı . Video ve ses kısıtlamalarını değiştirebilirsiniz',
    media_access_denied: 'Kamera ve mikrofona erişim izniniz bulunmamakta.'
  },

  filters: {
    days7: 'Son 7 gün',
    days15: 'Son 15 gün',
    days30: 'Son 30 gün',
    days365: 'Son 365 gün',
    date: 'Tarih oluşturuldu',
    relevance: 'İlgi Düzeyi',
    popularity: 'Popülarite',
    name: 'İsim',
    group: 'Grup',
    Newest_to_Oldest: 'Yeniden Eskiye',
    Oldest_to_Newest: 'Eskiden Yeniye',
    A_Z: 'A’dan Z’ye',
    Z_A: 'Z’den A’ya'
  },

  helpers: {
    password:
      'Hesabınızın güvenliğinden emin olmak için sizden şifrenizde kullanmanız için en az bir küçük harf ve rakam istiyoruz. Ayrıca şifreniz minimum 10 karakter içermelidir.',
    phone:
      'Lütfen telefon numarasının başına ülke kodunu da ekleyin, örneğin: +65',
    username:
      'Kullanıcı adınız kamuya ait bilgi olarak değerlendirilir ve gerektiğinde diğer kullanıcılar ile paylaşılır. Bu yüzden kullanıcı adınızda kişisel bilgilerinizi kullanmamanızı tavsiye ederiz',
    public_description_normal:
      'Size ait <0><0/></0> kaydedildi and varsayılan durumu Gizli olarak belirtildi. Bu da bu içeriği sadece siz görebilirsiniz demektir. Bu içeriği yayınlamak için aşağıdaki seçeneklerden birini seçebilirsiniz. Kursunuzu Halka Açık şekilde yayınlayarak platformda bulunan tüm kullanıcılara mevcut hale getiriyorsunuz. Özel Kullanıcı seçeneği kurslarınızı diğer Özel Kullanıcılarla Sharelook hesaplarıyla ilişkilendirilmiş email adreslerini kullanarak paylaşabilmenizi sağlayacak.',
    public_description_admin:
      'Bu <0><0/></0> kaydedildi ve varsayılan durumu Gizli olarak ayarlandı. Bu da sadece sizin (ve içerik üreticisinin) bu içeriği görebileceği anlamına gelir. Bu içeriği yayınlamak için aşağıdaki seçeneklerden birini seçebilirsiniz. Kursunuzu Halka Açık şekilde yayınlayarak organizasyonunuza dışarıdan dahil olanlar da dahil olmak üzere platformdaki tüm kullanıcıların erişimine açık hale getiriyorsunuz. Organizasyona yönelik yayınlamak demek organizasyon içindeki tüm kullanıcılar bunu görebilecek demektir. 1 veya daha fazla grup için de yayınlayabilirsiniz ki bu da sadece o gruplar içerisindekilerin görebileceği anlamına gelir.  Son olarak, Sharelook hesaplarını ve email adreslerini kullanarak organizasyonunuzun içinden ve dışından özel kullanıcılara da yayın yapabilme seçeneğine sahipsiniz.'
  },

  labels: {
    add: 'Ekle',
    portrait: 'Crop Portrait',
    landscape: 'Crop Landscape',
    answer_type: 'Cevap türü',
    answers: 'Cevaplar',
    back_to_quiz: 'Quiz listesine geri dön',
    back_to_question: 'Soru listesine geri dön',
    chat: 'Sohbet',
    chat_available: 'Bu etkinlikte sohbet mevcut değil',
    chat_report: 'Sohbet Raporu',
    choice_single: 'Tek seçenek',
    choice_multiple: 'Çoktan seçmeli',
    choice_free: '"Serbest" seçim',
    choice_sorting: '"Sınıflandırma" seçenekli',
    choice_matrix: '"Matris Sınıflandırma" seçenekli',
    clone_lesson_permission: 'Çoğaltma izni',
    code: 'Kod',
    correct: 'Doğru',
    created: 'Tarih oluşturuldu',
    clone_modal: 'Kursu çoğalt',
    cloning_course: 'Kurs çoğaltılıyor...',
    cloning: 'Çoğaltılıyor...',
    cloning_course_desc: 'Bu işlem biraz sürebilir. Lütfen biraz sabırlı olun',
    cloning_success: 'Kurs "<0/>" başarılı bir şekilde çoğaltıldı!',
    description: 'Tanım',
    disclaimer: 'Feragat',
    drop_file: 'Dosyayı buraya bırakın...',
    duration: 'Süre',
    edit: 'Düzenle',
    email: 'Email',
    event: 'Etkinlik',
    event_all: 'Tüm Etkinlikleri',
    event_date: 'Canlı Etkinlik Yerel Tarih/Saat',
    event_this: 'Bu Etkinlik',
    expire: 'Geçerliliği sona eriyor',
    expiry_date: 'Son geçerlilik tarihi',
    forgot: 'Şifreyi mi unuttunuz?',
    group: 'Grup',
    groups_list: 'Grup Listesi',
    host: 'Oturum Sahibi',
    id_annotation: 'Dipnot id',
    id_quiz: 'Quiz id',
    incorrect: 'Yanlış',
    interests: 'İlgi alanları',
    invited: 'Davet edildi',
    live_chat: 'Canlı Tartışma',
    live_stream_limit: 'Canlı yayında geçen dakikalar',
    contact_text:
      'Aylık canlı yayın süre limitinizi değiştirmek için lütfen bizimle irtibat kurun',
    contact_admin:
      '<0>Aylık canlı yayın süre limitinizi değiştirmek istiyorsanız lütfen bizimle bu adres üzerinden irtibat kurun</0> <1>admin@sharelook.com</1>',
    limit_stream_text:
      'Canlı Yayın Kalan Dakika: <0>{{ sınır }}</0> dakikalar <1/><2/> <3>Aylık canlı yayın süre limitinizi değiştirmek için lütfen bizimle bu adresten irtibat kurun</3> <4>admin@sharelook.com</4>',
    location: 'Konum',
    lessons: 'Dersler',
    location_broadcast: 'Oturum Sahibi Sunucu Konumu',
    location_broadcast_subtitle:
      'Görüntü ses gecikmesini azaltmak için yayıncı oturum sahibinin konumuna en yakın konumu ekleyin',
    media_manager: 'Medya Yöneticisi',
    minutes: 'Dakika',
    name: 'İsim',
    online_quiz: 'Çevrimiçi Quiz',
    organiser: 'Yazar',
    organization: 'organizasyon',
    overall_emotion: 'Genel Tecrübe:',
    organizations_list: 'Organizasyonların Listesi',
    password: 'Şifre',
    participants: 'Katılımcılar',
    picture: 'Resim (16:9)',
    phone: 'Telefon',
    photo: 'Fotoğraf',
    poll: 'Anket',
    position: 'Pozisyon',
    processing: 'İşleniyor',
    privacy_settings: 'Gizlilik Ayarları',
    question: 'Soru',
    quiz_create: 'Yeni Quiz Oluştur',
    quiz_name: 'Quiz İsmi',
    quiz_update: 'Quizi güncelle',
    select_organization: 'Bir organizasyon seçin',
    settings: 'Ayarlar',
    show: 'Göster',
    show_graph: 'Grafik sonuçlarını göster',
    signin: 'Oturum Aç',
    signup: 'Kayıt ol',
    sort_by: 'Sırala',
    start_date: 'Başlangıç tarihi',
    tags: 'Etiketler',
    terms: 'Bu <1>hüküm ve koşullara</1> katılıyorum.',
    title: 'Başlık',
    topics: 'Konular',
    topic: 'Konu',
    total_comments: 'Toplam Yorumlar',
    username: 'Kullanıcı adı',
    users_list: 'Kullanıcı Listesi',
    users_emails: 'Özel kullanıcı emailleri gir',
    description_specifiction_email:
      'Lütfen birden çok özel kullanıcı ile paylaşmak için her satıra 1 email adresi girin.',
    message_specific_email: 'Lütfen özel kullanıcının emailini girin.',
    viewers: 'Görüntüleyiciler',
    edit_course: 'Düzenle',
    edit_assessment: 'Düzenle',
    edit_event: 'Düzenle',
    delete_course: 'Sil',
    delete_event: 'Sil',
    delete_assessment: 'Sil',
    view_course: 'Görüntüle',
    view_event: 'Görüntüle',
    view_assessment: 'Görüntüle',
    date_event: 'Canlı etkinlik tarihi',
    date_assessment: 'Canlı değerlendirme tarihi',
    clone_of: 'Çoğalt',
    clone_course:
      'Ctüm dersleri ve sayfaları içermek üzere bu kursun orijinalinden bağımsız bir şekilde değiştirebileceğiniz bir klonunu oluşturun.',
    make: 'Yap',
    available_to: 'her Sharelook kullanıcısının erişimindedir',
    uploaded_by: 'Yükleyen kişi: ',
    course: 'Kurs',
    assessment: 'Değerlendirme',
    user_not_exist:
      'Böyle bir kullanıcı bulunmuyor, davet göndermek ister misiniz?',
    check_user: 'Tebliğ',
    user_not_exist_in_org:
      'Bu organizasyonda böyle bir kullanıcı bulunmuyor, davet göndermek ister misiniz?',
    title_modal_stream: 'Organizasyon Canlı yayın Sınırını Değiştir',
    des_warning_stream: 'Değiştirmek üzeresiniz',
    monthly_stream: 'aylık canlı yayın limiti:',
    min_per_month: 'dakika/ay',
    broadcast_label: 'Yapmak istiyorum:',
    hosts_emails: `Özel oturum sahibinin emailini girin`,
    attendee_emails: `Özel katılımcının emailini girin`,
    description_host_specifiction_email:
      'Lütfen her satıra 1 email adresi girin.',
    presenter_label: 'Sunucu',
    presenter_label_conference: 'Kişi Sayısı',
    event_privacy: 'Konferans Modu'
  },

  broadcastOptions: {
    stream_from_phone: 'Telefonumla Canlı Yayın',
    other_webrtc: 'Pc’mden Web Semineri',
    multihost: 'Pc’mden Çoklu Web Semineri',
    peer2peer: 'Koçluk Oturumu',
    conference: 'Canlı Konferans'
  },

  lessoncreate: {
    title: 'Ders detaylarını Ekle',
    subtitle:
      'Değerlendirmenize isim verip hedef kitlenize ne öğrenmelerini beklediğinizi söyleyin.',
    input_label_title: 'Ders başlığı',
    input_label_topics: 'Ders konuları',
    input_label_description: 'Ders tanımı',
    delete_warning: 'Bu sayfayı silmek istediğinizden emin misiniz?'
  },

  lessonoverview: {
    title: 'Ders Ekle',
    subtitle: 'Var olan bir ders ekle veya yeni bir tane oluştur',
    new_lesson: 'yeni bir ders oluştur',
    new_lesson_sub: 'En baştan yeni bir ders oluştur!',
    existing_lesson: 'Var olan bir ders seç',
    existing_lesson_sub:
      'Var olan bir derse bağlan; bu ders için yapılan değişiklikler diğer kurslardaki aynı dersi de etkileyecek.',
    clone_lesson: 'var olan dersi çoğalt',
    clone_lesson_sub:
      'Bu dersin benzersiz bir kopyasını oluştur; bu ders için yapılan değişiklikler orijinal dersi etkilemeyecektir.',
    clone_lesson_modal_sub:
      'Lütfen kursunuza eklemek istediğiniz dersin altındaki klonla(çoğalt) butonuna tıklayınız.'
  },

  management: {
    groups_title: 'Grupları Yönet',
    delete_warning: 'Emin misiniz?',
    organizations_title: 'Organizasyonlarım',
    organizations_subtitle: 'Listene organizasyon ekle',
    users_title: 'Kullanıcıları yönet',
    users_position: 'Kıdemli Hesap Yöneticisi',
    user_not_found: 'Kullanıcı bulunamadı',
    select_org:
      'Kullanıcıları yönetmek için lütfen bir organizasyon bulup seçin'
  },

  audio: {
    modal_title: 'Ses Ekle',
    list_view: 'Liste Görünümü',
    create_audio_modal_title: 'Ses Oluştur',
    edit_audio_modal_title: 'Ses Düzenle',
    public_audio_not_found: 'Ses bulunamadı.',
    private_audio_not_found: 'Henüz herhangi bir ses dosyası yüklemediniz',
    are_you_sure_to_delete: 'Silmek istediğinizden emin misiniz',
    type_something: 'Bir şey yaz',
    select_voice: 'Seç',
    file_name: 'Dosya adı',
    description: 'Tanım',
    topic: 'Konu',
    key_words_tag: 'Anahtar sözcük Etiketi',
    mp3_audio: 'MP3 Ses',
    created: 'Oluşturuldu',
    duration: 'Süre',
    close: 'kapat'
  },

  image: {
    modal_title: 'Resim Ekle',
    image: 'Resim',
    pixel: 'Piksel',
    dimension: 'Boyut',
    public_image_not_found: 'Resim bulunamadı.',
    private_image_not_found: 'Henüz herhangi bir Resim dosyası yüklemediniz'
  },

  annotation: {
    modal_title: 'Dipnot'
  },

  text: {
    modal_title: 'Metin Düzenleyici'
  },

  video: {
    modal_title: 'Video Ekle',
    image: 'Video',
    mp4_video: 'MP4 Video',
    public_video_not_found: 'Video bulunamadı',
    private_video_not_found: 'Henüz herhangi bir"video" dosyası yüklemediniz'
  },

  media: {
    title: 'Medya Kütüphanesi',
    subtitle: 'Dosyalarınızın kütüphanesi',
    modal_title: 'Medya yükle',
    delete_warning: 'Silmeyi onayla',
    delete_message: 'Silinen medya kullanıldığı sayfada yine de görünür olacak',
    dropzone_text: 'veya Sürükle ve Bırak',
    delete_success: 'Medya başarılı bir şekilde silindi',
    upload_file: 'Dosya yükle',
    drag_drop: 'veya Sürükle ve Bırak',
    import_file: 'Dosya(ları) bilgisayarınızdan içeri aktarın',
    no_media: 'Henüz herhangi bir medya dosyası yüklemediniz',
    media_type: 'Medya türü',
    video: 'Video',
    image: 'Resim',
    audio: 'Ses',
    gif: 'GIF',
    transcribed_text: 'Transcribed Text',
    media_not_found: 'Medya bulunamadı',
    of: 'ın',
    disclaimer: `Medyamın aşağıdakilere sebep olmayacağını garanti ederim:
    - herhangi bir üçüncü tarafın haklarını ihlal etmeyecek
  - herhangi bir yasadışı eylem barındırmayacak, gurur zedeleyici, müstehcen, uygunsuz, açık saçık, pornografik, şiddet içeren, küfürlü, aşağılayıcı, tehditkâr, ayrımcı içerikleri veya yıldız işareti ile gizlenmiş olsa dahi içermeyecek
  - diğer kişiler üzerine kişisel saldırıları barındırmayacak
  - diğer katılımcıların, halka mal olmuş kişilerin veya ünlülerin herhangi bir taklidini içermeyecek
  - kişinin rızası olmadan o kişiyle ilgili kayıtlar veya kişisel bilgiler barındırılmayacak ve dünyanın herhangi bir yerindeki verilerin korunması düzenlemeleri ihlal
  - herhangi bir ticari talebi veya “spam” şeklini barındırmayacak`
  },

  navigation: {
    account: 'Hesap',
    assessments: 'Değerlendirmeler',
    dashboard: 'Pano',
    groups: 'Grup Yönetimi',
    logout: 'Çıkış',
    organisations: 'Organizasyonlar',
    organizations_users: 'Organizasyonlar & Kullanıcılar',
    reports: 'Raporlar',
    schedule: 'Program',
    training: 'Alıştırma',
    users: 'Kullanıcılar',
    workshop: 'Atölyem',
    nowLive: 'canlı yayın'
  },

  onboarding: {
    step1_title: '1. Adım : Bize Biraz Kendinden Bahset',
    step1_subtitle: 'Adın nedir? Nerelisin? İş unvanınız nedir?',
    step2_title: '2. Adım : Geliştirebileceğin Birkaç Yetenek Seç',
    step2_subtitle:
      'Sonrasında bize neyi daha iyi anlamak istediğini söyle. Belki de henüz algılayamadığın bir şey? Yeni bir çalışma alanı? Sevdiğin kişi ne yapıyor?'
  },

  pagebuilder: {
    title: 'Ders Oluştur',
    subtitle:
      'Hedef kitlenizin sizden bir şeyler öğrenmesi için resimler, videolar veya infografikler gibi içerik oluşturma zamanı',
    select_layout: 'Sayfa Düzeni Seçin',
    template1: 'Boşluk',
    template2: 'Başlık & Altyazı',
    template3: 'Başlık & Metin',
    template4: 'Başlık , Medya & Metin',
    template5: 'Başlık & Medya',
    template6: 'Başlık & Quiz'
  },

  pageconstructor: {
    title: 'Dersi Oluştur ve Klonla',
    subtitle:
      'Hedef kitlenizin sizden bir şeyler öğrenmesi için resimler, videolar veya infografikler gibi içerik oluşturma zamanı'
  },

  passwordreset: {
    success_coded:
      'Verilen email adresine bir onaylama kodu gönderdik, lütfen aşağıdaki alandaki kodu yeni bir şifre koymak için kopyalayın.',
    success_changed:
      'Başarılı bir şekilde yeni şifre oluşturdunuz. Şimdi giriş yapabilirsiniz.'
  },

  placeholders: {
    search: 'Ara',
    answer: 'Cevapla',
    chat: 'Bir şey söyleyin...',
    code: 'Kod',
    criterion: 'Ölçütler',
    date_event: 'Tarih & saat seç',
    date_start: 'Başlangıç tarihi',
    date_expire: 'Son geçerlilik tarihi',
    description: 'Tanım',
    elements: 'Öğeleri sınıflandır',
    email: 'Email',
    feedback_correct: 'Geribildirim Doğru',
    feedback_incorrect: 'Geri bildirim Yanlış',
    host: 'Oturum Sahibi',
    keywords: 'Anahtar sözcük(ler) ara',
    lesson_description: 'Ders tanımı',
    location: 'Konum',
    minutes: 'Dakika',
    name: 'İsim',
    organiser: 'Yazar',
    organization: 'organizasyon',
    password: 'Şifre',
    phone: 'Telefon',
    photo: 'Fotoğraf',
    picture: 'Resim',
    point: 'Puan',
    position: 'Pozisyon',
    question: 'Soru',
    quiz: 'Quiz İsmi',
    search_course: 'Kurslarda Ara',
    search_media: 'Medya ara',
    search_name: 'Ada göre Ara',
    search_title: 'Başlığa göre Ara',
    search_training: 'Alıştırma Ara',
    settings: 'Ayarlar',
    tags: 'Etiketler',
    title: 'Başlık',
    topics: 'Konular',
    username: 'Kullanıcı adı',
    keyword: 'Anahtar kelimeler',
    max_500_characters: 'Maksimum 500 Karakter',
    no_description: 'Bu dosyanın tanımı bulunmamakta',
    no_keyword: 'Bu dosyanın anahtar kelimeleri bulunmamakta',
    no_topics: 'Bu dosyanın belli bir konusu bulunmamakta',
    feedback: 'Mesaj',
    contact: 'Nasıl yardımcı olabiliriz?'
  },

  publishsettings: {
    title: 'Yayınlama Ayarları',
    event_subtitle:
      'Ayarları etkinliğinize ekleyerek etkinliğinizi görüntüleyebilir veya etkileşime geçebilirsiniz',
    assessment_subtitile:
      'Değerlendirmenize isim verip hedef kitlenize ne öğrenmelerini beklediğinizi',
    course_subtitle:
      'Ayarları etkinliğinize ekleyerek etkinliğinizi görüntüleyebilir veya etkileşime geçebilirsiniz',
    disclaimer: `1.	Kayıt

  Bir kullanıcı olarak kayıt yaptırarak / bir hesap oluşturarak [Kullanıcı Sözleşmesi]’ni onaylıyor ve [Gizlilik İlkesi]’ni okuduğunuzu kabul ediyorsunuz



  2.	Kurs oluşturma

  Araçlarımızı kullanarak ve kurs içeriği oluşturarak [Kullanıcı Sözleşmesi]’ni onaylıyor ve [Gizlilik İlkesi]’ni okuduğunuzu kabul ediyorsunuz



  3.	Etkinlik oluşturma

  Araçlarımızı kullanarak ve etkinlik oluşturarak [Kullanıcı Sözleşmesi]’ni onaylıyor ve [Gizlilik İlkesi]’ni okuduğunuzu kabul ediyorsunuz


  4.	Değerlendirme oluşturma

  Araçlarımızı kullanarak ve değerlendirme oluşturarak [Kullanıcı Sözleşmesi]’ni onaylıyor ve [Gizlilik İlkesi]’ni okuduğunuzu kabul ediyorsunuz`,
    dates_validation_error: 'Her iki tarih de doldurulmalı.',
    emails_validation_error: 'Email listesi geçersiz.',
    specific_email_validation_error: 'Sadece bir emaile izin veriliyor.',
    i_agree_to_these: 'Bunlara katılıyorum ',
    terms_and_conditions: 'hüküm ve koşullar.',
    PublicDescription:
      'Ücretsiz bağımsız kullanıcılar: (Gizli ve halka açık seçenekler)Size ait [Kurs|Etkinlik|Değerlendirme] kaydedildi ve varsayılan durumu Gizli olarak ayarlandı. Bu da bu içeriği sadece siz görebilirsiniz demektir. Bu içeriği yayınlamak için halka açık hale getir seçeneğini seçebilirsiniz. Böylelikle kurs platformdaki herkese erişilebilir hale gelecek.',
    OrganisationsDescription:
      'Org kullanıcıları: (""Bitir"" yerine ""Yayınla""yı kullanın ve onları diğer kullanıcılar gibi fakat herhangi bir yayınlama seçeneği olmadan yayınlama sayfasına getirin.) Size ait [Kurs|Etkinlik|Değerlendirme] kaydedildi ve Gizli durumda. Böylelikle sadece siz ve organizasyonlarınızdaki yöneticiler bunu görebilir. Bir kullanıcı olarak, kendi organizasyonunuz içerisinde bunu kendi kendinize yayınlayamazsınız çünkü bu yönetici haklarını gerektirir. Lütfen Yöneticilerinizden biriyle irtibat kurun ve içeriğinizi herkese açık şekilde tüm organizasyonlara, organizasyonunuz içerisindeki özel gruplara veya özel kullanıcılara yayınlamasını isteyiniz.',
    OrganisationGroupsDescription:
      'Org Yöneticiler: (Bu organizasyon içerisinde yapılan içerikler için: Gizli, halka açık, organizasyon, gruplar, özel kullanıcılar):Bu [Kurs|Etkinlik|Değerlendirme] kaydedildi ve varsayılan olarak Gizli olarak ayarlandı. Bu da sadece sizin (ve içerik üreticisinin) bu içeriği görebileceği anlamına gelir. Bu içeriği yayınlamak için aşağıdaki seçeneklerden birini seçebilirsiniz. Kursunuzu Halka Açık şekilde yayınlayarak organizasyonunuza dışarıdan dahil olanlar da dahil olmak üzere platformdaki tüm kullanıcıların erişimine açık hale getiriyorsunuz. Organizasyona yönelik yayınlamak demek organizasyon içindeki tüm kullanıcılar bunu görebilecek demektir. 1 veya daha fazla grup için de yayınlayabilirsiniz ki bu da sadece o gruplar içerisindekilerin görebileceği anlamına gelir.  Son olarak, Sharelook hesaplarını ve email adreslerini kullanarak organizasyonunuzun içinden ve dışından özel kullanıcılara da yayın yapabilme seçeneğine sahipsiniz.',
    SpecificUsersDescription:
      'Ücretli Bağımsız Kullanıcılar: (gizli, halka açık özel kullanıcılar)Size ait [Kurs|Etkinlik|Değerlendirme] kaydedildi ve varsayılan durumu Gizli olarak ayarlandı. Bu da bu içeriği sadece siz görebilirsiniz demektir. Bu içeriği yayınlamak için aşağıdaki seçeneklerden birini seçebilirsiniz. Kursunuzu Halka Açık şekilde yayınlayarak platformda bulunan tüm kullanıcılara mevcut hale getiriyorsunuz. Özel Kullanıcı seçeneği kurslarınızı diğer Özel Kullanıcılarla Sharelook hesaplarıyla ilişkilendirilmiş email adreslerini kullanarak paylaşabilmenizi sağlayacak.'
  },

  quiz: {
    quiz_empty: 'Henüz bir quiz oluşturmadınız',
    questions_empty: 'Henüz bir soru oluşturmadınız'
  },

  regions: {
    asia_pacific_australia: 'Avustralya',
    asia_pacific_india: 'Hindistan',
    asia_pacific_japan: 'Japonya',
    asia_pacific_s_korea: 'Güney Kore',
    asia_pacific_singapore: 'Singapur',
    asia_pacific_taiwan: 'Tayvan',
    eu_belgium: 'Belçika',
    eu_germany: 'Almanya',
    eu_ireland: 'İrlanda',
    south_america_brazil: 'Brezilya',
    us_central_iowa: 'Iowa',
    us_east_s_carolina: 'Güney Carolina',
    us_east_virginia: 'Virginia',
    us_west_california: 'California',
    us_west_oregon: 'Oregon'
  },

  reports: {
    title: 'Raporlar'
  },

  signup: {
    success_step1:
      'Verilen email adresine bir Doğrulama kodu gönderdik, lütfen aşağıdaki alandaki kodu kaydınızı onaylamak için kopyalayın.',
    success_step2: 'Teşekkürler. Başarılı bir şekilde kaydoldunuz.',
    privacy: `Sharelook'un <1> hüküm & koşullarını </1> ve <3> gizlilik ilkelerini </3>kabul ediyorum`
  },

  stream: {
    emotion_impressive: 'Etkileyici',
    emotion_unimpressive: 'Etkileyici değil',
    message_not_started: 'Çok Yakında',
    message_running: 'Canlı',
    message_processing:
      'Şu anda canlı kaydı banttan çalmak için işlem yapıyoruz. Videoyu görüntülemek ve derecelendirme ve geri bildirim eklemeye devam etmek için daha sonra tekrar kontrol edin'
  },

  tabs: {
    audience: 'Hedef Kitle',
    course: 'Kurs Genel Bakış',
    details: 'Canlı Etkinlik Detayları',
    description: 'Tanım',
    host: 'Oturum Sahibi',
    lesson: 'Ders Genel Bakış',
    lessons: 'Dersler',
    overview: 'Genel Bakış',
    page: 'Sayfa Oluşturucu',
    publish: 'Yayınla',
    settings: 'Yayınlama Ayarları',
    total_views: 'Toplam Görüntülemeler',
    live_participates: 'Canlı Katılımcılar',
    live_chats: 'Canlı Sohbetler',
    live_quiz: 'Canlı Anketler',
    trainer_rating: 'Alıştırma Derecesi',
    trainer_times: 'Alıştırma Zamanları',
    courses_quizzes: 'Kurs Quizleri',
    user_list: 'Kullanıcı Listesi',
    discussion: 'Tartışma',
    notes: 'Notlarım'
  },

  topics: {
    SALES_TRAINING: 'Satış Eğitimi',
    NEGOTIATION_TRAINING: 'Pazarlık Eğitimi',
    CROSS_CULTURAL_AWARENESS: 'Kültürler Arası Farkındalık',
    NETWORKING_SKILLS: 'Örgülenim Yetenekleri',
    SOFTWARE_ARCHITECTURE: 'Yazılım Mimarisi',
    PROJECT_MANAGEMENT: 'Proje Yönetimi',
    DIGITAL_TRANSFORMATION: 'Dijital Dönüşüm',
    IT_CONSULTING: 'IT Danışmanlığı',
    MANAGEMENT_CONSULTING: 'Yönetim Danışmanlığı',
    HR_CONSULTING: 'HR Danışmanlığı',
    INTERNET_OF_THINGS: 'Nesnelerin İnterneti (IoT)',
    CYBERSECURITY: 'Siber güvenlik',
    PRIVACY_AND_GDRP: 'Gizlilik ve GVKP(Genel Veri Koruma Tüzüğü)'
  },

  training: {
    title: 'Alıştırma',
    title_assessment: 'Değerlendirmeler',
    subtitle: 'Kurslar ve Canlı Etkinlikler',
    enrolled_title: 'Kaydolunmuş Kurslar ve Canlı Etkinlikler',
    enrolled_subtitle: 'Kurslar ve Kaydolduğunuz Canlı Etkinlikler',
    enrolled_message:
      'Şu anda herhangi bir kurs ve etkinliğe kayıtlı değilsiniz.',
    past_live_event_title: 'Geçmiş Canlı Etkinlikler',
    past_live_event_subTitle: 'Bitmiş Canlı Etkinlikler',
    past_live_event: 'Şu anda hiç geçmiş canlı bir etkinlik bulunmamakta.',
    recomended_title: 'Tavsiye Edilen Kurslar ve Canlı Etkinlikler',
    now_live_event_title: 'Şimdi Canlı',
    now_live_event_subTitle: 'Canlı veya yakında canlı olacak etkinlikler',
    now_live_event: 'Şu anda canlı bir etkinlik bulunmamakta.',
    webinar_event_title: 'Web Seminer Kanalı',
    webinar_event_subTitle: 'Bitmiş Web Seminerleri',
    webinar_event: 'Şu anda web semineri bulunmamakta.',
    p2p_event_title: 'Koçluk Oturumları',
    p2p_event_subTitle: 'Bitmiş Koçluk Oturumu',
    p2p_event: 'Şu anda hiç koçluk oturumu bulunmamakta.',

    now_live_assessment_title: 'Şimdi Canlı',
    now_live_assessment_subTitle:
      'Canlı veya yakında canlı olacak değerlendirmeler',
    now_live_assessment_message:
      'Şu anda hiç canlı bir değerlendirme bulunmamakta.',

    upcoming_assessment_title: 'Yaklaşan Canlı Değerlendirme',
    upcoming_assessment_subtitle: 'Yaklaşan Canlı Değerlendirme',
    upcoming_assessment_message:
      'Şu anda gelecek canlı bir değerlendirme bulunmamakta.',

    past_live_assessment_title: 'Geçmiş Canlı Değerlendirmeler',
    past_live_assessment_subTitle: 'Bitmiş Canlı Değerlendirmeler',
    past_live_assessment_message:
      'Şu anda hiç geçmiş canlı değerlendirmeler bulunmamakta.',

    /**
     * <0/> get index 0 of data from Trans component
     * exp -->
     * <Trans i18nKey='training:recomended_subtitle' >
     *    <Navlink>{...something}</Navlink> --> is index 0 of Trans component
     *    <h1>{...something}</h1> --> is index 1 of Trans component
     * </Trans>
     */
    recomended_subtitle:
      '<0><0/></0>’ıza bağlı olarak kurslar ve canlı etkinlikler düzenledik',
    /**
     * and we also can get data from index to replace in recommended_subtitle by like above string.
     * for exp -->
     * at the end of the recomended_subtitle above will get data from index of component to display
     * <0><0/></0> mean get data index 0 of Trans component above = <Navlink> and <0/> get data index 0 from <Navlink> = {...something}
     */
    search: 'Alıştırma Ara',
    search_assessment: 'Değerlendirme Ara',
    recomended_message:
      'Şu anda tavsiye edilen bir kurs veya etkinlik bulunmamakta.'
  },

  warnings: {
    delete: 'Emin misiniz?'
  },

  workshop: {
    title: 'Atölyem',
    subtitle: 'Bir kurs oluşturun veya kendi canlı etkinliğinize sahiplik edin',
    new_course: 'Yeni bir kurs oluştur',
    new_event: 'Yeni bir canlı etkinlik oluştur',
    new_assessment: 'Yeni bir canlı değerlendirme oluştur',
    courses_title: 'Oluşturulan Kurslar& Canlı Etkinlikler',
    courses_subtitle:
      'Oluşturulan kursunuzu Düzenle & Yayınla ve canlı etkinlikleri planla ',
    tags_course: 'Kurs',
    tags_live: 'Canlı etkinlik',
    tags_assessment: 'Canlı Değerlendirme'
  },

  help_center: {
    sidebar: {
      title: 'Size nasıl yardımcı olabiliriz?',
      back_to: 'Pano'
    },
    menu: {
      help_center: 'Yardım Merkezi',
      whats_new: 'Yenilikler',
      feedback: 'Geri bildirim',
      feedback_title: 'Sharelook’a geri bildirim gönder',
      terms_and_conditions: 'Hüküm & Koşullar',
      privacy_policy: 'Gizlilik İlkesi',
      contact_us: 'Bizimle irtibat kurun'
    },
    get_started: {
      title: 'Sharelook ile harekete geçin',
      description: {
        main:
          'CANLI VİDEOYU KULLANIMI KOLAY KURS OLUŞTURUCULARINA ENTEGRE ETTİK',
        tagline:
          'ShareLook’un patentli canlı yayın sistemi ile etkileşim yaratabilir, öğrenmeyi otomatikleştirebilir ve bilgi paylaşımını en üst düzeye çıkarabilirsiniz.'
      }
    },
    whats_new: {
      title: 'Sharelook ile harekete geçin',
      description: {
        main:
          'Hikaye anlatıcılığı tarzı öğrenmenin oluşturulmasının kısa sürede yaratılabileceğini ve çok pahalı olmadığını biliyor muydunuz?  ShareLook kurs oluşturucusunu ve ilgili yetenekleri birleştirmelisiniz. "NASIL olduğunu sorabilirsiniz',
        tagline: ''
      }
    },
    create_workshop: 'Nasıl Atölye oluşturulur',
    create_course: 'Nasıl Kurs oluşturulur',
    create_user: 'Nasıl Kullanıcı oluşturulur',
    create_group: 'Nasıl Grup oluşturulur',
    create_webinar: 'Nasıl Web Semineri oluşturulur',
    create_live_event: 'Nasıl Canlı Etkinlik oluşturulur',
    create_live_assessment: 'Nasıl Canlı Değerlendirme oluşturulur',
    create_live_on_mobile: 'Mobilde Canlı Bağlantı Oluştur',
    personalize_profile: 'Profili kişiselleştir',
    manage_group: 'Grubu Yönet',
    report: 'Rapor',
    add_annotations: 'Dipnot/Açılır Pencere Nasıl Eklenir',
    add_audio: 'Ses Nasıl Eklenir',
    add_header_and_footer: 'Başlık ve Alt Bilgi Ekleme',
    add_image: 'Resim Nasıl Eklenir',
    add_quiz_content: 'Quiz İçeriği Nasıl Eklenir',
    add_text_content: 'Metin İçeriği Nasıl Eklenir',
    add_user: 'Kullanıcı Nasıl Eklenir',
    add_video: 'Video Nasıl Eklenir',
    clone_course: 'Kurs Nasıl Çoğaltılır',
    find_live_broadcast: 'Canlı Yayın Nasıl Bulunur',
    contact_us: {
      title: 'Bizimle irtibat kurun',
      subject_placeholder: 'Bir konu girin*',
      content_placeholder: 'Bir mesaj girin*',
      subject_required: '*Konu gerekli',
      content_required: '*Mesaj gerekli',
      submit: 'Gönder',
      cancel: 'İptal Et',
      sent: 'Gönderildi'
    },
    feedback: {
      title: 'Sharelook’a geri bildirim gönder',
      attach_title: 'Bir dosya ekle',
      choose: 'Dosya seç',
      detail: 'Dosya destek ekibiyle paylaşılacak',
      content_placeholder: 'Bir mesaj girin*',
      content_required: '*Mesaj gerekli',
      submit: 'Gönder',
      cancel: 'İptal Et',
      sent: 'Gönderildi'
    }
  },
  discussion: {
    join_discussion: 'Tartışmaya katılın'
  },
  notes: {
    title: 'Notlarım',
    add_note: 'Yeni not ekle'
  }
}
