import * as types from '../types'
import { api } from '../../services'

export const fetchRequestAssessments = payload => ({
  type: types.FETCH_REQUEST_ASSESSMENTS,
  payload
})

export const fetchAssessments = (list, params) => ({
  type: types.FETCH_ASSESSMENTS,
  payload: {
    list
  },
  params
})

export const loadAssessments = (list, hasMoreItems, isFetching) => ({
  type: types.LOAD_ASSESSMENTS,
  payload: {
    list,
    hasMoreItems,
    isFetching
  }
})

export const fetchAssessmentById = id => ({
  type: types.FETCH_ASSESSMENT_BY_ID,
  payload: {
    id
  }
})

export const loadAssessmentById = payload => ({
  type: types.LOAD_ASSESSMENT_BY_ID,
  payload
})

export const createAssessment = payload => ({
  type: types.CREATE_ASSESSMENT,
  payload
})

export const updateAssessments = payload => ({
  type: types.UPDATE_ASSESSMENTS,
  payload
})

export const updateAssessment = (data, id) => ({
  type: types.UPDATE_ASSESSMENT,
  payload: {
    data,
    id
  }
})

export const loadAssessment = payload => ({
  type: types.LOAD_ASSESSMENT,
  payload
})

export const resetAssessment = () => ({
  type: types.RESET_ASSESSMENT
})

export const removeAssessmentById = id => ({
  type: types.REMOVE_ASSESSMENT_BY_ID,
  payload: {
    id
  }
})
export const getWorkshopAssessments = params => dispatch => {
  dispatch({
    type: types.WORKSHOP_ASSESSMENTS_FETCHING,
    payload: true
  })

  return api.assessments
    .getAll({ mode: 'workshop', ...params })
    .then(({ data }) => {
      if (data) {
        dispatch({
          type: types.WORKSHOP_ASSESSMENTS_SUCCESS,
          payload: data
        })

        dispatch({
          type: types.WORKSHOP_ASSESSMENTS_FETCHING,
          payload: false
        })

        return data
      }
    })
}

export const getPastLiveAssessment = params => (dispatch, getState) => {
  const {
    assessments: {
      pastAssessment: { fetch: pastAssessmentsFetch }
    }
  } = getState()

  if (pastAssessmentsFetch) {
    return
  }

  const d = (type, payload) =>
    dispatch({
      type,
      payload
    })

  const assessmentsParams = {
    ...params,
    mode: 'past'
  }

  d(types.PAST_ASSESSMENTS_FETCHING, true)

  return Promise.all([api.assessments.getAll(assessmentsParams)]).then(
    ([
       {
         data: { rows: assessmentsData }
       }
     ]) => {
      if (!assessmentsData) {
        return null
      }

      d(types.PAST_ASSESSMENTS_FETCHING, false)

      // d(WORKSHOP_COURSES_HAS_MORE, !!coursesData.length)
      // d(WORKSHOP_EVENTS_HAS_MORE, !!eventsData.length)

      const pastAssessment =
        assessmentsData.length > 0
          ? assessmentsData.map(i => {
            i.type = 'Live Assessment'
            return i
          })
          : []

      d(types.PAST_ASSESSMENTS_SUCCESS, pastAssessment)
    }
  )
}
export const getUpcomingLiveAssessment = params => (dispatch, getState) => {
  const {
    assessments: {
      upcomingAssessment: { fetch: upcomingAssessmentsFetch }
    }
  } = getState()

  if (upcomingAssessmentsFetch) {
    return
  }

  const d = (type, payload) =>
    dispatch({
      type,
      payload
    })

  const assessmentsParams = {
    ...params,
    mode: 'upcoming'
  }

  d(types.UPCOMING_ASSESSMENTS_FETCHING, true)

  return Promise.all([api.assessments.getAll(assessmentsParams)]).then(
    ([
       {
         data: { rows: assessmentsData }
       }
     ]) => {
      if (!assessmentsData) {
        return null
      }

      d(types.UPCOMING_ASSESSMENTS_FETCHING, false)

      // d(WORKSHOP_COURSES_HAS_MORE, !!coursesData.length)
      // d(WORKSHOP_EVENTS_HAS_MORE, !!assessmentsData.length)

      const upcomingAssessment =
        assessmentsData.length > 0
          ? assessmentsData.map(i => {
            i.type = 'Live Assessment'
            return i
          })
          : []

      d(types.UPCOMING_ASSESSMENTS_SUCCESS, upcomingAssessment)
    }
  )
}
export const getNowLiveAssessment = params => (dispatch, getState) => {
  const {
    assessments: {
      nowLiveAssessment: { fetch: nowLiveAssessmentFetch }
    }
  } = getState()

  if (nowLiveAssessmentFetch) {
    return
  }

  const d = (type, payload) =>
    dispatch({
      type,
      payload
    })

  const assessmentsParams = {
    ...params,
    mode: 'live'
  }

  d(types.NOW_LIVE_ASSESSMENTS_FETCHING, true)

  return Promise.all([api.assessments.getAll(assessmentsParams)]).then(
    ([
       {
         data: { rows: assessmentsData }
       }
     ]) => {
      if (!assessmentsData) {
        return null
      }

      d(types.NOW_LIVE_ASSESSMENTS_FETCHING, false)

      // d(WORKSHOP_COURSES_HAS_MORE, !!coursesData.length)
      // d(WORKSHOP_EVENTS_HAS_MORE, !!assessmentsData.length)

      const nowLiveAssessment =
        assessmentsData.length > 0
          ? assessmentsData.map(i => {
            i.type = 'Live Assessment'
            return i
          })
          : []

      d(types.NOW_LIVE_ASSESSMENTS_SUCCESS, nowLiveAssessment)
    }
  )
}
export const viewAssessment = assessmentId => dispatch => {
  return api.assessments.viewAssessments(assessmentId).then(({ data }) => {
    if (data) {
      dispatch({
        type: types.VIEW_EVENT_SUCCESS
      })
    }
  })
}
