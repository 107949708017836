import React, { Component } from 'react'
import { Checkbox } from 'antd'
import { Field, Row, Col } from '../../../../../../../styles'
import { Organization } from './styled'
import { withTranslation } from 'react-i18next'

class OrganizationPicker extends Component {
  handleChange = (id, e) => {
    const { onChange } = this.props

    if (onChange) {
      onChange(id)
    }
  }

  render () {
    const { list, selectedOrganizations, t } = this.props

    return (
      <Field>
        <Field.Label>{t('labels:select_organization')}</Field.Label>
        <Field.Control>
          <Row style={{ marginBottom: '-3.2rem' }}>
            {list.map(organization => {
              const { id, title, logo } = organization
              return <Col key={id} md={3}>
                <Organization
                  active={selectedOrganizations.includes(id)}
                  onClick={e => this.handleChange(id, e)}
                >
                  <Organization.Checkbox>
                    <Checkbox checked={selectedOrganizations.includes(id)}/>
                  </Organization.Checkbox>
                  <Organization.Image src={logo ? logo.link : ''} alt=''/>
                  <Organization.Name>{title}</Organization.Name>
                </Organization>
              </Col>
            })}
          </Row>
        </Field.Control>
      </Field>
    )
  }
}

export default withTranslation()(OrganizationPicker)
