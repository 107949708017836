const autoCompleteModal = {
  borderRadius: '3px solid red',
  boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
  backgroundColor: 'white',
  // padding: '10px 0px',
  fontSize: '90%',
  position: 'absolute',
  top: 30,
  left: 0,
  overflow: 'auto',
  zIndex: 100
}
const buttonRow = {
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center'
}

const HighLight = {
  backgroundColor: '#0068FF',
  color: 'white',
  zIndex: 100,
  padding: '5px 10px'
}
const NormalText = {
  backgroundColor: 'white',
  color: '#333333',
  zIndex: 100,
  padding: '5px 10px'
}
const InputStyle = {
  width: '200px',
  outlineWidth: 0
}
const InputWrapper = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center'
}
const AutoWrappper = {
  display: 'inline-block',
  width: '300px'
}

const ButtonStyle = {
  marginLeft: '1rem'
}
const Message = {
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  height: '65vh'
}
const ErrorMessage = {
  display: 'flex',
  color: '#FF4B55'
}
const ErrorMessageConatainer = {
  paddingTop: '2rem',
  paddingLeft: '1rem'
}

export default {
  autoCompleteModal,
  HighLight,
  NormalText,
  InputStyle,
  InputWrapper,
  AutoWrappper,
  ButtonStyle,
  Message,
  buttonRow,
  ErrorMessage,
  ErrorMessageConatainer
}
