import React from 'react'
import { Row, Col, Form, Input } from 'antd'
import '../../../../Courses/Create/nestedComponents/CourseCreateMain/components/index.css'
import _ from 'lodash'

const MarketPlaceDetailSection = props => {
  const {
    form: { getFieldDecorator },
    current: {
      description,
      marketplace: {
        course_description = '',
        course_obj = '',
        course_outline = ''
      }
    },
    onDescription,
    onObjectives,
    onOutline,
    onMarketPlaceDescription,
    isEventMarketPlace,
    marketplaceOption,
    t,
    App: { handleForm }
  } = props

  const handleDesc = (val, field) => {
    switch (field) {
      case 'description':
        onDescription(val)
        break
      case 'course_obj':
        onObjectives(val)
        break
      case 'course_outline':
        onOutline(val)
        break
      case 'course_description':
        onMarketPlaceDescription(val)
        break
      default:
        break
    }
    handleForm()
  }

  const handleDescription = _.debounce((v, f) => handleDesc(v, f), 1000)

  const generateEditor = (title, message, value) => {
    const fieldValue = message.split('event').join('course')
    return (
      <div className="margin_top ">
        <Form.Item label={title} colon={false} required={false}>
          {getFieldDecorator(fieldValue, {
            rules: [
              {
                required: true,
                message: t(`v3:${message}`)
              }
            ],
            initialValue: value
          })(
            <Input.TextArea
              placeholder={t('v4:text')}
              autoSize={{ minRows: 5, maxRows: 20 }}
              onKeyDown={e => {
                if (
                  e.keyCode === 32 &&
                  (e.target.value.charAt(e.target.selectionStart - 1) === ' ' ||
                    e.target.value.charAt(e.target.selectionStart) === ' ')
                ) {
                  e.preventDefault()
                }
              }}
              onChange={e => handleDescription(e.target.value, value)}
              style={{ fontSize: '1.6rem', resize: 'none' }}
              wrap={'soft'}
            />
          )}
        </Form.Item>
      </div>
    )
  }

  console.log('check option', marketplaceOption)
  return (
    <Row className="quill-div_row">
      <Col span={24}>
        {isEventMarketPlace && (
          <>
            {generateEditor('Event Objectives', 'event_obj', course_obj)}
            {generateEditor('Event Outline', 'event_outline', course_outline)}
            {generateEditor(
              'Event Description',
              'event_description',
              course_description
            )}
          </>
        )}
        {!isEventMarketPlace &&
          generateEditor('Description', 'description', description)}
      </Col>
    </Row>
  )
}

export { MarketPlaceDetailSection }
