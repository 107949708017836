/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-06-19 10:54:34
 * @LastEditTime: 2019-08-27 14:02:40
 * @LastEditors: Please set LastEditors
 */
import { all, takeLatest, takeEvery } from 'redux-saga/effects'

import * as courseBuilder from './courseBuilder'
import * as lessonBuilder from './lessonBuilder'
import * as courses from './courses'
import * as quizes from './quizes'
import * as assessments from './assessments'
import * as mazeBuilder from './mazeBuilder'
import * as reviewsBuilder from './reviews'

import * as types from '../types'

export default function* sagas() {
  yield all([
    takeLatest(types.FETCH_COURSES, courses.fetchCourses),
    takeLatest(types.CREATE_COURSE, courseBuilder.createCourse),
    takeLatest(types.UPDATE_COURSE, courseBuilder.updateCourse),
    takeLatest(types.REMOVE_COURSE_BY_ID, courses.removeCourseById),

    takeLatest(types.CREATE_LESSON, lessonBuilder.createLesson),
    takeLatest(types.FETCH_LESSONS, lessonBuilder.fetchLessons),
    takeLatest(types.GET_LESSONS_IN_COURSE, courseBuilder.getLessonsList),
    takeEvery(types.FETCH_LESSON_BY_ID, lessonBuilder.fetchLessonById),
    takeLatest(types.UPDATE_LESSON, lessonBuilder.updateLesson),
    takeLatest(
      types.DELETE_LESSON_FROM_COURSE,
      courseBuilder.deleteLessonFromCourse
    ),

    takeLatest(types.FETCH_QUIZES, quizes.fetchQuizes),
    takeLatest(types.FETCH_QUIZ_BY_ID, quizes.fetchQuizById),
    takeLatest(types.FETCH_QUIZ_BY_ID_ANSWERS, quizes.fetchQuizByIdAnswers),
    takeLatest(types.VALIDATE_QUIZ, quizes.validateQuiz),
    takeLatest(types.CREATE_QUIZ, quizes.createQuiz),
    takeLatest(types.UPDATE_QUIZ, quizes.updateQuiz),
    takeLatest(types.UPDATE_QUESTIONS, quizes.updateQuestions),
    takeLatest(types.REMOVE_QUIZ_BY_ID, quizes.removeQuizById),

    takeLatest(types.FETCH_ASSESSMENTS, assessments.fetchAssessments),
    takeLatest(types.FETCH_ASSESSMENT_BY_ID, assessments.fetchAssessmentById),
    takeLatest(types.CREATE_ASSESSMENT, assessments.createAssessment),
    takeLatest(types.UPDATE_ASSESSMENT, assessments.updateAssessment),
    takeLatest(types.REMOVE_ASSESSMENT_BY_ID, assessments.removeAssessmentById),

    takeLatest(types.CREATE_MAZE, mazeBuilder.createMaze),

    takeLatest(types.GET_ALL_REVIEWS_REQUEST, reviewsBuilder.fetchReviews),
    takeLatest(types.ADD_REVIEW_REQUEST, reviewsBuilder.addReviews),
    takeLatest(types.EDIT_REVIEW_REQUEST, reviewsBuilder.updateReviews)
  ])
}
