import React, { Component, Fragment } from 'react'
import { Form, Input, Button } from 'antd'
import { withTranslation } from 'react-i18next'

class GetCodeForm extends Component {
  handleSubmit = e => {
    e.preventDefault()

    const { getPasswordResetCode } = this.props

    this.props.form.validateFields((err, values) => {
      if (!err) {
        Object.keys(values).forEach(key => {
          values[key] = values[key].trim()
          if (key === 'username') {
            values[key] = values[key].toLowerCase()
          }
        })

        getPasswordResetCode(values.username)
      }
    })
  }

  render() {
    const {
      form: { getFieldDecorator },
      t,
      loading
    } = this.props

    return (
      <Fragment>
        <Form onSubmit={this.handleSubmit}>
          <Form.Item>
            {getFieldDecorator('username', {
              rules: [
                {
                  required: true,
                  message: t('errors:username') + ' / ' + t('labels:email'),
                  max: 320
                }
              ]
            })(
              <Input
                placeholder={t('labels:username') + ' / ' + t('labels:email')}
                name="username"
                maxLength={320}
              />
            )}
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" block loading={loading}>
              {t('buttons:reset')}
            </Button>
          </Form.Item>
        </Form>
      </Fragment>
    )
  }
}

export default Form.create()(withTranslation('passwordreset')(GetCodeForm))
