import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import LessonOverView from './LessonOverView'

import * as lessonBuilderActions from '../../../../../store/actions/lessonBuilder'

export default connect(
  null,
  (dispatch) => ({
    lessonBuilderActions: bindActionCreators(lessonBuilderActions, dispatch)
  })
)(LessonOverView)
