import React, { Component } from 'react'
import { Row, Col } from 'antd'
import ReactEcharts from 'echarts-for-react'
import moment from 'moment/moment'
import { Spinner } from '../../../../../../../../components/index'
import '../../../../style.css'
import { report } from '../../../../../../../../constants'

class QuizByOverall extends Component {
  questionByOverall(question) {
    switch (question.type) {
      case 'matrix':
        let total_submit = question.user_answer.matrix.reduce((acc, item) => {
          return (acc += item.users.length)
        }, 0)
        return (
          <Row>
            <Col sm={24} lg={24}>
              <table className="quiz-table">
                <thead>
                  <tr>
                    <td></td>
                    {question.quiz_answer.map((x, index) => {
                      return (
                        <td key={index}>
                          <img
                            key={index}
                            style={{ height: 70, width: 110, margin: 10 }}
                            alt={x.elements}
                            src={x.content}
                          />
                        </td>
                      )
                    })}
                  </tr>
                </thead>
                <tbody>
                  {question.user_answer.matrix.map((x, index) => {
                    return (
                      <tr>
                        <td key={index}>
                          {' '}
                          {((x.users.length * 100) / total_submit).toFixed(2)} %
                          ({x.users.length}/{total_submit})
                        </td>
                        {x.element.map(i => {
                          return <td>{i}</td>
                        })}
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </Col>
          </Row>
        )

      case 'multiple':
        const number = question.quiz_answer.reduce((acc, item) => {
          question.user_answer.multiple.forEach(x => {
            x.element.forEach(y => {
              if (item.answer === y) {
                if (acc[item.answer]) {
                  acc[item.answer] += x.users.length
                } else acc[item.answer] = x.users.length
              }
            })
          })
          return acc
        }, [])

        const x = question.quiz_answer.forEach(x => {
          return x.answer
        })
        const y = x.forEach(answer => {
          return number[answer] || 0
        })
        return (
          <Row>
            <Col sm={24} lg={12}>
              <ReactEcharts
                option={{
                  animationDelay: 1000,
                  color: ['#7FC4FD'],
                  grid: {
                    show: false
                  },
                  tooltip: {},
                  series: [
                    {
                      type: 'bar',
                      barWidth: '60%',
                      data: y
                    }
                  ],
                  xAxis: {
                    type: 'category',
                    data: x,
                    axisTick: {
                      alignWithLabel: true
                    }
                  },
                  yAxis: {
                    type: 'value',
                    splitNumber: y.length
                  }
                }}
              />
            </Col>
          </Row>
        )
      case 'sorting':
        total_submit = question.user_answer.sorting.reduce((acc, item) => {
          return (acc += item.users.length)
        }, 0)
        return (
          <Row>
            <Col sm={24} lg={16}>
              <table className="quiz-table">
                <tbody>
                  {question.user_answer.sorting.map(x => {
                    return (
                      <tr>
                        <td>
                          {' '}
                          {((x.users.length * 100) / total_submit).toFixed(2)} %
                          ({x.users.length}/{total_submit})
                        </td>
                        {x.element.map(i => {
                          return <td>{i}</td>
                        })}
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </Col>
          </Row>
        )
      case 'free':
        return (
          <Row>
            <Col sm={24} lg={16}>
              <ul>
                {question.user_answer.free.map(x => {
                  if (x.answer === '') {
                    return (
                      <li
                        style={{
                          textDecoration: 'line-through',
                          background: 'rgba(235,235,235,0.9)'
                        }}
                      >
                        {report.ANSWER_NOT_AVAILABLE}
                      </li>
                    )
                  } else {
                    return (
                      <li
                        key={x.user_id}
                        className={x.correct ? 'text-blue' : 'text-red'}
                      >
                        {x.answer}
                      </li>
                    )
                  }
                })}
              </ul>
            </Col>
          </Row>
        )
      case 'single':
        return (
          <Row>
            <Col sm={24} lg={16}>
              <ReactEcharts
                option={{
                  color: ['#2699FB'],
                  legend: {
                    x: 'right',
                    orient: 'vertical',
                    align: 'left',
                    icon: 'circle'
                  },
                  tooltip: {},
                  series: [
                    {
                      data:
                        question.quiz_answer &&
                        question.quiz_answer.reduce((acc, itm) => {
                          let mapped =
                            question.user_answer.single &&
                            question.user_answer.single.map(ele => ele.answer)
                          let found = mapped && mapped.includes(itm.answer)
                          // console.log(found, 'map ma', itm)
                          if (found) {
                            if (itm.correct) {
                              acc.push({
                                name: 'Correct',
                                value: 1,
                                itemStyle: {
                                  color: '#2699FB'
                                }
                              })
                            } else {
                              acc.push({
                                name: 'Incorrect',
                                value: 1,
                                itemStyle: { color: '#BDCADB' }
                              })
                            }
                          }

                          const group = arr => {
                            const reduced = arr.reduce((a, curr) => {
                              const name = curr.name
                              a[name] = a[name] || 0
                              a[name]++
                              return a
                            }, {})

                            return Object.getOwnPropertyNames(reduced).map(
                              prop => ({
                                name: prop,
                                value: reduced[prop],
                                itemStyle: {
                                  color:
                                    '#' +
                                    ((Math.random() * 0xffffff) << 0).toString(
                                      16
                                    )
                                }
                              })
                            )
                          }

                          return group(acc)
                        }, []),

                      type: 'pie',
                      //center: ['80%', '30%'],
                      radius: '50%',
                      z: 2,
                      label: {
                        normal: {
                          formatter: '{d}',
                          position: 'inside'
                        }
                      }
                    }
                  ]
                }}
              />
            </Col>
          </Row>
        )
      default:
        return
    }
  }
  componentDidMount() {
    const { id } = this.props
    this.props.getQuizReport(id, 'overAll')
  }
  render() {
    const {
      data: {
        total_points,
        //   total_scores, submit, questions_count,
        questions = []
      }
    } = this.props.data.overAll
    const { fetch } = this.props.data.LessonQuiz
    const scatterData = questions
      .reduce((acc, item) => {
        if (item.user_answer)
          item.user_answer[item.type]
            .filter(z => z.users)
            .forEach(z => {
              z.users.forEach(y => {
                if (acc[y.user])
                  acc[y.user] = {
                    date: moment(y.date).format('YYYY-MM-DD'),
                    score: acc[y.user].score + y.scores
                  }
                else
                  acc[y.user] = {
                    date: moment(y.date).format('YYYY-MM-DD'),
                    score: y.scores
                  }
              })
            })
        return acc
      }, [])
      .filter(x => {
        return x !== null
      })
    return fetch ? (
      <Spinner />
    ) : (
      <div>
        <Row>
          <Col sm={16} lg={18}>
            <ReactEcharts
              option={{
                color: ['#2699FB'],
                legend: {
                  title: '3 User',
                  x: 'right',
                  orient: 'vertical',
                  align: 'right',
                  icon: 'circle',
                  top: 50
                },
                xAxis: {
                  name: 'Date/time',
                  axisLabel: {
                    formatter: value => {
                      return moment(value).format('D MMM')
                    }
                  },

                  splitLine: {
                    show: false
                  },
                  type: 'time'
                },
                yAxis: {
                  name: 'point',
                  scale: true,
                  type: 'value',
                  splitLine: {
                    show: false
                  },
                  max: total_points
                },
                grid: {
                  show: false
                },
                tooltip: {},
                series: [
                  {
                    visualMap: [
                      {
                        inRange: {
                          color: '#BDCADB'
                        },
                        outOfRange: {
                          color: '#2699FB'
                        },
                        type: 'piecewise',
                        zlevel: 1,
                        pieces: [
                          {
                            max: 50,
                            min: 0
                          }
                        ]
                      }
                    ],

                    symbolSize: 15,
                    data: scatterData.map(x => {
                      return [x.date, x.score]
                    }),
                    type: 'scatter'
                  }
                  // ,
                  // {
                  // data: scatterData.reduce((acc, item) => {
                  //     if (item.score > (total_points / 2)) {
                  //         if (acc[0]) acc[0].value++;
                  //         else acc.push({
                  //             name: 'Pass',
                  //             value: 1,
                  //             itemStyle: {color: '#2699FB'}
                  //         })
                  //     }
                  //     else {
                  //         if (acc[1]) acc[1].value++;
                  //         else acc.push({
                  //             name: 'Fail',
                  //             value: 1,
                  //             itemStyle: {color: '#BDCADB'}
                  //         })
                  //     }
                  //     return acc;
                  // }, []),
                  // type: 'pie',
                  // center: ['80%', '30%'],
                  // radius: '28%',
                  // z: 2,
                  // label: {
                  //     normal: {
                  //         formatter: '{d}',
                  //         position: 'inside'
                  //     }
                  // },

                  // }
                ]
              }}
            />
          </Col>
          <Col sm={8} lg={6}>
            <ReactEcharts
              option={{
                color: ['#2699FB'],
                legend: {
                  x: 'right',
                  orient: 'vertical',
                  align: 'right',
                  icon: 'circle'
                },
                tooltip: {},
                series: [
                  {
                    data: scatterData.reduce((acc, item) => {
                      if (item.score > total_points / 2) {
                        if (acc[0]) acc[0].value++
                        else
                          acc.push({
                            name: 'Pass',
                            value: 1,
                            itemStyle: { color: '#2699FB' }
                          })
                      } else {
                        if (acc[1]) acc[1].value++
                        else
                          acc.push({
                            name: 'Fail',
                            value: 1,
                            itemStyle: { color: '#BDCADB' }
                          })
                      }
                      return acc
                    }, []),
                    type: 'pie',
                    // center: ['80%', '30%'],
                    radius: '50%',
                    z: 2,
                    label: {
                      normal: {
                        formatter: '{d}',
                        position: 'inside'
                      }
                    }
                  }
                ]
              }}
            />
          </Col>
        </Row>
        {questions
          .filter(question => question.user_answer)
          .map((question, index) => {
            return (
              <div style={{ paddingTop: 30 }}>
                <div className="question">
                  Q{index + 1}. {question.quiz_question}
                </div>
                {this.questionByOverall(question)}
              </div>
            )
          })}
      </div>
    )
  }
}
export { QuizByOverall }
