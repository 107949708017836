import styled, { css } from 'styled-components'
import { Button, Radio, Checkbox, Icon } from 'antd'

import { styleguide } from '../../../../../constants'

const { colors } = styleguide

const Quiz = styled.div`
  padding: 2rem;
  overflow-y: auto;

  border:0.5px solid #fff;
  border-radius:8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color:#fff;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  }
`

Quiz.Content = styled.div``

Quiz.Action = styled.div`
  margin-top: 2rem;
`

Quiz.Title = styled.div`
  font-weight: 300;
  // font-size: 2.1rem;
  font-size: 2.3rem;
  margin-bottom: 0.8rem;
`

Quiz.Strong = styled.span`
  font-weight: 700;
`

Quiz.Question = styled.div`
  font-weight: 600;
  // font-size: 1.6rem;
  font-size: 2rem;
  margin-bottom: 0.8rem;
`

Quiz.Radio = styled(Radio)`
  && {
    height: 3rem;
    line-height: 3rem;
    font-size: 1.6rem;
    display: table;
    white-space: initial;
    text-align: justify;
  }
`

Quiz.ListRadioGroup = styled(Radio.Group)`
  && {
    width: 100%;
  }
`

Quiz.Checkbox = styled(Checkbox)`
  && {
    display: block;
    font-size: 1.6rem;
    text-align: justify;
  }
`

Quiz.ListCheckboxGroup = styled(Checkbox.Group)`
  && {
    width: 100%;

    .ant-checkbox-wrapper + .ant-checkbox-wrapper {
      margin-left: 0;
    }
  }
`

Quiz.SortableList = styled.div`
  position: relative;
  text-align: justify;
`

Quiz.SortableItem = styled.div`
  width: 100%;
  background-color: ${colors.white};
  border: 0.1rem dashed ${colors.darkGreyTwo};
  cursor: move;
  padding: 0.8rem 2rem;
  margin-bottom: 1rem;
  z-index: 99999;
  font-size: 1.6rem;
`

Quiz.ElementsList = styled.div`
  position: relative;
  text-align: justify;
  ${props =>
    props.hide &&
    css`
      display: none;
    `}
`

Quiz.Element = styled.div`
  background-color: ${colors.white};
  border: 0.1rem dashed ${colors.darkGreyTwo};
  cursor: move;
  padding: 0.8rem 2rem;
  z-index: 99999;
  margin-bottom: 1rem;
`

Quiz.ElementDrop = styled.div`
  background-color: ${colors.white};
  border: 0.1rem dashed ${colors.darkGreyTwo};
  cursor: move;
  padding: 0.8rem 2rem;
  z-index: 99999;
`

Quiz.CriterionList = styled.div`
  pointer-events: none;
  text-align: justify;
`

Quiz.CriterionContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-content: center;

  &:not(:first-child) {
    margin-top: 1rem;
  }
`

Quiz.Criterion = styled.div`
  border: 0.1rem solid ${colors.darkGreyTwo};
  flex: 1;
  padding: 0.8rem 2rem;
  margin-right: 1rem;
`

Quiz.Area = styled.div`
  border: 0.1rem solid ${colors.darkGreyTwo};
  flex: 1;
  padding: 0.8rem;
`

Quiz.Image = styled.img`
  width: 100%;
  max-width: calc(50% - 0.5rem);
  margin-top: 1rem;
`

Quiz.Result = styled.div`
  height: auto;
  overflow-y: auto;
`

Quiz.Result.Title = styled.div`
  width: 100%;
  color: ${colors.red};
  // font-size: 2.1rem;
  font-size: 2.8rem;
  margin-bottom: 2rem;
  text-align: center;

  ${props =>
    props.status &&
    css`
      color: ${colors.green};
    `}
`

Quiz.Result.Label = styled.div`
  border: 0.1rem solid ${colors.red};
  color: ${colors.red};
  padding: 0.5rem 0.5rem 0.5rem 3.5rem;
  margin: 0.5rem 0;
  position: relative;

  ${props =>
    props.status &&
    css`
      border-color: ${colors.green};
      color: ${colors.green};
    `}
`

Quiz.Result.Icon = styled(Icon)`
  position: absolute;
  top: 50%;
  left: 1rem;
  transform: translateY(-50%);
`

Quiz.Result.Load = styled.div`
  width: 100%;
  font-size: 1.8rem;
  padding: 3rem 0;
  text-align: center;
`

Quiz.Reset = styled(Button)`
  margin-bottom: 2rem;
`

Quiz.UnityContainer = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`

Quiz.FullScreen = styled.div`
  position: absolute;
  right: 10px;
  bottom: 15px;
  padding: 1rem;
  background: #ebebeb;
  display: grid;
  place-items: center;
`

export { Quiz }
