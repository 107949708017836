import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import BrainIcon from '../../assets/brain.svg'
import BrainDots from '../../assets/knowledgeCenter.png'
import userImage from '../../assets/user.svg'
import {
  Alert,
  Avatar,
  Button,
  Comment,
  Form,
  Input,
  Modal,
  Tag,
  Tooltip
} from 'antd'

import uuidv4 from 'uuid/v4'
import { useTranslation } from 'react-i18next'
import './KnowledgeCenter.scss'
import { api } from '../../services'
import { useSelector } from 'react-redux'
import { Spinner } from '../../components'
import _ from 'lodash'
import NewHeader from '../../components/NewHeader'

const knowledgeResources = {
  organization: 'ORGANIZATION',
  worldKnowledge: 'WORLD_KNOWLEDGE'
}

const { TextArea } = Input

function KnowledgeCenter({
  form: { getFieldDecorator, validateFields, setFieldsValue }
}) {
  const [conversation, setConversation] = useState([])
  const [currentQuestion, setCurrentQuestion] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [activeResource, setActiveResource] = useState('')

  const user = useSelector(state => state.user)

  const generateAnswer = useCallback(
    knowledgeResource => {
      validateFields((err, values) => {
        if (err) return

        setActiveResource(knowledgeResource)
        setLoading(true)
        const org_id = user.info.active_organization_id

        const temp = {
          org_id: String(org_id),
          question: currentQuestion
        }

        let tryCount = 0
        const maxTries = 2

        const request =
          knowledgeResource === knowledgeResources.organization
            ? api.audioSynthesizer.questionBot(temp)
            : api.audioSynthesizer.openAIBot(temp)
        const makeRequest = () => {
          request
            .then(res => {
              const body = res.data.body && res.data.body[0]
              setConversation(prevData => [
                ...prevData,
                {
                  ...body,
                  resource: knowledgeResource,
                  id: uuidv4()
                }
              ])
              setCurrentQuestion('')
              setFieldsValue({
                currentQuestion: ''
              })
              setLoading(false)
              setError(false)
            })
            .catch(err => {
              if (tryCount < maxTries) {
                tryCount++
                makeRequest()
              } else {
                setLoading(false)
                setCurrentQuestion('')
                setError(true)
              }
            })
        }

        makeRequest()
      })
    },
    [currentQuestion]
  )

  const messagesEndRef = useRef(null)

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }

  useEffect(() => {
    if (currentQuestion && !loading) return
    scrollToBottom()
  }, [conversation, currentQuestion, loading])
  const { t } = useTranslation()
  return (
    <Form className="knowledge-center-container">
      <div className="knowledge-center-head-wrap">
        <div className="knowledge-center-head">{t('v3:knowledge_center')}</div>
        <div className="shl-header-right">
          <NewHeader />
        </div>
      </div>

      <div className="knowledge-center-content">
        {conversation.length === 0 ? (
          <>
            <div className="knowledge-center-icon">
              <img alt="" className="brain-dots" src={BrainDots} />
            </div>

            <h1>{t('v3:knowledge_center_know_today')}</h1>
          </>
        ) : (
          <div className="knowledge-center-conversation">
            {conversation.map((item, index) => (
              <div key={item.id} className="knowledge-center-item">
                <ConversationItem item={item} isQuestion />

                <ConversationItem item={item} />
              </div>
            ))}

            {currentQuestion && loading && (
              <div key={uuidv4()} className="knowledge-center-item">
                <ConversationItem
                  item={{ question: currentQuestion, resource: activeResource }}
                  isQuestion
                />
                <ConversationItem isLoading />
              </div>
            )}

            {error && (
              <Alert type="error" message={t('v3:knowledge_center_error')} />
            )}

            <div ref={messagesEndRef} />
          </div>
        )}

        <Form.Item>
          {getFieldDecorator('currentQuestion', {
            initialValue: currentQuestion,
            rules: [
              {
                required: true,
                message: t('v3:knowledge_center_question')
              }
            ]
          })(
            <TextArea
              className="knowledge-center-input"
              rows={2}
              placeholder={t('v4:ask_questions')}
              onChange={e => setCurrentQuestion(e.target.value)}
              disabled={loading}
            />
          )}
        </Form.Item>

        <div className="knowledge-center-buttons">
          <Tooltip title={t('v3:knowledge_center_tooltrip')}>
            <Button
              type="primary"
              shape="round"
              onClick={() => generateAnswer(knowledgeResources.organization)}
              loading={
                loading && knowledgeResources.organization === activeResource
              }
              disabled={
                loading && knowledgeResources.worldKnowledge === activeResource
              }
              icon="info-circle"
            >
              {t('v3:my_organization')}
            </Button>
          </Tooltip>
          <Tooltip title="Get concise and contextually accurate responses to a wide range of queries including general knowledge questions. The responses are obtained from the general information on the internet from an AI model trained from a diverse range of internet text.">
            <Button
              type="primary"
              shape="round"
              onClick={() => generateAnswer(knowledgeResources.worldKnowledge)}
              loading={
                loading && knowledgeResources.worldKnowledge === activeResource
              }
              disabled={
                loading && knowledgeResources.organization === activeResource
              }
              icon="info-circle"
            >
              {t('v3:world_knowledge')}
            </Button>
          </Tooltip>
        </div>
      </div>
    </Form>
  )
}

const ConversationItem = ({ item, isQuestion, isLoading }) => {
  const user = useSelector(state => state.user)
  const [botModal, setBotModal] = useState({
    visible: false,
    file: {}
  })
  const { t } = useTranslation()
  const showModalContent = () => {
    const {
      visible,
      file: { context, pdf_name, file_title }
    } = botModal

    return (
      <Modal
        onCancel={() =>
          setBotModal({
            visible: false,
            file: {}
          })
        }
        visible={visible}
        title={pdf_name || file_title}
        footer={[
          <Button
            shape="round"
            key="submit"
            type="primary"
            onClick={handleOpenReferenceFile}
          >
            {t('v3:open_file')}
          </Button>
        ]}
        width="800px"
      >
        <div className="reference-content-container">
          <h1 className="reference-content-title">Contexts</h1>
          <div className="reference-content-all">
            {context.map((item, index) => (
              <div className="reference-content">
                <h3>{index + 1}.</h3>
                <span>{item}</span>
              </div>
            ))}
          </div>
        </div>
      </Modal>
    )
  }

  const handleOpenReferenceFile = useCallback(async () => {
    const {
      file: { signed_url }
    } = botModal

    window.open(signed_url, '_blank')
  }, [botModal])

  const reference = useMemo(
    () =>
      item && (_.isEmpty(item.reference) ? item.context_v1 : item.reference),

    [item]
  )
  return (
    <>
      <Comment
        avatar={
          <Avatar
            className="knowledge-center-item-avatar"
            src={
              isQuestion
                ? (user.info && user.info.avatar && user.info.avatar.link) ||
                  userImage
                : BrainIcon
            }
            alt="Sharelook Avatar"
          />
        }
        content={
          isLoading ? (
            <Spinner />
          ) : (
            <div className="knowledge-center-item-maintext">
              <p>{isQuestion ? item.question : item.answer}</p>
              {isQuestion && (
                <Tag color="geekblue">
                  {' '}
                  {item.resource === knowledgeResources.organization
                    ? t('general:organization')
                    : t('v3:world_knowledge')}
                </Tag>
              )}

              {!isQuestion && !_.isEmpty(reference) && (
                <div className="reference">
                  <span>{t('v3:reference')}</span> <br />
                  <ul>
                    {Object.keys(reference).map(ref => {
                      const fileItem = reference[ref]
                      return (
                        <li
                          onClick={() =>
                            setBotModal({
                              visible: true,
                              file: fileItem
                            })
                          }
                        >
                          [{ref}] {fileItem.pdf_name || fileItem.file_title}
                        </li>
                      )
                    })}
                  </ul>
                </div>
              )}
            </div>
          )
        }
      />

      {botModal.visible && showModalContent()}
    </>
  )
}

export default Form.create()(KnowledgeCenter)
