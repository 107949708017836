import http from './http'

export const fetchQuestions = (payload) => {
    return http({
        method: 'GET',
        url: `/triviaquizzes/`
    })
}
export const gameQuestions = () => {
    return http({
        method: 'GET',
        url: `/triviaquizzes/questions`
    })
}
export const getBucketNames = (payload) => {
    return http({
        method: 'POST',
        data: payload,
        url: `/library/sign`
    })
}
export const createQuitz = (payload) => {
    return http({
        method: 'POST',
        data: payload,
        url: `/triviaquizzes/`
    })
}
export const updateQuitz = (payload) => {
    return http({
        method: 'PUT',
        data: payload,
        url: `/triviaquizzes/${payload.id}`
    })
}
export const getScore = () => {
    return http({
        method: 'GET',
        url: `/triviaquizzes/score/`
    })
}
export const updateScore = (payload) => {
    return http({
        method: 'PUT',
        data: payload,
        url: `/triviaquizzes/updatescore/`
    })
}
export const fetchQuestionDetail = (id) => {
    return http({
        method: 'GET',
        url: `/triviaquizzes/${id}`
    })
}
export const deleteQuestion = (id) => {
    return http({
        method: 'DELETE',
        url: `/triviaquizzes/${id}`
    })
}
