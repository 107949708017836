import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { api } from '../../../services'

import './viewChannel.scss'
import ViewChannelHeader from './components/ViewChannelHeader'
import ViewChannelDisplay from './components/ViewChannelDisplay'
import ViewChannelVideos from './components/ViewChannelVideos'
import ViewChannelSidebar from './components/ViewChannelSidebar'
import { Spinner, TopPageLink } from '../../../components'
import _ from 'lodash'
import EmptyMessage from '../../../components/EmptyMessage/EmptyMessage'
import { Modal, Button, Alert, Result, Icon } from 'antd'
import { useTranslation } from 'react-i18next'

const defaultChannelHeaderState = {
  notes: false,
  discussion: false,
  review: false
}
const ViewChannel = props => {
  const [showHeaderItems, setShowHeaderItems] = useState(
    defaultChannelHeaderState
  )
  const [channelData, setChannelData] = useState({})
  const [isChannelLoading, setIsChannelLoading] = useState(true)
  const [channelErrors, setChannelErrors] = useState({})
  const [selectedVideo, setSelectedVideo] = useState(null)
  const [showAiVideoQueueModal, setShowAiVideoQueueModal] = useState({
    show: false,
    videoId: null,
    checking: false,
    status: 'queued'
  })

  useEffect(() => {
    if (showAiVideoQueueModal.show && showAiVideoQueueModal.videoId) {
      fetchVideoStatus()
    }
  }, [showAiVideoQueueModal.videoId])

  useEffect(() => {
    const channelId = props.match.params.id || null
    if (channelId) {
      setIsChannelLoading(true)
      api.channel
        .getChannel(channelId)
        .then(res => {
          setChannelData(res.data)
          setIsChannelLoading(false)
          setChannelErrors({})
        })
        .catch(err => {
          setIsChannelLoading(false)
          setChannelErrors(err)
        })
    }
  }, [])

  const handleHeaderItemClick = value => {
    const itemType = value && value.toLowerCase()
    setShowHeaderItems({
      ...defaultChannelHeaderState,
      [itemType]: !showHeaderItems[itemType]
    })
  }

  const handleSelectVideo = id => {
    const video = channelData.collections_videos.find(item => item.id === id)

    if (video.type === 'ai_video') {
      if (!video.status) return

      if (video.status === 'generating' || video.status === 'error') {
        setShowAiVideoQueueModal({
          ...showAiVideoQueueModal,
          videoId: video.id,
          show: true
        })
        return
      }
    }

    setSelectedVideo(video)
  }

  const handleClose = () => setShowHeaderItems(defaultChannelHeaderState)

  const showAnyHeaderItem = useMemo(() => {
    return Object.keys(showHeaderItems).find(
      key => showHeaderItems[key] === true
    )
  }, [showHeaderItems])

  const fetchVideoStatus = async () => {
    try {
      setShowAiVideoQueueModal({
        ...showAiVideoQueueModal,
        checking: true
      })
      const response = await api.video.getVideoStatus(
        showAiVideoQueueModal.videoId
      )
      const status = response.data.generateStatus
      if (status !== 'completed') {
        setShowAiVideoQueueModal({
          ...showAiVideoQueueModal,
          status: status,
          checking: false
        })
      } else {
        window.location.reload()
      }
    } catch (error) {
      setShowAiVideoQueueModal({
        ...showAiVideoQueueModal,
        checking: false
      })
    }
  }

  const renderVideoQueueModal = useCallback(() => {
    const video =
      channelData.collections_videos &&
      channelData.collections_videos.find(
        item => item.id === showAiVideoQueueModal.videoId
      )

    return (
      <Modal
        visible={showAiVideoQueueModal.show}
        footer={null}
        onCancel={() =>
          setShowAiVideoQueueModal({ show: false, videoId: null })
        }
        width={600}
        destroyOnClose
        centered
        maskClosable
        className="ai-video-queue-modal-container"
      >
        <Result
          icon={
            showAiVideoQueueModal.checking ? (
              <Icon type="loading" />
            ) : showAiVideoQueueModal.status === 'error' ? (
              <Icon type="close-circle" style={{ color: '#ff4b55' }} />
            ) : (
              <Icon type="info-circle" />
            )
          }
          title={
            showAiVideoQueueModal.checking
              ? 'Checking video generation status...'
              : showAiVideoQueueModal.status === 'queued'
              ? 'The video generation is on queue.'
              : showAiVideoQueueModal.status === 'error'
              ? 'Sorry, the video generation failed. Please try another one.'
              : 'This video is currently being generated.'
          }
          extra={
            showAiVideoQueueModal.checking ||
            showAiVideoQueueModal.status === 'error' ||
            showAiVideoQueueModal.status === 'completed' ? null : (
              <Button
                type="primary"
                ghost
                key="console"
                onClick={fetchVideoStatus}
                loading={showAiVideoQueueModal.checking}
              >
                {showAiVideoQueueModal.checking
                  ? 'Checking Status'
                  : 'Check Status'}
              </Button>
            )
          }
        />
      </Modal>
    )
  }, [showAiVideoQueueModal, selectedVideo, channelData])

  if (isChannelLoading) {
    return <Spinner />
  }

  if (!_.isEmpty(channelErrors)) {
    return <EmptyMessage title="No Channel Found" />
  }
  return (
    <>
      <div className="video-head-back">
        <TopPageLink page="Training" path="/training" />
      </div>
      <div className="view-channel-container">
        <div className="view-channel-left">
          <ViewChannelHeader
            channelId={channelData.id}
            title={channelData.title}
            showHeaderItems={showHeaderItems}
            onHeaderItemClick={handleHeaderItemClick}
          />

          <ViewChannelDisplay
            previewFile={selectedVideo ? selectedVideo : channelData.cover}
            isVideo={selectedVideo ? true : false}
            description={channelData.description}
          />

          {selectedVideo && selectedVideo.id && (
            <ViewChannelVideoInfo
              title={selectedVideo.filename || selectedVideo.title}
            />
          )}
        </div>
        <div className="view-channel-right">
          <ViewChannelVideos
            videos={channelData.collections_videos}
            onSelectVideo={handleSelectVideo}
            selectedVideoId={selectedVideo ? selectedVideo.id : null}
          />
          {showAnyHeaderItem && (
            <ViewChannelSidebar
              channelId={channelData.id}
              showHeaderItems={showHeaderItems}
              handleClose={handleClose}
            />
          )}
        </div>

        {showAiVideoQueueModal.show && renderVideoQueueModal()}
      </div>
    </>
  )
}

const ViewChannelVideoInfo = ({ title }) => {
  const { t } = useTranslation()
  return (
    <div className="view-channel-video-info">
      <h3 className="view-channel-video-info-title">
        {t('v3:video_playing')}
        {title}
      </h3>
    </div>
  )
}

export default ViewChannel
