import Certificate from './Certificate'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import actions from '../../../../../../../store/actions'

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getLibraryList: actions.library.getLibraryList,
      upload: actions.library.upload
    },
    dispatch
  )

export default connect(null, mapDispatchToProps)(Certificate)
