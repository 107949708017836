import styled from 'styled-components'

const Cards = styled.div`
`

Cards.List = styled.div`
`

export {
  Cards
}
