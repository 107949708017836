import React, { useMemo, useState } from 'react'
import ReactEcharts from 'echarts-for-react'
import _ from 'lodash'
import { report } from '../../../../constants'
import './styles.scss'
import { Button, Modal, Tag } from 'antd'
import { useTranslation } from 'react-i18next'

const colors = [
  '#FFB37C',
  '#A4EFA7',
  '#8DC3F8',
  '#246A9C',
  '#B95EF0',
  '#E1D917'
]

const black = { color: 'black' }

const modalTypes = {
  badge: 'Badge',
  certificate: 'Certificate'
}

function OverviewMarketGraph(props) {
  const {
    monthXAxis,
    courseMarketData,
    webinarMarketData,
    coachingMarketData,
    liveAssessmentMarketData,
    mazesMarketData,
    publicConferenceMarketData,
    isUserReport,
    buyers,
    Product,
    badges = [],
    certificates = [],
    enableMaze
  } = props

  const [showModalType, setShowModalType] = useState(null)

  const isBadgeActive = useMemo(() => showModalType === modalTypes.badge, [
    showModalType
  ])
  const isCertificateActive = useMemo(
    () => showModalType === modalTypes.certificate,
    [showModalType]
  )

  const emptyData = monthXAxis.map(item => (item = 0))

  const filteredBadges = useMemo(() => {
    return badges.filter(item => item.badge != null)
  }, [badges])

  const downloadReward = item => {
    const itemUrl = isBadgeActive
      ? item.link
      : item.custom_data && item.custom_data.pdf_link
    const itemName = `${showModalType} - ${item.title}`
    const link = document.createElement('a')
    link.href = itemUrl
    if (isCertificateActive) {
      link.target = '__blank'
    }
    link.download = itemName
    link.click()
  }

  const { t } = useTranslation()

  return (
    <div className="overview-graph">
      <ReactEcharts
        option={{
          tooltip: {},
          legend: {
            icon: 'circle',
            x: 'left',
            left: '20px',
            data: [
              report.COURSE,
              report.WEBINAR,
              report.COACHING_SESSION,
              report.CHANNELS,
              enableMaze && report.MAZE,
              report.PUBLIC_CONFERENCE
            ]
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: {
            type: 'category',
            data: monthXAxis
          },
          yAxis: {
            type: 'value'
          },
          color: colors,
          series: [
            {
              name: report.COURSE,
              data: _.isEmpty(courseMarketData) ? emptyData : courseMarketData,
              type: 'line',
              smooth: 'true'
            },
            {
              name: report.WEBINAR,
              data: _.isEmpty(webinarMarketData)
                ? emptyData
                : webinarMarketData,
              type: 'line',
              smooth: 'true'
            },
            {
              name: report.COACHING_SESSION,
              data: _.isEmpty(coachingMarketData)
                ? emptyData
                : coachingMarketData,
              type: 'line',
              smooth: 'true'
            },
            {
              name: report.CHANNELS,
              data: _.isEmpty(liveAssessmentMarketData)
                ? emptyData
                : liveAssessmentMarketData,
              type: 'line',
              smooth: 'true'
            },
            {
              name: enableMaze && report.MAZE,
              data:
                enableMaze &&
                (_.isEmpty(mazesMarketData) ? emptyData : mazesMarketData),
              type: 'line',
              smooth: 'true'
            },
            {
              name: report.PUBLIC_CONFERENCE,
              data: _.isEmpty(publicConferenceMarketData)
                ? emptyData
                : publicConferenceMarketData,
              type: 'line',
              smooth: 'true'
            }
          ]
        }}
      />

      <div className="badge-certificate-menus">
        <div
          className="menu-item"
          style={{
            background: '#ffb37c'
          }}
          onClick={() =>
            filteredBadges.length > 0
              ? setShowModalType(modalTypes.badge)
              : null
          }
        >
          <span style={black}>{Product}</span>
          <h3 style={black}>{t('v4:products')}</h3>
        </div>
        <div
          className="menu-item"
          style={{
            background: '#1294F2'
          }}
          onClick={() =>
            certificates.length > 0
              ? setShowModalType(modalTypes.certificate)
              : null
          }
        >
          <span>{buyers}</span>
          <h3>{t('reports:buyers')}</h3>
        </div>
      </div>

      <Modal
        width="1229px"
        title={
          isBadgeActive
            ? 'Products'
            : isCertificateActive
            ? 'Buyers'
            : 'Closing...'
        }
        visible={showModalType}
        onCancel={() => setShowModalType(null)}
        footer={false}
      >
        {(() => {
          const items = isBadgeActive
            ? filteredBadges
            : isCertificateActive
            ? certificates
            : []

          return (
            <div className="report-reward-container">
              {items &&
                items.map(item => {
                  const imageItem = isBadgeActive
                    ? item.badge && item.badge
                    : item.certificate && item.certificate
                  return (
                    <div className="report-reward-item">
                      <img
                        src={imageItem && imageItem.link}
                        alt="user-report-reward-image"
                      />

                      <div className="report-reward-item-info">
                        <div className="about">
                          <h3>{imageItem && imageItem.title}</h3>
                          <p>{imageItem && imageItem.description} </p>
                        </div>
                        <div className="action">
                          {isCertificateActive ? (
                            <Button
                              type="primary"
                              onClick={() => downloadReward(imageItem)}
                            >
                              {t('buttons:download')} {showModalType}
                            </Button>
                          ) : (
                            <>
                              {t('general:course')} :{' '}
                              <Tag> {item.course_title} </Tag>{' '}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  )
                })}
            </div>
          )
        })()}
      </Modal>
    </div>
  )
}

export default OverviewMarketGraph
