import {
  HANDLE_MAZE_INPUT,
  RESET_MAZE_BUILDER,
  SET_MAZE_CREATION
} from '../types'

const defaultState = {
  mazeId: null,
  mazeTitle: '',
  mazeOrganiser: '',
  mazeTags: [],
  mazeImgId: null,
  mazeImg: '',
  mazeDescription: '',
  mazeBackground: {},
  mazeBackgroundId: null,
  mazeAvatarId: null,
  mazeVoiceType: null,
  mazeVoiceAccent: null,
  mazeVoiceAge: null,
  mazeSelectedVoice: null,
  mazeVoice: undefined,
  mazeCertId: null,
  mazeBadgeId: null,
  mazeCategory: null,
  mazeIsFree: true,
  mazePrice: null,
  mazeVideoLink: '',
  mazeOrganization: null,
  mazeGroup: null,
  mazeObjectives: '',
  mazeOutline: '',
  mazeMarketPlaceDescription: '',
  mazePassScore: null,
  mazeLayers: null,
  mazeOptions: null,
  saving: false,
  mazeContent: [],
  isCreation: false,
  mazeStartDate: null,
  mazeMarketplaceOption: 1,
  mazeExpDate: null,
  mazeShareProfile: false,
  mazeHostEmails: []
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case HANDLE_MAZE_INPUT:
      return {
        ...state,
        [action.field]: action.payload
      }
    case RESET_MAZE_BUILDER:
      return defaultState

    case SET_MAZE_CREATION:
      return {
        ...state,
        isCreation: action.payload
      }

    default:
      return state
  }
}
