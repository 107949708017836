import React, { useCallback } from 'react'
import TrainerSection from '../../../../../../../components/TrainerSection'

import PriceSection from './components/PriceSection'
import TextSection from './components/TextSection'
import { Button, Col, Form, Row } from 'antd'
import { useTranslation } from 'react-i18next'

function ChannelFormMarketplace({
  onPrice,
  onMarketplaceValues,
  marketplaceData,
  form,
  channelId,
  onToggleMarketplace
}) {
  const { t } = useTranslation()
  const validateForm = useCallback(() => {
    const { validateFields } = form
    validateFields()
  })
  return (
    <Form>
      <TrainerSection />
      <PriceSection
        onPriceObj={obj => {
          onPrice(obj)
          validateForm()
        }}
        form={form}
        values={marketplaceData}
      />
      <TextSection
        onValues={val => {
          onMarketplaceValues(val)
          validateForm()
        }}
        form={form}
        values={marketplaceData}
      />

      <Row type={"flex"} justify="end">
        <Col>
          <Button
            size="large"
            shape="round"
            className="button-blue_color"
            onClick={onToggleMarketplace}
            disabled={!channelId}
          >
            {t('v3:save_and_continue').toUpperCase()}
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

export default Form.create()(ChannelFormMarketplace)
