import React, { Component } from 'react'
import Dropzone from 'react-dropzone'
import _ from 'lodash'
import { Element } from '../'
import './Page.scss'
import nanoid from 'nanoid'
import { builder } from '../../../constants'
import { isSafari } from 'react-device-detect'
import { ACCEPT_TYPE } from './page.ctrl'
import grid from '../../../assets/course_grid.png'

const { types } = builder

export default class extends Component {
  state = {
    ref: null,
    error: null
  }
  _refs = new Map()

  setRef = (i, n) => {
    this._refs.set(i, n)
  }

  addElement = (uid, type, index = 0) => {
    const { handleData } = this.props
    const width = type === types.TEXT ? 450 : 400
    const data = {
      uid: uid,
      type: type,
      params: {
        content: '',
        width,
        height: 200,
        x: this.getX(index),
        y: this.getY(index)
      }
    }

    if (handleData) {
      handleData(data, true)
    }
  }

  getY = index => {
    let Y = 0
    if (index <= 9) {
      Y = index
    } else if (index <= 19) {
      Y = index - 10
    } else if (index <= 29) {
      Y = index - 20
    } else if (index <= 39) {
      Y = index - 30
    } else if (index <= 49) {
      Y = index - 40
    }
    return Y * 60
  }

  getX = index => {
    let X = 0
    if (index <= 9) {
      X = 0
    } else if (index <= 19) {
      X = 60
    } else if (index <= 29) {
      X = 120
    } else if (index <= 39) {
      X = 180
    } else if (index <= 49) {
      X = 240
    }
    return X
  }

  isValidType = type => {
    let valid = true
    switch (type) {
      case types.TEXT:
        break

      case types.VIDEO:
        break
      case types.AUDIO:
        break
      case types.IMAGE:
        break
      default:
        valid = false
        break
    }
    return valid
  }

  getType = ({ type }) => (type ? type.split('/')[0].toUpperCase() : ' ')

  onDrop = files => {
    const { handleMultipleDrop, handleFileDropIn, clearUploadList } = this.props

    clearUploadList()

    if (files.length < 1 || files.length > 10) {
      if (handleMultipleDrop) {
        handleMultipleDrop(files.length)
      }
      return
    }
    const uid = nanoid(8)

    const customFile = _.map(files, (file, index) => ({
      file,
      uid: uid + index
    }))

    _.each(customFile, (data, index) => {
      this.generateFilesUpload(data, index)
    })

    handleFileDropIn(customFile) // Create redux to store dropped file with auto generated uid
  }

  generateFilesUpload = ({ file, uid }, index) => {
    const getType = this.getType(file)

    if (!this.isValidType(getType)) {
      return
    }

    this.addElement(uid, getType, index) // function prepare data for uploading and display content of data depend on type in page
  }

  render() {
    const {
      handleData,
      handleParams,
      setBackgroundComponentRef,
      onChangeBackgroundComponent,
      uidBackground,
      pageStructure,
      isEditElement, // get from redux use for disable Dropzone if Edit function work isEditElement true,
      handleMultipleDrop,
      isLandScape,
      isAnnote,
      isAutoBackground,
      enableGrid
    } = this.props

    // 71.4rem instead of 55.4rem for 768px height
    return (
      <div
        className="wrap_page"
        style={{
          width: isLandScape ? '1086px' : '',
          marginTop: isLandScape ? '11px' : '',
          marginLeft: isLandScape ? '1em' : ''
        }}
      >
        <div
          className={`page ${isSafari ? 'fix-input-problem-safari ' : ''}`}
          style={{ height: isAnnote ? '102.4rem' : '55.4rem' }}
        >
          {enableGrid && <img alt="" className="page_grid" src={grid} />}
          <Dropzone
            accept={ACCEPT_TYPE}
            multiple
            disabled={isEditElement}
            className="page__dropzone"
            // style={isLandScape ? { width: '1024px' } : ''}
            onDrop={this.onDrop}
            disableClick
          >
            <div className="page__data">
              <input
                ref={setBackgroundComponentRef}
                uid={uidBackground}
                type="file"
                onChange={onChangeBackgroundComponent}
              />
            </div>

            <div
              className="page__elements"
              style={{ width: isLandScape ? '1024px' : '' }}
            >
              {pageStructure.map((node, elIndex) => (
                <Element
                  key={node.uid}
                  node={node}
                  isAutoBackground={isAutoBackground}
                  elIndex={elIndex}
                  isLandScape={isLandScape}
                  handleData={handleData}
                  handleParams={handleParams}
                  pageStructure={pageStructure}
                  handleMultipleDrop={handleMultipleDrop}
                  _refs={this._refs}
                  setRef={this.setRef}
                  {...this.props}
                />
              ))}
            </div>
          </Dropzone>
        </div>
      </div>
    )
  }
}
