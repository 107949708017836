import styled from 'styled-components'

import { styleguide } from '../../constants'

const { colors, typography } = styleguide

export const PollBox = styled.div`
  border: 0.1rem solid ${colors.darkGreyThree};
  padding-bottom: 1rem;
  margin-top: 2rem;
`

PollBox.Title = styled.div`
  padding: 1.5rem;
  font-size: ${typography.h2FontSize};
  font-weight: ${typography.h3FontWeight};
  border-bottom: 0.1rem solid ${colors.darkGreyThree};
`

PollBox.Content = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 1.5rem; 
`

PollBox.Question = styled.div`
  font-weight: ${typography.h2FontWeight}
`

PollBox.QuestionsBox = styled.div`
  margin-top: 1rem;
`

PollBox.SingleVariant = styled.div`
  font-size: 1.1rem;
  font-style: italic;
  font-weight: 300;
  border: 0.1rem solid ${colors.darkGreyThree};
  border-radius: 2rem;
  padding: 1.5rem;
  margin-bottom: 1rem;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  &:after {
    display: block;
    content: '';
    background-color: ${colors.darkGreyThree};
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    z-index: -1;
    width: ${props => props.percent}%;
  }
`
