import React from 'react'
import { withTranslation } from 'react-i18next'
import View from './internals/View'
import { upload, updateAR } from '../../../../services/api/library'
import { config } from '../../../../constants'

class MazeUploads extends React.Component {
  state = {
    mediaDat: {},
    zipLoading: false,
    imgLoading: false,
    imgPreviewLoading: false,
    audioLoading: false,
    scaleMP4: '',
    isZip: false,
    tempId: '',
    submitLoading: false,
    imageSizeCheck: false
  }

  componentDidMount() {
    const { editAssets, selectedData } = this.props
    const { mediaDat } = this.state
    console.log('didmount', selectedData)
    if (
      editAssets &&
      selectedData &&
      selectedData.id &&
      selectedData.ar_files
    ) {
      const found = selectedData.ar_files.some(
        el => el.media_mime_type === 'application/zip'
      )
      this.setState({
        mediaDat: {
          ...mediaDat,
          id: selectedData.id,
          ar_files: selectedData.ar_files
        },
        isZip: !found ? false : true
      })
    }

    // SERVER WAKE CALL
    const GET_URL = `${config.phpServerURL}?select=GetTarget`
    fetch(GET_URL)
      .then(response => {
        return response.json()
      })
      .then(response => {
        console.log('PHP response GET', response)
      })
      .catch(error => {
        // handle the error
        console.log('ERROR GET', error)
      })
  }

  onDropTargetImage = (acceptedFiles, rejectedFiles) => {
    const accepted_files = acceptedFiles[0]
    const rejected_files = rejectedFiles[0]

    if (rejected_files) {
      this.setState({
        imageSizeCheck: true
      })
      return
    }

    this.fileReader(accepted_files, 'preview-img')
    const { upload } = this.props
    this.setState(
      {
        imgLoading: true,
        imageSizeCheck: false
      },
      () => {
        upload(accepted_files, { isAR: 'imgAR' }).then(value => {
          const { mediaDat } = this.state
          this.setState({
            mediaDat: {
              ...mediaDat,
              category: 'ar',
              key: value.key,
              filename: value.filename,
              type: value.type,
              format: 'ARIMAGE'
            },
            imgLoading: false
          })
        })
      }
    )
  }

  onDropppedZip = (acceptedFiles, rejectedFiles) => {
    const { upload } = this.props
    const { mediaDat } = this.state
    const accepted_files = acceptedFiles[0]
    const rejected_files = rejectedFiles[0]

    if (rejected_files) {
      console.log('i m rejected zip')
      return
    }
    console.log('accepted_files mp4', accepted_files.type)
    if (accepted_files && accepted_files.type === 'video/mp4') {
      const video = document.createElement('video')
      video.addEventListener('loadedmetadata', () => {
        console.log('Video Width Height', video.videoWidth, video.videoHeight)
        if (mediaDat.hasOwnProperty('ar_files')) {
          let arFiles = mediaDat.ar_files.slice()
          const audioIndex =
            mediaDat &&
            mediaDat.ar_files &&
            arFiles.findIndex(obj => {
              return obj.media_format === 'AUDIO'
            })
          if (audioIndex > -1) {
            arFiles.splice(audioIndex, 1)
          }

          const compressId =
            mediaDat &&
            mediaDat.ar_files &&
            arFiles.find(obj => {
              return obj.media_format === 'COMPRESSED'
            })

          this.setState(
            {
              tempId: compressId && compressId.id ? compressId.id : ''
            },
            () => {
              const compressIndex =
                mediaDat &&
                mediaDat.ar_files &&
                arFiles.findIndex(obj => {
                  return obj.media_format === 'COMPRESSED'
                })

              if (compressIndex > -1) {
                arFiles.splice(compressIndex, 1)
              }

              console.log('arFiles after spliced', arFiles)
              this.setState({
                mediaDat: { ...mediaDat, ar_files: arFiles }
              })
            }
          )
        }
        this.setState({
          scaleMP4: video.videoHeight / video.videoWidth,
          isZip: false
        })
      })
      video.src = URL.createObjectURL(accepted_files)
    } else {
      this.setState(
        {
          isZip: true
        },
        () => {
          if (mediaDat.hasOwnProperty('ar_files')) {
            let arFiles = mediaDat.ar_files.slice()

            const videoId =
              mediaDat &&
              mediaDat.ar_files &&
              arFiles.find(obj => {
                return obj.media_format === 'VIDEO'
              })

            this.setState(
              {
                tempId: videoId && videoId.id ? videoId.id : ''
              },
              () => {
                const videoIndex =
                  mediaDat &&
                  mediaDat.ar_files &&
                  arFiles.findIndex(obj => {
                    return obj.media_format === 'VIDEO'
                  })

                if (videoIndex > -1) {
                  arFiles.splice(videoIndex, 1)
                }

                console.log('arFiles after spliced else', arFiles)
                this.setState({
                  mediaDat: { ...mediaDat, ar_files: arFiles }
                })
              }
            )
          }
        }
      )
    }
    this.setState(
      {
        zipLoading: true
      },
      () => {
        console.log('accepted_files file ', accepted_files)
        upload(accepted_files, { isAR: 'zipAR' }).then(value => {
          const { tempId } = this.state
          const typeTemp = value.type === 'video/mp4' ? 'VIDEO' : 'COMPRESSED'
          console.log('tempId file ', tempId)
          const index = this.findIndex(typeTemp)
          if (tempId !== '') {
            value.id = tempId
          }
          console.log('value chnaged ', value)
          this.checkPoint(
            this.generateObject(typeTemp, value),
            index,
            'zipLoading'
          )
        })
      }
    )
  }

  onDropTargetPreviewImage = (acceptedFiles, rejectedFiles) => {
    const accepted_files = acceptedFiles[0]
    const rejected_files = rejectedFiles[0]

    if (rejected_files) {
      console.log('i m rejected preview image')
      return
    }

    this.fileReader(accepted_files, 'preview-ar-img')
    const { upload } = this.props
    this.setState(
      {
        imgPreviewLoading: true
      },
      () => {
        upload(accepted_files, { isAR: 'zipAR' }).then(value => {
          console.log('VALUE CHECK PREVIEW', value)
          const index = this.findIndex('IMAGE')
          this.checkPoint(
            this.generateObject('IMAGE', value),
            index,
            'imgPreviewLoading'
          )
        })
      }
    )
  }

  onDropppedAudio = (acceptedFiles, rejectedFiles) => {
    const accepted_files = acceptedFiles[0]
    const rejected_files = rejectedFiles[0]

    if (rejected_files) {
      console.log('i m rejected audio')
      return
    }

    const { upload } = this.props
    this.setState(
      {
        audioLoading: true
      },
      () => {
        upload(accepted_files, { isAR: 'zipAR' }).then(value => {
          console.log('VALUE CHECK AUDIO', value)
          const index = this.findIndex('AUDIO')
          this.checkPoint(
            this.generateObject('AUDIO', value),
            index,
            'audioLoading'
          )
        })
      }
    )
  }

  fileReader = (f, t) => {
    const reader = new FileReader()
    reader.onload = () => {
      const output = document.getElementById(t)
      output.src = reader.result
    }
    reader.readAsDataURL(f)
  }

  isType = data => {
    const index =
      data &&
      data.data &&
      data.data.ar_files &&
      data.data.ar_files.findIndex(obj => {
        return obj.media_format === 'COMPRESSED' || obj.media_format === 'VIDEO'
      })
    if (
      index > -1 &&
      data.data.ar_files[index].media_mime_type === 'video/mp4'
    ) {
      return 'mp4'
    } else {
      return 'zip'
    }
  }

  resetState = () => {
    const { hideModal } = this.props
    hideModal()
    this.setState({
      mediaDat: {},
      zipLoading: false,
      isZip: false,
      imgLoading: false,
      imgPreviewLoading: false,
      audioLoading: false,
      submitLoading: false,
      tempId: ''
    })
  }

  generateObject = (mediaFormat, val) => {
    console.log('log val', val)
    return {
      filename: val.filename,
      media_format: mediaFormat,
      id: val.id,
      key: val.key,
      media_mime_type: val.type
    }
  }

  findIndex = type => {
    const { mediaDat } = this.state
    return (
      mediaDat &&
      mediaDat.ar_files &&
      mediaDat.ar_files.findIndex(obj => {
        return obj.media_format === type
      })
    )
  }

  checkPoint = (struct, index, loader) => {
    const { mediaDat } = this.state
    if (mediaDat.hasOwnProperty('ar_files')) {
      let arFiles = mediaDat.ar_files.slice()
      console.log('Hey in if')
      if (index > -1) {
        struct.id = arFiles[index].id
        arFiles[index] = struct
      } else {
        arFiles[arFiles.length] = struct
      }
      this.setState({
        mediaDat: { ...mediaDat, ar_files: arFiles },
        [loader]: false
      })
    } else {
      console.log('Hey in else')
      this.setState({
        mediaDat: { ...mediaDat, ar_files: [struct] },
        [loader]: false
      })
    }
  }

  onChangeTitle = e => {
    const { mediaDat } = this.state
    this.setState({
      mediaDat: { ...mediaDat, title: e.target.value.trim() }
    })
  }

  onChangeDescription = e => {
    const { mediaDat } = this.state
    this.setState({
      mediaDat: { ...mediaDat, description: e.target.value }
    })
  }

  onClickUpdate = () => {
    this.setState({ submitLoading: true }, () => {
      const { dispatchResult } = this.props
      const { mediaDat } = this.state
      if (mediaDat && mediaDat.id) {
        updateAR(mediaDat, mediaDat.id).then(data => {
          dispatchResult(data.data)
          this.resetState()
        })
      }
    })
  }

  onClickSubmit = () => {
    this.setState({ submitLoading: true }, () => {
      const { mediaDat } = this.state
      const { dispatchResult } = this.props
      upload(mediaDat).then(data => {
        dispatchResult(data.data)
        this.resetState()
      })
    })
  }

  render() {
    console.log('state value', this.state)

    const {
      mediaDat,
      zipLoading,
      imgLoading,
      submitLoading,
      imgPreviewLoading,
      audioLoading,
      scaleMP4,
      isZip,
      imageSizeCheck
    } = this.state

    const { editAssets, selectedData } = this.props
    console.log('value of isZip', isZip)
    return (
      <View
        mediaDat={mediaDat}
        zipLoading={zipLoading}
        imgLoading={imgLoading}
        scaleMP4={scaleMP4}
        selectedData={selectedData}
        submitLoading={submitLoading}
        imgPreviewLoading={imgPreviewLoading}
        audioLoading={audioLoading}
        isZip={isZip}
        imageSizeCheck={imageSizeCheck}
        editAssets={editAssets}
        onChangeTitle={this.onChangeTitle}
        onChangeDescription={this.onChangeDescription}
        onDropTargetImage={this.onDropTargetImage}
        onDropppedZip={this.onDropppedZip}
        onDropppedAudio={this.onDropppedAudio}
        onDropTargetPreviewImage={this.onDropTargetPreviewImage}
        onClickUpdate={this.onClickUpdate}
        onClickSubmit={this.onClickSubmit}
      />
    )
  }
}

export default withTranslation('')(MazeUploads)
