export class CmiObjectives {
	id
	n
	n_id
	score_raw
	score_min
	score_max
	status
	core_id
	score_scaled
	progress_measure
	success_status
	completion_status
	description
}