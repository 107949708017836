import React, { Component } from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './slickCarousel.scss'

export default class SlickCarousel extends Component {
  render () {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      lazyload: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      beforeChange: (current, next) =>
        this.props.children[current].props.onPause()
    }

    return (
      <Slider ref={slider => (this.slider = slider)} {...settings}>
        {this.props.children}
      </Slider>
    )
  }
}
