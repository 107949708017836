import React, { useEffect, useRef, useState } from 'react'
import {
  ReloadOutlined,
  BackwardOutlined,
  PlayCircleOutlined,
  PauseCircleOutlined,
  ForwardOutlined
} from '@ant-design/icons'
import './AudioPlayer.scss'

export default function AudioPlayer({ audioUrl, audioName, autoPlayNext }) {
  const audioRef = useRef(null)
  const progressBarRef = useRef(null)
  const [duration, setDuration] = useState(0)
  const [currentProgress, setCurrentProgress] = useState(0)
  const [currentPosition, setCurrentPosition] = useState(0)

  const skipPlay = type => {
    const currentTime = audioRef.current.currentTime
    const duration = audioRef.current.duration
    let newProgress

    if (type === 'BACK') {
      newProgress = Math.max(0, currentTime - 10)
    } else if (type === 'FORWARD') {
      newProgress = Math.min(currentTime + 10, duration)
    }

    audioRef.current.currentTime = newProgress
    setCurrentProgress((newProgress / duration) * 100)
  }
  const formatTime = timeInSeconds => {
    const minutes = Math.floor(timeInSeconds / 60)
    const seconds = Math.floor(timeInSeconds % 60)
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`
  }
  useEffect(() => {
    const audio = audioRef.current

    const updateProgress = () => {
      const audio = audioRef.current
      const currentTime = audio.currentTime
      setCurrentPosition(currentTime)
      const duration = audio.duration
      const progress = (currentTime / duration) * 100
      if (progress === 100) {
        autoPlayNext()
      }
      setCurrentProgress(progress)
    }

    const updateDuration = () => {
      const duration = audio.duration
      setDuration(duration)
    }

    audio.addEventListener('timeupdate', updateProgress)
    audio.addEventListener('loadedmetadata', updateDuration)

    return () => {
      audio.removeEventListener('timeupdate', updateProgress)
      audio.removeEventListener('loadedmetadata', updateDuration)
    }
  }, [])
  useEffect(() => {
    // Reset to new audio url
    const audio = audioRef.current
    audio.load()
    audio.play()
  }, [audioUrl])
  const togglePlay = () => {
    const audio = audioRef.current
    if (audio.paused) {
      audio.play()
    } else {
      audio.pause()
    }
  }
  const handleProgressBarClick = e => {
    const audio = audioRef.current
    const clickPosition =
      e.pageX - progressBarRef.current.getBoundingClientRect().left
    const progressBarWidth = progressBarRef.current.offsetWidth
    const currentTime = (clickPosition / progressBarWidth) * audio.duration
    audio.currentTime = currentTime
    setCurrentProgress((currentTime / audio.duration) * 100)
  }
  return (
    <div className="podcast-play-main">
      <div className="podcast-play-main-controls">
        <BackwardOutlined
          onClick={() => {
            skipPlay('BACK')
          }}
          style={{ fontSize: 32, color: '#ffff', margin: '5px' }}
        />
        {currentPosition === duration ? (
          <ReloadOutlined
            onClick={() => {
              togglePlay(true)
            }}
            style={{ fontSize: 38, color: '#ffff', margin: '5px' }}
          />
        ) : audioRef && audioRef.current && !audioRef.current.paused ? (
          <PauseCircleOutlined
            onClick={() => {
              togglePlay(false)
            }}
            style={{ fontSize: 38, color: '#ffff', margin: '5px' }}
          />
        ) : (
          <PlayCircleOutlined
            onClick={() => {
              togglePlay(true)
            }}
            style={{ fontSize: 38, color: '#ffff', margin: '5px' }}
          />
        )}

        <ForwardOutlined
          onClick={() => {
            skipPlay('FORWARD')
          }}
          style={{ fontSize: 32, color: '#ffff', margin: '5px' }}
        />
      </div>
      <div className="podcast-play-main-waves">
        <div className="podcast-play-main-waves-background">
          <audio ref={audioRef} controls={false}>
            <source src={audioUrl} type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>

          <div
            className="progress-bar"
            onClick={handleProgressBarClick}
            ref={progressBarRef}
          >
            <div className="current">{formatTime(currentPosition)}</div>
            <div
              className="progress"
              style={{
                width: `${currentProgress}%`
              }}
            ></div>
            <div className="total">{formatTime(duration)}</div>
          </div>
        </div>
      </div>
      <div className="podcast-play-main-name">{audioName}</div>
    </div>
  )
}
