import { connect } from 'react-redux'
import RegisterForm from './RegisterForm'

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterForm)
