import React, { PureComponent } from 'react'
import moment from 'moment'
import { Col, Icon, Row, Button, Popconfirm, Spin } from 'antd'
import { withTranslation } from 'react-i18next'
import _ from 'lodash'
import { deleteAudio } from '../../../images'
import {
  bytesToSize,
  durationToTime
} from '../../../../../../../../../../utils'
import { config } from '../../../../../../../../../../constants'
import './ShowDetailVideo.scss'
import { api } from '../../../../../../../../../../services'

class ShowDetailVideo extends PureComponent {
  state = {
    showDetail: false,
    loading: false,
    topicList: []
  }

  handleShowDetail = () => {
    this.setState(prevState => ({
      showDetail: !prevState.showDetail
    }))
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data) {
      this.setState(
        {
          topicList: []
        },
        () => {
          this.getTopics()
        }
      )
    }
  }

  componentDidMount() {
    this.getTopics()
  }

  getTopics() {
    const { data } = this.props
    const { topicList } = this.state
    const topics = Object.assign([], data)
    const topicsIds = _.filter(topics, item => item.isSelected)

    if (!_.isEmpty(topicsIds) && !_.isEmpty(topicsIds[0].topics)) {
      this.setState({
        loading: true
      })
      api.topics.getTopicsByIds(topicsIds[0].topics.join()).then(topics => {
        let tempTopics = [...topicList]
        topics.data &&
          topics.data.forEach(topic => {
            tempTopics.push(topic.title)
          })
        this.setState({
          topicList: tempTopics,
          loading: false
        })
      })
    }
  }

  render() {
    const { showDetail, topicList, loading } = this.state
    const { removeCardSelection, videoId, duration, loadingVideo } = this.props
    const { data, t, handleRemoveVideo } = this.props
    let vimeoUrl = ''
    if (videoId !== null) {
      vimeoUrl = config.vimeoUrl + videoId
    } else {
      vimeoUrl = 'Video preview is not available. Please wait a moment...'
    }
    return (
      <>
        {loadingVideo ? (
          <div className="loading">
            <Spin size="small" />
          </div>
        ) : (
          !_.isEmpty(data) &&
          data.map((item, ind) => (
            <div key={ind}>
              {item.isSelected && (
                <div>
                  <section className="action-list">
                    <Row>
                      <Col span={12}>
                        <Icon type="close" onClick={removeCardSelection} />{' '}
                        {t('audio:close')}
                      </Col>
                      <Col span={12}>
                        <ul>
                          <li>
                            <Popconfirm
                              title={t('warnings:delete')}
                              okText={t('general:yes')}
                              cancelText={t('general:no')}
                              icon={false}
                              onConfirm={() => handleRemoveVideo(item.id)}
                            >
                              <img src={deleteAudio} alt="" />
                            </Popconfirm>
                          </li>
                          {/*<li>
                            <img src={editAudioDetail} alt="" />
                          </li>*/}
                        </ul>
                      </Col>
                    </Row>
                  </section>
                  <section className="action-list">
                    {videoId && (
                      <iframe
                        title="iframe title"
                        src={vimeoUrl}
                        width="357"
                        height="283"
                        frameBorder="0"
                        webkitallowfullscreen="true"
                        mozallowfullscreen="true"
                        allowFullScreen
                      />
                    )}
                    {!videoId && vimeoUrl}
                  </section>
                  <section className="action-list audio-detail">
                    <div>
                      <strong>{item.title || item.filename}</strong>
                      <p>
                        {t('video:mp4_video')} - {bytesToSize(item.file_size)}
                      </p>
                      <Row>
                        <Col span={12}>
                          <p>{t('audio:created')}</p>
                        </Col>
                        <Col span={12}>
                          <p>{moment(item.created_at).format('DD MMM YYYY')}</p>
                        </Col>
                        <Col span={12}>
                          <p>{t('audio:duration')}</p>
                        </Col>
                        <Col span={12}>
                          <p>{duration ? durationToTime(duration) : '...'}</p>
                        </Col>
                        {showDetail && (
                          <div>
                            <Col span={12}>
                              <p>{t('audio:topic')}</p>
                            </Col>
                            <Col span={12}>
                              <p>
                                {loading
                                  ? 'loading...'
                                  : topicList.length > 0
                                  ? topicList.join()
                                  : '...'}
                              </p>
                            </Col>

                            <Col span={12}>
                              <p>{t('audio:description')}</p>
                            </Col>
                            <Col span={12}>
                              <p>
                                {item.description ? item.description : '...'}
                              </p>
                            </Col>

                            <Col span={12}>
                              <p>{t('audio:key_words_tag')}</p>
                            </Col>
                            <Col span={12}>
                              <p>{item.keywords ? item.keywords : '...'}</p>
                            </Col>
                          </div>
                        )}
                      </Row>
                      <Button onClick={this.handleShowDetail}>
                        {!showDetail
                          ? t('media:show_more_details')
                          : t('media:show_less_details')}
                      </Button>
                    </div>
                  </section>
                </div>
              )}
            </div>
          ))
        )}
      </>
    )
  }
}

export default withTranslation('audio')(ShowDetailVideo)
