import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { Item } from './internal'
import { Menu } from './styled'

class Sidebar extends Component {
  render () {
    return (
      <Menu>
        {
          this.props.items.map((item, index) => (
            <Menu.Item key={index}>
              <Item
                url={item.url}
                heading={item.heading}
                exact={item.exact}
              />
            </Menu.Item>
          ))
        }
      </Menu>
    )
  }
}

export default withTranslation()(Sidebar)
