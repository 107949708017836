import React, { Component } from 'react'
import { Spin, Select, Tag, Checkbox, Button } from 'antd'
import _ from 'lodash'
import { Page } from './styled'
import { api } from '../../../services'
import { Search } from '../../../components'
export default class ShowContactsModal extends Component {
  state = {
    isFetching: false,
    users: [],
    search: '',
    searchType: 'search',
    inviteList: [],
    isGroupSelected: false,
    selectedGroupId: null
  }

  componentDidMount() {
    this.setUsers()
  }

  componentDidUpdate(prevProps) {
    const { selectedEmails } = this.props
    if (selectedEmails !== prevProps.selectedEmails && selectedEmails) {
      this.setUsers()
    }
  }

  setUsers = () => {
    const {
      groupOptions: { groups },
      selectedEmails
    } = this.props
    this.setState(
      {
        isFetching: true,
        selectedGroupId: groups[0] ? groups[0].title : '',
        users: selectedEmails,
        inviteList: selectedEmails
      },
      () => {
        this.getAllUsers()
      }
    )
    return null
  }

  getAllUsers = () => {
    const { active_organization_id, selectedEmails } = this.props
    api.organizations
      .getUsersByOrganizationId(active_organization_id)
      .then(res => {
        res.data.forEach(val => {
          this.setState(state => {
            if (val.user && !selectedEmails.includes(val.user.email)) {
              const emailList = [...state.users, val.user.email]
              return {
                users: emailList,
                isFetching: false
              }
            }
          })
        })
      })
  }

  onSearch = _.debounce(
    value =>
      this.setState(
        {
          search: value,
          page: 1
        },
        () => {
          this.fetchData(true)
        }
      ),
    1000
  )

  addUser = e => {
    const { value, checked } = e.target
    this.setState(prevState => {
      const prevList = prevState.inviteList
      if (!prevList.includes(value) && checked) {
        return {
          inviteList: [...prevList, value]
        }
      }
      return {
        inviteList: prevList.filter(p => p !== value)
      }
    })
  }

  handleInvite = () => {
    const { inviteList } = this.state
    const { addEmails } = this.props
    addEmails(inviteList)
    // setTimeout(() => {
    //   this.setState({inviteList:[], users: []})
    // }, 2000);
  }

  fetchData = () => {
    const { active_organization_id, selectedEmails } = this.props
    const searchValue = this.state.search
    const searchType = this.state.searchType
    if (searchValue) {
      this.setState({ isFetching: true, users: selectedEmails }, () => {
        return api.organizations
          .searchUsersInOrganization({
            orgId: active_organization_id,
            searchType,
            searchValue
          })
          .then(res => {
            if (res.data) {
              res.data.forEach(val => {
                this.setState(state => {
                  if (val.user && !selectedEmails.includes(val.user.email)) {
                    const emailList = [...state.users, val.user.email]
                    return {
                      users: emailList,
                      isFetching: false,
                      search: ''
                    }
                  }
                })
              })
            }
            return {
              users: selectedEmails,
              isFetching: false,
              search: ''
            }
          })
      })
    }
    if (!searchValue && searchType === 'search') {
      this.setState({ isFetching: true, users: selectedEmails }, () => {
        this.getAllUsers()
      })
    }
  }

  handleSearchType = type => {
    if (type === 'name') {
      this.setState(
        {
          searchType: 'searchByname',
          isGroupSelected: false
        },
        () => this.fetchData()
      )
    } else if (type === 'id') {
      this.setState(
        {
          searchType: 'searchById',
          isGroupSelected: false
        },
        () => this.fetchData()
      )
    } else if (type === 'email') {
      this.setState(
        {
          searchType: 'searchByemail',
          isGroupSelected: false
        },
        () => this.fetchData()
      )
    } else if (type === 'group') {
      const { selectedGroupId } = this.state
      this.setState(
        {
          searchType: 'group',
          isGroupSelected: true,
          search: selectedGroupId
        }
        // () => this.fetchData()
      )
    } else {
      this.setState(
        {
          searchType: 'search',
          isGroupSelected: false,
          isFetching: true,
          users: []
        },
        () => this.getAllUsers()
      )
    }
  }

  handleSearchGroupType = groupId => {
    this.setState(
      {
        search: groupId,
        selectedGroupId: groupId,
        page: 1
      },
      () => {
        this.fetchData(true)
      }
    )
  }

  render() {
    const { isFetching, users, isGroupSelected, inviteList } = this.state
    const { groupOptions, t } = this.props
    return (
      <div>
        <Page>
          <Page.Head.Search>
            <Select
              onChange={this.handleSearchType}
              className="rounded-select"
              defaultValue={'All'}
            >
              <Select.Option value={'All'}>{t('reports:all')}</Select.Option>
              <Select.Option value={'name'}>{t('reports:name')}</Select.Option>
              <Select.Option value={'id'}>
                {t('reports:id_number')}
              </Select.Option>
              <Select.Option value={'email'}>
                {t('reports:email')}
              </Select.Option>
              <Select.Option value={'group'}>{t('v4:group')}</Select.Option>
            </Select>
            {isGroupSelected && (
              <Select
                onChange={this.handleSearchGroupType}
                className="rounded-select"
                placeholder="Select a group"
                defaultValue={'all'}
              >
                <Select.Option value={'all'}>
                  {t('v4:select_group')}
                </Select.Option>
                {groupOptions.groups.map(g => (
                  <Select.Option value={g.title}>{g.title}</Select.Option>
                ))}
              </Select>
            )}
            <Search
              placeholder={t('placeholders:search')}
              onChange={e => this.onSearch(e.target.value)}
            />
          </Page.Head.Search>
          <Page.Container>
            <Spin spinning={isFetching}>
              {users && users.length > 0 ? (
                users.map(u => (
                  <Tag key={`user_${u}`}>
                    <Checkbox
                      onChange={this.addUser}
                      value={u}
                      checked={inviteList.includes(u)}
                    >
                      {u}
                    </Checkbox>
                  </Tag>
                ))
              ) : (
                <div>{t('v2:no_user_found')}</div>
              )}
            </Spin>
          </Page.Container>
          <div className="wrap_btn_actions">
            <Button
              type="primary"
              size="large"
              className="action_button"
              disabled={isFetching}
              onClick={() => this.handleInvite()}
            >
              {t('v2:invite')}
            </Button>
          </div>
        </Page>
      </div>
    )
  }
}
