/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-08-20 11:09:06
 * @LastEditTime: 2019-08-29 19:22:05
 * @LastEditors: Please set LastEditors
 */
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import CloneModal from './CloningModal'
import actions from '../../store/actions'

const mapStateToProps = state => ({
  user: state.user,
  courseCloningProgress: state.courses.courseCloningProgress
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      initCloneSocket: actions.courses.initCloneSocket,
      showPopover: actions.courses.showPopover,
      clearCourseCloning: actions.courses.clearCourseCloning,
      upload: actions.library.upload
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(CloneModal)
