import React, { useEffect, useState } from 'react'
import { SaveOutlined } from '@ant-design/icons'
import { withTranslation } from 'react-i18next'
import history from '../../../history'
import {
  Row,
  Col,
  Button,
  Input,
  Form,
  Radio,
  Divider,
  message,
  Spin,
  Select
} from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'
import axios from 'axios'
import './styles.scss'
const { TextArea } = Input
const { Option } = Select

const TriviaAddEdit = props => {
  const { id, triviaActions, getCategories, t } = props
  const { getFieldDecorator, setFieldsValue } = props.form
  const [imageFile, setImageFile] = useState({
    question_image: {},
    question_image_url: '',
    answer_image: {},
    answer_image_url: ''
  })
  const [loading, setLoading] = useState(false)
  const [categoryList, setCategoryList] = useState([])
  const [isFetching, setIsFetching] = useState(false)

  useEffect(() => {
    fetchCategories()
  }, [])
  useEffect(() => {
    if (id) {
      getData(id)
    }
  }, [id])
  const fetchCategories = async () => {
    await getCategories().then(data => {
      setCategoryList(data)
    })
  }
  const getData = async id => {
    try {
      setIsFetching(true)
      let res = await triviaActions.fetchQuestionDetail(id)
      if (res.status < 300) {
        let data = res.data
        setImageFile({
          ...imageFile,
          question_image_url: data.main_image_url,
          answer_image_url: data.clarification_image_url
        })
        setFieldsValue({
          question: data.question,
          categories:
            data.categories.length > 0 ? data.categories.map(el => el.id) : [],
          option_one: data.options[0],
          option_two: data.options[1],
          option_three: data.options[2],
          option_four: data.options[3],
          answer_option:
            data.options.findIndex(option => data.correct_option === option) +
            1,
          question_image: res.data.main_image,
          answer_image: res.data.clarification_image
        })
      }
    } catch (error) {
      console.error('trivia Error fetching trivia detail:', error)
    }
    setIsFetching(false)
  }
  const handleSubmit = async e => {
    e.preventDefault()
    props.form.validateFields(async (err, values) => {
      if (!err) {
        setLoading(true)
        let main_image = ''
        let clarification_image = ''
        let tempImageData = [
          {
            isPublic: true,
            filename: imageFile.question_image.name,
            type: imageFile.question_image.type
          },
          {
            isPublic: true,
            filename: imageFile.answer_image.name,
            type: imageFile.answer_image.type
          }
        ]
        async function uploadImages() {
          for (let [i, element] of tempImageData.entries()) {
            try {
              if (i === 0 && element.filename !== '') {
                let image = element.filename ? await getLocation(element) : null
                main_image = image ? image.key : values.question_image
                element.filename && (await uploadImage(image, i))
              } else if (i === 1 && element.filename !== '') {
                let image = element.filename ? await getLocation(element) : null
                clarification_image = image ? image.key : values.answer_image
                element.filename && (await uploadImage(image, i))
              }
            } catch (error) {
              message.error('Error uploading question image!')
              setLoading(false)
              return
            }
          }

          //(creating list for question and submitting it)
          let payload = {
            ...(id && { id: id }),
            question: values.question,
            options: [
              values.option_one,
              values.option_two,
              values.option_three,
              values.option_four
            ],
            categories: values.categories,
            clarification_image: clarification_image,
            main_image: main_image,
            correct_option:
              values.answer_option === 1
                ? values.option_one
                : values.answer_option === 2
                ? values.option_two
                : values.answer_option === 3
                ? values.option_three
                : values.answer_option === 4
                ? values.option_four
                : ''
          }
          if (id) {
            try {
              let res = await triviaActions.updateQuitz(payload)
              if (res.status < 300) {
                history.push('/share-sphere/list')
                message.success('ShareSphere question updated')
              }
            } catch (error) {
              message.error('Error updating question!')
            }
          } else {
            try {
              let res = await triviaActions.createQuitz(payload)
              if (res.status < 300) {
                history.push('/share-sphere/list')
                message.success('ShareSphere question created')
              }
            } catch (error) {
              message.error('Error creating question!')
            }
          }
        }

        async function getLocation(data) {
          let res = await triviaActions.getBucketNames(data)
          if (res.status < 300) {
            return res.data
          } else {
            throw new Error('Failed to get image location')
          }
        }

        async function uploadImage(image, i) {
          const opts = {
            headers: {
              'Content-Type':
                i === 0
                  ? imageFile.question_image.type
                  : imageFile.answer_image.type
            }
          }
          let newFile = ''
          if (i === 0) {
            newFile = imageFile.question_image
          } else {
            newFile = imageFile.answer_image
          }
          try {
            await axios.put(image.link, newFile, opts)
          } catch (error) {
            message.error('Error uploading file!')
            throw new Error('Error uploading file')
          }
        }
        await uploadImages()
        setLoading(false)
      }
    })
  }
  const navigateTo = type => {
    history.goBack()
  }
  const addFile = id => {
    document.getElementById(id).click()
  }
  const uploadFile = async (file, id) => {
    let reader = new FileReader()
    reader.onload = e => {
      if (e.target && e.target.result) {
        if (id === 'question_image') {
          setImageFile({
            ...imageFile,
            question_image: file,
            question_image_url: e.target.result
          })
          setFieldsValue({ question_image: file.name })
        } else if (id === 'answer_image') {
          setImageFile({
            ...imageFile,
            answer_image: file,
            answer_image_url: e.target.result
          })
          setFieldsValue({ answer_image: file.name })
        }
      }
    }
    reader.readAsDataURL(file)
  }
  const changeImage = (e, id) => {
    let file = e.target.files[0]
    if (file.type !== 'image/png' && file.type !== 'image/jpeg') {
      message.error('Please select a valid image.')
      return
    }
    let img = new Image()
    img.onload = () => {
      uploadFile(file, id)
      // let { naturalWidth, naturalHeight } = img
      // let aspectRatio = naturalHeight / naturalWidth
      // let tolerance = 0.1 // Tolerance range of 0.1
      // if (Math.abs(aspectRatio - 1.5) <= tolerance) {
      //   uploadFile(file, id)
      // } else {
      //   setMessage({
      //     ...message,
      //     status: true,
      //     text: 'Image dimensions should be of 650x1000 pixels'
      //   })
      // }
    }

    img.onerror = () => {
      message.error('Failed to load the image. Please try again!')
      return
    }
    img.src = URL.createObjectURL(file)
    e.target.value = ''
  }
  const removeImage = id => {
    if (id === 'question_image') {
      setImageFile({
        ...imageFile,
        question_image: null,
        question_image_url: ''
      })
      setFieldsValue({ question_image: '' })
    } else if (id === 'answer_image') {
      setImageFile({
        ...imageFile,
        answer_image: null,
        answer_image_url: ''
      })
      setFieldsValue({ answer_image: '' })
    }
  }
  return (
    <Spin spinning={isFetching}>
      <div className="add-wrapper">
        <div className="trivia">
          <section className="section header">
            <Row type="flex" justify="center">
              <Col md={24}>
                <div className="title">{t('v3:share_sphere')}</div>
              </Col>
            </Row>
          </section>
          <section className="section question">
            <Row gutter={[16, 16]}>
              <Col md={24}>
                <div className="question_body">
                  <Form
                    onSubmit={handleSubmit}
                    layout="vertical"
                    style={{
                      width: '100%'
                    }}
                  >
                    <Form.Item
                      name="question"
                      label={
                        <span
                          style={{
                            fontSize: '16px',
                            fontWeight: '600',
                            textTransform: 'capitalize',
                            color: '#000000A6'
                          }}
                        >
                          {t('reports:question')}
                        </span>
                      }
                      required
                    >
                      {getFieldDecorator('question', {
                        rules: [
                          {
                            required: true,
                            message: 'Please input your question!'
                          }
                        ]
                      })(
                        <TextArea rows={2} placeholder="Enter your question" />
                      )}
                    </Form.Item>

                    <div className="question_body_title">
                      {t('v3:select_question')}
                    </div>
                    <Form.Item required name="answer_option">
                      {getFieldDecorator('answer_option', {
                        rules: [
                          {
                            required: true,
                            message: t('v3:select_answer')
                          }
                        ]
                      })(
                        <Radio.Group
                          style={{ textAlign: 'left', width: '100%' }}
                        >
                          <div className="question_body_context">
                            <div className="question_body_context_left">
                              <Radio value={1} />
                            </div>
                            <div className="question_body_context_right">
                              <Form.Item required name="option_one">
                                {getFieldDecorator('option_one', {
                                  rules: [
                                    {
                                      required: true,
                                      message: t('v3:check_option_one')
                                    }
                                  ]
                                })(
                                  <TextArea
                                    rows={2}
                                    placeholder="option 1"
                                    style={{ width: '100%' }}
                                  />
                                )}
                              </Form.Item>
                            </div>
                          </div>
                          <div className="question_body_context">
                            <div className="question_body_context_left">
                              <Radio value={2} />
                            </div>
                            <div className="question_body_context_right">
                              <Form.Item required name="option_two">
                                {getFieldDecorator('option_two', {
                                  rules: [
                                    {
                                      required: true,
                                      message: t('v3:check_option_two')
                                    }
                                  ]
                                })(
                                  <TextArea
                                    rows={2}
                                    placeholder="option 2"
                                    style={{ width: '100%' }}
                                  />
                                )}
                              </Form.Item>
                            </div>
                          </div>
                          <div className="question_body_context">
                            <div className="question_body_context_left">
                              <Radio value={3} />
                            </div>
                            <div className="question_body_context_right">
                              <Form.Item required name="option_three">
                                {getFieldDecorator('option_three', {
                                  rules: [
                                    {
                                      required: true,
                                      message: t('v3:check_option_three')
                                    }
                                  ]
                                })(
                                  <TextArea
                                    rows={2}
                                    placeholder="option 3"
                                    style={{ width: '100%' }}
                                  />
                                )}
                              </Form.Item>
                            </div>
                          </div>
                          <div className="question_body_context">
                            <div className="question_body_context_left">
                              <Radio value={4} />
                            </div>
                            <div className="question_body_context_right">
                              <Form.Item required name="option_four">
                                {getFieldDecorator('option_four', {
                                  rules: [
                                    {
                                      required: true,
                                      message: t('v3:check_option_four')
                                    }
                                  ]
                                })(
                                  <TextArea
                                    rows={2}
                                    placeholder="option 4"
                                    style={{ width: '100%' }}
                                  />
                                )}
                              </Form.Item>
                            </div>
                          </div>
                        </Radio.Group>
                      )}
                    </Form.Item>
                    <Form.Item required name="categories">
                      {getFieldDecorator('categories', {
                        rules: [
                          {
                            required: true,
                            message: 'Please select category(s)!'
                          }
                        ]
                      })(
                        <Select
                          mode="multiple"
                          style={{ width: '100%' }}
                          placeholder="Select category(s)"
                          // onChange={handleChange}
                        >
                          {categoryList.map(item => (
                            <Option key={item.id} value={item.id}>
                              {item.name}
                            </Option>
                          ))}
                        </Select>
                      )}
                    </Form.Item>
                    <Divider />
                    <Row
                      type="flex"
                      justify="center"
                      align="middle"
                      gutter={[8, 8]}
                    >
                      <Col
                        xs={24}
                        sm={12}
                        md={12}
                        style={{
                          borderRight: '2px solid lightgrey',
                          display: 'flex',
                          justifyContent: 'center'
                        }}
                      >
                        <input
                          type="file"
                          id="question_image"
                          style={{ display: 'none' }}
                          onChange={e => {
                            changeImage(e, 'question_image')
                          }}
                        />
                        <div className="questionimg">
                          <div className="imgtitle">
                            {t('v3:question_image')}
                          </div>
                          {imageFile.question_image_url ? (
                            <div className="imginput">
                              <div>
                                <img
                                  src={imageFile.question_image_url}
                                  alt="questionimg"
                                />
                              </div>
                              <CloseCircleOutlined
                                className="removebtn"
                                onClick={() => {
                                  removeImage('question_image')
                                }}
                              />
                            </div>
                          ) : (
                            <div
                              className="imginput"
                              onClick={() => {
                                addFile('question_image')
                              }}
                            >
                              <div>
                                <div>+</div>
                                <div>{t('buttons:upload_image')}</div>
                              </div>
                            </div>
                          )}
                          <Form.Item required name="question_image">
                            {getFieldDecorator('question_image', {
                              rules: [
                                {
                                  required: true,
                                  message: t('v3:select_question_image')
                                }
                              ]
                            })(<></>)}
                          </Form.Item>
                        </div>
                      </Col>
                      <Col
                        xs={24}
                        sm={12}
                        md={12}
                        style={{
                          display: 'flex',
                          justifyContent: 'center'
                        }}
                      >
                        <input
                          type="file"
                          id="answer_image"
                          style={{ display: 'none' }}
                          onChange={e => {
                            changeImage(e, 'answer_image')
                          }}
                        />
                        <div className="ansimg">
                          <div className="imgtitle">{t('v3:ans_image')}</div>
                          {imageFile.answer_image_url ? (
                            <div className="imginput">
                              <div>
                                <img
                                  src={imageFile.answer_image_url}
                                  alt="ansimage"
                                />
                              </div>
                              <CloseCircleOutlined
                                className="removebtn"
                                style={{
                                  color: 'red'
                                }}
                                onClick={() => {
                                  removeImage('answer_image')
                                }}
                              />
                            </div>
                          ) : (
                            <div
                              className="imginput"
                              onClick={() => {
                                addFile('answer_image')
                              }}
                            >
                              <div>
                                <div>+</div>
                                <div>{t('v3:upload_image_question')}</div>
                              </div>
                            </div>
                          )}
                          <Form.Item required name="answer_image">
                            {getFieldDecorator('answer_image', {
                              rules: [
                                {
                                  required: true,
                                  message: t('v3:select_image_answer')
                                }
                              ]
                            })(<></>)}
                          </Form.Item>
                        </div>
                      </Col>
                    </Row>
                    <Divider />
                    <Form.Item>
                      <Button
                        type="default"
                        disabled={loading}
                        onClick={() => {
                          navigateTo('back')
                        }}
                        style={{ borderRadius: 25 }}
                      >
                        {t('buttons:cancel')}
                      </Button>
                      <Button
                        loading={loading}
                        type="primary"
                        htmlType="submit"
                        style={{ margin: '10px 10px', borderRadius: 25 }}
                      >
                        {!loading && <SaveOutlined />} {t('v3:save_question')}
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
              </Col>
            </Row>
          </section>
        </div>
      </div>
    </Spin>
  )
}
export default Form.create()(withTranslation('trivia')(TriviaAddEdit))
