import React, { useEffect, useState } from 'react'
import { Alert, Button, Divider, Modal, Result, message } from 'antd'
import nanoid from 'nanoid'
import { useDispatch } from 'react-redux'

import Layout1 from '../img/layout-1.svg'
import Layout2 from '../img/layout-2.svg'
import Layout3 from '../img/layout-3.svg'
import Layout4 from '../img/layout-4.svg'
import Layout5 from '../img/layout-5.svg'
import Layout6 from '../img/layout-6.svg'
import Layout7 from '../img/layout-7.svg'
import Layout8 from '../img/layout-8.svg'
import Layout9 from '../img/layout-9.svg'
import Layout10 from '../img/layout-10.svg'
import Layout11 from '../img/layout-11.svg'
import Layout12 from '../img/layout-12.svg'

import actions from '../../../../../../../../store/actions'
import { useTranslation } from 'react-i18next'

const layoutData = [
  {
    img: Layout1,
    layoutStyle: 'layout-1'
  },
  {
    img: Layout2,
    layoutStyle: 'layout-2'
  },
  {
    img: Layout3,
    layoutStyle: 'layout-3'
  },
  {
    img: Layout4,
    layoutStyle: 'layout-4'
  },
  {
    img: Layout5,
    layoutStyle: 'layout-5'
  },
  {
    img: Layout6,
    layoutStyle: 'layout-6'
  },
  {
    img: Layout7,
    layoutStyle: 'layout-7'
  },
  {
    img: Layout8,
    layoutStyle: 'layout-8'
  },
  {
    img: Layout9,
    layoutStyle: 'layout-9'
  },
  {
    img: Layout10,
    layoutStyle: 'layout-10'
  },
  {
    img: Layout11,
    layoutStyle: 'layout-11'
  },
  {
    img: Layout12,
    layoutStyle: 'layout-12'
  }
]

const LayoutOptions = props => {
  const { t } = useTranslation()
  const [selectedLayout, setSelectedLayout] = useState(null)
  const [showLayoutModal, setShowLayoutModal] = useState({
    visible: false,
    layoutStyle: null
  })

  const { currentPage, pageStructureData, lessonUpdate, isUpdating } = props

  const dispatch = useDispatch()

  useEffect(() => {
    const currentPageData = pageStructureData[currentPage - 1]
    if (!currentPageData || !selectedLayout) return

    let updatedPageData = currentPageData

    const textData = getCurrentPageTextData(currentPageData)

    const [firstPart, secondPart] = splitParagraph(textData)

    switch (selectedLayout) {
      case 'layout-1':
        // two  columns with two images on top
        updatedPageData = {
          ...currentPageData,
          data: currentPageData.data.filter(item => item.display === 'HEADING')
        }
        updatedPageData.data.push({
          uid: nanoid(8),
          type: 'TEXT',
          display: 'CONTENT',
          params: {
            content: firstPart,
            height: 254,
            media_format: 'TEXT',
            width: 510,
            x: 0,
            y: 298
          }
        })

        updatedPageData.data.push({
          uid: nanoid(8),
          type: 'TEXT',
          display: 'CONTENT',
          params: {
            content: secondPart,
            height: 254,
            media_format: 'TEXT',
            width: 510,
            x: 513,
            y: 298
          }
        })

        updatedPageData.data.push({
          uid: nanoid(8),
          type: 'IMAGE',
          params: {
            content: '',
            height: 240,
            media_format: 'IMAGE',
            width: 513,
            x: 0,
            y: 58
          }
        })

        updatedPageData.data.push({
          uid: nanoid(8),
          type: 'IMAGE',
          params: {
            content: '',
            height: 240,
            media_format: 'IMAGE',
            width: 513,
            x: 513,
            y: 58
          }
        })

        break

      case 'layout-2':
        // three images on top and then one paragraph of content on bottom
        updatedPageData = {
          ...currentPageData,
          data: currentPageData.data.filter(item => item.display === 'HEADING')
        }
        updatedPageData.data.push({
          uid: nanoid(8),
          type: 'TEXT',
          display: 'CONTENT',
          params: {
            content: textData,
            height: 254,
            media_format: 'TEXT',
            width: 1023,
            x: 0,
            y: 298
          }
        })

        updatedPageData.data.push({
          uid: nanoid(8),
          type: 'IMAGE',
          params: {
            content: '',
            height: 240,
            media_format: 'IMAGE',
            width: 341,
            x: 0,
            y: 58
          }
        })

        updatedPageData.data.push({
          uid: nanoid(8),
          type: 'IMAGE',
          params: {
            content: '',
            height: 240,
            media_format: 'IMAGE',
            width: 341,
            x: 341,
            y: 58
          }
        })

        updatedPageData.data.push({
          uid: nanoid(8),
          type: 'IMAGE',
          params: {
            content: '',
            height: 240,
            media_format: 'IMAGE',
            width: 341,
            x: 682,
            y: 58
          }
        })
        break

      case 'layout-3':
        // two  columns of only text without any images
        updatedPageData = {
          ...currentPageData,
          data: currentPageData.data.filter(item => item.display === 'HEADING')
        }
        updatedPageData.data.push({
          uid: nanoid(8),
          type: 'TEXT',
          display: 'CONTENT',
          params: {
            content: firstPart,
            height: 494,
            media_format: 'TEXT',
            width: 510,
            x: 0,
            y: 58
          }
        })

        updatedPageData.data.push({
          uid: nanoid(8),
          type: 'TEXT',
          display: 'CONTENT',
          params: {
            content: secondPart,
            height: 494,
            media_format: 'TEXT',
            width: 510,
            x: 513,
            y: 58
          }
        })

        break

      case 'layout-4':
        // one column with image on top and text content on bottom
        updatedPageData = {
          ...currentPageData,
          data: currentPageData.data.filter(item => item.display === 'HEADING')
        }
        updatedPageData.data.push({
          uid: nanoid(8),
          type: 'TEXT',
          display: 'CONTENT',
          params: {
            content: textData,
            height: 254,
            media_format: 'TEXT',
            width: 1023,
            x: 0,
            y: 298
          }
        })

        updatedPageData.data.push({
          uid: nanoid(8),
          type: 'IMAGE',
          params: {
            content: '',
            height: 240,
            media_format: 'IMAGE',
            width: 1023,
            x: 0,
            y: 58
          }
        })
        break

      case 'layout-5':
        // three rows
        // - Heading Text full row
        // - Second row of 2 columns of images
        // -Third row of 1 column of text full row
        updatedPageData = {
          ...currentPageData,
          data: currentPageData.data.filter(item => item.display === 'HEADING')
        }
        updatedPageData.data.push({
          uid: nanoid(8),
          type: 'IMAGE',
          params: {
            content: '',
            height: 240,
            media_format: 'IMAGE',
            width: 511.5,
            x: 0,
            y: 58
          }
        })

        updatedPageData.data.push({
          uid: nanoid(8),
          type: 'IMAGE',
          params: {
            content: '',
            height: 240,
            media_format: 'IMAGE',
            width: 511.5,
            x: 511,
            y: 58
          }
        })

        updatedPageData.data.push({
          uid: nanoid(8),
          type: 'TEXT',
          display: 'CONTENT',
          params: {
            content: textData,
            height: 254,
            media_format: 'TEXT',
            width: 1023,
            x: 0,
            y: 298
          }
        })
        break

      case 'layout-6':
        // three rows
        // - Heading Text full row
        // - Second row of 2 columns of text and image where text is  bigger width than image
        // -Third row of 1 column of text full row
        updatedPageData = {
          ...currentPageData,
          data: currentPageData.data.filter(item => item.display === 'HEADING')
        }
        updatedPageData.data.push({
          uid: nanoid(8),
          type: 'TEXT',
          display: 'CONTENT',
          params: {
            content: firstPart,
            height: 240,
            media_format: 'TEXT',
            width: 682,
            x: 0,
            y: 58
          }
        })

        updatedPageData.data.push({
          uid: nanoid(8),
          type: 'IMAGE',
          params: {
            content: '',
            height: 240,
            media_format: 'IMAGE',
            width: 341,
            x: 682,
            y: 58
          }
        })

        updatedPageData.data.push({
          uid: nanoid(8),
          type: 'TEXT',
          display: 'CONTENT',
          params: {
            content: secondPart,
            height: 254,
            media_format: 'TEXT',
            width: 1023,
            x: 0,
            y: 298
          }
        })

        break

      case 'layout-7':
        // three rows
        // - Heading Text full row
        // - Two annotation columns on left
        // - One image column on right
        updatedPageData = {
          ...currentPageData,
          data: currentPageData.data.filter(item => item.display === 'HEADING')
        }
        updatedPageData.data.push({
          uid: nanoid(8),
          type: 'ANNOTATION',
          params: {
            content: '',
            height: 240,
            width: 513,
            x: 0,
            y: 58
          }
        })

        updatedPageData.data.push({
          uid: nanoid(8),
          type: 'TEXT',
          display: 'CONTENT',
          params: {
            content: textData,
            height: 254,
            width: 513,
            media_format: 'TEXT',
            x: 0,
            y: 298
          }
        })

        updatedPageData.data.push({
          uid: nanoid(8),
          type: 'IMAGE',
          params: {
            content: '',
            media_format: 'IMAGE',
            height: 494,
            width: 510,
            x: 513,
            y: 58
          }
        })
        break

      case 'layout-8':
        // three rows
        // - Heading Text full row
        // - One video row which is bigger in height
        // - One text row on the bottom
        updatedPageData = {
          ...currentPageData,
          data: currentPageData.data.filter(item => item.display === 'HEADING')
        }
        updatedPageData.data.push({
          uid: nanoid(8),
          type: 'VIDEO',
          params: {
            content: '',
            height: 400,
            width: 1023,
            media_format: 'VIDEO',
            x: 0,
            y: 58
          }
        })

        updatedPageData.data.push({
          uid: nanoid(8),
          type: 'TEXT',
          display: 'CONTENT',
          params: {
            content: textData,
            height: 94,
            media_format: 'TEXT',
            width: 1023,
            x: 0,
            y: 458
          }
        })
        break

      case 'layout-9':
        // three rows
        // - Heading Text full row
        // - One text column on left
        // - Two image columns on right
        updatedPageData = {
          ...currentPageData,
          data: currentPageData.data.filter(item => item.display === 'HEADING')
        }
        updatedPageData.data.push({
          uid: nanoid(8),
          type: 'TEXT',
          display: 'CONTENT',
          params: {
            content: textData,
            height: 494,
            media_format: 'TEXT',
            width: 511,
            x: 0,
            y: 58
          }
        })

        updatedPageData.data.push({
          uid: nanoid(8),
          type: 'IMAGE',
          params: {
            content: '',
            height: 240,
            media_format: 'IMAGE',
            width: 511,
            x: 511,
            y: 58
          }
        })

        updatedPageData.data.push({
          uid: nanoid(8),
          type: 'IMAGE',
          params: {
            content: '',
            height: 254,
            media_format: 'IMAGE',
            width: 511,
            x: 511,
            y: 298
          }
        })
        break

      case 'layout-10':
        // three rows
        // - Heading Text full row
        // - Two annotation columns on left
        // - Two annotation columns on right
        updatedPageData = {
          ...currentPageData,
          data: currentPageData.data.filter(item => item.display === 'HEADING')
        }
        updatedPageData.data.push({
          uid: nanoid(8),
          type: 'TEXT',
          display: 'CONTENT',
          params: {
            content: firstPart,
            height: 240,
            width: 511,
            media_format: 'TEXT',

            x: 0,
            y: 58
          }
        })

        updatedPageData.data.push({
          uid: nanoid(8),
          type: 'TEXT',
          display: 'CONTENT',
          params: {
            content: secondPart,
            x: 511,
            y: 58,
            media_format: 'TEXT',

            height: 240,
            width: 511
          }
        })

        updatedPageData.data.push({
          uid: nanoid(8),
          type: 'ANNOTATION',
          params: {
            content: '',
            height: 254,
            width: 511,
            x: 0,
            y: 298
          }
        })

        updatedPageData.data.push({
          uid: nanoid(8),
          type: 'ANNOTATION',
          params: {
            content: '',
            height: 254,
            width: 511,
            x: 511,
            y: 298
          }
        })
        break

      case 'layout-11':
        // three rows
        // - Heading Text full row
        // - Two columns: left column text and right column image
        updatedPageData = {
          ...currentPageData,
          data: currentPageData.data.filter(item => item.display === 'HEADING')
        }
        updatedPageData.data.push({
          uid: nanoid(8),
          type: 'TEXT',
          display: 'CONTENT',
          params: {
            content: textData,
            height: 494,
            media_format: 'TEXT',
            width: 510,
            x: 0,
            y: 58
          }
        })

        updatedPageData.data.push({
          uid: nanoid(8),
          type: 'IMAGE',
          params: {
            content: '',
            height: 494,
            media_format: 'IMAGE',
            width: 510,
            x: 510,
            y: 58
          }
        })
        break

      case 'layout-12':
        // three rows
        // - Heading Text full row
        // - Second & third row, both of 2 columns. Left column with small width image and right column with big width text
        updatedPageData = {
          ...currentPageData,
          data: currentPageData.data.filter(item => item.display === 'HEADING')
        }
        updatedPageData.data.push({
          uid: nanoid(8),
          type: 'IMAGE',
          params: {
            content: '',
            height: 240,
            media_format: 'IMAGE',
            width: 310,
            x: 0,
            y: 58
          }
        })

        updatedPageData.data.push({
          uid: nanoid(8),
          type: 'TEXT',
          params: {
            content: textData,
            height: 240,
            width: 713,
            media_format: 'TEXT',

            x: 310,
            y: 58
          }
        })

        updatedPageData.data.push({
          uid: nanoid(8),
          type: 'IMAGE',
          params: {
            content: '',
            height: 254,
            media_format: 'IMAGE',
            width: 310,
            x: 0,
            y: 298
          }
        })

        updatedPageData.data.push({
          uid: nanoid(8),
          type: 'TEXT',
          display: 'CONTENT',
          params: {
            content: textData,
            height: 254,
            width: 713,
            media_format: 'TEXT',

            x: 310,
            y: 298
          }
        })
        break

      default:
        break
    }

    const updatedData = [...pageStructureData]
    updatedData[currentPage - 1] = updatedPageData

    const { handleLessonInput } = actions.lessonBuilder
    dispatch(handleLessonInput('pages_landscape', updatedData))
    lessonUpdate({ pages_landScape: updatedData })
  }, [selectedLayout])

  const getCurrentPageTextData = (currentPageData = {}) => {
    const pageData = currentPageData.data

    if (pageData) {
      const totalElements = pageData.filter(
        item => item.display === 'CONTENT' && item.type === 'TEXT'
      )

      if (totalElements.length > 1) {
        const combinedText = totalElements
          .map(item => item.params.content)
          .join(' ')
        return combinedText
      }

      return (totalElements[0] && totalElements[0].params.content) || ''
    }

    return ''
  }

  function splitParagraph(paragraph) {
    if (!paragraph) return ['', '']
    // Splitting the paragraph into an array of sentences
    const sentences = paragraph.split(/(?<=\.|\?|\!)\s/)

    // Calculating the midpoint index
    const midpoint = Math.ceil(sentences.length / 2)

    // Joining the first half of the sentences into a string
    const firstHalf = sentences.slice(0, midpoint).join(' ') || ''

    // Joining the second half of the sentences into a string
    const secondHalf = sentences.slice(midpoint).join(' ') || ''

    // Returning the first and second halves as an array
    return [firstHalf, secondHalf]
  }

  const handleSelectLayout = layout => {
    if (isUpdating) return

    const currentPageData = pageStructureData[currentPage - 1]

    const pageHasScenario =
      currentPageData &&
      currentPageData.data.some(node => node.type === 'SCENARIO')

    if (pageHasScenario) {
      message.error(
        'You cannot change layout in a scenario page. Please try other pages or remove the scenario from the page first.',
        10
      )
      return
    }

    setShowLayoutModal({
      visible: true,
      layoutStyle: layout
    })
  }

  const onConfirmLayoutChange = () => {
    setSelectedLayout(showLayoutModal.layoutStyle)
    setShowLayoutModal(pre => ({
      ...pre,
      visible: false
    }))
  }

  return (
    <div className="layout-options-container">
      <Divider orientation="left" className="divider-title">
        Layout
      </Divider>

      <div className="layout-options">
        {layoutData.map(layout => (
          <img
            className={`${
              selectedLayout === layout.layoutStyle ? 'layout-selected' : ''
            } layout-img`}
            src={layout.img}
            alt=""
            onClick={() => handleSelectLayout(layout.layoutStyle)}
          />
        ))}
      </div>

      <Modal
        visible={showLayoutModal.visible}
        onCancel={() =>
          setShowLayoutModal({ visible: false, layoutStyle: null })
        }
        footer={null}
        centered
      >
        <Result
          status="warning"
          title={t('v4:warning')}
          subTitle={t('v4:change_layout_page')}
          extra={[
            <Button
              key="back"
              onClick={() =>
                setShowLayoutModal({ visible: false, layoutStyle: null })
              }
            >
              {t('v4:cancel')}
            </Button>,
            <Button type="primary" key="submit" onClick={onConfirmLayoutChange}>
              {t('v3:confirm_change')}
            </Button>
          ]}
        >
          <Alert type="info" message={t('v4:change_layout_message')} />
        </Result>
      </Modal>
    </div>
  )
}

export default LayoutOptions
