import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import LessonsPagination from './LessonsPagination'

import * as lessonBuilderActions from '../../../../../store/actions/lessonBuilder'

export default connect(
  (state) => ({
    lesson: state.lessonBuilder,
    uploadFetching: state.library.uploadFetching
  }),
  (dispatch) => ({
    lessonBuilderActions: bindActionCreators(lessonBuilderActions, dispatch)
  })
)(LessonsPagination)
