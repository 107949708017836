import React, { Component } from 'react'
import ReactPlayer from 'react-player'
import './Graph.scss'
import moment from 'moment'
import 'moment-duration-format'
import { api } from '../../../../../services'
import className from 'classnames'

export default class Graph extends Component {
  state = {
    duration: 0,
    played: 0,
    playedSeconds: 0,

    data: [],
    activePoint: null
  }

  componentDidMount () {
    const { id } = this.props
    api.assessments.getEmotionsById(id).then(({ data }) => {
      this.setState({
        data
      })
    })
  }

  onProgress = state => {
    this.setState({
      played: state.played,
      playedSeconds: state.playedSeconds
    })
  }

  onDuration = state => {
    this.setState({
      duration: state
    })
  }

  render () {
    const { link } = this.props
    const { played, playedSeconds, duration, data, activePoint } = this.state

    const timeStart = moment
      .duration(0, 'seconds')
      .format('mm:ss', { trim: false })
    const timeEnd = moment
      .duration(duration, 'seconds')
      .format('mm:ss', { trim: false })
    const timePlayed = moment
      .duration(playedSeconds, 'seconds')
      .format('mm:ss', { trim: false })

    const pointsCount = Math.ceil(duration / 5)

    return (
      <div className='b-graph'>
        <div className='b-graph__video'>
          <div className='video'>
            <ReactPlayer
              className='video__player'
              url={link}
              width='100%'
              height='100%'
              onProgress={this.onProgress}
              onDuration={this.onDuration}
              progressInterval={10}
            />
          </div>
        </div>

        <div
          className='b-graph__progress'
          data-time-start={timeStart}
          data-time-end={timeEnd}
        >
          <div
            className='b-graph__progress-line'
            style={{ width: played * 100 + '%' }}
          />
        </div>

        {duration > 0 && (
          <div className='b-graph__data'>
            <div
              className='b-graph__cursor'
              style={{ left: played * 100 + '%' }}
              data-time-played={timePlayed}
              data-points={pointsCount}
            />

            {activePoint && (
              <div
                className={className({
                  'b-graph__box': true,
                  'b-graph__box_active': activePoint
                })}
                style={{
                  top: `${activePoint.y - 30}px`,
                  left: `${activePoint.x + 20}px`
                }}
              >
                <div className='b-graph__box-emotion b-graph__box-emotion_happy'>
                  {activePoint.data.emotions[4]}
                </div>
                <div className='b-graph__box-emotion b-graph__box-emotion_smile'>
                  {activePoint.data.emotions[3]}
                </div>
                <div className='b-graph__box-emotion b-graph__box-emotion_neutral'>
                  {activePoint.data.emotions[2]}
                </div>
                <div className='b-graph__box-emotion b-graph__box-emotion_sad'>
                  {activePoint.data.emotions[1]}
                </div>
                <div className='b-graph__box-emotion b-graph__box-emotion_angry'>
                  {activePoint.data.emotions[0]}
                </div>
              </div>
            )}

            <div className='b-graph__chart'>
              <div className='b-graph__chart-line'></div>

              {[...Array(pointsCount).keys()].map((point, index) => {
                const isPoint = data.find(point => point.time === index * 5)
                return (
                  <div
                    className={className({
                      'b-graph__chart-point': true,
                      'b-graph__chart-point_1': isPoint && isPoint.level === 1,
                      'b-graph__chart-point_2': isPoint && isPoint.level === 2,
                      'b-graph__chart-point_3': isPoint && isPoint.level === 3,
                      'b-graph__chart-point_4': isPoint && isPoint.level === 4,
                      'b-graph__chart-point_5': isPoint && isPoint.level === 5
                    })}
                    style={{ left: `${(100 / pointsCount) * index}%` }}
                    onMouseOver={e =>
                      this.setState({
                        activePoint: isPoint
                          ? { x: e.clientX, y: e.clientY, data: isPoint }
                          : null
                      })
                    }
                    onMouseLeave={() => this.setState({ activePoint: null })}
                  />
                )
              })}
            </div>
          </div>
        )}

        {duration > 0 && data.length > 0 && (
          <div className='b-overall'>
            <div className='b-overall__title'>Overall experience:</div>
            <div className='b-overall__box'>
              <div className='b-overall__item b-overall__item_angry'>
                <i></i>
                {data.reduce((s, i) => {
                  return i.emotions[0] + s
                }, 0)}
              </div>
              <div className='b-overall__item b-overall__item_sad'>
                <i></i>
                {data.reduce((s, i) => {
                  return i.emotions[1] + s
                }, 0)}
              </div>
              <div className='b-overall__item b-overall__item_neutral'>
                <i></i>
                {data.reduce((s, i) => {
                  return i.emotions[2] + s
                }, 0)}
              </div>
              <div className='b-overall__item b-overall__item_smile'>
                <i></i>
                {data.reduce((s, i) => {
                  return i.emotions[3] + s
                }, 0)}
              </div>
              <div className='b-overall__item b-overall__item_happy'>
                <i></i>
                {data.reduce((s, i) => {
                  return i.emotions[4] + s
                }, 0)}
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
}
