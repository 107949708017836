import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import history from '../../../history'

import actions from '../../../store/actions'

import * as courseBuilderActions from '../../../store/actions/courseBuilder'
import * as lessonBuilderActions from '../../../store/actions/lessonBuilder'

import {
  CourseCreateMain,
  LessonOverview,
  PageBuilder,
  PageConstructor,
  PublishSettings,
  LessonCreate
} from './nestedComponents'

class CourseCreateRoutes extends Component {
  componentWillUnmount() {
    const {
      courseBuilderActions: { resetCourseBuilder },
      lessonBuilderActions: { resetLessonBuilder },
      resetCourseById
    } = this.props

    resetCourseById()
    resetCourseBuilder()
    resetLessonBuilder()
  }

  componentDidMount() {
    const {
      mode,
      match: { params },
      location: { pathname },
      courseBuilder,
      courseById: { item },
      user: {
        info: { isAdmin, isSuperAdmin, id }
      }
    } = this.props

    const isEdit = mode === 'edit'

    const rootPathTemplate = isEdit ? '/courses/:id/edit' : '/course-create'
    const rootPath = rootPathTemplate.replace(':id', params.id)

    if (
      (isAdmin || isSuperAdmin) &&
      isEdit &&
      item.creator_id !== id &&
      pathname === `${rootPath}/publish`
    ) {
      return null
    }

    if (pathname !== rootPath && !courseBuilder.courseId) {
      history.push(rootPath)
      return null
    }
  }

  render() {
    const {
      mode,
      match: { params },
      isCourseReducerUpdated
    } = this.props

    const rootPathTemplate =
      mode === 'edit' ? '/courses/:id/edit' : '/course-create'
    const rootPath = rootPathTemplate.replace(':id', params.id)

    return (
      <Switch>
        <Route
          exact
          path={rootPathTemplate}
          render={props => (
            <CourseCreateMain
              {...props}
              rootPath={rootPath}
              isCourseReducerUpdated={isCourseReducerUpdated}
            />
          )}
        />
        <Route
          path={`${rootPathTemplate}/lesson`}
          render={props => <LessonOverview {...props} rootPath={rootPath} />}
        />
        <Route
          path={`${rootPathTemplate}/lesson-create`}
          render={props => <LessonCreate {...props} rootPath={rootPath} />}
          exact
        />
        <Route
          path={`${rootPathTemplate}/lesson-create/:sub_id`}
          render={props => <LessonCreate {...props} rootPath={rootPath} />}
        />
        <Route
          path={`${rootPathTemplate}/page-builder`}
          render={props => <PageBuilder {...props} rootPath={rootPath} />}
        />
        <Route
          path={`${rootPathTemplate}/page-constructor`}
          render={props => <PageConstructor {...props} rootPath={rootPath} />}
        />
        <Route
          path={`${rootPathTemplate}/publish`}
          render={props => <PublishSettings {...props} rootPath={rootPath} />}
        />
      </Switch>
    )
  }
}

export default withRouter(
  connect(
    state => ({
      courseBuilder: state.courseBuilder,
      courseById: state.courses.courseById,
      user: state.user
    }),
    dispatch => ({
      courseBuilderActions: bindActionCreators(courseBuilderActions, dispatch),
      lessonBuilderActions: bindActionCreators(lessonBuilderActions, dispatch),
      resetCourseById: () => dispatch(actions.courses.resetCourseById())
    })
  )(CourseCreateRoutes)
)
