import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import actions from '../../../store/actions'
import Edit from './Edit'

const mapStateToProps = state => ({
  assessments: state.assessments,
  topics: state.topics,
  library: state.library,
  user: state.user
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      upload: actions.library.upload,
      clearUploadList: actions.library.clearUploadList,
      updateTopicList: actions.topics.updateTopicList
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(Edit)
