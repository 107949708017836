import React, { Fragment, useMemo, useState } from 'react'
import Dropzone from 'react-dropzone'
import ReactPhoneInput from 'react-phone-input-2'
import { Row, Col } from '../../styles'
import { Input, Button, Form, Tag, Spin, Icon, Switch } from 'antd'
import { Page } from './styled'
import TimezoneSelect from 'react-timezone-select'
import BadgeIcon from './assets/badgeIcon.svg'
import CertificateIcon from './assets/certificateIcon.svg'
import { api } from '../../services'
import config from '../../constants/config'
import libphonenumber from 'google-libphonenumber'
import AccountSubscription from './AccountSubscription'
import stripeImage from './assets/stripe.svg'
import 'react-phone-input-2/lib/style.css'
import NewHeader from '../../components/NewHeader'

// const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance()

const viewTypes = {
  info: 'Info',
  subscription: 'Subscription'
}

/**
 * use for display username input-box in the page
 */
const UserNameRow = ({ App }) => {
  const {
    user: {
      info: { username }
    },
    t
  } = App.props
  return (
    <Fragment>
      <Row>
        <Col md={12}>
          <Form.Item
            label={t('labels:username')}
            colon={false}
            required={false}
          >
            <Input
              name="firstName"
              maxLength={320}
              defaultValue={username}
              disabled
            />
          </Form.Item>
        </Col>
      </Row>
    </Fragment>
  )
}

/**
 *
 * use for display Name and Email input-box in the page
 */
const NameEmailRow = ({ App }) => {
  const {
    form: { getFieldDecorator },
    user: {
      info: { first_name, last_name, email }
    },
    t
  } = App.props
  return (
    <Fragment>
      <Row>
        <Col md={12}>
          <Form.Item label={t('labels:email')} colon={false} required={true}>
            {getFieldDecorator('email', {
              rules: [
                {
                  type: 'email',
                  message: t('errors:email_invalid')
                },
                {
                  required: true,
                  message: t('errors:email'),
                  max: 320
                }
              ],
              initialValue: email
            })(<Input name="email" maxLength={320} disabled />)}
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Form.Item label={t('v3:first_name')} colon={false} required={true}>
            {getFieldDecorator('first_name', {
              initialValue: first_name,
              rules: [
                {
                  required: true,
                  message: t('v3:error_firstName')
                }
              ]
            })(<Input name="firstName" maxLength={320} />)}
          </Form.Item>
        </Col>
        <Col md={6}>
          <Form.Item label={t('v3:last_name')} colon={false} required={true}>
            {getFieldDecorator('last_name', {
              initialValue: last_name,
              rules: [
                {
                  required: true,
                  message: t('v3:error_lastName')
                }
              ]
            })(<Input name="lastName" maxLength={320} />)}
          </Form.Item>
        </Col>
      </Row>
    </Fragment>
  )
}

/**
 *
 * use for display Oragnization, position, timezone and Phone number input-box in the page
 */
const OrgaPositPhoneRow = ({ App }) => {
  const {
    props: {
      form: { getFieldDecorator },
      user: {
        info: {
          position,
          phone_number: phoneNumber,
          timezone: userTZ,
          bio,
          linkedin
        }
      },
      t
    },
    state: { timezone }
  } = App
  console.log(App)

  const customTimeZoneSelectStyle = {
    control: provided => ({
      ...provided,
      borderRadius: 'none',
      width: '100%',
      height: '40px'
    }),
    menu: base => ({
      ...base,
      borderRadius: 0,
      marginTop: 0,
      textAlign: 'left',
      width: '100%'
    }),
    menuList: base => ({
      ...base,
      padding: 0
    }),
    // input: styles => ({ ...styles, paddingBottom: '10px' }),
    singleValue: (styles, { data }) => ({
      ...styles,
      paddingBottom: '10px'
    })
  }
  return (
    <Fragment>
      <Row>
        <Col md={6}>
          <Form.Item
            label={t('labels:position')}
            colon={false}
            required={false}
          >
            {getFieldDecorator('position', {
              initialValue: position,
              rules: [
                {
                  required: true,
                  message: t('errors:position')
                }
              ]
            })(<Input name="position" maxLength={320} />)}
          </Form.Item>
        </Col>

        <Col md={6}>
          <Form.Item label={t('labels:phone')} colon={false} required={false}>
            {getFieldDecorator('phone_number', {
              initialValue: phoneNumber
              // rules: [
              //   {
              //     validator: (_, value) => {
              //       const number = phoneUtil.parseAndKeepRawInput(value)
              //       console.log(
              //         number,
              //         phoneUtil.isValidNumber(number),
              //         'valid phone number test'
              //       )
              //       if (!phoneUtil.isValidNumber(number)) {
              //         return Promise.reject(t('errors:phone_invalid'))
              //       }
              //       return Promise.resolve()
              //     }
              //   }
              // ]
            })(
              <ReactPhoneInput
                specialLabel={''}
                defaultCountry={'us'}
                name="phone_number"
              />
            )}
          </Form.Item>
        </Col>

        <Col md={6}>
          <Form.Item label={t('v4:timezone')} colon={false} required={false}>
            <TimezoneSelect
              value={
                timezone && timezone.length > 0
                  ? JSON.parse(timezone)
                  : userTZ
                  ? JSON.parse(userTZ)
                  : ''
              }
              components={{
                ClearIndicator: null,
                DropdownIndicator: null
              }}
              onChange={App.handleTimezone}
              styles={customTimeZoneSelectStyle}
            />
          </Form.Item>
        </Col>

        <Col md={6}>
          <Form.Item colon={false} required={false} label={t('v4:linkedin')}>
            {getFieldDecorator('linkedin', {
              initialValue: linkedin
            })(
              <Input
                addonBefore={'https://www.linkedin.com/in/'}
                size="large"
                maxLength={100}
              />
            )}
          </Form.Item>
        </Col>
      </Row>
    </Fragment>
  )
}

const BioForm = ({ App }) => {
  const {
    props: {
      form: { getFieldDecorator },
      user: {
        info: { bio }
      },
      t
    }
  } = App
  return (
    <Row>
      <Col md={12}>
        <Form.Item
          label={t('v4:bio').toUpperCase()}
          colon={false}
          required={false}
        >
          {getFieldDecorator('bio', {
            initialValue: bio,
            rules: [{ required: true, message: t('v4:trainer_bio') }]
          })(
            <Input.TextArea
              placeholder={t('v4:text')}
              autosize={{ minRows: 7, maxRows: 20 }}
              onKeyDown={e => {
                if (
                  e.keyCode === 32 &&
                  (e.target.value.charAt(e.target.selectionStart - 1) === ' ' ||
                    e.target.value.charAt(e.target.selectionStart) === ' ')
                ) {
                  e.preventDefault()
                }
              }}
              style={{ fontSize: '1.6rem', resize: 'none' }}
              wrap={'soft'}
            />
          )}
        </Form.Item>
      </Col>
    </Row>
  )
}

const RewardForm = ({ App }) => {
  const { t } = App.props
  const {
    props: {
      badge: { list }
    },
    state: { badgeLoading, certificateData, certificateLoading }
  } = App
  let myList = list.filter(item => item.badge !== null)
  let myCertificateList = certificateData.filter(item => item.cert !== null)
  return (
    <>
      <Row>
        <Col md={12}>
          <Page.Reward>
            <img src={BadgeIcon} style={{ height: '15px', width: '25px' }} />
            <span>{t('v2:badges')}</span>
          </Page.Reward>
          {badgeLoading && (
            <Page.ErrorContainer>
              <Spin spinning />
            </Page.ErrorContainer>
          )}
          {!badgeLoading && myList.length === 0 && (
            <Page.ErrorContainer>
              {t('v2:you_no_badge_available')}
            </Page.ErrorContainer>
          )}
          <Page.List>
            {!badgeLoading &&
              myList &&
              myList.map(item => (
                <Page.Box>
                  <img
                    src={item.badge.link && item.badge.link}
                    onClick={() => App.openModal(item, 'badge')}
                  />
                </Page.Box>
              ))}
          </Page.List>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Page.Reward>
            <img
              src={CertificateIcon}
              style={{ height: '15px', width: '25px' }}
            />
            <span>{t('v2:certificates')}</span>
          </Page.Reward>
          {certificateLoading && (
            <Page.ErrorContainer>
              <Spin spinning />
            </Page.ErrorContainer>
          )}
          {!certificateLoading && myCertificateList.length === 0 && (
            <Page.ErrorContainer>
              {t('v2:you_no_certificates')}
            </Page.ErrorContainer>
          )}
          <Page.List>
            {!certificateLoading &&
              myCertificateList &&
              myCertificateList.map(item => (
                <Page.Box>
                  <img
                    src={item.certificate.link && item.certificate.link}
                    onClick={() => App.openModal(item, 'certificate')}
                  />
                </Page.Box>
              ))}
          </Page.List>
        </Col>
      </Row>
    </>
  )
}

/**
 *
 * use for display Topics Button list in the page
 */
const TopicForm = ({ App }) => {
  const {
    user: {
      info: { topics: userTopics },
      isFetchingUserAttributes
    },
    topics: { list, isFetching },
    t
  } = App.props
  return (
    <Fragment>
      <Form.Item label={t('labels:topics')} colon={false} required={false}>
        <Spin spinning={isFetchingUserAttributes || isFetching}>
          {list.map((topic, index) => {
            if (index < 10) {
              return (
                <Tag
                  key={topic.id}
                  color={userTopics.includes(topic.id) ? 'blue' : ''}
                  onClick={() => App.handleTopic(topic.id)}
                >
                  {t('topics:' + topic.slug)}
                </Tag>
              )
            }
          })}
        </Spin>
      </Form.Item>
    </Fragment>
  )
}
/**
 * PhotoForm is a box of image for user to upload image by clicking select image or drag drop image in directly
 * isDragActive use for checking is user click and hold the image to drag in the box or not
 */
const PhotoForm = ({ App }) => {
  const { isAvatarLoading } = App.state
  const {
    user: {
      info: { avatar }
    },
    t
  } = App.props
  return (
    <Fragment>
      <Form.Item label={t('labels:photo')} colon={false} required={false}>
        <Spin spinning={isAvatarLoading}>
          <Dropzone accept="image/*" onDrop={App.handleDrop} style={{}}>
            {({ isDragActive }) => (
              <Page.Dropzone
                active={isDragActive}
                background={avatar ? avatar.link : ''}
              >
                {isDragActive ? (
                  <Page.Dropzone.Text>
                    {t('labels:drop_file')}
                  </Page.Dropzone.Text>
                ) : (
                  <Page.Dropzone.Button hide={false}>
                    <Icon type="picture" />
                    {t('buttons:upload_photo')}
                  </Page.Dropzone.Button>
                )}
              </Page.Dropzone>
            )}
          </Dropzone>
        </Spin>
      </Form.Item>
    </Fragment>
  )
}
/**
 * HeadSection use for display title and subtitle of the page
 *
 */
const HeaderSection = ({ App }) => {
  const { t } = App.props
  return (
    <Fragment>
      <section className="section head-row">
        <div className="head head_nopadding">
          <div className="head__title">{t('title')}</div>
          <div className="head__subtitle">{t('subtitle')}</div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            gap: '2rem'
          }}
        >
          <Button type="danger" shape="round" onClick={App.handleDeleteAccount}>
            {t('v3:delete_my_accont')}
          </Button>
          <NewHeader />
        </div>
      </section>
    </Fragment>
  )
}

/**
 * BodySection to display all content below the title and subtitle in the page
 *
 */
const BodySection = ({ App, checkStripe }) => {
  const {
    user: {
      isFetchingUserAttributes,
      info: { id, isAdmin, stripe_connect, email }
    },
    t,
    location
  } = App.props
  const { hasIntegrated, hasIntegratedStripe } = App.state

  const handleToggleUserFlag = () => {
    api.user
      .resetUserFlag()
      .then(response => {
        console.log('resetUserFlag response', response.data.status)
      })
      .catch(e => {})
  }

  const [viewType, setViewType] = useState(
    location.search ? viewTypes.subscription : viewTypes.info
  )

  const handleSwitchIntegration = value => {
    if (value) {
      window.open(
        `https://zoom.us/oauth/authorize?response_type=code&client_id=${config.clientID}&redirect_uri=${config.redirectURI}&state={"user":{"id":${id}}}`,
        '_blank'
      )
    } else {
      App.toggleRevokeModal()
    }
  }

  const handleStripeConnect = stripeId => {
    if (stripeId) {
      const pathName = '/account'
      api.user
        .stripeConnect(id, pathName)
        .then(res => {
          if (res && res.data) {
            window.location.assign(res.data)
          }
        })
        .catch(error => {
          console.error('Error:', error)
        })
    } else {
      // checkStripe(false)
      // revoke stipe conncet
    }
  }

  const { isAccountView, isSubscriptionView, isLoading } = useMemo(() => {
    const isAccountView = viewType === viewTypes.info
    const isSubscriptionView = viewType === viewTypes.subscription
    return { isAccountView, isSubscriptionView, isLoading }
  }, [viewType, isLoading])

  console.log({
    isAccountView,
    isSubscriptionView
  })

  return (
    <Fragment>
      <Page.HeadButtons>
        <Button
          shape="round"
          type={isAccountView ? 'primary' : ''}
          isActive={isAccountView}
          onClick={() => setViewType(viewTypes.info)}
        >
          {t('v4:information')}
        </Button>
        <Button
          shape="round"
          type={isSubscriptionView ? 'primary' : ''}
          isActive={isSubscriptionView}
          onClick={() => setViewType(viewTypes.subscription)}
        >
          {t('v3:subscription')}
        </Button>
      </Page.HeadButtons>
      <section className="section section_divider">
        {isAccountView ? (
          <Form onSubmit={App.handleSave}>
            <Row>
              <Col md={8}>
                <Page.Column>
                  <UserNameRow App={App} />
                  <NameEmailRow App={App} />
                  <OrgaPositPhoneRow App={App} />
                </Page.Column>
              </Col>

              <Col md={4}>
                <Page.Column>
                  <PhotoForm App={App} />
                </Page.Column>
              </Col>
            </Row>
            <Row>
              <Col md={8}>
                <Page.Column>
                  <BioForm App={App} />
                </Page.Column>
              </Col>
              <Col md={4}>
                <Page.Column>
                  <RewardForm App={App} />
                </Page.Column>
              </Col>
            </Row>
            <TopicForm App={App} />

            {isAdmin && (
              <Row>
                <Col>
                  <Page.Column>
                    <Switch
                      name="zoom_integrate"
                      checkedChildren={'Zoom Integrate'}
                      unCheckedChildren={'Zoom Integrate'}
                      checked={hasIntegrated}
                      onChange={handleSwitchIntegration}
                    />
                  </Page.Column>
                </Col>
              </Row>
            )}

            <Row>
              <Col>
                <div className="stripe-connect-wrapper">
                  <div className="stripe-connect-inner">
                    <div className="stripe-connect-left">
                      <div className="image">
                        <img src={stripeImage} alt="stripe" />
                      </div>
                      <div className="stripe-connect-status">
                        {hasIntegratedStripe ? (
                          <span style={{ color: 'green' }}>
                            {t('v3:active')}
                          </span>
                        ) : (
                          <span style={{ color: 'red' }}>
                            {t('v3:Inactive')}
                          </span>
                        )}
                      </div>
                    </div>
                    <div
                      className="stripe-connect-status"
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      {hasIntegratedStripe ? (
                        <span style={{ color: 'transparent' }}>
                          {t('v3:active')}
                        </span>
                      ) : (
                        <>
                          <span style={{ color: 'transparent' }}>
                            {t('v3:Inactive')}
                          </span>
                          <div
                            className="warning-message"
                            style={{ color: 'red' }}
                          >
                            {t('v4:your_stripe_activete_to_continue')}
                          </div>
                        </>
                      )}
                    </div>
                    <div className="stripe-connect-right">
                      <div className="stripe-connect-email">{email}</div>

                      <Button
                        type={
                          hasIntegratedStripe ? 'disconnect-btn' : 'primary'
                        }
                        shape="round"
                        onClick={handleStripeConnect}
                        // loading={isFetchingUserAttributes}
                        isLoading={isLoading}
                        disabled={hasIntegratedStripe}
                      >
                        {hasIntegratedStripe
                          ? t('v4:connect_tripe')
                          : t('v4:connect_tripe')}
                      </Button>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

            <hr
              style={{
                opacity: '0.3'
              }}
            />
            <Row>
              <Button
                type="green"
                shape="round"
                size="large"
                htmlType="submit"
                style={{
                  margin: '2rem auto',
                  padding: '0 60px'
                }}
                loading={isFetchingUserAttributes}
              >
                {t('buttons:save_settings')}
              </Button>

              {(id === 204 ||
                id === 760 ||
                id === 757 ||
                id === 630 ||
                id === 761 ||
                id === 248 ||
                id === 168) && (
                <Button danger onClick={() => handleToggleUserFlag()}>
                  {t('v3:reset_user_flag')}
                </Button>
              )}
            </Row>
          </Form>
        ) : (
          <AccountSubscription />
        )}
      </section>
    </Fragment>
  )
}

/**
 *
 * main content include header title and subtitle and body form and other ...
 */
const AccountContent = ({ App, checkStripe }) => {
  return (
    <Page>
      <HeaderSection App={App} />
      <BodySection App={App} checkStripe={checkStripe} />
    </Page>
  )
}

export default AccountContent
