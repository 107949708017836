import React from 'react'
import './styles.scss'
import { Page } from './styled.js'
import { Row, Col, Form, Input, Icon, Spin, Button, message } from 'antd'
import Dropzone from 'react-dropzone'
import ImageCropContainer from '../../components/ImageCrop'
import { general } from '../../constants'
import { withTranslation } from 'react-i18next'
import { api } from '../../services'
import stripeImage from '../../views/Account/assets/stripe.svg'

class TrainerSection extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isAvatarLoading: false,
      isValueLoading: false,
      image: null,
      file: null,
      hasIntegratedStripe: false,
      courseId: this.props.courseId
    }
  }

  componentDidMount() {
    const {
      user: { info }
    } = this.props

    const hasIntegratedStripe =
      info.stripe_connect && info.stripe_connect.status
    this.setState({ hasIntegratedStripe })
  }
  checkStripe = status => {
    this.setState({
      hasIntegratedStripe: status
    })
  }
  LoadingIndicator = props => (
    <Icon style={{ paddingRight: '10px' }} type="loading" />
  )

  onDrop = dropped => {
    if (!dropped) return

    const file = dropped[0]
    if (!file) {
      return
    }
    const image = URL.createObjectURL(file)
    this.setState({
      file,
      image
    })
  }

  handleClosePopup = type => {
    if (type === general.SAVE) {
      this.setState({
        image: null,
        isAvatarLoading: true
      })
    } else {
      this.setState({
        image: null,
        file: null,
        isAvatarLoading: false
      })
    }
  }

  handleUploadImage = data => {
    const {
      updateUser,
      user: { info },
      upload
    } = this.props
    const opts = { category: 'profile' }

    upload(data, opts)
      .then(response => {
        updateUser(info.id, null, { avatar_id: response.id })
      })
      .then(() => {
        this.setState({
          isAvatarLoading: false,
          file: null
        })
      })
  }

  updateUserInformation = () => {
    const {
      t,
      updateUser,
      user: { info },
      form: { validateFields }
    } = this.props

    validateFields((err, values) => {
      if (!err) {
        const { linkedin, bio } = values

        updateUser(
          info.id,
          {
            'custom:bio': bio,
            'custom:linkedin': linkedin
          },
          null
        ).then(() => message.success(`${t('v4:user_information_updated')}`))
      }
    })
  }

  handleStripeConnect = stripeId => {
    const {
      user: { info }
    } = this.props

    const { courseId, hasIntegratedStripe } = this.state

    if (stripeId) {
      // const pathName = `/courses/${courseId}/edit?mpquery=true`
      const pathName = `/courses/${courseId}/edit`
      api.user
        .stripeConnect(info.id, pathName)

        .then(res => {
          if (res && res.data) {
            window.location.assign(res.data)
          }
        })
        .catch(error => {})
    } else {
      // checkStripe(false)
      // revoke stipe conncet
    }
  }
  render() {
    const { image, file, isAvatarLoading, hasIntegratedStripe } = this.state
    console.log(this.state, 'this state::')
    const {
      t,
      user: {
        info: { first_name, last_name, username, email, avatar, bio, linkedin },
        isFetchingUserAttributes
      },
      form: { getFieldDecorator }
    } = this.props

    return (
      <Form style={{ width: '100%' }}>
        <div className="trainer_info">
          {t('v4:trainer_information').toUpperCase()}
        </div>
        <div className="trainer_section">
          <div className="trainer_section_top">
            <div className="trainer_section_col">
              <div className="trainer-row">
                <Col md={12}>
                  <Form.Item
                    colon={false}
                    required={false}
                    label={t('labels:username')}
                  >
                    <Input
                      size="large"
                      value={username}
                      maxLength={100}
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col md={12}>
                  <Form.Item
                    colon={false}
                    required={false}
                    label={t('labels:name')}
                  >
                    <Input
                      size="large"
                      placeholder="profilename"
                      maxLength={100}
                      disabled
                      value={first_name + ' ' + last_name}
                    />
                  </Form.Item>
                </Col>
              </div>
              <div className="trainer-row">
                <Col md={12}>
                  <Form.Item
                    colon={false}
                    required={false}
                    label={t('labels:email')}
                  >
                    <Input
                      size="large"
                      maxLength={100}
                      disabled
                      value={email}
                    />
                  </Form.Item>
                </Col>
                <Col md={12}>
                  <Form.Item
                    colon={false}
                    required={true}
                    label={t('v4:linkedin')}
                  >
                    {' '}
                    {getFieldDecorator('linkedin', {
                      initialValue: linkedin,
                      rules: [
                        {
                          required: true,
                          message: t('v3:linkdin_is_required')
                        }
                        // {
                        //   pattern: /^(https?:\/\/)?(www\.)?linkedin\.com\/.*$/,
                        //   message: 'Please enter a valid LinkedIn URL.'
                        // }
                      ]
                    })(
                      <Input
                        addonBefore={'https://www.linkedin.com/in/'}
                        size="large"
                        maxLength={100}
                      />
                    )}
                  </Form.Item>
                </Col>
              </div>
              <div className="trainer-row">
                <Col md={12}>
                  <Form.Item
                    label={t('v4:bio').toUpperCase()}
                    colon={false}
                    required={true}
                  >
                    {getFieldDecorator('bio', {
                      initialValue: bio,
                      rules: [
                        {
                          required: true,
                          message: t('v3:bio_is_required')
                        }
                      ]
                    })(
                      <Input.TextArea
                        placeholder={t('v4:text')}
                        autoSize={{ minRows: 5, maxRows: 20 }}
                        style={{ fontSize: '1.6rem', resize: 'none' }}
                        wrap={'soft'}
                      />
                    )}
                  </Form.Item>
                </Col>
                {/* <Col md={12}>
                  <Form.Item
                    label={t('labels:avatar')}
                    colon={false}
                    required={true}
                  >
                    <Spin spinning={isAvatarLoading}>
                      <Dropzone
                        accept="image/*"
                        onDrop={this.onDrop}
                        // disabled={avatar ? true : false}
                        style={{}}
                      >
                        {({ isDragActive }) => (
                          <Page.Dropzone
                            active={isDragActive}
                            background={avatar ? avatar.link : ''}
                          >
                            {isDragActive ? (
                              <Page.Dropzone.Text>
                                {t('labels:drop_file')}
                              </Page.Dropzone.Text>
                            ) : (
                              <Page.Dropzone.Button hide={false}>
                                <Icon type="picture" />
                                {t('buttons:upload_photo')}
                              </Page.Dropzone.Button>
                            )}
                          </Page.Dropzone>
                        )}
                      </Dropzone>
                    </Spin>
                  </Form.Item>
                </Col> */}

                {image && (
                  <ImageCropContainer
                    circle
                    image={image}
                    handleUploadImage={this.handleUploadImage}
                    handleClosePopup={this.handleClosePopup}
                    file={file}
                    t={t}
                  />
                )}
              </div>
            </div>
          </div>

          <div className="stripe_trainer_section_bottom">
            <Row>
              <Col>
                <div className="stripe-connect-wrapper">
                  <div className="stripe-connect-inner">
                    <div className="stripe-connect-left">
                      <div className="image">
                        <img src={stripeImage} alt="stripe" />
                      </div>
                      <div className="stripe-connect-status">
                        {hasIntegratedStripe ? (
                          <span style={{ color: 'green' }}>
                            {t('v3:active')}
                          </span>
                        ) : (
                          <span style={{ color: 'red' }}>
                            {t('v3:Inactive')}
                          </span>
                        )}
                      </div>
                    </div>

                    <div
                      className="stripe-connect-status"
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      {hasIntegratedStripe ? (
                        <span style={{ color: 'transparent' }}>
                          {t('v3:active')}
                        </span>
                      ) : (
                        <>
                          <span style={{ color: 'transparent' }}>
                            {t('v3:Inactive')}
                          </span>
                          <div
                            className="warning-message"
                            style={{ color: 'red' }}
                          >
                            {t('v4:your_stripe_activete_to_continue')}
                          </div>
                        </>
                      )}
                    </div>

                    <div className="stripe-connect-right">
                      <div className="stripe-connect-email">{email}</div>

                      <Button
                        type={
                          hasIntegratedStripe ? 'disconnect-btn' : 'primary'
                        }
                        shape="round"
                        onClick={this.handleStripeConnect}
                        loading={isFetchingUserAttributes}
                        disabled={hasIntegratedStripe}
                      >
                        {hasIntegratedStripe
                          ? t('v4:connect_tripe')
                          : t('v4:connect_tripe')}
                      </Button>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="trainer_section_bottom">
            <Button
              type="green"
              shape="round"
              onClick={this.updateUserInformation}
              loading={isFetchingUserAttributes}
            >
              {t('v3:save_user_information')}
            </Button>
          </div>
        </div>
      </Form>
    )
  }
}

export default Form.create()(withTranslation('trainer')(TrainerSection))
