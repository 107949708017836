import React, { Component } from 'react'
import { Conference } from './components/AntMediaService'
import { ChimeRecording } from './components/ChimeServices'
import { AgoraRecording } from './components/AgoraServices'
import queryString from 'query-string'
import { config } from '../../constants'
import { message } from 'antd'
import { withTranslation } from 'react-i18next'

message.config({
  top: 100,
  duration: 5,
  maxCount: 1
})

const testToken = '5351925135e5879281787two8140467802m'
class EventRecording extends Component {
  socket = null
  _isMounted = false

  state = {
    isLoading: true,
    messages: [],
    isChatAble: false,
    playerRef: null,
    countDown: false,
    isConference: true,
    broadcast: false,
    isStreamStopped: false,
    isWebStream: null,
    liveDuration: null,
    hasStreamStarted: false,
    totalViewers: 0,
    controlVideos: [],
    isCoachingSession: false,
    isProcessing: false,
    countStream: '',
    isSocketClose: false,
    isPlay: false,
    showJoinButton: false,
    webinarHostId: '',
    allowJoin: false,
    isEventCrash: false,
    isPreparingConf: false,
    fetchToken: false
  }

  componentDidMount = async () => {
    this._isMounted = true
    const {
      location: { search }
    } = this.props
    const { token } = queryString.parse(search)
    console.log(
      'in mount',
      token,
      'config',
      config.recordingToken,
      '--------',
      this.props
    )
    if (token === testToken) {
      this.setState({ fetchToken: true })
    }
    // this.initSocket(userid, username)
  }

  // initSocket = (userId, userName) => {
  //   const { id } = this.props.match.params
  //   this.socket = new window.WebSocket(
  //     `${config.socketURL}?channel_type=EVENT&channel_id=${id}&user_id=${userId}&user_name=${userName}`
  //   )

  //   this.socket.onopen = () => {
  //     // this.getChatHistory()
  //     this.getAntInfo()
  //     this.getConnectedInterval = setInterval(() => {
  //       this.socket.send(
  //         JSON.stringify({
  //           action: 'pingPong',
  //           msg: 'ping'
  //         })
  //       )
  //     }, 10000)
  //     this.setState({ isSocketClose: false })
  //   }

  //   this.socket.onmessage = event => {
  //     const data = JSON.parse(event.data)

  //     console.log('websocket data log', data)

  //     if (data.event === 'sendMessage' && data.channel_id === id) {
  //       this.setState(state => ({
  //         messages: [...state.messages, data]
  //       }))
  //     }

  //     if (data.event === 'endConference' && data.channel_id === id) {
  //       clearInterval(this.getConnectedInterval)
  //     }

  //     if (data.event === 'antmediaInfo') {
  //       if (data.status === 'CREATING' || data.status === 'CREATED') {
  //         console.log('inside antmediaInfo CREATING and CREATED')
  //         this.setState({
  //           isPreparingConf: true
  //         })
  //       } else if (data.status === 'READY') {
  //         console.log('inside antmediaInfo READY')
  //         this.setState(
  //           {
  //             allowJoin: true,
  //             isPreparingConf: false,
  //             isEventCrash: false
  //           })
  //       } else if (data.status === 'FAILED') {
  //         console.log('inside antmediaInfo FAILED')
  //         this.setState({
  //           isEventCrash: true,
  //           allowJoin: false,
  //           isPreparingConf: false,
  //         })
  //       }
  //     }

  //     // if (data.event === 'sendCount' && data.channel_id === id) {
  //     //   this.setState({
  //     //     totalViewers: data.views
  //     //   })
  //     // }

  //     // if (data.event === 'startStream' && data.channel_id === id) {
  //     //   this.liveCountInterval = setInterval(() => {
  //     //     this.getLiveCount()
  //     //   }, 5000)
  //     // }

  //     if (data.event === 'historyChannel' && data.channel_id === id) {
  //       this.setState({
  //         messages: data.messages,
  //       })
  //     }
  //   }

  //   this.socket.onerror = error => {
  //     console.log('Error ' + error.message)
  //   }

  //   this.socket.onclose = () => {
  //     console.log('on this socket close')
  //     this.setState({ isSocketClose: true })
  //   }
  // }

  // getAntInfo = () => {
  //   const { id } = this.props.match.params

  //   this.socket.send(
  //     JSON.stringify({
  //       action: 'getAntmediaInfo',
  //       channel_id: id,
  //       channel_type: 'EVENT'
  //     })
  //   )
  // }

  onStartBroadcast = () => {
    this.setState(prevState => {
      if (!prevState.broadcast) {
        return {
          broadcast: true
        }
      } else {
        const { isConference } = prevState
        if (!isConference) {
          this.onStreamStopped()
        }
        return {
          broadcast: false
        }
      }
    })
  }

  onStreamStopped = () => {
    this.setState({ isStreamStopped: true })
  }

  render() {
    const {
      t,
      match: {
        params: { id }
      },
      location: { search }
    } = this.props

    const { isStreamStopped, fetchToken } = this.state

    const { server, startdatetime, duration } = queryString.parse(search)

    const isConferenceHost = false
    const confCreator = false
    return (
      <div className="event-streams">
        {fetchToken ? (
          <>
            {server === 'antmedia' ? (
              <Conference
                conferenceId={'room_' + id}
                eventId={id}
                allowJoin={true}
                handleBroadcastChange={this.onStartBroadcast}
                onStreamStopped={this.onStreamStopped}
                isStreamStopped={isStreamStopped}
                // stopstream={this.stopStream}
                isConferenceHost={isConferenceHost}
                isConfCreator={confCreator}
              />
            ) : server === 'agora' ? (
              <AgoraRecording
                eventId={id}
                startdatetime={startdatetime}
                duration={duration}
              />
            ) : (
              <ChimeRecording
                eventId={id}
                isConferenceHost={isConferenceHost}
                handleBroadcastChange={this.onStartBroadcast}
                isConfCreator={confCreator}
                onStreamStopped={this.onStreamStopped}
                isStreamStopped={isStreamStopped}
                startdatetime={startdatetime}
                duration={duration}
              />
            )}
          </>
        ) : (
          <div>{t('v3:you_donot_access_event')}</div>
        )}
      </div>
    )
  }
}

export default withTranslation()(EventRecording)
