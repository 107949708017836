import './App.css'
import CanvasDesigner from './whiteboard'
import React, { useRef, useEffect, useState } from 'react'
import { Col, Row, Modal, Radio } from 'antd'
import {
  getUploadLink,
  getDownloadLink
} from '../../../../../services/api/events'
import axios from 'axios'
import { api } from '../../../../../services'
import { PopOver } from 'amazon-chime-sdk-component-library-react'

// we use this function to replace the image type in the base64 string with the desired type
// String.prototype.replaceAt = function(index, replacement) {
//   return (
//     this.substring(0, index) +
//     replacement +
//     this.substring(index + replacement.length)
//   )
// }
// Function to replace a character at a specific index in a string
const replaceAt = (string, index, replacement) => {
  return (
    string.substring(0, index) +
    replacement +
    string.substring(index + replacement.length)
  )
}

var designer = new CanvasDesigner()
designer.widgetHtmlURL =
  'https://www.webrtc-experiment.com/Canvas-Designer/widget.html'
designer.widgetJsURL =
  'https://www.webrtc-experiment.com/Canvas-Designer/widget.js'

let drawing = {
  points: [],
  startIndex: 0
}

const getS3Data = async eventId => {
  const test = await getDownloadLink(eventId).catch(err => console.log(err))
  const response = await axios.get(test.data.link)
  return response
}

const uploadDrawings = async (data, eventId) => {
  const uploadLink = await getUploadLink(eventId)

  const options = {
    headers: {
      'Content-Type': 'application/json'
    }
  }

  await api.library.putToS3(uploadLink.data.link, data, options)
}

const Whiteboard = ({
  sendRealTimeMessage,
  meetingManager,
  eventId,
  whiteboardRequest,
  whiteboardResponse,
  setWhiteboardRequest,
  setWhiteboardResponse,
  isAgora,
  consumerId,
  userId,
  onClickWhiteboardSharing
}) => {
  const wbRef = useRef(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [value, setValue] = useState(1)

  const showModal = () => {
    setIsModalOpen(true)
  }

  //const className = consumerId === userId ? 'active' : 'inactive'
  const className = 'active'

  const handleOk = () => {
    designer.toDataURL('img', url => {
      if (value === 1) {
        let link = document.getElementById('downlink_png')
        link.setAttribute('href', url)
        link.click()
      } else if (value === 2) {
        // url = url.replaceAt(11, 'jpg')
        url = replaceAt(url, 11, 'jpg')
        let link = document.getElementById('downlink_jpg')
        link.setAttribute('href', url)
        link.click()
      }
    })
    setIsModalOpen(false)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const onChange = e => {
    console.log('radio checked', e.target.value)
    setValue(e.target.value)
  }
  const getMessage = topic => {
    try {
      meetingManager.audioVideo.realtimeSubscribeToReceiveDataMessage(
        topic,
        receiveDataMessageHandler
      )
    } catch (err) {
      console.log(err)
    }
  }

  const receiveDataMessageHandler = async data => {
    data = JSON.parse(new TextDecoder().decode(data.data))
    if (data.state === 'drawing') {
      for (let i = 0; i < data.points.length; i++) {
        drawing.points.push(data.points[i])
      }
    } else if (data.state === 'end') {
      drawing.startIndex = data.startIndex
      designer.syncData(drawing)
      drawing.points = []
    } else if (data.state === 'image') {
      getS3Data(eventId).then(response => {
        designer.syncData(response.data)
      })
    }
  }

  const sendMessage = async (topic, data, lifetimeMs) => {
    await sendRealTimeMessage(topic, data, lifetimeMs)
  }

  useEffect(() => {
    getMessage(eventId)
    const whiteboard = wbRef.current

    designer.appendTo(whiteboard || document.documentElement)
  }, [])

  useEffect(() => {
    console.log('whiteboardRequest:', `${eventId}-${whiteboardRequest}`)
    if (whiteboardRequest !== 0) {
      sendMessage(
        `${eventId}-${whiteboardRequest}`,
        {
          state: 'response',
          wbStatus: true
        },
        50000
      )
      designer.sync()
      setWhiteboardRequest(0)
    }
  }, [whiteboardRequest])

  useEffect(() => {
    designer.sync(async function(data) {
      let chunkSize = 5
      let chunkNum = Math.ceil(data.points.length / chunkSize)

      for (let i = 0; i < chunkNum; i++) {
        let points = data.points.splice(0, chunkSize)

        sendMessage(
          eventId,
          {
            state: 'drawing',
            points
          },
          50000
        )
      }
      sendMessage(
        eventId,
        {
          state: 'end',
          startIndex: data.startIndex
        },
        50000
      )
    })
  }, [])

  useEffect(() => {
    designer.addSyncListener(async function(data) {
      if (
        data.points[data.points.length - 1][0] === 'image' ||
        data.points[data.points.length - 1][0] === 'pdf'
      ) {
        let base64Image = data.points[data.points.length - 1][1][0] // your base64 image data
        let imageWidht = data.points[data.points.length - 1][1][3]
        let imageHeight = data.points[data.points.length - 1][1][4]
        let div = document.getElementById('sharewhiteboard')
        let rect = div.getBoundingClientRect()
        let resizedBase64 = 0

        let newWidth = data.points[data.points.length - 1][1][3] // the new width of the image in pixels
        let newHeight = data.points[data.points.length - 1][1][4] // the new height of the image in pixels

        if (imageWidht > rect.width) {
          newWidth =
            data.points[data.points.length - 1][1][3] *
            (rect.width / imageWidht)
          newHeight =
            data.points[data.points.length - 1][1][4] *
            (rect.width / imageWidht)
        } else if (imageHeight > rect.height) {
          newHeight =
            data.points[data.points.length - 1][1][4] *
            (rect.height / imageHeight)
          newWidth =
            data.points[data.points.length - 1][1][3] *
            (rect.height / imageHeight)
        }

        let canvas = document.createElement('canvas')
        let ctx = canvas.getContext('2d')
        let image = new Image()
        image.src = base64Image

        image.onload = async function() {
          canvas.width = newWidth
          canvas.height = newHeight
          ctx.drawImage(image, 0, 0, newWidth, newHeight)
          resizedBase64 = canvas.toDataURL() // contains the resized image in Base64 format
          data.points[data.points.length - 1][1][0] = resizedBase64
          data.points[data.points.length - 1][1][3] = newWidth
          data.points[data.points.length - 1][1][4] = newHeight

          designer.syncData(data)
          await uploadDrawings(data, eventId)

          sendMessage(
            eventId,
            {
              state: 'image'
            },
            50000
          )
        }
      } else {
        let chunkSize = 5
        let chunkNum = Math.ceil(data.points.length / chunkSize)

        for (let i = 0; i < chunkNum; i++) {
          let points = data.points.splice(0, chunkSize)

          sendMessage(
            eventId,
            {
              state: 'drawing',
              points
            },
            50000
          )
        }
        sendMessage(
          eventId,
          {
            state: 'end',
            startIndex: data.startIndex
          },
          50000
        )
      }
    })
  }, [])

  // close whiteboard when owner (the one opened whiteboard) leaves/reloads the page
  useEffect(() => {
    const handleBeforeUnload = event => {
      event.preventDefault()
      if (consumerId === userId) onClickWhiteboardSharing()
    }
    window.addEventListener('beforeunload', handleBeforeUnload)
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [])

  return (
    <div id="sharewhiteboard">
      <div className={className} ref={wbRef} id="whiteboard">
        <div id="features">
          <Row gutter={[40, 8]}>
            <Col className="gutter-row" span={6}>
              <div title="Take a screenshot" id="screenshot">
                <a
                  style={{ display: 'none' }}
                  href="downlink_png"
                  id="downlink_png"
                  download="ShareLook Screenshot.png"
                >
                  downlink_png
                </a>
                <a
                  style={{ display: 'none' }}
                  id="downlink_jpg"
                  href="downlink_jpg"
                  download="ShareLook Screenshot.jpg"
                >
                  downlink_jpg
                </a>
                <img
                  alt=""
                  src={require('../../assets/camera.png')}
                  onClick={showModal}
                />
                <Modal
                  title="Take a screenshot"
                  visible={isModalOpen}
                  onOk={handleOk}
                  onCancel={handleCancel}
                >
                  <Radio.Group onChange={onChange} value={value}>
                    <p>Please select the file format you want to download.</p>
                    <Radio value={1}>PNG</Radio>
                    <Radio value={2}>JPG</Radio>
                  </Radio.Group>
                </Modal>
              </div>
            </Col>
            <Col className="gutter-row" span={6}>
              <div title="Clear the canvas" id="clearcanvas">
                <img
                  alt=""
                  src={require('../../assets/eraser.png')}
                  onClick={() => {
                    sendMessage(
                      eventId,
                      {
                        state: 'end',
                        startIndex: 0
                      },
                      50000
                    )
                    designer.clearCanvas()
                  }}
                />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}

export default Whiteboard
