import React from 'react'
import _ from 'lodash'
import { Icon, Dropdown, Menu, Row, Col } from 'antd'
import { userImage } from '../../../../Reports/images'
import RaiseHand from '../../assets/raisehand.png'
import { withTranslation } from 'react-i18next'

const PassiveUsers = ({
  participants,
  handleAllow,
  raiseHandAllowed,
  handleDecline,
  isHost,
  t
}) => {
  return (
    <div id="enroll-wrapper">
      <div className="inner-wrapper">
        <div className="overflow-wrapper scrolling">
          {participants.map((i, index) => {
            if (i.status === 'declined') return null
            const imgSrc = i.avatar ? i.avatar : userImage
            const isAllowed =
              _.findLastIndex(raiseHandAllowed, function(o) {
                return o === i.userId
              }) > -1
                ? true
                : false
            const isJoined = isAllowed && i.status === 'approved'

            return (
              <div
                className="event__participants_users_item"
                key={i.userName + index}
              >
                <div className="event__participants_users_item_about">
                  <div className="event__participants_users_item_about-imgContainer">
                    <img
                    alt=""
                      className={`avatar ${
                        i.status === 'approved' ? 'passive__joined' : ''
                      }`}
                      src={imgSrc}
                    />
                    {i.status === 'approved' && (
                      <img
                        src={RaiseHand}
                        alt="allowed-to-ask"
                        className="allow__join"
                      />
                    )}
                  </div>
                  <span>{i.userName}</span>
                </div>
                <div className="event__participants_users_item_status">
                  <span>{_.capitalize(i.status)}</span>
                  {isHost && (
                    <Dropdown
                      trigger={['click']}
                      overlay={
                        <Menu>
                          {i.status === 'waiting' && !isJoined && (
                            <Menu.Item onClick={() => handleAllow(i.userId)}>
                              <Row
                                type="flex"
                                justify="space-around"
                                align="middle"
                                gutter={[8, 8]}
                              >
                                <Col span={4}>
                                  <Icon
                                    type="check"
                                    style={{ color: 'green' }}
                                  />
                                </Col>
                                <Col span={18}>
                                  <span role="button">{t('v2:allow')}</span>
                                </Col>
                              </Row>
                            </Menu.Item>
                          )}
                          <Menu.Item onClick={() => handleDecline(i.userId)}>
                            <Row
                              type="flex"
                              justify="space-around"
                              align="middle"
                              gutter={[8, 8]}
                            >
                              <Col span={4}>
                                <Icon type="close" style={{ color: 'red' }} />
                              </Col>
                              <Col span={18}>
                                <span role="button">{t('buttons:close')}</span>
                              </Col>
                            </Row>
                          </Menu.Item>
                        </Menu>
                      }
                    >
                      <Icon type="more" />
                    </Dropdown>
                  )}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default withTranslation()(PassiveUsers)
