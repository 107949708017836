import React, { Fragment, useEffect, useState } from 'react'
import {
  Col,
  Input,
  Popconfirm,
  Row,
  Spin,
  message,
  Button,
  Empty,
  Tooltip
} from 'antd'
import _ from 'lodash'
import { Page, Column, Table } from './styled'
import { Spinner } from '../../../../components'
import { trash, pencil } from '../../images'
import { withTranslation } from 'react-i18next'
import InfoIcon from '../../../../assets/info_icon.png'

const Category = props => {
  const {
    t,
    organizations: {
      list: { rows: list }
    },
    user: { active_organization_id: activeOrganizationId, isSuperAdmin },
    updateCategory,
    createCategory,
    deleteCategory,
    getCategories
  } = props
  let orgList = []
  if (!_.isEmpty(list)) {
    orgList = isSuperAdmin
      ? list
      : list.filter(i => i.role && i.role.name === 'Admin')
  }

  const orgId = activeOrganizationId || (!!orgList.length && orgList[0].id)
  const selectedOrg = orgList.find(i => i.id === orgId) || {}
  const isOrgAdmin =
    selectedOrg && selectedOrg.role && selectedOrg.role.name === 'Admin'
  const [isFetching, setIsFetching] = useState(false)
  const [isCreation, setIsCreation] = useState(false)
  const [deletedCategoryId, setDeletedCategoryId] = useState(null)
  const [activeCategory, setActiveCategory] = useState(null)
  const [isEdit, setIsEdit] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [categoryList, setCategoryList] = useState([])
  const [categoryInput, setCategoryInput] = useState('')
  const [categoryThreshold, setCategoryThreshold] = useState(null)
  const [categoryEditInput, setCategoryEditInput] = useState('')
  const [categoryEditThreshold, setCategoryEditThreshold] = useState('')
  useEffect(() => {
    fetchCategories()
  }, [])
  const fetchCategories = async () => {
    setIsFetching(true)

    await getCategories().then(data => {
      setIsFetching(false)
      setCategoryList(data)
      setActiveCategory(false)
      setActiveCategory(null)
    })
  }

  const deleteCategoryById = async (e, id) => {
    e.stopPropagation()
    setDeletedCategoryId(id)
    await deleteCategory(id)
      .then(() => {
        message.success('Category delete successful.')
        fetchCategories()
        setDeletedCategoryId(null)
      })
      .catch(err => {
        setDeletedCategoryId(null)
        message.error('Category delete failed!')
      })
  }

  const editCategory = activeCategory => {
    setIsEdit(true)
    setCategoryEditInput(activeCategory.name)
    setCategoryEditThreshold(activeCategory.threshold)
    setActiveCategory(activeCategory)
  }

  const create = data => {
    setIsCreation(true)
    return createCategory(data).then(() => {
      fetchCategories()
      setIsCreation(false)
      setActiveCategory(null)
    })
  }

  const update = data => {
    setIsEditing(true)
    return updateCategory(activeCategory.id, data)
      .then(() => {
        fetchCategories()
        setIsEditing(false)
        setIsEdit(false)
        setActiveCategory(null)
      })
      .catch(err => {
        setIsEditing(false)
        message.error('Category edit failed!')
      })
  }

  const onChange = e => {
    let value = e.target.value
    setCategoryInput(value)
  }
  const onChangeThreshold = e => {
    let value = e.target.value
    if (value > 100) {
      value = 100
    }
    setCategoryThreshold(value)
  }
  const onEditChange = e => {
    let value = e.target.value
    setCategoryEditInput(value)
  }
  const onEditChangeThreshold = e => {
    let value = e.target.value
    if (value > 100) {
      value = 100
    }
    setCategoryEditThreshold(value)
  }
  const submitCategory = async () => {
    if (categoryInput) {
      await create({
        name: categoryInput,
        threshold: categoryThreshold ? categoryThreshold : 0
      })
      setCategoryInput('')
      setCategoryThreshold('')
    }
  }
  const handleCancel = async () => {
    setActiveCategory(null)
    setIsEdit(false)
  }
  const handleSave = async () => {
    if (categoryEditInput) {
      await update({
        name: categoryEditInput,
        threshold: categoryEditThreshold ? categoryEditThreshold : 0
      })
    }
  }
  return (
    <Page>
      <Fragment>
        <Page.Head>
          <Column>
            <h2>{t('v4:manage_category')}</h2>
          </Column>
        </Page.Head>
        <Row gutter={[16, 16]} type="flex" justify="left" align="middle">
          <Col xs={24} sm={12} md={6} lg={6}>
            <Col>{t('v3:category')}:</Col>
            <Input
              placeholder="Input Category..."
              allowClear
              maxLength={24}
              readOnly={isCreation}
              value={categoryInput}
              onChange={onChange}
            />
          </Col>
          <Col xs={24} sm={8} md={5} lg={4}>
            <Col>
              {t('v4:threshold')}:
              <Tooltip
                placement="topLeft"
                title={t('v4:threshold_popup')}
                arrow
              >
                <img alt="" src={InfoIcon} />
              </Tooltip>
            </Col>
            <Input
              placeholder={t('v4:threshold')}
              allowClear
              type="number"
              maxLength={3}
              readOnly={isCreation}
              value={categoryThreshold}
              onChange={onChangeThreshold}
            />
          </Col>
          <Col xs={24} sm={4} md={3}>
            <Col style={{ marginTop: 14 }}></Col>
            {(isSuperAdmin || isOrgAdmin) && (
              <Spin spinning={isCreation}>
                <Page.Head.Button
                  type="primary"
                  className="rounded"
                  onClick={submitCategory}
                >
                  {t('v2:create')}
                </Page.Head.Button>
              </Spin>
            )}
          </Col>
        </Row>

        <Table>
          <Table.Header>
            <Table.Label>{t('v4:list_of_catagory')}</Table.Label>
          </Table.Header>
          {isFetching ? (
            <Spinner />
          ) : (
            <Table.Body>
              <Table.Head>
                <Table.Head.Column>{t('v4:category_name')}</Table.Head.Column>
                <Table.Head.Column>
                  {t('v4:category_threshold')}
                </Table.Head.Column>
              </Table.Head>
              {categoryList.length === 0 && <Empty />}
              {categoryList.length !== 0 &&
                categoryList.map(category => (
                  <Spin
                    spinning={
                      deletedCategoryId && deletedCategoryId === category.id
                    }
                    key={category.id}
                  >
                    <Table.Item>
                      <Table.Column>
                        <Table.Item.Info>
                          <Table.Item.Organisation>
                            {isEdit && category.id === activeCategory.id ? (
                              <Input
                                size="small"
                                maxLength={24}
                                placeholder="Input Category..."
                                allowClear
                                readOnly={isEditing}
                                value={categoryEditInput}
                                onChange={onEditChange}
                              />
                            ) : (
                              category.name
                            )}
                          </Table.Item.Organisation>
                        </Table.Item.Info>
                      </Table.Column>
                      <Table.Column>
                        <Table.Item.Info>
                          <Table.Item.Organisation>
                            {isEdit && category.id === activeCategory.id ? (
                              <Input
                                size="small"
                                maxLength={3}
                                type="number"
                                placeholder="Threshold"
                                allowClear
                                readOnly={isEditing}
                                value={categoryEditThreshold}
                                onChange={onEditChangeThreshold}
                              />
                            ) : category.threshold ? (
                              category.threshold
                            ) : (
                              0
                            )}
                          </Table.Item.Organisation>
                        </Table.Item.Info>
                      </Table.Column>

                      {isEdit && category.id === activeCategory.id ? (
                        <Table.EventsEdit>
                          <Button
                            style={{ width: 100, margin: 5, borderRadius: 25 }}
                            type="text"
                            size="small"
                            disabled={isEditing}
                            onClick={handleCancel}
                          >
                            {t('buttons:cancel')}
                          </Button>
                          <Button
                            style={{
                              width: 100,
                              margin: 5,
                              borderRadius: 25
                            }}
                            type="primary"
                            size="small"
                            loading={isEditing}
                            onClick={handleSave}
                          >
                            {t('buttons:save')}
                          </Button>
                        </Table.EventsEdit>
                      ) : (
                        <Table.Events>
                          <Table.EventLink>
                            <Table.EventIcon
                              src={pencil}
                              onClick={() => editCategory(category)}
                            />
                          </Table.EventLink>

                          {(isSuperAdmin || isOrgAdmin) && (
                            <Popconfirm
                              title={t('delete_warning')}
                              okText={t('general:yes')}
                              cancelText={t('general:no')}
                              placement="right"
                              onClick={e => e.stopPropagation()}
                              onCancel={e => e.stopPropagation()}
                              onConfirm={e =>
                                deleteCategoryById(e, category.id)
                              }
                              icon={false}
                            >
                              <Table.EventLink>
                                <Table.EventIcon src={trash} />
                              </Table.EventLink>
                            </Popconfirm>
                          )}
                        </Table.Events>
                      )}
                    </Table.Item>
                  </Spin>
                ))}
            </Table.Body>
          )}
        </Table>
      </Fragment>
    </Page>
  )
}

export default withTranslation('management')(Category)
