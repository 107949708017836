import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import actions from '../../../store/actions'
import DetailMaze from './Detail'

const { updateUser } = actions.user
const { getMazeById } = actions.maze

const mapStateToProps = state => ({
  user: state.user,
  organizations: state.organizations.list,
  maze: { fetching: state.maze.fetchById, mazeById: state.maze.mazeById }
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getMazeById,
      updateUser
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(DetailMaze)
