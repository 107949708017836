import React, { Component } from 'react'
import { DatePicker } from 'antd'
import { Row, Col, Field } from '../../../../../../../styles'
import { withTranslation } from 'react-i18next'

class EDatePicker extends Component {
  state = {
    endOpen: false
  }

  disabledStartDate = (startValue) => {
    const { endValue } = this.props
    if (!startValue || !endValue) {
      return false
    }
    return startValue.valueOf() > endValue.valueOf()
  }

  disabledEndDate = (endValue) => {
    const { startValue } = this.props
    if (!endValue || !startValue) {
      return false
    }
    return endValue.valueOf() <= startValue.valueOf()
  }

  onChange = (field, value) => {
    const { onChange } = this.props
    if (onChange) {
      onChange(field, value)
    }
  }

  onStartChange = (value) => {
    this.onChange('startValue', value)
    if (value) {
      this.setState({ endOpen: true })
    }
  }

  onEndChange = (value) => {
    this.onChange('endValue', value)
  }

  handleEndOpenChange = (open) => {
    this.setState({ endOpen: open })
  }

  render () {
    const { startValue, endValue, t } = this.props
    const { endOpen } = this.state
    return (
      <Row>
        <Col md={6}>
          <Field>
            <Field.Label>{t('labels:start_date')}</Field.Label>
            <Field.Control>
              <DatePicker
                style={{ width: '100%' }}
                disabledDate={this.disabledStartDate}
                format='DD-MM-YYYY'
                value={startValue}
                placeholder={t('placeholders:date_start')}
                onChange={this.onStartChange}
              />
            </Field.Control>
          </Field>
        </Col>
        <Col md={6}>
          <Field>
            <Field.Label>{t('labels:expiry_date')}</Field.Label>
            <Field.Control>
              <DatePicker
                style={{ width: '100%' }}
                disabledDate={this.disabledEndDate}
                format='DD-MM-YYYY'
                value={endValue}
                placeholder={t('placeholders:date_expire')}
                onChange={this.onEndChange}
                open={endOpen}
                onOpenChange={this.handleEndOpenChange}
              />
            </Field.Control>
          </Field>
        </Col>
      </Row>
    )
  }
}

export default withTranslation()(EDatePicker)
