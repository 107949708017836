import { api } from '../../services'
import {
  WORKSHOP_EVENTS_FETCHING,
  WORKSHOP_EVENTS_SUCCESS,
  DELETE_EVENT_SUCCESS,
  DELETE_PODCAST_SUCCESS,
  VIEW_EVENT_SUCCESS,
  TRAINING_EVENTS_WITH_ASSESSMENTS_SUCCESS,
  TRAINING_EVENTS_WITH_ASSESSMENTS_FETCHING
} from '../types'

const getEventsByMode = params => dispatch => {
  const d = (type, payload) =>
    dispatch({
      type,
      payload
    })

  const isAssessment = params.mode === 'live_assessment'
  if (isAssessment) {
    d(TRAINING_EVENTS_WITH_ASSESSMENTS_FETCHING, true)
  } else {
    d(WORKSHOP_EVENTS_FETCHING, true)
  }

  return api.events.get(params).then(({ data }) => {
    if (data && isAssessment) {
      d(TRAINING_EVENTS_WITH_ASSESSMENTS_SUCCESS, data)
      d(TRAINING_EVENTS_WITH_ASSESSMENTS_FETCHING, false)
      return data
    }

    if (data && !isAssessment) {
      d(WORKSHOP_EVENTS_SUCCESS, data)
      d(WORKSHOP_EVENTS_FETCHING, true)
      return data
    }
  })
}

const deleteEvent = id => dispatch => {
  return api.events.deleteById(id).then(() => {
    dispatch({
      type: DELETE_EVENT_SUCCESS,
      payload: id
    })
    return Promise.resolve()
  })
}
const deletePodcast = id => dispatch => {
  return api.events.deleteById(id).then(() => {
    dispatch({
      type: DELETE_PODCAST_SUCCESS,
      payload: id
    })
    return Promise.resolve()
  })
}

const viewEvent = eventId => dispatch => {
  return api.events.viewEvent(eventId).then(({ data }) => {
    if (data) {
      dispatch({
        type: VIEW_EVENT_SUCCESS
      })
    }
  })
}

export { getEventsByMode, deleteEvent, deletePodcast, viewEvent }
