import React from 'react'
import { Button } from 'antd'

const FooterActions = ({ t, onClickCancel, onClickTitle, title, loading, isDisabled }) => {
  return [
    <Button
      type="default"
      shape="round"
      size="default"
      disabled={loading}
      onClick={onClickCancel}
      className="ant-btn rounded"
    >
      {t('buttons:cancel')}
    </Button>,
    <Button
      type="primary"
      shape="round"
      size="default"
      loading={loading}
      disabled={isDisabled}
      onClick={onClickTitle}
      className="ant-btn rounded ant-btn-primary"
    >
      {title}
    </Button>
  ]
}

export default FooterActions
