import React, { Component, Fragment } from 'react'
import { withTranslation } from 'react-i18next'
import _ from 'lodash'
import className from 'classnames'
import { isSafari } from 'react-device-detect'
import { general } from '../../../../../../../../../../constants'
import { Button, Row, Col, Tabs, Input, Modal } from 'antd'
import { activateSoundIcon, deactiveSoundIcon } from '../../../images'
import { Card } from '../../../../../../../../../../components/Card/styled'
import { AudioPlayer } from '../../../components'
import { saveBase64AsAudioFile } from '../../../../../../../../../../utils'
import { voices } from '../../../../../../../../../../constants'
import './EditAudio.scss'

const { TabPane } = Tabs
const { TextArea } = Input

class EditAudio extends Component {
  state = {
    src: ''
  }

  componentDidMount() {
    const {
      audioSynthesizer: { contentId }
    } = this.props

    if (contentId) {
      const audio_data = JSON.parse(localStorage.getItem('audio_data'))
      if (audio_data === null || _.isEmpty(audio_data)) {
        const { showEditAudioModal } = this.props
        showEditAudioModal({ isShowEditAudio: false })
        Modal.warning({
          title: 'Failed editing audio',
          content:
            'You must first create audio before you edit because Content IDs are currently stored temporarily in localstorage'
        })
        return
      }
      let getAudioLocalStorage = audio_data.filter(
        content => content.content_id === contentId
      )
      if (!_.isEmpty(getAudioLocalStorage)) {
        const { getSelectedTextVoiceType } = this.props
        getSelectedTextVoiceType({
          selectedText: getAudioLocalStorage[0].text,
          selectedVoice: getAudioLocalStorage[0].voice_type
        })
      }
    }
  }

  onChangeText = e => {
    const {
      getSelectedTextVoiceType,
      audioSynthesizer: {
        selected: { selectedVoice }
      }
    } = this.props
    selectedVoice
      ? getSelectedTextVoiceType({
          selectedText: e.target.value,
          selectedVoice: selectedVoice
        })
      : getSelectedTextVoiceType({ selectedText: e.target.value })
    this.setState({ src: '' })
  }

  onClickCard = value => {
    const {
      getSelectedTextVoiceType,
      audioSynthesizer: {
        selected: { selectedText }
      }
    } = this.props

    selectedText
      ? getSelectedTextVoiceType({
          selectedText: selectedText,
          selectedVoice: value
        })
      : getSelectedTextVoiceType({ selectedVoice: value })

    this.setState({
      src: ''
    })
  }

  onClickButton = () => {
    const {
      createAudioSynthesizer,
      audioSynthesizer: {
        selected: { selectedText, selectedVoice }
      },
      onShowEditDetailModal
    } = this.props
    const { src } = this.state
    if (!_.isEmpty(src)) {
      let block = src.split(';')
      let dataType = block[0].split(':')[1]
      let data = block[1].split(',')[1]
      let filename = `${selectedVoice +
        Math.floor(1000 + Math.random() * 9000)}` /* @TODO Update logic later*/
      let mp3_file = saveBase64AsAudioFile(filename, data, dataType)
      onShowEditDetailModal(mp3_file)
      return
    }

    createAudioSynthesizer({
      voice: selectedVoice,
      OutputFormat: 'mp3',
      text: selectedText
    }).then(data => {
      if (data.result) {
        this.setState({
          src: 'data:' + data.data.mimetype + ';base64,' + data.data.audio
        })
      }
    })
  }

  render() {
    const {
      t,
      audioSynthesizer: {
        isProcessing,
        selected: { selectedText, selectedVoice }
      }
    } = this.props
    const { src } = this.state
    const maleVoice = _.filter(voices.VOICES, item => item.gender === 'Male')
    const femaleVoice = _.filter(
      voices.VOICES,
      item => item.gender === 'Female'
    )
    const classNames = className('card-audio', {
      'card_on-select': selectedVoice
    })
    return (
      <Fragment>
        <div className="bg-modal">
          <Row gutter={24}>
            <Col span={17}>
              <div className={'textarea-warp'}>
                <TextArea
                  disabled={!!isProcessing}
                  value={selectedText}
                  onChange={this.onChangeText}
                  placeholder={t('audio:type_something')}
                  autoSize={{ minRows: 25, maxRows: 25 }}
                />

                <div className={isSafari ? 'audio-warp-safari' : 'audio-warp'}>
                  {_.isEmpty(src) && <AudioPlayer src="" />}{' '}
                  {!_.isEmpty(src) && <AudioPlayer src={src} />}
                  <Button
                    type="submit"
                    shape="round"
                    size="default"
                    disabled={
                      _.isEmpty(selectedText) ||
                      _.isEmpty(selectedVoice) ||
                      isProcessing
                    }
                    className="button-create btn-success"
                    onClick={() => this.onClickButton()}
                  >
                    {isProcessing
                      ? general.PROCESSING_AUDIO
                      : general.EDIT_AUDIO}
                  </Button>
                </div>
              </div>
            </Col>
            <Col span={7} className={'audio-tab'}>
              <Row>
                <Col span={12}>
                  <h3>{t('audio:select_voice')}</h3>
                </Col>
              </Row>
              <Tabs animated={false}>
                <TabPane tab={general.ALL} key="1">
                  <Row gutter={24}>
                    {voices.VOICES.map((item, i) => (
                      <Col className="gutter-row " span={12} key={i}>
                        <div className="gutter-box">
                          <div
                            className={
                              selectedVoice === item.value
                                ? classNames
                                : 'card-audio'
                            }
                            onClick={() => this.onClickCard(item.value)}
                          >
                            <div className="card__audio-thumb">
                              <Card.AudioIcon>
                                <Card.Icon>
                                  <img
                                    src={
                                      selectedVoice === item.value
                                        ? activateSoundIcon
                                        : deactiveSoundIcon
                                    }
                                    alt=""
                                  />
                                </Card.Icon>
                              </Card.AudioIcon>
                            </div>
                            <div className="card__content">
                              <Fragment>
                                <Card.AudioInfo>
                                  <div
                                    className="card__title"
                                    title={item.label}
                                  >
                                    {item.label}.mp3
                                  </div>
                                </Card.AudioInfo>
                              </Fragment>
                            </div>
                          </div>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </TabPane>
                <TabPane tab={general.MALE} key="2">
                  <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, 20]}>
                    {maleVoice.map((item, i) => (
                      <Col className="gutter-row" span={12} key={i}>
                        <div className="gutter-box">
                          <div
                            className={
                              selectedVoice === item.value
                                ? classNames
                                : 'card-audio'
                            }
                            onClick={() => this.onClickCard(item.value)}
                          >
                            <div className="card__audio-thumb">
                              <Card.AudioIcon>
                                <Card.Icon>
                                  <img
                                    src={
                                      selectedVoice === item.value
                                        ? activateSoundIcon
                                        : deactiveSoundIcon
                                    }
                                    alt=""
                                  />
                                </Card.Icon>
                              </Card.AudioIcon>
                            </div>
                            <div className="card__content">
                              <Fragment>
                                <Card.AudioInfo>
                                  <div
                                    className="card__title"
                                    title={item.label}
                                  >
                                    {item.label}.mp3
                                  </div>
                                </Card.AudioInfo>
                              </Fragment>
                            </div>
                          </div>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </TabPane>
                <TabPane tab={general.FEMALE} key="3">
                  {femaleVoice.map((item, i) => (
                    <Col className="gutter-row" span={12} key={i}>
                      <div className="gutter-box">
                        <div
                          className={
                            selectedVoice === item.value
                              ? classNames
                              : 'card-audio'
                          }
                          onClick={() => this.onClickCard(item.value)}
                        >
                          <div className="card__audio-thumb">
                            <Card.AudioIcon>
                              <Card.Icon>
                                <img
                                  src={
                                    selectedVoice === item.value
                                      ? activateSoundIcon
                                      : deactiveSoundIcon
                                  }
                                  alt=""
                                />
                              </Card.Icon>
                            </Card.AudioIcon>
                          </div>
                          <div className="card__content">
                            <Fragment>
                              <Card.AudioInfo>
                                <div className="card__title" title={item.label}>
                                  {item.label}.mp3
                                </div>
                              </Card.AudioInfo>
                            </Fragment>
                          </div>
                        </div>
                      </div>
                    </Col>
                  ))}
                </TabPane>
              </Tabs>
            </Col>
          </Row>
        </div>
      </Fragment>
    )
  }
}

export default withTranslation('')(EditAudio)
