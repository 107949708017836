import React from 'react'
import { Item } from './styled'
import ReactSVG from 'react-svg'

export default props => {
  const { url, exact, icon, heading } = props
  const to = url || '/'

  return (
    <Item>
      {url ? (
        <Item.Link to={to} exact={exact}>
          <Item.Inner>
            <Item.Icon>
              <ReactSVG src={icon} />
            </Item.Icon>
            <Item.Heading>{heading}</Item.Heading>
          </Item.Inner>
        </Item.Link>
      ) : (
        <Item.Link
          exact={exact}
          to={{}}
          className="none-active"
          target="_blank"
        >
          <Item.Inner>
            <Item.Icon>
              <ReactSVG src={icon} />
            </Item.Icon>
            <Item.Heading>{heading}</Item.Heading>
          </Item.Inner>
        </Item.Link>
      )}
    </Item>
  )
}
