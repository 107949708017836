import React, { Fragment } from 'react'
import className from 'classnames'
import moment from 'moment'
import { Card } from './styled'
import { Icon, Menu, Dropdown, Button } from 'antd'
import './VideoCard.scss'
import { withTranslation } from 'react-i18next'
import { VideoReactangleIconBig, videoPlayIcon16x20 } from '../../images'
import { general } from '../../../../../../../../../constants'

const menu = (props, data) => (
  <Menu>
    {/*<Menu.Item>
      <a target="_blank" rel="noopener noreferrer" href="">
        Rename
      </a>
    </Menu.Item>*/}
    <Menu.Item>
      <span
        style={{ color: '#1890ff', cursor: 'pointer' }}
        onClick={() => props.onEditVideoDetail(data.id)}
      >
        {props.t('media:edit_detail')}
      </span>
    </Menu.Item>
    <Menu.Item>
      <span
        style={{ color: '#1890ff', cursor: 'pointer' }}
        onClick={() => props.confirmDeleteItem({ data: data })}
      >
        {props.t('media:delete')}
      </span>
    </Menu.Item>
  </Menu>
)

const VideoCard = props => {
  const {
    data,
    type,
    cardSelected,
    handleViewDetails,
    data: { title, filename, horizontal, created_at, id, vimeo_id }
  } = props

  const classNames = className(
    'card-video',
    { card_horizontal: horizontal },
    { 'card_on-select': cardSelected }
  )
  return (
    <Fragment>
      <div className={classNames}>
        <div
          className="card__video-thumb"
          onClick={() => handleViewDetails(id, vimeo_id, data)}
        >
          <Card.VideoIcon cardSelected={cardSelected}>
            {/*<Card.Icon>
              {(is_synthesized_audio &&
                <img
                  src={cardSelected ? activeSynthesizedAudio : deactiveSynthesizedAudio}
                  alt=""
                />
              )}
              {(!is_synthesized_audio &&
                <img
                  src={cardSelected ? activateSoundIcon : deactiveSoundIcon}
                  alt=""
                />
              )}

            </Card.Icon>*/}

            {/*<div className="img-fit-into-card">
              <video controls width="250">

                <source src={link}
                        type="video/webm"/>

                <source src={link}
                        type="video/mp4"/>

                Sorry, your browser doesn't support embedded videos.
              </video>
            </div>*/}

            <div className="wrap-video-icons">
              <img
                className="img-fit-into-card"
                src={VideoReactangleIconBig}
                alt=""
              />
              <img
                className="play-icon-inside-list-rectangle"
                src={videoPlayIcon16x20}
                alt=""
              />
            </div>
          </Card.VideoIcon>
        </div>
        <div className="card__content">
          <Fragment>
            {type === general.MY_MEDIA_LIBRARY && (
              <Dropdown overlay={menu(props, data)} placement="bottomLeft">
                <Button>
                  <Icon type="more" />
                </Button>
              </Dropdown>
            )}

            <Card.VideoInfo>
              <div
                className="card__title"
                title={title || filename}
                onClick={() => handleViewDetails(id, vimeo_id, data)}
              >
                {title || filename}
              </div>
              {moment(created_at).format('DD MMM YYYY')}
            </Card.VideoInfo>
          </Fragment>
        </div>
      </div>
    </Fragment>
  )
}

export default withTranslation('video')(VideoCard)
