import React, { useState } from 'react'
import ReactEcharts from 'echarts-for-react'
import './style.scss'
import { useEffect } from 'react'
import colors from '../colors.json'
import noData from '../noData.png'
import { Col, Row } from 'antd'

const CategoryChart = ({ graphData, type }) => {
  const [chartData, setChartData] = useState([])
  const [isEmpty, setEmpty] = useState(false)
  useEffect(() => {
    if (graphData && graphData.total.length !== 0) {
      let total = 0
      if (type === 'USER') {
        graphData.total.forEach(category => {
          total = category.totalAttempts + total
        })
        let gData = graphData.total.map(el => ({
          ...el,
          value: parseFloat(((el.totalAttempts * 100) / total).toFixed(2))
        }))
        setChartData(gData)
      } else {
        graphData.total.forEach(category => {
          total = category.count + total
        })
        let gData = graphData.total.map(el => ({
          ...el,
          value: parseFloat(((el.count * 100) / total).toFixed(2))
        }))
        setChartData(gData)
      }
      setEmpty(false)
    } else {
      setEmpty(true)
    }
  }, [graphData])

  return (
    <div className="overview-chart-category">
      <div className="overview-chart-diagram">
        {isEmpty ? (
          <Row type="flex" justify="center" align="middle">
            <Col style={{ textAlign: 'center' }}>
              <img src={noData} alt="noData" height={200} />
            </Col>
          </Row>
        ) : (
          <ReactEcharts
            option={{
              tooltip: {
                trigger: 'item',
                formatter: '{a} <br/>{b} : {d}%'
              },
              legend: {
                orient: 'horizontal',
                type: 'scroll',
                icon: 'square',
                data: chartData.map(el => el.name)
              },
              toolbox: {
                show: true,
                feature: {
                  mark: { show: true },
                  magicType: {
                    show: true,
                    type: ['pie', 'funnel'],
                    option: {
                      funnel: {
                        x: '25%',
                        width: '50%',
                        funnelAlign: 'center',
                        max: 1548
                      }
                    }
                  }
                  // saveAsImage: { show: true, title: 'Download chart...' }
                }
              },
              series: [
                {
                  name: 'Category Average Activity',
                  type: 'pie',
                  radius: ['35%', '85%'],
                  // right: '-25%',
                  top: '10%',
                  label: {
                    show: true,
                    position: 'inner',
                    formatter: function(params) {
                      return params && params.value > 0
                        ? params.value + '%\n'
                        : ''
                    }
                  },
                  data: chartData,
                  color: colors
                }
              ]
            }}
          />
        )}
      </div>
    </div>
  )
}

export default CategoryChart
