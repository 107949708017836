import { Button, Icon, Modal } from 'antd'
import React, { useState } from 'react'

import BeginningOfLessonImg from '../../../../../../assets/beginning_of_lesson.svg'
import EndOfLessonImg from '../../../../../../assets/end_of_lesson.svg'
import CurrentlyActivePageImg from '../../../../../../assets/currently_active_page.svg'

const postions = {
  start: 'start',
  end: 'end',
  current: 'current'
}

function AiQuizPageSelection({ onClose, onPosition }) {
  const [selectedPosition, setSelectedPosition] = useState(postions.current)

  const onAdd = () => {
    Modal.confirm({
      title: 'Confirm Selection',
      content:
        'Are you sure you want to add the quiz to this page? This cannot be undone.',
      centered: true,
      onOk: () => {
        onPosition(selectedPosition)
        onClose()
      }
    })
  }

  return (
    <Modal centered visible={true} footer={null} width={'80%'} closable={false}>
      <div className="ai-quiz-page-selection-header">
        <div className="ai-quiz-page-selection-header-title">
          <h1>Select Quiz Page</h1>
          <p>Which part of the lesson do you want to add this quiz?</p>
        </div>

        <Icon onClick={onClose} className="close-icon" type="close" />
      </div>
      <div className="ai-quiz-page-selection-body">
        <div className="ai-quiz-page-selection-body-container">
          <div
            className={`ai-quiz-page-selection-body-container-item ${
              selectedPosition === postions.start ? 'active' : ''
            }`}
            onClick={() => setSelectedPosition(postions.start)}
          >
            <img src={BeginningOfLessonImg} alt="beginning_of_lesson" />
            <p>At the Beginning of Lesson</p>
          </div>
          <div
            className={`ai-quiz-page-selection-body-container-item ${
              selectedPosition === postions.end ? 'active' : ''
            }`}
            onClick={() => setSelectedPosition(postions.end)}
          >
            <img src={EndOfLessonImg} alt="beginning_of_lesson" />
            <p>End of Lesson</p>
          </div>
          <div
            className={`ai-quiz-page-selection-body-container-item ${
              selectedPosition === postions.current ? 'active' : ''
            }`}
            onClick={() => setSelectedPosition(postions.current)}
          >
            <img src={CurrentlyActivePageImg} alt="beginning_of_lesson" />
            <p>Currently Active Page</p>
          </div>
        </div>

        <div className="ai-quiz-page-selection-action">
          <Button
            shape="round"
            size="large"
            className="ai-quiz-page-selection-action-btn"
            onClick={onAdd}
          >
            Finish
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default AiQuizPageSelection
