import styled from 'styled-components'
import { Tabs } from 'antd'

import { styleguide } from '../../../constants'

const { colors } = styleguide

const Page = styled.div`
  width: 100%;
`

Page.Inner = styled.div`
  padding: 2.3rem;
`

const TabsCategories = styled(Tabs)`
  && {
    .ant-tabs-bar {
      border-top: .1rem solid #EFEFEF;
      border-bottom: 0;
    }

    .ant-tabs-tab {
      padding: 1.3rem 1.5rem;
      margin-right: 1.5rem;
      color: ${colors.darkGreyOne};
      font-weight: 500;

      &.ant-tabs-tab-active {
        font-weight: 600;
      }
    }

    .ant-tabs-ink-bar {
      top: 0;
      bottom: initial;
    }
  }
`

TabsCategories.Tab = styled(Tabs.TabPane)`
`

Page.TabsContent = styled.div`
  position: relative;
`

Page.Download = styled.a`
  font-weight: 600;
  position: absolute;
  top: 1rem;
  right: 0;
  z-index: 1;
`

Page.FilterCharts = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2.5rem;
`

Page.FilterCharts.Info = styled.div`
  margin-right: 2.5rem;
`

Page.Column = styled.div`
  display: flex;
  align-items: center;
`

const TabsCharts = styled(Tabs)`
  && {
    .ant-tabs-bar {
      border: 0;
      margin-bottom: 0;
    }

    .ant-tabs-content {
      border: .1rem solid #EFEFEF;
      padding: 2.5rem;
    }

    &.ant-tabs-card {
      .ant-tabs-card-bar {
        .ant-tabs-nav-container {
          height: auto;
        }

        .ant-tabs-tab {
          min-width: 10rem;
          line-height: 1;
          background-color: #EFEFEF;
          border: .1rem solid #EFEFEF;
          padding: 1.9rem 3.5rem;

          &.ant-tabs-tab-active {
            background-color: ${colors.white};
            padding-right: 3.5rem;
            margin-right: 0;
          }
        }
      }
    }
  }
`

TabsCharts.Tab = styled(Tabs.TabPane)`
`

TabsCharts.Tab.Title = styled.div`
  color: ${colors.darkGreyOne};
  text-align: center;

  .ant-tabs-tab-active & {
    font-weight: 700;
  }
`

export {
  Page,
  TabsCategories,
  TabsCharts
}
