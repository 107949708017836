import $ from 'jquery'; 

export class Integration {
	static MENU_INDENT = 20
	static PopulateMenuItemElement(
		rootElement,
		document,
		activity,
		indentLevel,
		menuItem
	) {
		const activityTitle = activity.getTitle()
		const activityIdentifier = activity.getIdentifier()
		const totalIndent = indentLevel * Integration.MENU_INDENT

		const link = document.createElement("a");
		link.id = "MenuItemText" + activityIdentifier;
		link.className = "menulink";
		link.text = activityTitle;
		link.style.paddingLeft = totalIndent + "px";
		rootElement.appendChild(link);
		rootElement.className = "menuitem";
		rootElement.title = activityTitle;
		if (menuItem.enabled) {
			// in this function we always need the menuItem to become enabled if it is marked enabled,
			// so forceably set it to not enabled, then in .Enable it will be switched back, this prevents
			// us from having to copy the things .Enable does here or pulling them out into yet another function
			menuItem.enabled = false;
			menuItem.enable(activityIdentifier);
		}

		if(activity && activity.scormSco && activity.scormSco.scormtype){
			link.className = "enabledMenulink"
		}

	}

	static GetHtmlElementToInsertMenuWithin() {
    return $("#MenuPlaceHolderDiv")[0];
	}

	static SetCurrentActivity(baseLocation){
		// console.log('activity', activity)
		console.log('baseLocation', baseLocation)
		const src =  `${baseLocation}`
		console.log('src log', src)
		$('#scromIframe')[0].src= src
	}

	static HideMenu(){
		$("#sha-sidebar").removeClass("col-md-3").addClass("sr-only");
    $("#sha-main").removeClass("col-md-9");
	}

	static ShowMenu(){
		$("#sha-sidebar").removeClass("sr-only").addClass("col-md-3");
    $("#sha-main").addClass("col-md-9");
	}

}