import {
  LIST_MAZES,
  GET_MAZE_BY_ID,
  UPDATE_MAZE,
  DELETE_MAZE,
  FETCH_MAZES,
  FETCH_MAZE_BY_ID,
  UPDATING_MAZE,
  RESET_MAZE_BUILDER,
  RESET_MAZE_LIST,
  WORKSHOP_MAZES_FETCHING,
  WORKSHOP_MAZES_SUCCESS,
  WORKSHOP_MAZES_HAS_MORE
} from '../types'

const initialState = {
  list: [],
  mazeById: {},
  fetchingList: false,
  fetchById: false,
  updatingMaze: false,
  workshopMazes: {
    list: [],
    fetch: false,
    hasMore: true
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LIST_MAZES:
      return {
        ...state,
        list: [...state.list, ...action.payload.data]
      }

    case GET_MAZE_BY_ID:
      return {
        ...state,
        mazeById: action.payload.data
      }

    case UPDATE_MAZE:
      return {
        ...state,
        mazeById: action.payload
      }

    case DELETE_MAZE:
      return {
        ...state,
        workshopMazes: {
          ...state.workshopMazes,
          list: state.workshopMazes.list.filter(i => i.id !== action.payload)
        }
      }

    case FETCH_MAZE_BY_ID:
      return {
        ...state,
        fetchById: action.payload
      }

    case FETCH_MAZES:
      return {
        ...state,
        fetchingList: action.payload
      }

    case UPDATING_MAZE: {
      return {
        ...state,
        updatingMaze: action.payload
      }
    }

    case RESET_MAZE_BUILDER: {
      return initialState
    }

    case RESET_MAZE_LIST: {
      return {
        ...state,
        list: []
      }
    }

    case WORKSHOP_MAZES_SUCCESS:
      return {
        ...state,
        workshopMazes: {
          ...state.workshopMazes,
          list: action.payload
        }
      }

    case WORKSHOP_MAZES_FETCHING:
      return {
        ...state,
        workshopMazes: {
          ...state.workshopMazes,
          fetch: action.payload
        }
      }

    case WORKSHOP_MAZES_HAS_MORE:
      return {
        ...state,
        workshopMazes: {
          ...state.workshopMazes,
          hasMore: action.payload
        }
      }

    default:
      return state
  }
}
