import React, { useState } from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { ImagePreview } from '../../../../../../../../../components'
import { Button } from 'antd'

const NextArrow = props => {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="white"
        width="24px"
        height="24px"
      >
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
      </svg>
    </div>
  )
}

const PrevArrow = props => {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="white"
        width="24px"
        height="24px"
      >
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
      </svg>
    </div>
  )
}

const ImageCarousel = ({ images, onSelectImage }) => {
  const [activeSlide, setActiveSlide] = useState(0)

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    beforeChange: (current, next) => setActiveSlide(next),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      }
    ]
  }

  const handleSelectImage = () => {
    if (onSelectImage && images[activeSlide]) {
      onSelectImage(images[activeSlide])
    }
  }

  return (
    <div
      className="carousel-container"
      style={{
        maxWidth: '800px',
        margin: '0 auto',
        position: 'relative',
        borderRadius: '10px'
      }}
    >
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index}>
            <ImagePreview
              src={image.link}
              style={{
                width: '100%',
                height: 'auto',
                minHeight: '400px',
                background: '#f4f5fa',
                borderRadius: '10px'
              }}
              alt={image.title || `Slide ${index + 1}`}
            />
          </div>
        ))}
      </Slider>
      <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <Button type="primary" onClick={handleSelectImage}>
          Select this Image
        </Button>
      </div>
    </div>
  )
}

export default ImageCarousel
