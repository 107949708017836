import React, { useState, useEffect } from 'react'
import { Col, Form, Input, Checkbox } from 'antd'
import Switch from '../../../../../components/Switch'
const BottomSection = props => {
  const {
    t,
    form: { getFieldDecorator },
    current: { marketplace },
    onFieldChange,
    handleSwitch
  } = props

  const [freeStatus, setfreeStatus] = useState(false)

  const onSwitchChange = val => {
    setfreeStatus(val)
    handleSwitch(val)
  }

  useEffect(() => {
    if (typeof marketplace.is_free === 'boolean')
      setfreeStatus(marketplace.is_free)
  }, [])

  return (
    <div className="event__mp__bottom">
      <div className="row">
        <Col md={24}>
          <Form.Item
            colon={false}
            required={false}
            label={t('v4:promotional_video_link')}
          >
            {getFieldDecorator('video_link', {
              rules: [{ required: true, message: t('v4:input_video_link') }],
              initialValue: marketplace.video_link
            })(
              <Input
                size="large"
                maxLength={100}
                onChange={e => onFieldChange(e.target.value)}
              />
            )}
          </Form.Item>
        </Col>
      </div>
      <div className="row">
        <Col md={12}>
          <div className="flex__row">
            <Form.Item colon={false} required={false}>
              {getFieldDecorator('is_free', {
                initialValue: marketplace.is_free
              })(
                <Switch
                  label={` ${t('general:free')}  ${t('general:event')}`}
                  onChanged={e => onSwitchChange(e)}
                />
              )}
            </Form.Item>
            <Form.Item
              colon={false}
              required={false}
              className="price_style"
              label={t('labels:price (usd)')}
            >
              {freeStatus ? (
                <Input
                  size="large"
                  type="number"
                  min={5}
                  maxLength={100}
                  onChange={e => onFieldChange(e.target.value)}
                  disabled={freeStatus === null ? false : freeStatus}
                />
              ) : (
                getFieldDecorator('price', {
                  initialValue: marketplace.price,
                  rules: [
                    {
                      validator: (rule, value) => value >= 5,
                      required: false,
                      message: t('v4:enter_price')
                    }
                  ]
                })(
                  <Input
                    size="large"
                    type="number"
                    min={5}
                    maxLength={100}
                    onChange={e => onFieldChange(e.target.value)}
                    disabled={freeStatus}
                  />
                )
              )}
            </Form.Item>
          </div>
        </Col>
      </div>
      <div className="row">
        <Form.Item colon={false} required={false}>
          {getFieldDecorator('is_shared_profile', {
            valuePropName: 'checked',
            initialValue: marketplace.hasOwnProperty('is_shared_profile')
              ? marketplace.is_shared_profile
              : false
          })(
            <Checkbox
              className="marketplace__checkbox"
              onChange={e => onFieldChange(e.target.value)}
            >
              {t('v3:allow_sharing_trainer_info')}
            </Checkbox>
          )}
        </Form.Item>
      </div>
    </div>
  )
}

export default BottomSection
