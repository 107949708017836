/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-08-07 15:55:38
 * @LastEditTime: 2019-08-27 14:45:00
 * @LastEditors: Please set LastEditors
 */
import { call, put, select } from 'redux-saga/effects'
// import _ from 'lodash'
import {
  getLessonsList,
  lessonCreate,
  getLessonById,
  lessonUpdate
} from '../../services/api/lessons'

import * as lessons from '../actions/lessonBuilder'

export function * createLesson (action) {
  yield put(lessons.setSavingLesson(true))
  try {
    const response = yield call(lessonCreate, action.data, action.id)

    if (response.status === 200) {
      const lessonId = response.data.id

      yield call(lessonUpdate, action.data, lessonId)
      yield put(lessons.handleLessonInput('lessonId', lessonId))
    }
    yield put(lessons.setSavingLesson(false))
  } catch (err) {
    console.log(JSON.stringify(err))
  }
}

export function * updateLesson (action) {
  try {
    yield put(lessons.setSavingLesson(true))
    const state = yield select()
    const response = yield call(lessonUpdate, action.data, action.id)
    const currentPages = state.lessonBuilder.pages
    // const incomingPages = response.data.pages

    // const diff = _.differenceWith(currentPages, incomingPages, _.isEqual)
    // console.log('diff:')
    // console.log(diff)

    if (currentPages.find(i => !i.id)) {
      yield put(lessons.loadSingleLesson(response.data))
    }

    yield put(lessons.setSavingLesson(false))
  } catch (err) {
    yield put(lessons.setSavingLesson(false))
  }
}

export function * fetchLessons (action) {
  yield put(lessons.fetchLessonsRequest(true))

  try {
    const response = yield call(getLessonsList, action.payload)
    const data = response.data
    yield put(lessons.loadLessons(data))
    yield put(lessons.fetchLessonsRequest(false))
  } catch (err) {
    yield put(lessons.fetchLessonsRequest(false))
  }
}

export function * fetchLessonById (action) {
  yield put(lessons.fetchLessonRequest())

  try {
    const response = yield call(getLessonById, action.id)
    if (response.status === 200) {
      yield put(lessons.loadSingleLesson(response.data))
    }
  } catch (err) {
    console.log(JSON.stringify(err))
  }
}
