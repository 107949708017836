import React from 'react'
import { CustomScrollbars } from './styled'

export default props => {
  const { children, type, minimize } = props

  return (
    <CustomScrollbars
      {...props}
      type={type}
      minimize={minimize}
      autoHeightMax="100%"
      renderTrackVertical={({ style, ...props }) => (
        <CustomScrollbars.Track
          {...props}
          className="track-vertical"
          style={{ ...style }}
        />
      )}
      renderTrackHorizontal={({ style, ...props }) => (
        <CustomScrollbars.Track
          {...props}
          className="track-horizontal"
          style={{ ...style }}
        />
      )}
      renderThumbVertical={({ style, ...props }) => (
        <CustomScrollbars.Thumb {...props} style={{ ...style }} />
      )}
      renderThumbHorizontal={({ style, ...props }) => (
        <CustomScrollbars.Thumb {...props} style={{ ...style }} />
      )}
    >
      {children}
    </CustomScrollbars>
  )
}
