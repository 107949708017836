import { api } from '../../services'
import { TOPICS_SUCCESS, TOPICS_FETCHING, UPDATE_NEW_TOPIC } from '../types'

const getTopics = () => dispatch => {
  dispatch({
    type: TOPICS_FETCHING,
    payload: true
  })

  return api.topics.getTopics().then(response => {
    dispatch({
      type: TOPICS_SUCCESS,
      payload: response.data
    })
    dispatch({
      type: TOPICS_FETCHING,
      payload: false
    })
    return response.data
  })
}

const updateTopicList = newTopic => dispatch => {
  dispatch({
    type: UPDATE_NEW_TOPIC,
    payload: newTopic
  })
}

export { getTopics, updateTopicList }
