import styled from 'styled-components'

const Menu = styled.div``

Menu.List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`

Menu.Item = styled.li`
  list-style: none;
`

export {
  Menu
}
