/*
* @Description: In User Settings Edit
* @Author: your name
* @Date: 2019-08-09 16:41:54
* @LastEditTime: 2019-12-19 10:53:49
* @LastEditTime: 2019-09-09 11:43:43
* @LastEditors: Please set LastEditors
*/

export default {
  general: {
    save: 'Guardar',
    autosaved: 'Autoguardado',
    back_to: 'Volver a',
    by: 'Por',
    chat: 'Chat',
    completed: 'Completado',
    canceled: 'Cancelado',
    component: 'Componente',
    confirm: 'Confirmar',
    course: 'Curso',
    date: 'Fecha',
    details: 'Detalles',
    empty: 'Sin datos',
    enrolled: 'Inscrito',
    free: 'Gratis',
    graph: 'Gráfico',
    loading: 'Cargando...',
    lesson: 'Lección',
    lessons: 'Lecciones',
    live_assessment: 'Evaluación en vivo',
    live_event: 'Evento en vivo',
    no: 'No',
    of: 'De',
    or: 'O',
    organization: 'Organización',
    page: 'Página',
    pages: 'Páginas',
    personal: 'Personal',
    poll: 'Encuesta',
    rating: 'Valoración',
    reset: 'Reiniciar',
    signin: 'Iniciar Sesión',
    signup: 'Registrarse',
    soon: 'Pronto',
    type: 'Escribir',
    uploading: 'Subiendo...',
    yes: 'Sí',
    something_went_wrong: '¡Algo ha ido mal!',
    view_all: 'Ver todo',
    upload_successful: 'Carga Exitosa',
    upload_successful_message:
      'Sus documentos de Excel han sido cargados con éxito y están siendo procesados. Esto puede tardar varios minutos.',
    check_email_upload_successful:
      'Por favor <0>revise su correo electrónico </0> para el <1>informe</1> de la lista de usuarios cargada.',
    is_private: 'Privado'
  },

  account: {
    title: 'Cuenta',
    subtitle: 'Gestione la configuración y la información de su cuenta.',
    saved: 'Cuenta modificada con éxito'
  },

  assessments: {
    title: 'Evaluaciones',
    subtitle: 'Crear una evaluación',
    finished: 'La evaluación ha finalizado',
    countdown: 'La evaluación en vivo será transmitida en ',
    create: 'Crear una Evaluación',
    list_title: 'Sus Evaluaciones',
    delete_warning: '¿Está seguro de querer eliminar este Elemento? ',
    details_title: 'Agregar Detalles de la Evaluación',
    details_subtitle:
      'Nombre su Evaluación y dígale a su audiencia lo que pueden aprender de la misma.',
    disclaimer: `Garantizo que mi evaluación:
    - no infringe los derechos de terceros
    - no contiene ningún material ilegal, incluyendo contenido difamatorio, obsceno, indecente, lascivo, pornográfico, violento, abusivo, insultante, amenazador, o discriminatorio, ni siquiera disfrazado con asteriscos
    - no contiene ningún ataque contra otras personas
    - no contiene ninguna suplantación de otros participantes, figuras públicas o celebridades
    - no contiene registro alguno o información sobre ninguna persona sin su consentimiento y no infringe ninguna norma de protección de datos en ninguna parte del mundo
    - no contiene ninguna solicitud comercial o forma de “spam”'`
  },

  buildersettings: {
    title: 'Configuraciones de Publicación',
    subtitle:
      'Configure quién puede ver e interactuar con su evento'
  },

  buttons: {
    market_place: 'Market Place',
    back_to_normal: 'Back To Normal',
    add: 'Agregar',
    add_page: 'Agregar Página',
    add_lesson: 'Agregar Lecciones',
    add_answer: 'Agregar nueva respuesta',
    add_group: 'Agregar Grupo',
    add_organization: 'Agregar Organización',
    add_question: 'Agregar pregunta',
    add_quiz: 'Agregar Quiz',
    add_users: 'Agregar usuarios',
    back: 'Volver',
    back_to_course: 'Volver a la Vista General del Curso',
    back_to_lesson: 'Volver a la Vista General de la Lección',
    back_to_media: 'Volver a Mediateca',
    back_to_details: 'Volver a detalles',
    cancel: 'Cancelar',
    clear: 'Limpiar',
    close: 'Cerrar',
    clone: 'Duplicar',
    close_lesson: 'Cerrar Lección',
    clone_course: 'Duplicar Curso',
    confirm: 'Confirmar',
    create: 'Crear',
    download: 'Descargar',
    download_mass_user_tamplate: 'Descargar Plantilla',
    add_mass_users: 'Agregar Usuarios Masivamente',
    upload_excel_list: 'Cargar Lista de Excel',
    delete: 'Eliminar Contenido Multimedia',
    edit: 'Editar',
    edit_course: 'Editar Curso',
    enroll: 'Inscribirse',
    finish: 'Finalizar',
    upload_image: 'Subir imagen',
    upload_image_video: 'Subir imagen o vídeo',
    select_media_library: 'Seleccionar mediateca',
    make_public: 'Hacer Público',
    make_private: 'Hacer Privado',
    make_org: 'Hacer Organización',
    media: 'Mediateca',
    my_theme: 'Mi tema',
    next: 'Siguiente',
    next_lesson: 'Siguiente lección',
    next_step: 'Paso Siguiente',
    previous_step: 'Paso Anterior',
    publish: 'Publicar',
    publish_assessment: 'Publicar su Evaluación',
    publish_course: 'Publicar su curso',
    publish_event: 'Publicar su evento',
    reset: 'Reiniciar',
    reset_elements: 'Reiniciar elementos',
    save: 'Guardar',
    creating: 'Creando...',
    updating: 'Actualizando...',
    saved: 'Guardado',
    save_for_later: 'Guardar para después',
    save_settings: 'Guardar ajustes',
    select_files: 'Seleccionar archivos',
    select_theme: 'Seleccionar tema',
    start_lesson: 'Iniciar lección',
    update: 'Actualizar',
    upload: 'Cargar',
    upload_audio: 'Cargar Audio',
    upload_photo: 'Cargar foto de perfil',
    upload_video: 'Cargar Vídeo',
    enrolled: 'Inscrito',
    unenroll: 'Darse de baja',
    from_library: 'Mediateca',
    remove: 'Eliminar',
    removed: 'Eliminado',
    start_broadcast: 'Iniciar Transmisión',
    end_broadcast: 'Finalizar Transmisión',
    camera: 'Cámara',
    screen: 'Pantalla',
    screen_with_camera: 'Pantalla con Cámara'
  },

  card: {
    quick_add: 'Agregar rápido',
    add: 'Agregar',
    clone: 'Duplicar',
    completed: 'lecciones completadas'
  },

  course: {
    upcoming_course: 'Próximo Curso',
    unPublishEvent: 'Cancelar Publicación del Evento',
    unPublishAssessment: 'Cancelar Publicación de Evaluación',
    pastCourse: 'Curso de Capacitación',
    upcoming: 'Próximo Evento en Vivo',
    pastEvent: 'Evento en Vivo Anterior',
    pastAssessment: 'Evaluación en Vivo Anterior',
    upcomingAssessment: 'Próxima Evaluación en Vivo',
    created: 'Fecha de Creación:',
    assignees: 'Cesionario',
    organisations:
      ' {{ organizations }} Organizaciones, <2> {{ users }} usuarios</2>',
    event_date: 'Fecha de evento en vivo <1> {{ date }} </1>',
    delete_warning: '¿Está seguro de querer eliminar esto?',
    clonning_no_lesson:
      'Por favor agregue al menos una Lección para duplicar este Curso.',
    clone_warning: '¿Está seguro de querer duplicar esto?',
    clone_success: 'Está en proceso de duplicación',
    disclaimer: `Garantizo que mi curso:
    - no infringe los derechos de terceros
    - no contiene ningún material ilegal, incluyendo contenido difamatorio, obsceno, indecente, lascivo, pornográfico, violento, abusivo, insultante, amenazador, o discriminatorio, ni siquiera disfrazado con asteriscos
    - no contiene ningún ataque contra otras personas
    - no contiene ninguna suplantación de otros participantes, figuras públicas o celebridades
    - no contiene registro alguno o información sobre ninguna persona sin su consentimiento y no infringe ninguna norma de protección de datos en ninguna parte del mundo
    - no contiene ninguna solicitud comercial o forma de “spam” `
  },

  coursecreate: {
    dropzone_text: 'Insertar multimedia',
    sidebar_text: 'Seleccione un tema <1/> para sus <3/> lecciones',
    delete_warning: '¿Está seguro de querer eliminar esta lección?'
  },

  coursepage: {
    quizview_loading: 'Revisando respuestas. Por favor espere...',
    quizview_answer: 'Su respuesta'
  },

  coursequiz: {
    title: 'Quizzes en todas las lecciones'
  },

  dashboard: {
    tooltip_title: 'No mostrar este vídeo nuevamente',
    page_title: 'Estadística de sus cursos’',
    page_subtitle: 'Estado de los cursos creados por usted',
    profile_add_info:
      'Agregue su <1>{{uncompletedText}}</1> para completar su perfil.',
    profile_build:
      'Cree su perfil <1>Aquí</1>, háganos saber sus intereses y habilidades.',
    profile_title: 'Fortaleza del Perfil',
    more_details: 'Más detalles'
  },

  errors: {
    answer: 'Por favor ingrese su respuesta',
    answer_correct: 'Seleccione la respuesta correcta',
    code: 'Por favor ingrese su código',
    criterion: 'Por favor ingrese el criterio',
    duration: 'La duración es obligatoria ',
    element: 'Por favor ingrese el elemento',
    email: 'Por favor ingrese su correo electrónico',
    email_invalid: 'El correo electrónico no es válido',
    feedback_correct: 'Por favor ingrese la retroalimentación caso correcto',
    feedback_incorrect: 'Por favor ingrese la retroalimentación caso incorrecto',
    host: 'Por favor ingrese al anfitrión del evento',
    host_assessment: 'Por favor ingrese al anfitrión de la evaluación',
    image: 'Por favor suba una imagen',
    location: 'Por favor ingrese su ubicación',
    live_location: 'Por favor elija una ubicación de transmisión',
    limit: 'Por favor ingrese minutos de transmisión en vivo',
    min_3char: 'Mínimo 3 caracteres',
    number: 'por favor ingrese un número positivo.',
    host_email_require: `Los correos electrónicos del anfitrión son obligatorios.`,
    attendee_email_require: `Los correos electrónicos de los asistentes son obligatorios.`,
    name: 'Por favor ingrese su nombre',
    not_found_course: 'Curso no encontrado',
    not_found_event: 'Evento no encontrado',
    not_found_assessment: 'Evaluación no encontrada',
    password: 'Por favor ingrese su contraseña',
    password_digit: 'Al menos un dígito',
    password_short: 'Contraseña muy corta, mínimo 10 caracteres',
    password_special: 'Al menos un caracter especial',
    password_uppercase: 'Al menos un caracter en mayúscula',
    password_lowercase: 'Al menos un caracter en minúscula',
    phone: 'Por favor ingrese su número telefónico',
    phone_invalid: 'Número telefónico inválido',
    position: 'Por favor ingrese su posición',
    question: 'Por favor ingrese su pregunta',
    start_date: 'Fecha y Hora de Inicio son obligatorias ',
    tag: 'Por favor seleccione una etiqueta',
    topics: 'Por favor seleccione al menos un tema.',
    live_topics: 'Por favor agregue temas',
    title: 'Por favor ingrese el título',
    title_assessment: 'Por favor ingrese el título de la evaluación',
    title_course: 'Por favor ingrese el título del curso',
    title_empty: 'El título no puede estar vacío',
    title_event: 'Por favor ingrese el título del evento',
    title_lesson: 'Por favor ingrese el título de la lección',
    title_quiz: 'Por favor ingrese el nombre del quiz',
    username: 'Por favor ingrese su nombre de usuario',
    upload_error: 'Error – ¡Archivo no compatible!',
    upload_more_than_10: 'Sólo puede subir un máximo de 10 archivos a la vez.',
    topics_max: 'Por favor agregue 3 temas como máximo.',
    upload_excel: 'Por favor asegúrese de haber cargado el archivo correcto.',
    title_excel: 'Error al cargar la plantilla:',
    file_size_exceed: 'Tamaño de archivo excedido',
    subtitle_excel:
      'Por favor asegúrese de utilizar la plantilla proporcionada y que las columnas hayan sido llenadas correctamente.',
    select_broadcast_option: 'Por favor seleccione una opción para transmitir este evento',
    multi_hosts_number_error:
      'El No. de presentadores no puede exceder lo especificado para el evento.',
    conference_people_number_error: `El No. de asistentes no puede exceder lo especificado para el evento.`,
    select_max_host: 'Seleccione al menos 1 presentador para el evento.',
    select_max_conference: 'Seleccione al menos 1 asistente para el evento.',
    stream_stop_error: 'La Transmisión en vivo se Detuvo Inesperadamente!',
    unsecure_context:
      'Error Fatal: El navegador no puede acceder a la cámara ni al micrófono debido a un contexto inseguro. Por favor instale SSL e ingrese vía https',
    ws_not_supported: 'Error Fatal: WebSocket no es compatible con este navegador'
  },

  events: {
    title: 'Agregue Detalles del Evento en Vivo',
    change_host: 'Cambie el Anfitrión',
    finished: 'El evento ha finalizado',
    countdown: 'Evento en vivo se transmitirá en ',
    subtitle:
      'Nombre su evento en Vivo y dígale a su audiencia lo que pueden aprender del mismo.',
    poll_disabled: 'La encuesta no está disponible en este evento',
    disclaimer: `Garantizo que mi evento:
    - no infringe los derechos de terceros
    - no contiene ningún material ilegal, incluyendo contenido difamatorio, obsceno, indecente, lascivo, pornográfico, violento, abusivo, insultante, amenazador, o discriminatorio, ni siquiera disfrazado con asteriscos
    - no contiene ningún ataque contra otras personas
    - no contiene ninguna suplantación de otros participantes, figuras públicas o celebridades
    - no contiene registro alguno o información sobre ninguna persona sin su consentimiento y no infringe ninguna norma de protección de datos en ninguna parte del mundo
    - no contiene ninguna solicitud comercial o forma de “spam”`,
    prompt_message:
      'Al salir de esta página terminará su evento. ¿Está seguro de salir de esta página? ',
    duration_exceed_message:
      'Tiempo de transmisión excedido, la aplicación dejará de transmitir. ',
    before_schedule_message:
      'Usted está a punto de iniciar la transmisión antes de la hora del evento programado. Tenga en cuenta que el evento finalizará al detener la transmisión.',
    browser_unsupportive_message: 'Su navegador no admite compartir la pantalla. Puede ver los navegadores compatibles en este enlace',
    cancel_switch_host_message:
      'El anfitrión no está disponible. El evento será continuado por usted.',
    coaching_prompt_message:
      'No puede dejar esta página hasta que el evento haya terminado.',
    event_modal_warn: '¿Quiere dejar este evento?',
    confirm_message: 'Su evento terminará si hace clic en OK.',
    screen_share_denied: 'Denegó compartir su pantalla',
    media_not_found:
      'La Cámara o el Micrófono no se encuentran o su uso no se permitió en su dispositivo.',
    media_not_access:
      'La Cámara o el Micrófono están siendo utilizados por algún otro proceso que no permite reconocer los dispositivos',
    constraint_error:
      'No se ha encontrado ningún dispositivo que se ajuste a sus restricciones de audio y vídeo. Puede cambiar las restricciones de audio y vídeo',
    media_access_denied: 'No se le permite acceder a la cámara y al micrófono.'
  },

  filters: {
    days7: 'Últimos 7 días',
    days15: 'Últimos 15 días',
    days30: 'Últimos 30 días',
    days365: 'Últimos 365 días',
    date: 'Fecha de Creación',
    relevance: 'Relevancia',
    popularity: 'Popularidad',
    name: 'Nombre',
    group: 'Grupo',
    Newest_to_Oldest: 'Del más Reciente al más Antiguo',
    Oldest_to_Newest: 'Del más Antiguo al más Reciente',
    A_Z: 'De la A a la Z',
    Z_A: 'De la Z a la A'
  },

  helpers: {
    password:
      'Para garantizar la seguridad de su cuenta, requerimos al menos una minúscula, número en su contraseña. Deberá tener mínimo 10 caracteres.',
    phone: 'Por favor incluya el código telefónico de su país, por ejemplo: +65',
    username:
      'Su nombre de usuario es considerado como información pública y se comparte con otros usuarios cuando es necesario. Por tal motivo, le recomendamos que no utilice información personal en su nombre de usuario.',
    public_description_normal:
      'Su <0><0/></0> se ha guardado y se considera Privado por defecto. Esto significa que sólo usted puede visualizar este contenido. Para publicarlo puede seleccionar una de las siguientes opciones. Publicar su curso en Público significa que estará disponible para todos los usuarios de la plataforma. La opción Usuarios Específicos le permitirá compartir su curso con usuarios específicos utilizando las direcciones de correo electrónico asociadas con sus cuenta de Sharelook.',
    public_description_admin:
      'Este <0><0/></0> se ha guardado y se considera Privado por defecto.  Esto significa que sólo usted (y el creador del contenido) pueden visualizarlo. Para publicar este contenido puede seleccionar una de las siguientes opciones. Publicar su curso en Público significa que estará disponible para todos los usuarios de la plataforma - incluyendo aquellos fuera de su organización. Publicarlo a la organización significa que todos los usuarios dentro de la organización podrán visualizarlo. También puede publicarlo en 1 o más grupos, lo que significa que sólo los usuarios dentro de esos grupos podrán visualizarlo. Por último, tiene la opción de publicarlo a usuarios específicos dentro y fuera de su organización utilizando sus direcciones de correo electrónico de Sharelook.'
  },

  labels: {
    add: 'Agregar',
    portrait: 'Crop Portrait',
    landscape: 'Crop Landscape',
    answer_type: 'Tipo de respuesta',
    answers: 'Respuestas',
    back_to_quiz: 'Volver a la lista de Quiz',
    back_to_question: 'Volver a la lista de preguntas',
    chat: 'Chat',
    chat_available: 'El Chat no está disponible en este evento',
    chat_report: 'Informe del Chat',
    choice_single: 'Selección Simple',
    choice_multiple: 'Selección Múltiple',
    choice_free: 'Selección "libre"',
    choice_sorting: 'Selección por "Sorteo"',
    choice_matrix: 'Selección de Clasificación de "Matriz" ',
    clone_lesson_permission: 'Permiso para Duplicar',
    code: 'Código',
    correct: 'Correcto',
    created: 'Fecha de creación',
    clone_modal: 'Duplicar curso',
    cloning_course: 'Duplicando curso...',
    cloning: 'Duplicando...',
    cloning_course_desc: 'Este proceso puede tardar. Por favor espere',
    cloning_success: 'Curso "<0/>" duplicado satisfactoriamente',
    description: 'Descripción',
    disclaimer: 'Descargo de Responsabilidad',
    drop_file: 'Suelte el archivo aquí...',
    duration: 'Duración',
    edit: 'Editar',
    email: 'Correo electrónico',
    event: 'Evento',
    event_all: 'Todos sus Eventos',
    event_date: 'Fecha/Hora Local del Evento en Vivo',
    event_this: 'Este Evento',
    expire: 'Expira en',
    expiry_date: 'Fecha de expiración',
    forgot: '¿Olvidó su contraseña?',
    group: 'Grupo',
    groups_list: 'Lista de Grupos',
    host: 'Anfitrión',
    id_annotation: 'id de Anotación',
    id_quiz: 'id del Quiz',
    incorrect: 'Incorrecto',
    interests: 'Intereses',
    invited: 'Invitado',
    live_chat: 'Debate en vivo',
    live_stream_limit: 'Minutos de transmisión en vivo',
    contact_text: 'Para cambiar su límite mensual de transmisión en vivo, contáctenos',
    contact_admin:
      '<0>Si desea cambiar su límite mensual de transmisión en vivo, contáctenos al correo </0> <1>admin@sharelook.com</1>',
    limit_stream_text:
      'Minutos de Transmisión en Vivo Restantes: <0>{{ limit }}</0> minutos <1/><2/> <3>Para cambiar su límite mensual de transmisión en vivo, contáctenos al correo </3> <4>admin@sharelook.com</4>',
    location: 'Ubicación',
    lessons: 'Lecciones',
    location_broadcast: 'Ubicación del Servidor del Anfitrión',
    location_broadcast_subtitle:
'Agregue una ubicación que sea la más cercana a la ubicación del anfitrión de la transmisión para reducir el retraso audiovisual',
    media_manager: 'Administrar Contenido Multimedia',
    minutes: 'Minutos',
    name: 'Nombre',
    online_quiz: 'Quiz en Línea',
    organiser: 'Autor',
    organization: 'Organización',
    overall_emotion: 'Experiencia General:',
    organizations_list: 'Lista de Organizaciones',
    password: 'Contraseña',
    participants: 'Participantes',
    picture: 'Imagen (16:9)',
    phone: 'Teléfono',
    photo: 'Foto',
    poll: 'Encuesta',
    position: 'Posición',
    processing: 'Procesando',
    privacy_settings: 'Ajustes de privacidad',
    question: 'Preguntas',
    quiz_create: 'Crear Nuevo Quiz',
    quiz_name: 'Nombre del Quiz',
    quiz_update: 'Actualizar quiz',
    select_organization: 'Seleccione una organización',
    settings: 'Ajustes',
    show: 'Mostrar',
    show_graph: 'Mostrar resultado gráfico',
    signin: 'Iniciar sesión',
    signup: 'Registrarse',
    sort_by: 'Ordenar Por',
    start_date: 'Fecha de Inicio',
    tags: 'Etiquetas',
    terms: 'Estoy de acuerdo con estas <1>condiciones de uso</1>.',
    title: 'Título',
    topics: 'Temas',
    topic: 'Tema',
    total_comments: 'Número de Comentarios',
    username: 'Nombre de Usuario',
    users_list: 'Lista de Usuarios',
    users_emails: 'Insertar correos electrónicos de usuarios específicos',
    description_specifiction_email:
'Por favor coloque 1 dirección de correo electrónico por línea para compartir esto con varios usuarios específicos.',
    message_specific_email: 'Por favor introduzca el correo electrónico del usuario específico.',
    viewers: 'Espectadores',
    edit_course: 'Editar',
    edit_assessment: 'Editar',
    edit_event: 'Editar',
    delete_course: 'Eliminar',
    delete_event: 'Eliminar',
    delete_assessment: 'Eliminar',
    view_course: 'Ver',
    view_event: 'Ver',
    view_assessment: 'Ver',
    date_event: 'Fecha de evento en vivo',
    date_assessment: 'Fecha de evaluación en vivo',
    clone_of: 'Duplicar',
    clone_course:
'Crear un duplicado de este curso que se puede modificar independientemente del original, incluyendo todas las lecciones y páginas.',
    make: 'Hacer',
    available_to: 'disponible para cualquier usuario de Sharelook',
    uploaded_by: 'Cargado por: ',
    course: 'Curso',
    assessment: 'Evaluación',
    user_not_exist: 'El usuario no existe, ¿quiere enviar una invitación?',
    check_user: 'Aviso',
    user_not_exist_in_org:
'El usuario no existe en esta organización, ¿quiere enviar una invitación?',
    title_modal_stream: 'Cambiar Límite de Transmisión en Vivo de la Organización',
    des_warning_stream: 'Está a punto de cambiar',
    monthly_stream: 'límite mensual de transmisión en vivo:',
    min_per_month: 'minutos/mes',
    broadcast_label: 'Quiero hacer:',
    hosts_emails: `Insertar correos electrónicos específicos del anfitrión`,
    attendee_emails: `Insertar correos electrónicos específicos de asistentes`,
    description_host_specifiction_email: 'Por favor coloque 1 dirección de correo electrónico por línea.',
    presenter_label: 'Presentador',
    presenter_label_conference: 'No. de personas',
    event_privacy: 'Modo conferencia'
  },

  broadcastOptions: {
    stream_from_phone: 'Transmisión en Vivo con mi teléfono',
    other_webrtc: 'Webminar desde mi PC',
    multihost: 'Multidifusión de Webinar desde mi PC',
    peer2peer: 'Sesión de capacitación',
    conference: 'Conferencia en Vivo'
  },

  lessoncreate: {
    title: 'Agregar detalles de la lección',
    subtitle:
  ' Nombre su lección y dígale a su audiencia lo que pueden aprender de la misma.',
    input_label_title: 'Título de la lección',
    input_label_topics: 'Temas de la lección',
    input_label_description: 'Descripción de la lección',
    delete_warning: '¿Está seguro de querer eliminar esta página?'
  },

  lessonoverview: {
    title: 'Agregar Lección',
    subtitle: 'Agregar lección existente o crear una nueva',
    new_lesson: 'Crear una nueva lección',
    new_lesson_sub: '¡Crear una nueva lección desde cero!',
    existing_lesson: 'Seleccionar lección existente',
    existing_lesson_sub:
  'Enlace a una lección existente, los cambios realizados en esta lección también afectarán esta lección en otros cursos.',
    clone_lesson: 'duplicar lección existente',
    clone_lesson_sub:
  'Hacer una copia única de la lección en este curso, los cambios realizados en esta lección no afectarán la lección original.',
    clone_lesson_modal_sub:
  'Por favor haga clic en el botón duplicar debajo de la lección que le gustaría añadir a su curso.'
  },

  management: {
    groups_title: 'Administrar grupos',
    delete_warning: '¿Está seguro?',
    organizations_title: 'Mis Organizaciones',
    organizations_subtitle: 'Agregar organizaciones a su lista',
    users_title: 'Administrar usuarios',
    users_position: 'Gerente de Cuenta Senior',
    user_not_found: 'Usuario no Encontrado',
    select_org: 'Por favor busque y elija una organización para administrar a los usuarios'
  },

  audio: {
    modal_title: 'Insertar Audio',
    list_view: 'Ver Lista',
    create_audio_modal_title: 'Crear Audio',
    edit_audio_modal_title: 'Editar Audio',
    public_audio_not_found: 'Audio no encontrado.',
    private_audio_not_found: 'No ha cargado ningún audio todavía',
    are_you_sure_to_delete: '¿Está seguro de querer eliminarlo?',
    type_something: 'Escribir Algo',
    select_voice: 'Seleccionar',
    file_name: 'Nombre del Archivo',
    description: 'Descripción',
    topic: 'Tema',
    key_words_tag: 'Etiqueta de palabras clave',
    mp3_audio: 'Audio MP3',
    created: 'Creado',
    duration: 'Duración',
    close: 'cerrar'
  },

  image: {
    modal_title: 'Insertar Imagen',
    image: 'Imagen',
    pixel: 'Píxel',
    dimension: 'Dimensión',
    public_image_not_found: 'Imagen no encontrada.',
    private_image_not_found: 'No ha cargado ninguna imagen todavía'
  },

  annotation: {
    modal_title: 'Anotación'
  },

  text: {
    modal_title: 'Editor de Texto'
  },

  video: {
    modal_title: 'Insertar Vídeo',
    image: 'Video',
    mp4_video: 'Vídeo MP4',
    public_video_not_found: 'Vídeo no encontrado.',
    private_video_not_found: 'No ha cargado ningún vídeo todavía'
  },

  media: {
    title: 'Mediateca',
    subtitle: 'Biblioteca de sus archivos',
    modal_title: 'Subir contenido multimedia',
    delete_warning: 'Confirme para eliminar',
    delete_message:
  'El contenido multimedia eliminado seguirá apareciendo en las páginas donde ha sido utilizado',
    dropzone_text: 'o Arrastrar y Soltar',
    delete_success: 'Contenido multimedia eliminado con éxito',
    upload_file: 'Subir archivo',
    drag_drop: 'o Arrastrar y Soltar',
    import_file: 'Importar archivo(s) desde su computadora',
    no_media: 'No ha cargado ningún contenido multimedia todavía',
    media_type: 'Tipo de Contenido Multimedia',
    video: 'Vídeo',
    image: 'Imagen',
    audio: 'Audio',
    gif: 'GIF',
    transcribed_text: 'Transcribed Text',
    media_not_found: 'Contenido multimedia no encontrado.',
    of: 'of',
    disclaimer: ` Garantizo que mi contenido multimedia:
    - no infringe los derechos de terceros
    - no contiene ningún material ilegal, incluyendo contenido difamatorio, obsceno, indecente, lascivo, pornográfico, violento, abusivo, insultante, amenazador, o discriminatorio, ni siquiera disfrazado con asteriscos
    - no contiene ningún ataque contra otras personas
    - no contiene ninguna suplantación de otros participantes, figuras públicas o celebridades
    - no contiene registro alguno o información sobre ninguna persona sin su consentimiento y no infringe ninguna norma de protección de datos en ninguna parte del mundo
    - no contiene ninguna solicitud comercial o forma de “spam”'`
  },

  navigation: {
    account: 'Cuenta',
    assessments: 'Evaluaciones',
    dashboard: 'Tablero',
    groups: 'Administrar Grupo',
    logout: 'Cerrar Sesión',
    organisations: 'Organizaciones',
    organizations_users: 'Organizaciones y Usuarios',
    reports: 'Informes',
    schedule: 'Calendario',
    nowLive: 'Transmitir',
    training: 'Capacitación',
    users: 'Usuarios',
    workshop: 'Mi Taller'
  },

  onboarding: {
    step1_title: 'Paso 1: Cuéntenos un poco sobre usted',
    step1_subtitle:
  '¿Cuál es su nombre? ¿De dónde es? ¿Cuál es su cargo en su trabajo?',
    step2_title: 'Paso 2: Seleccione Alguna Habilidad en la que Podría Mejorar',
    step2_subtitle:
  'A continuación, díganos lo que le gustaría entender mejor. ¿Tal vez algo que quiera aprender? ¿Un nuevo campo de estudio? ¿Lo que su ser querido hace?'
  },

  pagebuilder: {
    title: 'Crear Lección',
    subtitle:
  'Es hora de crear el contenido para que su público aprenda de usted, pueden ser imágenes, vídeos e infografías',
    select_layout: 'Seleccione un Diseño de Pagina',
    template1: 'En blanco',
    template2: 'Título y Subtítulo',
    template3: 'Título y Texto',
    template4: 'Título, Contenido Multimedia y Texto',
    template5: 'Título y Contenido Multimedia',
    template6: 'Título y Quiz'
  },

  pageconstructor: {
    title: 'Crear y Duplicar Lección',
    subtitle:
  'Es hora de crear el contenido para que su público aprenda de usted, puede agregar imágenes, vídeos e infografías'
  },

  passwordreset: {
    success_coded:
    'Hemos enviado por correo electrónico un código de confirmación a la dirección que usted proporcionó, por favor escriba el código en el campo de abajo para establecer una nueva contraseña.',
    success_changed:
  'Ha configurado con éxito una nueva contraseña. Puede iniciar sesión ahora.'
  },

  placeholders: {
    search: 'Buscar',
    answer: 'Responder',
    chat: 'Decir algo...',
    code: 'Código',
    criterion: 'Criterio',
    date_event: 'Seleccionar fecha y hora',
    date_start: 'Fecha de inicio',
    date_expire: 'Fecha de expiración',
    description: 'Descripción',
    elements: 'Ordenar elementos',
    email: 'Correo Electrónico',
    feedback_correct: 'Retroalimentación caso Correcto',
    feedback_incorrect: 'Retroalimentación caso Incorrecto',
    host: 'Anfitrión',
    keywords: 'Buscar palabra(s) clave',
    lesson_description: 'Descripción de la Lección',
    location: 'Ubicación',
    minutes: 'Minutos',
    name: 'Nombre',
    organiser: 'Autor',
    organization: 'Organización',
    password: 'Contraseña',
    phone: 'Teléfono',
    photo: 'Foto',
    picture: 'Imagen',
    point: 'Punto',
    position: 'Posición',
    question: 'Pregunta',
    quiz: 'Nombre del Quiz',
    search_course: 'Buscar por Curso',
    search_media: 'Buscar contenido multimedia',
    search_name: 'Buscar por Nombre',
    search_title: 'Buscar por Título',
    search_training: 'Buscar Capacitación',
    settings: 'Ajustes',
    tags: 'Etiquetas',
    title: 'Título',
    topics: 'Temas',
    username: 'Nombre de usuario',
    keyword: 'Palabras clave',
    max_500_characters: 'Max 500 Caracteres',
    no_description: 'Este archivo no tiene una descripción',
    no_keyword: 'Este archivo no tiene palabras clave',
    no_topics: 'Este archivo no tiene un conjunto de temas',
    feedback: 'Mensaje',
    contact: '¿Cómo podemos ayudarle?'
  },

  publishsettings: {
    title: ' Configuración de Publicación ',
    event_subtitle:
  'Configure quién puede ver e interactuar con su evento',
    assessment_subtitile:
  'Nombre su Evaluación y dígale a su audiencia lo que pueden aprender de la misma',
    course_subtitle:
  'Configure quién puede ver e interactuar con su curso',
    /* disclaimer:
  1.	Registro

  Al registrarse como usuario/crear una cuenta, usted acepta nuestro [User Agreement] [Acuerdo de Usuario] y reconoce haber leído nuestra [Privacy Policy] [Política de Privacidad]

  2.	Crear Curso

  Al utilizar nuestras herramientas y crear el contenido de un curso, usted acepta nuestro [User Agreement] [Acuerdo de Usuario] y reconoce haber leído nuestra [Privacy Policy] [Política de Privacidad]

  3.	Crear Evento

  Al utilizar nuestras herramientas y crear un evento, usted acepta nuestro [User Agreement] [Acuerdo de Usuario] y reconoce haber leído nuestra [Privacy Policy] [Política de Privacidad]

  4.	Crear Evaluación

  Al utilizar nuestras herramientas y crear una evaluación, usted acepta nuestro [User Agreement] [Acuerdo de Usuario] y reconoce haber leído nuestra [Privacy Policy] [Política de Privacidad] */
    dates_validation_error: 'Ambas fechas deben ser llenadas.',
    emails_validation_error: 'La lista de correo electrónico no es válida.',
    specific_email_validation_error: 'Sólo se permite un correo electrónico.',
    i_agree_to_these: 'Yo acepto estos ',
    terms_and_conditions: 'términos y condiciones.',
    PublicDescription:
  'Usuarios independientes: (opciones privadas y públicas)su [Course|Event|Assessment] [Curso|Evento|Evaluación] ha sido guardado y se considera Privado por defecto. Esto significa que sólo usted puede visualizar este contenido. Para publicarlo puede seleccionar hacerlo público a continuación. Esto quiere decir que el curso estará disponible para todos los usuarios de la plataforma.',
    OrganisationsDescription:
  'Usuarios de una Org: (En lugar de ""Finalizar"" utilice ""Publicar"" y llévelos a la página de publicación igual que otros usuarios pero no tendrá ninguna de las opciones de publicación.)Su [Course|Event|Assessment] [Curso|Evento|Evaluación] ha sido guardado y es privado. Esto significa que sólo usted y los Administradores de su organización pueden visualizarlo. Como usuario, no puede publicar su contenido dentro de la organización debido a que esto requiere derechos de administrador. Por favor contacte a uno de sus Administradores y pídale que coloque su contenido público, a toda la organización, a grupos específicos dentro de su organización o a usuarios específicos.',
    OrganisationGroupsDescription:
  'Administradores de una Org: (Para contenido hecho dentro de la organización: Privado, público, organización, grupos, usuarios específicos):Este [Course|Event|Assessment] [Curso|Evento|Evaluación] ha sido guardado y se considera Privado por defecto. Esto significa que sólo usted (y el creador del contenido) pueden visualizar este contenido. Para publicarlo puede seleccionar una de las opciones a continuación. Publicar su curso en Público significa que estará disponible para todos los usuarios de la plataforma – incluyendo aquellos fuera de su organización. Publicarlo a la organización significa que todos los usuarios dentro de la organización podrán visualizarlo. También puede publicarlo en 1 o más grupos, lo que significa que sólo los usuarios dentro de esos grupos podrán visualizarlo. Por último, tiene la opción de publicarlo a usuarios específicos dentro y fuera de la organización utilizando su dirección de correo electrónico de la cuenta de Sharelook.',
    SpecificUsersDescription: 'Usuarios Independientes Pagados: (privado, público y usuarios específicos)Su [Course|Event|Assessment] [Curso|Evento|Evaluación] ha sido guardado y se considera Privado por defecto. Esto significa que sólo usted puede visualizar este contenido. Para publicarlo puede seleccionar una de las opciones a continuación. Publicar su curso en Público significa que estará disponible para todos los usuarios de la plataforma. La opción Usuarios Específicos le permitirá compartir su curso con usuarios específicos utilizando sus direcciones de correo electrónico de la cuenta de Sharelook.'
  },

  quiz: {
    quiz_empty: 'Todavía no ha creado un quiz',
    questions_empty: 'Todavía no ha creado una pregunta'
  },

  regions: {
    asia_pacific_australia: 'Australia',
    asia_pacific_india: 'India',
    asia_pacific_japan: 'Japón',
    asia_pacific_s_korea: 'Corea del Sur',
    asia_pacific_singapore: 'Singapur',
    asia_pacific_taiwan: 'Taiwan',
    eu_belgium: 'Bélgica',
    eu_germany: 'Alemania',
    eu_ireland: 'Irlanda',
    south_america_brazil: 'Brasil',
    us_central_iowa: 'Iowa',
    us_east_s_carolina: 'Carolina del Sur',
    us_east_virginia: 'Virginia',
    us_west_california: 'California',
    us_west_oregon: 'Oregón'
  },

  reports: {
    title: 'Informes'
  },

  signup: {
    success_step1:
    'Hemos enviado por correo electrónico un código de verificación a la dirección que usted proporcionó, por favor escriba el código en el siguiente campo para confirmar su registro.',
    success_step2: 'Gracias. Se ha registrado correctamente.',
    privacy: `Acepto las <1> condiciones de uso</1> y <3> política de privacidad</3>de Sharelook`
  },

  stream: {
    emotion_impressive: 'Impresionante',
    emotion_unimpressive: 'No destaca',
    message_not_started: 'Próximamente',
    message_running: 'En vivo',
    message_processing:
  'Actualmente estamos procesando la grabación en vivo para su reproducción. Por favor, vuelva más tarde para ver el vídeo y seguir añadiendo valoraciones y comentarios'
  },

  tabs: {
    audience: 'Audiencia',
    course: 'Vista general del Curso',
    details: 'Detalles del Evento en Vivo',
    description: 'Descripción',
    host: 'Anfitrión',
    lesson: 'Vista general de la Lección',
    lessons: 'Lecciones',
    overview: 'Vista general',
    page: 'Creador de la página',
    publish: 'Publicar',
    settings: 'Configurar Publicación',
    total_views: 'Visualizaciones Totales',
    live_participates: 'Participantes en Vivo',
    live_chats: 'Chats en Vivo',
    live_quiz: 'Encuestas en Vivo',
    trainer_rating: 'Valoración del Entrenador',
    trainer_times: 'Tiempos de Entrenador',
    courses_quizzes: 'Quizzes de los Cursos',
    user_list: 'Lista de Usuarios',
    discussion: 'Debate',
    notes: 'Mis Notas'
  },

  topics: {
    SALES_TRAINING: 'Capacitación en Ventas',
    NEGOTIATION_TRAINING: 'Capacitación en Negociación',
    CROSS_CULTURAL_AWARENESS: 'Sensibilización Intercultural',
    NETWORKING_SKILLS: 'Habilidades de Networking',
    SOFTWARE_ARCHITECTURE: 'Arquitectura de Software',
    PROJECT_MANAGEMENT: 'Gestión de Proyectos',
    DIGITAL_TRANSFORMATION: 'Transformación Digital',
    IT_CONSULTING: 'Consultoría Tecnológica',
    MANAGEMENT_CONSULTING: 'Consultoría de Gestión',
    HR_CONSULTING: 'Consultoría de Recursos Humanos',
    INTERNET_OF_THINGS: 'Internet de las Cosas (IdC)',
    CYBERSECURITY: 'Seguridad Informática',
    PRIVACY_AND_GDRP: 'Privacidad y RGPD'
  },

  training: {
    title: 'Capacitación',
    title_assessment: 'Evaluaciones',
    subtitle: 'Cursos y Eventos en Vivo',
    enrolled_title: 'Cursos Inscrito y Eventos en Vivo',
    enrolled_subtitle: 'Cursos y Eventos en Vivo en los que se encuentra inscrito',
    enrolled_message:
  'Actualmente usted no está inscrito en ningún curso o evento.',
    past_live_event_title: 'Eventos en Vivo Anteriores',
    past_live_event_subTitle: 'Eventos en Vivo Finalizados',
    past_live_event: 'Actualmente no hay eventos en vivo anteriores.',
    recomended_title: 'Cursos y Eventos en Vivo Recomendados ',
    now_live_event_title: 'Ahora en Vivo',
    now_live_event_subTitle: 'Eventos que están en vivo ahora o lo estarán muy pronto',
    now_live_event: 'Actualmente no hay eventos en vivo.',
    webinar_event_title: 'Canal de Webinar',
    webinar_event_subTitle: 'Webinarios Finalizados',
    webinar_event: 'Actualmente no hay webinarios.',
    p2p_event_title: 'Sesiones de entrenamiento',
    p2p_event_subTitle: 'Sesión de Entrenamiento Finalizada',
    p2p_event: 'Actualmente no hay sesiones de entrenamiento.',

    now_live_assessment_title: 'Ahora en Vivo',
    now_live_assessment_subTitle:
  'Evaluaciones que están en vivo ahora o lo estarán muy pronto',
    now_live_assessment_message: 'Actualmente no hay evaluaciones en vivo.',

    upcoming_assessment_title: 'Próximas Evaluaciones en Vivo',
    upcoming_assessment_subtitle: 'Próximas Evaluaciones en Vivo',
    upcoming_assessment_message:
  'Actualmente no hay próximas evaluaciones en vivo.',

    past_live_assessment_title: 'Evaluaciones en Vivo Anteriores',
    past_live_assessment_subTitle: 'Evaluaciones en Vivo Finalizadas',
    past_live_assessment_message:
  'Actualmente no hay evaluaciones en vivo anteriores.',

    /**
     * <0/> get index 0 of data from Trans component
     * exp -->
     * <Trans i18nKey='training:recomended_subtitle' >
     *    <Navlink>{...something}</Navlink> --> is index 0 of Trans component
     *    <h1>{...something}</h1> --> is index 1 of Trans component
     * </Trans>
     */
    recomended_subtitle:
  'Hemos escogido cursos y eventos en vivo basados en su <0><0/></0>',
    /**
     * and we also can get data from index to replace in recommended_subtitle by like above string.
     * for exp -->
     * at the end of the recomended_subtitle above will get data from index of component to display
     * <0><0/></0> mean get data index 0 of Trans component above = <Navlink> and <0/> get data index 0 from <Navlink> = {...something}
     */
    search: 'Buscar Capacitación',
    search_assessment: 'Buscar Evaluación',
    recomended_message: 'Actualmente no hay cursos o eventos recomendados.'
  },

  warnings: {
    delete: '¿Está seguro?'
  },

  workshop: {
    title: 'Mi Taller',
    subtitle: 'Crear un curso o ser anfitrión de un evento en vivo propio',
    new_course: 'Crear un curso nuevo',
    new_event: 'Crear un nuevo evento en vivo',
    new_assessment: 'Crear una nueva evaluación en vivo',
    courses_title: 'Cursos y Eventos en Vivo Creados',
    courses_subtitle: 'Edite y Publique su curso creado y planifique eventos en vivo',
    tags_course: 'Curso',
    tags_live: 'Evento en Vivo',
    tags_assessment: 'Evaluación en Vivo'
  },

  help_center: {
    sidebar: {
      title: '¿Como podemos ayudarle? ',
      back_to: 'Tablero'
    },
    menu: {
      help_center: 'Centro de Ayuda',
      whats_new: 'Lo nuevo',
      feedback: 'Comentarios',
      feedback_title: 'Enviar comentarios a sharelook',
      terms_and_conditions: 'Condiciones de uso',
      privacy_policy: 'Política de Privacidad',
      contact_us: 'Contáctenos'
    },
    get_started: {
      title: 'Comience con share look',
      description: {
        main: 'HEMOS INTEGRADO VIDEOS EN VIVO EN UN CREADOR DE CURSOS FÁCIL DE UTILIZAR',
        tagline:
      'Con el sistema de transmisión en vivo de ShareLook, puede crear interacción, automatizar el aprendizaje y maximizar el intercambio de conocimientos.'
      }
    },
    whats_new: {
      title: 'Comience con sharelook',
      description: {
        main:
        '¿Sabía que puede diseñar el estilo de aprendizaje con Storytelling en poco tiempo y no es costoso? Debe combinar el creador de cursos de ShareLook y los respectivos talentos. Pregúntenos "CÓMO”',
        tagline: ''
      }
    },
    create_workshop: 'Cómo Crear un Taller',
    create_course: 'Cómo Crear un Curso',
    create_user: 'Cómo Crear un Usuario',
    create_group: 'Cómo Crear un Grupo',
    create_webinar: 'Cómo Crear un Webinar',
    create_live_event: 'Cómo Crear un Evento en Vivo',
    create_live_assessment: 'Cómo Crear una Evaluación en Vivo',
    create_live_on_mobile: 'Crear en Vivo en el Móvil',
    personalize_profile: 'Personalizar un Perfil',
    manage_group: 'Administrar Grupo',
    report: 'Informe',
    add_annotations: 'Cómo Agregar Anotaciones/Ventanas emergentes',
    add_audio: 'Cómo Agregar Audio',
    add_header_and_footer: 'Agregar Encabezado y Pie de Página',
    add_image: 'Cómo Agregar Imagen',
    add_quiz_content: 'Cómo Agregar Contenido en el Quiz',
    add_text_content: 'Cómo Agregar Contenido de Texto',
    add_user: 'Cómo Agregar Usuarios',
    add_video: 'Cómo Agregar Vídeo',
    clone_course: 'Cómo Duplicar Curso',
    find_live_broadcast: 'Cómo Encontrar Transmisión en Vivo',
    contact_us: {
      title: 'Contáctenos',
      subject_placeholder: 'Escriba un tema*',
      content_placeholder: 'Escriba un mensaje*',
      subject_required: '*El tema es obligatorio',
      content_required: '*El mensaje es obligatorio',
      submit: 'Enviar',
      cancel: 'Cancelar',
      sent: 'Enviado'
    },
    feedback: {
      title: 'Enviar comentarios a sharelook',
      attach_title: 'Adjuntar un archivo',
      choose: 'Elegir archivo',
      detail: 'El archivo será compartido con el equipo de soporte',
      content_placeholder: 'Escriba un mensaje*',
      content_required: '*El mensaje es obligatorio',
      submit: 'Enviar',
      cancel: 'Cancelar',
      sent: 'Enviado'
    }
  },
  discussion: {
    join_discussion: 'Unirse al Debate'
  },
  notes: {
    title: 'Mis notas',
    add_note: 'Agregar nota nueva'
  }
}
