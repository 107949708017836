import { httpCustomSummarizedInstance } from './http'

function getSummarized(data, type) {
  return httpCustomSummarizedInstance.post(
    type === 'abstractive'
      ? `/abstractive-summarization/summarization`
      : '/extractive-summarization/ext-summary',
    type === 'abstractive'
      ? {
          params: {
            content: data.content,
            language: data.lang,
            min_length: data.min_length,
            max_length: data.max_length
          }
        }
      : data
  )
}

export { getSummarized }
