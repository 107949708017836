import React, { Component } from 'react'
import LessonsPagination from '../../internal/LessonsPagination'
import SaveLesson from '../../internal/SaveLesson'

import PageMaker from './internal/PageMaker'

import { Wrapper, Main } from '../../styled'
import history from '../../../../../history'
import { Button } from 'antd'
import { withTranslation } from 'react-i18next'

class PageConstructor extends Component {
  render () {
    const {
      lesson: { pages, lessonId },
      rootPath,
      t
    } = this.props

    if (pages.length === 0) {
      history.push(`${rootPath}/page-builder`)
      return null
    }

    return (
      <Wrapper>
        <Main noPadding>
          {/*<section className='section'>
            <div className='head head_nopadding'>
              <div className='head__title'>{t('title')}</div>
              <div className='head__subtitle'>{t('subtitle')}</div>
            </div>
          </section>*/}

          <PageMaker rootPath={rootPath}/>

        </Main>

        <div className='p-footer'>
          <div className='p-footer__inner p-footer__inner_justify'>
            <div className='p-footer__col'>
              <div className='p-footer__item'>
                <LessonsPagination rootPath={rootPath}/>
              </div>
            </div>
            <div className='p-footer__col'>
              <div className='p-footer__item'>
                <Button className='rounded'
                        onClick={() => history.push(`${rootPath}/lesson-create/${lessonId}`)}>{t('buttons:back_to_lesson')}</Button>
              </div>
              <div className='p-footer__item'>
                <SaveLesson/>
              </div>
            </div>
          </div>
        </div>

      </Wrapper>
    )
  }
}

export default withTranslation('pageconstructor')(PageConstructor)
