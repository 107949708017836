import React from 'react'
import './SidebarModal.scss'

import HeadTitle from './components/HeadTitle'
import EventOverview from './components/EventOverview'
import EventHost from './components/EventHost'
import Attachments from '../../../../../components/Attachments'
import Notes from '../../../../../components/Notes'
import EventParticipants from './components/EventParticipants'
import EventRooms from './components/EventRooms/EventRooms'
import EventRatings from './components/EventRatings'
import Discussion from '../../../../../components/Discussion'
class SidebarModal extends React.Component {
  handleClose = () => {
    const {
      onClose,
      controller: {
        overview,
        notes,
        hosts,
        attachments,
        rooms,
        ratings,
        discussion
      },
      ITEMS
    } = this.props

    const itemToClose = overview
      ? ITEMS.OVERVIEW
      : notes
      ? ITEMS.NOTES
      : hosts
      ? ITEMS.HOST
      : attachments
      ? ITEMS.ATTACHMENT
      : rooms
      ? ITEMS.ROOM
      : ratings
      ? ITEMS.RATING
      : discussion
      ? ITEMS.DISCUSSION
      : ITEMS.PARTICIPANTS

    onClose(itemToClose)
  }

  render() {
    const {
      userId,
      event,
      controller: {
        overview,
        notes,
        hosts,
        attachments,
        participants,
        rooms,
        ratings,
        discussion
      },
      onRoomCreate,
      isBreakoutHost
    } = this.props

    return (
      <div className="events__sidebar__modal">
        <div className="events__sidebar__modal__header">
          <div className="events__sidebar__modal__header_title">
            <HeadTitle {...this.props} />
          </div>
          <div
            className="events__sidebar__modal__header_close"
            onClick={this.handleClose}
          >
            X
          </div>
        </div>
        <div className="events__sidebar__modal__items">
          {overview && <EventOverview event={event} />}
          {hosts && <EventHost event={event} />}
          {notes && (
            <Notes noteId={event.id} noteType={'event'} userId={userId} />
          )}
          {attachments && <Attachments event={event} />}

          {participants && <EventParticipants {...this.props} />}
          {rooms && (
            <EventRooms
              rooms={this.props.rooms}
              isHost={isBreakoutHost}
              onRoomCreate={onRoomCreate}
              event={event}
            />
          )}
          {ratings && <EventRatings eventId={event.id} userId={userId} />}
          {discussion && (
            <Discussion
              discussionId={event.id}
              discussionType={'event'}
              userId={userId}
            />
          )}
        </div>
      </div>
    )
  }
}

export default SidebarModal
