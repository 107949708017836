import styled from 'styled-components'
import { Card } from '../../../../../../../../../components/Card/styled.js'

Card.VideoInfo = styled.div`
  margin-left: -16px;
`

Card.VideoIcon = styled.div`
  color: #c0c0c0;
  ${props =>
  props.cardSelected &&
  `
    color: #08c;
  `}
`

export { Card }
