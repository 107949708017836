import React, { Component } from 'react'

import { TopPageLink, Filter } from '../../../components'

import { Page, TabsCategories, TabsCharts } from './styled'

const filterData = [
  { value: 7, text: 'Last 7 days' },
  { value: 15, text: 'Last 15 days' },
  { value: 30, text: 'Last 30 days' }
]

class DashboardMore extends Component {
  render () {
    return (
      <Page>
        <TopPageLink page='Dashboard' path='/'/>

        <Page.Inner>
          <div className='head'>
            <div className='head__title'>Calligraphy for Beginners</div>
            <div className='head__subtitle'>5 lessons</div>
          </div>
          <Page.TabsContent>
            <Page.Download>Download</Page.Download>

            <TabsCategories defaultActiveKey='2'>
              <TabsCategories.Tab tab='Details' key='1'>
              </TabsCategories.Tab>

              <TabsCategories.Tab tab='Analytics' key='2'>
                <TabsCharts defaultActiveKey='1' type='card' tabPosition='left'>
                  <TabsCharts.Tab tab={<TabsCharts.Tab.Title>Overview</TabsCharts.Tab.Title>} key={1}>
                    <Page.FilterCharts>
                      <Page.Column>
                        <Page.FilterCharts.Info>116 Total Views</Page.FilterCharts.Info>
                        <Page.FilterCharts.Info>33 RSVP</Page.FilterCharts.Info>
                      </Page.Column>

                      <Page.Column>
                        <Filter
                          data={filterData}
                          defaultValue={filterData[0].text}
                          callback={(item) => this.onFilter(item.value)}
                          label='Show'
                          noIndent
                        />
                      </Page.Column>
                    </Page.FilterCharts>
                  </TabsCharts.Tab>

                  <TabsCharts.Tab tab={<TabsCharts.Tab.Title>Traffic</TabsCharts.Tab.Title>} key={2}>
                  </TabsCharts.Tab>

                  <TabsCharts.Tab tab={<TabsCharts.Tab.Title>Audience</TabsCharts.Tab.Title>} key={3}>
                  </TabsCharts.Tab>
                </TabsCharts>
              </TabsCategories.Tab>
            </TabsCategories>
          </Page.TabsContent>
        </Page.Inner>
      </Page>
    )
  }
}

export default DashboardMore
