import React from 'react'
import { Icon } from 'antd'

import DiscussionIcon from '../../../../assets/discussion.svg'

import { withTranslation } from 'react-i18next'
class HeadTitle extends React.Component {
  render() {
    const {
      controller: {
        overview,
        notes,
        hosts,
        attachments,
        participants,
        rooms,
        ratings,
        discussion
      },
      t
    } = this.props
    return (
      <>
        {overview && (
          <>
            <Icon type="read" /> {t('reports:overview')}
          </>
        )}
        {hosts && (
          <>
            <Icon type="user" /> {t('labels:host')}
          </>
        )}
        {attachments && (
          <>
            <Icon type="paper-clip" /> {t('v2:attachment')}
          </>
        )}
        {notes && (
          <>
            <Icon type="form" /> {t('tabs:notes')}
          </>
        )}
        {participants && (
          <>
            <Icon type="usergroup-add" /> {t('labels:participants')}
          </>
        )}
        {rooms && (
          <>
            <Icon type="appstore" /> {t('v2:breakout_room')}
          </>
        )}
        {ratings && <span>{t('v2:review')}</span>}

        {discussion && (
          <>
             <img alt=""
              src={DiscussionIcon}
              style={{
                filter: 'brightness(0) saturate(100%)',
                marginRight: '1rem'
              }}
            />
            {t('tabs:discussion')}
          </>
        )}
      </>
    )
  }
}

export default withTranslation()(HeadTitle)
