import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import actions from '../../../../store/actions'
import ARUploads from './MazeUploads'

const mapStateToProps = state => ({
  library: state.library,
  user: state.user,
  topics: state.topics
})

const mapDispatchToProps = dispatch => bindActionCreators({
  getLibraryList: actions.library.getLibraryList,
  getPublicList: actions.library.getPublicList,
  dispatchResult: actions.library.dispatchResult,
  getOrgList: actions.library.getOrgList,
  upload: actions.library.upload,
  clearUploadList: actions.library.clearUploadList,
  updateLibraryStatus: actions.library.updateLibraryStatus,
  updateOrgLib: actions.library.updateOrgLib,
  handleMakeType: actions.library.handleMakeType,
  updateTopicList: actions.topics.updateTopicList
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ARUploads)