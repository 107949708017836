import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { styleguide } from '../../../../constants'

const { colors } = styleguide

const Item = styled.div``

Item.Link = styled(NavLink)`
  position: relative;
  display: block;

  &:link,
  &:hover,
  &:visited {
    text-decoration: none;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 0.5rem;
    background: transparent;
    transition: border-color 0.25s;
  }
`

Item.Inner = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

Item.Icon = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;

  & > div,
  & > div > div {
    display: flex;
    align-items: center;
  }

  svg,
  path {
    transition: fill 0.25s;
  }
`

Item.Heading = styled.div`
  flex-shrink: 0;
  font-weight: 500;
  font-size: 14px;
  color: #d5d9e2;
  transition: width 0.5s;
`

export { Item }
