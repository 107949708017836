import React, { Component, Fragment } from 'react'
import {
  ReportTable,
  CustomTabPane,
  HeaderTabs,
  SearchEventMargin,
  PaginationRightAligned
} from '../../styled.js'
import { Tabs, Icon, Row, Col, Pagination, Divider } from 'antd'
import { generateCSV } from '../../../../utils'
import moment from 'moment/moment'
import '../../Report/style.css'
import { isSafari } from 'react-device-detect'
import { withTranslation } from 'react-i18next'
import { Search } from '../../../../components'
import _ from 'lodash'
import ReportHeader from '../../components/ReportHeader.jsx'
import TypeSelect from '../../components/TypeSelect.jsx'
import { report } from '../../../../constants'

const { TabPane } = Tabs

const dateType = {
  allTime: '5',
  today: '1',
  week: '7',
  month: '30',
  year: '12',
  other: '2'
}

const reverseDateType = {
  5: 'all',
  1: 'today',
  7: 'week',
  30: 'month',
  12: 'year',
  2: 'other'
}

const columns = t => [
  {
    title: t('v4:item'),
    dataIndex: 'cover',
    render: cover => {
      return (
        <img
          style={{ width: '49px', height: '32px' }}
          src={cover && cover.resizes.thumbnail}
          alt="cover"
        />
      )
    },
    width: 49
  },
  { dataIndex: 'title', textWrap: 'word-break', width: 200 },
  {
    title: t('reports:created_date'),
    dataIndex: 'created_at',
    render: x => {
      // if (!x) return 'N/A'
      return moment(x).format('ll')
    }
    // sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
    // sortDirections: ['descend', 'ascend']
  },
  // { title: t('reports:status'), dataIndex: 'status' },
  { title: t('reports:views'), dataIndex: 'views' },
  { title: t('v4:minimum_pass_score'), dataIndex: 'pass_score' }
  // {
  //   title: t('v4:result'),
  //   dataIndex: 'pass_status',
  //   render: status => {
  //     let color = status === true ? '#20AA26' : '#BC0000'
  //     let result = status === true ? 'Passed' : 'Failed'
  //     return <p style={{ color: color }}>{result}</p>
  //   }
  // }
]

class Details extends Component {
  _isMount = false
  state = {
    search: '',
    workshopDateType: '5',
    dateFrom: null,
    dateTo: null,
    sortBy: '',
    page: report.PAGE_NUMBER,
    pageSize: report.PAGE_SIZE
  }

  componentDidMount() {
    this._isMount = true
    const { state } = this.props.history.location
    console.log('stae', state)
    if (state && state.searchType) {
      this.setState(
        {
          workshopDateType: dateType[state.searchType],
          dateFrom: state.dateFrom,
          dateTo: state.dateTo
        },
        () => {
          if (state.dateFrom && state.dateTo !== '') {
            // this.handleSearch()
            console.log('ifffff')
            this.fetchData()
          } else {
            console.log('else')
            this.handleSortByChange(dateType[state.searchType].toString())
          }
        }
      )
      return
    }

    this.props.getReport({
      type: 'live_assessment',
      offset: 0,
      limit: 10,
      date: 'all',
      search: '',
      from: '',
      to: ''
    })
  }

  handleDate = val => {
    this.setState(
      {
        dateFrom: val[0],
        dateTo: val[1]
      },
      this.fetchData
    )
  }

  fetchData = () => {
    const { search, pageSize, page, dateFrom, dateTo } = this.state
    this.props.getReport({
      type: 'live_assessment',
      offset: this.createOffset(page, pageSize),
      limit: pageSize,
      date: '',
      from: dateFrom,
      to: dateTo,
      search: search
    })
  }

  onSearch = _.debounce(value => {
    if (this._isMount) {
      const { sortBy, dateFrom, dateTo } = this.state
      console.log('value', value)
      this.setState({ search: value }, () => {
        this.props.getReport({
          type: 'live_assessment',
          // offset: this.createOffset(page, pageSize),
          // limit: pageSize,
          offset: 0,
          limit: 10,
          search: value,
          date: sortBy === 'other' ? '' : sortBy,
          from: sortBy === 'other' ? dateFrom : '',
          to: sortBy === 'other' ? dateTo : ''
        })
      })
    }
  }, 1000)

  handleSortByChange(value) {
    const { search, page, pageSize } = this.state
    let defaultProps = {
      type: 'live_assessment',
      offset: this.createOffset(page, pageSize),
      limit: pageSize,
      date: 'today',
      search: search,
      from: '',
      to: ''
    }
    if (value === '1') {
      if (this._isMount) {
        this.setState(
          {
            sortBy: 'today',
            workshopDateType: value
          },
          () => {
            this.props.getReport({
              ...defaultProps
            })
          }
        )
      }
    } else if (value === '7') {
      if (this._isMount) {
        this.setState(
          {
            sortBy: 'week',
            workshopDateType: value
          },
          () => {
            this.props.getReport({
              ...defaultProps,
              date: 'week'
            })
          }
        )
      }
    } else if (value === '30') {
      if (this._isMount) {
        this.setState(
          {
            sortBy: 'month',
            workshopDateType: value
          },
          () => {
            this.props.getReport({
              ...defaultProps,
              date: 'month'
            })
          }
        )
      }
    } else if (value === '12') {
      if (this._isMount) {
        this.setState(
          {
            sortBy: 'year',
            workshopDateType: value
          },
          () => {
            this.props.getReport({
              ...defaultProps,
              date: 'year'
            })
          }
        )
      }
    } else if (value === '5') {
      if (this._isMount) {
        this.setState(
          {
            sortBy: 'all',
            dateworkshopDateTypeype: value
          },
          () => {
            this.props.getReport({
              ...defaultProps,
              date: 'all'
            })
          }
        )
      }
    } else if (value === '2') {
      if (this._isMount) {
        this.setState({
          sortBy: 'other',
          workshopDateType: value
        })
      }
    }
  }

  download = () => {
    const { list } = this.props.data.liveAssessmentsList
    const live_assessments = list && list.live_assessments
    const dataCSV = live_assessments.map(x => {
      return {
        id: x.id,
        title: x.title,
        created_at: moment(x.created_at).format('YYYY-MM-DD'),
        status: x.pass_status === true ? 'Passed' : 'Failed',
        views: x.views,
        pass_score: x.pass_score
      }
    })
    const header = [
      '#',
      'Title',
      'Created at',
      'Status',
      'Views',
      'Minimum Pass Score'
    ]
    if (!isSafari) {
      let ele = document.createElement('a')
      ele.setAttribute(
        'href',
        'data:text/csv;charset=utf-8,' +
          encodeURIComponent(generateCSV(dataCSV, header))
      )
      ele.setAttribute('download', 'live-assessments-report.csv')
      document.body.appendChild(ele)
      ele.click()
      // generateCSV(dataCSV, header)
    } else {
      let ele = document.createElement('a')
      ele.setAttribute(
        'href',
        'data:text/csv;charset=utf-8,' +
          encodeURIComponent(generateCSV(dataCSV, header))
      )
      ele.setAttribute('download', 'live-assessments-report.csv')
      ele.click()
    }
  }

  createOffset = (page, pageSize) => page * pageSize - pageSize

  onChangePagination = page => {
    const {
      pageSize,
      workshopSearchType,
      search,
      dateFrom,
      dateTo
    } = this.state
    this.setState(
      {
        page: page,
        pageSize: pageSize
      },
      () => {
        this.props.getReport({
          type: 'live_assessment',
          offset: this.createOffset(page, pageSize),
          limit: pageSize,
          ...(workshopSearchType && {
            date: workshopSearchType === 'allTime' ? 'all' : workshopSearchType
          }),
          search: search,
          date:
            reverseDateType[workshopSearchType] === 'other'
              ? ''
              : reverseDateType[dateType],
          from: reverseDateType[dateType] === 'other' ? dateFrom : '',
          to: reverseDateType[dateType] === 'other' ? dateTo : ''
        })
      }
    )
  }

  callbackTabClicked = key => {
    if (key === '0') {
      this.props.history.push(`/reports`)
    } else {
      this.props.history.push('/reports', 'user')
    }
  }

  componentWillUnmount() {
    this._isMount = false
  }

  render() {
    const { t } = this.props
    const { workshopDateType, dateFrom, dateTo, page, pageSize } = this.state
    const { list, fetch } = this.props.data.liveAssessmentsList
    const liveAssessments = list && list.live_assessments
    const totalData = list && list.pagination ? list.pagination.total : 0
    console.log('data', list)
    console.log('liveAssessments', liveAssessments)
    console.log('liveassessmentspropos', this.props)

    return (
      <Fragment>
        <div className="content-warp">
          <div
            className="report-warp sharelook-table sharelook-live-assessment"
            style={{ direction: 'ltr' }}
          >
            <HeaderTabs
              defaultActiveKey="1"
              onTabClick={this.callbackTabClicked}
              onChange={this.changeTab}
              // onTabClick={key => {
              //   if (key === '0') {
              //     this.props.history.push(`/reports`)
              //   }
              // }}
              tabBarExtraContent={
                <ReportHeader
                  workshopDateType={workshopDateType}
                  dateChange={value => this.handleSortByChange(value)}
                  download={this.download}
                  handleDate={(date, dateString) => this.handleDate(dateString)}
                  dateFrom={dateFrom}
                  dateTo={dateTo}
                  // backToDashboard={e => this.props.history.push('/reports')}
                />
              }
            >
              <TabPane
                tab={
                  <CustomTabPane>
                    <Icon type="left" style={{ fontSize: '18px' }} />
                  </CustomTabPane>
                }
                key="0"
              />
              <TabPane tab={t('reports:workshop_report')} key="1">
                <TypeSelect defaultValue="live_assessments" />
                <Row>
                  <ReportTable
                    rowKey="id"
                    columns={columns(t)}
                    dataSource={liveAssessments}
                    loading={fetch}
                    borderRadius
                    pagination={false}
                    // pagination={{
                    //   itemRender: RenderArrows,
                    //   pageSize: 10
                    // }}
                    onRow={record => {
                      return {
                        onClick: () =>
                          this.props.history.push(
                            `/reports/live-assessments/` + record.id
                          )
                      }
                    }}
                    title={() => (
                      <>
                        <Row
                          className="sharelook-course-wrapper sharelook-course-participants-wrapper"
                          style={{ marginLeft: '15px', marginTop: '3px' }}
                        >
                          <Col span={5}>
                            <SearchEventMargin>
                              <Search
                                placeholder={t('placeholders:search')}
                                onChange={e => this.onSearch(e.target.value)}
                              />
                            </SearchEventMargin>
                          </Col>
                          <Col span={11} />
                          <Col className="report-title" span={4}>
                            {t('v4:total')} {totalData ? totalData : '0'}{' '}
                            {t('reports:live_assessments').toUpperCase()}
                          </Col>
                          <Col
                            className="report-pagination"
                            span={4}
                            style={{ marginTop: '14px' }}
                          >
                            <PaginationRightAligned margin>
                              <Pagination
                                total={totalData}
                                current={page}
                                pageSize={pageSize}
                                onChange={this.onChangePagination}
                                simple
                              />
                            </PaginationRightAligned>
                          </Col>
                        </Row>
                        <Divider style={{ margin: '0px' }} />
                      </>
                    )}
                  />
                </Row>
              </TabPane>
              <TabPane tab={t('reports:user_report')} key="2"></TabPane>
              <TabPane tab={t('v4:marketplace_report')} key="3"></TabPane>
            </HeaderTabs>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default withTranslation('report')(Details)
