/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-08-22 10:35:38
 * @LastEditTime: 2019-09-23 11:00:05
 * @LastEditors: Please set LastEditors
 */
import styled, { css } from 'styled-components'
import { Head, media } from '../../../styles'
import { Button, Switch } from 'antd'

import { styleguide } from '../../../constants'

const { colors, typography } = styleguide

export const HeadWrap = styled(Head)`
  margin-bottom: 0;
`

export const CourseContent = styled.section`
  padding: 0 3.2rem 10rem;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;

  ${media.md`
    flex-direction: column;
  `}
`

export const CourseOwner = styled.section`
  padding: 0 3.2rem;
`

CourseOwner.Name = styled.p`
  font-size: 1.1rem;
  text-transform: uppercase;
  font-weight: 300;
  width: 100%;
  margin: 0;
  padding-bottom: 1.1rem;
  border-bottom: 1px solid ${colors.darkGreyThree} span {
    font-weight: ${typography.h3FontWeight};
  }
`

export const PanelWrapp = styled.div`
  display: flex;
  align-content: flex-start;
  justify-content: space-between;
`

export const PanelItem = styled.div`
  width: 100%;
  padding: 3rem;
  border: 0.1rem solid ${colors.darkGreyThree};
`

PanelItem.Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${media.sm`
    flex-direction: column;
  `}
`

PanelItem.Title = styled.h2``

PanelItem.Content = styled.div`
  border-top: 0.1rem solid #ddd;
  padding-top: 2.5rem;
`

PanelItem.Description = styled.p`
  font-weight: 300;
  line-height: 2.4rem;
  margin-bottom: 2rem;
`

PanelItem.Image = styled.img`
  width: 100%;
  height: auto;
`

export const LessonsBlock = styled.div`
  width: 30%;
  flex-basis: 30%;
  padding-top: 6.7rem;
  margin-left: 1.5rem;

  & > div {
    padding: 0;
  }

  ${media.md`
    width: 100%;
    margin-left: 0;
    padding-top: 2.9rem;
  `}
`

export const Footer = styled.section`
  height: 7rem;
  border-top: 0.1rem solid #eeeeee;
  width: 100vw;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #fff;
  padding: 0 4rem;
`

Footer.Wrapp = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;

  .ant-btn {
    margin-right: 0.8rem;

    &:last-child {
      margin-right: 0;
    }
  }
`

export const CourseExpire = styled.span`
  color: red;
  font-weight: ${typography.h2FontWeight};
  margin-right: 6rem;
`

export const Btn = styled(Button)`
  && {
    width: 15rem;
    height: 4rem;
    font-weight: ${typography.h2FontWeight};

    ${props =>
  props.startlesson &&
  css`
        background-color: ${colors.green} !important;
        border-color: ${colors.green} !important;
        display: flex;
        align-items: center;
        justify-content: space-around;
      `}
  }
`
export const Switched = styled(Switch)`
  && {
    ${props =>
  props.startlesson &&
  css`
        background-color: ${colors.green} !important;
        border-color: ${colors.green} !important;
        display: flex;
        align-items: center;
        justify-content: space-around;
      `}
  }
`

export const PageWrapper = styled.div`
  resize: both;
  position: relative;
  margin: 3rem 0 0 0;
  .el__preview {
    background: rgba(255, 255, 255, 0);
  }
`
export const AnnotationPageWrapper = styled.div`
  resize: both;
  position: relative;
  margin: 3rem 0 0 0;
  .el__preview {
    background: rgba(255, 255, 255, 0);
  };
  height:100%;
  min-height: 1060px;
`
export const LandscapePageWrapper = styled.div`
  resize: both;
  position: relative;
  margin: -4rem 0 0 0;
  .el__preview {
    background: rgba(255, 255, 255, 0);
  }

  ${props =>
  props.scale &&
  props.size &&
  css`
      height: ${props.size.height - 200 * props.scale}px;
    `}
    overflow: auto;
`

export const PageContent = styled.section`
  position: relative;
  width: 100%;
  min-height: 1024px;
  height:auto;
  border: 1px solid ${colors.light};
  background-color: ${colors.white};
  overflow: auto;
  top: -35px;
`

export const LandscapePageContent = styled.section`
  position: relative;
  width: 100%;
  min-height: 548px;
  height: auto;
  bottom: 0px;
  border: 1px solid ${colors.light};
  background-color: ${colors.white};
`
