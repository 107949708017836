import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import actions from '../../../../store/actions/index'
import EventsReport from './EventsReport'

export default connect(
  state => ({
    data: state.reports
  }),
  dispatch => ({
    handlingData: bindActionCreators(actions.reports.handlingData, dispatch),
    getReport: bindActionCreators(actions.reports.getEventList, dispatch)
  })
)(EventsReport)
