import React, { Component } from 'react'

import { SearchRectangular } from './slyled'

export default class extends Component {
  render () {
    const props = this.props

    return (
      <SearchRectangular {...props} />
    )
  }
}
