import React, { Component } from 'react'
import { Spin, Avatar, Tooltip } from 'antd'
import _ from 'lodash'
import { Profile } from './styled'
import { api } from '../../../../services'
import history from '../../../../history'
import { withTranslation } from 'react-i18next'
import './style.scss'

class _Profile extends Component {
  state = {
    active: false,
    updatingUser: false
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  handleClick = () => {
    this.setState({
      active: !this.state.active
    })
  }

  setRef = node => {
    this.ref = node
  }

  handleClickOutside = event => {
    if (this.ref && !this.ref.contains(event.target)) {
      this.setState({
        active: false
      })
    }
  }

  handleClickSignOut = () => {
    api.user.signOut().then(() => {
      window.location.reload()
    })
  }

  handleClickItem = href => {
    history.push(href)
    this.setState({
      active: false
    })
  }

  switchAccount = id => {
    const {
      user: { info }
    } = this.props
    // const canReload =
    //   id && info.active_organization_id
    //     ? id !== info.active_organization_id
    //       ? true
    //       : false
    //     : false
    if (info.active_organization_id === id) {
      this.handleClick()
      return
    }
    this.setState(
      {
        updatingUser: true
      },
      () => {
        api.user
          .updateUser(info.id, null, { active_organization_id: id })
          .then(() => {
            // if (canReload) {
            window.location.reload()
            // } else {
            // history.push('/')
            // }
          })
      }
    )
  }
  getSmallProfileImage = image => {
    if (image) {
      const { link, resizes } = image
      if (_.isEmpty(resizes)) {
        return link
      } else {
        return resizes.thumbnail
      }
    }
    return ''
  }

  render() {
    const {
      user: {
        info: {
          name,
          username,
          avatar,
          active_organization_id: orgId,
          isSuperAdmin,
          isAdmin,
          email
        },
        isFetchingUserAttributes
      },
      organizations,
      t
    } = this.props
    const { active, updatingUser } = this.state

    const picture = this.getSmallProfileImage(avatar) // avatar ? avatar.link : ''
    const activeOrg =
      !_.isEmpty(organizations) &&
      !_.isEmpty(organizations.rows) &&
      organizations.rows.find(i => i.id === orgId)
    const orgLogo =
      activeOrg && activeOrg.logo && this.getSmallProfileImage(activeOrg.logo) // activeOrg.logo ? activeOrg.logo.link : ''
    // const userAvatar = orgId ? orgLogo : picture

    return (
      <Profile ref={this.setRef}>
        <Profile.Head onClick={this.handleClick} active={active}>
          <Profile.Thumb>
            {/* {userAvatar ? (
              <img src={userAvatar} alt="" />
            ) : (
              <Avatar size="large" icon="user" />
            )} */}

            {orgId && orgLogo ? (
              <img src={orgLogo} alt="" />
            ) : orgId && activeOrg ? (
              <Profile.Item.DefaultOrgImage>
                {activeOrg.title.charAt(0).toUpperCase()}
              </Profile.Item.DefaultOrgImage>
            ) : picture ? (
              <img src={picture} alt="" />
            ) : (
              <Avatar size="large" icon="user" />
            )}
          </Profile.Thumb>
          <Profile.Info>
            <Profile.Type>
              {activeOrg ? t('general:organization') : t('general:personal')}
            </Profile.Type>
            <Profile.Name>
              {activeOrg ? activeOrg.title : name || username}
            </Profile.Name>
          </Profile.Info>
          <Profile.Icon type="down" />
        </Profile.Head>
        <Profile.Dropdown active={active}>
          <Profile.List>
            <div
              className="ssl-account-detail"
              onClick={() => this.handleClickItem('/account')}
            >
              <div className="ssl-org-thumb">
                {orgId && orgLogo ? (
                  <img src={orgLogo} alt="" />
                ) : orgId && activeOrg ? (
                  <Profile.Item.DefaultOrgImage>
                    {activeOrg.title.charAt(0).toUpperCase()}
                  </Profile.Item.DefaultOrgImage>
                ) : picture ? (
                  <img src={picture} alt="" />
                ) : (
                  <Avatar size="large" icon="user" />
                )}
              </div>
              <div className="ssl-username">
                <span className="user-org">
                  {activeOrg ? activeOrg.title : name || username}
                </span>
                <span className="user-name">{name || username}</span>
                <span className="user-mail">{email}</span>
              </div>
            </div>
            <Profile.Item onClick={() => this.handleClickItem('/account')}>
              <Profile.Item.Icon src={'/images/icons/menu/account.svg'} />
              <Profile.Item.Title>{t('navigation:account')}</Profile.Item.Title>
            </Profile.Item>
            <Profile.Item onClick={() => this.handleClickItem('/calendar')}>
              <Profile.Item.Icon src={'/images/icons/menu/calendar.svg'} />
              <Profile.Item.Title>{t('v3:my_calendar')}</Profile.Item.Title>
            </Profile.Item>
            {(isSuperAdmin || isAdmin) && (
              <Profile.Item onClick={() => this.handleClickItem('/management')}>
                <Profile.Item.Icon
                  src={'/images/icons/menu/organization.svg'}
                />
                <Profile.Item.Title>
                  {t('navigation:organizations_users')}
                </Profile.Item.Title>
              </Profile.Item>
            )}
            <Profile.Item onClick={this.handleClickSignOut}>
              <Profile.Item.Icon src={'/images/icons/menu/logout-red.svg'} />
              <Profile.Item.Title style={{ color: '#FF4C54' }}>
                {t('navigation:logout')}
              </Profile.Item.Title>
            </Profile.Item>

            <Spin
              spinning={updatingUser || isFetchingUserAttributes}
              tip={updatingUser ? t('v4:switching_organization') : ''}
            >
              <div className="ssl-scrollbar-bg">
                {!_.isEmpty(organizations) &&
                  !_.isEmpty(organizations.rows) &&
                  organizations.rows.map(({ id, title, logo }) => (
                    <Profile.Account
                      key={id}
                      selected={orgId === id}
                      onClick={() => this.switchAccount(id)}
                    >
                      <Profile.Thumb>
                        {logo ? (
                          <img src={this.getSmallProfileImage(logo)} alt="" />
                        ) : (
                          <Profile.Item.DefaultOrgImage>
                            {title.charAt(0).toUpperCase()}
                          </Profile.Item.DefaultOrgImage>
                        )}
                        {/* <img src={this.getSmallProfileImage(logo)} alt="" /> */}
                      </Profile.Thumb>
                      <Tooltip title={title}>
                        <Profile.Info>
                          <Profile.Type>
                            {t('general:organization')}
                          </Profile.Type>
                          <Profile.Name>{title}</Profile.Name>
                        </Profile.Info>
                      </Tooltip>
                      {orgId != id && (
                        <Profile.Switch>{t('v4:switch')}</Profile.Switch>
                      )}
                    </Profile.Account>
                  ))}

                <Profile.Account
                  selected={!orgId}
                  onClick={() => this.switchAccount(null)}
                >
                  <Profile.Thumb>
                    {picture ? (
                      <img src={picture} alt="" />
                    ) : (
                      <Avatar size="large" icon="user" />
                    )}
                  </Profile.Thumb>
                  <Profile.Info>
                    <Profile.Type>{t('general:personal')}</Profile.Type>
                    <Profile.Name>{name}</Profile.Name>
                  </Profile.Info>
                </Profile.Account>
              </div>
            </Spin>
          </Profile.List>
        </Profile.Dropdown>
      </Profile>
    )
  }
}

export default withTranslation()(_Profile)
