import React from 'react'
import Dropzone from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { Row, Col, Button, Progress, Input, Spin } from 'antd'
import className from 'classnames'
import { uploadMedia } from '../../../../internal/List/images'

const View = props => {
  const { t } = useTranslation()
  const { TextArea } = Input
  const {
    mediaDat,
    zipLoading,
    imgLoading,
    isZip,
    submitLoading,
    selectedData,
    imageSizeCheck,
    editAssets,
    onChangeTitle,
    onChangeDescription,
    onDropTargetImage,
    onDropppedZip,
    onClickUpdate,
    onClickSubmit
  } = props

  //const condn = (!editAssets && !mediaDat.hasOwnProperty('ar_files'))

  // const index =
  //   mediaDat &&
  //   mediaDat.ar_files &&
  //   mediaDat.ar_files.findIndex(obj => {
  //     return obj.media_format === 'IMAGE'
  //   })

  // const audioIndex =
  //   mediaDat &&
  //   mediaDat.ar_files &&
  //   mediaDat.ar_files.findIndex(obj => {
  //     return obj.media_format === 'AUDIO'
  //   })
  return (
    <>
      <Row>
        <col span={2}></col>
        <Col span={20} offset={2}>
          <h3 className="upload-modal__text" style={{ textAlign: 'left' }}>
            {t('v2:model_title')}
          </h3>
          <Input
            placeholder={t('v2:enter_model_title')}
            defaultValue={selectedData && selectedData.title}
            allowClear
            onChange={onChangeTitle}
          />
        </Col>
        <col span={2}></col>
      </Row>
      <br />
      <br />
      <Row>
        <col span={2}></col>
        <Col span={20} offset={2}>
          <h3 className="upload-modal__text" style={{ textAlign: 'left' }}>
            {t('v2:model_description')}
          </h3>
          <TextArea
            placeholder={t('v2:please_type_model_description')}
            defaultValue={selectedData && selectedData.description}
            autoSize={{ minRows: 2, maxRows: 6 }}
            allowClear
            onChange={onChangeDescription}
          />
        </Col>
        <col span={2}></col>
      </Row>
      <br />
      <br />
      <Row>
        <Col span={3}></Col>
        <Col span={18}>
          <h3
            className="upload-modal__text"
            style={{ textAlign: 'left', marginLeft: '-32px' }}
          >
            {t('v2:model_image')}
          </h3>
          {/* <p style={{ textAlign: 'left', fontSize: '13px' }}>
            {t('v2:_jpg_files')}
          </p> */}
          <div className="upload-modal__inner">
            <div className="upload-modal__dropzone">
              <Dropzone
                accept={['image/jpeg']}
                maxFiles={1}
                maxSize={2306867}
                onDrop={onDropTargetImage}
              >
                {({ isDragActive }) => (
                  <div>
                    <img
                      id="preview-img"
                      src={selectedData && selectedData.link}
                      style={{
                        position: 'absolute',
                        opacity: '0.4',
                        objectFit: 'cover'
                      }}
                      width="900"
                      height="189"
                      alt=""
                    />
                    <div
                      className={className('upload-dropzone', {
                        'upload-dropzone_active': isDragActive
                      })}
                    >
                      <Spin size="large" spinning={imgLoading}>
                        <div className="upload-dropzone__text-wrapper">
                          <img src={uploadMedia} alt="" />
                          <p
                            className="upload-dropzone__text"
                            style={{ width: '148px' }}
                          >
                            <span>{t('v2:upload_model_image')}</span>
                            <br />
                            {t('v2:drag_n_drop')}
                            <br />
                            {imageSizeCheck && (
                              <em style={{ color: 'red' }}>
                                {t('v2:file_size_should_not_exceed')}
                              </em>
                            )}
                          </p>
                        </div>
                      </Spin>
                    </div>
                  </div>
                )}
              </Dropzone>
            </div>
          </div>
        </Col>
        <Col span={3}></Col>
      </Row>
      <br />
      <br />
      <Row>
        <Col span={3}></Col>
        <Col span={18}>
          <h3
            className="upload-modal__text"
            style={{ textAlign: 'left', marginLeft: '-32px' }}
          >
            {t('v2:model_content')}
          </h3>
          <p style={{ textAlign: 'left', fontSize: '13px' }}>
            {t('v2:maze_3d_files_mp4')}
          </p>
          <div className="upload-modal__inner">
            <div className="upload-modal__dropzone">
              <Dropzone
                //accept={['application/zip', 'video/*']}
                //accept={['application/zip', 'application/x-zip-compressed']}
                //accept={'*'}
                maxFiles={1}
                onDrop={onDropppedZip}
              >
                {({ isDragActive }) => (
                  <div
                    className={className('upload-dropzone', {
                      'upload-dropzone_active': isDragActive
                    })}
                  >
                    <Spin size="large" spinning={zipLoading}>
                      <div className="upload-dropzone__text-wrapper">
                        <img src={uploadMedia} alt="" />
                        <p
                          className="upload-dropzone__text"
                          style={{ width: '100px' }}
                        >
                          <span>{t('v2:upload_3d_maze_zip')}</span>{' '}
                          {t('v2:drag_n_drop')}
                        </p>
                        <br />
                        {mediaDat.hasOwnProperty('ar_files') && (
                          <div>
                            <Progress type="circle" percent={100} width={30} />
                            <p
                              className="upload-dropzone__text"
                              style={{ width: '118px' }}
                            >
                              {isZip
                                ? t('v2:content_attached')
                                : t('v2:video_attached')}
                            </p>
                          </div>
                        )}
                      </div>
                    </Spin>
                  </div>
                )}
              </Dropzone>
            </div>
          </div>
        </Col>
        <Col span={3}></Col>
      </Row>
      <br />
      <br />
      <br />
      <Row>
        <Col span={4} offset={20}>
          <Button
            type="primary"
            shape="round"
            size="large"
            onClick={editAssets ? onClickUpdate : onClickSubmit}
            disabled={
              !editAssets &&
              !(
                mediaDat.hasOwnProperty('category') &&
                mediaDat.hasOwnProperty('ar_files')
              )
            }
          >
            {editAssets
              ? submitLoading
                ? t('v2:updating___')
                : t('v2:update')
              : submitLoading
              ? t('v2:creating___')
              : t('v2:create')}
          </Button>
        </Col>
      </Row>
    </>
  )
}

export default View
