import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import actions from '../../../store/actions'
import View from './View'

export default connect(
  state => ({
    assessments: state.assessments,
    user: state.user
  }),
  dispatch => ({
    assessmentsActions: bindActionCreators(actions.assessments, dispatch),
    viewAssessment: bindActionCreators(
      actions.assessments.viewAssessment,
      dispatch
    )
  })
)(View)
