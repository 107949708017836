import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import actions from '../../../store/actions'

import View from './View'

const { getCourseById, getLessonById, enrollCourse, unenrollCourse, viewCourse, resetCourseById, initCloneSocket } = actions.courses
const { clearNotes } = actions.notes
const { getDiscussionLog, clearDiscussion } = actions.discussion
const { updateUser } = actions.user

const mapStateToProps = state => ({
  courses: state.courses,
  course: state.courses.courseById,
  isCourseEnrollment: state.courses.isCourseEnrollment,
  user: state.user,
  topics: state.topics,
  log: state.discussion.log,
  organizations: state.organizations.list
})

const mapDispatchToProps = dispatch => bindActionCreators({
  getCourseById,
  getLessonById,
  resetCourseById,
  enrollCourse,
  unenrollCourse,
  viewCourse,
  initCloneSocket,
  clearNotes,
  getDiscussionLog,
  clearDiscussion,
  updateUser,
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(View)