import React, { Fragment } from 'react'
import { Button } from 'antd'
import './EventRooms.scss'
import { withTranslation } from 'react-i18next'
// import { withRouter } from 'react-router'
import { createBrowserHistory } from 'history'

function EventRooms(props) {
  const { rooms, isHost, onRoomCreate, t, event } = props
  const hasBreakoutRoom = event && event.has_breakout_room
  const eventId = event && event.id
  const history = createBrowserHistory()
  const handleBreakoutRoom = room => {
    history.push({
      pathname: `/events/${room.id}`,
      state: { hasBreakoutRoom, eventId }
    })
  }

  return (
    <Fragment>
      {rooms.map((room, idx) => (
        <div key={`file_${room.id}`} className="room__item">
          <span>
            {idx + 1}. {room.title}
          </span>
          <span>
            {room.is_finished ? (
              'Finished'
            ) : (
              <a
                href={`/events/${room.id}`}
                onClick={() => handleBreakoutRoom(room)}
              >
                {t('v4:join')}
              </a>
            )}
          </span>
        </div>
      ))}
      {isHost && (
        <Button
          onClick={onRoomCreate}
          style={{
            width: '100%'
          }}
          size="large"
        >
          {t('v2:create_room')}
        </Button>
      )}
    </Fragment>
  )
}

export default withTranslation()(EventRooms)
