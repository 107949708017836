import React, { Component } from 'react'
import AgoraRTC from 'agora-rtc-sdk'
import { Row, Button } from 'antd'
import http from '../../../../services/api/http'
import moment from 'moment'
import '../../../Events/Preview/components/Agora/AgoraVideoCall/agorastyles.scss'

let globalEstimatedTimeToStop
let min = Math.pow(10, 5)
let max = Math.pow(10, 8)
let checkDurationInterval = null
export default class AgoraRecording extends Component {
  constructor(props) {
    super(props)
    this.toggleAudioRef = React.createRef()
    this.client = {}
    // this.localStream = {}
    this.state = {
      isJoined: false,
      streamList: [],
      videoTiles: false,
      tileId: '',
      screenStreamList: [],
      estimatedTimeToStop: '',
      durationExceed: false,
      hasEnd: false
    }
  }

  componentDidMount() {
    setTimeout(() => {
      document.getElementById('join_event').click()
    }, 3000)
  }

  componentDidUpdate(prevProps, prevState) {
    const { tileId, streamList, screenStreamList } = this.state
    const prevTileId = prevState.tileId

    const screenShareStream =
      streamList &&
      streamList.filter(val => val.streamId >= min && val.streamId <= max)
    if (screenStreamList.length !== screenShareStream.length) {
      this.setState({
        screenStreamList: screenShareStream
      })
    }
    streamList &&
      streamList.forEach((item, index) => {
        let id = item.streamId
        let remote = document.getElementById(`video${id}`)
        remote &&
          remote.setAttribute(
            'style',
            `
        height: 100%;
        width: 100%;
        transform: scaleX(-1) rotateY(180deg);
        object-fit: cover;
        `
          )
      })

    const remoteVid = document.getElementById(`remote_${tileId}`)
    const remoteVidEle = document.getElementById(`remote_video_${tileId}`)
    const remoteInnerEle = document.getElementById(`inner_div_${tileId}`)

    const remotePrevVid = document.getElementById(`remote_${prevTileId}`)
    const remotePrevVidEle = document.getElementById(
      `remote_video_${prevTileId}`
    )
    const remotePrevInnerEle = document.getElementById(
      `inner_div_${prevTileId}`
    )

    if (tileId === null && streamList.length === 1) {
      const streamId = streamList[0].streamId
      const defaultVid = document.getElementById(`remote_${streamId}`)
      const defaultVidEle = document.getElementById(`remote_video_${streamId}`)
      const defaultInnerEle = document.getElementById(`inner_div_${streamId}`)
      if (defaultVid && screenShareStream.length === 0) {
        defaultVid.classList.add('chime_recording_main__view')
        defaultVid.classList.remove('chime_recording_side_view')
        defaultVidEle.classList.remove('remotePlayerClass')
        defaultInnerEle.classList.remove('myClass')
      }
    }

    if (remoteVid && screenShareStream.length === 0) {
      if (tileId !== null && tileId !== prevTileId) {
        remoteInnerEle.classList.remove('myClass')
        remoteVidEle.classList.remove('remotePlayerClass')
        remoteVid.classList.remove('chime_recording_side_view')
        remoteVid.classList.add('chime_recording_main__view')
      }
    }

    if (remotePrevVid) {
      if (prevTileId !== null && tileId && prevTileId !== tileId) {
        remotePrevVid.classList.add('chime_recording_side_view')
        remotePrevVidEle.classList.add('remotePlayerClass')
        remotePrevInnerEle.classList.add('myClass')
        remotePrevVid.classList.remove('chime_recording_main__view')
      }
    }

    if (screenShareStream.length !== 0) {
      const screenId = screenShareStream[0].streamId
      const screenVid = document.getElementById(`remote_${screenId}`)
      const screenVidEle = document.getElementById(`remote_video_${screenId}`)
      const screenInnerEle = document.getElementById(`inner_div_${screenId}`)

      screenInnerEle.classList.remove('myClass')
      screenVidEle.classList.remove('remotePlayerClass')
      screenVid.classList.remove('chime_recording_side_view')

      screenVid.classList.add('chime_recording_main__view')

      if (remoteVid) {
        remoteVid.classList.add('chime_recording_side_view')
        remoteVidEle.classList.add('remotePlayerClass')
        remoteInnerEle.classList.add('myClass')
        remoteVid.classList.remove('chime_recording_main__view')
      }
      if (prevTileId) {
        remotePrevVid.classList.add('chime_recording_side_view')
        remotePrevVidEle.classList.add('remotePlayerClass')
        remotePrevInnerEle.classList.add('myClass')
        remotePrevVid.classList.remove('chime_recording_main__view')
      }
    }
  }

  startEvent = async () => {
    this.setState({
      isJoined: true
    })
    const { eventId } = this.props

    const eventTitle = `event_${eventId}`

    const reqURL = `events/${eventId}/agora/`
    const payload = {
      title: eventTitle,
      name: 'recording#User',
      region: 'us-east-1'
    }
    const response = await http({
      method: 'PUT',
      url: `${reqURL}record-join`,
      data: payload
    })
    const { agora_token, channel_name, uid } = response.data.JoinInfo
    // Create a client
    this.client = AgoraRTC.createClient({ mode: 'live', codec: 'vp8' })

    this.client.enableAudioVolumeIndicator()
    this.client.on('volume-indicator', volumes => {
      console.log('volume-indicator-record', volumes)
      if (volumes.attr !== '') {
        volumes &&
          volumes.attr.forEach((volume, index) => {
            console.log(`${index} UID: ${volume.uid} Level ${volume.level}`)

            if (volume.uid >= min && volume.uid <= max) {
              return
            }

            if (volume.level > 0) {
              this.setState({
                tileId: volume.uid
              })
            }
          })
      } else {
        this.setState({
          tileId: null
        })
      }
    })

    this.setState(
      {
        videoTiles: true
      },
      () => {
        // Initialize the client
        this.client.init(
          agora_token,
          () => {
            this.subscribeStreamEvents()
            // Join a channel
            this.client.join(
              agora_token,
              channel_name,
              uid ? +uid : null,
              uid => {
                console.log('UserCheck ' + uid + ' join channel successfully')
              },
              err => {
                console.error('client join failed', err)
              }
            )
          },
          err => {
            console.error(err)
          }
        )
      }
    )
    this.handleClick()
    checkDurationInterval = setInterval(() => {
      this.checkDurationExceed()
    }, 30000)
  }

  handleClick = () => {
    this.calculateStopDurationOnConference()
  }

  subscribeStreamEvents = () => {
    let rt = this
    rt.client.on('stream-added', function(evt) {
      let stream = evt.stream
      rt.client.subscribe(stream, function(err) {
        console.log('Subscribe stream failed', err)
      })
    })

    rt.client.on('peer-leave', function(evt) {
      rt.removeStream(evt.uid)
    })

    rt.client.on('stream-subscribed', function(evt) {
      let stream = evt.stream
      rt.addStream(stream)
    })

    rt.client.on('stream-removed', function(evt) {
      let stream = evt.stream
      rt.removeStream(stream.streamId)
    })
  }

  removeStream = uid => {
    this.state.streamList.map((item, index) => {
      if (item.streamId === uid) {
        item.close()
        let tempList = [...this.state.streamList]
        tempList.splice(index, 1)
        this.setState({
          streamList: tempList
        })
      }
    })
  }

  addStream = (stream, push = false) => {
    const { streamList } = this.state
    this.setState(
      {
        streamList: [stream].concat(streamList)
      },
      () => {
        this.playRemote()
      }
    )
  }

  playRemote = () => {
    const { streamList } = this.state
    streamList &&
      streamList.map((item, index) => {
        let id = item.streamId
        item.play(`remote_${id}`)
      })
  }

  // calculate duration for conference to stop event
  calculateStopDurationOnConference = () => {
    const { startdatetime, duration } = this.props
    const { durationExceed } = this.state
    console.log('in check exceed duration', startdatetime, 'duration', duration)
    const estimatedTimeToStop = moment(startdatetime)
      .add(duration, 'm')
      .toDate()
    if (!durationExceed) {
      this.setState({
        estimatedTimeToStop
      })
    }
  }

  checkDurationExceed = () => {
    const { hasEnd, estimatedTimeToStop, streamList } = this.state
    globalEstimatedTimeToStop = estimatedTimeToStop
    const diffMinutes = moment().diff(globalEstimatedTimeToStop, 'minutes')
    // const filteredUser = globalAttendees.filter(attendee => {
    //   const indexOfRecordingUser = attendee.externalUserId.indexOf("recording#User");
    //   return indexOfRecordingUser === -1;
    // })

    if (
      (diffMinutes === 0 || diffMinutes > 0) &&
      streamList &&
      streamList.length < 1
    ) {
      this.setState({
        durationExceed: true
      })
      clearInterval(checkDurationInterval)
      checkDurationInterval = null
      this.setState({
        estimatedTimeToStop: ''
      })
      if (!hasEnd) {
        this.endEvent()
      }
    }
  }

  endEvent = async () => {
    this.setState({
      hasEnd: true
    })
    const { eventId } = this.props

    const eventTitle = `event_${eventId}`
    const reqURL = `events/${eventId}/agora/`

    const payload = {
      title: eventTitle
    }
    const response = await http({
      method: 'PUT',
      url: `${reqURL}end-event-record`,
      data: payload
    })
    if (response) {
      this.setState({
        videoTiles: false,
        isJoined: false,
        streamList: [],
        hasEnd: false
      })
    }
    try {
      // this.client && this.client.unpublish(this.localStream)
      // this.localStream && this.localStream.close()
      this.client &&
        this.client.leave(
          () => {
            console.log('Client succeed to leave.')
          },
          () => {
            console.log('Client failed to leave.')
          }
        )
    } finally {
      this.client = null
      // this.localStream = null
    }
  }

  render() {
    const { streamList, videoTiles } = this.state
    return (
      <div>
        <Row>
          {videoTiles && (
            <div className="recording__streams">
              <div className="__scrollbar" id="players">
                {streamList.length === 0 ? (
                  <video
                    autoPlay
                    muted
                    playsInline
                    className="default_element"
                  />
                ) : (
                  streamList.map(item => {
                    let id = item.streamId
                    return (
                      <div
                        className="remotePlayerClass"
                        key={`remote_video_${id}`}
                        id={`remote_video_${id}`}
                      >
                        <div className="myClass" id={`inner_div_${id}`}>
                          <div
                            // tileId={item}
                            id={`remote_${id}`}
                            className="chime_recording_side_view"
                          />
                        </div>
                      </div>
                    )
                  })
                )}
              </div>
            </div>
          )}
        </Row>
        <Button
          id="join_event"
          className="button__hidden"
          onClick={() => this.startEvent()}
        >
          Join
        </Button>
        <Button
          id="mute_event"
          className="button__hidden"
          // onClick={toggleMute}
        >
          Mute
        </Button>
      </div>
    )
  }
}
