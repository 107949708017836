import React, { useEffect, useState } from 'react'
import { DatePicker, TimePicker, Checkbox } from 'antd'
import moment from 'moment'
import './SelectDate.scss'

const dateFormat = 'DD/MM/YYYY'
const timeFormat = 'HH:mm'
const SelectDate = ({
  t,
  podcastId,
  dateTime,
  selectedDate,
  setSelectedDate,
  selectedStartTime,
  setSelectedStartTime,
  selectedEndTime,
  setSelectedEndTime,
  dateError,
  changeDateError
}) => {
  const [allDay, setAllDay] = useState(false)
  const [startTimeValue, setStartTimeValue] = useState(null)
  const [endTimeValue, setEndTimeValue] = useState(null)
  const [defaltDateValue, setDateValue] = useState('')
  useEffect(() => {
    if (dateTime.date) {
      let date = moment(dateTime.date)
        .local()
        .format(dateFormat)
      setDateValue(date)
      setSelectedDate(moment(dateTime.date).local())
      onStartTimeChange(moment(dateTime.date).local())
      onEndTimeChange(
        moment(dateTime.date)
          .local()
          .add(dateTime.duration, 'm')
      )
      if (dateTime.duration === 1440) {
        setAllDay(true)
      }
    }
  }, [dateTime])
  useEffect(() => {
    if (selectedDate && selectedStartTime && selectedEndTime) {
      changeDateError(false)
    }
  }, [selectedDate, selectedStartTime, selectedEndTime])
  const onAllDayChange = e => {
    setAllDay(e.target.checked)
    if (e.target.checked) {
      setSelectedStartTime('00:00')
      setSelectedEndTime('24:00')
      setStartTimeValue(moment('00:00', timeFormat))
      setEndTimeValue(moment('24:00', timeFormat))
    } else {
      setSelectedStartTime(null)
      setSelectedEndTime(null)
      setStartTimeValue(null)
      setEndTimeValue(null)
    }
  }

  const onDateChange = (date, dateString) => {
    if (date) {
      setSelectedDate(date.toDate())
    }
  }

  const onStartTimeChange = (time, timeString) => {
    if (time) {
      setSelectedStartTime(time.format(timeFormat))
      setStartTimeValue(time)
    }
  }

  const onEndTimeChange = (time, timeString) => {
    if (time) {
      setSelectedEndTime(time.format(timeFormat))
      setEndTimeValue(time)
    }
  }

  const disableHours = () => {
    if (selectedStartTime) {
      let hours = []
      for (let i = 0; i < moment(selectedStartTime, timeFormat).hour(); i++) {
        hours.push(i)
      }
      return hours
    }
    return []
  }

  const disableMinutes = selectedHour => {
    if (
      selectedStartTime &&
      selectedHour === moment(selectedStartTime, timeFormat).hour()
    ) {
      let minutes = []
      for (let i = 0; i < moment(selectedStartTime, timeFormat).minute(); i++) {
        minutes.push(i)
      }
      return minutes
    }
    return []
  }
  const disabledDate = current => current && current < moment().startOf('day')
  return (
    <div className="select-date">
      <div className="select-date__content">
        <div className="ant-row ant-form-item">
          <div className="select-date-title">{t('v4:show_time')}</div>
          <div className="time-container">
            <div className="date-section">
              <div style={{ fontSize: '1.4rem' }}>
                {t('v3:podcast_date')} &nbsp;
              </div>
              <DatePicker
                value={
                  podcastId
                    ? moment(
                        selectedDate ? selectedDate : defaltDateValue,
                        dateFormat
                      )
                    : selectedDate
                    ? moment(selectedDate, dateFormat)
                    : ''
                }
                disabledDate={disabledDate}
                className="date-picker"
                format={dateFormat}
                onChange={onDateChange}
              />
            </div>

            <div className="time-section">
              <div style={{ fontSize: '1.4rem' }}>
                {t('v3:podcast_time')}&nbsp;
              </div>
              <TimePicker
                value={startTimeValue}
                className="time-picker"
                format={timeFormat}
                disabled={allDay}
                placeholder={t('v4:start_time')}
                onChange={onStartTimeChange}
              />
              <span className={'to'}>{t('v3:podcast_to')}</span>
              <TimePicker
                value={endTimeValue}
                className="time-picker"
                format={timeFormat}
                disabled={allDay}
                placeholder={t('v4:end_time')}
                onChange={onEndTimeChange}
                disabledHours={disableHours}
                disabledMinutes={disableMinutes}
              />

              <Checkbox className="all-day-picker" onChange={onAllDayChange}>
                {t('v3:podcast_all_day')}
              </Checkbox>
            </div>
          </div>
          {dateError && (
            <div
              style={{
                color: '#ff4d4f',
                fontSize: '12px',
                height: '1.5rem'
              }}
            >
              {t('v3:podcast_start_date_time')}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default SelectDate
