import styled from 'styled-components'

const Loader = styled.div`
  width: 100%;
  padding: 3rem 0;
  text-align: center;
`

export {
  Loader
}
