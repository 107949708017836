import React, { useCallback, useMemo, useState } from 'react'

import './YoutubeVideosList.scss'
import { Alert, Button, Card, Checkbox, Input, Spin } from 'antd'
import Spinner from '../../Spinner'
import AiLanguageSelect from '../../AiLanguageSelect'
import { useTranslation } from 'react-i18next'

const { Search } = Input

function YoutubeVideosList({
  videos = [],
  loading,
  onLoadMore,
  showLoadMore,
  handleSelection,
  handleSelectAll,
  onYoutubeSearch,
  handleEnableAI,
  handleAiLanguage
}) {
  const [youtubeSearch, setYoutubeSearch] = useState('')

  const isAllSelected = useMemo(() => {
    return videos.length > 0 && videos.every(video => video.selected)
  }, [videos])

  const handleLoadMore = useCallback(() => {
    onLoadMore('MORE', youtubeSearch)
  }, [youtubeSearch, onLoadMore])

  const { t } = useTranslation()
  return (
    <>
      {' '}
      <div className="youtube_videos_header">
        <h2 className="youtube_videos_results">Showing Results:</h2>
        <div className="youtube_videos_actions">
          <div className="youtube_videos_select_all">
            <input
              id="select-all"
              type="checkbox"
              checked={isAllSelected}
              onChange={e => handleSelectAll(e.target.checked)}
            />
            <label htmlFor="select-all">{t('v3:select_all')}</label>
          </div>
          <div className="youtube_videos_search">
            <Search
              placeholder="Search"
              size="small"
              onChange={e => setYoutubeSearch(e.target.value)}
              onSearch={onYoutubeSearch}
            />
          </div>
        </div>
      </div>
      <Spin spinning={loading}>
        <div className="youtube_videos">
          {videos.length === 0 ? (
            <Alert type="info" message="No Videos Found !" />
          ) : (
            videos.map((item, ind) => (
              <div key={item.etag} className="youtube_video">
                <Card
                  style={{ width: '100%', height: '100%' }}
                  cover={
                    <img
                      alt=""
                      className="youtube_video_thumbnail"
                      src={item.snippet.thumbnails.medium.url}
                    />
                  }
                >
                  <Card.Meta
                    title={item.snippet.title}
                    description={item.snippet.description || 'No Description'}
                  />

                  <div className="youtube_video_actions">
                    {item.selected && (
                      <Checkbox
                        className="select-youtube-video-ai"
                        checked={item.isAiEnabled}
                        onClick={e =>
                          handleEnableAI(item.etag, e.target.checked)
                        }
                      >
                        {t('v3:enable_ai')}
                      </Checkbox>
                    )}

                    {item.isAiEnabled && (
                      <AiLanguageSelect
                        className="select-youtube-video-aiLang"
                        handleAiLanguage={lang =>
                          handleAiLanguage(item.etag, lang)
                        }
                      />
                    )}
                  </div>
                </Card>

                <Checkbox
                  checked={item.selected || false}
                  onChange={e => handleSelection(item.etag, e.target.checked)}
                  className="select-youtube-video"
                />
              </div>
            ))
          )}
        </div>
      </Spin>
      <div className="youtube_videos_footer">
        {loading ? (
          <Spinner />
        ) : showLoadMore ? (
          <Button onClick={handleLoadMore}>{t('v3:load_more')}</Button>
        ) : null}
      </div>
    </>
  )
}

export default YoutubeVideosList
