/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-06-19 10:54:34
 * @LastEditTime: 2019-08-29 19:57:31
 * @LastEditors: Please set LastEditors
 */
import { connect } from 'react-redux'
import Header from './Header'

import actions from '../../store/actions'

const mapStateToProps = state => ({
  isFetchingUser: state.user.isFetchingUser,
  user: state.user,
  organizations: state.organizations.list,
  courseCloningProgress: state.courses.courseCloningProgress,
  isShowPopover: state.courses.isShowPopover,
  courseCloneTitle: state.courses.courseCloneTitle
})

const mapDispatchToProps = dispatch => ({
  updateUser: (id, attr, dbAttr) => dispatch(actions.user.updateUser(id, attr, dbAttr))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header)
