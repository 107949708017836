import React, { Component } from 'react'
import {
  Icon,
  Tooltip,
  Modal,
  Select,
  Spin,
  message,
  Checkbox as Check
} from 'antd'
import debounce from 'lodash/debounce'
import moment from 'moment'
import axios from 'axios'
import { api } from '../../../../services'
import { withTranslation } from 'react-i18next'
import _ from 'lodash'
import './Card.scss'
import { makePrivateIcon, makePublicIcon } from './images'
import { builder } from '../../../../constants'
import Checkbox from '../Checkbox'
import { pdfImage, textIcon, pptImage } from '../../internal/Gallery/images'

const { types } = builder
const { Option } = Select

const defaultThumbs = {
  IMAGE: '/images/placeholder-image@2x.png',
  VIDEO: '/images/placeholder-video@2x.png',
  AUDIO: '/images/placeholder-audio@2x.png',
  VIDEO_STREAM: '/images/placeholder-video@2x.png',
  VIDEO_ANTMEDIA: '/images/placeholder-video@2x.png'
}

class Card extends Component {
  constructor(props) {
    super(props)
    this.lastFetchId = 0
    this.fetchUserEmails = debounce(this.fetchUserEmails, 1000)
    this.state = {
      thumb: defaultThumbs[props.item.media_format],
      duration: 0,
      isChecked: false,
      status: '',
      isShareModal: false,
      emailList: [],
      selectedValue: [],
      fetching: false,
      isSuccess: false,
      tempData: []
    }
    this.selectRef = React.createRef(null)
  }

  componentDidMount() {
    const { item } = this.props
    this.getThumb()
    if (item.remark_public || item.remark_org) {
      this.setState({
        status: 'private'
      })
    } else {
      this.setState({
        status: 'private'
      })
    }
  }

  getSmallImage = ({ resizes, link }) => {
    /*if (_.isEmpty(resizes)) {
      return link
    } else {
      return resizes.medium
    }*/
    if (link !== '') {
      return link
    } else if (_.isEmpty(resizes)) {
      return resizes.medium
    }
  }

  checkIsDocx = type => {
    return (
      type ===
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    )
  }

  getThumb = () => {
    const { item } = this.props
    const isPPT = this.checkIsPPT(item.media_mime_type)
    const isDocx = this.checkIsDocx(item.media_mime_type)

    if (isDocx) {
      this.setState({ thumb: textIcon })
    } else if (isPPT) {
      this.setState({ thumb: pptImage })
    } else if (
      item.media_format === types.IMAGE ||
      item.media_format === types.ARIMAGE ||
      item.media_format === types.ICON ||
      item.media_format === types.LOGO ||
      item.media_format === types.SIGNATURE ||
      item.media_format === types.CERTIFICATE ||
      item.media_format === types.BADGE ||
      item.media_format === 'GAME'
    ) {
      this.setState({
        thumb: this.getSmallImage(item)
      })
    } else if (
      item.media_format === types.VIDEO_ANTMEDIA ||
      item.media_format === types.VIDEO ||
      item.media_format === types.VIDEO_STREAM
    ) {
      axios
        .get(
          'https://vimeo.com/api/oembed.json?url=https://player.vimeo.com/video/' +
            item.vimeo_id
        )
        .then(response => {
          this.setState({
            thumb: response.data.thumbnail_url,
            duration: response.data.duration
          })
        })
    } else if (item.media_format === types.PDF) {
      this.setState({
        thumb: pdfImage
      })
    } else if (item.media_format === types.TEXT) {
      this.setState({
        thumb: textIcon
      })
    }
  }

  updateCardStatus = (item, thumb) => {
    this.props.ctrlLibraryStatus(item, thumb)
  }

  updateOrgCardStatus = (item, thumb) => {
    this.props.ctrlOrgLibrary(item, thumb)
  }

  checkedCard = (e, thumb) => {
    const { ctrlHandleCheckCard } = this.props

    this.setState({ isChecked: e.target.checked })
    ctrlHandleCheckCard(e.target.checked, thumb)
  }

  handleMediaStatusClass = item => {
    return item.is_loading ? 'is-loading' : ''
  }

  handleMediaStatusName = (remark_public, remark_org) => {
    return remark_public || remark_org ? 'make-private' : ''
  }

  handleMediaCardClass = isChecked => {
    return isChecked ? 'is-checked' : ''
  }

  handleReturnLabel = status => {
    const { t } = this.props
    if (status.remark_public) {
      return t('buttons:make_private')
    } else {
      return t('buttons:make_public')
    }
  }

  handleReturnOrgAdminLabel = status => {
    const { t } = this.props
    if (status.remark_org) {
      return t('buttons:make_private')
    } else {
      return t('buttons:make_org')
    }
  }

  renderImg = () => {
    const { item, allowUpdateStatus, allowOrgAdminUpdateStatus } = this.props
    if (allowUpdateStatus && allowOrgAdminUpdateStatus) {
      if (item.remark_public) {
        return makePrivateIcon
      }
      return makePublicIcon
    } else if (!allowUpdateStatus && allowOrgAdminUpdateStatus) {
      if (item.remark_org) {
        return makePrivateIcon
      }
      return makePublicIcon
    }
    return makePublicIcon
  }

  onClickShareModal = () => {
    this.setState({
      isShareModal: true
    })
  }

  handleCancelShareModal = () => {
    this.setState({
      isShareModal: false
    })
  }

  handleOkShareModal = () => {
    this.setState(
      {
        isShareModal: false
      },
      () => {
        const { selectedValue, tempData } = this.state
        const { item } = this.props
        let tempEmails = []
        selectedValue.forEach(element => {
          let obj = tempData.find(o => o.email === element.label)
          if (obj !== undefined) {
            tempEmails.push({
              user_id: obj.id,
              email: obj.email,
              username: obj.username
            })
          }
        })
        const data = {
          filename: item.filename,
          public: item.is_public,
          emails: tempEmails,
          media_data_id: item.id,
          path: item.path
        }
        const { t } = this.props
        api.library
          .sharePdf(data)
          .then(res => {
            if (res.status === 200) {
              message.info(t('v4:shared_file'))
            }
          })
          .catch(() => {
            message.info(t('v4:error_shared_file'))
          })
      }
    )
  }

  fetchUserEmails = value => {
    const { activeOrganizationId } = this.props
    this.lastFetchId += 1
    const fetchId = this.lastFetchId
    this.setState({ emailList: [], tempData: [], fetching: true })
    api.organizations
      .getUsersByOrganizationId(activeOrganizationId)
      .then(res => {
        if (fetchId !== this.lastFetchId) {
          return
        }
        res.data.forEach(val => {
          if (val.user) {
            const dataValue = {
              text: val.user.email,
              value: val.user.email
            }
            this.setState(
              state => {
                const tempData = [
                  ...state.tempData,
                  {
                    id: val.user.id,
                    email: val.user.email,
                    username: val.user.username
                  }
                ]
                return {
                  tempData
                }
              },
              () => {
                this.setState(state => {
                  if (dataValue.value.includes(value)) {
                    const emailList = [...state.emailList, dataValue]
                    return {
                      emailList,
                      fetching: false
                    }
                  }
                })
              }
            )
          }
        })
      })
  }

  handleChange = selectedValue => {
    this.setState({
      selectedValue,
      emailList: [],
      fetching: false
    })
  }

  checkIsPPT = (fileMimeType = '') =>
    fileMimeType.includes('powerpoint') || fileMimeType.includes('presentation')

  render() {
    const {
      item,
      allowUpdateStatus,
      allowOrgAdminUpdateStatus,
      allowDelete,
      handleViewDetails,
      isPageBuilder,
      tab,
      t,
      allowMultiple,
      ctrlCheckPdfCard,
      handleClickEdit
    } = this.props
    const {
      thumb,
      duration,
      isChecked,
      isShareModal,
      fetching,
      emailList,
      selectedValue
    } = this.state

    const tooltipPublic = item.remark_public
      ? ''
      : `${t('labels:make')} ${item.title} ${t('labels:available_to')} `

    const isFileThumb =
      item.media_format === 'APPLICATION' ||
      item.media_format === types.PDF ||
      item.media_format === types.TEXT

    return (
      <div className={`media-card ${this.handleMediaCardClass(isChecked)}`}>
        <div className="media-card__inner">
          {item && item.ar_files && item.ar_files.length > 0 && (
            <span>
              <Icon
                type="edit"
                onClick={() => handleClickEdit(item)}
                style={{
                  backgroundColor: '#2e2b2b94',
                  fontSize: '16px',
                  color: '#ffffff',
                  zIndex: 1,
                  position: 'absolute'
                }}
                theme="outlined"
              />
            </span>
          )}
          <div
            className="media-card__thumb"
            onClick={e => handleViewDetails(item)}
          >
            <img
              className={isFileThumb ? 'fileThumb' : 'imageThumb'}
              src={thumb}
              alt=""
            />
            {duration > 0 && (
              <div className="media-card__duration">
                {moment
                  .duration(duration, 'seconds')
                  .format(duration >= 3600 ? 'hh:mm:ss' : 'mm:ss', {
                    trim: false
                  })}
              </div>
            )}
          </div>
          <div className="media-card__content">
            <div className="media-card__format">
              <span>
                {this.checkIsDocx(item.media_mime_type)
                  ? 'DOCX'
                  : this.checkIsPPT(item.media_mime_type)
                  ? 'PPT'
                  : item.media_format === 'APPLICATION'
                  ? 'PDF'
                  : item.media_format === 'VIDEO_STREAM' ||
                    item.media_format === 'VIDEO_ANTMEDIA'
                  ? 'VIDEO'
                  : item.media_format}
              </span>
              {(item.media_format === 'APPLICATION' ||
                item.media_format === 'TEXT') && (
                <span style={{ left: '16px' }}>
                  {!allowMultiple && (
                    <span
                      style={{ color: '#1890ff', cursor: 'pointer' }}
                      onClick={this.onClickShareModal}
                    >
                      {t('v4:share')}
                    </span>
                  )}
                  {isShareModal && (
                    <Modal
                      title={
                        item.media_format === 'TEXT'
                          ? t('v4:share_text_title_txt')
                          : t('v4:share_text_title')
                      }
                      visible={isShareModal}
                      onOk={this.handleOkShareModal}
                      onCancel={this.handleCancelShareModal}
                    >
                      <p>
                        {t('v4:share_text')} <b>{item.filename}</b>
                      </p>

                      <Select
                        mode="multiple"
                        labelInValue
                        value={selectedValue}
                        placeholder={t('v4:select_users')}
                        notFoundContent={
                          fetching ? <Spin size="small" /> : null
                        }
                        filterOption={false}
                        onSearch={this.fetchUserEmails}
                        onChange={this.handleChange}
                        style={{ width: '100%' }}
                      >
                        {emailList.map(d => (
                          <Option key={d.value}>{d.text}</Option>
                        ))}
                      </Select>
                    </Modal>
                  )}
                </span>
              )}

              {allowDelete && !isPageBuilder && (
                <Checkbox
                  ref={this.selectRef}
                  ctrlOnCheckboxChange={e => this.checkedCard(e, thumb)}
                />
              )}
              {allowMultiple && isPageBuilder && (
                <Check
                  ref={this.selectRef}
                  checked={item.isSelected}
                  value={item.id}
                  onClick={e => ctrlCheckPdfCard(item, e)}
                />
              )}
            </div>
            <div
              className="media-card__title"
              title={item.title}
              onClick={() => handleViewDetails(item)}
            >
              {item.title}
            </div>
            <div className="media-card__date-container">
              <div className="media-card__ai-enable-container">
                <span className="media-card__date">
                  <Icon type="calendar" />{' '}
                  {moment(item.created_at).format('DD MMM YYYY')}
                </span>
                <div>
                  {this.props.user &&
                    this.props.user.info &&
                    this.props.user.info.organization &&
                    this.props.user.info.organization.enable_ai &&
                    item &&
                    item.is_ai_enabled && (
                      <Tooltip
                        title={
                          item.is_indexed
                            ? 'This file is indexed for AI.'
                            : 'File is not indexed for AI.'
                        }
                      >
                        <span className="media-card__date">
                          <Check
                            className={
                              item.is_indexed
                                ? 'blue-checkbox'
                                : 'disabled-checkbox'
                            }
                            defaultChecked={item.is_ai_enabled}
                            disabled
                          />
                          &nbsp; {t('v4:ai')}
                        </span>
                      </Tooltip>
                    )}
                </div>
              </div>
              <div>
                {allowUpdateStatus && tab !== 'Organization Library' && (
                  <span
                    className={`media-card-status ${this.handleMediaStatusName(
                      item.remark_public,
                      item.remark_org
                    )} ${this.handleMediaStatusClass(item)}`}
                    onClick={() => this.updateCardStatus(item, thumb)}
                  >
                    <Tooltip title={tooltipPublic}>
                      <img
                        src={
                          item.remark_public || item.remark_org
                            ? makePrivateIcon
                            : makePublicIcon
                        }
                        alt=""
                      />
                      <span className="label">
                        {this.handleReturnLabel(item)}
                      </span>
                    </Tooltip>
                    <div className="loading-media">
                      <div className="double-bounce1" />
                      <div className="double-bounce2" />
                    </div>
                  </span>
                )}

                {allowOrgAdminUpdateStatus &&
                  !allowUpdateStatus &&
                  tab === 'My Media' && (
                    <span
                      className={`media-card-status ${this.handleMediaStatusName(
                        item.remark_public,
                        item.remark_org
                      )} ${this.handleMediaStatusClass(item)}`}
                      onClick={() => this.updateCardStatus(item, thumb)}
                    >
                      <Tooltip title={tooltipPublic}>
                        <img
                          src={
                            item.remark_public
                              ? makePublicIcon
                              : item.remark_org
                              ? makePrivateIcon
                              : makePublicIcon
                          }
                          alt=""
                        />
                        <span className="label">
                          {this.handleReturnOrgAdminLabel(item)}
                        </span>
                      </Tooltip>
                      <div className="loading-media">
                        <div className="double-bounce1" />
                        <div className="double-bounce2" />
                      </div>
                    </span>
                  )}
                {tab === 'Organization Library' && (
                  <span
                    className={`media-card-status ${this.handleMediaStatusName(
                      item.remark_public
                    )} ${this.handleMediaStatusClass(item)}`}
                    onClick={() => this.updateCardStatus(item, thumb)}
                  >
                    <Tooltip title={tooltipPublic}>
                      <img src={makePrivateIcon} alt="" />
                      <span className="label">{t('buttons:make_private')}</span>
                    </Tooltip>
                    <div className="loading-media">
                      <div className="double-bounce1" />
                      <div className="double-bounce2" />
                    </div>
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslation('media')(Card)
