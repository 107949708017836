import React, { Component } from 'react'
import './Poll.scss'
import { withTranslation } from 'react-i18next'

class Poll extends Component {
  state = {
    isVoted: false,
    votedAnsIndex: null
  }

  onClick = (rootIndex, index) => {
    const { onClick } = this.props
    const { isVoted } = this.state

    if (onClick && !isVoted) {
      onClick(rootIndex, index)
      this.setState({
        isVoted: true,
        votedAnsIndex: index
      })
    }
  }

  render() {
    const { isVoted } = this.state
    const {
      available,
      data,
      pollValues,
      t,
      onAskQuestion,
      askedPolls
    } = this.props

    const votedQuestions = pollValues.map(a => {
      return JSON.parse(a.q)
    })

    if (!available) {
      return <div className="poll__disabled">{t('poll_disabled')}</div>
    }

    return (
      <>
        {data.map((item, idx) => (
          <div className={`poll__content`} key={`poll_${idx}`}>
            <div className="poll__question">
              {item.question}
              <span
                className={`poll__ask__question ${
                  askedPolls && askedPolls.includes(idx) ? 'poll__asked' : ''
                }`}
                role="button"
                onClick={() => {
                  onAskQuestion(idx)
                }}
              >
                {askedPolls && askedPolls.includes(idx) ? 'Asked' : 'Ask'}
              </span>
            </div>
            <div className="poll__answers poll-answers__voted">
              <div className="poll-answers">
                {pollValues.length > 0 &&
                  pollValues.map(p => {
                    if (JSON.parse(p.q) === idx) {
                      const totalVotes = p.votes.reduce((a, i) => {
                        return a + i.value
                      }, 0)
                      return (
                        <>
                          {item.answers.map((answer, index) => {
                            if (
                              p.votes.find(i => i.key === index.toString()) &&
                              totalVotes > 0
                            ) {
                              const percent =
                                (100 / totalVotes) *
                                p.votes.find(i => i.key === index.toString())
                                  .value
                              // console.log('answer here', answer, 'index', index, 'pollValues', pollValues, 'totalVotes', totalVotes, 'percent', percent);
                              return (
                                <div
                                  className="poll-answers__item"
                                  style={{ cursor: isVoted ? 'default' : '' }}
                                  key={index}
                                >
                                  {/* // onClick={() => this.onClick(idx, index)}> */}
                                  <div className="poll-answers__item-text">
                                    {answer} ({percent.toFixed(0)}%)
                                  </div>
                                  {/* {isVoted && <span className="poll-answers__item-voted"><CheckCircleOutlined /></span>} */}
                                  <div
                                    className="poll-answers__item-progress"
                                    style={{ width: percent + '%' }}
                                  />
                                </div>
                              )
                            }
                            return (
                              <div className="poll-answers__item" key={index}>
                                {/* onClick={() => this.onClick(idx, index)}> */}
                                <div className="poll-answers__item-text">
                                  {answer}
                                </div>
                                {/* {(!canVote && votedAnsIndex===index) && <span className="poll-answers__item-voted"><CheckCircleOutlined /></span>} */}
                              </div>
                            )
                          })}
                        </>
                      )
                    } else {
                      return <></>
                    }
                  })}
                {!votedQuestions.includes(idx) &&
                  item.answers.map((answer, index) => (
                    <div className="poll-answers__item" key={index}>
                      {/* onClick={() => this.onClick(idx, index)}> */}
                      <div className="poll-answers__item-text">{answer}</div>
                      {/* {(!canVote && votedAnsIndex===index) && <span className="poll-answers__item-voted"><CheckCircleOutlined /></span>} */}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        ))}
      </>
    )
  }
}

export default withTranslation('events')(Poll)
