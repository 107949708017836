import React, { Component } from 'react'
import { Table, AutoComplete, Button, Tooltip, Modal } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import ReactEcharts from 'echarts-for-react'
import { Page, Row, EmailButton } from './styled'
import './styles.scss'
import { getUsageReport } from '../../../../../../services/api/organizations'
import { withTranslation } from 'react-i18next'
import { jsPDF } from 'jspdf'
import html2canvas from 'html2canvas'

import Sendmail from './SendMail'

class PDFReport extends Component {
  constructor(props) {
    super(props)
    this.state = {
      reportData: [],
      filteredData: [],
      organisationList: [],
      selectedOrg: '',
      isTableDataLoading: false,
      groups: [],
      showDateFilter: false,
      selectedOrgId: null,
      dateTo: null,
      dateFrom: null,
      groupDateOption: 'allTime',
      groupVsUsers: [],
      orgUserData: [],
      groupsLoading: false,
      filteredOrgs: [],
      openEmailModal: false
    }
  }

  componentDidMount() {
    this.getReport()
  }

  getReport = async () => {
    this.setState({ isTableDataLoading: true })
    await getUsageReport({
      limit: null,
      date: this.state.groupDateOption,
      group: '',
      dateFrom: this.state.dateFrom,
      dateTo: this.state.dateTo,
      activeOrg: null
    }).then(res => {
      const arr = res.data.rows
      const data = this.getTabularData(arr)
      this.setState(
        {
          reportData: data,
          organisationList: data.map(item => ({
            value: item.id,
            text: item.name
          })),
          isTableDataLoading: false,
          res: arr
        },
        () => {
          this.setGraphOptions()
          if (this.state.selectedOrg) {
            this.handleOrganizationChange(this.state.selectedOrgId)
          }
        }
      )
    })
  }

  setGraphOptions = () => {
    const options = this.state.filteredData.map(item => {
      return {
        product: item['name'],
        'Live Hours Usage': item['total_usage'],
        'Transcribe Usage': item['transcribe_usage']
      }
    })
    this.setState({
      graphOptions: options
    })
  }

  setActiveUserOptions = () => {
    const { filteredData } = this.state
    const groupOptions = this.state.groupVsUsers.map(item =>
      this.getActiveUserData(item)
    )
    const orgOptions = this.getActiveUserData({
      title: filteredData[0].name,
      users: filteredData[0].users,
      activeUsers: filteredData[0].activeUsers
    })
    this.setState({
      groupGraphData: groupOptions,
      orgUserData: [
        { ...orgOptions, 'Non Signup Users': filteredData[0].nonSignup }
      ]
    })
  }

  getActiveUserData = item => {
    return {
      product: item.title,
      'Total Users': item.users,
      'Active Users': item.activeUsers
    }
  }

  getTabularData = arr => {
    return arr.map(item => ({
      transcribe: this.getHours(item.transcribe_limit),
      total_usage: this.getHours(item.total_usage),
      name: item.title,
      transcribe_usage: this.getHours(item.total_transcribe_usage),
      broadcast_limit: this.getHours(item.broadcast_limit),
      users: item.users,
      id: item.id,
      nonSignup: item.invited_users,
      activeUsers: '...'
    }))
  }

  getHours = val => {
    if (val === null) return 0
    return (val / 60).toFixed(0)
  }

  handleOrganizationChange = ID => {
    const id = Number(ID)
    const data = this.state.reportData.filter(item => item.id === id)
    this.setState(
      {
        filteredData: data,
        selectedOrg: data[0].name,
        selectedOrgId: id,
        groupsLoading: true,
        isTableDataLoading: true
      },
      () => {
        this.setGraphOptions()
        this.handleGroups()
      }
    )
  }

  handleGroups = () => {
    const { res, selectedOrgId, reportData } = this.state
    getUsageReport({
      limit: null,
      date: this.state.groupDateOption || null,
      group: '',
      dateFrom: null,
      dateTo: null,
      activeOrg: selectedOrgId
    }).then(response => {
      const arr = response.data.rows[0]

      const groups = res
        .filter(item => item.id === selectedOrgId)
        .map(item => item.groups)[0]

      const data = reportData.filter(item => item.id === selectedOrgId)

      const groupOptions = groups.map(group => {
        const activeGroup = arr.groups.filter(item => item.id === group.id)[0]
        return {
          title: group.title,
          users: group.users,
          activeUsers: activeGroup.users
        }
      })

      this.setState(
        {
          filteredData: [{ ...data[0], activeUsers: arr.users }],
          groups: groups,
          groupVsUsers: groupOptions,
          groupsLoading: false,
          isTableDataLoading: false
        },
        () => this.setActiveUserOptions()
      )
    })
  }

  handleDurationChange = val => {
    if (val === 'other') {
      this.setState({
        showDateFilter: true
      })
    } else {
      this.setState(
        {
          showDateFilter: false,
          groupDateOption: val,
          dateTo: null,
          dateFrom: null
        },
        () => {
          this.getReport()
        }
      )
    }
  }

  downloadPdf = () => {
    const input = document.getElementById('pdf-document')
    html2canvas(input, {
      scrollY: -window.scrollY
    }).then(canvas => {
      var img = canvas.toDataURL('image/png', canvas.width, canvas.height)
      var ratio = canvas.height / canvas.width
      var doc = new jsPDF('p', 'pt', 'a4')
      var width = doc.internal.pageSize.width
      var height = width * ratio
      doc.addImage(img, 'JPEG', 5, 2, width, height)
      doc.save(`${this.state.selectedOrg} - Usage Report.pdf`)
    })
  }

  getTableHeader = val => {
    return (
      <div style={{ display: 'flex' }}>
        <h3 style={{ fontSize: 12 }}>{val}</h3>
        <span style={{ fontSize: 10, transform: 'translateY(2px)' }}>
          (hours)
        </span>
      </div>
    )
  }

  handleDate = (val, type) => {
    if (type === 'from') {
      this.setState({ dateFrom: val })
    } else {
      this.setState({ dateTo: val })
    }
  }

  handleSearch = () => {
    this.setState(
      {
        groupDateOption: null
      },
      () => this.getReport()
    )
  }

  onSearch = text => {
    const { organisationList } = this.state
    this.setState({
      filteredOrgs: organisationList.filter(obj =>
        obj.text.toLowerCase().includes(text.toLowerCase())
      )
    })
  }

  handleUsersHeader = val => {
    var title = 'Total Users'
    var color = 'blue'

    if (val === 'inactive') {
      title = 'Non Signup Users'
      color = 'red'
    }

    if (val === 'active') {
      title = 'Active Users'
      color = 'green'
    }

    return (
      <Tooltip title={title}>
        <h3
          style={{
            fontSize: 12,
            color: color
          }}
        >
          Users
        </h3>
      </Tooltip>
    )
  }

  toggleEmailModal = () =>
    this.setState(state => ({ openEmailModal: !state.openEmailModal }))

  render() {
    const {
      filteredData,
      selectedOrg,
      selectedOrgId,
      graphOptions,
      showDateFilter,
      res,
      groupVsUsers,
      groupsLoading,
      groupGraphData,
      orgUserData,
      filteredOrgs,
      isTableDataLoading,
      openEmailModal,
      organisationList
    } = this.state
    const { t } = this.props
    const groupTableColumns = [
      {
        title: 'Group Name',
        dataIndex: 'title',
        key: 'title'
      },
      {
        title: 'Number of Users',
        dataIndex: 'users',
        key: 'users'
      },
      {
        title: 'Active Users',
        dataIndex: 'activeUsers',
        key: 'activeUsers'
      }
    ]
    const columns = [
      {
        title: 'Organization Name',
        dataIndex: 'name',
        key: 'name'
      },
      {
        title: this.handleUsersHeader('inactive'),
        dataIndex: 'nonSignup',
        key: 'nonSignup'
      },
      {
        title: this.handleUsersHeader('total'),
        dataIndex: 'users',
        key: 'users'
      },
      {
        title: this.handleUsersHeader('active'),
        dataIndex: 'activeUsers',
        key: 'activeUsers'
      },
      {
        title: this.getTableHeader('Total Broadcast'),
        dataIndex: 'total_usage',
        key: 'total_usage'
      },
      {
        title: this.getTableHeader('Broadcast Limit'),
        dataIndex: 'broadcast_limit',
        key: 'broadcast_limit'
      },
      {
        title: this.getTableHeader('Transcribe Usage'),
        dataIndex: 'transcribe_usage',
        key: 'transcribe_usage'
      },
      {
        title: this.getTableHeader('Transcribe Limit'),
        dataIndex: 'transcribe',
        key: 'transcribe'
      }
    ]

    const series = [
      {
        type: 'bar',
        barMaxWidth: 30,
        itemStyle: {
          color: '#5470C6'
        }
      },
      {
        type: 'bar',
        barMaxWidth: 30,
        itemStyle: {
          color: '#9DC988'
        }
      }
    ]
    return (
      <React.Fragment>
        <Page>
          <Page.Title>
            {t('v3:organisation')} {'> '}
            <Page.Title.SubText> {' Usage Report '}</Page.Title.SubText>{' '}
          </Page.Title>
          <Page.SelectContainer>
            <Row>
              <AutoComplete
                dataSource={filteredOrgs}
                style={{ width: 200 }}
                onSelect={this.handleOrganizationChange}
                onSearch={this.onSearch}
                placeholder="Search Organisation"
              />
              <Page.Select
                disabled={!selectedOrg}
                placeholder="Duration"
                onChange={this.handleDurationChange}
              >
                <Page.Select.Option value="today">
                  {t('reports:today')}
                </Page.Select.Option>
                <Page.Select.Option value="week">
                  {t('reports:seven_days')}
                </Page.Select.Option>
                <Page.Select.Option value="month">
                  {t('reports:last_month')}
                </Page.Select.Option>
                <Page.Select.Option value="year">
                  {t('reports:one_year')}
                </Page.Select.Option>
                <Page.Select.Option value="allTime">
                  {t('reports:all_time')}
                </Page.Select.Option>
                <Page.Select.Option value="other">{'Other'}</Page.Select.Option>
              </Page.Select>
              {showDateFilter && (
                <>
                  <Page.DatePicker
                    placeholder="From"
                    format="YYYY-MM-DD"
                    onChange={(date, dateString) =>
                      this.handleDate(dateString, 'from')
                    }
                  />
                  <Page.DatePicker
                    placeholder="To"
                    format="YYYY-MM-DD"
                    onChange={(date, dateString) => this.handleDate(dateString)}
                  />
                  <Page.SearchContainer onClick={this.handleSearch}>
                    <SearchOutlined />
                  </Page.SearchContainer>
                </>
              )}
            </Row>
            <EmailButton onClick={this.toggleEmailModal}>
              SEND EMAIL
            </EmailButton>
          </Page.SelectContainer>
          <div id="pdf-document" style={{ marginBottom: 30 }}>
            {!selectedOrg && (
              <div className="nosearch-container">
                <img src="/images/search-error-icon.png" alt="" />
                <div>{t('v4:select_org_report')}</div>
              </div>
            )}
            {selectedOrg && (
              <Table
                loading={isTableDataLoading}
                dataSource={filteredData}
                columns={columns}
                pagination={false}
              />
            )}
            {selectedOrg && !isTableDataLoading && groupVsUsers.length > 0 && (
              <div className="group_table">
                <Table
                  dataSource={groupVsUsers}
                  columns={groupTableColumns}
                  pagination={groupVsUsers.length > 10 ? true : false}
                  loading={groupsLoading}
                />
              </div>
            )}
            <div className="org_charts">
              {selectedOrg && !isTableDataLoading && graphOptions && (
                <ReactEcharts
                  option={{
                    legend: {
                      orient: 'horizontal',
                      x: 'center',
                      bottom: -5
                    },
                    dataset: {
                      dimensions: [
                        'product',
                        'Live Hours Usage',
                        'Transcribe Usage'
                      ],
                      source: graphOptions
                    },
                    tooltip: {},
                    xAxis: {
                      type: 'category',
                      name: 'Org',
                      axisLabel: {
                        interval: 0,
                        rotate: graphOptions.length > 5 ? 30 : 0
                      }
                    },
                    yAxis: { type: 'value', name: 'Hours' },
                    series: [
                      {
                        type: 'bar',
                        barMaxWidth: 30,
                        itemStyle: {
                          color: '#EE6666'
                        }
                      },
                      {
                        type: 'bar',
                        barMaxWidth: 30,
                        itemStyle: {
                          color: '#FAC858'
                        }
                      }
                    ],
                    label: {
                      show: true,
                      position: 'top',
                      color: 'black',
                      fontSize: 12
                    }
                  }}
                />
              )}
              {selectedOrg && !isTableDataLoading && orgUserData && (
                <ReactEcharts
                  option={{
                    legend: {
                      orient: 'horizontal',
                      x: 'center',
                      bottom: -5
                    },
                    dataset: {
                      dimensions: [
                        'product',
                        'Total Users',
                        'Active Users',
                        'Non Signup Users'
                      ],
                      source: orgUserData
                    },
                    tooltip: {},
                    xAxis: {
                      type: 'category',
                      name: 'Org',
                      axisLabel: {
                        interval: 0,
                        rotate: orgUserData.length > 5 ? 30 : 0
                      }
                    },
                    yAxis: { type: 'value', name: 'Users' },
                    series: [
                      {
                        type: 'bar',
                        barMaxWidth: 30,
                        itemStyle: {
                          color: '#5470C6'
                        }
                      },
                      {
                        type: 'bar',
                        barMaxWidth: 30,
                        itemStyle: {
                          color: '#9DC988'
                        }
                      },
                      {
                        type: 'bar',
                        barMaxWidth: 30,
                        itemStyle: {
                          color: '#ff6060'
                        }
                      }
                    ],
                    label: {
                      show: true,
                      position: 'top',
                      color: 'black',
                      fontSize: 12
                    }
                  }}
                />
              )}
            </div>
            {!isTableDataLoading &&
              groupGraphData &&
              groupGraphData.length > 0 && (
                <ReactEcharts
                  option={{
                    legend: {
                      orient: 'horizontal',
                      x: 'center',
                      bottom: -5
                    },
                    tooltip: {},
                    dataset: {
                      dimensions: ['product', 'Total Users', 'Active Users'],
                      source: groupGraphData
                    },
                    xAxis: {
                      type: 'category',
                      name: 'Groups',
                      axisLabel: {
                        interval: 0,
                        rotate: 0
                      }
                    },
                    yAxis: { type: 'value', name: 'Number of Users' },
                    series: series,
                    label: {
                      show: true,
                      position: 'top',
                      color: 'black',
                      fontSize: 12
                    }
                  }}
                />
              )}
          </div>
          <div className="p-footer">
            <div className="p-footer__inner">
              <Button
                onClick={this.downloadPdf}
                icon="download"
                type="primary"
                shape="round"
              >
                {t('buttons:download')}
              </Button>
            </div>
          </div>
          {openEmailModal && (
            <Modal
              visible={openEmailModal}
              onCancel={this.toggleEmailModal}
              footer={false}
            >
              <Sendmail
                t={t}
                response={res}
                orgId={selectedOrgId}
                orgName={selectedOrg}
                organizations={organisationList}
                onSend={this.toggleEmailModal}
              />
            </Modal>
          )}
        </Page>
      </React.Fragment>
    )
  }
}

export default withTranslation('management')(PDFReport)
