import React from 'react'

import { styleTitle } from './style'
import {
  textIconRectangle,
  textIconInsideRectangle
} from '../../../../PageBuilder/Components/Text/images'
import {
  VideoPlayIcon,
  VideoRectangleIcon
} from '../../../../PageBuilder/Components/Video/images'
import {
  micIcon,
  modalMixedIcon,
  modalTitleIcon
} from '../../../../PageBuilder/Components/Audio/images'
import { imageIcon } from '../../../../PageBuilder/Components/Image/images'

const AudioIcon = ({ t }) => (
  <div className="toolbar__item-icon" style={styleTitle}>
    <img src={micIcon} alt="" /> {t('audio:modal_title')}
  </div>
)

const ImageIcon = ({ t }) => (
  <div className="toolbar__item-icon" style={styleTitle}>
    <img src={imageIcon} alt="" /> {t('image:modal_title')}
  </div>
)

const VideoIcon = ({ t }) => (
  <div className="toolbar__item-icon" style={styleTitle}>
    <div className="wrap-video-icons">
      <img src={VideoRectangleIcon} alt="" /> {t('video:modal_title')}
      <img src={VideoPlayIcon} alt="" className="play-icon-inside-rectangle" />
    </div>
  </div>
)

const TextIcon = ({ t }) => (
  <div className="toolbar__item-icon" style={styleTitle}>
    <div className="wrap-video-icons">
      <img src={textIconRectangle} alt="" />{' '}
      <span style={{ color: '#67686C' }}> {t('text:modal_title')} </span>
      <img
        src={textIconInsideRectangle}
        alt=""
        className="text-icon-inside-rectangle"
      />
    </div>
  </div>
)

const AudioModalTitleIcon = ({ t, isShowCreateAudioModal }) => {
  return (
    <div className="toolbar__item-icon" style={styleTitle}>
      <div className="audio-img-warp">
        <img src={modalTitleIcon} alt="" /> <img src={modalMixedIcon} alt="" />
      </div>
      {isShowCreateAudioModal
        ? t('audio:create_audio_modal_title')
        : t('audio:edit_audio_modal_title')}
    </div>
  )
}

export { AudioIcon, ImageIcon, VideoIcon, TextIcon, AudioModalTitleIcon }
