import React, { Fragment } from 'react'
import _ from 'lodash'
import Dropzone from 'react-dropzone'
import { withTranslation } from 'react-i18next'
import className from 'classnames'
import { isSafari } from 'react-device-detect'
import { api } from '../../../../../../../../../../services'
import { VideoRectangleIconBig } from '../../../images'
import { Button, Col, Row } from 'antd'
import { general } from '../../../../../../../../../../constants'
import { bytesToSize } from '../../../../../../../../../../utils'
import { AddDetailVideo } from '../../Create'
import './DropzoneVideo.scss'

const getMediaDetail = (id, props) => {
  api.library.getMediaInfoById(id).then(data => {
    props.getSelectedFile([data.data])
  })
}

const DropzoneVideo = props => {
  const {
    t,
    uploadProgress,
    fileNotCompatible,
    fileSizeExceed,
    getSelectedFile,
    onDrop,
    uploadListClassName,
    checkedCompleteUpload,
    resetFileInCompatibleMessage,
    src
  } = props

  const item = uploadProgress[0]

  if (item && item.percent === 100) {
    getMediaDetail(item.id, props)
  }

  const media_id = src.substring(src.lastIndexOf('/') + 1)
  const isMediaIdInteger =
    _.isFinite(_.parseInt(media_id)) && !_.isNaN(_.parseInt(media_id))
  return (
    <Fragment>
      <div className="upload-modal insert-audio-detail">
        <div className="upload-modal__inner">
          {uploadProgress.length === 0 && (
            <div className="upload-modal__dropzone">
              <Dropzone
                accept={['video/mp4,video/x-m4v,video/*']}
                multiple={false}
                onDrop={onDrop}
              >
                {({ getRootProps, getInputProps, isDragActive }) => (
                  <div
                    className={className('upload-dropzone', {
                      'upload-dropzone_active': isDragActive
                    })}
                  >
                    <div className="upload-dropzone__text-wrapper">
                      <img src={VideoRectangleIconBig} alt="" />
                      <p>{t('media:drop_files')}</p>
                      <p>{t('media:or')}</p>
                      <Button>{t('media:import_files')}</Button>
                    </div>
                  </div>
                )}
              </Dropzone>
            </div>
          )}

          <div
            className={`upload-modal__list-wrapper ${uploadListClassName(
              uploadProgress
            )}`}
          >
            <div className="upload-modal__list-container">
              {fileNotCompatible && (
                <div className="upload-modal__error-item-wrapper">
                  <div className="upload-modal__error-message-container">
                    <div className="upload-modal__error-bar" />
                    <div className="upload-modal__error-message">
                      {t('errors:upload_error')}
                    </div>
                  </div>

                  <Button
                    className="upload-modal__cancel-error"
                    type="primary"
                    onClick={() => resetFileInCompatibleMessage()}
                  >
                    {t('buttons:ok')}
                  </Button>
                </div>
              )}

              {fileSizeExceed && (
                <div className="upload-modal__error-item-wrapper">
                  <div className="upload-modal__error-message-container">
                    <div className="upload-modal__error-bar" />
                    <div className="upload-modal__error-message">
                      {t('errors:file_size_exceed')}
                    </div>
                  </div>

                  <Button
                    className="upload-modal__cancel-error"
                    type="primary"
                    onClick={() => resetFileInCompatibleMessage()}
                  >
                    {t('buttons:ok')}
                  </Button>
                </div>
              )}

              {uploadProgress.length > 0 && (
                <div className="upload-modal__list">
                  <div key={item.uid} className="upload-modal__item">
                    <Row>
                      <Col span={12}>
                        {_.isEmpty(src) && (
                          <img
                            src={VideoRectangleIconBig}
                            className="thumbnail-video-fit-into-dropzone"
                            alt=""
                          />
                        )}{' '}
                        {!_.isEmpty(src) && isMediaIdInteger && !isSafari && (
                          <iframe
                            title="iframe title"
                            style={{
                              width: '306px',
                              height: '251px',
                              objectFit: 'cover',
                              marginLeft: '118px',
                              marginBottom: '5px'
                            }}
                            src={'https://player.vimeo.com/video/' + media_id}
                            width="357"
                            height="283"
                            frameBorder="0"
                            allowFullScreen
                          />
                        )}{' '}
                        {!_.isEmpty(src) && isMediaIdInteger && isSafari && (
                          <div>
                            <iframe
                              title="iframe title"
                              src={
                                'https://player.vimeo.com/video/' +
                                media_id +
                                '?&autoplay=1&loop=1&title=0&byline=0&portrait=0&muted=1&#t=235s'
                              }
                              width="357"
                              height="283"
                              style={{
                                width: '306px',
                                height: '251px',
                                objectFit: 'cover',
                                marginLeft: '118px',
                                marginBottom: '5px'
                              }}
                              frameBorder="0"
                              webkitallowfullscreen
                              mozallowfullscreen
                              allowFullScreen
                            ></iframe>
                          </div>
                        )}{' '}
                      </Col>
                      <Col span={12}>
                        <div className="gutter-box">
                          <div className="upload-modal-item__percent-border">
                            <div
                              className={`upload-modal-item__percent ${
                                item.status === general.CANCELED
                                  ? 'upload-modal-item__percent__cancel'
                                  : ''
                              }`}
                              style={{ width: item.percent + '%' }}
                            />
                          </div>
                          {item.status !== general.CANCELED && (
                            <div className="upload-modal-item__weight">
                              {checkedCompleteUpload(item) && (
                                <div style={{ marginTop: '7px' }}>
                                  {bytesToSize(item.loaded)} {t('of')}{' '}
                                  {bytesToSize(item.total)}
                                </div>
                              )}
                              {!checkedCompleteUpload(item) && (
                                <span className="upload-modal-item__weight__complete">
                                  {t('general:completed')}
                                </span>
                              )}
                            </div>
                          )}
                          <AddDetailVideo
                            getSelectedFile={getSelectedFile}
                            mediaId={item.id}
                            fileName={item.name}
                            src={src}
                            uploadComplete={checkedCompleteUpload(item)}
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default withTranslation('video')(DropzoneVideo)
