import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
  Button,
  Icon,
  List,
  Modal,
  Input,
  Popconfirm,
  Statistic,
  Tooltip,
  Divider,
  message,
  Spin,
  Alert
} from 'antd'
import './AiTableOfContent.scss'

import {
  SortableContainer,
  SortableElement,
  arrayMove
} from 'react-sortable-hoc'

import { api } from '../../../services'
import { config } from '../../../constants'
import { useSelector } from 'react-redux'

import DeleteIcon from '../../../assets/bin.svg'
import EditIcon from '../../../assets/ai_edit.svg'
import AttentionIcon from '../../../assets/AttentionIcon.svg'
import WarningIcon from '../../../assets/WarningIcon.svg'
import CheckIcon from '../../../assets/check_icon.svg'
import CancelIcon from '../../../assets/delete.svg'

import uuidv4 from 'uuid/v4'

import { Document, Packer, Paragraph, TextRun, HeadingLevel } from 'docx'
import { saveAs } from 'file-saver'

import history from '../../../history'
import { useTranslation } from 'react-i18next'

const TopicItem = SortableElement(({ children }) => {
  return <div className="sortableHelper">{children}</div>
})

const TopicsContainer = SortableContainer(({ children }) => {
  return (
    <div className="ai-course__table-of-content__lesson-topics">{children}</div>
  )
})

const { TextArea } = Input

const TableOfContents = ({
  courseTitle,
  tableOfContent,
  onTableOfContentChange,
  onContentGenerationStarted,
  isFromKnowledgeCenter,
  courseId: aiCourseId,
  setIsContentGenerationCompleted
}) => {
  const [content, setContent] = useState({})
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [newLessonModalVisible, setNewLessonModalVisible] = useState(false)
  const [newTopicModalVisible, setNewTopicModalVisible] = useState(false)
  const [newLessonTitle, setNewLessonTitle] = useState('')
  const [currentSection, setCurrentSection] = useState(null)
  const [currentSubsection, setCurrentSubsection] = useState(null)
  const [newTitle, setNewTitle] = useState('')
  const [isGeneratingContent, setIsGeneratingContent] = useState(false)
  const [isConfirmationModalVisible, setIsConfirmationModalVisible] = useState(
    false
  )
  const [editTopicIndex, setEditTopicIndex] = useState(-1)
  const [editTopicTopIndex, setEditTopicTopIndex] = useState(-1)
  const [editTopicStatus, setEditTopicStatus] = useState(false)

  const [generatedContentModal, setGeneratedContentModal] = useState({
    isVisible: false,
    content: '',
    title: ''
  })

  const [isSavingContent, setIsSavingContent] = useState(false)

  const socketRef = useRef()
  const user = useSelector(state => state.user)
  const course = useSelector(state => state.courseBuilder)
  const [isGeneratingCourse, setIsGeneratingCourse] = useState(false)
  const [
    isAddingContentFromInternet,
    setIsAddingContentFromInternet
  ] = useState(null)
  const [showDocumentModal, setShowDocumentModal] = useState(false)

  const courseId = isFromKnowledgeCenter ? uuidv4() : aiCourseId

  useEffect(() => {
    return () => {
      socketRef.current && socketRef.current.close()
    }
  }, [])

  useEffect(() => {
    if (tableOfContent) {
      setContent(JSON.parse(tableOfContent.content))
    }
  }, [tableOfContent])

  const updateContent = newContent => {
    setContent(newContent)
    onTableOfContentChange({
      tableOfContent: JSON.stringify(newContent),
      id: tableOfContent.id
    })
  }

  const showNewLessonModal = () => {
    setNewLessonModalVisible(true)
  }

  useEffect(() => {
    if (isGeneratingContent || isFromKnowledgeCenter) return

    // if any subtopic has generatedContent property
    const hasGeneratedContent = Object.keys(content).some(section => {
      return Object.keys(content[section]).some(subtopic => {
        return (
          content[section][subtopic] &&
          content[section][subtopic].generatedContent
        )
      })
    })

    if (hasGeneratedContent) {
      saveContentToDatabase()
    }
  }, [content])

  const handleDelete = (section, subsection) => {
    const newContent = { ...content }
    if (!subsection) {
      delete newContent[section]
    } else {
      delete newContent[section][subsection]
    }
    updateContent(newContent)
  }

  const handleEdit = (section, subsection = null, index, tindex, wstatus) => {
    setCurrentSection(section)
    setCurrentSubsection(subsection)
    setNewTitle(subsection ? subsection : section)
    setIsModalVisible(true)
    setEditTopicIndex(index)
    setEditTopicTopIndex(tindex)
    setEditTopicStatus(wstatus)
  }

  const handleSaveEdit = () => {
    if (newTitle && currentSection !== null) {
      const newContent = { ...content }

      if (currentSubsection === null) {
        // Editing lesson title without changing the order
        const lessonKeys = Object.keys(newContent)
        const lessonIndex = lessonKeys.indexOf(currentSection)
        const lessonTopics = newContent[currentSection]

        if (lessonIndex !== -1) {
          lessonKeys[lessonIndex] = newTitle
        }

        const updatedContent = {}
        lessonKeys.forEach(key => {
          updatedContent[key] = newContent[key]
        })

        updatedContent[newTitle] = lessonTopics

        updateContent(updatedContent)
      } else {
        // Editing topic title
        const topics = { ...newContent[currentSection] }
        const updatedTopics = {}

        // Iterate through the topics, update the title if it matches currentSubsection
        Object.keys(topics).forEach(topic => {
          if (topic === currentSubsection) {
            updatedTopics[newTitle] = topics[topic]
          } else {
            updatedTopics[topic] = topics[topic]
          }
        })

        newContent[currentSection] = updatedTopics
        updateContent(newContent)
      }
    }

    setIsModalVisible(false)
    setEditTopicIndex(-1)
    setEditTopicTopIndex(-1)
    setEditTopicStatus(false)
  }

  const handelCancleEdit = () => {
    setIsModalVisible(false)
    setEditTopicIndex(-1)
    setEditTopicTopIndex(-1)
    setEditTopicStatus(false)
  }

  const handleAddTopic = () => {
    if (newTitle && currentSection !== null) {
      const newContent = { ...content }

      if (!newContent[currentSection]) {
        newContent[currentSection] = {}
      }

      const currentSectionObject = newContent[currentSection]

      currentSectionObject[`${newTitle}`] = {}
      setNewTitle('')
      setNewTopicModalVisible(false)
      updateContent(newContent)
    }
  }

  const handleAddLesson = () => {
    if (newLessonTitle) {
      const newContent = { ...content }

      newContent[`${newLessonTitle}`] = {}
      setNewLessonTitle('')
      setNewLessonModalVisible(false)
      updateContent(newContent)
    }
  }

  const renderActionItems = (
    sectionTitle,
    item = null,
    index = 0,
    tindex = 0,
    wstatus
  ) => {
    const itemForGeneratedContent = content[sectionTitle][item]

    const itemGeneratedContent =
      itemForGeneratedContent && itemForGeneratedContent.generatedContent

    const isGenerationError =
      itemForGeneratedContent && itemForGeneratedContent.status === 'error'

    return (
      <div className="ai-course__table-of-content__pop-over__container">
        {itemGeneratedContent && !isGenerationError && (
          <Tooltip trigger={['hover']} title={t('v4:view_generate_content')}>
            <div
              className="ai-course__table-of-content__pop-over__generate"
              onClick={() =>
                setGeneratedContentModal({
                  isVisible: true,
                  content: itemGeneratedContent,
                  lesson: sectionTitle,
                  title: item
                })
              }
            >
              <Icon type="eye" />
            </div>
          </Tooltip>
        )}

        {isGenerationError && (
          <Tooltip trigger={['hover']} title={t('v4:generate_error')}>
            <div className="ai-course__table-of-content__pop-over__warning">
              <Icon type="exclamation-circle" />
            </div>
          </Tooltip>
        )}

        {console.log(
          'editTopicTopIndex == tindex===>',
          editTopicTopIndex,
          index,
          tindex,
          editTopicStatus
        )}

        {!isGeneratingContent &&
        isModalVisible &&
        editTopicStatus &&
        editTopicTopIndex == tindex &&
        index == -1 ? (
          <>
            <div
              className="ai-course__table-of-content__pop-over__edit"
              onClick={() => handleSaveEdit()}
            >
              <Tooltip
                trigger={['hover']}
                title={`Edit ${item ? 'Topic' : 'Lesson'}`}
              >
                {/* <Icon type="edit" /> */}
                <img src={CheckIcon} alt="delete" className="delete-icon" />
              </Tooltip>
            </div>
            <div
              className="ai-course__table-of-content__pop-over__delete"
              onClick={() => handelCancleEdit()}
            >
              <Tooltip
                trigger={['hover']}
                title={`Delete ${item ? 'Topic' : 'Lesson'}`}
              >
                <img src={CancelIcon} alt="delete" className="delete-icon" />
              </Tooltip>
            </div>
          </>
        ) : isModalVisible &&
          editTopicTopIndex == tindex &&
          editTopicIndex == index ? (
          <>
            <div
              className="ai-course__table-of-content__pop-over__edit"
              onClick={() => handleSaveEdit()}
            >
              <Tooltip
                trigger={['hover']}
                title={`Edit ${item ? 'Topic' : 'Lesson'}`}
              >
                {/* <Icon type="edit" /> */}
                <img src={CheckIcon} alt="delete" className="delete-icon" />
              </Tooltip>
            </div>
            <div
              className="ai-course__table-of-content__pop-over__delete"
              onClick={() => handelCancleEdit()}
            >
              <Tooltip
                trigger={['hover']}
                title={`Delete ${item ? 'Topic' : 'Lesson'}`}
              >
                <img src={CancelIcon} alt="delete" className="delete-icon" />
              </Tooltip>
            </div>
          </>
        ) : (
          <>
            <div
              className="ai-course__table-of-content__pop-over__edit"
              onClick={() =>
                handleEdit(sectionTitle, item, index, tindex, wstatus)
              }
            >
              <Tooltip
                trigger={['hover']}
                title={`Edit ${item ? 'Topic' : 'Lesson'}`}
              >
                {/* <Icon type="edit" /> */}
                <img src={EditIcon} alt="delete" className="delete-icon" />
              </Tooltip>
            </div>
            <div className="ai-course__table-of-content__pop-over__delete">
              <Popconfirm
                icon={null}
                title={`Are you sure you want to delete this ${
                  item ? 'topic' : 'lesson'
                }? ${
                  item
                    ? ''
                    : 'All topics in this lesson will be deleted. This cannot be undone.'
                }`}
                onConfirm={() => handleDelete(sectionTitle, item)}
              >
                <Tooltip
                  trigger={['hover']}
                  title={`Delete ${item ? 'Topic' : 'Lesson'}`}
                >
                  <img src={DeleteIcon} alt="delete" className="delete-icon" />
                </Tooltip>
              </Popconfirm>
            </div>
          </>
        )}
      </div>
    )
  }

  const onSortTopicsEnd = ({ sectionTitle, oldIndex, newIndex }) => {
    if (oldIndex === newIndex) return
    const newContent = { ...content }
    const section = newContent[sectionTitle]
    const newTopics = mapObjectByOrder(section, oldIndex, newIndex)
    newContent[sectionTitle] = newTopics
    updateContent(newContent)
  }

  const mapObjectByOrder = (obj, oldIndex, newIndex) => {
    const keys = Object.keys(obj)
    const newKeys = arrayMove(keys, oldIndex, newIndex)
    const newObj = {}
    newKeys.forEach(key => {
      newObj[key] = obj[key]
    })
    return newObj
  }

  const convertToArrayOfObjects = jsonObject => {
    const result = []

    for (const topic in jsonObject) {
      if (jsonObject.hasOwnProperty(topic)) {
        const sub_topics = Object.keys(jsonObject[topic])
        const courseObject = {
          topic,
          sub_topics: sub_topics.length === 0 ? [topic] : sub_topics
        }
        result.push(courseObject)
      }
    }

    return result
  }

  const initializeWebsocket = () => {
    const { id, username } = user.info
    socketRef.current = new WebSocket(
      config.midJourneySocket +
        `?channel_type=COURSE&channel_id=${courseId}&user_id=${id}&user_name=${username}`
    )

    socketRef.current.onopen = () => {
      console.log('Websocket open')
      sendContentGenerationRequest()
    }

    socketRef.current.onclose = () => {
      console.log('Websocket closed')
    }

    socketRef.current.onmessage = event => {
      const data = JSON.parse(event.data)

      if (!data.content) return

      const receivedContent = [...data.content]

      if (
        (receivedContent && receivedContent.length === 0) ||
        (data && data.generate_status === 'error')
      ) {
        setIsGeneratingCourse(false)
        socketRef.current.close()
        handleGenerationCompletion(content) // Ensure this function name is correctly spelled
        return
      }

      const newContent = { ...content }

      Object.entries(content).forEach(([key, value]) => {
        receivedContent.forEach(item => {
          if (item.topic === key) {
            // Ensure value is an object and has sub_topic keys
            if (typeof value === 'object' && !Array.isArray(value)) {
              Object.keys(value).forEach(sub_topic => {
                if (sub_topic === item.sub_topic) {
                  if (!newContent[key][sub_topic]) {
                    newContent[key][sub_topic] = {}
                  }
                  // Update or initialize 'generatedContent' and 'status'
                  newContent[key][sub_topic]['generatedContent'] =
                    item.content_generated
                  newContent[key][sub_topic]['status'] = item.generated_status
                }
              })
            }
          }
        })
      })

      setContent(newContent)
      saveContentToDatabase(newContent)

      if (data.generate_status === 'completed') {
        socketRef.current.close()
        handleGenerationCompletion(newContent) // Ensure this function name is correctly spelled
      }
    }
  }

  const handleGenerationCompletion = newContent => {
    const contentToProcess = { ...newContent }

    Object.keys(contentToProcess).forEach(topic => {
      Object.keys(contentToProcess[topic]).forEach(sub_topic => {
        const contentObj =
          contentToProcess &&
          contentToProcess[topic] &&
          contentToProcess[topic][sub_topic]
        if (contentObj && contentObj.generatedContent) {
          contentObj.status = 'success'
        } else {
          contentObj.generatedContent = ''
          contentObj.status = 'error'
        }
      })
    })

    setIsGeneratingContent(false)

    // Return the modified content
    setContent(contentToProcess)
    saveContentToDatabase(contentToProcess)
  }

  function selectItemsWithGeneratedContentEmpty(data) {
    const selectedItems = {}

    for (const key in data) {
      const subtopics = data[key]
      for (const subtopicKey in subtopics) {
        const subtopic = subtopics[subtopicKey]
        if (
          !subtopic.generatedContent ||
          subtopic.generatedContent === '' ||
          (subtopic.status && subtopic.status === 'error')
        ) {
          if (!selectedItems[key]) {
            selectedItems[key] = {}
          }
          selectedItems[key][subtopicKey] = subtopic
        }
      }
    }

    return selectedItems
  }

  const saveContentToDatabase = data => {
    const contentToSave = JSON.stringify(data || content)

    const { active_organization_id } = user.info

    setIsSavingContent(true)
    api.courses
      .saveGeneratedContent({
        courseId: courseId,
        orgId: active_organization_id,
        table: { content: contentToSave }
      })
      .then(() => {
        setIsSavingContent(false)
      })
      .catch(err => {
        setIsSavingContent(false)
        console.log(err)
      })
  }

  const sendContentGenerationRequest = () => {
    const { active_organization_id } = user.info

    const contentToGenerate = selectItemsWithGeneratedContentEmpty(content)
    const structuredArray = convertToArrayOfObjects(contentToGenerate)

    api.courses
      .aiContentGenerationUrl({
        course_id: courseId,
        table_of_content: JSON.stringify(content),
        course_name: courseTitle,
        org_id: String(active_organization_id),
        table_of_contents: structuredArray
      })
      .then(res => {
        console.log({ res })
      })
      .catch(err => {
        setIsGeneratingContent(false)
        console.error(err)
      })
  }

  const handleGenerateCourseContent = () => {
    setIsConfirmationModalVisible(false)
    setIsGeneratingContent(true)
    onContentGenerationStarted()
    initializeWebsocket()
  }

  const progressStatus = useMemo(() => {
    if (!content) {
      return {
        totalSubtopics: 0,
        totalSubtopicsWithContent: 0,
        subTopicsWithError: 0
      }
    }

    // total subtopics inside the content
    const totalSubtopics = Object.keys(content).reduce((acc, section) => {
      return acc + Object.keys(content[section]).length
    }, 0)

    // total subtopics inside the content with generated content
    const totalSubtopicsWithContent = Object.keys(content).reduce(
      (acc, section) => {
        return (
          acc +
          Object.keys(content[section]).reduce((acc, subtopic) => {
            return acc + (content[section][subtopic].generatedContent ? 1 : 0)
          }, 0)
        )
      },
      0
    )

    const subTopicsWithError = totalSubtopics - totalSubtopicsWithContent

    return {
      totalSubtopics,
      totalSubtopicsWithContent,
      subTopicsWithError
    }
  }, [content])

  function flattenJSON(data) {
    const result = []

    function flattenObject(obj, parentType = 'title') {
      for (const key in obj) {
        const content = obj[key].generatedContent

        if (typeof obj[key] === 'object' && Object.keys(obj[key]).length > 0) {
          result.push({ type: parentType, content: key })
          flattenObject(obj[key], 'subtopic')
        }

        if (content) {
          result.push({ type: 'content', content })
        }
      }
    }

    flattenObject(data)

    return result
  }

  function downloadDocx(docContent) {
    try {
      const transformedData = flattenJSON(docContent)
      const doc = new Document({
        sections: [
          {
            children: transformedData.map(({ type, content }) => {
              return new Paragraph({
                spacing: {
                  after: type === 'title' ? 750 : type === 'content' ? 300 : 250
                },
                children: [
                  new TextRun({
                    text: content,
                    bold: true,
                    size: type === 'title' ? 28 : type === 'subtopic' ? 24 : 20,
                    color: type === 'title' ? '#0003ff' : '#000000'
                  })
                ]
              })
            })
          }
        ]
      })

      saveDocumentToFile(doc, `Course Document File for ${courseTitle}.docx`)
    } catch (error) {
      console.error('Error while generating and saving the document:', error)
      // Handle the error accordingly
    }
  }

  const downloadWithoutReferences = () => {
    const objWithoutRef = removeReferences(content)
    const referencesArray = extractReferences(content)

    downloadRefenceDocx(referencesArray)
    downloadDocx(objWithoutRef)
  }

  const downloadRefenceDocx = array => {
    try {
      const doc = new Document({
        sections: [
          {
            children: [
              new Paragraph({
                text: 'References',
                heading: HeadingLevel.HEADING_1
              }),
              ...array.map(reference => {
                return new Paragraph({
                  spacing: {
                    after: 200
                  },
                  children: [
                    new TextRun({
                      text: reference,
                      bold: true
                    })
                  ]
                })
              })
            ]
          }
        ]
      })

      saveDocumentToFile(doc, `References File for ${courseTitle}.docx`)
    } catch (error) {
      console.error('Error while generating and saving the document:', error)
    }
  }

  function saveDocumentToFile(doc, fileName) {
    Packer.toBlob(doc).then(blob => {
      saveAs(blob, fileName)
    })
  }

  const handleSaveGeneratedContent = () => {
    const newContent = {
      ...content
    }

    const {
      content: generatedContent,
      title: subTopic,
      lesson
    } = generatedContentModal
    newContent[lesson][subTopic].generatedContent = generatedContent

    setContent(newContent)
    setGeneratedContentModal({
      isVisible: false,
      content: '',
      title: '',
      lesson: ''
    })
  }

  function convertContentToCourseData(data) {
    const dataArray = []

    for (const topic in data) {
      const subTopics = Object.entries(data[topic])
        .filter(([key, value]) => key !== 'generatedContent')
        .map(([sub_topic, { generatedContent }]) => ({
          sub_topic,
          generated_content: generatedContent
        }))
      dataArray.push({ topic, sub_topics: subTopics })
    }

    return dataArray
  }

  const confirmCreateCourse = () => {
    Modal.confirm({
      title: t('v4:confirm_creating_course'),
      content: t('v4:generate_content_automatically'),
      onOk() {
        handleCreateCourse()
      },
      centered: true,
      width: '500'
    })
  }

  const handleCreateCourse = () => {
    setIsGeneratingCourse(true)

    const contentWithRemovedRefs = removeReferences(content)
    const structuredArray = convertContentToCourseData(contentWithRemovedRefs)

    api.courses
      .generateAllAILessons(
        { courseId },
        {
          tocs: structuredArray
        }
      )
      .then(res => {
        history.push(`/courses/${courseId}/edit`)
      })
      .catch(err => {
        setIsGeneratingCourse(false)
        message.error(err.message)
      })
  }

  const handleAddContentFromInternet = () => {
    const { content: generatedContent, title, lesson } = generatedContentModal

    setIsAddingContentFromInternet({
      topic: lesson,
      sub_topic: title
    })

    const payload = {
      course_title: courseTitle,
      topic: lesson,
      sub_topic: title,
      database_content: generatedContent
    }

    api.courses
      .generateContentFromInternet(payload)
      .then(res => {
        const text = res.data
        setGeneratedContentModal(pre => ({
          ...pre,
          content: text
        }))
        setContent(pre => ({
          ...pre,
          [lesson]: {
            ...pre[lesson],
            [title]: {
              generatedContent: text
            }
          }
        }))
      })
      .finally(() => {
        setIsAddingContentFromInternet(null)
      })
  }

  const isContentGeneratedSuccessfully = useMemo(() => {
    if (isGeneratingContent) return false

    if (progressStatus.totalSubtopicsWithContent === 0) return false

    return (
      progressStatus.totalSubtopicsWithContent === progressStatus.totalSubtopics
    )
  }, [progressStatus, isGeneratingContent])

  const contentSuccessInAllSections = useMemo(() => {
    if (isGeneratingContent) return false

    const isSuccess = Object.keys(content).every(section =>
      Object.keys(content[section]).every(subsection => {
        const contentItem =
          content && content[section] && content[section][subsection]
        const generatedContent = contentItem && contentItem.generatedContent
        const status = contentItem && contentItem.status

        return generatedContent && status !== 'error'
      })
    )

    if (isSuccess) setIsContentGenerationCompleted(isSuccess)

    return isSuccess
  }, [isGeneratingContent, content])

  function removeReferences(inputObj) {
    // Remove references from each section
    const data = JSON.parse(JSON.stringify(inputObj))
    for (const section in data) {
      for (const subsection in data[section]) {
        const content = data[section][subsection].generatedContent
        let contentWithoutList = content.replace(/\nReferences:.*$/gs, '')

        // Remove references within the text
        let contentWithoutReferences = contentWithoutList.replace(
          /\[\d+\]/g,
          ''
        )

        contentWithoutReferences.trim()

        data[section][subsection].generatedContent = contentWithoutReferences
      }
    }

    // Convert the modified object back to JSON string and return
    return data
  }

  function extractReferences(data) {
    const referencesSet = new Set()

    // Loop through each section
    Object.values(data).forEach(section => {
      // Loop through each sub-section
      Object.values(section).forEach(subSection => {
        // Extract references from generated content
        const generatedContent = subSection.generatedContent
        const regex = /\[([^\]]+)\] [^\[]+/g // Regular expression to match references
        let match
        while ((match = regex.exec(generatedContent)) !== null) {
          let reference = match[0]
          // Remove trailing newline characters
          reference = reference.replace(/\n$/, '')
          referencesSet.add(reference) // Add matched reference to the set
        }
      })
    })

    // Convert set to sorted array and return
    const references = Array.from(referencesSet).sort()
    return references
  }

  const toggleDocumentModal = () => setShowDocumentModal(!showDocumentModal)

  const checkError = () => {
    if (isGeneratingContent) return false

    return Object.values(content).some(section => {
      return Object.values(section).some(subsection => {
        return subsection.status === 'error'
      })
    })
  }
  const { t } = useTranslation()

  const showInternetContentLoading = useMemo(() => {
    if (!isAddingContentFromInternet) return false

    if (isAddingContentFromInternet) {
      console.log({
        generatedContentModal,
        isAddingContentFromInternet
      })
      const isSameTopic =
        generatedContentModal.lesson === isAddingContentFromInternet.topic
      const isSameSubTopic =
        generatedContentModal.title === isAddingContentFromInternet.sub_topic

      return isSameTopic && isSameSubTopic
    }

    return false
  }, [isAddingContentFromInternet, generatedContentModal])

  return (
    <div className="ai-course__table-of-content">
      <div className="ai-course-table-of-content-inner">
        <div className="ai-course__table-of-content__header">
          <div className="ai-course__table-of-content__left-header">
            <h1>{t('v3:ai_toc')}</h1>
            <span className="ai-course__table-of-content__note">
              {t('v3:ai_note_topic')}
            </span>
          </div>
          {isGeneratingContent && (
            <div className="ai-course__table-of-content__progress">
              <Statistic
                title={t('v4:ai_generate_content_progress')}
                value={`${progressStatus.totalSubtopicsWithContent} / ${progressStatus.totalSubtopics}`}
                precision={2}
                valueStyle={{ color: '#3f8600' }}
                prefix={<Icon type="loading" />}
                suffix=""
              />
            </div>
          )}
        </div>
        <div className="ai-course__table-of-content__section">
          {Object.entries(content).map(
            ([sectionTitle, subsections], nindex) => (
              <div key={sectionTitle} className="section">
                <List.Item
                  className={`ai-course__table-of-content__lesson-title ${
                    isModalVisible &&
                    editTopicStatus &&
                    editTopicTopIndex == nindex
                      ? 'edit-ready'
                      : ''
                  }`}
                >
                  {isModalVisible &&
                  editTopicStatus &&
                  editTopicTopIndex == nindex ? (
                    <Input
                      size="large"
                      value={newTitle}
                      onChange={e => setNewTitle(e.target.value)}
                    />
                  ) : (
                    <span> {sectionTitle} </span>
                  )}

                  {renderActionItems(sectionTitle, null, -1, nindex, true)}
                </List.Item>
                <div>
                  <TopicsContainer
                    distance={1}
                    onSortEnd={({ oldIndex, newIndex }) =>
                      onSortTopicsEnd({ sectionTitle, oldIndex, newIndex })
                    }
                    useDragHandle={isGeneratingContent}
                    lockAxis="y"
                    axis="y"
                    lockToContainerEdges={true}
                  >
                    {Object.keys(subsections).map((item, index) => (
                      <TopicItem key={item} index={index}>
                        <div
                          className={`ai-course__table-of-content__lesson-topic ${
                            isModalVisible &&
                            editTopicTopIndex == nindex &&
                            editTopicIndex == index
                              ? 'edit-ready'
                              : ''
                          }`}
                        >
                          <span>
                            {isModalVisible &&
                            editTopicTopIndex == nindex &&
                            editTopicIndex == index ? (
                              <Input
                                size="large"
                                value={newTitle}
                                onChange={e => setNewTitle(e.target.value)}
                              />
                            ) : (
                              item
                            )}

                            {subsections[item].generatedContent && (
                              <Tooltip title="Content has been generated for this topic.">
                                <Icon
                                  type="check-circle"
                                  style={{ color: 'green' }}
                                />
                              </Tooltip>
                            )}
                          </span>
                          {isGeneratingContent &&
                          !subsections[item].generatedContent ? (
                            <Icon type="loading" />
                          ) : (
                            renderActionItems(
                              sectionTitle,
                              item,
                              index,
                              nindex,
                              false
                            )
                          )}
                        </div>
                      </TopicItem>
                    ))}
                  </TopicsContainer>
                </div>
                {!isGeneratingContent && (
                  <span
                    className="ai-course__table-of-content__add-topic"
                    onClick={() => {
                      setCurrentSection(sectionTitle)
                      setNewTopicModalVisible(true)
                    }}
                  >
                    {t('v3:ai_add_topic')}
                  </span>
                )}
              </div>
            )
          )}
        </div>

        <div className="ai-course__table-of-content__create_lesson">
          {contentSuccessInAllSections ? (
            <>
              {!isGeneratingContent && (
                <>
                  <Button
                    className="ai-course__table-of-content__add-lesson shl-secondary-btn"
                    onClick={showNewLessonModal}
                  >
                    {t('v3:ai_add_lesson')}
                  </Button>
                </>
              )}
              {!isFromKnowledgeCenter && (
                <Button
                  type="primary"
                  shape="round"
                  onClick={() => saveContentToDatabase()}
                  loading={isSavingContent}
                  ghost
                >
                  {t('v3:ai_save_later')}
                </Button>
              )}

              <Button
                icon="file-word"
                type="green"
                shape="round"
                onClick={toggleDocumentModal}
                loading={isGeneratingCourse}
              >
                {t('v3:ai_download_word_doc')}
              </Button>

              {!isFromKnowledgeCenter && (
                <Button
                  type="primary"
                  shape="round"
                  onClick={confirmCreateCourse}
                  loading={isGeneratingCourse}
                >
                  {t('v3:ai_create_course_button')}
                </Button>
              )}
            </>
          ) : (
            <div className="ai-course__table-of-content__create_lesson__button-with-notes">
              <div className="create-lesson-button-with-btn">
                {!isGeneratingContent && (
                  <>
                    <Button
                      className="ai-course__table-of-content__add-lesson shl-secondary-btn"
                      onClick={showNewLessonModal}
                    >
                      {t('v3:ai_add_lesson')}
                    </Button>
                  </>
                )}
                <Button
                  type="green"
                  shape="round"
                  onClick={() => setIsConfirmationModalVisible(true)}
                  loading={isGeneratingContent}
                >
                  {t('v3:ai_generate_course_content')}
                </Button>
              </div>

              {checkError() && (
                <Alert
                  message={`${progressStatus.subTopicsWithError} ${t(
                    'v3:ai_out_of'
                  )} ${progressStatus.totalSubtopics} ${t(
                    'v3:ai_subtopic_failed_content'
                  )}`}
                  type="error"
                  banner
                />
              )}
            </div>
          )}
        </div>
      </div>

      <Modal
        title={t('v3:ai_add_new_topic')}
        visible={newTopicModalVisible}
        onOk={handleAddTopic}
        okText="Add Topic"
        onCancel={() => setNewTopicModalVisible(false)}
        centered
        className="add-new-topic-open"
      >
        <Input
          placeholder="Add new topic"
          size="large"
          value={newTitle}
          onChange={e => setNewTitle(e.target.value)}
        />
      </Modal>

      <Modal
        title={t('v3:add_new_lesson')}
        visible={newLessonModalVisible}
        onOk={handleAddLesson}
        okText={t('v3:add_lesson')}
        onCancel={() => setNewLessonModalVisible(false)}
        centered
        className="add-new-lesson-open"
      >
        <Input
          placeholder={t('v3:add_new_lesson')}
          size="large"
          value={newLessonTitle}
          onChange={e => setNewLessonTitle(e.target.value)}
        />
      </Modal>

      <Modal
        title={t('v3:ai_download_course_word_doc')}
        visible={showDocumentModal}
        onCancel={toggleDocumentModal}
        footer={null}
        centered
        className="add-course-download-open"
      >
        <div className="ai-course__table-of-content__document-modal">
          {/* two choices, download with references or download without references */}
          <div className="ai-course__table-of-content__document-modal__choices">
            <div className="ai-course__table-of-content__document-modal__choices__item">
              <Button
                type="primary"
                icon="download"
                onClick={() => downloadDocx(content)}
              >
                {t('v3:ai_download_ref')}
              </Button>

              <p>{t('v3:ai_download_ref_content')}</p>
            </div>

            <Divider>or</Divider>
            <div className="ai-course__table-of-content__document-modal__choices__item">
              <Button
                type="primary"
                icon="download"
                onClick={downloadWithoutReferences}
              >
                {t('v3:ai_download_without_ref')}
              </Button>

              <p>
                <span>{t('v3:ai_download_two_word')}</span>
                <br />
                {t('v3:ai_download_two_word_content_one')}
                <br />-{t('v3:ai_download_two_word_content_two')}
              </p>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        onCancel={() => {
          if (showInternetContentLoading) return
          setGeneratedContentModal({
            isVisible: false,
            content: '',
            title: '',
            lesson: ''
          })
        }}
        visible={generatedContentModal.isVisible}
        centered
        className="ai-course__table-of-content__generated-content-modal modal-content-wrap"
        footer={null}
      >
        <Spin
          tip="Adding Content From Internet..."
          spinning={showInternetContentLoading}
        >
          <div className="manage-content">
            <h1>
              <Icon type="setting" /> {t('v3:ai_manage_content')}
            </h1>

            <div className="content-section">
              <div className="content-section__title">
                <h2>{generatedContentModal.title}</h2>
                <span
                  className="add-content-btn"
                  onClick={handleAddContentFromInternet}
                >
                  {t('v3:ai_add_content_from_internet')}
                </span>
              </div>

              <ViewContent
                text={generatedContentModal.content}
                onChange={value =>
                  setGeneratedContentModal(prev => ({
                    ...prev,
                    content: value
                  }))
                }
              />
            </div>

            <div className="button-group">
              <Button
                shape="round"
                type="primary"
                className="save-btn"
                onClick={handleSaveGeneratedContent}
              >
                {t('general:save')}
              </Button>
            </div>
          </div>
        </Spin>
      </Modal>

      <Modal
        title={t('v3:ai_popup_confirm_content')}
        visible={isConfirmationModalVisible}
        centered
        onOk={handleGenerateCourseContent}
        onCancel={() => setIsConfirmationModalVisible(false)}
        className="ai-confirm-content"
        footer={[
          <Button
            key="back"
            onClick={() => setIsConfirmationModalVisible(false)}
            className="shl-secondary-btn"
          >
            {t('buttons:cancel')}
          </Button>,
          <Button
            key="submit"
            onClick={handleGenerateCourseContent}
            className="shl-primary-btn"
          >
            {t('v3:ai_Verify')}
          </Button>
        ]}
      >
        <div className="ai-warning">
          <div className="ai-warning-inner">
            <img
              src={AttentionIcon}
              alt="attention"
              className="attention-icon"
            />
            <h3>{t('v3:attention')}</h3>
          </div>
          <p>{t('v3:ai_undo_warning')}</p>
        </div>
        <div className="ai-check">
          <div className="ai-warning-inner">
            <img src={WarningIcon} alt="warning" className="warning-icon" />
            <h3>{t('v3:before_varifying')}</h3>
          </div>

          <p> {t('v3:ai_verified_content')}</p>
        </div>
        <div className="ai-proceed">{t('v3:ai_want_proceed')}</div>
      </Modal>
    </div>
  )
}

const ViewContent = ({ text, onChange }) => {
  const [inputValue, setInputValue] = useState('')

  useEffect(() => {
    setInputValue(text)
  }, [text])

  const handleChange = e => {
    const newValue = e.target.value
    setInputValue(newValue)
    onChange(newValue)
  }

  return (
    <TextArea
      className="generated-content"
      value={inputValue}
      minRows={5}
      autoSize
      onChange={handleChange}
    />
  )
}
export default TableOfContents
