import React, { useEffect, useRef, useState } from 'react'
import './AudioPlayer.scss'
import { PlayCircleOutlined, PauseCircleOutlined } from '@ant-design/icons'
const AudioPlayer = ({ playPause, audioUrl, changeAudioStatus }) => {
  const audioRef = useRef(null)
  const progressBarRef = useRef(null)
  const [isPlaying, setIsPlaying] = useState(false)
  const [duration, setDuration] = useState(0)
  const [currentProgress, setCurrentProgress] = useState(0)
  const [currentPosition, setCurrentPosition] = useState(0)
  useEffect(() => {
    const audio = audioRef.current

    const updateProgress = () => {
      const audio = audioRef.current
      const currentTime = audio.currentTime
      setCurrentPosition(currentTime)
      const duration = audio.duration
      const progress = (currentTime / duration) * 100
      setCurrentProgress(progress)
    }

    const updateDuration = () => {
      const duration = audio.duration
      setDuration(duration)
    }

    audio.addEventListener('timeupdate', updateProgress)
    audio.addEventListener('loadedmetadata', updateDuration)

    return () => {
      audio.removeEventListener('timeupdate', updateProgress)
      audio.removeEventListener('loadedmetadata', updateDuration)
    }
  }, [])
  useEffect(() => {
    let audio = audioRef.current
    audio.load()
    changeAudioStatus(false)
    setIsPlaying(false)
  }, [audioUrl])
  useEffect(() => {
    if (playPause.status) {
      togglePlay()
    }
  }, [playPause])
  const formatTime = timeInSeconds => {
    const minutes = Math.floor(timeInSeconds / 60)
    const seconds = Math.floor(timeInSeconds % 60)
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`
  }
  const togglePlay = () => {
    const audio = audioRef.current
    if (audio.paused) {
      audio.play()
      changeAudioStatus(true)
      setIsPlaying(true)
    } else {
      audio.pause()
      changeAudioStatus(false)
      setIsPlaying(false)
    }
  }
  const handleProgressBarClick = e => {
    const audio = audioRef.current
    const clickPosition =
      e.pageX - progressBarRef.current.getBoundingClientRect().left
    const progressBarWidth = progressBarRef.current.offsetWidth
    const currentTime = (clickPosition / progressBarWidth) * audio.duration
    audio.currentTime = currentTime
    setCurrentProgress((currentTime / audio.duration) * 100)
  }
  return (
    <div className="podcast-player">
      <div className="podcast-player_player">
        <div className="podcast-player_player_body">
          <div className="podcast-player_player_body_controls">
            {isPlaying ? (
              <PauseCircleOutlined
                onClick={() => {
                  togglePlay(false)
                }}
                style={{ fontSize: 32, color: '#000000' }}
              />
            ) : (
              <PlayCircleOutlined
                onClick={() => {
                  togglePlay(true)
                }}
                style={{ fontSize: 32, color: '#000000' }}
              />
            )}
          </div>
          <div className="podcast-player_player_body_waves">
            <div className="podcast-player_player_body_waves_current">
              {formatTime(currentPosition)}
            </div>
            <div className="podcast-player_player_body_waves_background">
              <audio ref={audioRef} controls={false}>
                <source src={audioUrl} type="audio/mpeg" />
                Your browser does not support the audio element.
              </audio>

              <div className="progress-body">
                <div
                  className="progress-bar"
                  onClick={handleProgressBarClick}
                  ref={progressBarRef}
                >
                  <div
                    className="progress"
                    style={{
                      width: `${currentProgress}%`
                    }}
                  ></div>
                </div>
              </div>
            </div>
            <div className="podcast-player_player_body_waves_total">
              {formatTime(duration)}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AudioPlayer
