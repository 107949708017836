import { CmiInteractionCorrectResponses } from "./CmiInteractionCorrectResponses.js"
import { CmiInteractionObjectives } from "./CmiInteractionObjectives.js"

export class CmiInteractions{
	id
	n
  n_id
  interaction_time
  type
  weighting
  student_response
  result
  latency
  core_id
  description
  timestamp
  cmi_interactions_correct_responses
	cmi_interactions_objectives
	constructor(data){
		Object.assign(this, data);
		this.cmi_interactions_correct_responses = []
		this.cmi_interactions_objectives = []

		if(data){
			if(data.cmi_interactions_correct_responses){
				this.cmi_interactions_correct_responses = data.cmi_interactions_correct_responses.map(x=>Object.assign(new CmiInteractionCorrectResponses(), x))
			}
	
			if(data.cmi_interactions_objectives){
				this.cmi_interactions_objectives = data.cmi_interactions_objectives.map(x=>Object.assign(new CmiInteractionObjectives(), x))
			}
		}
	}

	correctResponseCount(){
		return this.cmi_interactions_correct_responses.length
	}

	objectiveCount(){
		return this.cmi_interactions_objectives.length
	}


	AddCorrectResponse(){
		const correctResponses = new CmiInteractionCorrectResponses()
		correctResponses.n = this.correctResponseCount()
		this.cmi_interactions_correct_responses.push(
			correctResponses
		)
	}

	AddObjective(){
		const objectives = new CmiInteractionObjectives()
		objectives.n = this.objectiveCount()
		this.cmi_interactions_objectives.push(
			objectives
		)
	}

	findNObjective(index) {
		return this.cmi_interactions_objectives.find(x => x.n === index)
	}

	findNCorrectResponse(index) {
		return this.cmi_interactions_correct_responses.find(x => x.n === index)
	}

}