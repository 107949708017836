import React from 'react'
import { Button, Tooltip } from 'antd'
import { Row, Col, Field } from '../../../../../styles'
import { withTranslation, Trans } from 'react-i18next'

const PrivacySelector = ({ types, selectedType, t, isAdmin, onChange }) => {
  const onClick = id => {
    if (onChange) {
      onChange(id)
    }
  }
  return (
    <Field>
      <Field.Label>{t('labels:privacy_settings')}</Field.Label>
      <div style={{ paddingBottom: '10px' }}>
        {!isAdmin && (
          <Trans i18nKey="helpers:public_description_normal">
            <span>{t('v4:podcast_music'.toLowerCase())}</span>
          </Trans>
        )}
        {isAdmin && (
          <Trans i18nKey="helpers:public_description_admin">
            <span>{t('v4:podcast_music'.toLowerCase())}</span>
          </Trans>
        )}
      </div>
      <Field.Control>
        <Row>
          {types.map(type => (
            <Col md={types.length > 1 ? 12 / types.length : 4} key={type.id}>
              {type.name === 'Marketplace' && type.disabled ? (
                <Tooltip placement="bottom" title={t('v4:not_complete')}>
                  <Button block icon="exclamation-circle" disabled>
                    {type.name}
                  </Button>
                </Tooltip>
              ) : (
                <Button
                  block
                  type={selectedType === type.id ? 'primary' : ''}
                  onClick={() => onClick(type.id)}
                  disabled={type.disabled}
                >
                  {type.name}
                </Button>
              )}
            </Col>
          ))}
        </Row>
      </Field.Control>
    </Field>
  )
}

export default withTranslation()(PrivacySelector)
