export class ScormSco{
	id
	title
	identifier
	parent
	sortorder
	href
	scormtype
	datafromlms
	parameters

	mastery_score
	max_time_allowed
	time_limit_action

	constructor(data){
		if(data){
			Object.assign(this, data)	
		}
	}
}