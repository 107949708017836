import { ROLES_SUCCESS, ROLES_FETCHING } from '../types'

const initialState = {
  isFetching: false,
  list: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ROLES_SUCCESS:
      return {
        ...state,
        list: action.payload
      }

    case ROLES_FETCHING:
      return {
        ...state,
        isFetching: action.payload
      }

    default:
      return state
  }
}
