import styled from 'styled-components'
// import { styleguide } from '../../constants'
import { Button } from 'antd'

// const { colors } = styleguide

const MessageContainer = styled.div`
  height: 80vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
`
MessageContainer.Title = styled.div`
  display: block;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: bolder;
`
MessageContainer.Subtitle = styled.div`
  display: block;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
  font-size: 2rem;
  font-style: normal;
  font-weight: bolder;
`
MessageContainer.Button = styled(Button)`
  display: block;
  justify-content: center;
  align-items: center;
`

export {
  MessageContainer
}
