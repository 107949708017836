import React from 'react'
import { CreateChannelForm } from './components'

import './CreateChannel.scss'
function CreateChannel(props) {
  const id = props.match.params.id

  const state = props.location.state || null

  return (
    <div className="shl-create-channel">
      <CreateChannelForm channelId={id} newAiVideo={state} />
    </div>
  )
}

export default CreateChannel
