import React, { Component } from 'react'
import { Icon, Button } from 'antd'

import { ModalExistingLesson } from './internal'

import { Wrapper, Main, HR } from '../../styled'
import { ChoiceWrapper, Choice } from './styled'

import { pencil, paper, clone } from './images'
import { withTranslation } from 'react-i18next'

class LessonOverview extends Component {
  state = {
    isVisible: false,
    isClone: null
  }

  setModalVisible = (value, isClone = null, isClear = false) => {
    const { lessonBuilderActions } = this.props
    this.setState({
      isClone,
      isVisible: value
    }, () => {
      if (isClear) {
        lessonBuilderActions.clearLessons()
      }
    })
  }

  onCreateLesson = (callBack = null) => {
    const { lessonBuilderActions } = this.props

    lessonBuilderActions.resetLessonBuilder()

    if (callBack !== null) {
      callBack().then(response => {
        lessonBuilderActions.fetchLessonById(response.data.id)
        this.gotoPage(response.data.id)
      })
    } else {
      this.gotoPage()
    }
  }

  gotoPage = (id) => {
    const { history, rootPath } = this.props
    if (id) {
      history.push(`${rootPath}/lesson-create/${id}`)
    } else {
      history.push(`${rootPath}/lesson-create`)
    }
  }

  render () {
    const { history, rootPath, t } = this.props
    const { isVisible, isClone } = this.state

    return (
      <Wrapper>
        {isVisible && <ModalExistingLesson
          visible={isVisible}
          onCancel={() => this.setModalVisible(false, null, true)}
          rootPath={rootPath}
          isClone={isClone}
          onCreateLesson={this.onCreateLesson}
        />
        }

        <Main>

          <div className='head'>
            <div className='title'>{t('title')}</div>
            <div className='subtitle'>{t('subtitle')}</div>
          </div>

          <HR/>

          <ChoiceWrapper>
            <Choice onClick={() => this.onCreateLesson()}>
              <Choice.IconChoise src={pencil}/>
              <Choice.Title>{t('new_lesson')}</Choice.Title>
              <Choice.SubTitle>{t('new_lesson_sub')}</Choice.SubTitle>
            </Choice>

            <Choice.Divider>{t('general:or')}</Choice.Divider>

            <Choice onClick={() => this.setModalVisible(true)}>
              <Choice.IconChoise src={paper}/>
              <Choice.Title>{t('existing_lesson')}</Choice.Title>
              <Choice.SubTitle>{t('existing_lesson_sub')}</Choice.SubTitle>
            </Choice>

            <Choice.Divider>{t('general:or')}</Choice.Divider>

            <Choice onClick={() => this.setModalVisible(true, true)}>
              <Choice.IconChoise src={clone}/>
              <Choice.Title>{t('clone_lesson')}</Choice.Title>
              <Choice.SubTitle>{t('clone_lesson_sub')}</Choice.SubTitle>
            </Choice>
          </ChoiceWrapper>
        </Main>

        <div className='p-footer'>
          <div className='p-footer__inner'>
            <div className='p-footer__item'>
              <Button className='rounded' onClick={() => history.push(rootPath)}><Icon
                type='left'/> {t('buttons:back_to_course')}</Button>
            </div>
          </div>
        </div>
      </Wrapper>
    )
  }
}

export default withTranslation('lessonoverview')(LessonOverview)
