/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-08-07 15:55:38
 * @LastEditTime: 2019-08-27 11:27:03
 * @LastEditors: Please set LastEditors
 */
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as lessonBuilderActions from '../../../../../../../store/actions/lessonBuilder'
import actions from '../../../../../../../store/actions'

import ModalExistingLesson from './ModalExistingLesson'

const { handleLessonClone } = actions.lessonBuilder

export default connect(
  (state) => ({
    lessons: state.lessons,
    courseBuilder: state.courseBuilder
  }),
  (dispatch) => ({
    lessonBuilderActions: bindActionCreators(lessonBuilderActions, dispatch),
    handleLessonClone: bindActionCreators(handleLessonClone, dispatch),
    bindLesson: (courseId, lessonId, data) => dispatch(actions.lessonBuilder.bindLessontoCourse(courseId, lessonId, data))
  })
)(ModalExistingLesson)
