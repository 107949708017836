/*
 * @Author: your name
 * @Date: 2019-10-28 16:15:34
 * @LastEditTime: 2019-12-09 16:32:18
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: /frontend/src/views/Management/internal/Organisations/index.js
 */
import { connect } from 'react-redux'

import Organisations from './Organisations'
import actions from '../../../../store/actions'

export default connect(
  state => ({
    organizations: state.organizations,
    user: state.user
  }),
  dispatch => ({
    createOrganization: data =>
      dispatch(actions.organizations.createOrganization(data)),
    updateOrganization: (id, data) =>
      dispatch(actions.organizations.updateOrganization(id, data)),
    getOrganizations: data =>
      dispatch(actions.organizations.getAutoComplete(data)),
    upload: (file, opts) => dispatch(actions.library.upload(file, opts)),
    updateOrganizationsList: data => dispatch(actions.organizations.get(data)),
    deleteOrganizationById: id =>
      dispatch(actions.organizations.deleteOrganizationById(id))
  })
)(Organisations)
