const resizeRatio = 768 / 1024
const ORIENTATION_PORTRAIT = 'portrait'
const ORIENTATION_LANDSCAPE = 'landscape'

const TYPE_AUDIO = 'AUDIO'
const TYPE_BACKGROUND_IMAGE = 'BACKGROUND_IMAGE'
const TYPE_IMAGE = 'IMAGE'
const TYPE_TEXT = 'TEXT'
const TYPE_ANNOTATION = 'ANNOTATION'
const TYPE_QUIZ = 'QUIZ'
const TYPE_VIDEO = 'VIDEO'

/**
 * Resize element for landscape to portrait conversion
 * @param {Object} element
 * @return {Object}
 */
const resizeElementL2P = (element) => {
  return _resizeElement(element, 'L2P')
}

/**
 * Resize element for portrait to landscape conversion
 * @param {Object} element
 * @return {Object}
 */
const resizeElementP2L = (element) => {
  return _resizeElement(element, 'P2L')
}

/**
 * Resize element
 * @param {Object} element
 * @param {String} operation
 * @return {Object}
 */
const _resizeElement = (element, operation) => {
  // audio & quiz, annotation have a fixed size, only the position should be adjusted
  if (element.type === TYPE_AUDIO || element.type === TYPE_QUIZ || element.type === TYPE_ANNOTATION) {
    return element
  }

  const oldWidth = element.params.width
  const oldHeight = element.params.height

  let newWidth = oldWidth; let newHeight = oldHeight

  // if element height > 768 - element will not fit new page height
  // resize video & images, keep proportions

  if (
    (element.type === TYPE_VIDEO || element.type === TYPE_IMAGE)
  ) {
    newWidth = oldWidth * resizeRatio
    newHeight = oldHeight * resizeRatio
  }

  // text block: resize - make the block less wide but higher to keep the same amount of space for text
  if (operation === 'L2P' && element.type === TYPE_TEXT) {
    newWidth = oldWidth * resizeRatio // decrease width
    newHeight = oldHeight / resizeRatio // increase height
  }

  // text block: resize - make the block wider but less higher to keep the same amount of space for text
  if (operation === 'P2L' && element.type === TYPE_TEXT) {
    newWidth = oldWidth / resizeRatio // decrease width
    newHeight = oldHeight * resizeRatio // increase height
  }

  element.params.width = Math.ceil(newWidth)
  element.params.height = Math.ceil(newHeight)
  return element
}

/**
 * Convert page from portrait to landscape
 * Resize all elements on the page
 * change height 1024 to 768, recalculate new positions of the elements
 * @param {Object} _page
 * @return {Object} page with resized elements
 */
const resizePageP2LResize = (_page, _element) => {
  const page = JSON.parse(JSON.stringify(_page))

  page.orientation = ORIENTATION_LANDSCAPE
  page.size = {
    width: 1024,
    height: 768
  }

  // resize all elements on the page
  page.data = page.data.filter(element => {
    // do not touch TYPE_BACKGROUND_IMAGE  && TYPE_ANNOTATION
    if (element.type === TYPE_BACKGROUND_IMAGE) {
      console.log('backgroud', element)
      return false
      // return element
    }

    if (_element && element.uid !== _element.uid) {
      console.log('yes inside')
      return true
    }

    if (!_element && element.uid) {
      return true
    }

    const oldParams = JSON.parse(JSON.stringify(element.params)) // clone object

    element = resizeElementP2L(element)

    // move to proportional distance from the center
    const oldCenterY = oldParams.y + oldParams.height / 2 // center coordinate
    element.params.y = Math.ceil(oldCenterY * resizeRatio - element.params.height / 2)

    const oldCenterX = oldParams.x + oldParams.width / 2
    element.params.x = Math.ceil(oldCenterX / resizeRatio - element.params.width / 2)

    console.log('position', element)

    return true
  })

  return page
}

/*
* Convert page from portrait to landscape
* Resize all elements on the page
* change height 768 to 1024, recalculate new positions of the elements
* @param {Object} _page
* @return {Object} page with resized elements
*/
const resizePageL2PResize = (_page, _element) => {
  const page = JSON.parse(JSON.stringify(_page))

  page.orientation = ORIENTATION_PORTRAIT
  page.size = {
    width: 768,
    height: 1024
  }

  // resize all elements on the page
  page.data = page.data.filter(element => {
    // do not touch TYPE_BACKGROUND_IMAGE  && TYPE_ANNOTATION
    if (element.type === TYPE_BACKGROUND_IMAGE) {
      return false
    }
    if (_element && element.uid !== _element.uid) {
      return true
    }
    if (!_element && element.uid) {
      return true
    }

    const oldParams = JSON.parse(JSON.stringify(element.params)) // clone object

    element = resizeElementL2P(element)

    // move to proportional distance from the center
    const oldCenterY = oldParams.y + oldParams.height / 2 // center coordinate
    element.params.y = Math.ceil(oldCenterY / resizeRatio - element.params.height / 2)

    const oldCenterX = oldParams.x + oldParams.width / 2
    element.params.x = Math.ceil(oldCenterX * resizeRatio - element.params.width / 2)

    return true
  })

  return page
}

/**
 * Lambda handler. Call callback with processed data
 * @link https://aws.amazon.com/getting-started/hands-on/create-a-serverless-workflow-step-functions-lambda/?nc1=h_ls
 *
 * @param {Object} event: {
 *   data: {Object} - input data
 * }
 * @param {Object} context
 * @param {Function} callback
 *
 */

const orientationConversion = (param, element) => {
  console.log('i m in orientationConversion')
  // exports.handler = (event, context, callback) => {

  let data = {
    pages: param,
    element
  }

  // let data = event.data;
  // let data = JSON.parse(event.data);

  /** @var {String} force  'landscape' | 'portrait' **/
  // const force = event.force; // original
  const force = '' // changed

  // index pages by ID and orientation
  const pages = {}
  data.pages.forEach(page => {
    if (typeof pages[page.id] === 'undefined') {
      pages[page.id] = {}
    }
    pages[page.id][page.orientation] = page
  })

  /**
   * if force = "none", then go through each page and create the orientation that does not exist yet
   * ignoring any orientations that do exist.
   * if the force param is "landscape" then go through each page forcing the landscape param from an
   * existing portrait exam and overwriting any landscape that exists. if a portrait does not exist then skip it.
   * similarly, setting force to "portrait" would achieve the vice-versa of that.
   *
   * the final json would then have the existing "pages" object, and a new "pages_landscape" on the same level containing
   * the landscape page configurations. this would ensure its fully backwards compatible with the existing code.
   */

  const pagesPortrait = []
  const pagesLandscape = []

  // console.debug('*****************')
  // iterate over all pages, create required orientation
  Object.keys(pages).forEach(pageId => {
    const page = pages[pageId];

    if (!force) { // create missed orientation
      console.log('force if');

      if (page[ORIENTATION_LANDSCAPE]) {
        pagesLandscape.push(page[ORIENTATION_LANDSCAPE]);
        pagesPortrait.push(resizePageL2PResize(page[ORIENTATION_LANDSCAPE], data.element));
      } else {
        const res = resizePageP2LResize(page[ORIENTATION_PORTRAIT], data.element);

        pagesPortrait.push(page[ORIENTATION_PORTRAIT]);
        pagesLandscape.push(res);
      }
    } else if (force === ORIENTATION_PORTRAIT) { // force creating portrait
      console.log('force ORIENTATION_PORTRAIT');

      if (page[ORIENTATION_LANDSCAPE]) {
        pagesLandscape.push(page[ORIENTATION_LANDSCAPE]);
        pagesPortrait.push(resizePageL2PResize(page[ORIENTATION_LANDSCAPE], data.element));
      }
    } else if (force === ORIENTATION_LANDSCAPE) { // force creating landscape
      console.log('force ORIENTATION_LANDSCAPE');

      if (page[ORIENTATION_PORTRAIT]) {
        pagesPortrait.push(page[ORIENTATION_PORTRAIT]);
        pagesLandscape.push(resizePageP2LResize(page[ORIENTATION_PORTRAIT], data.element));
      }
    }
  });

  data.pages_landscape = pagesLandscape
  data.pages_portrait = pagesPortrait

  console.log('data pages ', data)

  return data

  // return callback(null, data)
}

/* const test = () => {
  const data = require('./sample2_lesson_portrait');

  exports.handler({data }, {}, function(err, result){
    console.log(JSON.stringify(result,null,2));
  });
}

test(); */

export {
  orientationConversion
}
