import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import actions from '../../../../../../store/actions'

import Quiz from './Quiz'

export default connect(
  state => ({
    data: state.reports
  }),
  dispatch => ({
    handlingData: bindActionCreators(actions.reports.handlingData, dispatch),
    getQuizListReport: bindActionCreators(
      actions.reports.getQuizListReport,
      dispatch
    ),
    getQuizReport: bindActionCreators(actions.reports.getQuizReport, dispatch)
  })
)(Quiz)
