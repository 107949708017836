import styled from 'styled-components'
import { styleguide } from '../../../../../../../constants'

const { colors } = styleguide

const Organization = styled.div`
  display: flex;
  align-items: center;
  padding: 1.6rem;
  border: 1px solid ${colors.darkGreyTwo};
  margin-bottom: 3.2rem;
  transition: border-color .2s;
  cursor: pointer;

  &:hover {
    border-color: rgba(0, 104, 255, 0.5);
  }

  ${props => props.active && `
    border-color: ${colors.blue};

    &:hover {
      border-color: ${colors.blue};
    }
  `}
`

Organization.Checkbox = styled.div`
  margin-right: 1.6rem;
`

Organization.Image = styled.img`
  display: block;
  width: 4rem;
  height: 4rem;
  margin-right: 1.6rem;
  border-radius: 100%;
`

Organization.Name = styled.div`
  font-weight: 600;
`

export {
  Organization
}
