import React, { Component, createRef, Fragment } from 'react'
import _, { intersection } from 'lodash'
import TopPageLink from '../../../components/TopPageLink'
import { Spinner } from '../../../components'
import loadingLogo from './assets'
import { withTranslation } from 'react-i18next'
import { Alert, Tabs, Button, Row, Col } from 'antd'
import { getSession } from '../../../services/api/user'
import UnityView from './components/UnityView.jsx'
import SentenceLoader from './components/SentenceLoader'
import { config } from '../../../constants'
import api from '../../../services/api'
import { getNestedChildren } from '../Create/components/MazeScenario/mazeHelper'
import './Detail.scss'
import EmptyMessage from '../../../components/EmptyMessage/EmptyMessage'
import DummyUserImage from '../../../assets/user.svg'

class MazeDetail extends Component {
  constructor(props) {
    super(props)
    this.mazeViewRef = createRef()
  }
  state = {
    token: '',
    playData: {},
    isReportLoading: false,
    isMazeComplete: false,
    isShowCover: true,
    recommendedCourses: {},
    isPlayLimited: false,
    isBuildLoaded: false,
    percentageLoader: 0,
    unityContext: '',
    activateTTS: false
  }

  treeContainer = createRef()

  componentDidMount() {
    this.checkOrgFromLink()
  }

  checkOrgFromLink = () => {
    const {
      location: { search, pathname },
      user: {
        info: { active_organization_id }
      },
      match: {
        params: { id }
      },
      getMazeById
    } = this.props

    const organizationUrlParam = search && search.split('id=')[1]

    const organizationId = organizationUrlParam.split('&')[0]

    // send null if org id is 1 from the marketplace
    const orgIdFromLink = organizationId === 1 ? null : organizationId

    // remove ?id= from url
    const newUrl = window.location.origin + pathname
    window.history.pushState({}, null, newUrl)

    if (organizationId.length && orgIdFromLink !== active_organization_id) {
      this.changeActiveOrg(orgIdFromLink)
    } else {
      getMazeById(id)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      user: { isFetchingUserAttributes },
      organizations: { rows },
      maze: { fetching, mazeById }
    } = this.props

    if (prevProps !== this.props) {
      const loadingCondition =
        isFetchingUserAttributes || fetching || _.isEmpty(mazeById)

      if (rows && !loadingCondition) {
        this.checkAndSwitchOrg(rows)
      }
    }
  }

  checkAndSwitchOrg = rows => {
    const {
      user: {
        info: { active_organization_id: userOrg }
      },
      maze: { mazeById: item }
    } = this.props

    const userOrgs = rows.map(e => e.id)

    if (!userOrg && item && item.purchased_from_marketplace) {
      this.getPlayData()
      return
    }

    if (item && item.setting) {
      const commonIds = intersection(item.setting.organization_ids, userOrgs)

      if (commonIds.length > 0) {
        const assignedOrg = _.findIndex(commonIds, function(o) {
          return o === userOrg
        })

        if (assignedOrg === -1) {
          this.changeActiveOrg(commonIds[0])
          return
        }
      }
    }

    this.getPlayData()
  }

  changeActiveOrg = orgId => {
    const {
      user: {
        info: { id }
      },
      updateUser
    } = this.props
    updateUser(id, null, {
      active_organization_id: orgId
    }).then(() => {
      window.location.reload()
    })
  }

  getPlayData = () => {
    const {
      match: {
        params: { id }
      }
    } = this.props
    getSession().then(session => {
      const token = 'Bearer ' + session.idToken.jwtToken
      this.setState(
        {
          token: session.idToken.jwtToken
        },
        () => {
          const requestOptionsPlay = {
            method: 'get',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token
            }
          }
          const URLPlay = config.baseURL + `programme/${id}/play`
          fetch(URLPlay, requestOptionsPlay)
            .then(response => response.json())
            .then(data => {
              console.log('LOG response PLAY', data)
              console.log('LOG response PLAY', data.data.play_limit)
              const {
                data: { play_limit }
              } = data
              if (play_limit) {
                this.setState({
                  isPlayLimited: play_limit
                })
                const {
                  user: {
                    info: { isAdmin, isSuperAdmin }
                  }
                } = this.props
                if (!isAdmin && !isSuperAdmin) {
                  return
                }
              }
              this.setState({
                playData: data.data
              })
            })
            .catch(() => {})
        }
      )
    })
  }

  // handleLoad = () => {
  //   const context = new AudioContext()
  //   document.querySelector('button').addEventListener('click', function() {
  //     context.resume().then(() => {})
  //   })
  // }

  componentWillUnmount() {
    if (this.intervalId !== undefined) {
      clearInterval(this.intervalId)
    }
  }

  getDataForGraph = () => {
    const {
      match: {
        params: { id }
      },
      maze: {
        mazeById: { organization_id }
      }
    } = this.props
    api.maze
      .mazeProvider({
        body: {
          programme: id
        },
        params: {
          play_id: this.state.playData.id
        },
        action: 'get_play_graph'
      })
      .then(res => {
        this.setState(
          {
            paths: res.data.data.paths,
            recommendedCourses: {
              title: res.data.data.programme.title,
              cover_id: res.data.data.programme.cover_id,
              lesson_ids: res.data.data.lesson_ids,
              organization_id: organization_id
            }
          },
          () => {
            this.setIdealPath()
          }
        )
      })
  }

  setIdealPath = () => {
    var ids = new Set(this.state.paths.chosen.map(d => d.code))
    var merged = [
      ...this.state.paths.chosen,
      ...this.state.paths.ideal.filter(d => !ids.has(d.code))
    ]
    const formattedData = merged.map(item => {
      const isIdealPath = this.state.paths.ideal.find(e => e.code === item.code)
      const chosenPath = this.state.paths.chosen.find(e => e.code === item.code)
      if (isIdealPath && chosenPath) {
        return { ...item, pathType: 'CHOSEN_IDEAL' }
      } else if (isIdealPath) {
        return { ...item, pathType: 'IDEAL' }
      }
      return item
    })

    const treeData = getNestedChildren(formattedData)
    this.setState(
      { idealPathData: treeData[0], isReportLoading: false },
      () => {
        let cn = document.querySelectorAll('.p-footer__item')[0]
        if (cn) {
          let btnBack = document.createElement('button')
          btnBack.innerHTML = 'Back to Dashboard'
          btnBack.classList.add('back_to_dashboard')
          btnBack.onclick = () => {
            const { recommendedCourses } = this.state
            console.log('recommendedCourses log', recommendedCourses)
            if (recommendedCourses.lesson_ids.length !== 0) {
              api.maze.recommendedCourse(recommendedCourses).then(res => {
                console.log('response recommendedCourse', res)
              })
            }
            this.props.history.push('/training')
          }
          cn.appendChild(btnBack)
        }
        this.translateTreeToCenter()
      }
    )
  }

  translateTreeToCenter = () => {
    const dimensions = this.treeContainer.getBoundingClientRect()
    this.setState({
      translate: {
        x: dimensions.width / 5,
        y: dimensions.height / 2
      }
    })
  }

  getPathClass = ({ source, target }) => {
    if (
      source.data.pathType === 'CHOSEN_IDEAL' &&
      target.data.pathType === 'CHOSEN_IDEAL'
    ) {
      return 'user_right_path'
    } else if (source.data.pathType && target.data.pathType) {
      return 'ideal_path_link'
    }
    return 'wrong_path_link'
  }

  showPopup = (evt, data) => {
    const mypopup = document.getElementById('mazeReportTooltip')
    mypopup.innerHTML = `<div><h3>${data.type}</h3><p>${data.content ||
      'N/A'}</p></div>`
    mypopup.style.display = 'block'
    mypopup.style.left = evt.pageX - 50 + 'px'
    mypopup.style.top = evt.pageY - 50 + 'px'
  }

  hidePopup = () => {
    const mypopup = document.getElementById('mazeReportTooltip')
    mypopup.style.display = 'none'
  }

  renderRectSvgNode = params => {
    const { nodeDatum } = params
    return (
      <>
        <g>
          <circle
            r="40"
            pointerEvents="all"
            stroke="#E9E9E9"
            stroke-width="3"
            fill={nodeDatum.type === 'Option' ? '#605890' : '#0967c0'}
            x="-10"
            id="myicon"
            onMouseOver={evt => this.showPopup(evt, nodeDatum)}
            onMouseOut={this.hidePopup}
          />
          <text
            strokeWidth="1"
            id="thingyouhoverover"
            x="-20"
            y="70"
            font-size="14"
          >
            {nodeDatum.type}
          </text>
        </g>
      </>
    )
  }

  isBuildLoadedFunc = param => {
    console.log('unityContext param: ', param)
    setTimeout(() => { this.setState({
      isBuildLoaded: true,
      unityContext: param
    }) }, 5000);    
  }

  isPercentageLoader = val => {
    this.setState({
      percentageLoader: val
    })
  }

  pollingIsCompleted = () => {
    const { token, playData } = this.state
    const {
      match: {
        params: { id }
      }
    } = this.props
    const requestOptionsStatus = {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token
      }
    }
    const statusAPI =
      config.baseURL + `programme/${id}/play/${playData.id}/status`
    fetch(statusAPI, requestOptionsStatus)
      .then(response => response.json())
      .then(data => {
        console.log('LOG response status', data)
        if (data && data.data && data.data.is_completed) {
          console.log('Hey status arrived', data.data.is_completed)
          clearInterval(this.intervalId)
          let footerTakeMaze = document.getElementById('footer-take-maze')
          if (footerTakeMaze) {
            footerTakeMaze.style.visibility = 'visible'
          }

          let btnTakeMaze = document.getElementById('btn-take-maze')
          if (btnTakeMaze) {
            btnTakeMaze.style.visibility = 'hidden'
          }

          // this.setState(
          //   {
          //     isMazeComplete: true,
          //     isReportLoading: true
          //   },
          //   () => {
          //     this.getDataForGraph()
          //   }
          // )
        }
      })
      .catch(() => {})
  }

  onClickTakeMaze = () => {
    this.intervalId = setInterval(() => {
      this.pollingIsCompleted()
    }, 5000)

    this.setState(
      {
        isPlayLimited: false,
        isShowCover: false,
        activateTTS: true
      },
      () => {
        let footerTakeMaze = document.getElementById('footer-take-maze')
        footerTakeMaze.style.visibility = 'hidden'
        // const unityViewDiv = document.getElementById('unity-canvas-1')
        // unityViewDiv.style.width = '1200px'
        // unityViewDiv.style.height = '720px'
        if (this.mazeViewRef.current) {
          //this.mazeViewRef.current.style.display = 'contents';
        }
      }
    )
  }

  onClickAlertMessage = () => {
    this.setState({
      isPlayLimited: false
    })
  }

  render() {
    const {
      isMazeComplete,
      isReportLoading,
      idealPathData,
      isPlayLimited,
      token,
      playData,
      isShowCover,
      isBuildLoaded,
      percentageLoader,
      activateTTS
    } = this.state

    const {
      user: {
        isFetchingUserAttributes,
        info: { isAdmin, isSuperAdmin }
      },
      maze: { fetching, mazeById },
      t,
      history,
      location
    } = this.props

    const skillJourney = location.state && location.state.skillJourney
    const skillJourneyId = location.state && location.state.skillJourneyId

    const mazeID =
      !_.isEmpty(playData) && playData.programme && playData.programme.id

    if (fetching || isFetchingUserAttributes) {
      return <Spinner height="70vh" />
    }

    console.log("playData in detail page: ", playData.id)
    return (
      <Fragment>
        {!fetching && _.isEmpty(mazeById) && (
          <EmptyMessage
            title={t('v2:sorry') + '' + t('v4:no_maze_found')}
            buttonTitle={t('general:back_to') + ' ' + t('navigation:training')}
            onClickAction={() => history.push('/training')}
          />
        )}

        {!fetching && !_.isEmpty(mazeById) && (
          <>
            <TopPageLink
              page={skillJourney ? t('v4:skill') : t('navigation:training')}
              path={
                skillJourney ? `/skill-journey/${skillJourneyId}` : '/training'
              }
            />
            <section
              className="section eve-wrap maze-wrap"
              style={{ height: 'auto' }}
            >
              <div ref={this.mazeViewRef} className="maze-view">
                <div className="maze-view__cover">
                  <div className="maze-view__heading">
                    {mazeById && mazeById.title}
                  </div>

                  {isShowCover && isBuildLoaded && mazeById && mazeById.cover && (
                    <div
                      className="maze-cover"
                      style={{
                        backgroundImage: `url(${mazeById.cover.link})`
                      }}
                    />
                  )}

                  {isPlayLimited && isBuildLoaded && (
                    <Alert
                      message={t('v4:maze_self_limit_reached')}
                      description={
                        isAdmin || isSuperAdmin
                          ? t('v4:metamaze_two_times')
                          : t('v4:metamaze_two_times_no_result')
                      }
                      type="warning"
                      closable
                      showIcon
                    />
                  )}

                  {isReportLoading && (
                    <div className="maze-view__loading">
                      <img
                        alt=""
                        id="shl-animate-logo"
                        className="shl-animate-logo"
                        src={loadingLogo}
                      />
                      <h2
                        style={{
                          width: '100%',
                          textAlign: 'center',
                          marginTop: '1.5rem'
                        }}
                      >
                        {/* {!isReportLoading && !isBuildLoaded && <SentenceLoader />} */}
                        {isReportLoading && 'Generating graph...'}
                      </h2>
                    </div>
                  )}

                  {!isBuildLoaded && (
                    <div className="maze-view__loading">
                      <img
                        alt=""
                        id="shl-animate-logo"
                        className="shl-animate-logo"
                        src={loadingLogo}
                      />
                      <h2
                        style={{
                          width: '100%',
                          textAlign: 'center',
                          marginTop: '1.5rem'
                        }}
                      >
                        <SentenceLoader percentageLoader={percentageLoader} />
                      </h2>
                    </div>
                  )}

                  {!isMazeComplete &&
                    mazeById &&
                    !_.isEmpty(playData) &&
                    mazeID &&
                    token &&
                    config.baseURL && (
                      <div
                        id="unityView"
                        style={{
                          visibility: isShowCover ? 'hidden' : 'visible',
                          height: isShowCover ? '0' : 'inherit',
                          overflow: 'hidden',
                          marginBottom: isShowCover ? '0' : '1rem'
                        }}
                      >
                        <UnityView
                          token={token}
                          avatarVoice={mazeById.avatar_voice}
                          avatarGender={mazeById.avatar.avatar_gender}
                          mazeID={mazeID}
                          baseURL={config.baseURL}
                          isBuildLoadedFunc={this.isBuildLoadedFunc}
                          isPercentageLoader={this.isPercentageLoader}
                          activateTTS={activateTTS}
                          playID={playData.id}
                        />
                      </div>
                    )}

                  {/* {isMazeComplete && !isReportLoading && (
                    <div className="maze-view__result">
                      <div
                        ref={tc => (this.treeContainer = tc)}
                        style={{ background: 'white' }}
                        className="black_outline"
                      >
                        {idealPathData && (
                          <Tree
                            data={idealPathData}
                            pathClassFunc={this.getPathClass}
                            collapsible={false}
                            translate={this.state.translate}
                            renderCustomNodeElement={this.renderRectSvgNode}
                            zoom={0.65}
                            // separation={{ siblings: 3, nonSiblings: 3 }}
                          />
                        )}
                        <div id="mazeReportTooltip"></div>
                      </div>
                      <div className="legend-container">
                        <div className="legend-item">
                          <div
                            className="legend-item__shape thick__line"
                            style={{ backgroundColor: 'teal' }}
                          ></div>
                          Ideal Path taken by user
                        </div>
                        <div className="legend-item">
                          <div
                            className="legend-item__shape"
                            style={{ backgroundColor: 'green' }}
                          ></div>
                          Ideal Path
                        </div>
                        <div className="legend-item">
                          <div
                            className="legend-item__shape"
                            style={{ backgroundColor: 'red' }}
                          ></div>
                          User Incorrect Path
                        </div>
                        <div className="legend-item">
                          <div
                            className="legend-item__shape"
                            style={{
                              backgroundColor: '#0967c0',
                              height: '20px',
                              borderRadius: '50%'
                            }}
                          ></div>
                          Scenario
                        </div>
                        <div className="legend-item">
                          <div
                            className="legend-item__shape"
                            style={{
                              backgroundColor: '#605890',
                              height: '20px',
                              borderRadius: '50%'
                            }}
                          ></div>
                          Option
                        </div>
                      </div>
                    </div>
                  )} */}
                </div>
                <div className="maze-view__sidebar">
                  <div className="event__tabs" style={{ position: 'relative' }}>
                    <Tabs defaultActiveKey="1">
                      <Tabs.TabPane tab={t('tabs:host')} key="1">
                        {mazeById && mazeById.host && (
                          <div className="hosts">
                            <div className="hosts__list">
                              <div className="hosts__item">
                                <div className="host">
                                  <div
                                    className="host__thumb"
                                    style={{
                                      backgroundImage: `url(${
                                        mazeById.host.image
                                          ? mazeById.host.image.link
                                          : DummyUserImage
                                      })`
                                    }}
                                  />
                                  <div className="host__name">
                                    {mazeById.host.name}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </Tabs.TabPane>
                      <Tabs.TabPane tab={t('tabs:description')} key="2">
                        {(mazeById &&
                          mazeById.host &&
                          mazeById.host.description) ||
                          'N/A'}
                      </Tabs.TabPane>
                    </Tabs>
                  </div>
                </div>
              </div>

              <div className="p-footer" id="footer-take-maze">
                <div className="p-footer__inner">
                  <div className="p-footer__item">
                    <Button
                      id="btn-take-maze"
                      onClick={() => this.onClickTakeMaze()}
                      type="primary"
                      className="rounded"
                      size="large"
                      disabled={
                        !isBuildLoaded ||
                        (isPlayLimited && !isAdmin && !isSuperAdmin)
                      }
                    >
                      {!isBuildLoaded ? t('v4:please_wait') : t('v4:take_maze')}
                    </Button>
                  </div>
                </div>
              </div>
            </section>
          </>
        )}
      </Fragment>
    )
  }
}

export default withTranslation('')(MazeDetail)
