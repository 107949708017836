import React, { Component, Fragment } from 'react'

import MazeTopPanel from './components/MazeTopPanel'
import MazeRoutes from './routes'

export default class Create extends Component {
  render() {
    const mode = 'create'
    return (
      <Fragment>
        <MazeTopPanel mode={mode} />
        <MazeRoutes mode={mode} />
      </Fragment>
    )
  }
}
