import React, { Fragment } from 'react'
import className from 'classnames'
import moment from 'moment'
import { Card } from './styled'
import { Icon, Menu, Dropdown, Button } from 'antd'
import './ImageCard.scss'
import { withTranslation } from 'react-i18next'
import { general } from '../../../../../../../../../constants'
import NORMAL_AR from '../../../../../../../../../assets/iconNormalAR.png'
import INTERACTIVE_AR from '../../../../../../../../../assets/iconInteractiveAR.png'

const menu = (props, data) => (
  <Menu>
    {/*<Menu.Item>
      <a target="_blank" rel="noopener noreferrer" href="">
        Rename
      </a>
    </Menu.Item>*/}
    <Menu.Item>
      <span
        style={{ color: '#1890ff', cursor: 'pointer' }}
        onClick={() => props.onEditImageDetail(data.id)}
        href="#"
      >
        {props.t('media:edit_detail')}
      </span>
    </Menu.Item>
    <Menu.Item>
      <span
        style={{ color: '#1890ff', cursor: 'pointer' }}
        onClick={() => props.confirmDeleteItem({ data: data })}
        href="#"
      >
        {props.t('media:delete')}
      </span>
    </Menu.Item>
  </Menu>
)

const ImageCard = props => {
  console.log("CHECK POINT FOR AR data", props.data)
  const {
    data,
    type,
    cardSelected,
    onDoubleClick,
    handleViewDetails,
    data: { title, filename, horizontal, created_at, id, link, is_interactive }
  } = props

  // const imgLink = isAR ? link : resizes && resizes.medium

  const classNames = className(
    'card-image',
    { card_horizontal: horizontal },
    { 'card_on-select': cardSelected }
  )
  return (
    <Fragment>
      <div className={classNames}>
        <div
          className="card__image-thumb"
          onClick={() => handleViewDetails(id, data)}
          onDoubleClick={() => onDoubleClick(id, data)}
        >
          <Card.ImageIcon cardSelected={cardSelected}>
            {/*<Card.Icon>
              {(is_synthesized_audio &&
                <img
                  src={cardSelected ? activeSynthesizedAudio : deactiveSynthesizedAudio}
                  alt=""
                />
              )}
              {(!is_synthesized_audio &&
                <img
                  src={cardSelected ? activateSoundIcon : deactiveSoundIcon}
                  alt=""
                />
              )}

            </Card.Icon>*/}

            <div className="wrap-icon">
              <img className="img-fit-into-card" src={link} alt="" />
              <span className="icon"><img src={is_interactive ? INTERACTIVE_AR : NORMAL_AR } alt="" /></span>
            </div>
          </Card.ImageIcon>
        </div>
        <div className="card__content">
          <Fragment>
            {type === general.MY_MEDIA_LIBRARY && (
              <Dropdown overlay={menu(props, data)} placement="bottomLeft">
                <Button>
                  <Icon type="more" />
                </Button>
              </Dropdown>
            )}

            <Card.ImageInfo>
              <div
                className="card__title"
                title={title || filename}
                onClick={() => handleViewDetails(id, data)}
              >
                {title || filename}
              </div>
              {moment(created_at).format('DD MMM YYYY')}
            </Card.ImageInfo>
          </Fragment>
        </div>
      </div>
    </Fragment>
  )
}

export default withTranslation('image')(ImageCard)
