/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-06-19 10:54:34
 * @LastEditTime: 2019-06-19 10:54:34
 * @LastEditors: your name
 */
import styled, { css } from 'styled-components'
import { Modal } from 'antd'

import { styleguide } from '../../constants'

const { colors } = styleguide

const AntModal = styled(Modal)`
  .anticon svg {
    width: 1.8rem;
    height: 1.8rem;
  }

  ${props => props.withFooter && css`
    .ant-modal-body {
      position: relative;
      padding-bottom: 5rem;
    }
  `}

  .ant-modal-header {
    border-bottom: 0;
    padding: 2rem 2.5rem 0 2.5rem;
  }

  .ant-modal-title {
    color: ${colors.darkGreyOne};
    font-size: 1.8rem;
    font-weight: 400;
  }

  .ant-modal-content {
    border-radius: 0;
  }
`

export {
  AntModal
}
