import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react'
import _ from 'lodash'
import {
  useMeetingManager,
  useLocalVideo,
  useToggleLocalMute,
  useContentShareControls,
  useContentShareState,
  // useRemoteVideoTileState,
  useSelectVideoQuality,
  useMeetingStatus,
  useRosterState,
  useSelectAudioInputDevice
} from 'amazon-chime-sdk-component-library-react'

import debounce from 'lodash/debounce'
import { Prompt } from 'react-router-dom'

import { Modal, message, Button } from 'antd'
import Chime from './Chime'
import './chimestyles.scss'
import http from '../../../../../services/api/http'
import { api } from '../../../../../services'
import { AddParticipant } from '../AntMediaService/internal'
import { config } from '../../../../../constants'
import BreakoutForm from '../BreakoutForm'
import EventMenus from '../EventMenus'

let initSocketConf = null
let connectionInterval = null
let inConferenceInterval = null
let prompt = null
let meetingEvents = []
let whiteboard = false

function ChimeConference(props) {
  const meetingManager = useMeetingManager()
  const reqURL = `events/${props.eventId}/chime/`
  const eventTitle = `event_${props.eventId}`
  const OBSERVER_API_ENDPOINT_URL = config.observerURL

  const toggleVideoRef = useRef(null)
  const toggleAudioRef = useRef(null)
  const { isVideoEnabled, toggleVideo } = useLocalVideo()
  const { muted, toggleMute } = useToggleLocalMute()
  const { toggleContentShare } = useContentShareControls()
  // const { tiles } = useRemoteVideoTileState()
  const selectAudioInput = useSelectAudioInputDevice()
  const { isLocalUserSharing, sharingAttendeeId } = useContentShareState()
  const { roster } = useRosterState()
  const selectVideoQuality = useSelectVideoQuality()
  const meetingStatus = useMeetingStatus()
  const [setSearchTerm] = useState('')
  const [confErrorMsg, setConfErrorMsg] = useState('')

  const [creatingBreakout, setCreateBreakout] = useState(false)
  const [showBreakout, setShowBreakout] = useState(false)
  const [breakoutRoom, setBreakoutRoom] = useState(null)
  const [rooms, setRooms] = useState([
    {
      title: '',
      is_recording: false,
      is_transcribed: false,
      transcribe_locale: '',
      is_ai_enabled: false
    }
  ])
  const [errors, setErrors] = useState([{ title: '', error_msg_ai: '' }])
  const [isLoading, setLoading] = useState(false)
  const [showVideo, setShowVideo] = useState(false)
  const [showPrompt, setShowPrompt] = useState(false)
  const [isJoined, setIsJoined] = useState(false)
  const [showParticipant, setShowParticipant] = useState(false)
  const [successInvitation, setSuccessInvitation] = useState(false)
  const [failInvitation, setFailInvitation] = useState(false)
  const [confFailed, setConfFailed] = useState(false)
  const [emailList, setEmailList] = useState([])
  const [initialEmails, setInitialEmails] = useState([])
  const [emails, setEmails] = useState([])
  const [inviting, setInviting] = useState(false)
  const [fetchingEmail, setFetchingEmail] = useState(false)
  const [canShowModal, setShowModal] = useState(false)
  const [canShowEndModal, setShowEndModal] = useState(false)

  const [videoTiles, setVideoTiles] = useState(false)
  const [participants, setParticipants] = useState(props.event.hosts)
  const [isGridActive, setIsGridActive] = useState(false)
  const [eventStartTime, setEventStartTIme] = useState(null)

  const [isWhiteboardSharing, setIsWhiteboardSharing] = useState(false)
  const [whiteboardRequest, setWhiteboardRequest] = useState(0)
  const [whiteboardResponse, setWhiteboardResponse] = useState(0)
  const [wbConsumerId, setWbConsumerId] = useState(0)

  const attendees = Object.values(roster)

  useEffect(() => {
    const { hostEmails, isHandRaised, App, userId } = props
    setInitialEmails(hostEmails)
    setEmails(hostEmails)
    return () => {
      if (prompt) {
        prompt = null
      }
      if (connectionInterval !== null) {
        clearInterval(connectionInterval)
        connectionInterval = null
      }

      if (isHandRaised) {
        App.declineToAsk(userId)
      }
    }
  }, [])

  // to unsubs the topic after close whiteboard
  useEffect(() => {
    if (!isWhiteboardSharing) {
      unsubscribeMessage(props.eventId)
    }
  }, [isWhiteboardSharing])

  useEffect(() => {
    if (roster && attendees && attendees.length > 0) {
      const {
        streamCount,
        App: { onParticipants }
      } = props
      let attendeeNum = 0
      attendees.forEach(attendee => {
        const { externalUserId } = attendee
        var nameIndex = externalUserId.indexOf('recording#User')
        // const name = externalUserId.split("#")[0];
        attendeeNum = nameIndex === -1 ? attendeeNum + 1 : attendeeNum
        console.log(
          'chime participant',
          nameIndex,
          'externalUserId',
          externalUserId,
          'attendeeNum',
          attendeeNum
        )
      })
      streamCount(attendeeNum)

      onParticipants({
        roster: roster,
        attendees: attendees.filter(
          item => !item.externalUserId.includes('recording#User')
        ),
        streamCount: attendeeNum,
        hostEmails: props.hostEmails
      })
    }
  }, [roster])

  useEffect(() => {
    const { App, isConferenceHost, isHandRaised, userId } = props
    if (isJoined && !isLoading) {
      if (!isConferenceHost && isHandRaised) {
        App.declineToAsk(userId)
      }
    }
  }, [isLoading, isJoined])

  useEffect(() => {
    const { userOrg } = props
    setFetchingEmail(true)
    api.organizations.getUsersByOrganizationId(userOrg).then(res => {
      const allEmails = res.data
        .map(val => {
          const userOrg = val.user
          const dataValue = {
            text: userOrg && userOrg.email,
            value: userOrg && userOrg.email
          }
          return dataValue
        })
        .filter(item => item.value !== null)

      setEmailList(allEmails)
      setFetchingEmail(false)
    })
  }, [])

  useEffect(() => {
    if (isVideoEnabled) {
      selectVideoQuality('360p')
    }
  }, [isVideoEnabled])

  // When event timer start time is received
  useEffect(() => {
    if (eventStartTime) {
      console.log('event time is changed in effect')
      props.onEventTime(eventStartTime)
    }
  }, [eventStartTime])

  // When join button is clicked
  useEffect(() => {
    if (props.onJoin) {
      startEvent()
    }
  }, [props.onJoin])

  // When invite button is clicked in participants
  useEffect(() => {
    if (props.App.state.isInvite) {
      if (
        event &&
        event.event_type === 'webinar' &&
        initialEmails &&
        initialEmails.length >= 5
      ) {
        message.error(
          'You have reached the maximum number of participants for this event'
        )
        if (props.App.onInvite) {
          props.App.onInvite()
        }
      } else {
        showAddparticipant()
      }
    }
  }, [props.App.state.isInvite])

  useEffect(() => {
    if (meetingStatus === 2 || meetingStatus === 3) {
      const { isConfCreator, onStreamStopped, stopstream } = props
      if (!isConfCreator) {
        leaveEvent()
        onStreamStopped()
        stopstream()
      }
    }
  }, [meetingStatus])

  useEffect(() => {
    if (inviting) {
      const { event, eventId } = props
      const newAdded = _.difference(emails, initialEmails)

      const data = {
        title: event.title,
        add_participant: newAdded,
        cover_id: event.cover_id
      }
      api.events
        .updateById(eventId, data)
        .then(response => {
          const { data: respData } = response
          initSocketConf.send(
            JSON.stringify({
              action: 'updateParticipant',
              channel_type: 'EVENT',
              channel_id: eventId,
              participants: respData.hosts
            })
          )
          setInviting(false)
          setSuccessInvitation(true)
          setInitialEmails(respData.host_emails)
          showAddparticipant()
        })
        .catch(() => {
          setInviting(false)
          setFailInvitation(true)
          setEmails(emails)
          setTimeout(() => {
            setFailInvitation(false)
          }, 3000)
        })
    }
  }, [inviting])

  useEffect(() => {
    if (!props.isConferenceHost && props.userId) {
      console.log('inside passive here')
      if (props.allowedUser && props.allowedUser.length === 0) {
        console.log('inside if')
        selectAudioInput(null)
      }
      if (
        props.allowedUser &&
        props.allowedUser.length > 0 &&
        props.allowedUser.includes(props.userId)
      ) {
        console.log('inside else', props.userId)
        selectAudioInput('default')
      }
    }
  }, [props.allowedUser])

  useEffect(() => {
    if (props.App.state.createRoom) {
      if (isJoined && isConfCreator && has_breakout_room) {
        showBreakoutForm()
      }
    }
  }, [props.App.state.createRoom])

  // To check whiteboard status when a user joined a meeting after it's started
  useEffect(() => {
    const { eventId } = props

    try {
      meetingManager.audioVideo.realtimeSubscribeToReceiveDataMessage(
        `${eventId}-${userId}`,
        data => {
          data = JSON.parse(new TextDecoder().decode(data.data))

          if (data.state === 'response' && data.wbStatus) {
            setIsWhiteboardSharing(true)
            setWhiteboardResponse(userId)
          } else if (!data.wbStatus) {
            setIsWhiteboardSharing(false)
          }
        }
      )

      /*
      initSocketConf.send(
        JSON.stringify({
          action: 'requestWhiteboardStatus',
          channel_id: eventId,
          userId
        })
      )
      */
    } catch (err) {
      console.log(err)
    }
  }, [props.onJoin, meetingManager.audioVideo])

  // To inform recording for whiteboard changes(open/close)
  useEffect(() => {
    const { event, eventId } = props
    if (event.is_recording && meetingManager.audioVideo && isConferenceHost) {
      if (isWhiteboardSharing) {
        sendMessage(
          `${eventId}-recording`,
          {
            state: 'response',
            wbStatus: true
          },
          50000
        )
      } else {
        sendMessage(
          `${eventId}-recording`,
          {
            state: 'response',
            wbStatus: false
          },
          50000
        )
      }
    }
  }, [isWhiteboardSharing])

  const startEvent = async () => {
    // Fetch the meeting and attendee data from your server

    const {
      userId,
      username,
      handleBroadcastChange,
      isConferenceHost,
      allowedUser,
      onStreamStopped,
      stopstream,
      eventId
    } = props

    setLoading(true)
    setIsJoined(true)
    setShowVideo(true)
    setShowPrompt(true)
    handleBroadcastChange()

    const { event } = props

    const payload = {
      title: eventTitle,
      name:
        isConferenceHost || event.encoder === 'live_assessment'
          ? `${username}#${userId}`
          : `passive${username}#${userId}`,
      region: 'us-east-1'
    }

    console.log('payload', payload)

    //api call
    const response = await http({
      method: 'PUT',
      url: `${reqURL}join`,
      data: payload
    }).catch(e => {
      console.log('Error while join: ', e.message)
      setConfFailed(true)
      setConfErrorMsg(e.message)
      onStreamStopped()
      stopstream()
      setShowModal(false)
      setShowVideo(false)
      setShowPrompt(false)
      setIsJoined(false)
      setVideoTiles(false)
      setIsWhiteboardSharing(false)
      prompt = null
      handleBroadcastChange()
      return
    })

    console.log('join response', response)

    if (!response) return

    if (response) {
      const { Meeting, Attendee } = response && response.data.JoinInfo
      var joinData = {
        meetingInfo: Meeting,
        attendeeInfo: Attendee
      }
    }

    // Use the join API to create a meeting session
    await meetingManager.join(joinData)

    handleMeetingEvents()
    // At this point you can let users setup their devices, or start the session immediately
    await meetingManager.start()
    setLoading(false)
    initializeSocket()
    setVideoTiles(true)
    inConferenceInterval = setInterval(() => {
      initSocketConf.send(
        JSON.stringify({
          action: 'inConference',
          userId: userId,
          channel_id: eventId,
          channel_type: 'EVENT'
        })
      )
    }, 60000)

    if (!isConferenceHost && allowedUser && allowedUser.length === 0) {
      console.log('audio on default')
      selectAudioInput(null)
    }

    if (isConferenceHost) {
      toggleVideoRef.current && toggleVideoRef.current.click()
    }
  }

  const sendMessage = (topic, data, lifetimeMs) => {
    meetingManager.audioVideo.realtimeSendDataMessage(topic, data, lifetimeMs)
  }

  const unsubscribeMessage = topic => {
    try {
      meetingManager.audioVideo.realtimeUnsubscribeFromReceiveDataMessage(topic)
    } catch (err) {
      console.log(err)
    }
  }

  const leaveEvent = async () => {
    const { handleBroadcastChange, onLeaveEvent, userId, isHandRaised } = props

    // if (parent_breakout_room) {
    //   console.log('breakout ma --------');
    //   history.push(`/events/${parent_breakout_room}`)
    // }

    // console.log('breakout bahira --------');
    if (isHandRaised) {
      App.declineToAsk(userId)
    }
    App.onHeaderItemClick()
    setConfFailed(false)
    setConfErrorMsg('')
    onLeaveEvent()
    setShowModal(false)
    setShowVideo(false)
    setShowPrompt(false)
    setIsJoined(false)
    setVideoTiles(false)
    prompt = null
    stopSocket()
    handleEndMeetingEvents()
    checkCondition()
    await meetingManager.leave()
    await handleBroadcastChange()
    if (inConferenceInterval) {
      clearInterval(inConferenceInterval)
      inConferenceInterval = null
    }
  }

  const endEvent = async () => {
    const { handleBroadcastChange, endChimeEvent, App } = props

    const payload = {
      title: eventTitle
    }
    App.onHeaderItemClick()
    setShowEndModal(false)
    setShowModal(false)
    setShowVideo(false)
    prompt = null
    setShowPrompt(false)
    setIsJoined(false)
    setVideoTiles(false)
    sendWhiteboardStatus(false)
    await handleBroadcastChange()
    await http({
      method: 'PUT',
      url: `${reqURL}end`,
      data: payload
    })
    stopSocket()
    handleStopApi()
    setTimeout(() => {
      handleEndMeetingEvents()
      endChimeEvent()
      checkEndCondition()
    }, 2000)
    if (inConferenceInterval) {
      clearInterval(inConferenceInterval)
      inConferenceInterval = null
    }
  }

  const checkCondition = () => {
    const {
      event: { parent_breakout_room }
    } = props
    console.log('checkCondition', parent_breakout_room)
    if (parent_breakout_room) {
      window.location.replace(`/events/${parent_breakout_room}`)
      // history.push(`/events/${parent_breakout_room}`)
    }
    // leaveEvent()
  }

  const checkEndCondition = () => {
    const {
      event: { parent_breakout_room }
    } = props
    console.log('checkEndCondition', parent_breakout_room)
    if (parent_breakout_room) {
      window.location.replace(`/events/${parent_breakout_room}`)
      // history.push(`/events/${parent_breakout_room}`)
    }
    // endEvent()
  }

  const toggleModal = () => {
    setShowModal(!canShowModal)
  }

  const toggleEndModal = () => {
    setShowEndModal(!canShowEndModal)
  }

  const handleMeetingEvents = () => {
    meetingManager.audioVideo.addObserver({
      eventDidReceive: (name, attributes) => {
        const { meetingHistory, ...otherAttributes } = attributes
        switch (name) {
          case 'audioInputFailed':
          case 'videoInputFailed':
          case 'meetingStartFailed':
          case 'meetingFailed':
            meetingEvents.push({
              name,
              attributes: {
                ...otherAttributes,
                meetingHistory: meetingHistory.filter(({ timestampMs }) => {
                  return Date.now() - timestampMs < 5 * 60 * 1000
                })
              }
            })
            setConfFailed(true)
            if (name === 'meetingFailed') {
              setConfErrorMsg(
                'Unstable Internet Connection. Trying To Connect.'
              )
            } else if (name === 'audioInputFailed') {
              setConfErrorMsg(
                'Please Confirm Your Audio Device Working Properly.'
              )
            } else if (name === 'videoInputFailed') {
              setConfErrorMsg('Please Confirm Your Camera Working Properly.')
            } else if (name === 'meetingStartFailed') {
              setConfErrorMsg(
                'Failed To Join The Meeting. Please Try To Rejoin.'
              )
            } else {
              setConfErrorMsg('')
            }
            handleEndMeetingEvents()
            break
          default:
            meetingEvents.push({
              name,
              attributes: otherAttributes
            })
            setConfFailed(false)
            setConfErrorMsg('')
            break
        }
      }
    })
  }

  const handleEndMeetingEvents = () => {
    if (meetingManager && meetingManager.audioVideo) {
      meetingManager.audioVideo.addObserver({
        audioVideoDidStop: () => {
          setTimeout(() => {
            if (meetingEvents.length > 0) {
              fetch(`${OBSERVER_API_ENDPOINT_URL}/meetingevents`, {
                method: 'POST',
                body: JSON.stringify(meetingEvents)
              })
              meetingEvents = []
            }
          }, 0)
        }
      })
    }
  }

  const handleStopApi = () => {
    const { eventId, onStreamStopped, stopstream } = props
    api.events
      .stopLiveEvent(eventId)
      .then(res => {
        if (res.status === 200) {
          onStreamStopped()
          stopstream()
        }
      })
      .catch(err => {
        console.log('error in stopping event', err)
      })
  }

  const showAddparticipant = () => {
    setShowParticipant(!showParticipant)
    setSuccessInvitation(false)
    if (showParticipant) {
      props.App.onInvite()
    }
  }

  const sendInvitations = () => {
    setInviting(true)
  }

  const fetchUserEmails = useCallback(
    debounce(value => {
      setSearchTerm(value)
      setEmailList([])
      setFetchingEmail(true)
    }, 1000),
    []
  )

  const handleEmailChange = selectedValue => {
    // setEmailList([])
    setEmails(selectedValue)
    // setFetchingEmail(false)
  }

  const fullScreenMode = () => {
    let elem = document.getElementById('chime_fullScreen')
    if (elem && elem.requestFullscreen) {
      elem.requestFullscreen()
    } else if (elem && elem.mozRequestFullScreen) {
      /* Firefox */
      elem.mozRequestFullScreen()
    } else if (elem && elem.webkitRequestFullscreen) {
      /* Chrome, Safari & Opera */
      elem.webkitRequestFullscreen()
    } else if (elem && elem.msRequestFullscreen) {
      /* IE/Edge */
      elem.msRequestFullscreen()
    }
  }

  const initializeSocket = () => {
    const { eventId, userId, userOrg, isConfCreator, isConferenceHost } = props
    initSocketConf = new window.WebSocket(
      `${config.socketURL}?channel_type=EVENT&channel_id=${eventId}`
    )
    initSocketConf.onopen = () => {
      if (isConfCreator) {
        initSocketConf.send(
          JSON.stringify({
            action: 'startConference',
            stream_id: `event_${eventId}`,
            channel_type: 'EVENT',
            channel_id: eventId
          })
        )
      }

      initSocketConf.send(
        JSON.stringify({
          action: 'getEventStartDate',
          channel_type: 'EVENT',
          channel_id: eventId,
          user_id: userId
        })
      )

      initSocketConf.send(
        JSON.stringify({
          action: 'requestWhiteboardStatus',
          channel_id: eventId,
          userId
        })
      )

      initSocketConf.send(
        JSON.stringify({
          action: 'connectStream',
          channel_type: 'EVENT',
          channel_id: eventId,
          user_id: userId,
          org_id: userOrg
        })
      )
      connectionInterval = setInterval(() => {
        initSocketConf.send(
          JSON.stringify({
            action: 'pingPong',
            msg: 'ping'
          })
        )
      }, 20000)
    }

    initSocketConf.onmessage = event => {
      const data = JSON.parse(event.data)
      console.log('event log endConference', event, data)
      if (
        data.event === 'muteUser' &&
        data.content === JSON.stringify(userId)
      ) {
        console.log('event log endConference inside')
        toggleAudioRef.current.click()
      }

      if (data.event === 'updateParticipant') {
        setParticipants(data.participants)
        props.App.addToParticipants(data.participants)
      }

      if (data.event === 'getEventStartDate') {
        console.log('event time received', eventStartTime)
        if (!eventStartTime) {
          console.log('event time inside the not only condition')
          setEventStartTIme(data.message)
        }
      }

      if (data.event === 'sendWhiteboardStatus') {
        console.log(data)
        new Promise(resolve => {
          setWbConsumerId(data.consumerId)
          resolve()
        }).then(() => {
          setIsWhiteboardSharing(data.wbStatus)
        })
        whiteboard = data.wbStatus
      }

      if (data.event === 'requestWhiteboardStatus' && isConferenceHost) {
        if (whiteboard) {
          setWhiteboardRequest(data.userId)
        } else {
          sendMessage(
            `${eventId}-${data.userId}`,
            {
              state: 'response',
              wbStatus: false
            },
            50000
          )
          setWhiteboardRequest(0)
        }
      }
    }

    initSocketConf.onclose = () => {
      console.log('stop web socket ko')
      clearInterval(connectionInterval)
    }

    initSocketConf.onerror = error => {
      console.log('Error ' + error.message)
      clearInterval(connectionInterval)
    }
  }

  const stopSocket = () => {
    const { eventId, userOrg, userId, isConfCreator } = props
    console.log(`initSocketConf`, initSocketConf)
    if (isConfCreator) {
      initSocketConf.send(
        JSON.stringify({
          action: 'stopConference',
          stream_id: `event_${eventId}`,
          channel_type: 'EVENT',
          channel_id: eventId
        })
      )
    }
    initSocketConf.send(
      JSON.stringify({
        action: 'disconnectStream',
        channel_type: 'EVENT',
        channel_id: eventId,
        user_id: userId,
        org_id: userOrg
      })
    )
    clearInterval(connectionInterval)
    connectionInterval = null
  }

  const sendMuteUserId = user => {
    const { eventId } = props

    initSocketConf.send(
      JSON.stringify({
        action: 'muteUser',
        content: user,
        channel_type: 'EVENT',
        channel_id: eventId
      })
    )
  }

  const showBreakoutForm = () => {
    props.App.setState({ createRoom: false })
    setShowBreakout(!showBreakout)
    return
  }

  const setBreakoutRoomCount = value => {
    let newarr = []
    let newErr = []
    if (value === rooms.length) {
      newarr = rooms
      newErr = errors
    } else if (value < rooms.length) {
      newarr = rooms.filter((item, i) => i < value)
      newErr = errors.filter((err, j) => j < value)
    } else {
      newarr = [
        ...rooms,
        ...[...Array(value - rooms.length)].map(() => {
          const roomsObj = {
            title: '',
            is_recording: false,
            is_transcribed: false,
            transcribe_locale: '',
            is_ai_enabled: false
          }
          return roomsObj
        })
      ]
      newErr = [
        ...errors,
        ...[...Array(value - errors.length)].map(() => {
          const errObj = { title: '', error_msg_ai: '' }
          return errObj
        })
      ]
    }
    setBreakoutRoom(value)
    setRooms(newarr)
    setErrors(newErr)
    console.log(value, 'set br here', newarr, 'newErr', newErr)
    return
  }

  const removeRoom = index => {
    const filterRooms = rooms.filter((item, i) => i !== index)
    const filterErr = errors.filter((item, i) => i !== index)
    const roomCount = breakoutRoom - 1
    setBreakoutRoom(roomCount)
    setRooms(filterRooms)
    setErrors(filterErr)
    return
  }

  const handleBreakoutRoom = (index, field, value) => {
    const arrBreakout = rooms
    const newBreakout = arrBreakout.map((item, i) => {
      if (i === index) {
        if (field === 'title' && !value) {
          item[field] = ''
          return item
        }
        if (field === 'title' && value) {
          item[field] = value
          const err = errors.map((e, j) => {
            if (j === index) {
              e.title = ''
            }
            return e
          })
          setErrors(err)
          return item
        }

        if (field === 'is_ai_enabled' && item.transcribe_locale) {
          const cleanLocale =
            item.transcribe_locale !== ''
              ? item.transcribe_locale.split('-')
              : []
          if (!cleanLocale.includes('en')) {
            // item.error_msg_ai = 'AI service available in English only'
            const err = errors.map((e, j) => {
              if (j === index) {
                e.error_msg_ai = 'AI service available in English only'
              }
              return e
            })
            setErrors(err)
            item[field] = value
            return item
          }
        }

        item[field] = value
        return item
      }
      return item
    })
    setRooms(newBreakout)
    return
  }

  const handleCheckChange = (index, field, value) => {
    console.log('is recording value', index, field, value)
    const arrBreakout = rooms
    const newBreakout = arrBreakout.map((item, i) => {
      if (i === index) {
        if (!value) {
          item.is_transcribed = false
          item.transcribe_locale = ''
          item.is_ai_enabled = false
        }
        item.is_recording = value
      }
      return item
    })
    setRooms(newBreakout)
    return
  }

  const handleChangeLocale = (index, value) => {
    const arrBreakout = rooms
    const newBreakout = arrBreakout.map((item, i) => {
      if (i === index) {
        if (item.is_ai_enabled) {
          const cleanLocale =
            item.is_ai_enabled && value !== '' ? value.split('-') : []
          if (!cleanLocale.includes('en')) {
            // setErrors([...errors, errors[i].error_msg_ai = 'AI service available in English only'])
            const err = errors.map((e, j) => {
              if (j === index) {
                e.error_msg_ai = 'AI service available in English only'
              }
              return e
            })
            setErrors(err)
            item.transcribe_locale = value
            return item
          }
        }
        item.transcribe_locale = value
        // item.error_msg_ai = ''
        setErrors([
          ...errors,
          (errors[index].error_msg_ai = ''),
          (errors[index].title = '')
        ])

        return item
      }
      return item
    })
    setRooms(newBreakout)
    return
  }

  const formValidation = () => {
    if (rooms.length > 0) {
      const test = rooms.map((each, i) => {
        if (each.title === '') {
          const title = errors.map((e, j) => {
            if (j === i) {
              e.title = 'required'
            }
            return e
          })
          setErrors(title)
          return true
        }
        return false
      })
      return test
    }
    return
  }

  const createBreakoutForm = () => {
    const { eventId } = props
    const hasError = formValidation()
    if (hasError.includes(true)) return
    setCreateBreakout(true)
    api.events
      .createBreakoutEvent(eventId, rooms)
      .then(res => {
        console.log('create breakout resp', res)
        if (res.status === 200) {
          message.success(res && res.data)
          setRooms([
            {
              title: '',
              is_recording: false,
              is_transcribed: false,
              transcribe_locale: '',
              is_ai_enabled: false,
              error_msg_ai: ''
            }
          ])
          setShowBreakout(false)
          setCreateBreakout(false)
          setBreakoutRoom(null)
        }
      })
      .catch(err => {
        console.log('error in stopping event', err)
        message.error('Something went wrong.')
        setShowBreakout(false)
        setCreateBreakout(false)
      })
    return
  }

  const sendWhiteboardStatus = value => {
    const { eventId, userId } = props
    initSocketConf.send(
      JSON.stringify({
        action: 'sendWhiteboardStatus',
        wbStatus: value,
        channel_id: eventId,
        consumerId: userId
      })
    )
  }

  const {
    isConferenceHost,
    t,
    isConfCreator,
    allowJoin,
    event: { has_breakout_room },
    organization,
    event,
    App,
    isConferenceParticipant,
    allowedUser: raiseHandAllowed,
    isHandRaised,
    raiseHandRequesting,
    userId,
    count,
    hasBreakoutRoom
  } = props

  console.log(
    'room errors',
    errors,
    'rooms',
    rooms,
    'meetingEvents',
    meetingEvents,
    'showJoinButton',
    allowJoin
  )

  const allParticipants = useMemo(
    () => [event.host, ...(Array.isArray(participants) ? participants : [])],
    [event, participants]
  )

  const isMaxParticipants = useMemo(() => {
    return (
      event &&
      event.event_type === 'webinar' &&
      initialEmails &&
      initialEmails.length >= 5
    )
  }, [event, initialEmails])

  return (
    <div>
      {confFailed && confErrorMsg && message.error(confErrorMsg)}
      {failInvitation && message.error('Please try again.')}
      {successInvitation &&
        message.success(
          'Your invitation sent successfully. Participant(s) will get notification in their email'
        )}

      {showVideo && (
        <>
          <Prompt
            when={showPrompt}
            message={() => {
              prompt = window.confirm(t('events:prompt_message'))
              if (prompt) {
                leaveEvent()
                return prompt
              } else {
                return false
              }
            }}
          />
          <Chime
            participants={allParticipants}
            videoTiles={videoTiles}
            isConfCreator={isConfCreator}
            isConferenceHost={isConferenceHost}
            sendMute={sendMuteUserId}
            loading={isLoading}
            ATTENDEES={attendees}
            userId={userId}
            isGridActive={isGridActive}
            t={t}
            exitGridView={() => setIsGridActive(!isGridActive)}
            isWhiteboardSharing={isWhiteboardSharing}
            sendRealTimeMessage={sendMessage}
            meetingManager={meetingManager}
            eventId={props.eventId}
            whiteboardRequest={whiteboardRequest}
            whiteboardResponse={whiteboardResponse}
            setWhiteboardRequest={setWhiteboardRequest}
            setWhiteboardResponse={setWhiteboardResponse}
            consumerId={wbConsumerId}
            onClickWhiteboardSharing={() => {
              sendWhiteboardStatus(!isWhiteboardSharing)
            }}
          />
        </>
      )}

      {allowJoin && (
        <>
          <div className={`${isJoined ? 'controller-items' : ''}`}>
            {isJoined && (
              <EventMenus
                count={count}
                isAudio={!muted}
                show={App.state.show}
                isVideo={isVideoEnabled}
                videoRef={toggleVideoRef}
                audioRef={toggleAudioRef}
                isGridActive={isGridActive}
                raiseHandAllowed={raiseHandAllowed}
                isHandRaised={isHandRaised}
                raiseHandRequesting={raiseHandRequesting}
                isConfCreator={isConfCreator}
                isRecording={event.is_recording}
                isConferenceHost={isConferenceHost}
                isScreenSharing={isLocalUserSharing}
                isBreakoutRoom={event.has_breakout_room}
                isConferenceParticipant={isConferenceParticipant}
                isRemoteScreenSharing={
                  isJoined && sharingAttendeeId && !isLocalUserSharing
                }
                onLeave={toggleModal}
                onClickMute={toggleMute}
                onClickVideo={toggleVideo}
                onEndForAll={toggleEndModal}
                onScreenShare={toggleContentShare}
                onClickBreakoutRooms={params => {
                  App.onHeaderItemClick(params)
                  App.onBreakoutRoom(isConfCreator && has_breakout_room)
                }}
                onClickParticipants={params => App.onHeaderItemClick(params)}
                onClickFullScreen={fullScreenMode}
                onClickGrid={() => setIsGridActive(!isGridActive)}
                onHandRaise={App.handleRaiseHand}
                onExitHandRaise={() => App.declineToAsk(userId)}
                isWhiteboardSharing={isWhiteboardSharing}
                onClickWhiteboardSharing={() => {
                  sendWhiteboardStatus(!isWhiteboardSharing)
                }}
                eventStartTime={eventStartTime}
                consumerId={wbConsumerId}
                userId={userId}
              />
            )}
          </div>
          {canShowModal && (
            <Modal
              title={t('labels:alert')}
              visible={canShowModal}
              onOk={() => leaveEvent()}
              // leaveEvent()}
              onCancel={toggleModal}
            >
              <h3>
                {hasBreakoutRoom
                  ? t('events:event_breakout_leave')
                  : t('events:event_modal_warn')}
              </h3>
            </Modal>
          )}
          {canShowEndModal && (
            <Modal
              title={t('labels:alert')}
              visible={canShowEndModal}
              // onOk={() => checkEndCondition()}
              onOk={() => endEvent()}
              onCancel={toggleEndModal}
            >
              <h3>
                {hasBreakoutRoom
                  ? t('events:event_breakout_end')
                  : t('events:event_end_warn')}
              </h3>
            </Modal>
          )}
          {showParticipant && !isMaxParticipants && (
            <Modal
              title={t('v2:add_participants')}
              visible={showParticipant}
              onOk={sendInvitations}
              // okText={'Send Invitation(s)'}
              onCancel={showAddparticipant}
              footer={[
                <Button key="back" onClick={showAddparticipant}>
                  {t('buttons:cancel')}
                </Button>,
                <Button
                  key="submit"
                  type="primary"
                  loading={inviting}
                  onClick={sendInvitations}
                >
                  {t('v2:send_invitations')}
                </Button>
              ]}
            >
              <AddParticipant
                emailList={emailList}
                emails={emails}
                fetchUserEmails={fetchUserEmails}
                handleEmailChange={handleEmailChange}
                fetching={fetchingEmail}
                invitedEmails={initialEmails}
                event={event}
                t={t}
              />
            </Modal>
          )}
          {showBreakout && (
            <Modal
              title={t('v2:create_breakout_room')}
              visible={showBreakout}
              onOk={showBreakoutForm}
              width={'60vw'}
              centered
              onCancel={showBreakoutForm}
              footer={[
                <Button
                  key="submit"
                  type="primary"
                  loading={creatingBreakout}
                  onClick={createBreakoutForm}
                  disabled={!breakoutRoom}
                >
                  {t('buttons:create')}
                </Button>
              ]}
            >
              <BreakoutForm
                setBreakoutRoomCount={setBreakoutRoomCount}
                breakoutRoom={breakoutRoom}
                removeRoom={removeRoom}
                handleBreakoutRoom={handleBreakoutRoom}
                handleCheckChange={handleCheckChange}
                handleChangeLocale={handleChangeLocale}
                rooms={rooms}
                errors={errors}
                organization={organization}
              />
            </Modal>
          )}
        </>
      )}
    </div>
  )
}

export default ChimeConference
