import React from 'react'
import { Switch, Router, Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

import history from '../../../history'

import Report from '../Report'

const ReportRouting = ({ userInfo: { info }, isUser }) => {
  const isSuperAdmin = info.is_super_admin
  const isAdmin = info.role && info.role.name === 'Admin'

  const userId = info.id

  const enableShowingReports = !!(
    info.organization && info.organization.enable_data
  )

  console.log({
    isSuperAdmin,
    isAdmin,
    userId,
    enableShowingReports,
    info
  })

  return (
    <Router history={history}>
      <Switch>
        {userId &&
          ((isAdmin && enableShowingReports) || isSuperAdmin ? (
            <Route path="/reports" component={Report} />
          ) : (
            <Redirect
              to={!isUser ? `/reports/user/participant/${userId}` : '/'}
            />
          ))}
      </Switch>
    </Router>
  )
}

export default connect(state => ({ userInfo: state.user }))(ReportRouting)
