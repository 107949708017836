import styled, { css } from 'styled-components'
import { styleguide } from '../../constants'

const { colors } = styleguide

const TagWrapper = styled.span`
  border: 0.1rem solid ${colors.gray};
  border-radius: 2rem;
  color: ${colors.blakGray};
  cursor: pointer;
  display: inline-block;
  font-size: 1.1rem;
  font-weight: 500;
  padding: 0.2rem 0.9rem;
  margin-right: 1rem;
  background: ${colors.gray}
    ${props =>
      props.disabled &&
      css`
        background-color: ${colors.darkGreyTwo};
        color: ${colors.white};
        pointer-events: none;
      `}
    ${props =>
      props.active &&
      css`
        background-color: ${colors.mainPurple};
        color: ${colors.white};
        border: 0.1rem solid ${colors.mainPurple};

        &:hover {
          color: ${colors.white};
        }
      `};
`

export { TagWrapper }
