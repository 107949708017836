/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-08-07 15:55:38
 * @LastEditTime: 2019-08-15 16:10:41
 * @LastEditors: Please set LastEditors
 */
import _ from 'lodash'
import {
  LIBRARY_SUCCESS,
  LIBRARY_FETCHING,
  PUBLIC_SUCCESS,
  PUBLIC_FETCHING,
  PUT_FILE_UPLOAD_PROGRESS,
  CLEAR_UPLOAD_LIST,
  UPLOAD_SUCCESS,
  UPLOAD_FETCHING,
  UPDATE_LIBRARY_STATUS,
  UPDATE_STATUS_UPLOAD,
  HANDLE_MAKE_TYPE,
  DOWNLOAD_SUCCESS,
  DOWNLOAD_FETCHING,
  REMOVE_UPLOAD_SUCCESS,
  ORG_SUCCESS,
  ORG_FETCHING
} from '../types'

const initialState = {
  uploadFetching: false,
  data: {
    count: null,
    rows: []
  },
  publicLibrary: {
    count: null,
    rows: []
  },
  uploadProgress: [],
  uploadSuccess: [],
  updateLibraryStatus: false,
  makeType: null,
  orgLibrary: {
    count: null,
    rows: []
  },
  isFetching: false,
  isOrgFetching: false,
  isPublicFetching: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LIBRARY_SUCCESS:
      return {
        ...state,
        data: action.payload
      }

    case LIBRARY_FETCHING:
      return {
        ...state,
        isFetching: action.payload
      }

    case ORG_FETCHING:
      return {
        ...state,
        isOrgFetching: action.payload
      }

    case DOWNLOAD_SUCCESS:
      return {
        ...state,
        download: action.payload
      }

    case DOWNLOAD_FETCHING:
      return {
        ...state,
        isFetching: action.payload
      }

    case HANDLE_MAKE_TYPE:
      return {
        ...state,
        makeType: action.makeType
      }

    case PUBLIC_SUCCESS:
      return {
        ...state,
        publicLibrary: action.payload
      }

    case PUBLIC_FETCHING:
      return {
        ...state,
        isPublicFetching: action.payload
      }

    case ORG_SUCCESS:
      return {
        ...state,
        orgLibrary: action.payload
      }

    case UPLOAD_SUCCESS:
      const { path, id: imageUploadedId } = action.payload
      const prevUploadProgress = _.map(state.uploadProgress, imgUploadItem => {
        if (imgUploadItem.key === path) {
          imgUploadItem.id = imageUploadedId
        }

        return imgUploadItem
      })

      return {
        ...state,
        uploadProgress: prevUploadProgress,
        uploadSuccess: [...state.uploadSuccess, action.payload]
      }

    case REMOVE_UPLOAD_SUCCESS:
      const fileUid = action.payload
      const newUploadProgress = _.filter(
        state.uploadProgress,
        item => item.fileUid !== fileUid
      )

      return {
        ...state,
        uploadProgress: newUploadProgress
      }

    case UPLOAD_FETCHING:
      return {
        ...state,
        uploadFetching: action.payload
      }

    case PUT_FILE_UPLOAD_PROGRESS:
      const progressIndex = state.uploadProgress.findIndex(
        i => i.uid === action.payload.uid
      )
      if (progressIndex < 0) {
        return {
          ...state,
          uploadProgress: [...state.uploadProgress, action.payload]
        }
      } else {
        return {
          ...state,
          uploadProgress: [
            ...state.uploadProgress.slice(0, progressIndex),
            action.payload,
            ...state.uploadProgress.slice(progressIndex + 1)
          ]
        }
      }

    case CLEAR_UPLOAD_LIST:
      return {
        ...state,
        uploadProgress: initialState.uploadProgress
      }

    case UPDATE_LIBRARY_STATUS:
      return {
        ...state,
        updateLibraryStatus: action.payload
      }

    case UPDATE_STATUS_UPLOAD:
      const { uid, status } = action.payload
      const prevUploadMedia = state.uploadProgress

      const mediaItemToUpdateStatus = _.findIndex(
        prevUploadMedia,
        mediaItem => mediaItem.uid === uid
      )

      if (mediaItemToUpdateStatus > -1) {
        prevUploadMedia[mediaItemToUpdateStatus].status = status
      }

      return {
        ...state,
        uploadProgress: prevUploadMedia
      }

    default:
      return state
  }
}
