// import { isSafari } from 'react-device-detect'
import { notification, Tooltip, message } from 'antd'
import YellowHandRaise from './assets/yellowHandRaise.svg'
import { DownloadOutlined, ReloadOutlined } from '@ant-design/icons'
import React, { Fragment, useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'
import { api } from '../../../services'
import { withTranslation } from 'react-i18next'
import history from '../../../history'
import './Preview.scss'
import fileSaver from 'file-saver'
import { TopPageLink, Spinner } from '../../../components'
import Chat from './components/Chat'
import PreviewStatus from './components/PreviewStatus'
import EmptyMessage from '../../../components/EmptyMessage/EmptyMessage'
import PodcastChime from './components/PodcastChime/PodcastChime'
import { config } from '../../../constants'
// import PodcastAudio from './components/PodcastAudio/PodcastAudio'

const showItems = {
  overview: false,
  hosts: false,
  attachments: false,
  notes: false,
  participants: false,
  rooms: false,
  ratings: false,
  discussion: false
}
const Preview = ({ t, match, user }) => {
  // general infos
  const [userId] = useState(user.info.id)
  const [userOrg] = useState(user.info.active_organization_id)
  const [userRole, setUserRole] = useState('Participant')
  const [event, setEvent] = useState(null)
  const [eventId] = useState(match.params.id)
  const [isLoading, setIsLoading] = useState(true)
  // const [hostLists, setHostEmails] = useState([])
  const [isPast, setIsPast] = useState(false)
  const [isLive, setIsLive] = useState(false)
  const [activeOrgUsers, setActiveOrgUsers] = useState([])
  const [creatorId, setCreatorId] = useState([])
  const [loadingData, setLoadingData] = useState({
    id: '',
    username: '',
    avatar: '',
    status: true
  })

  // infos about users
  const [userIdByRoles, setUserIdByRoles] = useState({
    guestIds: [],
    participantsIds: [],
    hostsIds: []
  })

  // const [userDataByRoles, setUserDataByRoles] = useState({
  //   guests: [],
  //   participants: [],
  //   hosts: []
  // })
  const [allUsers, setAllUsers] = useState([])

  // for messaging
  const [messages, setMessages] = useState([])
  const [isScrollBottom, setIsScrollBottom] = useState(true)
  const [isRefresh, setIsRefresh] = useState(false)
  const [isEnd, setIsEnd] = useState(false)

  // for socket connection

  const [liveCountInterval, setLiveCountInterval] = useState(null)
  const [getConnectedInterval, setGetConnectedInterval] = useState(null)
  const [isSocketClose, setIsSocketClose] = useState(false)
  const [isStreaming, setIsStreaming] = useState(false)
  const [isWebStream, setIsWebStream] = useState(false)
  const [show, setShow] = useState(showItems)
  // const [webinarHostId, setWebinarHostId] = useState('')
  const [requestedPassiveUsers, setRequestedPassiveUsers] = useState([])
  const [isPreparingConf, setIsPreparingConf] = useState(false)
  const [allowJoin, setAllowJoin] = useState(false)
  const [isEventCrash, setIsEventCrash] = useState(false)
  const [totalViewers, setTotalViewers] = useState(0)
  const [currentPlayTime] = useState(0)

  // for chime call
  const [isDisabled, setIsDisabled] = useState(false)
  const [isLeaving, setIsLeaving] = useState(false)
  const [joinStatus, setJoinStatus] = useState(false)
  const [joinLoading, setJoinLoading] = useState(false)
  const [interestStatus, setInterestStatus] = useState(false)
  const [currentSpeakerIds, setCurrentSpeakerIds] = useState([])
  const [isMicOff, setIsMicOff] = useState(true)
  const [mutedUserId, setMutedUserId] = useState(null)
  // const [streamList, setStreamList] = useState([])
  const [userToKick, setUserToKick] = useState(null)
  const [usersToInvite, setUsersToInvite] = useState([])
  const [isInvite, setIsInvite] = useState([])

  const userState = useSelector(state => state.user)
  const webSocket = useRef(null)

  const [podcastHost, setPodcastHostList] = useState([])
  const [totalHostList, setTotalHostList] = useState([])
  const [hostList, setHostList] = useState([])
  const [hostEmails, setPodcastHostEmails] = useState([])
  const [isDownLoading, setIsDownloading] = useState({ status: false, id: '' })
  const [attachedData, setAttachedData] = useState({
    filenames: [],
    attachmentIds: []
  })
  //component did mount
  useEffect(() => {
    fetchData()
    getEventById(eventId)
    initSocket(userId)
    console.log(
      isSocketClose,
      isStreaming,
      isWebStream,
      show,
      requestedPassiveUsers,
      isPreparingConf,
      allowJoin,
      isEventCrash,
      totalViewers,
      userIdByRoles
    )
  }, [])
  // Get all user ids
  useEffect(() => {
    if (event) getUserIds()
  }, [event])

  // set current user role
  useEffect(() => {
    setRole()
  }, [allUsers])

  // trigger when invite sent
  useEffect(() => {
    if (usersToInvite && isInvite && event) {
      const emails = usersToInvite.map(user => user.email)

      sendInvitations(emails)

      //reset to be able use again
      setUsersToInvite([])
      setIsInvite(false)
    }
  }, [isInvite])
  const fetchData = async () => {
    // get users in org
    try {
      const activeOrganizationId = userState.info.active_organization_id
      api.organizations
        .getUsersByOrganizationId(activeOrganizationId)
        .then(res => {
          const usersData = res.data.map(val => val.user)
          setActiveOrgUsers(usersData)
        })
    } catch (err) {
      console.error(err)
    }
  }
  const sendInvitations = users => {
    //const { emails, initialEmails } = this.state
    //const newAdded = _.difference(emails, initialEmails)

    const data = {
      title: event.title,
      add_participant: users,
      cover_id: event.cover_id
    }

    api.events
      .updateById(eventId, data)
      .then(response => {
        // const { data: respData } = response

        notification.success({
          message: 'Invitations Sent',
          description: `Invitations have been sent to ${users.join(', ')}`
        })
      })
      .catch(error => {
        console.log('sending invitation error: ', error)

        notification.error({
          message: 'Error Sending Invitations',
          description: `There was an error sending invitations. Please try again later.`
        })
        notification.error({
          message: 'Error Sending Invitations',
          description: `${error}`
        })
      })
  }

  const getEventById = async id => {
    api.events
      .getById(id)
      .then(event => {
        setJoinStatus(false)
        setEvent(event.data)
        setIsPast(event.data.is_finished)
        setIsLoading(false)
        setIsLive(event.data.is_live)
        setCreatorId(event.data.creator_id)
        getAttachmentDetail(event.data.attachments)
        if (event.data.hosts.length > 0) {
          if (event.data.host_ids.includes(event.data.host.id)) {
            setPodcastHostList(event.data.hosts)
            setTotalHostList(event.data.hosts)
          } else {
            let tempHost = event.data.hosts
            tempHost.push(event.data.host)
            setTotalHostList(tempHost)
            setPodcastHostList(event.data.hosts)
          }
        }
        setPodcastHostEmails(event.data.host_emails)
        setIsDisabled(false)
      })
      .catch(e => {
        setIsDisabled(false)
        setIsLoading(false)
      })
  }
  const getAttachmentDetail = async ids => {
    if (ids && ids.length > 0) {
      let filterNames = []
      await Promise.all(
        ids.map(async id => {
          const response = await api.library.getMediaInfoById(id)
          filterNames.push(response.data.filename)
        })
      )
      setAttachedData({
        attachmentIds: ids,
        filenames: filterNames
      })
    }
  }
  const onParticipants = async params => {
    let { attendees } = params
    let host = []
    attendees.forEach(async attendee => {
      let item = attendee.externalUserId.split('#')
      let user_name = item[0]
      let user_id = item[1]
      let isMicOff = false
      let chime_id = attendee.chimeAttendeeId
      host.push({ user_name, user_id, chime_id, isMicOff })
    })
    setHostList(host)
  }
  // const changeParticipantsState = (type, { id }, hosts, isMicOff) => {
  //   if (!id) return
  //   switch (type) {
  //     case 'MUTE':
  //       let newHosts = []
  //       hosts.forEach(item => {
  //         if (parseInt(item.user_id) === parseInt(id)) {
  //           item.isMicOff = isMicOff
  //         }
  //         newHosts.push(item)
  //       })
  //       setHostList(newHosts)
  //       break

  //     default:
  //       break
  //   }
  // }
  const displaySchedule = ({
    start_date_time: startDataTime,
    is_finished: is_finished,
    updated_at: endDateTime
  }) => {
    if (!startDataTime) return
    else {
      const format = moment(is_finished ? endDateTime : startDataTime).format(
        'HH:mm:ss'
      )
      return `${is_finished ? 'ENDED' : 'START'}: ${format}`
    }
  }

  const initSocket = userId => {
    const userName = user.info.username
    webSocket.current = new window.WebSocket(
      `${config.socketURL}?channel_type=EVENT&channel_id=${eventId}&user_id=${userId}&user_name=${userName}`
    )

    webSocket.current.onopen = () => {
      getChatHistory()
      getAllowedUsers()
      setGetConnectedInterval(
        setInterval(() => {
          webSocket.current.send(
            JSON.stringify({
              action: 'pingPong',
              msg: 'ping'
            })
          )
        }, 10000)
      )
      setIsSocketClose(false)
    }

    webSocket.current.onmessage = event => {
      const data = JSON.parse(event.data)
      if (data.message !== 'Pong') {
        console.log('onmessageEnd', data.message, data.event)
      }
      if (data.messages) {
        _.each(data.messages, item => {
          item.isReadMore = item.content && item.content.length >= 200
        })
      } else {
        if (data.content) {
          data.isReadMore = data.content.length >= 200
        }
      }
      // if (data.event === 'muteUser') {
      //   let { hostList, user, isMicOff } = data.content
      //   changeParticipantsState('MUTE', user, hostList, !isMicOff)
      // }
      if (data.event === 'sendMessage' && data.channel_id === eventId) {
        setMessages(prevMessages => [...prevMessages, data])
      }

      if (data.event === 'historyChannel' && data.channel_id === eventId) {
        setMessages(data.messages)
        if (/*!isPast && isLive*/ true) {
          setIsWebStream(true)
          setIsStreaming(true)
        }
      }

      if (data.event === 'raiseHand') {
        setRequestedPassiveUsers(data.message)
      }

      if (data.event === 'raiseHandNotification') {
        const msg = data.message
        if (msg.userId !== userId) {
          raisedHandNotification(msg)
        }
      }

      if (data.event === 'getRaisedHands') {
        // console.log('Request getRaisedHands', data)
        setRequestedPassiveUsers(data.message)
      }

      if (data.event === 'changeRaisedHandStatus') {
        setRequestedPassiveUsers(data.message)
      }
      if (data.event === 'endConference' && data.channel_id === eventId) {
        !event.data.is_finished && getEventById(eventId)
        setEvent(pre => ({ ...pre, is_finished: true }))
        setIsPast(true)
      }
      // if (data.event === 'stopConference' && data.channel_id === eventId) {
      //   getEventById(eventId)
      // }
      if (
        data.event === 'getLiveUserDetails' &&
        data.user_details &&
        data.user_details.length > 0
      ) {
        setAllUsers(data.user_details)
      }

      if (data.event === 'antmediaInfo') {
        if (data.status === 'CREATING' || data.status === 'CREATED') {
          setIsPreparingConf(true)
        } else if (data.status === 'READY') {
          setIsEventCrash(false)
          setIsPreparingConf(false)
          setAllowJoin(true)
        } else if (data.status === 'FAILED') {
          setIsPreparingConf(false)
          setAllowJoin(false)
          setShow(showItems)
          setIsEventCrash(true)
        }
      }

      if (data.event === 'sendCount' && data.channel_id === eventId) {
        setTotalViewers(data.views)
      }

      if (data.event === 'startStream' && data.channel_id === eventId) {
        setLiveCountInterval(
          setInterval(() => {
            getLiveCount()
          }, 5000)
        )
        setIsStreaming(true)
        setIsWebStream(true)
      }

      if (data.event === 'stopStream' && data.channel_id === eventId) {
        clearInterval(liveCountInterval)
        clearInterval(getConnectedInterval)
        setIsStreaming(false)
        setEvent(pre => ({ ...pre, is_finished: true }))
        setIsPast(true)
      }
    }

    webSocket.current.onerror = error => {
      console.log('Error ' + error.message)
    }

    webSocket.current.onclose = () => {
      console.log('on this socket close')
      setIsSocketClose(true)
    }
  }

  const getChatHistory = () => {
    webSocket.current.send(
      JSON.stringify({
        action: 'historyChannel',
        channel_id: eventId,
        channel_type: 'EVENT'
      })
    )
  }

  const getAllowedUsers = () => {
    const mediaServer = 'AGORA'

    webSocket.current.send(
      JSON.stringify({
        action: 'getRaisedHands',
        channel_id: eventId,
        channel_type: 'EVENT',
        mediaServer,
        title: `event_${eventId}`
      })
    )
  }

  // useEffect(() => {
  //   let data = user.info
  //   if (allUsers.length === 0) {
  //     if (data && loadingData.id !== data.id) {
  //       setLoadingData({
  //         id: data.id,
  //         username: data.username,
  //         avatar: data.avatar ? data.avatar.link : '',
  //         status: true
  //       })
  //     }
  //     return
  //   } else {
  //     let { guestIds, hostsIds, participantsIds } = userIdByRoles
  //     let userDataByRoles = {
  //       hosts: [],
  //       guests: [],
  //       participants: []
  //     }
  //     let status = true
  //     allUsers.forEach(user => {
  //       const { user_id } = user
  //       if (loadingData.id === user_id) {
  //         status = false
  //       }
  //       if (hostsIds.includes(user_id)) {
  //         userDataByRoles.hosts.push(user)
  //       } else if (guestIds.includes(user_id)) {
  //         userDataByRoles.guests.push(user)
  //       } else {
  //         userDataByRoles.participants.push(user)
  //       }
  //     })
  //     // move myself to first
  //     userDataByRoles.hosts.sort((a, b) => {
  //       if (a.user_id === loadingData.id) return -1
  //       if (b.user_id === loadingData.id) return 1
  //       return 0
  //     })
  //     setLoadingData({
  //       id: data.id,
  //       username: data.username,
  //       avatar: data.avatar ? data.avatar.link : '',
  //       status: status
  //     })
  //     setUserDataByRoles(userDataByRoles)
  //   }
  // }, [allUsers, userIdByRoles])

  const getLiveCount = () => {
    // webSocket.current.send(
    //   JSON.stringify({
    //     action: 'sendCount',
    //     channel_type: 'EVENT',
    //     channel_id: eventId,
    //     stream_id: webinarHostId
    //   })
    // )
  }

  const sendMessage = content => {
    webSocket.current.send(
      JSON.stringify({
        action: 'sendMessage',
        channel_type: 'EVENT',
        channel_id: eventId,
        avatar: user.info.avatar ? user.info.avatar.link : '',
        user_name: user.info.name || user.info.username,
        time: currentPlayTime || 0,
        content
      })
    )
  }

  const raisedHandNotification = info => {
    notification.open({
      message: `${info.userName} has raised their hand`,
      placement: 'bottomLeft',
      icon: <img alt="" src={YellowHandRaise} />
    })
  }

  const handleReadmore = (e, index) => {
    e.preventDefault()
    e.stopPropagation()
    const updatedMessages = messages.map((message, i) =>
      i === index ? { ...message, isReadMore: !message.isReadMore } : message
    )

    setMessages(updatedMessages)
    setIsRefresh(prevIsRefresh => !prevIsRefresh)
  }

  const handleScroll = e => {
    const newIsScrollBottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight

    setIsScrollBottom(newIsScrollBottom)
  }

  const setRole = () => {
    allUsers.forEach(user => {
      if (user.id === userId) {
        setUserRole(user.role)
      }
    })
  }

  const getUserIds = () => {
    let newHostIds = [event.host_id, ...event.host_ids]

    setUserIdByRoles({
      hostsIds: newHostIds,
      guestIds: event.guest_ids,
      participantsIds: event.participant_ids
    })
  }

  const endEvent = () => {
    webSocket.current.send(
      JSON.stringify({
        action: 'endConference',
        stream_id: `event_${eventId}`,
        channel_type: 'EVENT',
        channel_id: eventId
      })
    )
  }
  const onInterestPodcast = () => {
    console.log('Joining')
    setInterestStatus(true)
  }
  // const requestToSpeak = user_id => {
  //   const { guestIds } = userIdByRoles
  //   let newGuestIds = []
  //   if (guestIds.includes(user_id)) {
  //   } else {
  //     newGuestIds.push(user_id)
  //   }
  //   setUserIdByRoles({
  //     ...userIdByRoles,
  //     guestIds: newGuestIds.concat(guestIds)
  //   })
  //   webSocket.current.send(
  //     JSON.stringify({
  //       action: 'raiseHand',
  //       channel_id: eventId,
  //       channel_type: 'EVENT',
  //       userId
  //     })
  //   )
  // }

  // console.log('podcast newHostIds', {
  //   userDataByRoles,
  //   userIdByRoles,
  //   streamList
  // })
  const setLoading = status => {
    let data = user.info
    setLoadingData({
      id: data.id,
      username: data.username,
      avatar: data.avatar ? data.avatar.link : '',
      status: status
    })
  }
  const downloadFile = (id, name) => {
    setIsDownloading({ status: true, id: id })
    api.library
      .download({ id })
      .then(({ data }) => {
        fileSaver.saveAs(data.downloadble_link, name)
        setIsDownloading({ status: false, id: '' })
      })
      .catch(err => {
        message.error('Failed to download!')
        setIsDownloading({ status: false, id: '' })
      })
  }
  return (
    <Fragment>
      <TopPageLink page={t('buttons:training')} path="/training" />
      {isLoading && <Spinner />}

      {!isLoading && !event && (
        <EmptyMessage
          title={t('v2:sorry') + ' ' + t('errors:not_found_event')}
          buttonTitle={t('general:back_to') + ' ' + t('navigation:training')}
          onClickAction={() => history.push('/training')}
        />
      )}

      {!isLoading && event && (
        <div className="discussion_room" ref={() => {}}>
          <div className="discussion_room__inner">
            <div className="discussion_room__content__container">
              <div className="discussion_room__stream__container">
                <div
                  className={`discussion_room-stream__cover-pic discussion_room-stream__cover-pic--podcast`}
                  style={{
                    backgroundImage: `url(${event.cover && event.cover.link})`
                  }}
                >
                  {!isLive && event.start_date_time && !joinStatus && (
                    <div className="__schedule__dc-room">
                      {displaySchedule(event, isEnd)}
                    </div>
                  )}

                  {joinStatus && isLive && (
                    <PodcastChime
                      isConfCreator={userId === creatorId}
                      userOrg={userOrg}
                      isConferenceHost={true}
                      user={user.info}
                      eventId={eventId}
                      isEnd={isEnd}
                      isLive={isLive}
                      setIsLive={setIsLive}
                      isPast={isPast}
                      isLeaving={isLeaving}
                      setIsLeaving={setIsLeaving}
                      isMicOff={!isMicOff}
                      hostList={hostList}
                      onParticipants={onParticipants}
                      setCurrentSpeakerIds={setCurrentSpeakerIds}
                      hostEmails={hostEmails}
                      joinStatus={joinStatus}
                      setLoading={setLoading}
                      changeJoinLoadingStatus={status => {
                        setJoinLoading(status)
                      }}
                      // changeParticipantsState={changeParticipantsState}
                      endEvent={endEvent}
                    />
                  )}
                </div>
                <PreviewStatus
                  // participantData={participantData}
                  isConfCreator={userId === creatorId}
                  loadingData={loadingData}
                  changeLoading={value => {
                    setLoading(value)
                  }}
                  interestStatus={interestStatus}
                  onInterestPodcast={onInterestPodcast}
                  // requestToSpeak={requestToSpeak}
                  joinPodcast={() => {
                    setJoinStatus(true)
                  }}
                  joinStatus={joinStatus}
                  joinLoading={joinLoading}
                  isLive={isLive}
                  isPast={isPast}
                  t={t}
                  isDisabled={isDisabled}
                  setIsDisabled={setIsDisabled}
                  event={event}
                  userId={userId}
                  user={user}
                  hostsData={hostList}
                  podcastHost={podcastHost}
                  totalHostList={totalHostList}
                  // guestsData={userDataByRoles.guests}
                  // participantsData={userDataByRoles.participants}
                  allUsers={allUsers}
                  userRole={userRole}
                  setIsEnd={setIsEnd}
                  setIsLeaving={setIsLeaving}
                  currentSpeakerIds={currentSpeakerIds}
                  isMicOff={isMicOff}
                  changeMuteStatus={() => {
                    setIsMicOff(!isMicOff)
                  }}
                  getData={() => {
                    getEventById(eventId)
                  }}
                  mutedUserId={mutedUserId}
                  setMutedUserId={setMutedUserId}
                  // streamList={streamList}
                  activeOrgUsers={activeOrgUsers}
                  usersToInvite={usersToInvite}
                  setUsersToInvite={setUsersToInvite}
                  isInvite={isInvite}
                  setIsInvite={setIsInvite}
                  userToKick={userToKick}
                  setUserToKick={setUserToKick}
                />
              </div>
            </div>
            <div className="discussion_room__sidebar">
              {attachedData.filenames.length !== 0 && (
                <div className="discussion_room-sidebar__attachement">
                  <div className="comment-container">
                    <div className="comment-container_title">
                      <span>
                        <h1>{t('v3:attachments')}</h1>
                      </span>
                    </div>
                    {attachedData.filenames.map((name, i) => {
                      return (
                        <div className="discussion_room-sidebar__attachement_body">
                          <div>
                            <b>{i + 1}.&nbsp;</b>
                            {name}
                          </div>
                          <div>
                            <Tooltip
                              placement="topLeft"
                              title={'Download this audio.'}
                              arrow
                            >
                              {isDownLoading.status &&
                              isDownLoading.id ===
                                attachedData.attachmentIds[i] ? (
                                <ReloadOutlined
                                  spin
                                  style={{
                                    fontSize: 20,
                                    color: '#6AB2FF',
                                    margin: '5px',
                                    cursor: 'pointer'
                                  }}
                                />
                              ) : (
                                <DownloadOutlined
                                  onClick={() => {
                                    downloadFile(
                                      attachedData.attachmentIds[i],
                                      name
                                    )
                                  }}
                                  style={{
                                    fontSize: 20,
                                    color: '#52C41A',
                                    margin: '5px',
                                    cursor: 'pointer'
                                  }}
                                />
                              )}
                            </Tooltip>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              )}
              <div className="discussion_room-sidebar__section">
                <div className="comment-container">
                  <div className="comment-container_title">
                    <span>
                      <h1>{t('reports:comment').toUpperCase()}</h1>
                    </span>
                  </div>
                  <Chat
                    attachments={attachedData.attachmentIds}
                    hostLists={podcastHost}
                    user={user}
                    available={event.is_chat}
                    isChatAble={true}
                    messages={messages}
                    isPast={isPast}
                    currentPlayTime={currentPlayTime}
                    sendMessage={sendMessage}
                    handleReadmore={handleReadmore}
                    handleScroll={handleScroll}
                    isEnd={isEnd}
                    isRefresh={isRefresh}
                    isScrollBottom={isScrollBottom}
                    joinStatus={joinStatus}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  )
}

export default withTranslation()(Preview)
