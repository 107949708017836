import Events from './Events'
import Course from './Course'
import Maze from './Maze'
import Lesson from './Lesson'
import Report from './Report'
import User from './User'
import ReportRouting from './Routing'
import SkillJourney from './SkillJourney'
import LiveAssessments from './LiveAssessments'
import PublicConference from './PublicConference'
import ShareSphere from './ShareSphere'

export default {
  Report,
  Events,
  Course,
  Maze,
  Lesson,
  User,
  ReportRouting,
  SkillJourney,
  LiveAssessments,
  PublicConference,
  ShareSphere
}
