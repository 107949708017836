import React from 'react'
import { Button, Tooltip } from 'antd'
import { Row, Col, Field } from '../../styles'
import { useTranslation, Trans } from 'react-i18next'
import { useSelector } from 'react-redux'

function PrivacySelector(props) {
  const onClick = id => {
    const { onChange } = props

    if (onChange) {
      onChange(id)
    }
  }

  const { t } = useTranslation()
  const user = useSelector(state => state.user)
  const { selectedType, isMarketPlaceEnabled = false, label } = props

  const { isSuperAdmin, isAdmin } = user.info

  const marketplacePrivacyType = {
    id: 4,
    name: t('labels:Marketplace'),
    description: 'MarketPlaceDescription',
    disabled: !isMarketPlaceEnabled
  }

  const privacyTypes = [
    isSuperAdmin && {
      id: 1,
      name: 'Public',
      description: 'PublicDescription'
    },
    (isSuperAdmin || isAdmin) && {
      id: 2,
      name: t('v4:organisations'),
      description: 'OrganisationsDescription'
    },
    (isSuperAdmin || isAdmin) && {
      id: 3,
      name: t('v4:org_groups'),
      description: 'OrganisationGroupsDescription'
    },
    marketplacePrivacyType
  ].filter(i => !!i)

  const checkSelectedType = typeId =>
    Array.isArray(selectedType)
      ? selectedType.includes(typeId)
      : selectedType === typeId

  return (
    <Field>
      <Field.Label>{t('labels:privacy_settings')}</Field.Label>
      <div style={{ paddingBottom: '10px' }}>
        {!isAdmin && (
          <Trans i18nKey="helpers:public_description_normal">
            <span>{label}</span>
          </Trans>
        )}
        {isAdmin && (
          <Trans i18nKey="helpers:public_description_admin">
            <span>{label}</span>
          </Trans>
        )}
      </div>
      <Field.Control>
        <Row>
          {privacyTypes.map(type => (
            <Col
              md={privacyTypes.length > 1 ? 12 / privacyTypes.length : 4}
              key={type.id}
            >
              {type.name === 'Marketplace' && type.disabled ? (
                <Tooltip placement="bottom" title={t('v4:not_complete')}>
                  <Button block icon="exclamation-circle" disabled>
                    {type.name}
                  </Button>
                </Tooltip>
              ) : (
                <Button
                  block
                  type={checkSelectedType(type.id) ? 'primary' : ''}
                  onClick={() => onClick(type.id)}
                  disabled={type.disabled}
                >
                  {type.name}
                </Button>
              )}
            </Col>
          ))}
        </Row>
      </Field.Control>
    </Field>
  )
}

export default PrivacySelector
