import React from 'react'
import micOn from '../../assets/mic_on.svg'
import micOff from '../../assets/mic_off.svg'
import cameraOff from '../../assets/camera_off.svg'
import cameraOn from '../../assets/camera_on.svg'
import { useAttendeeStatus } from 'amazon-chime-sdk-component-library-react'

const MyRosterCell = ({ attendeeId }) => {
  const { muted, videoEnabled } = useAttendeeStatus(attendeeId)
  return (
    <div className="audio_container">
      <div className="audio_container__item">
        <img alt="" src={muted ? micOff : micOn} />
      </div>
      <div className="audio_container__item">
        <img  alt="" src={videoEnabled ? cameraOn : cameraOff} />
      </div>
    </div>
  )
}

export default MyRosterCell
