import React, { Component } from 'react'
import { Form, Icon, Modal, Button } from 'antd'
import { api } from '../../../../../services'
import { Row, Col } from '../../../../../styles'
import _ from 'lodash'
import { TopSection, BottomSection } from '../Marketplace'
import Preview from '../../../../Library/components/Preview'
import { PopupMedia } from '../../../../Library/internal'
import InsertMedia from './InsertMedia'
import { modal_style } from '../../styled'
import TrainerSection from '../../../../../components/TrainerSection'
import { MarketPlaceDetailSection } from './MarketPlaceDetailSection'
import MarketPlaceCalendar from './MarketPlaceCalendar'
export default class MarketPlaceForm extends Component {
  state = {
    isTopicLoading: false,
    isTopicsMax: false,
    fetching: false,
    options: [],
    value: [],
    inputValue: ''
  }

  componentDidUpdate(prevProps) {
    const { topicValue, current } = this.props
    const { value } = this.state
    // console.log('didupdatedatamarket', topicValue, current)

    if (prevProps.topicValue !== topicValue && topicValue) {
      // console.log('didupdatedatamarketif', topicValue, current)
      this.setState({
        value: topicValue
      })
    } else if (
      current.topics !== null &&
      prevProps.current.topics !== current.topics &&
      current.topics !== topicValue
    ) {
      // console.log(
      //   'didupdatedatamarketelseif',
      //   topicValue,
      //   current
      // )
      this.setState({
        value: [...value, ...current.topics, topicValue]
      })
    }

    // else if (prevProps.topicValue !== current.topics && current.topics) {
    //   console.log(
    //     'didupdatedatamarketelseifffff',
    //     topicValue,
    //     current
    //   )
    //   this.setState({
    //     value: [...current.topics, ...topicValue]
    //   })
    // }
  }

  createOptionList = list => {
    const options = list.map(topic => this.props.App.createOption(topic))
    this.setState({
      options,
      isTopicLoading: false
    })
  }

  handleChange = (newValue, actionMeta) => {
    if (actionMeta.removedValue) {
      this.setState(
        {
          value: newValue,
          inputValue: '',
          isTopicLoading: false,
          isTopicsMax: false
        },
        () => {
          this.props.App.handleTopicChange(this.state.value)
        }
      )
    } else if (!_.isEmpty(newValue) && newValue.length > 5) {
      this.setState({
        isTopicsMax: true
      })
    } else {
      this.setState(
        {
          value: newValue,
          inputValue: '',
          isTopicLoading: false,
          isTopicsMax: false
        },
        () => {
          this.props.App.handleTopicChange(this.state.value)
        }
      )
    }
    this.isModalSelectOpen()
  }

  handleCreate = inputValue => {
    const { current } = this.props
    this.setState({ isTopicLoading: true })
    const { options, value } = this.state
    const topic = { title: inputValue }
    if (!_.isEmpty(value) && value.length >= 3) {
      this.setState({
        isTopicsMax: true,
        isTopicLoading: false
      })
    } else {
      api.topics.createTopics(topic).then(({ data }) => {
        if (!_.isEmpty(data[0])) {
          const newOption = this.props.App.createOption(data[0])
          this.props.updateTopicList(data[0])
          this.setState(
            {
              isTopicLoading: false,
              isTopicsMax: false,
              options: [...options, newOption],
              value: [...current.topics, ...value, newOption],
              inputValue: ''
            },
            () => {
              this.props.App.handleParam()
            }
          )
        }
      })
    }
    this.isModalSelectOpen()
  }

  loadOptions = inputValue => {
    if (inputValue.length > 0 && inputValue.length <= 50) {
      this.setState({
        isTopicLoading: true
      })
      const params = {
        search: inputValue.trim(),
        limit: 10,
        offset: 0
      }
      api.topics.getTopics(params).then(({ data }) => {
        this.setState({
          inputValue
        })
        this.createOptionList(data)
      })
    }
  }

  LoadingIndicator = () => (
    <Icon style={{ paddingRight: '10px' }} type="loading" />
  )

  isModalSelectOpen = () => {
    const { inputValue, value, isTopicsMax } = this.state
    return !_.isEmpty(value) && value.length >= 3 && isTopicsMax
      ? false
      : inputValue.length > 0
  }

  render() {
    const {
      isFetching,
      modalOpen,
      toSelectFile,
      selectedItem,
      App: {
        toggleMediaModal,
        handleInsertData,
        handleInsertFile,
        handleRemoveSelected,
        handleFileInsert,
        handleParam,
        handleSwitch,
        hidePreviewModal,
        handleToggleMarketPlace
      },
      t,
      current,
      tag,
      isEventMarketPlace
    } = this.props

    if (isFetching) {
      return null
    }

    const eventType = tag.value

    const coaching = current.encoder === 'peer2peer'
    return (
      <section className="section" style={{ paddingBottom: '10rem' }}>
        <Form>
          <Row>
            <TopSection {...this.props} onFieldChange={handleParam} />
            <TrainerSection />
            <Col>
              <InsertMedia {...this.props} />
            </Col>
            <MarketPlaceDetailSection {...this.props} />
            <BottomSection
              {...this.props}
              onFieldChange={handleParam}
              handleSwitch={handleSwitch}
            />
            {coaching && <MarketPlaceCalendar />}
            <section className="section marketplace" style={{ width: '100%' }}>
              <div></div>
              {eventType === 'conference' ||
              eventType ===
                'live_assessment' ? null : !isEventMarketPlace ? null : (
                <Button
                  size="large"
                  shape="round"
                  onClick={handleToggleMarketPlace}
                  className="button__marketplace"
                  disabled={!current.id}
                >
                  Save & Continue
                </Button>
              )}
            </section>
          </Row>
          {modalOpen && (
            <Modal
              visible={modalOpen}
              onCancel={toggleMediaModal}
              footer={null}
              width={'80%'}
              style={modal_style}
            >
              <div className="wrap_modal_tabs">
                <PopupMedia
                  isPageBuilder
                  types={!toSelectFile ? 'images' : 'pdf'}
                  handleInsertData={handleInsertData}
                  handleInsertFile={handleInsertFile}
                  selectedFiles={current.attachments}
                  handleRemoveSelected={handleRemoveSelected}
                  // getFileName={this.getFileName}
                />
              </div>
              {selectedItem && (
                <Modal
                  visible={selectedItem}
                  onCancel={hidePreviewModal}
                  footer={null}
                >
                  <Preview library={selectedItem} />
                  <div className="wrap_btn_actions">
                    <Button
                      type="danger"
                      size="large"
                      className="action_button"
                      onClick={hidePreviewModal}
                    >
                      {t('labels:cancel')}
                    </Button>
                    <Button
                      type="primary"
                      size="large"
                      className="action_button"
                      onClick={() => handleFileInsert(selectedItem)}
                    >
                      {t('labels:insert')}
                    </Button>
                  </div>
                </Modal>
              )}
            </Modal>
          )}
        </Form>
      </section>
    )
  }
}
