import { api } from '../../services'
import {
  CREATE_AUDIO_SYNTHESIZER,
  PROCESSING_AUDIO_SYNTHESIZER,
  AUDIO_SYNTHESIZER_ERROR,
  SHOW_AUDIO_EDIT_MODAL,
  GET_SELECTED_TEXT_VOICE_TYPE,
  SHOW_AUDIO_DETAIL_EDIT_MODAL,
  LOAD_ALL_VOICES,
  CREATE_TTS_AUDIO_SYNTHESIZER
} from '../types'

const createAudioSynthesizer = data => dispatch => {
  dispatch({
    type: PROCESSING_AUDIO_SYNTHESIZER,
    payload: {}
  })
  return api.audioSynthesizer
    .audioSynthesizerCreate(data)
    .then(response => {
      dispatch({
        type: CREATE_AUDIO_SYNTHESIZER,
        payload: response.data
      })
      return response.data
    })
    .catch(err => {
      dispatch({
        type: AUDIO_SYNTHESIZER_ERROR,
        payload: err.message
      })
    })
}

const postAudioSynthesizer = data => dispatch => {
  dispatch({
    type: PROCESSING_AUDIO_SYNTHESIZER,
    payload: {}
  })
  return api.audioSynthesizer
    .postTTSAudioSynthesizerList(data)
    .then(response => {
      dispatch({
        type: CREATE_TTS_AUDIO_SYNTHESIZER,
        payload: response.data
      })
      return response.data
    })
    .catch(err => {
      dispatch({
        type: AUDIO_SYNTHESIZER_ERROR,
        payload: err.message
      })
    })
}

const listTTSAudioForMaze = () => dispatch => {
  dispatch({
    type: PROCESSING_AUDIO_SYNTHESIZER,
    payload: {}
  })
  return api.audioSynthesizer
    .getTTSAudioListForMaze()
    .then(response => {
      console.log('TTS Response 1', response)
      dispatch({
        type: LOAD_ALL_VOICES,
        payload: response
      })
      return response
    })
    .catch(err => {
      dispatch({
        type: AUDIO_SYNTHESIZER_ERROR,
        payload: err.message
      })
    })
}

const listTTSAudioSynthesizer = () => dispatch => {
  dispatch({
    type: PROCESSING_AUDIO_SYNTHESIZER,
    payload: {}
  })
  return api.audioSynthesizer
    .getTTSAudioSynthesizerList()
    .then(response => {
      const data = response.data
      dispatch({
        type: LOAD_ALL_VOICES,
        payload: data
      })
      return data
    })
    .catch(err => {
      dispatch({
        type: AUDIO_SYNTHESIZER_ERROR,
        payload: err.message
      })
    })
}

export const showEditAudioModal = data => dispatch => {
  dispatch({
    type: SHOW_AUDIO_EDIT_MODAL,
    payload: data
  })
}

export const showEditAudioDetailModal = data => dispatch => {
  dispatch({
    type: SHOW_AUDIO_DETAIL_EDIT_MODAL,
    payload: data
  })
}

export const getSelectedTextVoiceType = data => dispatch => {
  dispatch({
    type: GET_SELECTED_TEXT_VOICE_TYPE,
    payload: data
  })
}

export {
  createAudioSynthesizer,
  listTTSAudioForMaze,
  listTTSAudioSynthesizer,
  postAudioSynthesizer
}
