import React, { Component, Fragment } from 'react'

import CourseCreateTopPanel from './internal/CourseCreateTopPanel'
import CourseCreateRoutes from './routes'

class Create extends Component {
  render () {
    const mode = 'create'
    return (
      <Fragment>
        <CourseCreateTopPanel mode={mode}/>
        <CourseCreateRoutes mode={mode}/>
      </Fragment>
    )
  }
}

export default Create
