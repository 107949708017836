import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import actions from '../../../../../../../store/actions'
import Text from './Text'

const mapStateToProps = state => ({
  library: state.library,
  user: state.user,
  topics: state.topics
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getLibraryList: actions.library.getLibraryList,
      getPublicList: actions.library.getPublicList,
      upload: actions.library.upload,
      clearUploadList: actions.library.clearUploadList,
      updateLibraryStatus: actions.library.updateLibraryStatus,
      handleMakeType: actions.library.handleMakeType,
      updateTopicList: actions.topics.updateTopicList
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(Text)
