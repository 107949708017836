import React, { Fragment } from 'react'
import className from 'classnames'
import moment from 'moment'
import { Card } from './styled'
import { Icon, Menu, Dropdown, Button } from 'antd'
import './AudioCard.scss'
import { withTranslation } from 'react-i18next'
import {
  activateSoundIcon,
  deactiveSoundIcon,
  activeSynthesizedAudio,
  deactiveSynthesizedAudio
} from '../../images'
import { general } from '../../../../../../../../../constants'

const menu = (props, data) => (
  <Menu>
    {/*<Menu.Item>
      <a target="_blank" rel="noopener noreferrer" href="">
        Rename
      </a>
    </Menu.Item>*/}
    <Menu.Item>
      <span
        style={{ color: '#1890ff', cursor: 'pointer' }}
        onClick={() => props.onEditAudioDetail(data.id)}
      >
        {props.t('media:edit_detail')}
      </span>
    </Menu.Item>
    <Menu.Item>
      <span
        style={{ color: '#1890ff', cursor: 'pointer' }}
        onClick={() => props.confirmDeleteItem({ data: data })}
      >
        {props.t('media:delete')}
      </span>
    </Menu.Item>
  </Menu>
)

const AudioCard = props => {
  const {
    data,
    type,
    cardSelected,
    handleViewDetails,
    data: { title, filename, horizontal, created_at, id, is_synthesized_audio }
  } = props

  const classNames = className(
    'card-audio',
    { card_horizontal: horizontal },
    { 'card_on-select': cardSelected }
  )
  return (
    <Fragment>
      <div className={classNames}>
        <div
          className="card__audio-thumb"
          onClick={() => handleViewDetails(id, data)}
        >
          <Card.AudioIcon cardSelected={cardSelected}>
            <Card.Icon>
              {is_synthesized_audio && (
                <img
                  src={
                    cardSelected
                      ? activeSynthesizedAudio
                      : deactiveSynthesizedAudio
                  }
                  alt=""
                />
              )}
              {!is_synthesized_audio && (
                <img
                  src={cardSelected ? activateSoundIcon : deactiveSoundIcon}
                  alt=""
                />
              )}
            </Card.Icon>
          </Card.AudioIcon>
        </div>
        <div className="card__content">
          <Fragment>
            {type === general.MY_MEDIA_LIBRARY && (
              <Dropdown overlay={menu(props, data)} placement="bottomLeft">
                <Button>
                  <Icon type="more" />
                </Button>
              </Dropdown>
            )}
            <Card.AudioInfo>
              <div
                className="card__title"
                title={title || filename}
                onClick={() => handleViewDetails(id, data)}
              >
                {title || filename}
              </div>
              {moment(created_at).format('DD MMM YYYY')}
            </Card.AudioInfo>
          </Fragment>
        </div>
      </div>
    </Fragment>
  )
}

export default withTranslation('')(AudioCard)
