/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-08-09 16:41:54
 * @LastEditTime: 2019-12-19 10:53:49
 * @LastEditTime: 2019-09-09 11:43:43
 * @LastEditors: Please set LastEditors
 */

export default {
  general: {
    save: '保存',
    autosaved: '自动保存',
    back_to: '返回到',
    by: '通过',
    chat: '聊天',
    completed: '完成',
    canceled: '取消',
    component: '组件',
    confirm: '确认',
    course: '课程',
    date: '日期',
    details: '详情',
    empty: '没有数据',
    enrolled: '登记',
    free: '空闲的',
    graph: '图表',
    loading: '加载中...',
    lesson: '课',
    lessons: '课程',
    live_assessment: '直播评估',
    live_event: '直播活动',
    no: '否',
    of: '关于',
    or: '或',
    organization: '组织',
    page: '页面',
    pages: '页数',
    personal: '个人的',
    poll: '投票',
    rating: '评分',
    reset: '重置',
    signin: '登录',
    signup: '注册',
    soon: '不久',
    type: '类型',
    uploading: '上传...',
    yes: '是',
    something_went_wrong: '某些地方发生错误!',
    view_all: '查看全部',
    upload_successful: '上传成功',
    upload_successful_message:
      '您的excel 文档已成功上传，目前正在处理中。这可能需要一点时间。',
    check_email_upload_successful:
      '请<0>查看您的电子邮件</0>以获取上传用户列表的<1>报告</1>。',
    is_private: '私有的'
  },

  account: {
    title: '账户',
    subtitle: '管理您的设置和帐户信息。',
    saved: '账户更改成功'
  },

  assessments: {
    title: '评估',
    subtitle: '创建一个评估',
    finished: '评估完成',
    countdown: '直播评估将播送 ',
    create: '创建评估',
    list_title: '你的评估',
    delete_warning: '是否确认要删除此项目?',
    details_title: '添加评估详情',
    details_subtitle: '命名你的评估，并且告诉你的听众他们应该从中获得什么。',
    disclaimer: `我保证我记录的评估不会:
    -侵犯任何第三方的权利
    -包含任何违法行为，包括诽谤、淫秽、不雅、淫秽、色情、暴力、辱骂、侮辱、威胁或歧视性内容，甚至用星号伪装
    - 包含对他人的人身攻击
    - 包含任何模仿其他参与者、公众人物或名人的行为
    - 未经任何个人同意，包含任何个人的任何记录或个人信息，且不违反世界任何地区的任何数据保护法规
    - 包含任何商业招标或任何形式的“垃圾邮件”'`,
    assessment_details: '评估详情',

    assessment_settings: '评估设置'
  },

  buildersettings: {
    title: '发布设置',
    subtitle: '向您的活动添加设置，谁可以查看您的活动并与您互动'
  },

  buttons: {
    market_place: 'Market Place',
    back_to_normal: 'Back To Normal',
    add: '添加',
    add_page: '添加页面',
    add_lesson: '添加课程',
    add_answer: '添加新答案',
    add_group: '添加组',
    add_organization: '添加组织',
    add_question: '添加问题',
    add_quiz: '添加测试',
    add_users: '添加用户',
    back: '返回',
    back_to_course: '返回到课程概述',
    back_to_lesson: '返回到每课概述',
    back_to_media: '返回到媒体库',
    back_to_details: '返回到详情',
    cancel: '取消',
    clear: '清除',
    close: '关闭',
    clone: '复制',
    close_lesson: '复制课',
    clone_course: '复制课程',
    confirm: '确认',
    create: '创建',
    download: '下载',
    download_mass_user_tamplate: '下载模板',
    add_mass_users: '添加大量用户',
    upload_excel_list: '上传Excel表格',
    delete: '删除媒体',
    edit: '编辑',
    edit_course: '编辑课程',
    enroll: '注册',
    finish: '完成',
    upload_image: '上传图片',
    upload_image_video: '上传图片或视频',
    select_media_library: '选择媒体库',
    make_public: '公开',
    make_private: '隐私',
    make_org: '创建组织',
    media: '媒体库',
    my_theme: '我的主题',
    next: '下一个',
    next_lesson: '下一课',
    next_step: '下一步',
    previous_step: '上一步',
    publish: '发布',
    publish_assessment: '发布您的评估',
    publish_course: '发布您的课程',
    publish_event: '发布您的活动',
    reset: '重置',
    reset_elements: '重置内容',
    save: '保存',
    creating: '正在创建...',
    updating: '正在更新...',
    saved: '已保存',
    save_for_later: '稍后保存',
    save_settings: '保存设置',
    select_files: '选择文件',
    select_theme: '选择主题',
    start_lesson: '开始课程',
    update: '更新',
    upload: '上传',
    upload_audio: '上传音频',
    upload_photo: '上传个人资料照片',
    upload_video: '上传视频',
    enrolled: '已注册',
    unenroll: '取消注册',
    from_library: '媒体库',
    remove: '删除',
    removed: '已删除',
    start_broadcast: '开始播放',
    end_broadcast: '结束播放',
    camera: '摄像头',
    screen: '屏幕',
    screen_with_camera: '带摄像头的屏幕'
  },

  card: {
    quick_add: '快速添加',
    add: '添加',
    clone: '复制',
    completed: '课程已完成'
  },

  course: {
    upcoming_course: '即将发布的课程',
    unPublishEvent: '取消发布的活动',
    unPublishAssessment: '取消发布的评估',
    pastCourse: '培训课程',
    upcoming: '即将到来的直播活动',
    pastEvent: '过去的直播活动',
    pastAssessment: '过去的直播评估',
    upcomingAssessment: '即将到来的直播评估',
    created: '创建日期:',
    assignees: '受让人',
    organisations: ' {{ 组织 }} 组织, <2> {{ 用户 }} 用户</2>',
    event_date: '实时事件日期 <1> {{ 日期 }} </1>',
    delete_warning: '是否确认要删除这个',
    clonning_no_lesson: '为复制这个课程，请至少添加一节课。',
    clone_warning: '是否确定复制这个',
    clone_success: '正在复制',
    disclaimer: `我保证我的课程不会:
    -侵犯任何第三方的权利
    -包含任何违法行为，包括诽谤、淫秽、不雅、淫秽、色情、暴力、辱骂、侮辱、威胁或歧视性内容，甚至用星号伪装
    - 包含对他人的人身攻击
    - 包含任何模仿其他参与者、公众人物或名人的行为
    - 未经任何个人同意，包含任何个人的任何记录或个人信息，且不违反世界任何地区的任何数据保护法规
    - 包含任何商业招标或任何形式的“垃圾邮件”'`
  },

  coursecreate: {
    dropzone_text: '插入多媒体',
    sidebar_text: '选择一个主题 <1/> 给你的 <3/> 课',
    delete_warning: '确定要删除此课吗？'
  },

  coursepage: {
    quizview_loading: '正在检查答案，请稍后...',
    quizview_answer: '你的答案'
  },

  coursequiz: {
    title: '全部课的测验'
  },

  dashboard: {
    tooltip_title: '不再显示该视频',
    page_title: '你的课程’ 统计',
    page_subtitle: '您创建的课程的状态',
    profile_add_info: '添加你的 <1>{{未完成文本}}</1> 以完成您的个人资料.',
    profile_build:
      '建立您的个人资料 <1>在这里</1>, 让我们了解您的个人兴趣和技能。',
    profile_title: '个人资料完成度',
    more_details: '更多详情'
  },

  errors: {
    answer: '请输入答案',
    answer_correct: '选择正确答案',
    code: '请输入您的代码',
    criterion: '请输入条件',
    duration: '时长为必填项',
    element: '输入元素',
    email: '请输入您的电子邮件',
    email_invalid: '输入的电子邮件无效',
    feedback_correct: '请输入正确的反馈',
    feedback_incorrect: '请输入不正确的反馈',
    host: '请输入直播主机',
    host_assessment: '请输入评估主机',
    image: '请上传图片',
    location: '请输入您的位置',
    live_location: '请选择播放位置',
    limit: '请输入直播分钟',
    min_3char: '最少3个字符',
    number: '请输入正数。',
    host_email_require: `需要主机的电子邮件。`,
    attendee_email_require: `需要参与者的电子邮件`,
    name: '请输入你的名字',
    not_found_course: '未找到课程',
    not_found_event: '未找到活动',
    not_found_assessment: '未找到评估',
    password: '请输入您的密码',
    password_digit: '至少一位',
    password_short: '密码太短，至少十个字符',
    password_special: '至少一个特殊字符',
    password_uppercase: '至少一个大写字符',
    password_lowercase: '至少一个小写字符',
    phone: '请输入您的电话号码',
    phone_invalid: '电话号码无效',
    position: '请输入您的职位',
    question: '请输入您的问题',
    start_date: '需要输入开始日期和时间',
    tag: '请选择一个标签',
    topics: '请选择至少一个主题',
    live_topics: '请添加主题',
    title: '请输入标题',
    title_assessment: '请输入评估标题',
    title_course: '请输入课程标题',
    title_empty: '标题不能为空',
    title_event: '请输入活动标题',
    title_lesson: '请输入本课标题',
    title_quiz: '请输入测试名称',
    username: '请输入您的用户名',
    upload_error: '错误 – 文件不兼容!',
    upload_more_than_10: '一次最多只能上传10个文件。',
    topics_max: '请最多添加三个主题。',
    upload_excel: '请确保您已上传正确的文件。',
    title_excel: '上传模板时错误:',
    file_size_exceed: '超过文件大小',
    subtitle_excel: '请确保使用提供的模板，并且填写正确。',
    select_broadcast_option: '请选择一个选项来播放此直播',
    multi_hosts_number_error: '演示者的数目不能超过直播活动指定的数目。',
    conference_people_number_error: ` 参与者人数不能超过直播活动的指定人数`,
    select_max_host: '为事件选择至少1个演示者',
    select_max_conference: '为事件选择至少1名参与者。',
    stream_stop_error: '数据流意外停止!',
    unsecure_context:
      '致命错误：浏览器无法访问摄像头和麦克风，因为内容不安全。请安装SSL并通过https访问',
    ws_not_supported: '致命错误：此浏览器不支持WebSocket',
    no_org: '选择至少一个组织以继续。',
    no_org_group: '选择至少一个组织中的群组以继续操作。',
    waiting_to_join: '等待某人加入！'
  },

  events: {
    title: '添加实时事件详细信息',
    change_host: '更改主机',
    finished: '活动以完成',
    countdown: '直播将会播放。 ',
    subtitle: '给你的活动命名，告诉你的观众他们应该从中得到什么。',
    poll_disabled: '此活动中不提供投票',
    disclaimer: `我保证我记录的活动不会:
     -侵犯任何第三方的权利
    -包含任何违法行为，包括诽谤、淫秽、不雅、淫秽、色情、暴力、辱骂、侮辱、威胁或歧视性内容，甚至用星号伪装
    - 包含对他人的人身攻击
    - 包含任何模仿其他参与者、公众人物或名人的行为
    - 未经任何个人同意，包含任何个人的任何记录或个人信息，且不违反世界任何地区的任何数据保护法规
    - 包含任何商业招标或任何形式的“垃圾邮件”'`,
    prompt_message: '离开此页面将结束您的活动。您确定要离开此页面吗?',
    duration_exceed_message: '您的播放时间超时，应用程序将停止播放。',
    before_schedule_message:
      '您将在计划的活动时间之前开始播放。请注意，停止播放将结束活动。',
    browser_unsupportive_message:
      '您的浏览器不支持屏幕共享。您可以在这个链接中看到支持的浏览器',
    cancel_switch_host_message: '主机不可用。这项活动将从你这边继续进行。',
    coaching_prompt_message: '在活动完成之前，您不能离开此页。',
    event_modal_warn: '是否要退出此活动?',
    confirm_message: '如果单击“确定”，您的活动将结束。',
    screen_share_denied: '您拒绝共享您的屏幕',
    media_not_found: '在您的设备中找不到或不允许使用摄像头或麦克风',
    media_not_access: '摄像头或麦克风正被其他不允许读取设备的进程使用',
    constraint_error:
      '没有找到符合您的视频和音频限制的设备。您可以更改视频和音频限制',
    media_access_denied: '您不允许访问摄像机和麦克风。'
  },

  filters: {
    days7: '过去7天',
    days15: '过去15天',
    days30: '过去30',
    days365: '最后365天',
    date: '创建日期',
    relevance: '关联',
    popularity: '人气',
    name: '名称',
    group: '组',
    Newest_to_Oldest: '最新到最老',
    Oldest_to_Newest: '最老到最新',
    A_Z: 'A到Z',
    Z_A: 'Z到A'
  },

  helpers: {
    password:
      '为了确保您帐户的安全，我们要求您的密码中至少有一个小写字母，数字。您的密码至少需要10个字符',
    phone: '请输入您的电话国家代码，例如：+65',
    username:
      '您的用户名将被视为公共信息，并根据需要与其他用户共享。因此，我们建议您不要在用户名中使用个人信息',
    public_description_normal:
      '您的 <0><0/></0> 已保存，默认情况下被视为私有。这意味着只有您可以看到此内容。要发布此内容，您可以选择以下选项之一。向公众发布你的课程意味着它将对平台中的所有用户开放。“特定用户”选项将使您能够与Sharelook帐户关联的电子邮件地址与特定用户共享您的课程。',
    public_description_admin:
      '此 <0><0/></0> 已保存，默认情况下被视为私有。这意味着只有您（和内容创建者）才能看到此内容。要发布此内容，您可以选择以下选项之一。将您的课程发布到公共平台意味着平台中的所有用户都可以使用该课程，包括组织外部的用户。将其发布到组织意味着组织内的所有用户都可以看到它。您还可以将其发布到一个或多个组，这意味着只有这些组中的用户才能看到它。最后，您可以选择使用Sharelook帐户电子邮件地址将其发布给组织内外的特定用户。'
  },

  labels: {
    add: '添加',
    portrait: 'Crop Portrait',
    landscape: 'Crop Landscape',
    answer_type: '答案类型',
    answers: '答案',
    back_to_quiz: '返回测试列表',
    back_to_question: '返回问题列表',
    chat: '聊天',
    chat_available: '此活动不提供聊天功能',
    chat_report: '聊天报告',
    choice_single: '单选',
    choice_multiple: '多选',
    choice_free: '"自由" 选择',
    choice_sorting: '"排序" 选择',
    choice_matrix: '"矩阵排序" 选择',
    clone_lesson_permission: '复制权限',
    code: '代码',
    correct: '正确',
    created: '创建日期',
    clone_modal: '复制课程',
    cloning_course: '正在复制课程...',
    cloning: '复制中...',
    cloning_course_desc: '此过程需要一点时间，请耐心等待',
    cloning_success: '课程 "<0/>" 已经复制成功!',
    description: '说明',
    disclaimer: '免责声明',
    drop_file: '在此删除文件...',
    duration: '时长',
    edit: '编辑',
    email: '电子邮件',
    event: '活动',
    event_all: '他的所有活动',
    event_date: '直播活动的当地日期/时间',
    event_this: '此活动',
    expire: '到期',
    expiry_date: '到期日期',
    forgot: '忘记密码?',
    group: '组',
    groups_list: '组列表',
    host: '主机',
    id_annotation: '注释id',
    id_quiz: '测试id',
    incorrect: '不正确',
    interests: '兴趣',
    invited: '邀请',
    live_chat: '直播讨论',
    live_stream_limit: '直播分钟数',
    contact_text: '若要更改每月直播限制，请联系',
    contact_admin:
      '<0>如果您希望更改每月直播限制，请联系</0> <1>admin@sharelook.com</1>',
    limit_stream_text:
      '直播剩余分钟: <0>{{ limit }}</0> 分钟 <1/><2/> <3>若要更改您的每月直播限制，请联系</3> <4>admin@sharelook.com</4>',
    location: '位置',
    lessons: '课程',
    location_broadcast: '主机服务器位置',
    location_broadcast_subtitle: '添加最接近广播主机位置的位置以减少视听延迟',
    media_manager: '媒体管理器',
    minutes: '分钟',
    name: '名称',
    online_quiz: '在线测试',
    organiser: '作者',
    organization: '组织',
    overall_emotion: '整体体验:',
    organizations_list: '组织列表',
    password: '密码',
    participants: '参与者',
    picture: '图片 (16:9)',
    phone: '电话',
    photo: '照片',
    poll: '投票',
    position: '职位',
    processing: '正在处理',
    privacy_settings: '隐私设置',
    question: '问题',
    quiz_create: '创建新测验',
    quiz_name: '测验名称',
    quiz_update: '更新测验',
    select_organization: '选择组织',
    settings: '设置',
    show: '显示',
    show_graph: '显示图表结果',
    signin: '登录',
    signup: '注册',
    sort_by: '排序依据',
    start_date: '开始日期',
    tags: '标签',
    terms: '我同意这些 <1>条款和条件</1>.',
    title: '标题',
    topics: '主题',
    topic: '主题',
    total_comments: '总评论',
    username: '用户名称',
    users_list: '用户列表',
    users_emails: '插入特定用户电子邮件',
    description_specifiction_email:
      '请每行输入一个电子邮件地址，以便与多个特定用户共享此邮件。',
    message_specific_email: '请输入特定用户的电子邮件。',
    viewers: '观众',
    edit_course: '编辑课程',
    edit_assessment: '编辑评估',
    edit_event: '编辑活动',
    delete_course: '删除课程',
    delete_event: '删除活动',
    delete_assessment: '删除评估',
    view_course: '查看课程',
    view_event: '查看活动',
    view_assessment: '查看评估',
    date_event: '直播活动日期',
    date_assessment: '直播评估日期',
    clone_of: '复制',
    clone_course:
      '创建复制此课程，您可以独立于原课程进行修改，包括所有课和页面。',
    make: '生成',
    available_to: '可供任何 ShareLook 用户使用',
    uploaded_by: '上传人: ',
    course: '课程',
    assessment: '评估',
    user_not_exist: '用户不存在，是否发送邀请?',
    check_user: '通知',
    user_not_exist_in_org: '用户不在这个组织中，是否发送邀请?',
    title_modal_stream: '改变组织中直播的限制',
    des_warning_stream: '您将要更改',
    monthly_stream: '每月直播限制:',
    min_per_month: '分钟/月',
    broadcast_label: '我想要做:',
    hosts_emails: `插入特定主持人的电子邮件`,
    attendee_emails: `插入特定参与者的电子邮件`,
    description_host_specifiction_email: '请每行输入一个电子邮件。',
    presenter_label: '演示者',
    presenter_label_conference: '人的编号',
    event_privacy: '会议模式'
  },

  broadcastOptions: {
    stream_from_phone: '用我的手机进行直播',
    other_webrtc: '来自我的电脑的网络会议',
    multihost: '来自我的电脑的群播网络会议',
    peer2peer: '培训课程',
    conference: '直播会议'
  },

  lessoncreate: {
    title: '添加课程详情',
    subtitle: '给你的课命名，并且告诉你的观众他们应该从中得到什么。',
    input_label_title: '课的标题',
    input_label_topics: '课的主题',
    input_label_description: '课程说明',
    delete_warning: '确定要删除此页吗?'
  },

  lessonoverview: {
    title: '添加课',
    subtitle: '添加现有的一课，还是创建新的一课',
    new_lesson: '创建新一课',
    new_lesson_sub: '从头开始创建全新的一课!',
    existing_lesson: '选择现有的一课',
    existing_lesson_sub:
      '链接到现有的课，对此课所做的更改也将影响其他课程中的此课',
    clone_lesson: '复制现有的课',
    clone_lesson_sub:
      '在本课程中制作此课的唯一副本，对此课所做的更改不会影响原始的课。',
    clone_lesson_modal_sub: '请单击要添加到课程中的课下面的“复制”按钮。'
  },

  management: {
    groups_title: '管理组',
    delete_warning: '你确定吗?',
    organizations_title: '我的组织',
    organizations_subtitle: '将组织添加到你的列表',
    users_title: '管理用户',
    users_position: '高级用户管理者',
    user_not_found: '没有找到该用户',
    select_org: '请查找并选择要管理用户的组织'
  },

  audio: {
    modal_title: '插入音频',
    list_view: '列表视图',
    create_audio_modal_title: '创建音频',
    edit_audio_modal_title: '编辑音频',
    public_audio_not_found: '没有找到该音频。',
    private_audio_not_found: '您尚未上传任何音频',
    are_you_sure_to_delete: '您确定要删除吗?',
    type_something: '说些什么',
    select_voice: '选择',
    file_name: '文件名',
    description: '说明',
    topic: '主题',
    key_words_tag: '关键词标签',
    mp3_audio: 'MP3 音频',
    created: '已创建',
    duration: '时长',
    close: '关闭'
  },

  image: {
    modal_title: '插入图片',
    image: '图片',
    pixel: '像素',
    dimension: '尺寸',
    public_image_not_found: '未找到该图片',
    private_image_not_found: '您尚未上传任何图像'
  },

  annotation: {
    modal_title: '注释'
  },

  text: {
    modal_title: '文本编辑'
  },

  video: {
    modal_title: '插入视频',
    image: '视频',
    mp4_video: 'MP4 视频',
    public_video_not_found: '未找到该视频',
    private_video_not_found: '您尚未上传任何视频'
  },

  media: {
    title: '媒体库',
    subtitle: '你的文件库',
    modal_title: '上传媒体',
    delete_warning: '确认删除',
    delete_message: '已删除的媒体仍将出现在使用它们的页面上',
    dropzone_text: '或拖放',
    delete_success: '媒体已被成功删除',
    upload_file: '上传文件',
    drag_drop: '或拖放',
    import_file: '从您的电脑导入文件',
    no_media: '您尚未上传任何媒体',
    media_type: '媒体类型',
    video: '视频',
    image: '图片',
    audio: '音频',
    gif: 'GIF动态图片',
    transcribed_text: 'Transcribed Text',
    media_not_found: '未找到该媒体',
    of: '属于',
    disclaimer: `我保证我的媒体不会:
    -侵犯任何第三方的权利
    -包含任何违法行为，包括诽谤、淫秽、不雅、淫秽、色情、暴力、辱骂、侮辱、威胁或歧视性内容，甚至用星号伪装
    - 包含对他人的人身攻击
    - 包含任何模仿其他参与者、公众人物或名人的行为
    - 未经任何个人同意，包含任何个人的任何记录或个人信息，且不违反世界任何地区的任何数据保护法规
    - 包含任何商业招标或任何形式的“垃圾邮件”'`,
    my_media: '我的媒体',
    public_library: '公共库',
    organization_library: '组织库',
    upload_from_computer: '从电脑上传',
    create: '创建',
    all: '全部',
    male: '男',
    female: '女',
    processing_audio: '正在处理音频',
    create_annotation: '创建注释',
    popup_over_lesson_text: '点击后在课程页面上弹出注释',
    edit_detail: '编辑细节',
    delete: '删除',
    go_create_audio: '去创建音频',
    show_more_details: '显示更多详情',
    show_less_details: '显示较少详情',
    drop_files: '删除文件和上传位置',
    or: '或',
    import_files: '从计算机导入文件'
  },

  navigation: {
    account: '账户',
    assessments: '评估',
    dashboard: '控制面板',
    groups: '群组管理',
    logout: '注销',
    organisations: '组织',
    organizations_users: '组织 & 用户',
    reports: '报告',
    schedule: '计划',
    training: '训练',
    nowLive: '广播',
    users: '用户',
    workshop: '我的工作室'
  },

  onboarding: {
    step1_title: '第 1 步: 告诉我们一些关于你自己的事',
    step1_subtitle: '你叫什么名字? 你从哪里来? 你的?',
    step2_title: '第 2 步: 选择一些你想要提升的技能',
    step2_subtitle:
      '接下来，告诉我们你想更好地理解什么。也许是你还没学会的东西？一个新的学习领域？你喜欢做什么?'
  },

  pagebuilder: {
    title: '创建一节课',
    subtitle:
      '是时候为你的观众创造内容，让他们向你学习，你可以添加图片、视频和信息图表',
    select_layout: '选择页面布局',
    template1: '空白',
    template2: '标题 & 副标题',
    template3: '标题 & 文本',
    template4: '标题, 媒体 & 文本',
    template5: '标题 & 媒体',
    template6: '标题 & 测验'
  },

  pageconstructor: {
    title: '创建和复制课',
    subtitle:
      '是时候为你的观众创造内容，让他们来向你学习了，你可以添加图片、视频和信息图表'
  },

  passwordreset: {
    success_coded:
      '我们已通过电子邮件将验证码发送到您提供的地址，请将验证码复制到下面的地方以设置新密码。',
    success_changed: '您已成功设置新密码，您现在可以登录了。'
  },

  placeholders: {
    search: '搜索',
    answer: '答案',
    chat: '说点什么...',
    code: '代码',
    criterion: '标准',
    date_event: '选择日期 & 时间',
    date_start: '开始日期',
    date_expire: '截止日期',
    description: '说明',
    elements: '排序要素',
    email: '电子邮件',
    feedback_correct: '反馈正确',
    feedback_incorrect: '反馈不正确',
    host: '主机',
    keywords: '搜索关键词',
    lesson_description: '本课说明',
    location: '位置',
    minutes: '分钟',
    name: '名称',
    organiser: '作者',
    organization: '组织',
    password: '密码',
    phone: '电话',
    photo: '照片',
    picture: '图片',
    point: '点',
    position: '职位',
    question: '问题',
    quiz: '测验名称',
    search_course: '按课程搜索',
    search_media: '按媒体搜索',
    search_name: '按名称搜索',
    search_title: '按标题搜索',
    search_training: '搜索练习',
    settings: '设置',
    tags: '标签',
    title: '标题',
    topics: '主题',
    username: '用户名',
    keyword: '关键词',
    max_500_characters: '最多500字',
    no_description: '此文件没有说明',
    no_keyword: '此文件没有关键词',
    no_topics: '此文件没有设置主题',
    feedback: '消息',
    contact: '我们能提供什么帮助?'
  },

  publishsettings: {
    title: '发布设置',
    event_subtitle: '向您的活动添加设置，谁可以查看您的活动并与之互动',
    assessment_subtitile: '给你的评估命名，告诉你的听众他们应该从中学到什么',
    course_subtitle: '向您的课程添加设置，谁可以查看您的课程并与之互动',
    disclaimer: `1. 注册 注册为用户/创建帐户即表示您同意我们的[用户协议]并确认阅读了我们的[隐私政策]
    2. 创建课程
    通过使用我们的工具和创建课程内容，您同意我们的[用户协议]并确认阅读我们的[隐私政策] 3.创建活动
使用我们的工具并创建活动，即表示您同意我们的[用户协议]并确认阅读了我们的[隐私政策]
4. 创建评估
使用我们的工具并创建评估，即表示您同意我们的[用户协议]并确认阅读了我们的[隐私政策]
dates_validation_error: '两个日期都应填写。',
  emails_validation_error: '电子邮件列表无效。',
  specific_email_validation_error: '只允许一个电子邮件。',
  i_agree_to_these: '我同意这些 ',
  terms_and_conditions: '条款和条件。',
  PublicDescription:
'免费独立用户: (隐私和公共选项)你的 [课程|活动|评估] 已保存，默认情况下被视为私有。这意味着只有您可以看到此内容。若要发布此内容，您可以选择在下面将其公开。这意味着该课程将面向平台中的所有用户。',
  OrganisationsDescription:
'组织用户: (使用“发布” 而不是“完成”，将到发布页面，与其他用户相同，但没有任何发布选项。)你的 [课程|活动|评估] 已经保存，并且为私有。这意味着只有您和组织中的管理员才能看到它。作为用户，您不能自己在组织内发布内容，因为这需要管理员权限。请与您的管理员联系，要求他们将您的内容公开发布到整个组织、组织内的特定组或特定用户。',
  OrganisationGroupsDescription:
'组织管理员: (对于组织内制作的内容：私有、公共、组织、组、特定用户):这个 [课程|活动|评估]已保存，默认情况下被视为私有。这意味着只有您（和内容创建者）才能看到此内容。要发布此内容，您可以选择以下选项之一。将您的课程发布到公共平台意味着平台中的所有用户都可以使用该课程，包括组织外部的用户。将其发布到组织意味着组织内的所有用户都可以看到它。您还可以将其发布到一个或多个组，这意味着只有这些组中的用户才能看到它。最后，您可以选择使用Sharelook帐户电子邮件地址将其发布给组织内外的特定用户。',
  SpecificUsersDescription:
'付费独立用户: (私人、公共和特定用户)你的 [课程|活动|评估] 已保存，默认情况下被视为私有。这意味着只有您可以看到此内容。要发布此内容，您可以选择以下选项之一。向公众发布你的课程意味着它将对平台中的所有用户开放。“特定用户”选项将使您能够通过Sharelook帐户关联的电子邮件地址与特定用户共享您的课程。'`
  },

  quiz: {
    quiz_empty: '您尚未创建测验',
    questions_empty: '您尚未创建问题'
  },

  regions: {
    asia_pacific_australia: '澳大利亚',
    asia_pacific_india: '印度',
    asia_pacific_japan: '日本',
    asia_pacific_s_korea: '韩国',
    asia_pacific_singapore: '新加坡',
    asia_pacific_taiwan: '台湾',
    eu_belgium: '比利时',
    eu_germany: '德国',
    eu_ireland: '爱尔兰',
    south_america_brazil: '巴西',
    us_central_iowa: '爱荷华州',
    us_east_s_carolina: '南卡罗莱纳	',
    us_east_virginia: '弗吉尼亚洲',
    us_west_california: '加利福尼亚',
    us_west_oregon: '俄勒冈州'
  },

  reports: {
    title: '报告',
    workshop_report: '工坊报告',
    user_report: '用户报告',
    organization_workshop: '组织的工坊',
    course: '课程',
    live_event: '直播活动',
    workShop_graph: '工坊图表',
    organization_account: '组织账号',
    all: '全部',
    name: '名称',
    id_number: '账号',
    email: '电子邮箱',
    search_user_report: '搜索用户报告',
    participant: '参与者',
    participants: '参与者',
    host: '主机',
    username: '用户名',
    event: '活动',
    quiz: '测验',
    assessment: '评估',
    time: '时间',
    no_recent_activity: '没有最近活动',
    workshops: '工坊',
    courses: '课程',
    live_events: '直播活动',
    live_assessments: '在线评估',
    quizzes: '测验',
    overview: '概述',
    by: '通过',
    views: '视图',
    status: '状态',
    poll: '投票',
    comment: '评论',
    comments: '评论',
    no_comments: '没有评论',
    no_polls: '没有投票',
    live_date: '实时日期',
    score: '分数',
    question: '问题',
    submit: '提交',
    recent_activity: '最近的活动',
    ago: '前',
    no_data_found: '未找到数据',
    start_end_live: '开始/结束直播',
    join_leave_date: '加入/离开',
    watched_time: '观看时间',
    complete: '完成',
    in_complete: '完成',
    today: '今天',
    seven_days: '7七天',
    last_month: '上个月',
    one_year: '1年',
    all_time: '所有时间',
    report: '报告',
    from_date: '起始日期',
    to_date: '结束日期',
    all_workshop: '所有工坊',
    all_participants: '所有参与者',
    live_assessment: '在线评估',
    users: '用户',
    download: '下载',
    recent: '最近',
    WorkShop: '工坊',
    Activity: '活动',
    created_date: '创建日期',
    lessons: '课',
    lesson: '课',
    completed: '已完成',
    report_download: '下载报告',
    this_month: '这个月',
    search_course: '搜索课程',
    participants_graph: '参与者图表',
    viewed: '已查看',
    enrolled: '已注册',
    description: '说明',
    topic: '话题',
    analytic: '分析',
    quiz_list: '测验列表',
    select_one: '选择一个',
    group_status: '群组状态',
    duration_min: '持续时间(分钟)',
    submit_poll: '提交投票',
    download_events: '下载活动',
    search_event: '搜索活动',
    duration: '时长',
    total_participants: '所有参与者',
    message: '消息',
    avg_message: '平均消息',
    per_message: '消息/活动的百分比'
  },

  signup: {
    success_step1:
      '我们已经通过电子邮件将验证码发送到您提供的地址，请将该验证码复制到下面的字段以确认您的注册。',
    success_step2: '谢谢。您已成功注册。',
    privacy: `我同意Sharelook的 <1>条款 & 条件</1> 和 <3> 隐私政策</3>`
  },

  stream: {
    emotion_impressive: '令人赞叹',
    emotion_unimpressive: '平庸的',
    message_not_started: '即将到来',
    message_running: '运行中',
    message_processing:
      '我们目前正在处理直播录音以便回放。请稍后回来查看视频并继续添加评分和反馈'
  },

  tabs: {
    audience: '观众',
    course: '课程概述',
    details: '直播详情',
    description: '说明',
    host: '主机',
    lesson: '课的概述',
    lessons: '课',
    overview: '概述',
    page: '页面生成器',
    publish: '发布',
    settings: '发布设置',
    total_views: '总浏览量',
    live_participates: '直播参与者',
    live_chats: '直播聊天',
    live_quiz: '直播投票',
    trainer_rating: '培训师等级',
    trainer_times: '培训师时间',
    courses_quizzes: '课程测验',
    user_list: '用户列表',
    discussion: '讨论',
    notes: '我的笔记'
  },

  topics: {
    SALES_TRAINING: '销售培训',
    NEGOTIATION_TRAINING: '谈判培训',
    CROSS_CULTURAL_AWARENESS: '跨文化意识',
    NETWORKING_SKILLS: '网络技能',
    SOFTWARE_ARCHITECTURE: '软件构架',
    PROJECT_MANAGEMENT: '项目管理',
    DIGITAL_TRANSFORMATION: '数字转换',
    IT_CONSULTING: 'IT 咨询',
    MANAGEMENT_CONSULTING: '管理咨询',
    HR_CONSULTING: '人力资源咨询',
    INTERNET_OF_THINGS: '物联网 (IoT)',
    CYBERSECURITY: '网络安全',
    PRIVACY_AND_GDRP: '隐私和GDPR'
  },

  training: {
    title: '培训',
    title_assessment: '评估',
    subtitle: '课程和直播活动',
    enrolled_title: '登记课程和直播活动',
    enrolled_subtitle: '您登记的课程和直播活动',
    enrolled_message: '您目前没有参加任何课程和活动。',
    past_live_event_title: '过去的直播活动',
    past_live_event_subTitle: '已完成的直播活动',
    past_live_event: '当前没有过去的直播活动',
    recomended_title: '推荐的课程和直播活动',
    now_live_event_title: '正在直播的',
    now_live_event_subTitle: '正在直播或将快开始的活动',
    now_live_event: '当前没有直播活动。',
    webinar_event_title: '网络研讨会频道',
    webinar_event_subTitle: '已完成的网络研讨会',
    webinar_event: '当前没有网络研讨会',
    p2p_event_title: '培训课程 ',
    p2p_event_subTitle: '已完成的培训课程',
    p2p_event: '当前没有培训课程。',

    now_live_assessment_title: '新的直播',
    now_live_assessment_subTitle: '正在直播评估或即将到来的评估',
    now_live_assessment_message: '当前没有直播评估',

    upcoming_assessment_title: '即将到来的直播评估',
    upcoming_assessment_subtitle: '即将到来的直播评估',
    upcoming_assessment_message: '目前还没有即将到来的直播评估',

    past_live_assessment_title: '过去的直播评估',
    past_live_assessment_subTitle: '已完成的直播评估',
    past_live_assessment_message: '目前没有直播评估',

    /**
     * <0/> get index 0 of data from Trans component
     * exp -->
     * <Trans i18nKey='training:recomended_subtitle' >
     *    <Navlink>{...something}</Navlink> --> is index 0 of Trans component
     *    <h1>{...something}</h1> --> is index 1 of Trans component
     * </Trans>
     */
    recomended_subtitle: '我们根据您的<0><0/></0>策划课程和直播活动',
    /**
     * and we also can get data from index to replace in recommended_subtitle by like above string.
     * for exp -->
     * at the end of the recomended_subtitle above will get data from index of component to display
     * <0><0/></0> mean get data index 0 of Trans component above = <Navlink> and <0/> get data index 0 from <Navlink> = {...something}
     */
    search: '搜索培训',
    search_assessment: '搜索评估',
    recomended_message: '目前没有推荐的课程和直播活动。'
  },

  warnings: {
    delete: '你确定吗?'
  },

  workshop: {
    title: '我的工作室',
    subtitle: '创建课程或主持你自己的直播活动。',
    new_course: '创建一个新课程',
    new_event: '创建一个新的直播活动。',
    new_assessment: '创建一个新的直播评估',
    courses_title: '创建课程 & 直播活动',
    courses_subtitle: '编辑 & 发布你的课程和计划的直播活动',
    tags_course: '课程',
    tags_live: '直播活动',
    tags_assessment: '直播评估'
  },

  help_center: {
    sidebar: {
      title: '我们能为您提供什么帮助?',
      back_to: '控制面板'
    },
    menu: {
      help_center: '帮助中心',
      whats_new: '新功能',
      feedback: '反馈',
      feedback_title: '向sharelook发送反馈',
      terms_and_conditions: '条款 & 条件',
      privacy_policy: '隐私政策',
      contact_us: '联系我们'
    },
    get_started: {
      title: '由ShareLook开始',
      description: {
        main: '我们已经将直播视频集成到了一个易于使用的课程生成器',
        tagline:
          '有了ShareLook的专利直播系统，你可以创建预约，自动化学习，最大限度地分享知识'
      }
    },
    whats_new: {
      title: '由 ShareLook开始',
      description: {
        main:
          '你知道吗，创造讲故事式的学习可以在短时间内创造出来，而且不会那么昂贵吗？你应该把ShareLook课程生成器和各自的才能结合起来。你可以问我们“怎么做”',
        tagline: ''
      }
    },
    create_workshop: '如何创建工作室',
    create_course: '如何创建课程',
    create_user: '如何创建用户',
    create_group: '如何创建组',
    create_webinar: '如何创建网络研讨会',
    create_live_event: '如何创建直播活动',
    create_live_assessment: '如何创建直播评估',
    create_live_on_mobile: '在移动设备上创建直播',
    personalize_profile: '个性化配置文件',
    manage_group: '管理组',
    report: '报告',
    add_annotations: '如何添加批注/弹出窗口',
    add_audio: '如何添加音频',
    add_header_and_footer: '添加页眉 & 页脚',
    add_image: '如何添加图片',
    add_quiz_content: '如何添加测试内容',
    add_text_content: '如何添加文本内容',
    add_user: '如何添加用户',
    add_video: '如何添加视频',
    clone_course: '如何复制课程',
    find_live_broadcast: '如何查找课程直播',
    contact_us: {
      title: '联系我们',
      subject_placeholder: '输入主题*',
      content_placeholder: '输入消息*',
      subject_required: '*主题是必填的',
      content_required: '*内容是必填的',
      submit: '发送',
      cancel: '取消',
      sent: '已发送'
    },
    feedback: {
      title: '向 sharelook发送反馈',
      attach_title: '附加文件',
      choose: '选择文件',
      detail: '文件将与团队共享',
      content_placeholder: '输入消息*',
      content_required: '*内容是必填的',
      submit: '发送',
      cancel: '取消',
      sent: '已发送'
    }
  },
  discussion: {
    join_discussion: '加入讨论'
  },
  notes: {
    title: '我的笔记',
    add_note: '添加新笔记'
  }
}
