import React, { Component, Fragment } from 'react'
import {
  ReportTable,
  HostAvatar,
  HeaderTabs,
  CustomTabPane,
  SearchEventMargin,
  PaginationRightAligned
} from '../../styled'
import { withTranslation } from 'react-i18next'
import { Icon, Tabs, Row, Col, Pagination, Divider } from 'antd'
import { Search } from '../../../../components'
import { generateCSV } from '../../../../utils'
import moment from 'moment'
import { userImage } from '../../images'
import { isSafari } from 'react-device-detect'
import _ from 'lodash'
// import { SearchOutlined } from '@ant-design/icons'
import { report } from '../../../../constants'
import TypeSelect from '../../components/TypeSelect'
import ReportHeader from '../../components/ReportHeader'

const { TabPane } = Tabs
// const { Option } = CustomSelect

const columns = t => [
  {
    title: t('reports:event'),
    dataIndex: 'event_cover',
    render: cover => {
      const thumbnail = cover.resizes && cover.resizes.thumbnail
      return (
        <img style={{ width: '70px', height: '50px' }} src={thumbnail} alt="" />
      )
    },
    width: 70
  },
  {
    dataIndex: 'title',
    textWrap: 'word-break'
  },
  {
    title: t('reports:host'),
    dataIndex: 'avator',
    render: avator => {
      if (avator !== null) return <HostAvatar size={34} src={avator.link} />
      else return <img className="user-image" src={userImage} alt="logo" />
    }
  },
  {
    title: t('reports:created_date'),
    dataIndex: 'created_at',
    render: x => {
      return moment(x).format('ll')
    },
    sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
    sortDirections: ['descend', 'ascend']
  },
  {
    title: t('reports:live_date'),
    dataIndex: 'start_date_time',
    render: x => {
      return moment(x).format('ll')
    },
    sorter: (a, b) => new Date(a.start_date_time) - new Date(b.start_date_time),
    sortDirections: ['descend', 'ascend']
  },
  {
    title: t('reports:status'),
    dataIndex: 'status',
    sorter: (a, b) => a.status.length - b.status.length,
    sortDirections: ['descend', 'ascend']
  },
  {
    title: t('reports:duration_min'),
    dataIndex: 'duration',
    sorter: (a, b) => a.duration - b.duration,
    sortDirections: ['descend', 'ascend']
  },
  {
    title: t('reports:participants'),
    dataIndex: 'views',
    sorter: (a, b) => a.views - b.views,
    sortDirections: ['descend', 'ascend']
  },
  {
    title: t('reports:comments'),
    dataIndex: 'comment',
    sorter: (a, b) => a.comment - b.comment,
    sortDirections: ['descend', 'ascend']
  },
  {
    title: t('reports:submit_poll'),
    dataIndex: 'poll',
    sorter: (a, b) => a.poll - b.poll,
    sortDirections: ['descend', 'ascend']
  }
]

const dateType = {
  allTime: '5',
  today: '1',
  week: '7',
  month: '30',
  year: '12',
  other: '2'
}

const reverseDateType = {
  5: 'all',
  1: 'today',
  7: 'week',
  30: 'month',
  12: 'year',
  2: 'other'
}

class EventsReport extends Component {
  _isMount = false

  state = {
    page: report.PAGE_NUMBER,
    pageSize: report.PAGE_SIZE,
    search: '',
    sortBy: '',
    workshopDateType: '5'
    // enableCustomDateSelect: false,
  }

  componentDidMount() {
    this._isMount = true
    const { state } = this.props.history.location
    console.log('stae', state)
    if (state && state.searchType) {
      this.setState(
        {
          workshopDateType: dateType[state.searchType],
          dateFrom: state.dateFrom,
          dateTo: state.dateTo
        },
        () => {
          if (state.dateFrom && state.dateTo !== '') {
            // this.handleSearch()
            this.fetchData()
          } else {
            this.handleSortByChange(dateType[state.searchType].toString())
          }
        }
      )
      return
    }

    this.props.getReport({
      type: 'liveEvent',
      offset: 0,
      limit: 10,
      date: 'all',
      search: '',
      from: '',
      to: ''
    })
  }
  download = () => {
    const { list } = this.props.data.liveEvent
    const events = list && list.events
    const dataCSV = events.map(x => {
      return {
        id: x.id,
        title: x.title,
        host: `${x.host.first_name} ${x.host.last_name}`,
        created_at: moment(x.created_at),
        lived_at: moment(x.lived_at),
        status: x.status,
        duration: x.duration,
        comment: x.comment,
        poll: x.poll,
        views: x.views
      }
    })
    const header = [
      '#',
      'Title',
      'Host',
      'Created at',
      'Lived   at',
      'Status',
      'Duration (Mins)',
      'Comment',
      'POLL',
      'VIEWS'
    ]

    if (!isSafari) {
      let ele = document.createElement('a')
      ele.setAttribute(
        'href',
        'data:text/csv;charset=utf-8,' +
          encodeURIComponent(generateCSV(dataCSV, header))
      )
      ele.setAttribute('download', 'event-report.csv')
      document.body.appendChild(ele)
      ele.click()
      // generateCSV(dataCSV, header)
    } else {
      let ele = document.createElement('a')
      ele.setAttribute(
        'href',
        'data:text/csv;charset=utf-8,' +
          encodeURIComponent(generateCSV(dataCSV, header))
      )
      ele.setAttribute('download', 'event-report.csv')
      ele.click()
    }
  }

  createOffset = (page, pageSize) => page * pageSize - pageSize

  onChangePagination = page => {
    const {
      pageSize,
      workshopSearchType,
      search,
      workshopDateType,
      dateFrom,
      dateTo
    } = this.state
    this.setState(
      {
        page: page,
        pageSize: pageSize,
        hasFetched: false
      },
      () => {
        this.props.getReport({
          type: 'courseLists',
          offset: this.createOffset(page, pageSize),
          limit: pageSize,
          ...(workshopSearchType && {
            date: workshopSearchType === 'allTime' ? 'all' : workshopSearchType
          }),
          search: search,
          date:
            reverseDateType[workshopDateType] === 'other'
              ? ''
              : reverseDateType[workshopDateType],
          from: reverseDateType[workshopDateType] === 'other' ? dateFrom : '',
          to: reverseDateType[workshopDateType] === 'other' ? dateTo : ''
        })
      }
    )
  }

  componentWillUnmount() {
    this._isMount = false
  }

  onSearch = _.debounce(value => {
    const { sortBy, dateFrom, dateTo } = this.state
    if (this._isMount) {
      this.setState(
        {
          search: value
        },
        () => {
          this.props.getReport({
            type: 'liveEvent',
            // offset: this.createOffset(page, pageSize),
            // limit: pageSize,
            offset: 0,
            limit: 10,
            date: sortBy === 'other' ? '' : sortBy,
            search: value,
            from: sortBy === 'other' ? dateFrom : '',
            to: sortBy === 'other' ? dateTo : ''
          })
        }
      )
    }
  }, 1000)

  onRowSelect(selectedRecord) {
    this.setState({ selectedRecord })
  }

  handleSortByChange(value) {
    const { search, page, pageSize } = this.state
    let defaultProps = {
      type: 'liveEvent',
      offset: this.createOffset(page, pageSize),
      limit: pageSize,
      date: 'today',
      search: search,
      from: '',
      to: ''
    }
    if (value === '1') {
      if (this._isMount) {
        this.setState(
          {
            sortBy: 'today',
            workshopDateType: value
            // enableCustomDateSelect: false,
          },
          () => {
            this.props.getReport({
              ...defaultProps
            })
          }
        )
      }
    } else if (value === '7') {
      if (this._isMount) {
        this.setState(
          {
            sortBy: 'week',
            workshopDateType: value
            // enableCustomDateSelect: false,
          },
          () => {
            this.props.getReport({
              ...defaultProps,
              date: 'week'
            })
          }
        )
      }
    } else if (value === '30') {
      if (this._isMount) {
        this.setState(
          {
            sortBy: 'month',
            workshopDateType: value
            // enableCustomDateSelect: false,
          },
          () => {
            this.props.getReport({
              ...defaultProps,
              date: 'month'
            })
          }
        )
      }
    } else if (value === '12') {
      if (this._isMount) {
        this.setState(
          {
            sortBy: 'year',
            workshopDateType: value
            // enableCustomDateSelect: false,
          },
          () => {
            this.props.getReport({
              ...defaultProps,
              date: 'year'
            })
          }
        )
      }
    } else if (value === '5') {
      if (this._isMount) {
        this.setState(
          {
            sortBy: 'all',
            workshopDateType: value
            // enableCustomDateSelect: false,
          },
          () => {
            this.props.getReport({
              ...defaultProps,
              date: 'all'
            })
          }
        )
      }
    } else if (value === '2') {
      if (this._isMount) {
        this.setState({
          sortBy: 'other',
          workshopDateType: value
          // enableCustomDateSelect: true,
        })
      }
    }
  }

  handleDate = val => {
    this.setState(
      {
        dateFrom: val[0],
        dateTo: val[1]
      },
      this.fetchData
    )
  }

  fetchData = () => {
    const { search, pageSize, page, dateFrom, dateTo } = this.state
    this.props.getReport({
      type: 'courseLists',
      offset: this.createOffset(page, pageSize),
      limit: pageSize,
      date: '',
      from: dateFrom,
      to: dateTo,
      search: search
    })
  }

  callbackTabClicked = key => {
    if (key === '0') {
      this.props.history.push(`/reports`)
    } else if (key === '2') {
      this.props.history.push('/reports', 'user')
    } else if (key === '3') {
      this.props.history.push('/reports', 'marketplace')
    }
  }

  render() {
    const { list, fetch } = this.props.data.liveEvent
    const events = list && list.events
    console.log('eventprops', this.props)
    console.log('events', events)
    const { workshopDateType, dateFrom, dateTo, page, pageSize } = this.state
    const { t } = this.props
    const total = list && list.pagination ? list.pagination.total : 0
    return (
      <Fragment>
        <div className="content-warp">
          <div
            className="report-warp sharelook-table"
            style={{ direction: 'ltr' }}
          >
            {/* <Button
              className="download-report"
              onClick={this.download}
              type="primary"
            >
              {t('reports:download_events')}
            </Button> */}

            <HeaderTabs
              defaultActiveKey="1"
              animated={false}
              onChange={this.changeTab}
              onTabClick={this.callbackTabClicked}
              // onTabClick={key => {
              //   if (key === '0') {
              //     this.props.history.push(`/reports`)
              //   }
              // }}
              tabBarExtraContent={
                <ReportHeader
                  workshopDateType={workshopDateType}
                  dateChange={value => this.handleSortByChange(value)}
                  download={this.download}
                  handleDate={(date, dateString) => this.handleDate(dateString)}
                  dateFrom={dateFrom}
                  dateTo={dateTo}
                  // backToDashboard={e => this.props.history.push('/reports')}
                />
              }
            >
              <TabPane
                tab={
                  <CustomTabPane>
                    <Icon type="left" style={{ fontSize: '18px' }} />
                  </CustomTabPane>
                }
                key="0"
              />
              <TabPane tab={t('reports:workshop_report')} key="1">
                <TypeSelect defaultValue="events" />
                <Row>
                  <ReportTable
                    rowKey="id"
                    columns={columns(t)}
                    dataSource={events}
                    loading={fetch}
                    borderRadius
                    // pagination={{
                    //   itemRender: RenderArrows,
                    //   pageSize: records
                    // }}
                    pagination={false}
                    onRow={record => {
                      return {
                        onClick: () =>
                          this.props.history.push(
                            `/reports/events/` + record.id
                          )
                      }
                    }}
                    title={() => (
                      <>
                        <Row
                          className="sharelook-course-wrapper sharelook-course-participants-wrapper"
                          style={{ marginLeft: '15px', marginTop: '3px' }}
                        >
                          <Col span={5}>
                            <SearchEventMargin>
                              <Search
                                placeholder={t('reports:search_event')}
                                onChange={e => this.onSearch(e.target.value)}
                              />
                            </SearchEventMargin>
                          </Col>
                          <Col span={11} />
                          <Col className="report-title" span={3} push={1}>
                            {t('v4:total')} {total} {t('v4:report_events')}
                          </Col>
                          <Col
                            className="report-pagination"
                            span={4}
                            style={{ marginTop: '14px' }}
                          >
                            <PaginationRightAligned margin>
                              <Pagination
                                total={total}
                                current={page}
                                pageSize={pageSize}
                                onChange={this.onChangePagination}
                                simple
                              />
                            </PaginationRightAligned>
                          </Col>
                        </Row>
                        <Divider style={{ margin: '0px' }} />
                      </>
                    )}
                  />
                </Row>
              </TabPane>
              <TabPane tab={t('reports:user_report')} key="2"></TabPane>
              <TabPane tab={t('v4:marketplace_report')} key="3"></TabPane>
            </HeaderTabs>
          </div>
        </div>
      </Fragment>
    )
  }
}
export default withTranslation('reports')(EventsReport)
