import styled from 'styled-components'

const Menu = styled.div`
  display: flex;
  margin-left: 25rem;
  align-items: center;
  justify-content: flex-start;
  padding: 2.4rem;
`

Menu.List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`

Menu.Item = styled.li`
  list-style: none;
  font-size: 1.6rem;
`

export {
  Menu
}
