import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import actions from '../../../../store/actions'
import Text from './Text'

const mapStateToProps = state => ({
  droppedFile: state.lessonBuilder.droppedFile,
  library: state.library,
  user: state.user,
  topics: state.topics,
  pageBuilder: state.pageBuilder
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      handleFileDropIn: actions.lessonBuilder.handleFileDropIn,
      getLibraryList: actions.library.getLibraryList,
      getPublicList: actions.library.getPublicList,
      getOrgList: actions.library.getOrgList,
      upload: actions.library.upload,
      clearUploadList: actions.library.clearUploadList,
      updateLibraryStatus: actions.library.updateLibraryStatus,
      updateOrgLib: actions.library.updateOrgLib,
      handleMakeType: actions.library.handleMakeType,
      updateTopicList: actions.topics.updateTopicList,
      showTextModal: actions.pageBuilder.showTextModal
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(Text)
