import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Select, Icon } from 'antd'
import { api } from '../../../../services'
import { withTranslation } from 'react-i18next'
import CreatableSelect from 'react-select/creatable'
import './AddTopicCard.scss'

class AddTopicCard extends Component {
  static propTypes = {
    topics: PropTypes.array,
    mediaLists: PropTypes.array,
    item: PropTypes.object,
    ctrlChangeTopic: PropTypes.func,
    keywords: PropTypes.string
  }

  static defaultProps = {
    topics: [],
    mediaLists: [],
    item: {},
    keywords: ''
  }

  constructor (props) {
    super(props)
    this.loadOptions = _.debounce(this.loadOptions, 500)
    this.state = {
      options: [],
      value: [],
      inputValue: '',
      isTopicLoading: false,
      isTopicsMax: false
    }
  }

  handleChangeTopic = ind => {
    const { mediaLists, ctrlChangeTopic } = this.props
    const { value } = this.state
    const mediaList = Object.assign([], mediaLists)

    mediaList[ind].topic_value = _.map(value, 'label')
    mediaList[ind].topic_ids = _.map(value, 'id')
    mediaList[ind].topics = _.map(value, 'id')
    ctrlChangeTopic(mediaList)
  }

  handleChangeKeyword = (event, ind) => {
    const { mediaLists, ctrlChangeKeywords } = this.props
    const mediaList = Object.assign([], mediaLists)

    mediaList[ind].keywords = event
    ctrlChangeKeywords(mediaList)
  }

  componentDidMount () {
    const {
      item: { topics: topicList },
      item
    } = this.props
    this.getOldTopics(topicList)
    this.setState({
      keywords: item.keywords
    })
  }

  getOldTopics = topics => {
    const { topics: topicList } = this.props
    // if(!isFetching){
    const topic = topics.map(item =>
      _.head(topicList.filter(topic => topic.id === item))
    )
    const newValue = topic.map(item => item && this.createOption(item))
    this.setState({
      value: newValue
    })
    // }
  }

  createOptionList = list => {
    const options = list.map(topic => this.createOption(topic))
    this.setState({
      options,
      isTopicLoading: false
    })
  }

  handleChange = (newValue, actionMeta) => {
    if (actionMeta.removedValue) {
      this.setState(
        {
          value: newValue,
          inputValue: '',
          isTopicLoading: false,
          isTopicsMax: false
        },
        () => {
          const { item_index } = this.props
          this.handleChangeTopic(item_index)
        }
      )
    } else if (!_.isEmpty(newValue) && newValue.length > 3) {
      this.setState({
        isTopicsMax: true
      })
    } else {
      this.setState(
        {
          value: newValue,
          inputValue: '',
          isTopicLoading: false,
          isTopicsMax: false
        },
        () => {
          const { item_index } = this.props
          this.handleChangeTopic(item_index)
        }
      )
    }
    this.isModalSelectOpen()
  }
  handleCreate = inputValue => {
    this.setState({ isTopicLoading: true })
    const { options, value } = this.state
    const topic = { title: inputValue }

    if (!_.isEmpty(value) && value.length >= 3) {
      this.setState({
        isTopicsMax: true,
        isTopicLoading: false
      })
    } else {
      api.topics.createTopics(topic).then(({ data }) => {
        if (!_.isEmpty(data[0])) {
          const newOption = this.createOption(data[0])
          this.props.updateTopicList(data[0])

          this.setState(
            {
              isTopicLoading: false,
              options: [...options, newOption],
              isTopicsMax: false,
              value: [...value, newOption],
              inputValue: ''
            },
            () => {
              const { item_index } = this.props
              this.handleChangeTopic(item_index)
            }
          )
        }
      })
    }
    this.isModalSelectOpen()
  }

  createOption = topic => {
    const option = {
      label: topic.title,
      value: topic.title,
      id: topic.id
    }
    return option
  }

  loadOptions = inputValue => {
    if (inputValue.length > 0 && inputValue.length <= 50) {
      this.setState({
        isTopicLoading: true
      })
      const params = {
        search: inputValue.trim(),
        limit: 10,
        offset: 0
      }
      api.topics.getTopics(params).then(({ data }) => {
        this.setState({
          inputValue
        })
        this.createOptionList(data)
      })
    }
  }

  LoadingIndicator = () => (
    <Icon style={{ paddingRight: '10px' }} type='loading'/>
  )

  isModalSelectOpen = () => {
    const { inputValue, value, isTopicsMax } = this.state
    return !_.isEmpty(value) && value.length >= 3 && isTopicsMax
      ? false
      : inputValue.length > 0
  }

  getKeywords = (item) => {
    if(!Array.isArray(item.keywords)) {
      return (item.keywords === null || item.keywords.length <= 0) ? null : item.keywords.split(',')
    } else {
      return item
    }
  }

  render () {
    const { t, item, item_index } = this.props

    const {
      value,
      options,
      isTopicLoading,
      isTopicsMax
    } = this.state

    const selectInput = document.getElementById('react-select-2-input')
    if (selectInput) {
      selectInput.maxLength = 50
    }

    const customStyles = {
      control: provided => ({
        ...provided,
        borderRadius: '0px',
        marginBottom: isTopicsMax ? '0px' : '10px',
        marginTop: '10px',
        transition: 'transparent 0.3s cubic-bezier(0.215, 0.61, 0.355, 1)'
      }),
      menu: provided => ({
        ...provided,
        maxHeight: '185px'
      }),
      menuList: provided => ({
        ...provided,
        maxHeight: '185px'
      }),
      placeholder: provided => ({
        ...provided,
        color: '#BFBFBF'
      })
    }

    const keyword = this.getKeywords(item)
    console.log('keyword: ', keyword);

    return (
      <div className='add-topic-card'>
        <div className='add-topic-card__thumb'>
          <img src={item.thumb} alt=''/>
        </div>
        <CreatableSelect
          styles={customStyles}
          components={{
            ClearIndicator: null,
            DropdownIndicator: null,
            LoadingIndicator: this.LoadingIndicator
          }}
          isMulti
          placeholder={t('labels:topics')}
          inputId='react-select-2-input'
          isLoading={isTopicLoading}
          menuIsOpen={this.isModalSelectOpen()}
          onBlur={() => this.setState({ inputValue: '' })}
          onChange={this.handleChange}
          onCreateOption={this.handleCreate}
          onInputChange={this.loadOptions}
          options={options}
          value={value}
        />

        {isTopicsMax && (
          <div className='error_message'>{t('errors:topics_max')}</div>
        )}
        <div className='add-keywords-section'>
          <Select
            defaultValue={keyword || []}
            mode="tags"
            placeholder={t('placeholders:keyword')}
            className='custom-select-tag'
            dropdownClassName='disable-select-dropdown'
            tokenSeparators={[',', '.', ';']}
            onChange={(event) => this.handleChangeKeyword(event, item_index)}
            style={{ width: '100%' }}
          />
          
        </div>
      </div>
    )
  }
}

export default withTranslation()(AddTopicCard)
