import React from 'react'

import { Select } from 'antd'

const langOptions = [
  { name: 'English (United States)', code: 'en-US' },
  { name: 'German (Germany)', code: 'de-DE' },
  { name: 'French (France)', code: 'fr-FR' },
  { name: 'Russian (Russia)', code: 'ru' },
  { name: 'Spanish (Spain)', code: 'es' },
  { name: 'Thai (Thailand)', code: 'th' },
  { name: 'Korean (Korea)', code: 'ko' },
  { name: 'Hebrew', code: 'he-He' }
]

const { Option } = Select

const AiLanguageSelect = ({ handleAiLanguage, ...props }) => {
  return (
    <Select
      {...props}
      showSearch
      style={{ width: 200 }}
      optionFilterProp="children"
      defaultValue={'en-US'}
      onChange={e => handleAiLanguage(e)}
    >
      {langOptions.map(item => (
        <Option key={item.code} value={item.code}>
          {item.name}
        </Option>
      ))}
    </Select>
  )
}

export default AiLanguageSelect
