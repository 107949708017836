import { connect } from 'react-redux'
import actions from '../../../../../store/actions'
import Form from './Form'

const mapStateToProps = state => ({
  isFetchingUser: state.user.isFetchingUser
})

const mapDispatchToProps = dispatch => ({
  signIn: (email, password, isMarketplace) =>
    dispatch(actions.user.signIn(email, password, isMarketplace))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form)
