import React, { useState } from 'react'
import { Input, Modal, Row, Col, Tag, Checkbox, Select, Button } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import './UsersModal.scss'
import { useTranslation } from 'react-i18next'

const { Option } = Select

const UsersModal = ({
  visible,
  onCancel,
  searchText,
  onSearchChange,
  users,
  selectedUsers,
  onCheckboxChange,
  onSelectAllChange,
  isSelectAllChecked,
  isInviteAble,
  isInvite,
  setIsInvite
}) => {
  const [searchType, setSearchType] = useState('all')

  const handleSearchTypeChange = value => {
    setSearchType(value)
  }

  const handleSearchChange = e => {
    const { value } = e.target
    onSearchChange(searchType, value)
  }

  // only when isInviteAble true
  const handleInviteClick = () => {
    setIsInvite(true)
    onCancel()
  }
  const { t } = useTranslation()

  return (
    <Modal visible={visible} onCancel={onCancel} footer={null} width={700}>
      <div className="filter-search-bar">
        <div className="filter-users-section">
          <Select
            className="custom-filter-select"
            defaultValue="all"
            onChange={handleSearchTypeChange}
            style={{ marginRight: 8 }}
          >
            <Option value="all">{t('reports:all')}</Option>
            <Option value="id">{t('v3:podcast_id')}</Option>
            <Option value="name">{t('reports:name')}</Option>
            <Option value="email">{t('labels:email')}</Option>
          </Select>
          <Checkbox checked={isSelectAllChecked} onChange={onSelectAllChange}>
            {t('reports:all')}
          </Checkbox>
        </div>

        <div className="searchbar-section">
          <Input
            suffix={
              <span style={{ fontSize: '1.5rem' }}>
                <SearchOutlined />
              </span>
            }
            placeholder="Search"
            value={searchText}
            onChange={handleSearchChange}
            className="custom-search-input"
          />
        </div>
      </div>

      <div className="users-list">
        <Row gutter={[10, 5]}>
          {users.map(user => {
            const id = user && user.id
            const isChecked = selectedUsers.some(u => u.id === id)
            return (
              <Col key={user && user.id} span={12}>
                <Tag className="user-tag">
                  <Checkbox
                    checked={isChecked}
                    onChange={() => onCheckboxChange(user)}
                  >
                    {user && user.email}
                  </Checkbox>
                </Tag>
              </Col>
            )
          })}
        </Row>
      </div>

      {isInviteAble && (
        <div className="invite-button-container">
          <Button
            type="primary"
            onClick={handleInviteClick} // Define a function to handle the invite action
            disabled={selectedUsers.length === 0} // Disable the button if no users are selected
          >
            {t('v3:selected_user')}
          </Button>
        </div>
      )}
    </Modal>
  )
}

export default UsersModal
