import styled, { css } from 'styled-components'
import {
  PageHeader,
  Table,
  Avatar,
  Select,
  Tabs,
  Card,
  List,
  Checkbox,
  Timeline,
  DatePicker,
  Collapse,
  Col,
  Button
} from 'antd'

const { RangePicker } = DatePicker

export const CustomCol = styled(Col).attrs(props => ({
  size: props.size,
  next: props.next
}))`
  margin-top: 25px;
  padding-right: 32px;
  padding-left: 32px;
  #enroll-wrapper {
    display: flex;
  }
  .inner-wrapper {
    width: 550px;
    position: relative;
    height: 32px;
    overflow: hidden;
  }
  .overflow-wrapper {
    position: absolute;
    left: 0px;
    right: 0px;
    width: ${props => props.size}px;
    height: 32px;
    transition: all 0.4s ease-out;
  }
  .overflow-wrapper.scrolling {
    left: -${props => props.next}px;
  }
`
const { Panel } = Collapse
export const HostAvatar = styled(Avatar)`
  &:nth-child(n + 2) {
    margin-left: -19px;
  }
`
export const CustomAvatar = styled(Avatar).attrs(props => ({
  color: props.active || '#eee'
}))`
  & {
    border: 2px solid ${props => props.color};
    margin-right: 5px !important;
  }
  &:hover {
    border: 1px solid #2f80ed;
  }
`

export const PageHeaderReport = styled(PageHeader)`
  border: 1px solid rgb(235, 237, 240);
  height: 70px;
  line-height: 2px;
  .anticon {
    font-size: 18px;
  }
  .ant-divider {
    display: none;
  }
  .ant-page-header-heading {
    margin-left: 30px;
    border-bottom: 3px solid #7fc4fd;
    padding-bottom: 25px;
  }
  .ant-page-header-heading-title {
    color: #2699fb;
    font-family: Mulish;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
  }
  button {
    width: 200px;
    height: 40px;
    font-family: Mulish;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
  }
  .ant-page-header .ant-divider-vertical {
    display: none;
  }
`
export const ReportTable = styled(Table)`
  .ant-table {
    ${props =>
      props.borderRadius &&
      `
      border-radius: 20px;
      box-shadow: 0px 4px 21px rgba(0, 0, 0, 0.1);    
      `}
  }

  .ant-table-title {
    background-color: #ffffff;
    padding: 16px 0px 0px 0px;
  }

  .ant-table table {
    border-collapse: separate;
    ${props =>
      props.borderSpacing &&
      `
      border-spacing: 0 14px;
      `}
    text-align: center;
    background-color: white;
    // border-radius: 20px;
  }

  .ant-table-tbody > tr > td {
    border-bottom: 0.5px solid #eef2f5;
  }

  thead {
    font-family: Mulish;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 13px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    ${props =>
      props.borderSpacing &&
      `
      border-spacing: 4px;
      `}
    tr {
      height: 60px;
      ${props =>
        props.borderSpacing &&
        `
        height: 0px;
        `}
      th {
        padding: 8px 16px;
        text-align: center;
        width: 0px;
        border: none;
        color: #8e92b9;
        border-bottom: 0.5px solid #eef2f5;
      }
      th:nth-child(2) {
        padding: 0px;
      }
    }
  }

  tbody {
    tr {
      // height: 80px;
      height: 36px;
      font-family: Mulish;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 15px;
      color: #000000;
      cursor:pointer td {
        // border: none !important;
        background-color: #fff;
        box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.102);
        &:first-child {
          text-align: left;
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
          box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.102);
        }
        &:last-child {
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
          box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.102);
        }
      }
      td:nth-child(2) {
        text-align: ${props => (props.textAlign ? 'center' : 'left')};
      }
    }
  }
`
export const CustomTabs = styled(Tabs)``
export const CustomCard = styled(Card)`
  font-family: Mulish;
  font-style: normal;
  background: transparent !important;
  .ant-card-cover {
    height: 200px;

    display: block;
    font-size: 14px;
    img {
      height: 100%;
      width: 100%;
    }
  }
  .ant-card-body {
    padding: 3px;
  }
  .ant-card-meta-title {
    line-height: 25px;
    font-weight: bold;
    font-size: 22px;
    color: #6c7b8a;
    margin-top: 16px;
  }

  p {
    margin-top: 15px;
    margin-bottom: 0;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: #484848;
  }
  .ant-card-head {
    min-height: unset;
  }
  .ant-card-extra {
    padding: 0;
    a {
      padding: 5px 10px;
    }
  }
`
export const HeaderTabs = styled(Tabs)``

export const CustomPanel = styled(Panel)`
  ${props =>
    props.color &&
    css`
      & {
        margin-bottom: 10px;
      }
      & .ant-collapse-item-disabled > .ant-collapse-header {
        color: ${props.color};
      }
    `}
`
export const Course = styled.div`
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #6c7b8a;
`

export const CustomSelect = styled(Select)`
  .ant-select-selection
    { width: 130px;
    border-radius:20px
    background-color:#7FC4FD;
    color:#fff;
    font-family: Mulish;
    ${props =>
      props.margin &&
      `
      margin-top: 6px;
    `}
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    border:1px solid #fff;
    .ant-select-arrow{color:#fff}
    }
    
`
export const PollTable = styled(ReportTable)`
  .ant-table-thead {
    display: none;
  }
  td {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #6c7b8a;
  }
  td:nth-child(2) {
    /* font-family: Roboto;*/
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #131311;
  }
`
export const CustomTitle = styled.h1`
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 50px;
  color: #6c7b8a;
  margin: 0;
`
export const CustomStatistic = styled.div`
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 35px;
  color: #6c7b8a;
  margin: 0;
`
export const BlueStatistic = styled.div`
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  color: #93c7fc;
  margin: 0;
`
export const CustomText = styled.span`
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 13px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #6c7b8a;
`
export const CustomList = styled(List)`
  margin: 10px;
  padding: 30px 0 0 0;
  background: #ffffff;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.102);
  border-radius: 10px;
  .ant-list-header {
    padding: 20px 15px;
  }
  .ant-list-item:hover {
    background: #f8f8f8;
    cursor: pointer;
  }
  .ant-list-item-meta-description {
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #6c7b8a;
  }
  .ant-list-item-meta-title {
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
  }
  .ant-list-item {
    padding: 25px;
  }
`
export const CommentList = styled(List)`
  margin: 10px;
  padding: 30px 0 0 0;
  background: #ffffff;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.102);
  border-radius: 10px;
  .ant-list-header {
    padding: 20px 15px;
    border-bottom: 0.5px solid #bdcadb;
  }

  .ant-list-item-meta-description {
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #6c7b8a;
  }
  .ant-list-item {
    padding: 10px 30px 0 30px;
    border-bottom: 0 !important;
  }
  .ant-list-items {
    padding: 20px 10px;
    margin-left: 1em;
    margin-right: 5em;
    padding: 0px;
  }
`
export const CustomCheckbox = styled(Checkbox)`
  ${props =>
    props.backgroundColor &&
    css`
      & .ant-checkbox-wrapper {
        margin-top: 10px;
      }
      & .ant-checkbox-checked .ant-checkbox-inner {
        background-color: ${props.backgroundColor};
        border-color: ${props.backgroundColor};
      }
      & .ant-checkbox-checked:hover {
        background-color: ${props.backgroundColor};
        border-color: ${props.backgroundColor};
      }
      & .ant-checkbox + span {
        color: ${props.backgroundColor};
      }
    `}
`

export const customTimeline = styled(Timeline)`
      .time {
            display:inline-block;
            font-weight: bold;
            font-size: 10px;
            line-height: 13px;
            color: #6C7B8A;
            }
        .workshop-box
            {
            background: #FFFFFF;
            border-radius: 10px;
            padding:10px;
            img{
            height:50px;
            width:50px;
            }
            .title{
                display:inline;
                font-weight: normal;
                font-size: 14px;
                line-height: 18px;
                color: #131311;
            }
            .type{
                font-size: 12px;
                line-height: 15px;
                color: #BDCADB;
            }
`

export const CustomDatePicker = styled(DatePicker)`
  & .ant-calendar-picker-input {
    border-radius: 30px;
    border: 2px solid #bce0fd;
    color: #7fc4fd;
  }
  & .ant-calendar-picker-input::placeholder {
    color: #7fc4fd;
  }
  & .ant-calendar-picker-icon {
    color: #7fc4fd;
  }
  &.ant-calendar-picker {
    margin-bottom: 20px;
  }
`

export const CustomTabPane = styled.div`
  .ant-tabs-nav .ant-tabs-tab {
    position: relative;
    display: inline-block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    height: 100%;
    padding: 12px 16px;
    text-decoration: none;
    cursor: pointer;
    -webkit-transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
`
export const CustomDownloadButton = styled.div`
  button {
    padding: 10px 30px;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    background: #2699fb;
    border-radius: 10px;
    border: none;
    button:hover {
      cursor: pointer;
    }
  }
`

export const CustomStatsOverviewBox = styled.div`
  .box-statistic {
    width: 131px;
    background-color: #fff;
    ${props =>
      props.selected === 'Overview' &&
      `
      background: 
      linear-gradient(#F4FAFF,#F4FAFF) padding-box,
      linear-gradient(110.38deg, #A4EFA7 18.22%, #8DC3F8 82.9%); border-box;
     border: 3px solid transparent;
     border-radius:20px;
     box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  `}
  }
`
export const CustomStatusBox = styled.div`
  flex: 1;
  .box-statistic {
    background-color: #fff;
    ${props =>
      props.selected &&
      `
      background: 
      linear-gradient(#F4FAFF,#F4FAFF) padding-box,
      linear-gradient(110.38deg, #A4EFA7 18.22%, #8DC3F8 82.9%); border-box;
     border: 3px solid transparent;
     border-radius:20px;
     box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  `}
  }
`

export const PaginationRightAligned = styled.div`
  ${props =>
    props.margin &&
    `
  margin-right: 32px;
`}
  margin-top: -4px;
  text-align: right;
`

export const QuizTitle = styled.div`
  margin-top: 16px;
`

export const SearchEventMargin = styled.div`
  margin-bottom: 20px;
`
// report changes

export const ReportSelect = styled(Select)`
  .ant-select-selection-selected-value {
    display: flex !important;
    align-items: center;
  }
  .ant-select-selection {
    display: flex;
    align-items: center;
    width: ${props =>
      props.defaultValue === 'courses' || props.defaultValue === 'events'
        ? '230px'
        : props.defaultValue === 'maze'
        ? '180px'
        : props.defaultValue === 'skill_journey'
        ? '315px'
        : props.defaultValue === 'public_conference'
        ? '300px'
        : props.defaultValue === 'live_assessments'
        ? '385px'
        : '250px'};
    background-color: transparent;
    color: black;
    font-family: Mulish;
    --font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 38px;
    border: none;
    ${props =>
      props.arrowColor &&
      `
    font-weight: 600;
    font-size: 16px;
    width: 152px;
  `}
    .ant-select-arrow {
      color: ${props => (props.arrowColor ? '#0068ff' : 'black')};
      margin-right: ${props => (props.arrowColor ? '6px' : '-2px')};
      margin-top: ${props => (props.arrowColor ? '-7px' : '-5px')};
    }
  }
`
// ReportSelect.Title = styled.span`
//   font-family: Mulish;
//   font-style: normal;
//   font-weight: bold;
//   font-size: 30px;
//   line-height: 38px;
// `

export const ReportHeaderSelect = styled(Select)`
  .ant-select-selection {
    width: 146px;
    background-color: transparent;
    color: black;
    // font-family: Mulish;
    // font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 15px;
    padding: 13px 0px 42px 0px;
    border: none;
    .ant-select-arrow {
      color: black;
    }
  }
`
export const CustomRangePicker = styled(RangePicker)`
  .ant-input {
    height: 54px;
    font-size: 16px;
    font-weight: bold;
    border: none;
    background: transparent;
    color: black;
  }
  .ant-calendar-range-picker-input {
    font-weight: bold;
  }
  .ant-calendar-range-picker-separator {
    vertical-align: initial;
  }
`

export const BackButton = styled(Button)`
  && {
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 19px;
    box-shadow: none;
    border-color: none;
    border: none;
    color: #222;
    font-family: Mulish;
    background: none;
  }
  display: block;
  justify-content: center;
  align-items: center;
`
