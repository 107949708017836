import React from 'react'
import ReactEcharts from 'echarts-for-react'
import './style.scss'
import { withTranslation } from 'react-i18next'
class OverviewChart extends React.Component {
  render() {
    const {
      graphData: {
        course = 0,
        events = 0,
        maze = 0,
        assessments = 0,
        quizes = 0,
        skillJourneys = 0,
        trivia = 0
      },
      isUserReport,
      enableMaze,
      t
    } = this.props

    const total =
      course +
      events +
      maze +
      assessments +
      skillJourneys +
      trivia +
      (isUserReport ? quizes : 0)

    const coursePercentage = ((course / total) * 100).toFixed(0)
    const eventsPercentage = ((events / total) * 100).toFixed(0)
    const mazePercentage = ((maze / total) * 100).toFixed(0)
    const assessmentsPercentage = ((assessments / total) * 100).toFixed(0)
    // const usersPercentage = ((users / total) * 100).toFixed(0)
    const quizesPercentage = ((quizes / total) * 100).toFixed(0)
    const skillJourneysPercentage = ((skillJourneys / total) * 100).toFixed(0)
    const triviaPercentage = ((trivia / total) * 100).toFixed(0)
    var colors = [
      '#FFB37C',
      '#A4EFA7',
      '#8DC3F8',
      '#246A9C',
      '#B95EF0',
      '#E1D917',
      '#FAC858'
    ]
    return (
      <div className="overview-chart">
        <div className="overview-chart-diagram">
          <ReactEcharts
            option={{
              tooltip: {
                trigger: 'item',
                formatter: '{a} <br/>{b} : {d}%'
              },
              legend: {
                // orient: 'vertical',
                // x: 'left',
                icon: 'square',
                data: [
                  t('general:course'),
                  t('v2:live_event'),
                  t('v2:live_assessment'),
                  isUserReport ? t('v4:quizes') : null,
                  enableMaze && t('v4:maze'),

                  t('v4:skill_journey'),
                  t('v4:trivia')
                ]
              },
              toolbox: {
                show: true,
                feature: {
                  mark: { show: true },
                  magicType: {
                    show: true,
                    type: ['pie', 'funnel'],
                    option: {
                      funnel: {
                        x: '25%',
                        width: '50%',
                        funnelAlign: 'center',
                        max: 1548
                      }
                    }
                  }
                  // saveAsImage: { show: true, title: 'Download chart...' }
                }
              },
              series: [
                {
                  name: 'Overview Average Activity',
                  type: 'pie',
                  radius: ['35%', '70%'],
                  // right: '-25%',
                  top: '10%',
                  label: {
                    show: true,
                    position: 'inner',
                    formatter: function(params) {
                      return params && params.value > 0
                        ? params.value + '%\n'
                        : ''
                    }
                  },
                  data: [
                    { value: coursePercentage, name: t('general:course') },
                    { value: eventsPercentage, name: t('v2:live_event') },
                    {
                      value: assessmentsPercentage,
                      name: t('v2:live_assessment')
                    },

                    {
                      value: isUserReport ? quizesPercentage : null,
                      name: isUserReport ? t('v4:quizes') : 'null'
                    },
                    enableMaze && { value: mazePercentage, name: t('v4:maze') },
                    {
                      value: skillJourneysPercentage,
                      name: t('v4:skill_journey')
                    },
                    { value: triviaPercentage, name: t('v4:trivia') }
                  ],
                  color: colors
                }
              ]
            }}
          />
        </div>
        <div className="overview-chart-notes">{t('v3:compare_last_month')}</div>
      </div>
    )
  }
}
export default withTranslation()(OverviewChart)
