import styled from 'styled-components'
import { Button } from 'antd'

// import { styleguide } from '../../../../../../constants'

// const { colors } = styleguide

const FormCreation = styled.div`
  padding: 0 2.5rem;
`

FormCreation.Submit = styled.div`
  display: flex;
  justify-content: flex-end;
`

FormCreation.Button = styled(Button)`
  padding: '0 4rem';
  background-color: '#12B903';
  color: 'white';
`

export { FormCreation }
