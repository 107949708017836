import PublishSkillJourney from './PublishSkillJourney'
import { connect } from 'react-redux'

const mapStateToProps = state => ({
  list: state.organizations.list.rows,
  isFetching: state.organizations.isFetching,
  user: state.user
})

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(PublishSkillJourney)
