import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router'
import _ from 'lodash'
import { Steps } from 'antd'
import NewHeader from '../../../../../components/NewHeader'
import { useTranslation } from 'react-i18next'

const { Step } = Steps

const MazeTopPanel = ({ location, history, id, mode }) => {
  const { t } = useTranslation()

  const tabsMatrix = {
    create: [
      {
        tab: t('v4:create_metamaze'),
        key: '/maze/create'
      },
      {
        tab: t('v4:metamaze_scenario'),
        key: '/maze/scenario'
      },
      {
        tab: t('v4:publish_metamaze'),
        key: '/maze/publish'
      }
    ],
    edit: [
      {
        tab: t('v4:create_metamaze'),
        key: '/maze/:id/edit'
      },
      {
        tab: t('v4:metamaze_scenario'),
        key: '/maze/:id/edit/scenario'
      },
      {
        tab: t('v4:publish_metamaze'),
        key: '/maze/:id/edit/publish'
      }
    ]
  }
  const [visitedSteps, setVisitedSteps] = useState([])

  const getActiveIndex = (index, activeKey) => {
    const tabs = tabsMatrix[mode]
    const activeIndex = _.findIndex(tabs, tab => tab.key === activeKey)
    return index <= activeIndex
  }

  let { pathname } = location
  let activeKey = pathname

  if (pathname.includes('/maze/create')) {
    activeKey = '/maze/create'
  } else if (pathname.includes('/edit/publish')) {
    activeKey = '/maze/:id/edit/publish'
  } else if (pathname.includes('/edit/scenario')) {
    activeKey = '/maze/:id/edit/scenario'
  } else if (pathname.includes('/edit')) {
    activeKey = '/maze/:id/edit'
  }
  const tabs = tabsMatrix[mode]
  const currentStepIndex = _.findIndex(tabs, tab => tab.key === activeKey)
  useEffect(() => {
    if (!visitedSteps.includes(currentStepIndex)) {
      setVisitedSteps(prev => [...prev, currentStepIndex])
    }
  }, [currentStepIndex])

  return (
    <div className="course-header">
      <Steps
        current={currentStepIndex}
        onChange={key => {
          if (key <= currentStepIndex) {
            history.push(tabs[key].key.replace(':id', id))
          }
        }}
      >
        {tabs.map((i, index) => (
          <Step
            title={
              <span
                className={getActiveIndex(index, activeKey) ? 'finished' : ''}
              >
                {i.tab}
              </span>
            }
            key={i.key}
            disabled={
              index !== currentStepIndex && !visitedSteps.includes(index)
            }
          />
        ))}
      </Steps>
      <NewHeader />
    </div>
  )
}

export default withRouter(MazeTopPanel)
