import React, { Component } from 'react'
import fileSaver from 'file-saver'
import ReactSVG from 'react-svg'
import { Spin, Icon } from 'antd'

import { api } from '../../services'
import './styles.scss'
import { withTranslation } from 'react-i18next'

const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />
class Attachments extends Component {
  state = {
    fileDetails: [],
    loading: true,
    isDownloading: null
  }

  async componentDidMount() {
    const {
      event: { id, attachments }
    } = this.props
    if (attachments.length > 0) {
      api.events.getAttachements(id).then(res => {
        this.setState({
          fileDetails: res.data.rows,
          loading: false
        })
      })
    } else {
      this.setState({
        loading: false
      })
    }
  }

  downloadFile = (id, filename) => {
    const { download } = this.props
    const data = {
      id
    }
    this.setState(
      {
        isDownloading: id
      },
      () => {
        download(data).then(response => {
          this.setState(
            {
              isDownloading: null
            },
            () => fileSaver.saveAs(response.downloadble_link, filename)
          )
        })
      }
    )
  }

  render() {
    const { fileDetails, loading, isDownloading } = this.state
    const { t } = this.props

    return (
      <div>
        {loading && <div>{t('loading')}</div>}
        {!loading && fileDetails.length > 0 && (
          <div className="file_container">
            {fileDetails.map((file, index) => (
              <div className="file_item" key={`file_${file.id}`}>
                <h1 className="file_title">
                  {index + 1}. {file.filename}
                </h1>
                <div
                  className="download_link"
                  role="button"
                  tabIndex="0"
                  onClick={() =>
                    isDownloading
                      ? null
                      : this.downloadFile(file.id, file.filename)
                  }
                >
                  {isDownloading === file.id ? (
                    <Spin indicator={antIcon} />
                  ) : (
                    <ReactSVG
                      svgStyle={{ height: '2rem', width: '2rem' }}
                      src={'/images/icons/menu/download.svg'}
                    />
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
        {!loading && fileDetails.length === 0 && (
          <span className="no_files">{t('no_file_attached')}</span>
        )}
      </div>
    )
  }
}

export default withTranslation('v2')(Attachments)
