import React, { Fragment } from 'react'
import className from 'classnames'
import moment from 'moment'
import { Card } from './styled'
import { Icon, Menu, Dropdown, Button } from 'antd'
import './ImageCard.scss'
import { withTranslation } from 'react-i18next'
import { general } from '../../../../../../../../../constants'

const menu = (props, data) => (
  <Menu>
    {/*<Menu.Item>
      <a target="_blank" rel="noopener noreferrer" href="">
        Rename
      </a>
    </Menu.Item>*/}
    <Menu.Item>
      <span
        style={{ color: '#1890ff', cursor: 'pointer' }}
        onClick={() => props.onEditImageDetail(data.id)}
      >
        {props.t('media:edit_detail')}
      </span>
    </Menu.Item>
    <Menu.Item>
      <span
        style={{ color: '#1890ff', cursor: 'pointer' }}
        onClick={() => props.confirmDeleteItem({ data: data })}
      >
        {props.t('media:delete')}
      </span>
    </Menu.Item>
  </Menu>
)

const ImageCard = props => {
  const {
    data,
    type,
    cardSelected,
    onDoubleClick,
    handleViewDetails,
    data: {
      title,
      filename,
      horizontal,
      created_at,
      id,
      resizes: { medium }
    }
  } = props

  const classNames = className(
    'card-image',
    { card_horizontal: horizontal },
    { 'card_on-select': cardSelected }
  )
  return (
    <Fragment>
      <div className={classNames}>
        <div
          className="card__image-thumb"
          onClick={() => handleViewDetails(id, data)}
          onDoubleClick={() => onDoubleClick(id, data)}
        >
          <Card.ImageIcon cardSelected={cardSelected}>
            {/*<Card.Icon>
              {(is_synthesized_audio &&
                <img
                  src={cardSelected ? activeSynthesizedAudio : deactiveSynthesizedAudio}
                  alt=""
                />
              )}
              {(!is_synthesized_audio &&
                <img
                  src={cardSelected ? activateSoundIcon : deactiveSoundIcon}
                  alt=""
                />
              )}

            </Card.Icon>*/}

            <img className="img-fit-into-card" src={medium} alt="" />
          </Card.ImageIcon>
        </div>
        <div className="card__content">
          <Fragment>
            {type === general.MY_MEDIA_LIBRARY && (
              <Dropdown overlay={menu(props, data)} placement="bottomLeft">
                <Button>
                  <Icon type="more" />
                </Button>
              </Dropdown>
            )}

            <Card.ImageInfo>
              <div
                className="card__title"
                title={title || filename}
                onClick={() => handleViewDetails(id, data)}
              >
                {title || filename}
              </div>
              {moment(created_at).format('DD MMM YYYY')}
            </Card.ImageInfo>
          </Fragment>
        </div>
      </div>
    </Fragment>
  )
}

export default withTranslation('image')(ImageCard)
