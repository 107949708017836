import {
  WORKSHOP_CHANNELS_FETCHING,
  WORKSHOP_CHANNELS_SUCCESS,
  WORKSHOP_CHANNELS_HAS_MORE
} from '../types'

const initialState = {
  workshopChannels: {
    list: [],
    fetch: false,
    hasMore: true
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case WORKSHOP_CHANNELS_SUCCESS:
      return {
        ...state,
        workshopChannels: {
          ...state.workshopChannels,
          list: action.payload
        }
      }

    case WORKSHOP_CHANNELS_FETCHING:
      return {
        ...state,
        workshopChannels: {
          ...state.workshopChannels,
          fetch: action.payload
        }
      }

    case WORKSHOP_CHANNELS_HAS_MORE:
      return {
        ...state,
        workshopChannels: {
          ...state.workshopChannels,
          hasMore: action.payload
        }
      }

    default:
      return state
  }
}
