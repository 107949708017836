import React from 'react'
import { Button, Typography } from 'antd'

const { Title } = Typography

const FormHeader = ({ t, mazeId, isMarketPlace, onToggleMarketPlace }) => {
  return (
    <div className="create-course">
      <div className="create-course__content">
        <Title level={3}>
          {t('v4:new_maze')}
          {isMarketPlace ? ' (Market Place)' : ''}
        </Title>
      </div>
      <div className="button-marker_place-div">
        <Button
          size="large"
          shape="round"
          onClick={onToggleMarketPlace}
          disabled={!Boolean(mazeId)}
          className="button-blue_color"
        >
          {isMarketPlace
            ? 'Save & Continue'.toUpperCase()
            : t('buttons:market_place').toUpperCase()}
        </Button>
      </div>
    </div>
  )
}

export default FormHeader
