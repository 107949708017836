import {
  ANNOTATION_LIST,
  ANNOTATION_GET_BY_ID,
  ANNOTATION_DELETE_BY_ID,
  ANNOTATION_FETCHING,
  ANNOTATION_EDITOR,
  ANNOTATION_DATA_ADD,
  ANNOTATION_DATA_UPDATE,
  ANNOTATION_DATA_DEFAULT,
  ANNOTATION_DATA_TITLE,
  ANNOTATION_EDIT_ELEMENT
} from '../types'

import { api } from '../../services'

function setEditor (bool) {
  return dispatch => {
    dispatch({
      type: ANNOTATION_EDITOR,
      payload: bool
    })
  }
}

function setEditElement (bool) {
  return dispatch => {
    dispatch({
      type: ANNOTATION_EDIT_ELEMENT,
      isEditElement: bool
    })
  }
}

function getAll () {
  return dispatch => {
    dispatch({
      type: ANNOTATION_FETCHING,
      payload: true
    })

    return api.annotation
      .getAll()
      .then(response => {
        dispatch({
          type: ANNOTATION_LIST,
          payload: response.data
        })
      })
      .then(() => {
        dispatch({
          type: ANNOTATION_FETCHING,
          payload: false
        })
      })
  }
}

function getById (id) {
  return dispatch => {
    dispatch({
      type: ANNOTATION_FETCHING,
      payload: true
    })

    return api.annotation.getById(id).then(response => {
      dispatch({
        type: ANNOTATION_GET_BY_ID,
        payload: response.data
      })

      dispatch({
        type: ANNOTATION_FETCHING,
        payload: false
      })
    })
  }
}

function deleteById (id) {
  return dispatch => {
    dispatch({
      type: ANNOTATION_FETCHING,
      payload: true
    })

    return api.annotation
      .deleteById(id)
      .then(response => {
        dispatch({
          type: ANNOTATION_DELETE_BY_ID,
          payload: {
            id
          }
        })
      })
      .then(() => {
        dispatch({
          type: ANNOTATION_FETCHING,
          payload: false
        })
      })
  }
}

function updateById (id, data) {
  return dispatch => {
    dispatch({
      type: ANNOTATION_FETCHING,
      payload: true
    })

    return api.annotation.updateById(id, data).then(response => {
      dispatch({
        type: ANNOTATION_FETCHING,
        payload: false
      })
    })
  }
}

function add (data) {
  return dispatch => {
    dispatch({
      type: ANNOTATION_FETCHING,
      payload: true
    })

    return api.annotation.add(data).then(response => {
      if (response && response.data) {
        dispatch({
          type: ANNOTATION_FETCHING,
          payload: false
        })

        return response.data.id
      }
    })
  }
}

function handleData (data, additional = false) {
  return dispatch => {
    if (additional) {
      dispatch({
        type: ANNOTATION_DATA_ADD,
        payload: {
          data
        }
      })
    } else {
      dispatch({
        type: ANNOTATION_DATA_UPDATE,
        payload: {
          data
        }
      })
    }
  }
}

function setEditorDefaults () {
  return dispatch => {
    dispatch({
      type: ANNOTATION_DATA_DEFAULT
    })
  }
}

function setPopupTitle (title) {
  return dispatch => {
    dispatch({
      type: ANNOTATION_DATA_TITLE,
      payload: {
        title
      }
    })
  }
}

export {
  setEditor,
  getAll,
  getById,
  deleteById,
  updateById,
  add,
  handleData,
  setEditorDefaults,
  setPopupTitle,
  setEditElement
}
