import React, { Component } from 'react'
import { Icon } from 'antd'
import { withTranslation } from 'react-i18next'
import history from '../../history'
import './TopPageLink.scss'
import NewHeader from '../NewHeader'

class TopPageLink extends Component {
  onClick = () => {
    const { path, hasBreakoutRoom } = this.props

    if (path) {
      history.push(path)
    }
    if (hasBreakoutRoom) {
      window.location.reload()
    }
  }

  render() {
    const { page, t } = this.props

    return (
      <div className="p-breadcrumbs p-breadcrumbs-flex">
        <div
          className="p-breadcrumbs__link p-breadcrumbs-link"
          onClick={this.onClick}
        >
          <div className="p-breadcrumbs-link__icon">
            <Icon type="left" />
          </div>
          <div className="p-breadcrumbs-link__text">
            {t('general:back_to')} <span>{page}</span>
          </div>
        </div>
        <NewHeader />
      </div>
    )
  }
}

export default withTranslation()(TopPageLink)
