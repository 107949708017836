import { CmiInteractions } from "./CmiInteractions.js"
import { CmiObjectives } from "./CmiObjectives.js"

/* eslint-disable no-unused-vars */
var SCORM_MODE_NORMAL = "normal",
	SCORM_MODE_REVIEW = "review",
	SCORM_MODE_BROWSE = "browse",

	SCORM_STATUS_PASSED = "passed",
	SCORM_STATUS_COMPLETED = "completed",
	SCORM_STATUS_FAILED = "failed",
	SCORM_STATUS_INCOMPLETE = "incomplete",
	SCORM_STATUS_BROWSED = "browsed",
	SCORM_STATUS_NOT_ATTEMPTED = "not attempted",
	SCORM_STATUS_UNKNOWN = "unknown";

export class CmiCore {
	id
	student_id
	student_name
	lesson_location
	credit
	lesson_status
	entry
	score_raw
	score_min
	score_max
	total_time
	lesson_mode
	exit
	session_time
	sco_id
	timestamp
	score_scaled
	success_status
	completion_status
	cmi_interactions
	cmi_objectives

	cmi_comments
	suspend_data
	cmi_comments_from_lms


	constructor(data) {
		Object.assign(this, data)
		this.cmi_interactions = []
		this.cmi_objectives = []

		if(data){
			if (data.cmi_interactions) {
				this.cmi_interactions = data.cmi_interactions.map(x => Object.assign(new CmiInteractions(x), x))
			}
	
			if (data.cmi_objectives) {
				this.cmi_objectives = data.cmi_objectives.map(x => Object.assign(new CmiObjectives(), x))
			}
		}
	}


	interactionCount() {
		return this.cmi_interactions.length
	}

	objectiveCount() {
		return this.cmi_objectives.length
	}


	getInteraction(index) {
		return this.cmi_interactions[index]
	}

	getObjective(index) {
		return this.cmi_objectives[index]
	}

	AddInteraction() {
		const interaction = new CmiInteractions({})
		interaction.n = this.interactionCount()
		this.cmi_interactions.push(interaction)
	}

	AddObjective() {
		const objective = new CmiObjectives()
		objective.n = this.objectiveCount()
		this.cmi_objectives.push(objective)
	}

	findNInteraction(index) {
		return this.cmi_interactions.find(x => x.n === index)
	}

	findNObjective(index) {
		return this.cmi_objectives.find(x => x.n === index)
	}

	IsValidObjectiveIndex(index) {
		index = parseInt(index, 10)
		return index <= this.objectiveCount()
	}

	IsValidInteractionIndex(index) {
		index = parseInt(index, 10)
		return index <= this.interactionCount()
	}

	IsValidInteractionObjectiveIndex(interactionIndex, objectiveIndex) {
		interactionIndex = parseInt(interactionIndex, 10);
		objectiveIndex = parseInt(objectiveIndex, 10);

		if (this.cmi_interactions[interactionIndex]) {
			return objectiveIndex <= this.cmi_interactions[interactionIndex].objectiveCount()
		}

		// if we haven't established this interaction yet, then the only valid objective index is 0
		return objectiveIndex === 0
	}

	IsValidInteractionCorrectResponseIndex(interactionIndex, responseIndex) {
		interactionIndex = parseInt(interactionIndex, 10);
		responseIndex = parseInt(responseIndex, 10);

		if (this.cmi_interactions[interactionIndex]) {
			return responseIndex <= this.cmi_interactions[interactionIndex].correctResponseCount()
		}

		// if we haven't established this interaction yet, then the only valid objective index is 0
		return responseIndex === 0
	}

}
