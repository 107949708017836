import { HIDE_LOADING_OVERLAY, SHOW_LOADING_OVERLAY } from '../types'

const defaultState = {
  isActive: false
}

const loading = (state = defaultState, action) => {
  switch (action.type) {
    case HIDE_LOADING_OVERLAY:
      return defaultState
    case SHOW_LOADING_OVERLAY:
      return {
        isActive: true
      }
    default:
      return state
  }
}

export default loading
