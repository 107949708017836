import React, { Component } from 'react'
import Dropzone from 'react-dropzone'
import { Form, Button, Select, Input, Spin, Icon, message } from 'antd'
import { ModalUsers, FormItem } from './styled'

class ModalAddUsers extends Component {
  state = {
    isLoading: false,
    fetchUpload: false
  }

  onCancel = needToReload => {
    const { form, onCancel } = this.props

    this.setState({
      isLoading: false
    })
    form.resetFields()
    onCancel(needToReload)
  }

  handleDrop = dropped => {
    // let file = dropped[0]
  }

  validateEmail = (rule, value, callback) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    if (value && !emailRegex.test(value)) {
      callback('The input is not a valid E-mail!')
    } else {
      callback()
    }
  }

  validateMultipleEmails = (rule, value, callback) => {
    if (value) {
      const emails = (value || '').split('\n').filter(i => !!i)

      if (emails.length > 0) {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
        const invalidEmails = []

        for (let i = 0; i < emails.length; i++) {
          if (!emailRegex.test(emails[i])) {
            invalidEmails.push(emails[i])
          }
        }

        if (invalidEmails.length > 0) {
          const errorMessage =
            invalidEmails.length === 1
              ? `${invalidEmails[0]} is not a valid E-mail!`
              : `${invalidEmails.join(', ')} are not valid E-mails!`
          callback(errorMessage)
        } else {
          callback()
        }
      } else {
        callback()
      }
    } else {
      callback()
    }
  }

  handleSubmit = e => {
    e.preventDefault()

    const {
      form,
      createOrganizationUser,
      updateOrganizationUser,
      organizationId,
      user
    } = this.props

    form.validateFields((err, values) => {
      if (!err) {
        this.setState({
          isLoading: true
        })

        return Promise.resolve()
          .then(() => {
            if (user) {
              console.log('values: ', values)
              updateOrganizationUser(organizationId, user.id, values).then(
                () => {
                  setTimeout(() => {
                    this.onCancel(true)
                  }, 2000)
                }
              )
            } else {
              const emails = (values.emails.toLowerCase() || '')
                .split('\n')
                .filter(i => !!i)
              createOrganizationUser(organizationId, {
                ...values,
                emails
              }).then(() => {
                setTimeout(() => {
                  this.onCancel(true)
                }, 2000)
              })
            }
          })
          .catch(err => {
            message.error(err.message)
            this.setState({
              isLoading: false
            })
          })
      }
    })
  }

  render() {
    const { form, visible, roles, groups = [], user } = this.props
    const { isLoading, fetchUpload } = this.state
    const isEdit = !!user

    return (
      <ModalUsers
        title={isEdit ? `Edit User - ${user.username}` : 'Add Users'}
        visible={visible}
        onCancel={() => this.onCancel(false)}
        style={{ maxWidth: 400 }}
      >
        <Form onSubmit={this.handleSubmit}>
          {false && !isEdit && (
            <ModalUsers.Container>
              <ModalUsers.Upload>
                <Spin spinning={fetchUpload}>
                  <Dropzone
                    accept="image/*"
                    onDrop={this.handleDrop}
                    style={{ width: '100%' }}
                  >
                    {({ isDragActive }) => (
                      <ModalUsers.Dropzone
                        active={isDragActive}
                        background={''}
                      >
                        {isDragActive ? (
                          <ModalUsers.Dropzone.Text>
                            Drop file here...
                          </ModalUsers.Dropzone.Text>
                        ) : (
                          <ModalUsers.Dropzone.Button hide={false}>
                            <Icon type="picture" /> Upload CSV File
                          </ModalUsers.Dropzone.Button>
                        )}
                      </ModalUsers.Dropzone>
                    )}
                  </Dropzone>
                </Spin>
              </ModalUsers.Upload>

              <ModalUsers.DownloadLink>
                Download sample template to import
              </ModalUsers.DownloadLink>
            </ModalUsers.Container>
          )}

          {false && !isEdit && <ModalUsers.Divider>Or</ModalUsers.Divider>}

          <ModalUsers.Container>
            {user ? (
              <FormItem label="Email">
                {form.getFieldDecorator('email', {
                  rules: [
                    { required: true, message: 'Please input email.' },
                    { min: 3, message: 'Email must be at least 3 characters.' },
                    { whitespace: true, message: 'Email cannot be empty.' },
                    { validator: this.validateEmail }
                  ],
                  initialValue: user.email
                })(<Input type="email" disabled={isEdit} />)}
              </FormItem>
            ) : (
              <FormItem label="Emails">
                {form.getFieldDecorator('emails', {
                  rules: [
                    { required: true, message: 'Please input emails.' },
                    { min: 3, message: 'Email must be at least 3 characters.' },
                    { whitespace: true, message: 'Email cannot be empty.' },
                    { validator: this.validateMultipleEmails }
                  ],
                  initialValue: ''
                })(
                  <Input.TextArea
                    rows={6}
                    type="emails"
                    placeholder="Please input emails (each email is on a new line)"
                  />
                )}
              </FormItem>
            )}

            <FormItem label="Role">
              {form.getFieldDecorator('roleId', {
                rules: [{ required: true, message: 'Please select a Role' }],
                initialValue: user ? user.role_id : null
              })(
                <Select>
                  {roles.map(role => (
                    <Select.Option key={role.id} value={role.id}>
                      {role.name}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </FormItem>

            <FormItem label="Group">
              {form.getFieldDecorator('groupIds', {
                rules: [{ required: false, message: 'Please select a Group' }],
                initialValue: user ? user.groups.map(i => i.id) : []
              })(
                <Select mode="multiple">
                  {groups.map(group => (
                    <Select.Option key={group.id} value={group.id}>
                      {group.title}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </FormItem>

            <FormItem label="Identification number (Optional)">
              {form.getFieldDecorator('employeeId', {
                rules: [
                  {
                    required: false,
                    message: 'Please add a Identification number'
                  }
                ],
                initialValue: user ? user.employee_id : null
              })(<Input type="text" />)}
            </FormItem>

            <Spin spinning={isLoading}>
              <ModalUsers.BtnGroup>
                <Button
                  type="primary"
                  className="rounded"
                  htmlType="submit"
                  style={{ margin: '0 1rem' }}
                >
                  {isEdit ? 'Edit User' : 'Add Users'}
                </Button>

                <Button
                  className="rounded"
                  onClick={() => this.onCancel(false)}
                  style={{ margin: '0 1rem' }}
                >
                  Cancel
                </Button>
              </ModalUsers.BtnGroup>
            </Spin>
          </ModalUsers.Container>
        </Form>
      </ModalUsers>
    )
  }
}

export default Form.create()(ModalAddUsers)
