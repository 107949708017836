import React, { Component } from 'react'
import { Spinner } from '../../../../../components'
import { api } from '../../../../../services'
import ReactPlayer from 'react-player'
import './Stream.scss'
import className from 'classnames'

class Stream extends Component {
  state = {
    isFetching: true,
    data: [],
    emotionsData: [],

    duration: 0,
    played: 0,
    playedSeconds: 0,

    actualMessageId: 0,
    distance: 0,
    activePoint: null
  }

  componentDidMount() {
    const {
      node: { params }
    } = this.props

    if (params.stream_type === 'ASSESSMENT') {
      api.assessments.getChatById(params.stream_id).then(({ data }) => {
        this.setState({
          isFetching: false,
          data: data
        })
      })

      api.assessments.getEmotionsById(params.stream_id).then(({ data }) => {
        this.setState({
          emotionsData: data
        })
      })
    } else if (params.stream_type === 'EVENT') {
      api.events.getChatById(params.stream_id).then(({ data }) => {
        this.setState({
          isFetching: false,
          data: data
        })
      })
    }
  }

  onProgress = state => {
    const { actualMessageId, data } = this.state

    if (this.state.seeking) {
      return
    }
    this.setState({
      played: state.played,
      playedSeconds: state.playedSeconds
    })

    const ps = Number(Math.abs(state.playedSeconds).toFixed(0))
    const actualMessage = data
      .filter(el => Number(Math.abs(el.time)) <= ps)
      .pop()

    if (actualMessage && actualMessage.id !== actualMessageId) {
      const id = actualMessage.id

      this.setState({
        actualMessageId: id
      })

      const child = document.querySelector('[data-sync-message="' + id + '"]')

      if (child) {
        console.log(child.offsetTop)

        this.setState(state => ({
          distance: child.offsetTop
        }))
      }
    } else if (!actualMessage && actualMessageId) {
      this.setState({
        distance: 0,
        actualMessageId: 0
      })
    }
  }

  onDuration = state => {
    this.setState({
      duration: state
    })
  }

  onSeekClick = e => {
    this.setState({ seeking: false })
    const target = e.currentTarget.getBoundingClientRect()
    const distance = e.pageX - target.x
    const percent = ((100 / target.width) * distance) / 100
    this.player.seekTo(percent, 'fraction')
  }

  ref = player => {
    this.player = player
  }

  cursorRef = n => {
    this.cursor = n
  }

  graphRef = n => {
    this.graph = n
  }

  onDragStartCursor = e => {
    return false
  }

  onMouseDownCursor = e => {
    this.setState({ seeking: true })

    const cursor = this.cursor
    const graph = this.graph

    var cursorCoords = this.getCoords(cursor)
    var shiftX = e.pageX - cursorCoords.left

    var graphCoords = this.getCoords(graph)

    document.onmousemove = function(e) {
      var newLeft = e.pageX - shiftX - graphCoords.left

      if (newLeft < 0) {
        newLeft = 0
      }

      var rightEdge = graph.offsetWidth - cursor.offsetWidth

      if (newLeft > rightEdge) {
        newLeft = rightEdge
      }

      cursor.style.left = newLeft + 'px'
    }

    document.onmouseup = function() {
      document.onmousemove = document.onmouseup = null
    }

    return false
  }

  getCoords(elem) {
    const box = elem.getBoundingClientRect()

    return {
      top: box.top + window.pageYOffset,
      left: box.left + window.pageXOffset
    }
  }

  render() {
    const {
      node,
      node: { params }
    } = this.props
    const {
      isFetching,
      data,
      distance,
      played,
      duration,
      activePoint
    } = this.state

    // const pointsCount = Math.ceil(duration / 5)

    return (
      <div className='stream-preview'>
        <div className='stream-preview__row stream-preview__video'>
          <ReactPlayer
            ref={this.ref}
            url={node.params.content}
            width='100%'
            height='100%'
            // onProgress={this.onProgress}
            // onDuration={this.onDuration}
            progressInterval={1000}
            style={{
              width: '100%',
              height: '100%'
            }}
            controls={true}
          />
        </div>

        {params.stream_type === 'OTHER' && (
          <div className='stream-preview__row stream-preview__graph'>
            <div
              className='b-graph'
              onClick={this.onSeekClick}
              ref={this.graphRef}
            >
              {duration > 0 && (
                <div
                  className='b-graph__data'
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <div
                    className='b-graph__cursor'
                    style={{ left: played * 100 + '%' }}
                    onDragStart={this.onDragStartCursor}
                    onMouseDown={this.onMouseDownCursor}
                    ref={this.cursorRef}
                  />

                  {activePoint && (
                    <div
                      className={className({
                        'b-graph__box': true,
                        'b-graph__box_active': activePoint
                      })}
                      style={{
                        top: `${activePoint.y -
                        document
                          .querySelector('.stream-preview')
                          .getBoundingClientRect().y -
                        30}px`,
                        left: `${activePoint.x -
                        document
                          .querySelector('.stream-preview')
                          .getBoundingClientRect().x +
                        20}px`
                      }}
                    >
                      <div className='b-graph__box-emotion b-graph__box-emotion_happy'>
                        {activePoint.data.emotions[4]}
                      </div>
                      <div className='b-graph__box-emotion b-graph__box-emotion_smile'>
                        {activePoint.data.emotions[3]}
                      </div>
                      <div className='b-graph__box-emotion b-graph__box-emotion_neutral'>
                        {activePoint.data.emotions[2]}
                      </div>
                      <div className='b-graph__box-emotion b-graph__box-emotion_sad'>
                        {activePoint.data.emotions[1]}
                      </div>
                      <div className='b-graph__box-emotion b-graph__box-emotion_angry'>
                        {activePoint.data.emotions[0]}
                      </div>
                    </div>
                  )}

                  {/* <div className='b-graph__chart'>
                    <div className='b-graph__chart-line' />
                    {[...Array(pointsCount).keys()].map((point, index) => {
                      const isPoint =
                        emotionsData &&
                        emotionsData.messages.find(
                          point => point.time === index * 5
                        )
                      return (
                        <div
                          className={className({
                            'b-graph__chart-point': true,
                            'b-graph__chart-point_1':
                              isPoint && isPoint.level === 1,
                            'b-graph__chart-point_2':
                              isPoint && isPoint.level === 2,
                            'b-graph__chart-point_3':
                              isPoint && isPoint.level === 3,
                            'b-graph__chart-point_4':
                              isPoint && isPoint.level === 4,
                            'b-graph__chart-point_5':
                              isPoint && isPoint.level === 5
                          })}
                          onMouseOver={e => {
                            console.log(
                              e.clientY,
                              this.graph.getBoundingClientRect().y
                            )
                            this.setState({
                              activePoint: isPoint
                                ? { x: e.clientX, y: e.clientY, data: isPoint }
                                : null
                            })
                          }}
                          onMouseLeave={() =>
                            this.setState({ activePoint: null })
                          }
                          style={{ left: `${(100 / pointsCount) * index}%` }}
                        />
                      )
                    })}
                  </div> */}
                </div>
              )}
            </div>
          </div>
        )}

        {params.chat && (
          <div className='stream-preview__row stream-preview__chat'>
            <div
              style={{
                transform: 'translateY(' + -distance + 'px)',
                overflow: 'scroll',
                height: '100%'
              }}
            >
              {isFetching ? (
                <Spinner size='small' />
              ) : (
                data.map(message => (
                  <div
                    className='chat__message'
                    key={message.id}
                    data-sync-message={message.id}
                  >
                    <div className='chat-message'>
                      <div className='chat-message__data'>
                        <div className='chat-message__author'>
                          {message.user.username}
                        </div>
                        <div className='chat-message__text'>
                          {message.content}
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default Stream
