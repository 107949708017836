import Badge from './badge'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import actions from '../../../../../../../store/actions'

const mapStateToProps = state => ({
  library: state.library
})

const mapDispatchToProps = dispatch => bindActionCreators({
  getLibraryList: actions.library.getLibraryList,
  upload: actions.library.upload
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Badge)
