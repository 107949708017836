import React, { useState, useEffect } from 'react';
import { Select, Form, Row, Col } from 'antd';
import { voicesData } from './listVoices';

const { Option } = Select;

const ListVoices = (props) => {
  const [selectedGender, setSelectedGender] = useState((props.mazeAvatarId !== undefined ? props.mazeAvatarId === 48683 ? 'male' : 'female' : null));
  const [selectedVoice, setSelectedVoice] = useState(props.voice_name || null);
  const [selectedId, setselectedId] = useState(null);
  const [selectedAccent, setSelectedAccent] = useState(props.mazeVoiceAccent || null);
  const [filteredVoices, setFilteredVoices] = useState([]);
  const [sortedAccents, setSortedAccents] = useState([]);

  const handleGenderChange = (value) => {
    setSelectedGender(value);
    setSelectedVoice(null);
    setselectedId(null);
    setSelectedAccent(null);
  };

  const handleVoiceChange = (value) => {
    setSelectedVoice(value.name);
    setselectedId(value.id);
    setSelectedAccent(null);
  };

  const handleAccentChange = (value) => {
 
    setSelectedAccent(value);
    props.handleElevenLabsVoiceSelect({
      selectedGender: selectedGender,
      selectedVoice: selectedVoice,
      selectedId: selectedId,
      selectedAccent: value,
      selectedVoiceType: props.selectedVoiceType
    })
  };

  useEffect(() => {
    if (selectedGender) {
      const voices = voicesData.voices
        .filter(voice => voice.labels.gender === selectedGender)
        .sort((a, b) => a.name.localeCompare(b.name)); 
      setFilteredVoices(voices);

      const uniqueAccents = [...new Set(voices.map(voice => voice.labels.accent))].sort();
      setSortedAccents(uniqueAccents);
    } else {
      setFilteredVoices([]);
      setSortedAccents([]);
    }
  }, [selectedGender]);

  return (
    <Form layout="vertical">
      <Row gutter={16} align="middle" style={{ marginBottom: '16px' }}>
        <Col span={6}>
          <label style={{ marginTop: '0.5em' }}>Gender</label>
        </Col>
        <Col span={18}>
          <Select
            placeholder="Select Gender"
            onChange={handleGenderChange}
            value={selectedGender}
            style={{ width: '100%' }}
            disabled={!props.selectedVoiceType}
          >
            <Option value="male">Male</Option>
            <Option value="female">Female</Option>
          </Select>
        </Col>
      </Row>

      <Row gutter={16} align="middle" style={{ marginBottom: '16px' }}>
        <Col span={6}>
          <label style={{ marginTop: '0.5em' }}>Voice Name</label>
        </Col>
        <Col span={18}>
          <Select
            placeholder="Select Voice"
            value={selectedVoice}
            onChange={handleVoiceChange}
            disabled={!filteredVoices.length}
            style={{ width: '100%' }}
          >
            {filteredVoices.map((voice, index) => (
              <Option key={`${voice.voice_id}-${index}`} value={{ name: voice.name, id: voice.voice_id }}>
                {voice.name}
              </Option>
            ))}
          </Select>
        </Col>
      </Row>

      <Row gutter={16} align="middle">
        <Col span={6}>
          <label style={{ marginTop: '0.5em' }}>Accent</label>
        </Col>
        <Col span={18}>
          <Select
            placeholder="Select Accent"
            value={selectedAccent}
            onChange={handleAccentChange}
            disabled={!sortedAccents.length}
            style={{ width: '100%' }}
          >
            {sortedAccents.map(accent => (
              <Option key={accent} value={accent}>
                {accent}
              </Option>
            ))}
          </Select>
        </Col>
      </Row>
    </Form>
  );
};

export default ListVoices;
