import styled from 'styled-components'
import ReactSVG from 'react-svg'
import { Button } from 'antd'
import { Head } from '../../../../styles'
import { styleguide } from '../../../../constants'
import { Filter } from '../../../../components'

const { colors } = styleguide

const Page = styled.div`
  padding: 4rem 3.2rem;
`

Page.Head = styled(Head)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
`

Page.Head.Button = styled(Button)`
  && {
    height: 4rem;
    padding: 0 2rem;
  }
`

const Column = styled.div`
`

Page.Filter = styled(Filter)`
  && {
    justify-content: flex-start;
  }
`

const Table = styled.div`
  margin: 3rem 0;
`

Table.Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

Table.Head = styled.div`
  border-top: .1rem solid ${colors.darkGreyThree};
  border-bottom: .1rem solid ${colors.darkGreyThree};
  display: flex;
  align-items: center;
  padding: 1.3rem 0;
  margin-top: 1.5rem;
`

Table.Head.Column = styled.div`
  width: calc(50% - 5rem);
  font-weight: 700;

  &:not(:first-child) {
    padding-left: 1.5rem;
  }
`

Table.Label = styled.div`
  color: ${colors.blue};
  font-weight: 600;
  white-space: nowrap;
`

Table.Body = styled.div`
`

Table.Column = styled.div`
  width: calc(50% - 5rem);
  display: flex;
  align-items: center;
  padding-right: 1.5rem;

  &:not(:first-child) {
    border-left: .1rem solid ${colors.darkGreyThree};
    padding-left: 1.5rem;
  }
`

Table.Item = styled.div`
  border-bottom: .1rem solid ${colors.darkGreyThree};
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  padding: 2rem 0;
`

Table.Item.Info = styled.div`
`

Table.Item.Organisation = styled.span`
`

Table.Events = styled.div`
  width: 10rem;
  border-left: .1rem solid ${colors.darkGreyThree};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1.5rem;
`

Table.EventLink = styled.a`
  display: inline-block;
  margin: 0 1.2rem;
`

Table.EventIcon = styled(ReactSVG)`
  height: 1.7rem;

  svg {
    width: 1.5rem;

    path {
      fill: ${colors.blue};
    }
  }
`

export {
  Page,
  Column,
  Table
}
