import {
  SHOW_TEXT_EDIT_MODAL,
  SHOW_IMAGE_EDIT_MODAL,
  SHOW_VIDEO_EDIT_MODAL,
  SHOW_ANNOTATION_IMAGE_MODAL,
  SHOW_ANNOTATION_TEXT_MODAL,
  SHOW_ANNOTATION_VIDEO_MODAL,
  SHOW_ANNOTATION_AUDIO_MODAL,
  SHOW_ANNOTATION_MODAL,
  SHOW_QUIZ_EDIT_MODAL,
  SHOW_TEXT_MODAL,
  SHOW_INSERT_VIDEO_MODAL,
  SHOW_CREATE_AUDIO_MODAL,
  SHOW_SCENARIO_MODAL
} from '../types'

const initialState = {
  uid: '',
  content: '',
  contentId: '',
  annotationUid: '',
  isShowEditText: false,
  isShowEditImage: false,
  isShowEditQuiz: false,
  isShowEditVideo: false,
  isShowAnnotationImage: false,
  isShowAnnotationText: false,
  isShowAnnotationVideo: false,
  isShowAnnotation: false,
  isShowTextModal: false,
  isShowInsertVideoModal: false,
  isShowGenerateAudioModal: false,
  isShowScenarioModal: false
}

const pageBuilder = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_TEXT_EDIT_MODAL:
      return {
        ...state,
        uid: action.payload.uid,
        content: action.payload.content,
        isShowEditText: action.payload.isShowEditText
      }

    case SHOW_ANNOTATION_TEXT_MODAL:
      return {
        ...state,
        content: action.payload.content,
        isShowAnnotationText: action.payload.isShowAnnotationText
      }

    case SHOW_ANNOTATION_MODAL:
      return {
        ...state,
        annotationUid: action.payload.uid,
        isShowAnnotation: action.payload.isShowAnnotation,
        contentId: action.payload.contentId
      }

    case SHOW_ANNOTATION_VIDEO_MODAL:
      return {
        ...state,
        content: action.payload.content,
        isShowAnnotationVideo: action.payload.isShowAnnotationVideo
      }
    case SHOW_ANNOTATION_AUDIO_MODAL:
      return {
        ...state,
        content: action.payload.content,
        isShowAnnotationAudio: action.payload.isShowAnnotationAudio
      }

    case SHOW_ANNOTATION_IMAGE_MODAL:
      return {
        ...state,
        content: action.payload.content,
        isShowAnnotationImage: action.payload.isShowAnnotationImage
      }
    case SHOW_IMAGE_EDIT_MODAL:
      return {
        ...state,
        uid: action.payload.uid,
        contentId: action.payload.contentId,
        isShowEditImage: action.payload.isShowEditImage
      }

    case SHOW_VIDEO_EDIT_MODAL:
      return {
        ...state,
        contentId: action.payload.contentId,
        isShowEditVideo: action.payload.isShowEditVideo
      }
    case SHOW_QUIZ_EDIT_MODAL:
      return {
        ...state,
        uid: action.payload.uid,
        contentId: action.payload.contentId,
        isSimulation: action.payload.isSimulation || false,
        isShowEditQuiz: action.payload.isShowEditQuiz
      }
    case SHOW_TEXT_MODAL:
      return {
        ...state,
        isShowTextModal: action.payload.isShowTextModal
      }

    case SHOW_INSERT_VIDEO_MODAL:
      return {
        ...state,
        uid: action.payload.uid,
        isShowInsertVideoModal: action.payload.isShowInsertVideoModal
      }

    case SHOW_CREATE_AUDIO_MODAL:
      return {
        ...state,
        content: action.payload.content,
        isShowGenerateAudioModal: action.payload.isShowGenerateAudioModal
      }

    case SHOW_SCENARIO_MODAL:
      return {
        ...state,
        content: action.payload.content,
        isShowScenarioModal: action.payload.isShowScenarioModal
      }
    default:
      return state
  }
}

export default pageBuilder
