import { connect } from 'react-redux'

import actions from '../../../../store/actions'

import Groups from './Groups'

export default connect(
  state => ({
    organizations: state.organizations,
    user: state.user.info
  }),
  dispatch => ({
    deleteGroup: (orgId, id) =>
      dispatch(actions.organizations.deleteGroup(orgId, id)),
    createGroup: (orgId, data) =>
      dispatch(actions.organizations.createGroup(orgId, data)),
    updateGroup: (id, orgId, data) =>
      dispatch(actions.organizations.updateGroup(id, orgId, data)),
    searchGroup: data => dispatch(actions.organizations.searchOrgGroup(data))
  })
)(Groups)
