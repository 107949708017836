import React, { useEffect, useMemo, useState } from 'react'
import { Builder } from '../../../../../../../../components' // Adjust the import path as needed
import { Button, Icon } from 'antd'
import StoryCreationForm from './StoryCreationForm'
import { useDispatch, useSelector } from 'react-redux'
import ScenarioHandler from './Scenario'
import * as lessonBuilderActions from '../../../../../../../../store/actions/lessonBuilder'

import {
  SortableContainer,
  SortableElement,
  arrayMove
} from 'react-sortable-hoc'
import { AiQuiz } from '../../../../../../../../components/Builder/Elements/EQuiz/internal'
import { useTranslation } from 'react-i18next'

const PageItem = SortableElement(({ children, className }) => {
  return <div className={className}>{children}</div>
})

const PagesContainer = SortableContainer(({ children, className }) => {
  return <div className={className}>{children}</div>
})

const PagePreviewSection = props => {
  const {
    lessonTitle,
    pageStructureData,
    currentPage,
    onPreviewItemClick,
    toPage,
    activeType,
    handleType,
    scenarioId,
    courseId,
    lessonId
  } = props
  const isStory = activeType === 'story'
  const isScenario = activeType === 'scenario'
  const isQuizAI = activeType === 'quiz'
  const isSelectable = isStory || isScenario || isQuizAI

  const [previewData, setPreviewData] = useState([])
  const [showStoryCreationForm, setShowStoryCreationForm] = useState(false)
  const [showScenario, setShowScenario] = useState(false)
  const [showQuizForm, setShowQuizForm] = useState(false)

  const pageBuilder = useSelector(state => state.pageBuilder)
  const dispatch = useDispatch()

  const { isShowScenarioModal, content } = pageBuilder
  const scenarioIndex = scenarioId
    ? scenarioId
    : isShowScenarioModal
    ? content
    : null

  useEffect(() => {
    if (isShowScenarioModal) {
      setShowScenario(true)
    }
  }, [isShowScenarioModal])

  useEffect(() => {
    setPreviewData(pageStructureData)
  }, [pageStructureData])

  useEffect(() => {
    if (!isStory) {
      setPreviewData(pageStructureData)
    }
  }, [isStory])

  const handlePageClick = i => {
    if (isSelectable) {
      handleSelection(i)
    } else {
      onPreviewItemClick(i)
    }
  }

  const handleSelection = i => {
    setPreviewData(pre =>
      pre.map((page, index) => {
        if (index === i) {
          return { ...page, pageSelected: !page.pageSelected }
        } else {
          return { ...page, pageSelected: page.pageSelected || false }
        }
      })
    )
  }

  const canGenerateStory = () => {
    const selectedPages = previewData.filter(page => page.pageSelected)
    return selectedPages.length > 0
  }

  const onClear = () => {
    setShowStoryCreationForm(false)
    setShowScenario(false)
    setShowQuizForm(false)
    setPreviewData(pageStructureData)
    handleType(null)
  }

  const mapOrder = array =>
    array.map((item, index) => ({ ...item, ordering: index + 1 }))

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const { pages, pageStructureData, lessonUpdate } = props
    const newPages = mapOrder(arrayMove(pages, oldIndex, newIndex))
    const newLandscapePages = mapOrder(
      arrayMove(pageStructureData, oldIndex, newIndex)
    )

    setPreviewData(newLandscapePages)

    dispatch(lessonBuilderActions.handleLessonInput('pages', newPages))
    dispatch(
      lessonBuilderActions.handleLessonInput(
        'pages_landscape',
        newLandscapePages
      )
    )
    lessonUpdate({ pages: newPages, pages_landScape: newLandscapePages })
  }

  const handleGenerateButton = () => {
    if (isStory) {
      setShowStoryCreationForm(true)
    } else if (isScenario) {
      setShowScenario(true)
    } else if (isQuizAI) {
      setShowQuizForm(true)
    }
  }

  const { t } = useTranslation()

  return (
    <div className="page-builder-preview-container">
      <span className="lesson-title">{lessonTitle}</span>
      <PagesContainer
        distance={1}
        onSortEnd={onSortEnd}
        lockAxis="y"
        lockToContainerEdges
        className="page-builder-preview-list"
        useDragHandle={isSelectable}
      >
        {previewData &&
          previewData.map((page, index) => {
            if (!page) return null

            const currentPageStructure = page.data || []
            const isCurrentPage = !isSelectable && currentPage === index + 1
            const selected = isSelectable && page.pageSelected

            return (
              <PageItem key={index} index={index} className="page-item">
                <div
                  key={index}
                  className={`page-builder-preview 
            ${isCurrentPage ? 'active' : ''}
            ${selected ? 'selected' : ''}`}
                  onClick={() => handlePageClick(index)}
                >
                  <Builder.Page
                    {...props}
                    pageStructure={currentPageStructure}
                    isPreview
                  />
                  <span className="page-number">{index + 1}</span>

                  {selected && (
                    <span className="selected-check">
                      <Icon type="check" />
                    </span>
                  )}
                </div>
              </PageItem>
            )
          })}
      </PagesContainer>

      {!isSelectable && (
        <div className="page-builder-preview add" onClick={() => toPage('new')}>
          <div className="add-new-page">
            <span>+</span>
            <p>{t('buttons:add_page')}</p>
          </div>
        </div>
      )}

      {isSelectable && (
        <div className="create-story">
          <Button
            type="green"
            shape="round"
            icon="plus"
            disabled={!canGenerateStory()}
            onClick={handleGenerateButton}
          >
            {isStory
              ? t('v4:create_story')
              : isQuizAI
              ? t('v4:generate_quiz')
              : t('v3:generate_scenario')}
          </Button>
        </div>
      )}

      {showStoryCreationForm && (
        <StoryCreationForm
          {...props}
          previewData={previewData}
          onCancel={onClear}
        />
      )}

      {showScenario && (
        <ScenarioHandler
          {...props}
          previewData={previewData}
          isShowScenarioModal={isShowScenarioModal}
          scenarioId={scenarioIndex}
          lessonId={lessonId}
          courseId={courseId}
          onClear={onClear}
        />
      )}

      {showQuizForm && (
        <AiQuiz {...props} previewData={previewData} onCancel={onClear} />
      )}
    </div>
  )
}

export default PagePreviewSection
