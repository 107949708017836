import React from 'react'
import { Icon } from 'antd'

import { styleguide } from '../../constants'

const { colors } = styleguide

function RenderArrows (current, type, originalElement) {
  const style = {
    color: colors.blue
  }

  if (type === 'prev') {
    return <Icon type='left' style={style}/>
  }
  if (type === 'next') {
    return <Icon type='right' style={style}/>
  }

  return originalElement
}

export default RenderArrows
