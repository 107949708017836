import styled from 'styled-components'
import ReactSVG from 'react-svg'
import { styleguide } from '../../../../constants'

const { colors } = styleguide

const Notifications = styled.div`
`

Notifications.Button = styled.div`
  position: relative;
  width: 3.2rem;
  height: 3.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

Notifications.Icon = styled(ReactSVG)`
  width: 100%;
  height: 100%;
  transition: opacity .2s;

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  svg {
    path {
      fill: ${colors.white};
    }
  }

  ${Notifications.Button}:hover & {
    opacity: .8;
  }
`
Notifications.Badge = styled.div`
  position: absolute;
  top: -.4rem;
  right: -.4rem;
`

export {
  Notifications
}
