import styled from 'styled-components'
import ReactSVG from 'react-svg'

import { Button, Progress, Icon } from 'antd'

import { styleguide } from '../../constants'

const { colors } = styleguide

const Card = styled.div`
  height: 100%;
  border: 0.1rem solid #ddd;
  color: ${colors.darkGreyOne};
  display: flex;
  flex-direction: column;
  margin-bottom: 1.6rem;
  position: relative;

  ${props =>
    props.horizontal &&
    `
    min-width: 27rem;
    max-width: 27rem;

    &:not(:last-child) {
      margin-right: 1rem;
    }
  `}
`

Card.Cover = styled.div`
  width: 100%;
  height: 14.5rem;
  background: url(${props => props.src}) center no-repeat;
  background-size: cover;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 1.2rem;
  position: relative;
  cursor: pointer;
`

Card.Icon = styled(Icon)`
  width: 100%;
  height: 14.5rem;
  padding: 1.2rem;
  font-size: 12rem;
`

Card.Duration = styled.div`
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 1.2rem;
  font-weight: 600;
  font-size: 1.1rem;
  color: ${colors.white};
  padding: 0.2rem 1rem;
`

Card.CoverDate = styled.div`
  width: 4rem;
  height: 4rem;
  background-color: ${colors.blue};
  color: ${colors.white};
  display: flex;
  font-weight: 600;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
`
Card.Overlay = styled.div`
  width: 100%;
  height: 100%;
  color: rgba(255, 255, 255, 0.6);
  font-size: 4rem;
  font-weight: 600;
  display: flex;
  background-color: rgba(0, 0, 0, 0.35);
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
`

Card.OverlayPlayer = styled.div`
  width: 100%;
  height: 100%;
  color: rgba(255, 255, 255, 0.6);
  font-size: 3rem;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
`

Card.CoverDate.Day = styled.span`
  line-height: 1;
  font-size: 1.6rem;
`

Card.CoverDate.Month = styled.span`
  font-size: 1.1rem;
  text-transform: uppercase;
`

Card.Body = styled.div`
  background-color: ${colors.white};
  flex: 1;
  padding: 2rem 2rem 0.5rem 2rem;
`

Card.Title = styled.a`
  width: 100%;
  color: ${colors.blue};
  display: inline-block;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

Card.Tags = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 1rem;
`

Card.Tag = styled.span`
  border: .1rem solid transparent;
  border-radius: 2rem;
  display: inline-block;
  font-size: 1rem;
  font-weight: 600;
  padding: .1rem 1rem;
  text-transform: uppercase;
  white-space: nowrap;

  ${props =>
    props.type === 'format' &&
    `
    background-color: #ddd;
  `}

  ${props =>
    props.type === 'mime' &&
    `
    margin-left: .25rem;
  `}

  ${props =>
    props.type === 'type' &&
    `
    border-color: ${colors.blue};
    color: ${colors.blue};
    font-weight: 700;
  `}

  ${props =>
    props.type === 'soon' &&
    `
    background-color: ${colors.orange};
    border-color: ${colors.orange};
    color: ${colors.white};
    margin-left: 1rem;
  `}
`

Card.Date = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.1rem;
  margin-top: 1.2rem;
  text-transform: uppercase;
`

Card.Time = styled.div`
  margin: 0.5rem 0;
  color: #595959;
  font-size: 13px;

  i {
    margin-right: 0.5rem;
  }
`

Card.ClockTime = styled.div`
  margin: 0.5rem 0;
  color: #595959;
  font-size: 12px;

  i {
    margin-right: 0.5rem;
  }
`

Card.Info = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
`

Card.TotalLessons = styled.div`
  margin-top: 1.5rem;
  font-size: 12px;
  color: #939393;
  font-weight: bold;
`

Card.Creator = styled.div`
  font-size: 1.1rem;
  margin: 0.5rem 0;
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 90%;
  color: ${colors.purple};
`

Card.Creator.Name = styled.span`
  font-weight: 700;
  letter-spacing: 0.05rem;
  margin-left: 0.2rem;
`

Card.Stats = styled.div`
  display: flex;
  align-items: center;
`

Card.ItemStats = styled.span`
  display: flex;
  align-items: center;
  font-size: 1.1rem;
  margin-right: 1.5rem;
  font-weight: bold;
`

Card.Price = styled.div`
  line-height: 1;
  font-size: 1.5rem;
  color: ${colors.blue};
`

Card.IconCard = styled(ReactSVG)`
  height: 1rem;
  line-height: 1;
  margin-right: 0.4rem;
`

Card.InfoEnroll = styled.div`
  margin-bottom: 1rem;
`

Card.Buttons = styled.div`
  display: flex;
  align-content: center;
  margin: 2.5rem 0 1.5rem 0;
`

Card.Buttons.Add = styled(Button)`
  && {
    height: 2.5rem;
    font-size: 1.1rem;
    margin-right: 0.5rem;
  }
`

Card.Buttons.Details = styled(Button)`
  && {
    height: 2.5rem;
    border-color: ${colors.blue};
    border-radius: 5rem;
    color: ${colors.blue};
    font-size: 1.1rem;
    font-weight: 600;
  }
`

Card.ProgressBar = styled(Progress)`
  && {
    position: absolute;
    bottom: -0.4rem;
    left: 0;

    .ant-progress-inner {
      background-color: #ddd;
      border-radius: 0;
    }

    .ant-progress-bg {
      height: 1rem !important;
    }
  }
`

const Container = styled.div`
  display: flex;
  flex: 1;
`
const View = styled.div`
  display: flex;
  flex: 1;
`
View.Viewer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  > div {
    padding-right: 1rem;
  }
  > i {
    padding-right: 0.5rem;
  }
`
Card.Progress = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`

Card.ProgressText = styled.span`
  font-size: 12px;
  color: #37bd06;
  font-weight: 500;
`

export { Card, View, Container }
