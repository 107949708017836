import React, { Fragment } from 'react'
import className from 'classnames'
import moment from 'moment'
import { Card } from './styled'
import './TextCard.scss'
import { withTranslation } from 'react-i18next'
import { textIcon } from '../../images'

const TextCard = props => {
  const {
    data,
    cardSelected,
    handleViewDetails,
    handleTextModalView,
    data: {
      title,
      filename,
      horizontal,
      created_at,
      id
    }
  } = props

  const classNames = className(
    'card-image',
    { card_horizontal: horizontal },
    { 'card_on-select': cardSelected }
  )
  return (
    <Fragment>
      <div className={classNames}>
        <div
          className='card__image-thumb'
          onClick={() => handleViewDetails(id, data)}
          onDoubleClick={() => handleTextModalView()}
        >
          <Card.ImageIcon cardSelected={cardSelected}>
            <img className='img-fit-into-card' src={textIcon} alt='' />
          </Card.ImageIcon>
        </div>
        <div className='card__content'>
          <Fragment>
            <Card.ImageInfo>
              <div
                className='card__title'
                title={title || filename}
                onClick={() => handleViewDetails(id, data)}
              >
                {title || filename}
              </div>
              {moment(created_at).format('DD MMM YYYY')}
            </Card.ImageInfo>
          </Fragment>
        </div>
      </div>
    </Fragment>
  )
}

export default withTranslation('image')(TextCard)
