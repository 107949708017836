/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-08-07 15:55:38
 * @LastEditTime: 2019-08-16 17:09:59
 * @LastEditors: Please set LastEditors
 */
import moment from 'moment'

import {
  WORKSHOP_COURSES_FETCHING,
  WORKSHOP_COURSES_SUCCESS,
  WORKSHOP_COURSES_HAS_MORE,
  WORKSHOP_EVENTS_FETCHING,
  WORKSHOP_EVENTS_SUCCESS,
  WORKSHOP_EVENTS_HAS_MORE,
  WORKSHOP_PODCAST_FETCHING,
  WORKSHOP_PODCAST_SUCCESS,
  WORKSHOP_PODCAST_HAS_MORE,
  WORKSHOP_ASSESSMENTS_SUCCESS,
  WORKSHOP_ASSESSMENTS_FETCHING,
  WORKSHOP_ASSESSMENTS_HAS_MORE,
  TRAINING_COURSES_FETCHING,
  TRAINING_COURSES_SUCCESS,
  TRAINING_COURSES_HAS_MORE,
  TRAINING_EVENTS_FETCHING,
  TRAINING_EVENTS_SUCCESS,
  TRAINING_EVENTS_HAS_MORE,
  ENROLLED_COURSES_SUCCESS,
  ENROLLED_COURSES_FETCHING,
  PAST_EVENTS_FETCHING,
  PAST_EVENTS_SUCCESS,
  NOW_LIVE_EVENTS_FETCHING,
  NOW_LIVE_EVENTS_SUCCESS,
  WEBINAR_EVENTS_FETCHING,
  WEBINAR_EVENTS_SUCCESS,
  PEER_TO_PEER_FETCHING,
  PEER_TO_PEER_SUCCESS,
  CONFERENCE_FETCHING,
  CONFERENCE_SUCCESS,
  WORKSHOP_SKILLJOURNEYS_FETCHING,
  WORKSHOP_SKILLJOURNEYS_SUCCESS,
  WORKSHOP_SKILLJOURNEYS_HAS_MORE,
  WORKSHOP_MAZES_FETCHING,
  WORKSHOP_MAZES_SUCCESS,
  WORKSHOP_MAZES_HAS_MORE,
  WORKSHOP_CHANNELS_FETCHING,
  WORKSHOP_CHANNELS_SUCCESS,
  WORKSHOP_CHANNELS_HAS_MORE
} from '../types'

import api from '../../services/api'

const checkHasMore = arr => arr && arr.length && arr.length > 0

export const getWorkshopData = (params, isOrderChanged) => (
  dispatch,
  getState
) => {
  const {
    courses: {
      workshopCourses: {
        fetch: coursesFetch,
        list: coursesList,
        hasMore: courseHasMore
      }
    },
    events: {
      workshopEvents: {
        fetch: eventsFetch,
        list: eventsList,
        hasMore: eventsHasMore
      },
      workshopPodcasts: {
        fetch: podcastsFetch,
        list: podcastsList,
        hasMore: podcastsHasMore
      }
    },
    // assessments: {
    //   workshopAssessments: {
    //     fetch: assessmentsFetch,
    //     list: assessmentsList,
    //     hasMore: assessmentsHasMore
    //   }
    // },
    skillJourney: {
      workshopSkillJourneys: {
        fetch: skillJourneysFetch,
        list: skillJourneysList,
        hasMore: skillJourneysHasMore
      }
    },
    maze: {
      workshopMazes: {
        fetch: mazesFetch,
        list: mazesList,
        hasMore: mazesHasMore
      }
    },
    channel: {
      workshopChannels: {
        fetch: channelsFetch,
        list: channelsList,
        hasMore: channelsHasMore
      }
    },
    user: {
      info: { active_organization_id: activeOrgId, organization }
    }
  } = getState()
  if (
    coursesFetch ||
    eventsFetch ||
    podcastsFetch ||
    // assessmentsFetch ||
    skillJourneysFetch ||
    mazesFetch ||
    channelsFetch
  ) {
    return
  }

  const d = (type, payload) =>
    dispatch({
      type,
      payload
    })

  const coursesParams = {
    ...params,
    mode: 'workshop',
    offset: isOrderChanged ? 0 : coursesList.length
  }

  const eventsParams = {
    ...params,
    mode: 'workshop',
    offset: isOrderChanged ? 0 : eventsList.length
  }
  const podcastsParams = {
    ...params,
    mode: 'workshop',
    order: 'created_at',
    schedule_state: 'all',
    event_type: 'discussion_room',
    offset: isOrderChanged ? 0 : podcastsList.length
  }

  // const assessmentsParams = {
  //   ...params,
  //   mode: 'workshop',
  //   offset: isOrderChanged ? 0 : assessmentsList.length
  // }

  const skillJourneysParams = {
    ...params,
    mode: 'workshop',
    offset: isOrderChanged ? 0 : skillJourneysList.length
  }

  const mazesParams = {
    ...params,
    mode: 'workshop',
    offset: isOrderChanged ? 0 : mazesList.length
  }

  const channelsParams = {
    ...params,
    mode: 'workshop',
    offset: isOrderChanged ? 0 : channelsList.length
  }

  d(WORKSHOP_COURSES_FETCHING, true)
  d(WORKSHOP_EVENTS_FETCHING, true)
  d(WORKSHOP_PODCAST_FETCHING, true)
  // d(WORKSHOP_ASSESSMENTS_FETCHING, true)
  d(WORKSHOP_SKILLJOURNEYS_FETCHING, true)
  d(WORKSHOP_MAZES_FETCHING, true)
  d(WORKSHOP_CHANNELS_FETCHING, true)

  if (isOrderChanged) {
    d(WORKSHOP_COURSES_SUCCESS, [])
    d(WORKSHOP_EVENTS_SUCCESS, [])
    d(WORKSHOP_PODCAST_SUCCESS, [])
    // d(WORKSHOP_ASSESSMENTS_SUCCESS, [])
    d(WORKSHOP_SKILLJOURNEYS_SUCCESS, [])
    d(WORKSHOP_MAZES_SUCCESS, [])
    d(WORKSHOP_CHANNELS_SUCCESS, [])
  }
  return Promise.allSettled([
    courseHasMore || isOrderChanged
      ? api.courses.getCoursesList(coursesParams)
      : Promise.resolve({
          data: []
        }),
    eventsHasMore || isOrderChanged
      ? api.events.get(eventsParams)
      : Promise.resolve({
          data: []
        }),
    podcastsHasMore || isOrderChanged
      ? api.events.get(podcastsParams)
      : Promise.resolve({
          data: []
        }),
    // assessmentsHasMore || isOrderChanged
    //   ? api.assessments.getAll(assessmentsParams)
    //   : Promise.resolve({
    //       data: {
    //         rows: []
    //       }
    //     }),
    skillJourneysHasMore || isOrderChanged
      ? api.skillJourney.listSkillJourneys(skillJourneysParams)
      : Promise.resolve({
          data: []
        }),
    (mazesHasMore || isOrderChanged) &&
    (activeOrgId === null ||
      activeOrgId === 1 ||
      (organization && organization.enable_maze))
      ? api.maze.listMazes(mazesParams)
      : Promise.resolve({
          data: {
            data: []
          }
        }),
    channelsHasMore || isOrderChanged
      ? api.channel.listChannels(channelsParams)
      : Promise.resolve({
          data: []
        })
  ]).then(resolvedResponse => {
    const [
      courseResponse,
      eventsResponse,
      podcastsResponse,
      // assessmentsResponse,
      skillJourneysResponse,
      mazesResponse,
      channelsResponse
    ] = resolvedResponse

    const coursesData = courseResponse.value.data || []
    const eventsData = eventsResponse.value.data || []
    const podcastsData = podcastsResponse.value.data || []
    // const assessmentsData =
    //   (assessmentsResponse.value && assessmentsResponse.value.data && assessmentsResponse.value.data.rows) ||
    //   []
    const skillJourneysData = skillJourneysResponse.value.data || []
    const mazesData =
      (mazesResponse.status === 'fulfilled' &&
        mazesResponse.value.data &&
        mazesResponse.value.data.data) ||
      []
    const channelsData = channelsResponse.value.data || []

    d(WORKSHOP_COURSES_FETCHING, false)
    d(WORKSHOP_EVENTS_FETCHING, false)
    d(WORKSHOP_PODCAST_FETCHING, false)
    // d(WORKSHOP_ASSESSMENTS_FETCHING, false)
    d(WORKSHOP_SKILLJOURNEYS_FETCHING, false)
    d(WORKSHOP_MAZES_FETCHING, false)
    d(WORKSHOP_CHANNELS_FETCHING, false)

    d(WORKSHOP_COURSES_HAS_MORE, checkHasMore(coursesData))
    d(WORKSHOP_EVENTS_HAS_MORE, checkHasMore(eventsData))
    d(WORKSHOP_PODCAST_HAS_MORE, checkHasMore(podcastsData))
    // d(WORKSHOP_ASSESSMENTS_HAS_MORE, checkHasMore(assessmentsData))
    d(WORKSHOP_SKILLJOURNEYS_HAS_MORE, checkHasMore(skillJourneysData))
    d(WORKSHOP_MAZES_HAS_MORE, checkHasMore(mazesData))
    d(WORKSHOP_CHANNELS_HAS_MORE, checkHasMore(channelsData))

    const newCourses =
      coursesData.length > 0
        ? coursesData.map(i => ({
            ...i,
            type: 'Course'
          }))
        : []
    const newEvents =
      eventsData.length > 0
        ? eventsData.map(i => ({
            ...i,
            type: 'Live Event'
          }))
        : []
    const newPodcasts =
      podcastsData.length > 0
        ? podcastsData.map(i => ({
            ...i,
            type: 'Podcast'
          }))
        : []
    // const newAssessments =
    //   assessmentsData.length > 0
    //     ? assessmentsData.map(i => ({
    //         ...i,
    //         type: 'Live Assessment'
    //       }))
    //     : []
    const newSkillJourneys =
      skillJourneysData.length > 0
        ? skillJourneysData.map(i => ({
            ...i,
            type: 'Skill Journey'
          }))
        : []
    const newMazes =
      mazesData.length > 0
        ? mazesData.map(i => ({
            ...i,
            type: 'Maze'
          }))
        : []
    const newChannels = channelsData.map(i => ({
      ...i,
      type: 'Channel'
    }))
    const union = newCourses
      .concat(newEvents)
      .concat(newPodcasts)
      // .concat(newAssessments)
      .concat(newSkillJourneys)
      .concat(newMazes)
      .concat(newChannels)
      .sort((a, b) => moment(b.created_at).diff(moment(a.created_at)))
    // .slice(0, params.limit)
    const events = union.filter(i => i.type === 'Live Event')
    const podcasts = union.filter(i => i.type === 'Podcast')
    const courses = union.filter(i => i.type === 'Course')
    // const assessments = union.filter(i => i.type === 'Live Assessment')
    const skilljourneys = union.filter(i => i.type === 'Skill Journey')
    const mazes = union.filter(i => i.type === 'Maze')
    const channels = union.filter(i => i.type === 'Channel')
    d(
      WORKSHOP_COURSES_SUCCESS,
      (isOrderChanged ? [] : coursesList).concat(courses)
    )
    d(
      WORKSHOP_EVENTS_SUCCESS,
      (isOrderChanged ? [] : eventsList).concat(events)
    )
    d(
      WORKSHOP_PODCAST_SUCCESS,
      (isOrderChanged ? [] : podcastsList).concat(podcasts)
    )
    // d(
    //   WORKSHOP_ASSESSMENTS_SUCCESS,
    //   (isOrderChanged ? [] : assessmentsList).concat(assessments)
    // )
    d(
      WORKSHOP_SKILLJOURNEYS_SUCCESS,
      (isOrderChanged ? [] : skillJourneysList).concat(skilljourneys)
    )
    d(WORKSHOP_MAZES_SUCCESS, (isOrderChanged ? [] : mazesList).concat(mazes))

    d(
      WORKSHOP_CHANNELS_SUCCESS,
      (isOrderChanged ? [] : channelsList).concat(channels)
    )
  })
}

export const getTrainingData = (params, isOrderChanged) => (
  dispatch,
  getState
) => {
  const {
    courses: {
      trainingCourses: {
        fetch: coursesFetch,
        list: coursesList
        // hasMore: courseHasMore
      }
    },
    events: {
      trainingEvents: {
        fetch: eventsFetch,
        list: eventsList
        // hasMore: eventsHasMore
      }
    }
  } = getState()

  if (coursesFetch || eventsFetch) {
    return
  }

  const d = (type, payload) =>
    dispatch({
      type,
      payload
    })

  const coursesParams = {
    ...params,
    mode: 'training',
    offset: isOrderChanged ? 0 : coursesList.length
  }
  const eventsParams = {
    ...params,
    mode: 'training',
    offset: isOrderChanged ? 0 : eventsList.length
  }

  d(TRAINING_COURSES_FETCHING, true)
  d(TRAINING_EVENTS_FETCHING, true)

  if (isOrderChanged) {
    d(TRAINING_COURSES_SUCCESS, [])
    d(TRAINING_EVENTS_SUCCESS, [])
  }

  return Promise.all([
    // courseHasMore
    //   ? api.courses.getCoursesList(coursesParams)
    //   : Promise.resolve({ data: [] }),
    // eventsHasMore
    //   ? api.events.get(eventsParams)
    //   : Promise.resolve({ data: [] })]
    api.courses.getCoursesList(coursesParams),
    api.events.get(eventsParams)
  ])
    .then(([{ data: coursesData }, { data: eventsData }]) => {
      d(TRAINING_COURSES_FETCHING, false)
      d(TRAINING_EVENTS_FETCHING, false)

      d(TRAINING_COURSES_HAS_MORE, !!coursesData.length)
      d(TRAINING_EVENTS_HAS_MORE, !!eventsData.length)

      let newEvents = []
      let newCourses = []
      if (eventsData.length > 0) {
        newEvents = eventsData.map(i => ({
          ...i,
          start_date: i.start_date_time,
          type: 'Live Event'
        }))
      }
      if (coursesData.length > 0) {
        newCourses = coursesData.map(i => ({
          ...i,
          type: 'Course'
        }))
      }

      d(
        TRAINING_COURSES_SUCCESS,
        (isOrderChanged ? [] : coursesList).concat(newCourses)
      )
      d(
        TRAINING_EVENTS_SUCCESS,
        (isOrderChanged ? [] : eventsList).concat(newEvents)
      )
    })
    .catch(e => {
      console.log('error', e)
    })
}

export const getEnrolledData = params => (dispatch, getState) => {
  const {
    courses: {
      enrolledCourses: { fetch: coursesFetch }
    },
    events: {
      enrolledEvents: { fetch: eventsFetch }
    }
  } = getState()

  if (coursesFetch || eventsFetch) {
    return
  }

  const d = (type, payload) =>
    dispatch({
      type,
      payload
    })

  const coursesParams = {
    ...params,
    mode: 'enrollment'
  }
  // const eventsParams = {
  //   ...params,
  //   mode: 'enrollment'
  // }

  d(ENROLLED_COURSES_FETCHING, true)

  return Promise.all([
    api.courses.getCoursesList(coursesParams)
    // api.events.get(eventsParams)
  ]).then(([{ data: coursesData }]) => {
    if (!coursesData) {
      return null
    }

    d(ENROLLED_COURSES_FETCHING, false)
    // d(WORKSHOP_COURSES_HAS_MORE, !!coursesData.length)
    // d(WORKSHOP_EVENTS_HAS_MORE, !!eventsData.length)

    const newCourses =
      coursesData.length > 0
        ? coursesData.map(i => ({
            ...i,
            type: 'Course'
          }))
        : []

    const union = newCourses
      .sort((a, b) => moment(b.created_at).diff(moment(a.created_at)))
      .slice(0, params.limit)

    const courses = union.filter(i => i.type === 'Course')

    d(ENROLLED_COURSES_SUCCESS, courses)
  })
}

export const getPastEventData = params => (dispatch, getState) => {
  const {
    events: {
      pastEvents: { fetch: pastEventsFetch }
    }
  } = getState()

  if (pastEventsFetch) {
    return
  }

  const d = (type, payload) =>
    dispatch({
      type,
      payload
    })

  const eventsParams = {
    ...params,
    mode: 'past'
  }

  d(PAST_EVENTS_FETCHING, true)

  return Promise.all([api.events.get(eventsParams)]).then(
    ([{ data: eventsData }]) => {
      if (!eventsData) {
        return null
      }

      d(PAST_EVENTS_FETCHING, false)

      // d(WORKSHOP_COURSES_HAS_MORE, !!coursesData.length)
      // d(WORKSHOP_EVENTS_HAS_MORE, !!eventsData.length)

      const pastEvents =
        eventsData.length > 0
          ? eventsData.map(i => {
              i.type = 'Live Event'
              return i
            })
          : []

      d(PAST_EVENTS_SUCCESS, pastEvents)
    }
  )
}

export const getNowLiveEventsData = params => (dispatch, getState) => {
  const {
    events: {
      nowLiveEvents: { fetch: nowLiveEventsFetch }
    }
  } = getState()

  if (nowLiveEventsFetch) {
    return
  }

  const d = (type, payload) =>
    dispatch({
      type,
      payload
    })

  const eventsParams = {
    ...params,
    mode: 'live'
  }

  d(NOW_LIVE_EVENTS_FETCHING, true)

  return Promise.all([api.events.get(eventsParams)]).then(
    ([{ data: eventsData }]) => {
      if (!eventsData) {
        return null
      }

      d(NOW_LIVE_EVENTS_FETCHING, false)

      // d(WORKSHOP_COURSES_HAS_MORE, !!coursesData.length)
      // d(WORKSHOP_EVENTS_HAS_MORE, !!eventsData.length)

      const nowLiveEvents =
        eventsData.length > 0
          ? eventsData.map(i => {
              i.type = 'Live Event'
              return i
            })
          : []

      d(NOW_LIVE_EVENTS_SUCCESS, nowLiveEvents)
    }
  )
}

export const getWebinarEventData = params => (dispatch, getState) => {
  const {
    events: {
      webinarEvents: { fetch: webinarEventsFetch }
    }
  } = getState()

  if (webinarEventsFetch) {
    return
  }

  const d = (type, payload) =>
    dispatch({
      type,
      payload
    })

  const eventsParams = {
    ...params,
    mode: 'webinar'
  }

  d(WEBINAR_EVENTS_FETCHING, true)

  return Promise.all([api.events.get(eventsParams)]).then(
    ([{ data: eventsData }]) => {
      if (!eventsData) {
        return null
      }

      d(WEBINAR_EVENTS_FETCHING, false)

      const webinarEvents =
        eventsData.length > 0
          ? eventsData.map(i => {
              i.type = 'Webinar Event'
              return i
            })
          : []

      d(WEBINAR_EVENTS_SUCCESS, webinarEvents)
    }
  )
}

export const getPeerToPeerData = params => (dispatch, getState) => {
  const {
    events: {
      peerToPeer: { fetch: peerToPeerFetch }
    }
  } = getState()

  if (peerToPeerFetch) {
    return
  }

  const d = (type, payload) =>
    dispatch({
      type,
      payload
    })

  const eventsParams = {
    ...params,
    mode: 'peer2peer'
  }

  d(PEER_TO_PEER_FETCHING, true)

  return Promise.all([api.events.get(eventsParams)]).then(
    ([{ data: eventsData }]) => {
      if (!eventsData) {
        return null
      }

      d(PEER_TO_PEER_FETCHING, false)

      const peerToPeer =
        eventsData.length > 0
          ? eventsData.map(i => {
              i.type = 'Coaching Event'
              return i
            })
          : []

      d(PEER_TO_PEER_SUCCESS, peerToPeer)
    }
  )
}

export const getConferenceData = params => (dispatch, getState) => {
  const {
    events: {
      conferenceEvents: { fetch: conferenceEventsFetch }
    }
  } = getState()

  if (conferenceEventsFetch) {
    return
  }

  const d = (type, payload) =>
    dispatch({
      type,
      payload
    })

  const eventsParams = {
    ...params,
    mode: 'conference'
  }

  d(CONFERENCE_FETCHING, true)

  return Promise.all([api.events.get(eventsParams)]).then(
    ([{ data: eventsData }]) => {
      if (!eventsData) {
        return null
      }

      d(CONFERENCE_FETCHING, false)

      const conferenceEvents =
        eventsData.length > 0
          ? eventsData.map(i => {
              i.type = 'Conference Event'
              return i
            })
          : []

      d(CONFERENCE_SUCCESS, conferenceEvents)
    }
  )
}
