import React, { Fragment, useEffect, useState } from 'react'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'
import { withTranslation } from 'react-i18next'
import { Row, Col, Popconfirm, Spin, message } from 'antd'
import history from '../../../history'
import './styles.scss'

const TriviaList = props => {
  const { t, triviaList, isLoading, triviaActions } = props
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getData('INIT')
  }, [])
  const getData = async type => {
    let res = await triviaActions.fetchQuestions(type)
    if (res.status >= 300) {
      message.error(res.data.errorMessage)
    }
  }
  const deleteQuestion = async question => {
    setLoading(true)
    let res = await triviaActions.deleteQuestion(question.id)
    if (res.status < 300) {
      message.success('Question deleted successfully')
      getData('DELETE')
    }
    setLoading(false)
  }
  const addEditQuestion = (type, question) => {
    if (type === 'ADD') {
      history.push(`/share-sphere/add`)
    } else if (type === 'EDIT') {
      history.push(`/share-sphere/${question.id}`)
    }
  }
  return (
    <div className="list-wapper">
      <div className="trivia">
        <section className="section header">
          <Row type="flex" justify="space-between">
            <Col sm={24} md={12} lg={12}>
              <div className="title">{t('v3:share_sphere')}</div>
            </Col>
            <Col sm={24} md={12} lg={12}>
              <div
                className="add"
                onClick={() => {
                  addEditQuestion('ADD')
                }}
              >
                +add a question
              </div>
            </Col>
          </Row>
        </section>
        <section className="section question">
          {isLoading ? (
            <Row
              type="flex"
              justify="center"
              align="middle"
              style={{ width: '100%', minHeight: '300px' }}
            >
              <Spin spinning />
            </Row>
          ) : (
            <Row gutter={[16, 16]}>
              {triviaList.length > 0 ? (
                <Fragment>
                  {triviaList.map((question, i) => {
                    return (
                      <Col md={24} key={i}>
                        <div className="question_body">
                          <div className="question_body_text">
                            {i + 1}.&nbsp;{question.question}
                          </div>
                          <div className="question_body_action">
                            <EditOutlined
                              onClick={() => {
                                !loading && addEditQuestion('EDIT', question)
                              }}
                              className="question_body_action_edit"
                              style={{ color: '#256BF6' }}
                            />
                            &nbsp;&nbsp;
                            <Popconfirm
                              title="Are you sure to delete?"
                              description="Are you sure to delete this question?"
                              onConfirm={() =>
                                !loading && deleteQuestion(question)
                              }
                              okText="Yes"
                              cancelText="No"
                              icon={false}
                            >
                              <DeleteOutlined
                                className="question_body_action_delete"
                                style={{ color: '#F22E2E' }}
                              />
                            </Popconfirm>
                          </div>
                        </div>
                      </Col>
                    )
                  })}
                </Fragment>
              ) : (
                <Col md={24}>
                  <div className="question_body">
                    <div className="question_body_text">
                      Travia question list is empty.
                    </div>
                  </div>
                </Col>
              )}
            </Row>
          )}
        </section>
      </div>
    </div>
  )
}

export default withTranslation('trivia')(TriviaList)
