import React, { Component } from 'react'
import { Button } from 'antd'
import { Row, Col, Field } from '../../../../../../styles'
import { withTranslation, Trans } from 'react-i18next'

class PrivacySelector extends Component {
  onClick = (id) => {
    const { onChange } = this.props

    if (onChange) {
      onChange(id)
    }
  }

  render () {
    const { selected, types, t, isAdmin } = this.props
    return (
      <Field>
        <Field.Label>{t('labels:privacy_settings')}</Field.Label>
        <div style={{ paddingBottom: '10px' }}>
          {!isAdmin && <Trans i18nKey='helpers:public_description_normal'>
            <span>{t('labels:assessment')}</span>
          </Trans>}
          {isAdmin && <Trans i18nKey='helpers:public_description_admin'>
            <span>{t('labels:assessment')}</span>
          </Trans>}
        </div>
        <Field.Control>
          <Row>
            {types.map(type =>
              <Col md={12 / types.length} key={type.id}>
                <Button
                  block
                  type={selected === type.id ? 'primary' : ''}
                  onClick={() => this.onClick(type.id)}
                >
                  {type.name}
                </Button>
              </Col>
            )}
          </Row>
        </Field.Control>
      </Field>
    )
  }
}

export default withTranslation()(PrivacySelector)
