import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'

import { Organisations, Users, Groups, Category } from './internal'
import PDFReport from './internal/Organisations/internal/PDFReport/PDFReport'

class Routes extends Component {
  render() {
    return (
      <Switch>
        <Switch>
          <Route
            path="/management"
            render={props => <Organisations {...props} />}
            exact
          />
          <Route
            path="/management/usage-report"
            render={props => <PDFReport {...props} />}
            exact
          />
          <Route
            path="/management/users"
            render={props => <Users {...props} />}
          />
          <Route
            path="/management/groups"
            render={props => <Groups {...props} />}
          />
          <Route
            path="/management/category"
            render={props => <Category {...props} />}
          />
        </Switch>
      </Switch>
    )
  }
}

export default Routes
