import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { Button, Modal, Row, Col, Input, Checkbox } from 'antd'
import { withTranslation, Trans } from 'react-i18next'

import { AddTopicCard } from '../../components'
import './UpdateStatusModal.scss'
import { general } from '../../../../constants'

class UpdateStatusModal extends Component {
  static propTypes = {
    topicList: PropTypes.array,
    mediaLists: PropTypes.array,
    topics: PropTypes.array,
    showModal: PropTypes.bool,
    ctrlHandalCloseModal: PropTypes.func
  }

  static defaultProps = {
    showModal: false,
    mediaLists: [],
    topics: []
  }

  constructor(props) {
    super(props)
    this.state = {
      checked: false,
      showModal: true,
      isDisableButton: true,
      checkedTerms: false,
      isEnable: false
    }
  }

  componentDidMount() {
    this.handleDisableButton(this.props.mediaLists)
  }

  handleDisableButton = list => {
    const filterList = _.filter(
      list,
      item => !_.isEmpty(item.topics) && !_.isEmpty(item.keywords)
    )
    if (filterList.length === list.length) {
      this.setState({ isEnable: true }, () => {
        this.handleEnableButton()
      })
    } else {
      this.setState({ isEnable: false }, () => {
        this.handleEnableButton()
      })
    }
  }

  handleEnableButton = () => {
    const {
      library: { makeType }
    } = this.props
    const { isEnable, checkedTerms } = this.state
    if (makeType === general.PRIVATE || makeType === general.ORG) {
      if (isEnable) {
        this.setState({
          isDisableButton: false
        })
      } else {
        this.setState({
          isDisableButton: true
        })
      }
    } else {
      if (isEnable && checkedTerms) {
        this.setState({
          isDisableButton: false
        })
      } else {
        this.setState({
          isDisableButton: true
        })
      }
    }
  }

  handleChangeTopic = list => {
    const mediaList = Object.assign([], list)
    this.handleDisableButton(mediaList)
  }

  handleChangeKeywords = list => {
    const mediaList = Object.assign([], list)
    this.handleDisableButton(mediaList)
  }

  handleChangeTerms = () => {
    this.setState(
      state => ({
        checkedTerms: !state.checkedTerms
      }),
      () => this.handleEnableButton()
    )
  }

  render() {
    const {
      t,
      showModal,
      ctrlHandleChangeTopic,
      topics,
      mediaLists,
      ctrlMakeMediaStatus,
      loadingModalButtion,
      library: { makeType }
    } = this.props
    const { isDisableButton, checkedTerms } = this.state
    return (
      <Modal
        visible={showModal}
        width={800}
        className="custom-ant-modal"
        onCancel={ctrlHandleChangeTopic}
        footer={null}
      >
        <div className="private-public-modal">
          <div className="private-public-modal__inner">
            <Row>
              {mediaLists &&
                mediaLists.map((item, ind) => (
                  <Col span={12}>
                    <AddTopicCard
                      mediaLists={mediaLists}
                      key={ind}
                      item_index={ind}
                      item={item}
                      itemTopic={item.topics}
                      topics={topics}
                      ctrlChangeTopic={this.handleChangeTopic}
                      ctrlChangeKeywords={this.handleChangeKeywords}
                    />
                  </Col>
                ))}
            </Row>
          </div>
          {makeType === general.PUBLIC && (
            <div className="disclaimer">
              <div className="disclaimer_label">
                <div className="label">{t('labels:disclaimer')}</div>
                <div className="text">
                  <Input.TextArea
                    disabled
                    rows={6}
                    style={{ resize: 'none' }}
                    value={t('media:disclaimer')}
                  />
                </div>
              </div>
              <div className="disclaimer_checkbox">
                <div className="label">
                  <Checkbox
                    value={checkedTerms}
                    onChange={this.handleChangeTerms}
                  >
                    <Trans i18nKey="labels:terms">
                      {t('v3:terms_agree')}{' '}
                      <a href="/terms">{t('v3:terms_and_conditions')}</a>.
                    </Trans>
                  </Checkbox>
                </div>
              </div>
            </div>
          )}
          <div className="private-public-modal__footer">
            {makeType === general.PUBLIC && (
              <Button
                className="save-button"
                loading={loadingModalButtion}
                type="primary"
                htmlType="submit"
                // disabled={isDisableButton}
                onClick={() => ctrlMakeMediaStatus(general.PUBLIC, mediaLists)}
              >
                {t('buttons:make_public')}
              </Button>
            )}
            {makeType === general.PRIVATE && (
              <Button
                className="save-button"
                loading={loadingModalButtion}
                type="primary"
                htmlType="submit"
                // disabled={isDisableButton}
                onClick={() => ctrlMakeMediaStatus(general.PRIVATE, mediaLists)}
              >
                {t('buttons:make_private')}
              </Button>
            )}
            {makeType === general.ORG && (
              <Button
                className="save-button"
                loading={loadingModalButtion}
                type="primary"
                htmlType="submit"
                // disabled={isDisableButton}
                onClick={() => ctrlMakeMediaStatus(general.ORG, mediaLists)}
              >
                {t('buttons:make_org')}
              </Button>
            )}
          </div>
        </div>
      </Modal>
    )
  }
}

export default withTranslation()(UpdateStatusModal)
