import React, { useState } from 'react'
import { Container, Pages } from '../../../../../styled'
import { Builder } from '../../../../../../../../components'
import PageTypes from './PageTypes'
import PagePreviewSection from './PagePreviewSection'
import { useSelector } from 'react-redux'
import LayoutOptions from './LayoutOptions'
import { useTranslation } from 'react-i18next'

const PageViewOption = props => {
  const { t } = useTranslation()

  const defaultPageTypes = {
    story: t('v4:story_writing'),
    scenario: t('v2:scenario'),
    quiz: t('v4:Ai_quiz')
  }
  const [pageTypes, setPageTypes] = useState(defaultPageTypes)
  const [activeType, setActiveType] = useState(null)
  const { currentPage, toPage, pageStructureData } = props

  const onPreviewItemClick = i => {
    if (currentPage === i + 1) return
    toPage(i + 1)
  }

  const handleType = type => {
    setActiveType(type)

    if (type !== 'quiz') {
      setPageTypes(defaultPageTypes)
    }
  }

  const course = useSelector(state => state.courses.courseById)

  const isAiGeneratedCourse =
    course && course.item && course.item.is_ai_generated
  const courseId = course && course.item && course.item.id

  return (
    <Container>
      <Pages>
        <Pages.List>
          {isAiGeneratedCourse && (
            <div className="page-builder-preview-container left-section">
              <div className="page-builder-type">
                <PageTypes
                  activeType={activeType}
                  pageTypes={pageTypes}
                  onClickType={handleType}
                  pageStructureData={pageStructureData}
                  currentPage={currentPage}
                />
              </div>

              <LayoutOptions {...props} />
            </div>
          )}
          <Pages.Item>
            <Builder.Page {...props} />
          </Pages.Item>
          <PagePreviewSection
            {...props}
            courseId={courseId}
            onPreviewItemClick={onPreviewItemClick}
            pageTypes={pageTypes}
            activeType={activeType}
            handleType={handleType}
          />
        </Pages.List>
      </Pages>
    </Container>
  )
}

export default PageViewOption
