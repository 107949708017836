import styled from 'styled-components'

const ResultQuestion = styled.div`
  margin-bottom: 1rem;
  padding: 1rem 0;
`

ResultQuestion.Title = styled.div`
  font-weight: 300;
  // font-size: 1.6rem;
  font-size: 2rem;
  margin-bottom: .8rem;
`

ResultQuestion.Answer = styled.div`
  font-weight: 600;
  font-size: 1.6rem;
`

export {
  ResultQuestion
}
