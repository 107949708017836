import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

import {
  WhatsNew,
  Terms,
  PrivacyPolicy,
  ContactUs,
  Feedback,
  Help
} from './internal'
import { helpCenter } from '../../constants'

class Routes extends Component {
  render () {
    console.log('--props--', Route)
    const helpCenterContent = {
      getStarted: {
        video: `<iframe width='560' height='315' src=${helpCenter.getStarted} frameBorder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowFullScreen />`,
        description: {
          main: 'get_started.description.main',
          tagline: 'get_started.description.tagline'
        }
      },
      whatsNew: {
        video: `<iframe width='560' height='315' src=${helpCenter.whatsNew} frameBorder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowFullScreen />`,
        description: {
          main: 'whats_new.description.main',
          tagline: 'whats_new.description.tagline'
        }
      },
      createCourse: {
        video: `<iframe width='560' height='315' src=${helpCenter.createCourse} frameBorder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowFullScreen />`
      },
      createGroup: {
        video: `<iframe width='560' height='315' src=${helpCenter.createGroup} frameBorder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowFullScreen />`
      },
      createLiveAssessment: {
        video: `<iframe width='560' height='315' src=${helpCenter.createLiveAssessment} frameBorder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowFullScreen />`
      },
      createLiveEvent: {
        video: `<iframe width='560' height='315' src=${helpCenter.createLiveEvent} frameBorder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowFullScreen />`
      },
      createWebinar: {
        video: `<iframe width='560' height='315' src=${helpCenter.createWebinar} frameBorder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowFullScreen />`
      },
      createLiveOnMobile: {
        video: `<iframe width='560' height='315' src=${helpCenter.createLiveOnMobile} frameBorder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowFullScreen />`
      },
      addAnnotations: {
        video: `<iframe width='560' height='315' src=${helpCenter.addAnnotations} frameBorder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowFullScreen />`
      },
      addAudio: {
        video: `<iframe width='560' height='315' src=${helpCenter.addAudio} frameBorder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowFullScreen />`
      },
      addHeaderAndFooter: {
        video: `<iframe width='560' height='315' src=${helpCenter.addHeaderAndFooter} frameBorder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowFullScreen />`
      },
      addImage: {
        video: `<iframe width='560' height='315' src=${helpCenter.addImage} frameBorder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowFullScreen />`
      },
      addQuizContent: {
        video: `<iframe width='560' height='315' src=${helpCenter.addQuizContent} frameBorder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowFullScreen />`
      },
      addTextContent: {
        video: `<iframe width='560' height='315' src=${helpCenter.addTextContent} frameBorder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowFullScreen />`
      },
      addUser: {
        video: `<iframe width='560' height='315' src=${helpCenter.addUser} frameBorder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowFullScreen />`
      },
      addVideo: {
        video: `<iframe width='560' height='315' src=${helpCenter.addVideo} frameBorder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowFullScreen />`
      },
      cloneCourse: {
        video: `<iframe width='560' height='315' src=${helpCenter.cloneCourse} frameBorder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowFullScreen />`
      },
      findLiveBroadcast: {
        video: `<iframe width='560' height='315' src=${helpCenter.findLiveBroadcast} frameBorder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowFullScreen />`
      },
      transcribeTranslate: {
        video: `<iframe width='560' height='315' src=${helpCenter.transcribeTranslate} frameBorder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowFullScreen />`
      }
    }
    return (
      <Switch>
        <Switch>
          <Route
            path='/help-center'
            render={(props) => {
              return (
                <Help
                  heading='get_started.title'
                  video={helpCenterContent.getStarted.video}
                  description={helpCenterContent.getStarted.description}
                  {...props} />)
            }}
            exact
          />
          <Route
            path='/help-center/getting-started'
            render={(props) => {
              return (
                <Help
                  heading='get_started.title'
                  video={helpCenterContent.getStarted.video}
                  description={helpCenterContent.getStarted.description}
                  {...props} />)
            }}
            exact
          />
          <Route path='/help-center/feedback'
            render={(props) => <Feedback {...props} />}
            exact
          />
          <Route path='/help-center/create-course'
            render={(props) => {
              return (
                <Help
                  heading='create_course'
                  video={helpCenterContent.createCourse.video}
                  {...props} />)
            }}
            exact
          />
          <Route path='/help-center/create-group'
            render={(props) => {
              return (
                <Help
                  heading='create_group'
                  video={helpCenterContent.createGroup.video}
                  {...props} />)
            }}
            exact
          />
          <Route path='/help-center/create-live-assessment'
            render={(props) => {
              return (
                <Help
                  heading='create_live_assessment'
                  video={helpCenterContent.createLiveAssessment.video}
                  {...props} />)
            }}
            exact
          />
          <Route path='/help-center/create-live-event'
            render={(props) => {
              return (
                <Help
                  heading='create_live_event'
                  video={helpCenterContent.createLiveEvent.video}
                  {...props} />)
            }}
            exact
          />
          <Route path='/help-center/create-webinar'
            render={(props) => {
              return (
                <Help
                  heading='create_webinar'
                  video={helpCenterContent.createWebinar.video}
                  {...props} />)
            }}
            exact
          />
          <Route path='/help-center/create-live-on-mobile'
            render={(props) => {
              return (
                <Help
                  heading='create_live_on_mobile'
                  video={helpCenterContent.createLiveOnMobile.video}
                  {...props} />)
            }}
            exact
          />
          <Route path='/help-center/add-annotations'
            render={(props) => {
              return (
                <Help
                  heading='add_annotations'
                  video={helpCenterContent.addAnnotations.video}
                  {...props} />)
            }}
            exact
          />
          <Route path='/help-center/add-audio'
            render={(props) => {
              return (
                <Help
                  heading='add_audio'
                  video={helpCenterContent.addAudio.video}
                  {...props} />)
            }}
            exact
          />
          <Route path='/help-center/add-header-and-footer'
            render={(props) => {
              return (
                <Help
                  heading='add_header_and_footer'
                  video={helpCenterContent.addHeaderAndFooter.video}
                  {...props} />)
            }}
            exact
          />
          <Route path='/help-center/add-image'
            render={(props) => {
              return (
                <Help
                  heading='add_image'
                  video={helpCenterContent.addImage.video}
                  {...props} />)
            }}
            exact
          />
          <Route path='/help-center/add-quiz-content'
            render={(props) => {
              return (
                <Help
                  heading='add_quiz_content'
                  video={helpCenterContent.addQuizContent.video}
                  {...props} />)
            }}
            exact
          />
          <Route path='/help-center/add-text-content'
            render={(props) => {
              return (
                <Help
                  heading='add_text_content'
                  video={helpCenterContent.addTextContent.video}
                  {...props} />)
            }}
            exact
          />
          <Route path='/help-center/add-user'
            render={(props) => {
              return (
                <Help
                  heading='add_user'
                  video={helpCenterContent.addUser.video}
                  {...props} />)
            }}
            exact
          />
          <Route path='/help-center/add-video'
            render={(props) => {
              return (
                <Help
                  heading='add_video'
                  video={helpCenterContent.addVideo.video}
                  {...props} />)
            }}
            exact
          />
          <Route path='/help-center/clone-course'
            render={(props) => {
              return (
                <Help
                  heading='clone_course'
                  video={helpCenterContent.cloneCourse.video}
                  {...props} />)
            }}
            exact
          />
          <Route path='/help-center/find-live-broadcast'
            render={(props) => {
              return (
                <Help
                  heading='find_live_broadcast'
                  video={helpCenterContent.findLiveBroadcast.video}
                  {...props} />)
            }}
            exact
          />
          <Route path='/help-center/transribe-and-translate-your-live-broadcast'
            render={(props) => {
              return (
                <Help
                  heading='transcribe_and_translate_your_live_broadcast'
                  video={helpCenterContent.transcribeTranslate.video}
                  {...props} />)
            }}
            exact
          />
          <Route path='/help-center/whats-new'
            render={(props) => <WhatsNew heading='whatsNew.title'
              video={helpCenterContent.whatsNew.video}
              description={helpCenterContent.whatsNew.description} {...props} />}
            exact
          />
          <Route path='/help-center/terms-and-conditions'
            render={(props) => <Terms {...props} />}
            exact
          />
          <Route path='/help-center/privacy-policy'
            render={(props) => <PrivacyPolicy {...props} />}
            exact
          />
          <Route path='/help-center/contact-us'
            render={(props) => <ContactUs {...props} />}
            exact
          />
        </Switch>
      </Switch>
    )
  }
}

export default withTranslation('help_center')(Routes)
