import React, { Component } from 'react'
import className from 'classnames'
import './Emotions.scss'

const EmotionContent = ({ app, data }) => {
  const { disabled } = app.state
  const { isVoteAble } = app.props

  return (
    <div className='emotions__item' key={data.type}>
      <div
        className={className({
          emotion: true,
          emotion_disabled: !isVoteAble ? !isVoteAble : disabled
        })}
        onClick={() => app.onClick(data.type)}
      >
        <div className='emotion__icon'>
          <img
            src={'/images/icons/emotions/color/' + data.name + '.svg'}
            alt={data.name}
          />
        </div>
        <span className='emotion__value'>{data.value}</span>
      </div>
    </div>
  )
}

class Emotions extends Component {
  state = {
    disabled: null
  }

  onClick = type => {
    const { onClick, isVoteAble } = this.props
    const { disabled } = this.state
    if (!isVoteAble) {
      return
    }

    if (!disabled && onClick) {
      onClick(type)
    }
  }

  render () {
    const { translate, emotionsData, totalEmotions } = this.props
    return (
      <div className='emotions'>
        <span className='emotions__label'>
          {translate('labels:overall_emotion')} {totalEmotions}
        </span>

        <div className='emotions__list'>
          {emotionsData &&
          emotionsData.map((emotion, index) => (
            <EmotionContent app={this} data={emotion} key={index}/>
          ))}
        </div>
      </div>
    )
  }
}

export default Emotions
