import http from './http'

function getByEvent (id, type) {
  return http.get('/notes', {
    params: {
      type,
      id
    }
  })
}

function add (id, type, data) {
  return http({
    method: 'POST',
    url: '/notes',
    data,
    params: {
      type,
      id
    }
  })
}

function deleteById (id) {
  return http({
    method: 'DELETE',
    url: `/notes/${id}`
  })
}

function editById (id, data) {
  return http({
    method: 'PUT',
    url: `/notes/${id}`,
    data
  })
}

export {
  getByEvent,
  add,
  deleteById,
  editById
}
