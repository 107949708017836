import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Chat from './Chat'

const mapStateToProps = state => ({
  user: state.user
})

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Chat)
