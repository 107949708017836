import React from 'react'
import { AntModal } from './styled'

export default (props) => {
  const { children } = props

  return (
    <AntModal footer={null} centered {...props}>
      {children}
    </AntModal>
  )
}
