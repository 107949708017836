/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-08-07 15:55:38
 * @LastEditTime: 2019-08-16 15:36:18
 * @LastEditors: Please set LastEditors
 */
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import View from './View'
import actions from '../../../store/actions'

const mapStateToProps = state => ({
  courses: state.courses,
  events: state.events,
  log: state.discussion.log,
  user: state.user,
  maze: state.maze,
  skillJourney: state.skillJourney
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getEnrolledData: actions.coursesEvents.getEnrolledData,
      getPastEventData: actions.coursesEvents.getPastEventData,
      getNowLiveEventsData: actions.coursesEvents.getNowLiveEventsData,
      getTrainingData: actions.coursesEvents.getTrainingData,
      getWebinarData: actions.coursesEvents.getWebinarEventData,
      getP2PData: actions.coursesEvents.getPeerToPeerData,
      getConferenceData: actions.coursesEvents.getConferenceData,
      getDiscussionLog: (id, type) =>
        actions.discussion.getDiscussionLog({ id, type }),
      updateUser: (id, data, dbData) =>
        actions.user.updateUser(id, data, dbData),
      listMazes: data => actions.maze.listMazes(data),
      resetMazeList: actions.maze.resetMazeList,
      listSkillJourney: actions.skillJourney.listSkillJourney,
      getEventsWithAssessments: actions.events.getEventsByMode
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(View)
