import React from 'react'

import DiscussionIcon from '../../../../../assets/discussion.svg'
import { Icon } from 'antd'
import Notes from '../../../../../components/Notes'
import Discussion from '../../../../../components/Discussion'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import './ViewChannelSidebar.scss'
import SidebarReviews from '../../../../../components/Reviews/SidebarReviews'

function ViewChannelSidebar(props) {
  const {
    showHeaderItems,
    showHeaderItems: { notes, discussion, review },
    channelId,
    handleClose
  } = props

  const user = useSelector(state => state.user.info)

  return (
    <div className="view-channel-sidebar">
      <div className="view-channel-sidebar-header">
        <div className="view-channel-sidebar-header-title">
          <SidebarHeader showHeaderItems={showHeaderItems} />
        </div>

        <div
          className="view-channel-sidebar-header-close"
          onClick={handleClose}
        >
          X
        </div>
      </div>
      <div className="view-channel-sidebar-content">
        {notes && (
          <Notes noteId={channelId} noteType={'channel'} userId={user.id} />
        )}

        {discussion && (
          <Discussion
            discussionId={channelId}
            discussionType={'channel'}
            userId={user.id}
          />
        )}

        {review && (
          <SidebarReviews
            reviewId={channelId}
            userId={user.id}
            type="channel"
          />
        )}
      </div>
    </div>
  )
}

function SidebarHeader({ showHeaderItems: { notes, discussion, review } }) {
  const { t } = useTranslation()
  return (
    <>
      {notes && (
        <>
          <Icon type="form" /> {t('tabs:notes')}
        </>
      )}{' '}
      {review && <span>{t('v2:review')}</span>}
      {discussion && (
        <>
          <img alt=""
            src={DiscussionIcon}
            style={{
              filter: 'brightness(0) saturate(100%)',
              marginRight: '1rem'
            }}
          />
          {t('tabs:discussion')}
        </>
      )}
    </>
  )
}

export default ViewChannelSidebar
