import styled from 'styled-components'
import ReactSVG from 'react-svg'
import { styleguide } from '../../constants'

const { colors } = styleguide

const Auth = styled.div`
  display: flex;
  align-items: center;
  height: 100vh;
  background: #f8f8f8;
`

Auth.Wrapper = styled.div`
  width: 100%;
  max-width: 32rem;
  margin: 0 auto;
`

Auth.Inner = styled.div`
  padding: 0 1.6rem;
  padding: 4rem 4.8rem;
  background: #fff;
  border-radius: .4rem;
`

Auth.Head = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 4rem;
`

Auth.Logo = styled(ReactSVG)`
  width: 20rem;

  svg {
    display: block;
    width: 100%;
  }
`

Auth.Message = styled.div`
  margin-bottom: 2.4rem;
`

Auth.Content = styled.div``

Auth.Footer = styled.div`
  margin-top: 2.4rem;
  display: flex;
  justify-content: space-between;

  ${props => props.align && `
    text-align: ${props.align};
  `}

  a {
    color: ${colors.darkGreyOne};
    transition: opacity .2s;

    &:hover {
      color: ${colors.darkGreyOne};
      opacity: .8;
    }
  }
`

Auth.Form = styled.div`
  .ant-form-item {
    &:last-child {
      margin: 0;
    }

    &-control {
      line-height: initial;
    }
  }
`

export default Auth
