import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Icon, Alert } from 'antd'
import { Logo } from '../../../components'
import { GetCodeForm, ResetPasswordForm } from './components'
import { Auth } from '../../../styles'
import { withTranslation } from 'react-i18next'
import { api, i18n } from '../../../services'

class PasswordReset extends Component {
  state = {
    step: 1,
    error: null,
    success: null,
    loading: false,
    username: null,
    code: null
  }

  componentDidMount() {
    const { search } = this.props.location

    if (search) {
      const params = search.split('&code=')
      const code = params[1]
      const username = params[0].split('?username=')[1]

      this.setState({
        code,
        username,
        step: 2
      })
    }
  }

  getPasswordResetCode = value => {
    this.clearAlert()
    this.setLoading(true)
    this.setState({ username: value })
    api.user
      .verifyPasswordResetCode({ username: value })
      .then(({ data }) => {
        if (data.errorMessage) {
          const emailPattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/
          if (emailPattern.test(value)) {
            this.checkErrorType({ message: i18n.t('v3:reset_email_error') })
          } else {
            this.checkErrorType({ message: data.errorMessage })
          }
          this.setLoading(false)
          return
        }
        this.setSuccess(
          i18n.t(
            data.message === 'We have emailed a link to verify your email'
              ? 'v3:signup_email_verification'
              : 'v3:reset_password_linK'
          )
        )
        this.setLoading(false)
        this.setStep(2)
      })
      .catch(error => {
        this.checkErrorType(error)
        this.setLoading(false)
      })
  }

  passwordReset = values => {
    this.clearAlert()
    const { code, username } = this.state
    this.setLoading(true)
    api.user
      .passwordReset({ ...values, username, code })
      .then(response => {
        this.setLoading(false)
        this.setStep(null)
        this.setSuccess(i18n.t('passwordreset:success_changed'))
      })
      .catch(error => {
        this.setError(error.message)
        this.setLoading(false)
      })
  }

  checkErrorType = error => {
    const { name, message } = error

    if (
      name === 'UserNotFoundException' ||
      name === 'InvalidParameterException'
    ) {
      this.setError(
        'Cannot reset password for this user as there is no registered/verified email or username.'
      )
    } else {
      this.setError(message)
    }
  }

  clearAlert = () => {
    this.setState({
      error: null,
      success: null
    })
  }

  setError = payload => {
    this.setState({
      error: payload
    })
  }

  setSuccess = value => {
    this.setState({
      success: value
    })
  }

  setLoading = value => {
    this.setState({
      loading: value
    })
  }

  setStep = value => {
    this.setState({
      step: value
    })
  }

  render() {
    const { error, success, step, loading, code, username } = this.state
    const { t } = this.props
    return (
      <Auth>
        <Auth.Wrapper>
          <Auth.Inner>
            <Auth.Head>
              <Logo color="blue" />
            </Auth.Head>

            <Auth.Content>
              {error && (
                <div style={{ marginBottom: '2.4rem' }}>
                  <Alert message={error} type="error" />
                </div>
              )}

              {success && (
                <div style={{ marginBottom: '2.4rem' }}>
                  <Alert message={success} type="success" />
                </div>
              )}

              <Auth.Form>
                {step === 1 && (
                  <GetCodeForm
                    getPasswordResetCode={this.getPasswordResetCode}
                    loading={loading}
                  />
                )}
                {step === 2 && code && (
                  <ResetPasswordForm
                    username={username}
                    passwordReset={this.passwordReset}
                    loading={loading}
                  />
                )}
              </Auth.Form>
            </Auth.Content>
          </Auth.Inner>

          <Auth.Footer align="left">
            <Link to="/auth/signin">
              <Icon type="arrow-left" />
              &nbsp;{t('general:signin')}
            </Link>
          </Auth.Footer>
        </Auth.Wrapper>
      </Auth>
    )
  }
}

export default withTranslation('passwordreset')(PasswordReset)
