import React, { Component } from 'react'
import { Icon, Row, Col, Tabs, Typography } from 'antd'
import {
  ReportTable,
  CustomTabs
  // CustomDatePicker,
  // CustomSelect
} from '../../../../styled.js'
import { withTranslation } from 'react-i18next'
import { Spinner } from '../../../../../../components/'
import { QuizByOverall, QuizByUser } from './internal'
import '../../style.css'
// import { generateCSV } from '../../../../../../utils'
// import moment from 'moment/moment'

const { Title, Text } = Typography
const { TabPane } = Tabs
// const { Option } = CustomSelect
const columns = (t) => [
  {
    title: t('reports:quiz'),
    dataIndex: 'title',
    textWrap: 'word-break',
    align: 'left'
  },
  {
    title: t('reports:status'),
    dataIndex: 'status',
    align: 'left'
  },
  {
    title: t('reports:question'),
    dataIndex: 'questions_count',
    align: 'left'
  },
  {
    title: t('reports:submit'),
    dataIndex: 'submit',
    align: 'left'
  }
]
class Quiz extends Component {
  state = {
    // startValue: moment(new Date(), 'YYYY-MM-DD').subtract(1, 'month'),
    // endValue: moment(new Date(), 'YYYY-MM-DD'),
    // downloadType: 0,
    showQuiz: false,
    quizNumber: []
  }

  // onClose = () => {
  //   const { handlingData } = this.props
  //   handlingData('visible', false)
  // }

  // onChange = (field, value) => {
  //   this.setState({
  //     [field]: value
  //   })
  //   const { handlingData } = this.props
  //   handlingData([field], value)
  // }
  // onStartChange = value => {
  //   this.onChange('startValue', value)
  // }

  // onEndChange = value => {
  //   this.onChange('endValue', value)
  // }
  // changeDownloadType = value => {
  //   this.onChange('downloadType', value)
  // }
  // download = () => {
  //   const {
  //     data: { quizzes = [] }
  //   } = this.props.data.Quiz
  //   let dataCSV = []
  //   let header = []
  //   if (this.state.downloadType === 0) {
  //     dataCSV = quizzes
  //       .map(x => {
  //         // if( moment(x.date_time).isBetween(this.state.startValue,this.state.endValue.add(1,'days')))
  //         return {
  //           id: x.id,
  //           title: x.title,
  //           status: x.status,
  //           question: x.questions_count,
  //           submit: x.submit
  //         }
  //       })
  //       .filter(x => {
  //         return x !==null
  //       })
  //     header = ['#', 'Title', 'Status', 'Question', 'Submit']
  //   }
  //   generateCSV(dataCSV, header)
  // }
  changeShowQuizState (quiz) {
    this.setState({
      showQuiz: true,
      quizNumber: quiz
    })
    this.props.getQuizReport(quiz, 'overAll')
    this.props.switchQuizStatus()

  }
  
  closePopup (e) {
    e.preventDefault()
    this.setState({
      showQuiz: false
    })
  }
  componentDidMount () {
    const { lesson, course } = this.props
    this.props.getQuizListReport(lesson, { type: 'Quiz', course_id: course })
  }
  render () {
    const { t } = this.props
    const { showQuiz, quizNumber } = this.state
    const {
      fetch,
      data: { quizzes = [] }
    } = this.props.data.Quiz
    const {
      data: { status, total_points, total_scores, submit, questions_count }
    } = this.props.data.overAll
    return fetch ? (
      <Spinner />
    ) : (
      <div>
        <div style={{ padding: '0 10px' }}>
          {!showQuiz && (
            <ReportTable
              rowKey='id'
              columns={columns(t)}
              dataSource={quizzes}
              onRow={record => {
                return {
                  onClick: () => this.changeShowQuizState(record.id)
                }
              }}
            />
          )}
          {showQuiz && (
            <div
              className='quiz'
              style={{
                padding: '20px',
                backgroundColor: '#fff',
                margin: '0 20px'
              }}
            >
              {submit === 0 ? (
                <div>
                  <Row>
                    <Col span={23}>
                      {quizzes
                        .filter(x => x.id === quizNumber)
                        .map(x => {
                          return <div className='quiz-title'>{x.title}</div>
                        })}
                      <div
                        style={{ borderColor: '1px solid #eee', margin: 20 }}
                      >
                        <Title level={4}>{t('v2:no_data')}</Title>
                      </div>
                    </Col>
                    <Col span={1}>
                      <Icon onClick={e => this.closePopup(e)} type='close' />
                    </Col>
                  </Row>
                </div>
              ) : (
                <div>
                  <Row>
                    <Col span={23}>
                      {quizzes
                        .filter(x => x.id === quizNumber)
                        .map(x => {
                          return (
                            <div key={x.id} className='quiz-title'>
                              {x.title}
                            </div>
                          )
                        })}
                      <div>
                        <Text className='quiz-status'>
                          {' '}
                          {t('v2:total_score')} {total_points} {t('v2:point')}{' '}
                        </Text>
                        |
                        <Text className='quiz-status'>
                        {t('v2:pass_score')} {total_scores} {t("v2:point")}{' '}
                        </Text>
                        |
                        <Text className='quiz-status'> {t('reports:status')} : {status} </Text>
                        |
                        <Text className='quiz-status'>
                          {questions_count} {t('reports:question')}
                        </Text>
                        |<Text className='quiz-status'>{submit} {t('reports:submit')}</Text>
                      </div>
                    </Col>
                    <Col span={1}>
                      <Icon onClick={e => this.closePopup(e)} type='close' />
                    </Col>
                  </Row>

                  <CustomTabs
                    style={{ borderTop: '1px solid #eee', marginTop: 20 }}
                  >
                    <TabPane tab='Overall' key='0'>
                      <QuizByOverall id={quizNumber} />
                      {/* <QuizByOverall id='361'/> */}
                    </TabPane>
                    <TabPane tab='By user' key='1'>
                      <QuizByUser id={quizNumber} />
                      {/* <QuizByUser id={361}/> */}
                    </TabPane>
                  </CustomTabs>
                </div>
              )}
            </div>
          )}
        </div>
        {/* <Drawer
          title="Report"
          placement="right"
          closable={true}
          onClose={this.onClose}
          visible={visible}
        >
          <CustomDatePicker
            format="YYYY-MM-DD"
            value={startValue}
            placeholder="From Date"
            onChange={this.onStartChange}
          />
          <CustomDatePicker
            format="YYYY-MM-DD"
            value={endValue}
            placeholder="To Date"
            onChange={this.onEndChange}
          />
          <CustomSelect
            className="report-type-select"
            onChange={this.changeDownloadType}
            defaultValue="Select One"
          >
            <Option value="0">Quiz list</Option>
          </CustomSelect>
          <button onClick={this.download}>Download</button>
        </Drawer> */}
      </div>
    )
  }
}
export default withTranslation('quiz')(Quiz)
