import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Attachments from './Attachments'
import actions from '../../store/actions'

const mapStateToProps = state => ({
  library: state.library
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      download: actions.library.download
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(Attachments)
