import Message from './Message'
import { connect } from 'react-redux'
import actions from '../../../store/actions'

const mapStateToProps = state => ({
  discussion: state.discussion,
  isLoading: state.discussion.isLoading,
  isEditing: state.discussion.isEditing
})

const mapDispatchToProps = dispatch => ({
  replyTo: (messageId) => dispatch(actions.discussion.replyTo(messageId)),
  deleteComment: (commentId) => dispatch(actions.discussion.deleteComment(commentId)),
  editComment: (commentId) => dispatch(actions.discussion.editComment(commentId)),
  editingMessage: () => dispatch(actions.discussion.editingMessage()),
  stopEditingMessage: () => dispatch(actions.discussion.stopEditingMessage())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Message)
