import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Icon, Alert, Spin } from 'antd'
import { RegisterForm, ConfirmForm } from './components'
import { Auth } from '../../../styles'
import { api, i18n } from '../../../services'
import { getUrlParameter } from '../../../utils'
import { withTranslation } from 'react-i18next'
import { Logo } from '../../../components'
import AuthContainer from '../components/AuthContainer'
class SignUp extends Component {
  state = {
    loading: false,
    step: 1,
    code: null,
    error: null,
    success: null,
    username: null,
    prefilledEmail: '',
    prefilledName: '',
    isUserNotConfirmed: false
  }

  componentDidMount() {
    const { search, state } = this.props.location
    const token = getUrlParameter('token', search)

    const isSignupFromMarketplace = search.includes('marketplace')

    if (isSignupFromMarketplace) return

    if (!token && search) {
      const params = search.split('&code=')
      const code = params[1]
      const username = params[0].split('?username=')[1]

      this.setState(
        {
          step: 2,
          success: i18n.t('v3:verifying_registration'),
          username: username,
          isUserNotConfirmed: true,
          code
        },
        () => {
          const { username, code } = this.state
          this.confirmSignUp({ username, code })
        }
      )
    } else if (state) {
      const { username, isUserNotConfirmed } = state
      if (isUserNotConfirmed) {
        this.setState(
          {
            step: 2,
            success: i18n.t('v3:signup_email_verification'),
            username: username,
            isUserNotConfirmed: true
          },
          () => {
            this.setLoading(true)
            api.user
              .resendConfirmationCode(username)
              .then(res => {
                this.setError(null)
                this.setLoading(false)
              })
              .catch(error => {
                this.setError(error.message)
                this.setLoading(false)
              })
          }
        )
      }
    } else if (token) {
      api.user
        .inviteUser(token)
        .then(res => {
          const { data } = res
          this.setLoading(true)
          if (data) {
            if (data.is_valid) {
              this.setState({
                prefilledEmail: data.email.toLowerCase(),
                prefilledName:
                  data.default_name && data.default_name.toLowerCase()
              })
            } else {
              this.setError(data.message)
            }
          }
          this.setLoading(false)
        })
        .catch(error => {
          this.setError(error.message)
          this.setLoading(false)
        })
    }
  }

  setError = value => {
    this.setState({
      error: value
    })
  }

  setSuccess = value => {
    this.setState({
      success: value
    })
  }

  setLoading = value => {
    this.setState({
      loading: value
    })
  }

  signUp = values => {
    this.setError(null)
    this.setLoading(true)
    const { prefilledName } = this.state
    const { search } = this.props.location
    let signUpData = {
      ...values
    }
    if (prefilledName) {
      signUpData.name = prefilledName
    }
    api.user
      .signUp(signUpData)
      .then(response => {
        if (response.userConfirmed) {
          this.setLoading(false)
          this.setError(null)
          this.setState({
            step: null,
            success: i18n.t('signup:success_step2')
          })
          const isMarketplace = search.includes('marketplace=true')
          api.user
            .signIn(values.email.toLowerCase(), values.password, isMarketplace)
            .then(() => {
              setTimeout(() => {
                window.location.replace('/')
              }, 5000)
            })
        } else {
          this.setLoading(false)
          this.setError(null)
          this.setState({
            step: 2,
            success: i18n.t('v3:signup_email_verification'),
            username: values.username
          })
        }
      })
      .catch(error => {
        // this.setError(error.message)
        let errorMessage = error.message
        if (errorMessage.includes('PreSignUp failed with error')) {
          errorMessage = errorMessage
            .replace('PreSignUp failed with error', '')
            .trim()
        }

        this.setError(errorMessage)
        this.setLoading(false)
      })
  }

  confirmSignUp = values => {
    const { username } = this.state
    const params = {
      ...values,
      username
    }

    this.setError(null)
    this.setLoading(true)
    api.user
      .confirmSignUp(params)
      .then(() => {
        this.setLoading(false)
        this.setState({
          step: null,
          success: i18n.t('signup:success_step2')
        })
      })
      .catch(error => {
        this.setLoading(false)
        this.setSuccess(null)
        this.setError(error.message)
      })
  }

  render() {
    const {
      step,
      error,
      success,
      loading,
      prefilledEmail,
      username,
      isUserNotConfirmed,
      code
    } = this.state
    const { t } = this.props

    const isResendingConfirmationCode = isUserNotConfirmed ? loading : false

    return (
      <AuthContainer>
        <Auth>
          <Auth.Wrapper style={{ maxWidth: '38rem' }}>
            <Auth.Inner>
              <Auth.Head>
                <Logo color="blue" />
              </Auth.Head>

              <Auth.Content>
                {error && (
                  <div style={{ marginBottom: '2.4rem' }}>
                    <Spin spinning={isResendingConfirmationCode}>
                      <Alert message={error} type="error" />
                    </Spin>
                  </div>
                )}
                {success && (
                  <div style={{ marginBottom: '2.4rem' }}>
                    <Spin spinning={isResendingConfirmationCode}>
                      <Alert message={success} type="success" />
                    </Spin>
                  </div>
                )}
                {step === 1 && (
                  <Auth.Form>
                    <RegisterForm
                      signUp={this.signUp}
                      loading={loading}
                      prefilledEmail={prefilledEmail}
                    />
                  </Auth.Form>
                )}
                {step === 2 && (
                  <Auth.Form>
                    <ConfirmForm
                      confirmSignUp={this.confirmSignUp}
                      loading={loading}
                      isUserNotConfirmed={isUserNotConfirmed}
                      username={username}
                      code={code}
                    />
                  </Auth.Form>
                )}
              </Auth.Content>
            </Auth.Inner>
            <Auth.Footer align="left">
              <Link to="/auth/signin">
                <Icon type="arrow-left" />
                &nbsp;{t('labels:signin')}
              </Link>
            </Auth.Footer>
          </Auth.Wrapper>
        </Auth>
      </AuthContainer>
    )
  }
}

export default withTranslation()(SignUp)
