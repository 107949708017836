import moment from 'moment'
import { rangeArray as range } from '../../../utils'

const axisDate = x => {
  if (x === 24)
    return range(x)
      .map(i =>
        moment()
          .subtract(i, 'hours')
          .format('HH')
      )
      .reverse()
  if (x === 12)
    return range(x)
      .map(i =>
        moment()
          .subtract(i, 'months')
          .format('MMM')
      )
      .reverse()
  if (x === 5)
    return range(x)
      .map(i =>
        moment()
          .subtract(i, 'years')
          .format('YYYY')
      )
      .reverse()
  return range(x)
    .map(i =>
      moment()
        .subtract(i, 'days')
        .format('D MMM')
    )
    .reverse()
}

const axisShowData = x => {
  if (x === 24)
    return range(x)
      .map(i =>
        moment()
          .subtract(i, 'hours')
          .format('hh:mm a')
      )
      .reverse()
}

const formatByDate = (date, x) => {
  if (x === 24)
    if (moment().isSame(date, 'day')) return moment(date).format('HH')
  if (x === 12) return moment(date).format('MMM')
  if (x === 5) return moment(date).format('YYYY')
  return moment(date).format('D MMM')
}

export { axisDate, axisShowData, formatByDate }
