export default {
  tone: {
    value: [
      'Formal',
      'Informal',
      'Humorous',
      'Assertive',
      'Sarcastic',
      'Optimistic'
    ],
    description: 'Overall mood or attitude of the story.'
  },
  narrativeHook: {
    value: '',
    description:
      'An engaging opening line or paragraph that captures the reader’s attention and sets the stage for the story.'
  },
  narrativeStyle: {
    value: [
      'First person',
      'Second person',
      'Third person limited',
      'Third person omniscient',
      'Epistolary'
    ],
    description: 'A perspective from which the story is told.'
  },
  storyPlot: {
    value: '',
    description: `A plot is how a story is shown, like in a movie or book. It's the order in which events happen. Sometimes they're shown one after another, and other times they're mixed up. For example, some stories start with the end and then go back to the beginning to explain.`
  },
  complexity: {
    value: ['Beginner', 'Intermediate', 'Advanced', 'Experimental'],
    description: 'Level of difficulty and depth of the story.'
  },
  genre: {
    value: [
      'Realistic',
      'Historical',
      'Casual',
      'Drama',
      'Fantasy',
      'Adventure',
      'Satire',
      'Science fiction',
      'Mystery'
    ],
    description: 'Category or type of story.'
  },
  vocabulary: {
    value: [
      'Simple',
      'Moderate',
      'Advanced',
      'Jargon or technical',
      'Period-specific',
      'Slang or colloquial'
    ],
    description: 'Choice and use of words.'
  },
  narrativeStructure: {
    value: [
      'Linear',
      'Non-linear',
      'Circular',
      'In media res',
      'Framed narrative'
    ],
    description: 'Organization of story events.'
  },
  timePeriod: {
    value: ['Past', 'Present', 'Future', 'Timeless or unspecified'],
    description: 'Era in which the story is set.'
  },
  storyLength: {
    value: [
      'Short (300 - 500 words)',
      'Medium (500 - 800 words)',
      'Long (800+ words)'
    ],
    description: 'Length of the narrative.'
  }
}
