import React, { Component } from 'react'
import _ from 'lodash'
import { WebRTCAdaptorPlay } from './webrtcAdaptor_new_play'
import { Row, message, Button } from 'antd'
import '../../../../components/Card/Card.scss'
// import { NetworkDetection } from '../NetworkDetection'
import mute from '../../../Events/Preview/assets/mute.svg'
import unmute from '../../../Events/Preview/assets/unmute.svg'
import './style.scss'

message.config({
  top: 100,
  duration: 5,
  maxCount: 1
})

const pcConfig = {
  iceServers: [
    {
      urls: 'stun:stun.l.google.com:19302'
    }
  ]
}

let sdpConstraints = {
  OfferToReceiveAudio: false,
  OfferToReceiveVideo: false
}
// 240p means width: 426, height: 240
let mediaConstraints = {
  video: {
    width: 426,
    height: 245,
    frameRate: 15
  },
  audio: true
}

// let errorMessageWSPlay = null

let playWebRTCAdaptor = null

const token = 'null'
let playWSCount = 0
let prompt = null
let video = null

class Conference extends Component {
  initSocketConf = null
  // SOCKET_URL_PUBLISH = `wss://antmedia-${this.props.eventId}.v2.sharelookapp.com/WebRTCAppEE/websocket?rtmpForward=undefined`
  SOCKET_URL_PLAY = `wss://antmedia-${this.props.eventId}.v2.sharelookapp.com:5443/WebRTCAppEE/websocket?rtmpForward=undefined`
  connectionInterval = null
  state = {
    showVideo: false,
    myStreamId: Math.round(Math.random() * 10000) +
    '_' +
      this.props.eventId,
    streamsList: [],
    webSocketConnectPlay: false,
    speaker: '',
    isJoined: false,
    isCameraOff: false,
    isMicOff: false,
    isShareScreen: false,
    disableScreenSharing: '',
    hasLeave: false,
    canShowModal: false,
    showPrompt: false,
    isPublishStarted: false,
    hideElement: false,
    callFirstTime: true
  }

  componentDidMount() {
    if (this.props.allowJoin) {
      setTimeout(() => {
        document.getElementById('join_event').click();
      }, 3000)
    }
  }

  componentDidUpdate (prevProps, prevState) {
    const {
      speaker,
      myStreamId,
      streamsList,
      isJoined,
    } = this.state
    const {isConferenceHost} = this.props
    if (prevState.speaker !== speaker && speaker) {
      if (speaker === myStreamId) {
        console.log('speaker === myStreamId if')
        let localVideo = document.getElementById('localVideo')
        localVideo.className = 'main__view'
        let remoteVideo = document.getElementById(
          'remoteVideo' + prevState.speaker
        )
        let remoteController = document.getElementById(
          'controller' + prevState.speaker
        )
        let remotePlayer = document.getElementById('player' + prevState.speaker)
        let remoteUser = document.getElementById('username' + prevState.speaker)

        if (remoteVideo) {
          remoteVideo.className = 'side__view'
          remotePlayer.classList.add('remote__player__class')
          remoteController.classList.remove('control__active__class')
          remoteController.classList.add('volume__controls__class')
          remoteUser.classList.remove('username__active__class')
          remoteUser.classList.add('username__inactive__class')
        }
      } else {
        console.log('speaker === myStreamId else streamsList:', streamsList)
        let localVideo = document.getElementById('localVideo')
        if (localVideo) {
          localVideo.className = ''
        }
        if (streamsList.length > 0) {
          streamsList.forEach(item => {
            let remoteVideo = document.getElementById('remoteVideo' + item)
            let remoteController = document.getElementById('controller' + item)
            let remotePlayer = document.getElementById('player' + item)
            let remoteUser = document.getElementById('username' + item)

            if (remoteVideo) {
              if (item === speaker) {
                remoteVideo.className = 'main__view'
                remoteVideo.classList.remove('side__view')
                remotePlayer.classList.remove('remote__player__class')
                remoteController.classList.remove('volume__controls__class')
                remoteController.classList.add('control__active__class')
                remoteUser.classList.add('username__active__class')
                remoteUser.classList.remove('username__inactive__class')
              } else {
                remoteVideo.className = 'side__view'
                remotePlayer.classList.add('remote__player__class')
                remoteController.classList.add('volume__controls__class')
                remoteController.classList.remove('control__active__class')
                remoteUser.classList.remove('username__active__class')
                remoteUser.classList.add('username__inactive__class')
              }
            }
          })
        }
      }
    }

    if (
      (prevState.streamsList !== streamsList ||
        prevState.speaker !== speaker) &&
      isJoined &&
      !isConferenceHost
    ) {
      if (streamsList.length > 0) {
        streamsList.forEach(item => {
          if (speaker === '') {
            this.setState(
              {
                speaker: streamsList[0]
              },
              () => {
                let remoteVideo = document.getElementById(
                  'remoteVideo' + streamsList[0]
                )
                let remoteController = document.getElementById(
                  'controller' + streamsList[0]
                )
                let remotePlayer = document.getElementById(
                  'player' + streamsList[0]
                )
                let remoteUser = document.getElementById(
                  'username' + streamsList[0]
                )
                if (remoteVideo) {
                  remoteVideo.className = 'main__view'
                  remoteVideo.classList.remove('side__view')
                  remotePlayer.classList.remove('remote__player__class')
                  remoteController.classList.remove('volume__controls__class')
                  remoteController.classList.add('control__active__class')
                  remoteUser.classList.add('username__active__class')
                  remoteUser.classList.remove('username__inactive__class')
                }
              }
            )
          }
          if (item === speaker) {
            console.log('item === speaker if')
            let remoteVideo = document.getElementById('remoteVideo' + item)
            let remoteController = document.getElementById('controller' + item)
            let remotePlayer = document.getElementById('player' + item)
            let remoteUser = document.getElementById('username' + item)
            if (remoteVideo) {
              console.log('item === speaker if remoteVideo', remoteVideo)
              remoteVideo.className = 'main__view'
              remoteVideo.classList.remove('side__view')
              remotePlayer.classList.remove('remote__player__class')
              remoteController.classList.remove('volume__controls__class')
              remoteController.classList.add('control__active__class')
              remoteUser.classList.add('username__active__class')
              remoteUser.classList.remove('username__inactive__class')
            }
          } else {
            console.log('item === speaker else')
            let remoteVideo = document.getElementById('remoteVideo' + item)
            let remotePlayer = document.getElementById('player' + item)
            let remoteController = document.getElementById('controller' + item)
            let remoteUser = document.getElementById('username' + item)
            if (remoteVideo) {
              remoteVideo.className = 'side__view'
              remotePlayer.classList.add('remote__player__class')
              remoteController.classList.add('volume__controls__class')
              remoteController.classList.remove('control__active__class')
              remoteUser.classList.remove('username__active__class')
              remoteUser.classList.add('username__inactive__class')
            }
          }
        })
      }
    }
  }

  componentWillUnmount () {
    console.log('componentWillUnmount log')
    const { showVideo } = this.state

    if (this.connectionInterval !==null) {
      clearInterval(this.connectionInterval)
      this.connectionInterval = null
    }

    if (prompt && showVideo) {
      prompt = null
    }

    if (playWebRTCAdaptor && (playWebRTCAdaptor.roomTimerId !== -1)) {
      console.log('componentWillUnmount playWebRTCAdaptor.roomTimerId', playWebRTCAdaptor.roomTimerId)
      clearInterval(playWebRTCAdaptor.roomTimerId)
      playWebRTCAdaptor.roomTimerId = -1
    }

  }

  playVideo = obj => {
    const { conferenceId } = this.props
    let video = document.getElementById('remoteVideo' + obj.streamId)
    console.log(
      'new stream available with id: ' +
      obj.streamId +
      'on the room:' +
      conferenceId,
      'video',
      video
    )
    if (video === null) {
      console.log('playVideo video null if')
      this.createRemoteVideo(obj)
    } else {
      console.log('playVideo video null else')
      video.srcObject = obj.stream
    }
  }

  createRemoteVideo = obj => {
    let player = document.createElement('div')
    player.className = 'remote__player__class'
    player.id = 'player' + obj.streamId
    player.innerHTML =
      '<video id="remoteVideo' +
      obj.streamId +
      '" class="side__view" autoplay></video>' +
      '<div class="volume__controls__class" id="controller' +
      obj.streamId +
      '">' +
      '<button class= "volume__button__class" id="button' +
      obj.streamId +
      '" >' +
      '<img src=' +
      unmute +
      ' alt="micOn" id="icon' +
      obj.streamId +
      '" />' +
      '</button>' +
      '<span id="username' +
      obj.streamId +
      '" class="username__inactive__class"></span>' +
      '</div>'

    if (player) {
      console.log('createRemoteVideo if', player)
      let playerElement = document.getElementById('players')
      if (playerElement) {
        console.log('playerElement if', playerElement)
        playerElement.appendChild(player)
        video = document.getElementById('remoteVideo' + obj.streamId)
        if (video) {
          console.log('createRemoteVideo if video', video)
          video.srcObject = obj.stream
        }
      }
    }
  }

  removeRemoteVideo = streamId => {
    let video = document.getElementById('remoteVideo' + streamId)
    if (video !==null) {
      let player = document.getElementById('player' + streamId)
      video.srcObject = null
      document.getElementById('players').removeChild(player)
    }
  }

  reConnectSocket = () => {
    const { eventId, handleBroadcastChange } = this.props
    // playOnly = true
    console.log('reconnecting')
    this.setState(
      {
        webSocketConnectPlay: false,
        myStreamId: Math.round(Math.random() * 10000) +
        '_' +
          eventId,
        streamsList: [],
        showVideo: true,
        isJoined: true,
        hasLeave: false,
        isShareScreen: false,
        isCameraOff: false,
        isMicOff: false,
        disableScreenSharing: '',
        isPublishStarted: false,
        showPrompt: true
      },
      () => {
        const {hideElement} = this.state
        if (!hideElement) {
          handleBroadcastChange()
        }
        this.initPlayService()
      }
    )
  }

  leave = () => {
    playWSCount = 0
    // publishWSCount = 0
    this.setState(
      {
        canShowModal: false,
        hasLeave: true,
        hideElement: false,
        isPublishStarted: false,
        callFirstTime: true,
        webSocketConnectPlay: false
      },
      () => {
        const { conferenceId } = this.props
        if (playWebRTCAdaptor) {
          playWebRTCAdaptor.leaveFromRoom(conferenceId)
        }
      }
    )
  }

  toggleModal = () => {
    this.setState(state => ({ canShowModal: !state.canShowModal }))
  }

  initPlayService = () => {
    playWebRTCAdaptor = new WebRTCAdaptorPlay({
      websocket_url: this.SOCKET_URL_PLAY,
      mediaConstraints: mediaConstraints,
      peerconnection_config: pcConfig,
      sdp_constraints: sdpConstraints,
      localVideoId: 'localVideo',
      isPlayMode: true,
      debug: true,
      bandwidth: 200,
      callback: (info, obj) => {
        console.log('play log command', info, obj)
        const {
          conferenceId,
          handleBroadcastChange,
          isConferenceHost,
        } = this.props
        const {
          myStreamId,
          speaker,
          isShareScreen,
          disableScreenSharing,
          hasLeave,
          streamsList        } = this.state
        if (info === 'initialized') {
          console.log('play initialized', obj)
          playWebRTCAdaptor.joinRoom(conferenceId, myStreamId)
        } else if (info === 'start') {
          console.log('play start', obj)
        } else if (info === 'joinedTheRoom') {
          const room = obj.ATTR_ROOM_NAME
          console.log('play joinedTheRoom', obj)
          playWebRTCAdaptor.getRoomInfo(room, obj.streamId)
          // if (!isConferenceHost && callFirstTime) {
          //   this.initializeSocket()
          // }
          console.log('play joinedTheRoom streamsList', streamsList)
          if (streamsList.length > 0) {
            streamsList.forEach(item => {
              console.log('removed from play old item', item)
              this.removeRemoteVideo(item)
            })
          }
          // errorMessageWSPlay = null
        } else if (info === 'newStreamAvailable') {
          console.log('play newStreamAvailable')
          this.playVideo(obj)
          if (
            _.isEmpty(disableScreenSharing) &&
            isShareScreen &&
            playWebRTCAdaptor
          ) {
            console.log('newStreamAvailable myStreamId', myStreamId)
            playWebRTCAdaptor.sendData(myStreamId, 'SCREEN')
          }
        } /* else if (info === 'streamJoined') {
          console.log('play streamJoined')
          this.playVideo(obj)
        } */ else if (info === 'bitrateMeasurement') {
          console.log('play bitrateMeasurement', obj)
        } else if (info === 'closed') {
          console.log('play Connection closed')
          if (typeof obj !== 'undefined') {
            const { streamsList } = this.state
            console.log('play Connection closed: ' + JSON.stringify(obj))
            if (streamsList.length > 0) {
              streamsList.forEach(item => {
                console.log('play closed streamDifference remove ', item)
                this.removeRemoteVideo(item)
              })
            }
          }
        } else if (info === 'play_started') {
          console.log('play play_started', obj)
          // errorMessageWSPlay = null
          playWSCount = 0
          this.setState({
            webSocketConnectPlay: false
          })
        } else if (info === 'play_finished') {
          console.log('play play_finished', obj, 'hasLeave', hasLeave)
          this.removeRemoteVideo(obj.streamId)
          if (!hasLeave) {
            if (isConferenceHost) {
              if (disableScreenSharing === speaker) {
                if (disableScreenSharing === obj.streamId) {
                  this.setState({
                    disableScreenSharing: '',
                    isShareScreen: false,
                    speaker: myStreamId
                  })
                } else {
                  this.setState({
                    speaker: disableScreenSharing
                  })
                }
              } else {
                this.setState({ speaker: myStreamId })
              }
            } else {
              this.setState({
                disableScreenSharing: '',
                isShareScreen: false,
                speaker: ''
              })
            }
          }
        } else if (info === 'roomInformation') {
          console.log('play roomInformation', obj)

          this.setState(
            {
              streamsList: obj.streams
            },
            () => {
              const { streamsList } = this.state
              if (obj.streams.length > 0) {
                obj.streams.forEach(item => {
                  console.log('Stream playing with ID: ' + item)
                  const { conferenceId } = this.props
                  playWebRTCAdaptor.play(item, token, conferenceId)
                })
              }

              if (!isConferenceHost && streamsList.length === 0) {
                this.setState({ speaker: '' })
                this.leave()
              }
            }
          )
          console.log('after streamsList', streamsList)
        } else if (info === 'leavedFromRoom') {
          console.log('play leavedFromRoom')
          if (playWebRTCAdaptor && !isConferenceHost) {
            this.setState(
              {
                showVideo: false,
                isJoined: false,
                speaker: '',
                myStreamId: '',
                showPrompt: false
              },
              () => {
                handleBroadcastChange()
                this.setState({ streamsList: [] })
              }
            )
          }
        } else if (info === 'data_received') {
          console.log('play data_received', obj)
          const eventData = obj.event.data.split('_')
          if (obj.event.data === 'SPEAKING') {
            if (!disableScreenSharing && !isShareScreen) {
              this.setState({ speaker: obj.streamId })
            }
          }

          if (eventData[0] === 'USERNAME') {
            if (obj.streamId !== myStreamId) {
              let targetUser = document.getElementById(
                'username' + obj.streamId
              )
              if (targetUser) {
                targetUser.innerText = eventData[1]
              }
            }
          }

          if (obj.event.data === 'SCREEN') {
            if (!isShareScreen) {
              this.setState({
                disableScreenSharing: obj.streamId,
                speaker: obj.streamId
              })
            }
          }

          if (obj.event.data === 'CAMERA') {
            this.setState({ disableScreenSharing: '', isShareScreen: false })
          }

          if (obj.event.data === 'MUTED') {
            if (obj.streamId !== myStreamId) {
              let targetVideo = document.getElementById(
                'remoteVideo' + obj.streamId
              )
              let targetIcon = document.getElementById('icon' + obj.streamId)
              if (targetVideo) {
                targetVideo.muted = true
                targetIcon.src = mute
              }
            } else {
              this.setState({ isMicOff: true }, () => {
                // webRTCAdaptor.muteLocalMic()
              })
            }
          }

          if (obj.event.data === 'UNMUTED') {
            if (obj.streamId !== myStreamId) {
              let targetVideo = document.getElementById(
                'remoteVideo' + obj.streamId
              )
              let targetIcon = document.getElementById('icon' + obj.streamId)
              if (targetVideo) {
                targetVideo.muted = false
                targetIcon.src = unmute
              }
            }
          }
        } else if (info === 'takeConfiguration') {
          console.log('takeConfiguration')
        } else if (info === 'data_channel_opened') {
          console.log('Data Channel open for stream id', obj)
        } else if (info === 'updated_stats') {
          // console.log('updated_stats audioLevel', obj.audioLevel)
          // console.log('updated_stats streamId', obj.streamId)
        }
      },
      callbackError: (error, message) => {
        // some of the possible errors, NotFoundError, SecurityError,PermissionDeniedError
        console.log('play log error ', error.isTrusted)
        console.log('play error callback: ' + JSON.stringify(error), message)

        // let errorMessage = JSON.stringify(error)

        if (error.isTrusted) {
          console.log('inside error.isTrusted play', error.isTrusted)
          this.initPlayService()
          return
        }

        if (error.indexOf('WebSocketNotConnected') !== -1) {
          console.log('play WebSocketNotConnected')

          if (playWebRTCAdaptor && (playWebRTCAdaptor.roomTimerId !== -1)) {
            console.log('play playWebRTCAdaptor.roomTimerId', playWebRTCAdaptor.roomTimerId)
            clearInterval(playWebRTCAdaptor.roomTimerId)
            playWebRTCAdaptor.roomTimerId = -1
          }

          /* if (this.connectionInterval !==null) {
            console.log('play this.connectionInterval', this.connectionInterval)
            clearInterval(this.connectionInterval)
            this.connectionInterval = null
          } */

          const { streamsList, isJoined } = this.state
          const { isDisconnected } = this.props

          console.log('streamsList play', streamsList)

          if (streamsList.length > 0) {
            streamsList.forEach(item => {
              console.log('from play remove item', item)
              this.removeRemoteVideo(item)
            })
          }

          const { webSocketConnectPlay } = this.state

          // errorMessageWSPlay = 'Play Web socket not connected message'

          if (!webSocketConnectPlay && isJoined && isDisconnected) {
            console.log('i m in websocket play if condition')
            // errorMessageWSPlay = 'Play websocket if condition'
            this.setState({
              webSocketConnectPlay: true
            })
          } else if (isJoined && !isDisconnected) {
            console.log('i m in websocket play else condition')
            // errorMessageWSPlay = 'Play websocket else condition'
            this.setState(
              {
                webSocketConnectPlay: false
              },
              () => {
                if (playWSCount === 0) {
                  console.log('play ws count', playWSCount)
                  playWSCount = playWSCount + 1
                  console.log('play ws count after', playWSCount)
                  this.initPlayService()
                }
              }
            )
          }

          // errorMessage = 'play side web socket not connected'
        } else if (error.indexOf('no_stream_exist') !== -1) {
          console.log('play No stream exist', error.streamId)
          // errorMessage = 'play No stream exist'
          this.removeRemoteVideo(error.streamId)
          // TODO: removeRemoteVideo(error.streamId);
        }
      }
    })
  }

  render() {
    const {streamsList} = this.state
    return (
      <div>
        <Row>
            <div className='recording__streams'>
              <div className='__scrollbar' id='players'>
                  {streamsList.length === 0 && (
                    <div id='players'>
                      <video
                        id='remoteVideo'
                        className='main__view'
                        autoPlay
                        muted
                        playsInline
                      />
                    </div>
                  )}
              </div>
            </div>
        </Row>
          <Button
            id='join_event'
            className='button__hidden'
            onClick={() => this.reConnectSocket()}
          >
            Join
          </Button>
      </div>
    )
  }
}

export default Conference
