import React from 'react'
import { Modal, Button } from 'antd'
import { useTranslation } from 'react-i18next'
import './ListenerModal.scss'

const ListenerModal = ({
  isModalVisible,
  isRequest,
  handleCancel,
  user,
  handleKick,
  userImg
  // requestToSpeak
}) => {
  const { t } = useTranslation()

  return (
    <Modal visible={isModalVisible} onCancel={handleCancel} footer={null}>
      <div className="listener-modal">
        <h3>{isRequest ? t('v3:request_to_speak') : t('v3:kick_user')}</h3>
        {user && (
          <div className="listener-modal__user">
            <img
              src={user.avatar && user.avatar.link ? user.avatar.link : userImg}
              alt={user.username}
              style={{
                width: '50px',
                height: '50px',
                borderRadius: '50%'
              }}
            />
            <span>{user.username}</span>
          </div>
        )}
        <div className="listener-modal__buttons">
          <Button
            key="cancel"
            onClick={handleCancel}
            style={{ color: '#747474', borderColor: '#cecece' }}
          >
            {t('buttons:cancel')}
          </Button>

          {isRequest ? (
            <Button
              key="request"
              className="listener-modal__buttons_request"
              onClick={e => {
                e.preventDefault()
                // requestToSpeak(user)
              }}
            >
              {t('v3:accept_request')}
            </Button>
          ) : (
            <Button
              key="kick"
              className="listener-modal__buttons_kick"
              onClick={e => {
                e.preventDefault()
                handleKick(user)
              }}
            >
              {t('v3:kick')}
            </Button>
          )}
        </div>
      </div>
    </Modal>
  )
}

export default ListenerModal
