import { call, put, select } from 'redux-saga/effects'
import * as api from '../../services/api/assessments'
import * as assessments from '../actions/assessments'

import history from '../../history'

export function* fetchAssessments(action) {
  try {
    const state = yield select()
    const { list } = action.payload

    yield put(
      assessments.loadAssessments(list, state.assessments.hasMoreItems, true)
    )

    const response = yield call(api.getAll, action.params)
    const data = response.data

    yield put(
      assessments.loadAssessments(list.concat(data), !!data.length, false)
    )
  } catch (err) {
    console.log(JSON.stringify(err))
  }
}

export function* fetchAssessmentById(action) {
  try {
    const { id } = action.payload
    const response = yield call(api.getById, id)

    yield put(assessments.loadAssessmentById(response.data))
  } catch (err) {
    console.log(JSON.stringify(err))
  }
}

export function* createAssessment(action) {
  try {
    yield put(assessments.fetchRequestAssessments(true))

    const response = yield call(api.create, action.payload)

    yield put(assessments.updateAssessments(response.data))
    yield put(assessments.fetchRequestAssessments(false))
    yield call(history.push, '/assessments/' + response.data.id + '/edit')
  } catch (err) {
    console.log(JSON.stringify(err))
  }
}

export function* updateAssessment(action) {
  try {
    yield put(assessments.fetchRequestAssessments(true))

    const { data, id } = action.payload

    const response = yield call(api.updateById, id, data)

    yield put(assessments.loadAssessment(response.data))
    yield put(assessments.fetchRequestAssessments(false))
  } catch (err) {
    console.log(JSON.stringify(err))
  }
}

export function* removeAssessmentById(action) {
  try {
    const { id } = action.payload
    yield call(api.deleteById, id)
  } catch (err) {
    console.log(JSON.stringify(err))
  }
}
