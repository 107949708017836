import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { Input, DatePicker, Spin, Button, Row, Col, Modal } from 'antd'
import { api } from '../../../../../../services/index'
import { FormItem } from './styled'
import { useTranslation } from 'react-i18next'

const ModalOrganisationScheduleTimeForm = props => {
  let initInterval = null
  const { isSuperAdmin, org, submitted } = props
  const [scheduleTime, setScheduleTime] = useState({
    schedule: null,
    ttl: 0,
    infinite: true
  })
  const [updatingSchedule, setUpdatingSchedule] = useState(false)
  const [updatingDuration, setUpdatingDuration] = useState(false)
  const [ready, setReady] = useState(false)
  const [showButton, setShowButton] = useState(false)
  const [visible, setVisible] = useState(false)
  const [voiceList, setVoiceList] = useState([])

  const [errors, setErrors] = useState({
    schedule: null,
    ttl: null
  })
  const updateData = async data => {
    try {
      const res = await api.user.updateScheduleTime(data)
      setScheduleTime(res.data)
    } catch (err) {
      console.log(err)
    } finally {
      setUpdatingSchedule(false)
      setUpdatingDuration(false)
      setErrors({
        schedule: null,
        ttl: null
      })
    }
  }
  const dateChange = async date => {
    if (!date) {
      setScheduleTime({
        ...scheduleTime,
        schedule: null
      })
      return
    }
    const unixDate = date.unix()
    setScheduleTime({
      ...scheduleTime,
      schedule: unixDate
    })
  }
  function disabledDate(current) {
    return current && current < moment().startOf('day')
  }
  // function range(start, end) {
  //   const result = []
  //   for (let i = start; i < end; i++) {
  //     result.push(i)
  //   }
  //   return result
  // }
  const durationChange = async e => {
    if (parseInt(e.target.value) > 300 || parseInt(e.target.value) < 1) {
      setErrors({
        ...errors,
        ttl: 'Must be between 1 to 300 minutes.'
      })
      return
    } else {
      setErrors({
        ...errors,
        ttl: null
      })
    }
    setScheduleTime({
      ...scheduleTime,
      ttl: parseInt(e.target.value),
      infinite: false
    })
  }

  useEffect(() => {
    const fetch = async () => {
      try {
        setUpdatingSchedule(true)
        setUpdatingDuration(true)
        const res = await api.user.getScheduleTime()
        setScheduleTime(res.data)
        const currentTime = moment()
        const scheduleTime = moment.unix(res.data.schedule)
        const scheduleTimeAfterDur = moment(
          scheduleTime.valueOf() + res.data.ttl * 60000
        )
        if (
          currentTime.isAfter(scheduleTime) &&
          currentTime.isBefore(scheduleTimeAfterDur)
        ) {
          setShowButton(true)
          initializeAudioSynthesizer()
          initInterval = setInterval(() => {
            initializeAudioSynthesizer()
          }, 120000)
        } else {
          setShowButton(false)
        }
      } catch (err) {
        console.log(err)
      } finally {
        setUpdatingSchedule(false)
        setUpdatingDuration(false)
      }
    }
    fetch()
  }, [org.id])

  const initializeAudioSynthesizer = () => {
    api.audioSynthesizer.getNewAudioAdminRes().then(res => {
      if (res.status === 200) {
        const responseData = res.data.data
        const responseDataArray = Object.entries(responseData).map(item => {
          return {
            ...item
          }
        })
        setVoiceList(responseDataArray)
        setReady(true)
        clearInterval(initInterval)
      }
    })
  }

  useEffect(() => {
    const handleupdateSchedule = async () => {
      const msgReq = 'All fields are required.'
      if (!scheduleTime.schedule || !scheduleTime.ttl) {
        setErrors({
          ...errors,
          schedule: msgReq
        })

        return
      }
      setUpdatingSchedule(true)
      await updateData(scheduleTime)
    }
    if (submitted === true) {
      handleupdateSchedule()
    }
  }, [submitted, scheduleTime, updateData, setUpdatingSchedule])

  const showModal = () => {
    setVisible(true)
  }

  const handleCancel = () => {
    setVisible(false)
  }

  const { schedule, ttl } = scheduleTime
  const { t } = useTranslation()

  return (
    <div>
      {isSuperAdmin && (
        <>
          <FormItem
            label="sharelook tts Date/Time"
            validateStatus={errors && errors.schedule ? 'error' : ''}
            help={errors && errors.schedule}
            rules={[{ required: true, message: 'Schedule is required field.' }]}
          >
            <Spin spinning={updatingSchedule}>
              <DatePicker
                format="YYYY-MM-DD HH:mm"
                allowClear={true}
                disabledDate={disabledDate}
                onChange={dateChange}
                value={schedule ? moment.unix(schedule) : ''}
                disabled={showButton || updatingDuration}
                showTime={{
                  defaultValue: moment('00:00:00', 'HH:mm:ss'),
                  format: 'HH:mm'
                }}
              />
            </Spin>
          </FormItem>
          <FormItem
            label="Duration (maximum 300 minutes)"
            validateStatus={errors && errors.ttl ? 'error' : ''}
            help={errors && errors.ttl}
            rules={[{ required: true, message: 'Duration is required field.' }]}
          >
            <Spin spinning={updatingDuration}>
              <Input
                type="number"
                max={300}
                min={1}
                onChange={durationChange}
                value={ttl || ''}
                disabled={showButton || updatingDuration}
                style={{ width: '195px' }}
              />
            </Spin>
          </FormItem>
          {showButton && (
            <div>
              {ready ? (
                <Button type="primary" onClick={showModal}>
                  {t('v3:ready')}
                </Button>
              ) : (
                <Button disabled>{t('v3:pending')}</Button>
              )}
              <Modal
                visible={visible}
                closable={false}
                destroyOnClose
                footer={<Button onClick={handleCancel}>Close</Button>}
              >
                <div>
                  {voiceList.map(item => {
                    return (
                      <div>
                        <Row>
                          <Col span={12}>{item[1].language}</Col>
                          <Col>{item[0]}</Col>
                        </Row>
                      </div>
                    )
                  })}
                </div>
              </Modal>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default ModalOrganisationScheduleTimeForm
