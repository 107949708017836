import React, { useState } from 'react'
import { Modal, Button, Icon } from 'antd'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import './ImagePreview.scss'
import 'antd/dist/antd.css'

const ImagePreview = props => {
  const [visible, setVisible] = useState(false)
  const [rotation, setRotation] = useState(0)

  const showModal = () => {
    setVisible(true)
  }

  const handleCancel = () => {
    setVisible(false)
  }

  const rotateClockwise = () => {
    setRotation(prevRotation => (prevRotation + 90) % 360)
  }

  const rotateCounterclockwise = () => {
    setRotation(prevRotation => (prevRotation - 90 + 360) % 360)
  }

  return (
    <div>
      {/* Display the image with click to show modal */}
      <img
        {...props}
        alt="Preview"
        onClick={showModal}
        className="shl-image-preview"
        style={{ cursor: 'pointer', maxWidth: '100%' }} // Cursor and styling similar to Antd
      />

      {/* Modal to show zoomable and rotatable image */}
      <Modal
        visible={visible}
        onCancel={handleCancel}
        footer={null}
        width="auto"
        bodyStyle={{ padding: 0, maxHeight: '90vh', overflow: 'hidden' }} // Prevent scrollbar and ensure image fits screen
        centered
        className="shl-image-preview-modal"
      >
        <TransformWrapper
          initialScale={1}
          minScale={1}
          maxScale={5}
          limitToBounds={true}
          panning={{ excluded: ['input', 'textarea'] }}
          smooth={true}
        >
          {({ zoomIn, zoomOut, resetTransform }) => (
            <>
              {/* Toolbar with zoom and rotation controls */}
              <div className="shl-tools">
                <Button onClick={() => zoomIn()}>
                  <Icon type="zoom-in" />
                </Button>
                <Button onClick={() => zoomOut()}>
                  <Icon type="zoom-out" />
                </Button>
                <Button onClick={rotateClockwise}>
                  <Icon type="redo" />
                </Button>
                <Button onClick={rotateCounterclockwise}>
                  <Icon type="undo" />
                </Button>
                <Button onClick={() => resetTransform()}>Reset</Button>
              </div>

              {/* Transformable image with rotation applied */}
              <TransformComponent>
                <img
                  {...props}
                  alt="Preview"
                  style={{
                    maxWidth: '100%',
                    maxHeight: '100vh',
                    objectFit: 'contain',
                    transform: `rotate(${rotation}deg)`
                  }}
                />
              </TransformComponent>
            </>
          )}
        </TransformWrapper>
      </Modal>
    </div>
  )
}

export default ImagePreview
