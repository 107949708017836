import React from 'react'
import moment from 'moment'
import { Alert, Tag } from 'antd'
import { Spinner } from '../../components'
import { useTranslation } from 'react-i18next'

import './styles.scss'

// const defaultState = {
//   data: [],
//   pagination: {}
// }

const SharebotUnSubscription = React.memo(
  ({
    title,
    data,
    loading
    // orgIdValue,
    // cancelUnSubList,
    // onChangePagination,
    // onDeleteSubscription,
    // error
  }) => {
    // const [isOpen, setIsOpen] = useState(false)
    // const [isShareBotLoading, setIsShareBotLoading] = useState(false)
    // const [isActiveSharebot, setIsActiveSharebot] = useState(defaultState)
    // const [isTrialSharebot, setIsTrialSharebot] = useState(defaultState)
    // const [subOpen, setSubOpen] = useState(false)
    // const [subType, setSubType] = useState('')
    // const [subValue, setSubValue] = useState(1)
    // const [unSubscriptionModal, setUnSubscriptionModal] = useState(false)

    // const showModal = () => {
    //   setIsOpen(true)
    // }

    // const handleCancel = () => {
    //   setIsOpen(false)
    //   setSubOpen(false)
    // }

    // const isSharebotFree = (OrgId, cusEmail) => {}

    // const isSharebotSubscribe = () => {
    //   if (data.stripe_status === 'month' || data.stripe_status === 'year') {
    //     console.log(orgIdValue, data.id, 'active org id......?')
    //     setUnSubscriptionModal(true)
    //     setIsOpen(true)
    //   } else {
    //     setIsOpen(true)
    //     setSubOpen(true)
    //   }
    // }

    // const unSubConfirmBox = () => {
    //   setIsShareBotLoading(true)
    // }
    // const handleNewSubscription = e => {
    //   setSubValue(e.target.value)
    // }

    // const subscribeShareBot = () => {
    //   setIsShareBotLoading(true)
    // }

    // const trialDatePeroid = tPeroid => {
    //   const tdate = moment(new Date()).format('DD-MM-YYYY')

    //   const date1 = moment(tdate, 'DD-MM-YYYY').valueOf()
    //   const date2 = moment(tPeroid, 'DD-MM-YYYY').valueOf()

    //   if (date2 >= date1) {
    //     return false
    //   }
    //   return true
    // }

    const { t } = useTranslation()

    return (
      <>
        <div className="account-subscription-title">&#x2022; {title}</div>
        <div className="account-subscription-content">
          {loading ? (
            <Spinner />
          ) : data && data.length > 0 ? (
            <>
              {data.map((item, index) => {
                return (
                  <div className="account-subscription-content-card">
                    <img src="/images/sharebot.png" alt="sharebot" />
                    <div className="account-subscription-content-card-info">
                      <h2 className="account-subscription-content-card-info-title">
                        {t('v3:sharebot_intel_knowledge_center')}
                      </h2>
                      <div className="account-subscription-content-card-info-description">
                        {`${t('v3:sharebot_welcome_paragraph')} `}
                      </div>

                      <div className="account-subscription-content-card-info-price">
                        <span>
                          <h1>
                            <Tag
                              style={{
                                borderRadius: '10px',
                                marginBottom: '10px',
                                color: '#fff',
                                backgroundColor: 'red'
                              }}
                            >
                              {t('v3:cancelled_date')}
                              {moment(item.canceled_date).format('DD-MM-YYYY')}
                            </Tag>
                          </h1>
                        </span>

                        {/* <Button
                          className={`sharebot-btn account-subscription-content-card-info-button ${
                            isActiveSharebot
                              ? 'sharebot-btn-primary'
                              : 'blue-bordered-button'
                          }`}
                          shape="round"
                          type={
                            isActiveSharebot ||
                            item.stripe_status === 'month' ||
                            item.stripe_status === 'year'
                              ? 'default'
                              : 'primary'
                          }
                          onClick={() => isSharebotSubscribe()}
                        >
                          {item.stripe_status === 'month' ||
                          item.stripe_status === 'year'
                            ? 'Unsubscribe'
                            : 'Subscribe Now'}
                        </Button> */}
                      </div>
                    </div>
                  </div>
                )
              })}
            </>
          ) : (
            <div className="account-subscription-error">
              <Alert message={t('v3:no_sharebot_org')} type={'info'} />
            </div>
          )}
        </div>
      </>
    )
  }
)

export default SharebotUnSubscription
