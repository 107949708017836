import { createStore, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'

import thunk from 'redux-thunk'
import logger from 'redux-logger'

import rootSagas from './sagas'
import rootReducer from './reducers'

const saga = createSagaMiddleware()

const middleWares = [saga, thunk]

if (process.env.NODE_ENV === 'development') {
  middleWares.push(logger)
}

export const store = createStore(
  rootReducer,
  applyMiddleware(...middleWares)
)

saga.run(rootSagas)
