import http from './http'

function postScormCourseById(id, data, options) {
  // return axios.post(url, data, options)
  return http({
    method: 'POST',
    url: `/scorm/course/${id}/signedurl`,
    data: { filename: data.name }
  })
}

function postScormFile(data) {
  // return axios.post(url, data, options)

  return http({
    method: 'POST',
    url: `/scorm/extract`,
    data: data,
  })
}

function getScormByCourseId(courseId) {
  return http({
    method: 'GET',
    url: `/scorm/course/${courseId}?includeAll=true`,
    data: ''
  })
}

function getLMSRunTime(user_id, sco_id) {
  return http({
    method: 'GET',
    url: `/scorm/lmsgetruntime/user/${user_id}/sco/${sco_id}`,
    data: ''
  })
}

function postLMSRunTime(data, course_id) {
  console.log('url req data log', data, course_id);
  return http({
    method: 'PUT',
    url: `/scorm/lmssetruntime/course/${course_id}`,
    data: data
  })
}

function getScormStatus(courseId) {
  return http({
    method: 'GET',
    url: `/scorm/course/${courseId}`,
    data: ''
  })
}

function getScormScanStatus(courseId) {
  return http({
    method: 'GET',
    url: `/scorm/scormav/status/course/${courseId}`,
    data: ''
  })
}

function getScormList() {
  return http({
    method: 'GET',
    url: `/courses?course_type=scorm&mode=workshop`
  })
}

export { postScormCourseById, postScormFile, getScormByCourseId, getLMSRunTime, postLMSRunTime, getScormStatus, getScormScanStatus, getScormList }
