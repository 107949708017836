import React, { Component, Fragment } from 'react'
import { withTranslation } from 'react-i18next'
import { ReportSelect } from '../styled'
import { withRouter } from 'react-router-dom'
import CourseIcon from '../../../assets/course_report_icon.png'
import EventIcon from '../../../assets/event_report_icon.png'
import MazeIcon from '../../../assets/maze_report_icon.png'
import SkillJourneyIcon from '../../../assets/skilljourney_report_icon.png'
import ShareSphereIcon from '../../../assets/sharesphere_report_icon.png'
import LiveAssessmentsIcon from '../../../assets/live_assessments_report_icon.png'

const { Option } = ReportSelect
class TypeSelect extends Component {
  handleSelect = value => {
    if (value === 'events') {
      this.props.history.push(`/reports/events/`)
    } else if (value === 'courses') {
      this.props.history.push(`/reports/course/`)
    } else if (value === 'maze') {
      this.props.history.push(`/reports/maze/`)
    } else if (value === 'live_assessments') {
      this.props.history.push(`/reports/live-assessments/`)
    } else if (value === 'skill_journey') {
      this.props.history.push(`/reports/skill-journey/`)
    } else if (value === 'sharesphere') {
      this.props.history.push(`/reports/sharesphere/`)
    }
  }
  render() {
    const { defaultValue, t, enableMaze } = this.props
    console.log('selectedValueprops', this.props)
    return (
      <Fragment>
        <div id="report_type_select" style={{ marginBottom: '18px' }}>
          <ReportSelect
            defaultValue={defaultValue}
            dropdownStyle={{ borderRadius: '9px' }}
            onSelect={value => this.handleSelect(value)}
            getPopupContainer={() =>
              document.getElementById('report_type_select')
            }
          >
            <Option value="courses" className="select-option">
              <img
                alt=""
                src={CourseIcon}
                style={{
                  height: '43px',
                  width: '40px',
                  marginRight: '5px'
                }}
              />
              {/* <Icon type="book" theme="twoTone" style={{marginRight: "10px", fontSize: "20px"}} /> */}
              <span>{t('v4:report_courses')}</span>
            </Option>
            <Option value="events" className="select-option">
              <img
                alt=""
                src={EventIcon}
                style={{
                  height: '22px',
                  width: '32px',
                  marginRight: '11px'
                }}
              />
              {/* <Icon type="video-camera" theme="twoTone"  style={{marginRight: "10px", fontSize: "20px"}} /> */}
              <span>{t('v4:report_events')}</span>
            </Option>
            {enableMaze && (
              <Option value="maze" className="select-option">
                <img
                  alt=""
                  src={MazeIcon}
                  style={{
                    height: '27px',
                    width: '27px',
                    marginRight: '13px'
                  }}
                />
                {/* <Icon type="build" theme="twoTone"  style={{marginRight: "10px", fontSize: "20px"}} /> */}
                <span>{t('v4:report_maze')}</span>
              </Option>
            )}
            <Option value="live_assessments" className="select-option">
              <img
                alt=""
                src={LiveAssessmentsIcon}
                style={{
                  height: '37px',
                  width: '33px',
                  marginRight: '11px'
                }}
              />
              <span>{t('v4:report_live_assessments')}</span>
            </Option>
            <Option value="skill_journey" className="select-option">
              <img
                alt=""
                src={SkillJourneyIcon}
                style={{
                  height: '37px',
                  width: '33px',
                  marginRight: '11px'
                }}
              />
              <span>{t('v4:report_skill_journey')}</span>
            </Option>
            <Option value="sharesphere" className="select-option">
              <img
                alt=""
                src={ShareSphereIcon}
                style={{
                  height: '26',
                  width: '34px',
                  marginRight: '11px'
                }}
              />
              <span>{t('v3:reportShareSphere')}</span>
            </Option>
          </ReportSelect>
        </div>
      </Fragment>
    )
  }
}

export default withRouter(withTranslation('report')(TypeSelect))
