import styled, { css } from 'styled-components'
import Dropzone from 'react-dropzone'
import { Icon } from 'antd'

import { media } from '../../../styles'
import { styleguide } from '../../../constants'

const { colors, typography } = styleguide

// ${props =>
//   props.cover &&
//   css`
//     background: no-repeat url(${props.cover}) center;
//     background-size: contain;
//     background-color: #f4f5fa;
//   `}

export const DropzoneWrapper = styled(Dropzone)`
  && {
    position: relative;
    width: 100%;
    // aspect-ratio:16/9;
    // border-width: 1.5px;
    // border-color: #E3E3E3;
    // border-style: solid;
    border-radius: 8px;
    cursor: pointer;
    min-height:25rem;


    &.small{
      min-height: 25rem;
    }


    

    ${props =>
      props.error &&
      css`
        border: 1.5px solid ${colors.red};
      `}

    ${media.md`
      height: 20rem;
    `}

    ${props =>
      props.mode &&
      props.mode === 'builder' &&
      `
      border-color: #979797 !important;
      width: 100% !important;
    `}

    ${props =>
      props.disabled &&
      css`
        opacity: 0.5;
        pointer-events: none;
      `}
  }
`

export const DropzoneImageWrapper = styled(Dropzone)`
  && {
    position: relative;
    width: 100%;
    // aspect-ratio:16/9;
    border-width: 1.5px;
    border-color: #e3e3e3;
    border-style: solid;
    border-radius: 8px;
    cursor: pointer;
    min-height: 25rem;

    &.small {
      min-height: 25rem;
    }
  }
`

export const DropzoneLoading = styled(Icon)`
  && {
    font-size: 3.6rem;
    color: ${colors.blue};
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -1.8rem;
    margin-top: -1.8rem;
  }
`

export const DropzoneInner = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 14rem;
  height: 15rem;
  display: flex;
  // flex-direction: column;
  width: 100%;
  // justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  padding: 2rem;
  &.small {
    height: auto;
  }

  &.small button {
    margin-bottom: 1rem;
  }
`

export const DropzoneText = styled.span`
  color: ${colors.darkGreyOne};
  font-weight: ${typography.h1FontWeight};
  font-size: 1.1rem;
  margin-left: 1rem;
`

export const modal_style = {
  overflow: 'auto',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0
}
