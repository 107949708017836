import React from 'react'
import _ from 'lodash'
import { withTranslation } from 'react-i18next'
import View from './internals/View'
import { upload, updateAR } from '../../../../services/api/library'
import { config } from '../../../../constants'

class ARUploads extends React.Component {
  state = {
    mediaDat: {},
    zipLoading: false,
    imgLoading: false,
    imgPreviewLoading: false,
    audioLoading: false,
    scaleMP4: '',
    unity_coordinates: '',
    scaleZip: 0.15,
    isZip: false,
    tempId: '',
    submitLoading: false,
    imageSizeCheck: false,
    isMarkerBased: 1,
    zipSizeCheck: false
  }

  componentDidMount() {
    const { editAssets, selectedData } = this.props
    const { mediaDat } = this.state
    console.log('didmount', selectedData)
    if (
      editAssets &&
      selectedData &&
      selectedData.id &&
      selectedData.ar_files
    ) {
      const found = selectedData.ar_files.some(
        el =>
          el.media_mime_type === 'application/zip' ||
          el.media_mime_type === 'application/x-zip-compressed'
      )
      console.log('found check didmount', found)
      this.setState({
        mediaDat: {
          ...mediaDat,
          id: selectedData.id,
          is_interactive: selectedData.is_interactive,
          ar_files: selectedData.ar_files,
          custom_data: selectedData.custom_data
        },
        isZip: !found ? false : true,
        isMarkerBased: !_.isEmpty(selectedData.custom_data.geoloc) ? 2 : 1
      })
    }

    // SERVER WAKE CALL
    const GET_URL = `${config.phpServerURL}?select=GetTarget`
    fetch(GET_URL)
      .then(response => {
        return response.json()
      })
      .then(response => {
        console.log('PHP response GET', response)
      })
      .catch(error => {
        // handle the error
        console.log('ERROR GET', error)
      })
  }

  onUnitySaveClicked = (data) => {
    const { unity_coordinates, mediaDat } = this.state
    console.log("data from unity", JSON.parse(data.json))
    this.setState({
      mediaDat: { ...mediaDat, is_interactive: data.switchInteractive },
      unity_coordinates: JSON.parse(data.json)
    }, () => {
      console.log("later data from unity", unity_coordinates)
    })
  }

  onDropTargetImage = (acceptedFiles, rejectedFiles) => {
    const accepted_files = acceptedFiles[0]
    const rejected_files = rejectedFiles[0]

    if (rejected_files) {
      this.setState({
        imageSizeCheck: true
      })
      return
    }

    this.fileReader(accepted_files, 'preview-img')
    const { upload } = this.props
    this.setState(
      {
        imgLoading: true,
        imageSizeCheck: false
      },
      () => {
        upload(accepted_files, { isAR: 'imgAR' }).then(value => {
          const { mediaDat } = this.state
          this.setState({
            mediaDat: {
              ...mediaDat,
              category: 'ar',
              key: value.key,
              filename: value.filename,
              type: value.type,
              format: 'ARIMAGE'
            },
            imgLoading: false
          })
        })
      }
    )
  }

  onDropppedZip = (acceptedFiles, rejectedFiles) => {
    const { upload } = this.props
    const { mediaDat } = this.state
    const accepted_files = acceptedFiles[0]
    const rejected_files = rejectedFiles[0]

    if (rejected_files) {
      console.log('i m rejected zip')
      this.setState({
        zipSizeCheck: true
      })
      return
    }
    console.log('acceptedFiles Array', acceptedFiles)
    if (accepted_files && accepted_files.type === 'video/mp4') {
      const video = document.createElement('video')
      video.addEventListener('loadedmetadata', () => {
        console.log('Video Width Height', video.videoWidth, video.videoHeight)
        if (mediaDat.hasOwnProperty('ar_files')) {
          let arFiles = mediaDat.ar_files.slice()
          const audioIndex =
            mediaDat &&
            mediaDat.ar_files &&
            arFiles.findIndex(obj => {
              return obj.media_format === 'AUDIO'
            })
          if (audioIndex > -1) {
            arFiles.splice(audioIndex, 1)
          }

          const compressId =
            mediaDat &&
            mediaDat.ar_files &&
            arFiles.find(obj => {
              return obj.media_format === 'COMPRESSED'
            })

          this.setState(
            {
              tempId: compressId && compressId.id ? compressId.id : ''
            },
            () => {
              const compressIndex =
                mediaDat &&
                mediaDat.ar_files &&
                arFiles.findIndex(obj => {
                  return obj.media_format === 'COMPRESSED'
                })

              if (compressIndex > -1) {
                arFiles.splice(compressIndex, 1)
              }

              console.log('arFiles after spliced', arFiles)
              this.setState({
                mediaDat: { ...mediaDat, ar_files: arFiles }
              })
            }
          )
        }
        this.setState({
          scaleMP4: video.videoHeight / video.videoWidth,
          isZip: false
        })
      })
      video.src = URL.createObjectURL(accepted_files)
    } else {
      this.setState(
        {
          isZip: true
        },
        () => {
          if (mediaDat.hasOwnProperty('ar_files')) {
            let arFiles = mediaDat.ar_files.slice()

            const videoId =
              mediaDat &&
              mediaDat.ar_files &&
              arFiles.find(obj => {
                return obj.media_format === 'VIDEO'
              })

            this.setState(
              {
                tempId: videoId && videoId.id ? videoId.id : ''
              },
              () => {
                const videoIndex =
                  mediaDat &&
                  mediaDat.ar_files &&
                  arFiles.findIndex(obj => {
                    return obj.media_format === 'VIDEO'
                  })

                if (videoIndex > -1) {
                  arFiles.splice(videoIndex, 1)
                }

                console.log('arFiles after spliced else', arFiles)
                this.setState({
                  mediaDat: { ...mediaDat, ar_files: arFiles }
                })
              }
            )
          }
        }
      )
    }
    this.setState(
      {
        zipLoading: true
      },
      () => {
        console.log('accepted_files file ', accepted_files)
        upload(accepted_files, { isAR: 'zipAR' }).then(value => {
          const { tempId } = this.state
          const typeTemp = value.type === 'video/mp4' ? 'VIDEO' : 'COMPRESSED'
          console.log('tempId file ', tempId)
          const index = this.findIndex(typeTemp)
          if (tempId !== '') {
            value.id = tempId
          }
          console.log('value chnaged ', value)
          this.checkPoint(
            this.generateObject(typeTemp, value),
            index,
            'zipLoading'
          )
        })
      }
    )
  }

  onDropTargetPreviewImage = (acceptedFiles, rejectedFiles) => {
    const accepted_files = acceptedFiles[0]
    const rejected_files = rejectedFiles[0]

    if (rejected_files) {
      console.log('i m rejected preview image')
      return
    }

    this.fileReader(accepted_files, 'preview-ar-img')
    const { upload } = this.props
    this.setState(
      {
        imgPreviewLoading: true
      },
      () => {
        upload(accepted_files, { isAR: 'zipAR' }).then(value => {
          console.log('VALUE CHECK PREVIEW', value)
          const index = this.findIndex('IMAGE')
          this.checkPoint(
            this.generateObject('IMAGE', value),
            index,
            'imgPreviewLoading'
          )
        })
      }
    )
  }

  onDropppedAudio = (acceptedFiles, rejectedFiles) => {
    const accepted_files = acceptedFiles[0]
    const rejected_files = rejectedFiles[0]

    if (rejected_files) {
      console.log('i m rejected audio')
      return
    }

    const { upload } = this.props
    this.setState(
      {
        audioLoading: true
      },
      () => {
        upload(accepted_files, { isAR: 'zipAR' }).then(value => {
          console.log('VALUE CHECK AUDIO', value)
          const index = this.findIndex('AUDIO')
          this.checkPoint(
            this.generateObject('AUDIO', value),
            index,
            'audioLoading'
          )
        })
      }
    )
  }

  fileReader = (f, t) => {
    const reader = new FileReader()
    reader.onload = () => {
      const output = document.getElementById(t)
      output.src = reader.result
    }
    reader.readAsDataURL(f)
  }

  isType = data => {
    const index =
      data &&
      data.data &&
      data.data.ar_files &&
      data.data.ar_files.findIndex(obj => {
        return obj.media_format === 'COMPRESSED' || obj.media_format === 'VIDEO'
      })
    if (
      index > -1 &&
      data.data.ar_files[index].media_mime_type === 'video/mp4'
    ) {
      return 'mp4'
    } else {
      return 'zip'
    }
  }

  resetState = () => {
    const { hideModal } = this.props
    hideModal()
    this.setState({
      mediaDat: {},
      zipLoading: false,
      isZip: false,
      imgLoading: false,
      imgPreviewLoading: false,
      audioLoading: false,
      submitLoading: false,
      tempId: ''
    })
  }

  onChangeZipScale = v => {
    console.log('inside onChangeZipScale', v)
    this.setState({
      scaleZip: v
    })
  }

  onChangeMarkerBased = e => {
    this.setState({
      isMarkerBased: e.target.value
    })
  }

  generateObject = (mediaFormat, val) => {
    console.log('log val', val)
    return {
      filename: val.filename,
      media_format: mediaFormat,
      scale: null,
      id: val.id,
      key: val.key,
      media_mime_type: val.type
    }
  }

  findIndex = type => {
    const { mediaDat } = this.state
    return (
      mediaDat &&
      mediaDat.ar_files &&
      mediaDat.ar_files.findIndex(obj => {
        return obj.media_format === type
      })
    )
  }

  checkPoint = (struct, index, loader) => {
    const { mediaDat } = this.state
    if (mediaDat.hasOwnProperty('ar_files')) {
      let arFiles = mediaDat.ar_files.slice()
      if (index > -1) {
        struct.id = arFiles[index].id
        arFiles[index] = struct
      } else {
        arFiles[arFiles.length] = struct
      }
      this.setState({
        mediaDat: { ...mediaDat, ar_files: arFiles },
        [loader]: false
      })
    } else {
      this.setState({
        mediaDat: { ...mediaDat, ar_files: [struct] },
        [loader]: false
      })
    }
  }

  onChangeTitle = e => {
    const { mediaDat } = this.state
    this.setState({
      mediaDat: { ...mediaDat, title: e.target.value.trim() }
    })
  }

  onChangeDescription = e => {
    const { mediaDat } = this.state
    this.setState({
      mediaDat: { ...mediaDat, description: e.target.value }
    })
  }

  onGeoLocation = e => {
    const { mediaDat } = this.state
    this.setState({
      mediaDat: { ...mediaDat, customData: { geoloc: e } }
    })
  }

  onClickUpdate = () => {
    this.setState({ submitLoading: true }, () => {
      const { dispatchResult } = this.props
      const { mediaDat, scaleZip, unity_coordinates } = this.state

      const cIndex =
        mediaDat &&
        mediaDat.ar_files.findIndex(obj => {
          return obj.media_format === 'COMPRESSED'
        })
      let arFilesTemp = mediaDat.ar_files.slice()

      if (cIndex > -1) {
        arFilesTemp[cIndex].scale = scaleZip
      }

      this.setState(
        {
          mediaDat: { ...mediaDat, ar_files: arFilesTemp }
        },
        () => {
          if (mediaDat && mediaDat.id) {
              const updatedMediaDat = {
              ...mediaDat,
              customData: {
                ...mediaDat.custom_data,
                unity_coordinates: unity_coordinates
              }
            };
            updateAR(updatedMediaDat, mediaDat.id).then(data => {
              console.log('UPDATE RETURN', data)
              const isTypeData = this.isType(data)
              console.log('log isTypeData update', isTypeData)
              if (
                data.data &&
                data.data.ar_files &&
                data.data.custom_data &&
                data.data.custom_data.target_id
              ) {
                const { scaleMP4 } = this.state
                const index = data.data.ar_files.findIndex(obj => {
                  return (
                    obj.media_format === 'COMPRESSED' ||
                    obj.media_format === 'VIDEO'
                  )
                })

                console.log('log index update', index)

                const audioIndex = data.data.ar_files.findIndex(obj => {
                  return obj.media_format === 'AUDIO'
                })

                const tempScaleMP4 =
                  scaleMP4 !== '' ? scaleMP4 : data.data.custom_data.scaleMP4

                const UPDATE_URL = `${config.phpServerURL
                  }?select=UpdateTarget&isType=${isTypeData}&mp4Scale=${tempScaleMP4}&scale=${data.data.ar_files[index].scale
                  }&paramImg=${data.data.link}&targetId=${data.data.custom_data.target_id
                  }&paramAudio=${audioIndex > -1 ? data.data.ar_files[audioIndex].link : null
                  }&paramZip=${data.data.ar_files[index].link}`
                fetch(UPDATE_URL)
                  .then(response => {
                    return response.json()
                  })
                  .then(response => {
                    console.log('PHP response update', response)
                    dispatchResult(data.data)
                    this.resetState()
                  })
                  .catch(error => {
                    // handle the error
                    console.log('UPDATE CATCH ERROR', error)
                    dispatchResult(data.data)
                    this.resetState()
                  })

                /*axios.get(baseURL_POST, { crossdomain: true }).then(response => {
                  console.log('PHP response update', response)
                  // @TO DO
                }).catch(error => {
                  console.log("UPDATE CATCH ERROR", error)
                })*/
              } else {
                //@TODO Remove this later
                dispatchResult(data.data)
                this.resetState()
              }
            })
          }
        }
      )
    })
  }

  onClickSubmit = () => {
    const { mediaDat, scaleZip } = this.state

    // if(isMarkerBased === 2 && _.isEmpty(mediaDat.custom_data) && _.isEmpty(mediaDat.custom_data.geoloc)){
    //       return
    // }

    const cIndex =
      mediaDat &&
      mediaDat.ar_files.findIndex(obj => {
        return obj.media_format === 'COMPRESSED'
      })
    let arFilesTemp = mediaDat.ar_files.slice()

    if (cIndex > -1) {
      arFilesTemp[cIndex].scale = scaleZip
    }

    this.setState(
      {
        mediaDat: { ...mediaDat, ar_files: arFilesTemp }
      },
      () => {
        this.setState({ submitLoading: true }, () => {
          console.log('mediaState before dispatch', mediaDat)
          const { dispatchResult } = this.props
          upload(mediaDat).then(data => {
            const isTypeData = this.isType(data)
            console.log('POST RETURN', data)
            if (data && data.data && data.data.ar_files) {
              const index = data.data.ar_files.findIndex(obj => {
                return (
                  obj.media_format === 'COMPRESSED' ||
                  obj.media_format === 'VIDEO'
                )
              })

              const audioIndex = data.data.ar_files.findIndex(obj => {
                return obj.media_format === 'AUDIO'
              })

              setTimeout(() => {
                const { scaleMP4, unity_coordinates } = this.state
                const POST_URL = `${config.phpServerURL
                  }?select=PostNewTarget&isType=${isTypeData}&mp4Scale=${scaleMP4}&scale=${data.data.ar_files[index].scale
                  }&paramImg=${data.data.link}&paramAudio=${audioIndex > -1 ? data.data.ar_files[audioIndex].link : null
                  }&paramZip=${data.data.ar_files[index].link}`
                fetch(POST_URL)
                  .then(response => {
                    return response.json()
                  })
                  .then(response => {
                    console.log('PHP response check', response)
                    let target_id = {
                      customData: response
                    }
                    target_id.customData.geoloc = data.data.custom_data.geoloc
                    target_id.customData.unity_coordinates = unity_coordinates
                    // @TODO
                    if (response && response.result_code === 'TargetCreated') {
                      if (scaleMP4 !== '') {
                        target_id.customData.scaleMP4 = scaleMP4
                      }
                      updateAR(target_id, data.data.id).then(value => {
                        console.log('UpdateAR logs: ', value)
                        dispatchResult(data.data)
                        this.resetState()
                      })
                    }
                  })
                  .catch(error => {
                    // handle the error
                    console.log('CREATE CATCH ERROR', error)
                    dispatchResult(data.data)
                    this.resetState()
                  })

                /*axios.get(baseURL_POST,{ crossdomain: true }).then(response => {
                console.log('PHP response check', response)
                const target_id = {
                  customData: response.data
                }
                // @TODO
                if (
                  response.data &&
                  response.data.result_code === 'TargetCreated'
                ) {
                  updateAR(target_id, data.data.id).then(value => {
                    console.log('UpdateAR logs: ', value)
                  })
                } /!*else {
                      // rollback uploaded AR assets
                  }*!/
              }).catch(error => {
                   console.log("CREATE CATCH ERROR", error)
              })*/
              }, 300)
            }
          })
        })
      }
    )
  }

  render() {
    const {
      mediaDat,
      zipLoading,
      imgLoading,
      submitLoading,
      imgPreviewLoading,
      audioLoading,
      scaleMP4,
      isZip,
      imageSizeCheck,
      isMarkerBased,
      zipSizeCheck
    } = this.state

    const { editAssets, selectedData, distroyUnity } = this.props
    
    return (
      <View
        mediaDat={mediaDat}
        distroyUnity={distroyUnity}
        zipLoading={zipLoading}
        isMarkerBased={isMarkerBased}
        imgLoading={imgLoading}
        scaleMP4={scaleMP4}
        selectedData={selectedData}
        submitLoading={submitLoading}
        imgPreviewLoading={imgPreviewLoading}
        audioLoading={audioLoading}
        isZip={isZip}
        onChangeZipScale={this.onChangeZipScale}
        imageSizeCheck={imageSizeCheck}
        zipSizeCheck={zipSizeCheck}
        editAssets={editAssets}
        onChangeTitle={this.onChangeTitle}
        onChangeDescription={this.onChangeDescription}
        onDropTargetImage={this.onDropTargetImage}
        onDropppedZip={this.onDropppedZip}
        onDropppedAudio={this.onDropppedAudio}
        onDropTargetPreviewImage={this.onDropTargetPreviewImage}
        onClickUpdate={this.onClickUpdate}
        onClickSubmit={this.onClickSubmit}
        onChangeMarkerBased={this.onChangeMarkerBased}
        onGeoLocation={this.onGeoLocation}
        onUnitySaveClicked={this.onUnitySaveClicked}
      />
    )
  }
}

export default withTranslation('')(ARUploads)
