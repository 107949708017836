import React from 'react'

export default () => {
  return (
    <div className='static-page'>
      <div style={{ padding: '0 2rem', maxWidth: '128rem', margin: '0 auto' }}>
        <h2>
          <p>USER AGREEMENT</p>
          <p>TERMS AND CONDITIONS</p>
        </h2>
        <p><strong>THIS IS AN AGREEMENT BETWEEN SHARELOOK PTE LTD AND YOU, THE USER.</strong></p>
        <p><strong>Accessing the Sharelook website and mobile application software and using the Services means that you
          must accept all of the terms and conditions contained in this Agreement, the Privacy Statement and the Licence
          Agreement for Mobile App. You should read all of these terms carefully.</strong></p>
        <ol>

          <li><h2> Definitions</h2></li>
          <p>
            <p>In this Agreement, the following words shall have the following meanings:</p>
            <p><strong>App</strong> means the Sharelook mobile application developed by Sharelook.</p>
            <p><strong>Confidential Information</strong> means any and all information in whatsoever form relating to
              Sharelook or the User, or the business, prospective business, technical processes, computer software (both
              source code and object code), Intellectual Property Rights or finances of Sharelook or the User (as the
              case may be), or compilations of two or more items of such information, whether or not each individual
              item is in itself confidential, which comes into a party's possession by virtue of its entry into this
              Agreement or provision of the Services, and which the party regards, or could reasonably be expected to
              regard, as confidential and any and all information which has been or may be derived or obtained from any
              such information. Such information includes know-how, trade secrets, products, operations, processes,
              product information unpublished information relating to either of the parties&rsquo; Intellectual Property
              Rights, any any other commercial, financial or technical information relating to the business or
              prospective business of either of the parties.</p>
            <p><strong>Content</strong> means training and education material developed and provided by Sharelook or the
              User or other third parties, as the case may be, relating to and for the purpose of the Services, which
              includes (but is not limited to) performances, statements, comments, communications, animations and
              images.</p>
            <p><strong>Fees</strong> means fees for the Services as set out in the Sites.</p>
            <p><strong>Intellectual Property Rights </strong>means all copyrights, patents, utility models, trade marks,
              service marks, registered designs, moral rights, design rights (whether registered or unregistered),
              technical information, know-how, database rights, semiconductor topography rights, business names and
              logos, computer data, generic rights, proprietary information rights and all other similar proprietary
              rights (and all applications and rights to apply for registration or protection of any of the foregoing)
              as may exist anywhere in the world.</p>
            <p><strong>Operating Rules </strong>means any Sharelook rules or protocols, in whatever form recorded, that
              affect the User's access to or use of the Services, and made available by the Sharelook from time to time
              to the User.</p>
            <p><strong>Privacy Statement </strong>means the Sharelook Privacy Statement as posted on the Sites, as may
              be amended from time to time.</p>
            <p><strong>Services </strong>means means any products, services, content, features, technologies, or
              functions, and all related websites, applications and services offered to the User by Sharelook in
              connection with an account or transaction.</p>
            <p><strong>Sites&nbsp;</strong>means websites, mobile apps or social media platforms used by Sharelook to
              offer Services.</p>
            <p><strong>Trade Marks </strong>means registered and unregistered trade marks, and any logos, belonging to
              the Content Provider (as defined in Clause 2).</p>
            <p><strong>User</strong>&nbsp;means an individual who uses the Services or accesses the Sites and has
              established a relationship with Sharelook by registering to use the Sites and/or Services.</p>
          </p>

          <li><h2>Services and Content</h2>
            <p>
              <ol>
                <li>The User engages Sharelook and Sharelook agrees to provide the Services in accordance with the terms
                  of this Agreement.
                </li>
                <li>Sharelook warrants that by performing the Services it will not knowingly infringe the rights of any
                  third party (including but not limited to Intellectual Property Rights) in any jurisdiction or be in
                  breach of any obligations it may have to a third party. Sharelook further warrants that it is not
                  prohibited from providing the Services by any statutory or other rules or regulations in any relevant
                  jurisdiction.
                </li>
                <li>Where Content is provided by the User (for the purposes of this Clause known as the &ldquo;Content
                  Provider&rdquo;):
                  <ol type='a'>
                    <li>Content Provider warrants that where Content is provided in respect of the Services such
                      Content <strong>does not</strong>:
                      <ol type='i'>
                        <li>infringe the rights of any third party (including but not limited to Intellectual Property
                          Rights and Confidential Information) in any jurisdiction or be in breach of any obligations it
                          may have to a third party. Content Provider further warrants that it is not prohibited from
                          providing Content by any statutory or other rules or regulations in any relevant jurisdiction;
                        </li>
                        <li>contain any profanity, obscenities of any kind, even disguised with asterisks;</li>
                        <li>contain any personal attacks on other persons;</li>
                        <li>contain any illegality, including defamatory, obscene, indecent, lewd, pornographic,
                          violent, abusive, insulting, threatening, discriminatory (racist or sexist) or harassing
                          statements or comments;
                        </li>
                        <li>contain any impersonation of other participants, public figures or celebrities; or</li>
                        <li>contain any personal information on any individual without the consent of such individual
                          and is not in breach of any data protection regulation in any part of the world;
                        </li>
                        <li>contain any software virus;</li>
                        <li>contain any commercial solicitation or any form of &ldquo;spam&rdquo;;</li>
                        <li>contain anything which may cause injury to any person or entity;</li>
                      </ol>
                    </li>
                    <li>Content Provider grants to Sharelook a non-exclusive world-wide licence to:
                      <ol type='i'>
                        <li>reproduce and store copies of the Content in such format and by such technology and/or media
                          as Sharelook may require at its own discretion;
                        </li>
                        <li>publish, distribute, transmit and otherwise reproduce all or part of the Content in
                          connection with, across and through the Sites;
                        </li>
                        <li>allow other Users to download, print, store and use and Content in connection with the
                          Services provided by Sharelook;
                        </li>
                        <li>use and reproduce the Content Provider&rsquo;s Trade Marks in connection with the
                          publication of the Content for the purpose of the Services;
                        </li>
                      </ol>
                    </li>
                    <li>Sharelook shall be entitled to sub-licence such Content to other Users for the purposes related
                      to the provision of the Services.
                    </li>
                  </ol>
                </li>
              </ol>
            </p>
          </li>

          <li>
            <p>
              <h2>Licences</h2>
              <ol>
                <li>Subject to the User's payment of the Fees, the User is granted a non-exclusive and non-transferable
                  licence to use the Services (including any associated software, Intellectual property Rights and
                  Confidential Information) during the Term. Such licence shall permit the User to make such copies of
                  software or other information as are required for the User to receive the Services.
                </li>
                <li>All Intellectual Property Rights and title to the Services (save to the extent incorporating any
                  User or third party owned item) shall remain with Sharelook and/or its licensors and no interest or
                  ownership the Services, the Intellectual Property Rights or otherwise is conveyed to the User under
                  this agreement. No right to modify, adapt, or translate the services or create derivative works from
                  the Services is granted to the User. Nothing in this Agreement shall be construed to mean, by
                  inference or otherwise, that the User has any right to obtain source code for the software comprised
                  within the Services.
                </li>
                <li>Disassembly, decompilation or reverse engineering and other source code derivation of the software
                  comprised within the Services is prohibited. To the extent that the User is granted the right by law
                  to decompile such software in order to obtain information necessary to render the Services
                  interoperable with other software (and upon written request by the User identifying relevant details
                  of the Services with which interoperability is sought and the nature of the information needed),
                  Sharelook will provide access to relevant source code or information. Sharelook has the right to
                  impose reasonable conditions including but not limited to the imposition ofa reasonable fee for
                  providing such access and information.
                </li>
                <li>Unless otherwise specified in this Agreement, the Services are provided and may be used solely by
                  the User as part of the User's website/desktop/ mobile device architecture. The User may not (i)
                  lease, loan, resell or otherwise distribute the Services save as permitted in writing by Sharelook
                  (ii) use the Services to provide ancillary services related to the Services ; or (iii) except as
                  permitted in this Agreement, permit access to or use of the Services by or on behalf of any third
                  party.
                </li>
                <li>The User warrants and represents that it shall maintain reasonable security measures (as may change
                  over time) covering, without limitation, confidentiality, authenticity and integrity to ensure that
                  the access to the Services granted under this Agreement is limited as set out under this Agreement.
                </li>
              </ol>
            </p>
          </li>

          <li>
            <p>
              <h2>Purchases and payments</h2>
              <ol>
                <li>In consideration of the provision of the Services by Sharelook, the User shall pay to Sharelook the
                  Fees as set out in the Sites.
                </li>
                <li>Sharelook shall render invoices to the User in respect of the Fees in connection with the provision
                  of the Services in accordance with this Agreement. The User shall not account to the Sharelook for any
                  Fees or disbursements unless an invoice has been provided.
                </li>
                <li>Payment by the User shall be without prejudice to any claims or rights which the User may have
                  against Sharelook and shall not constitute any admission by the User as to the performance by
                  Sharelook of its obligations under the Agreement.
                </li>
                <li>All invoices by the Sharelook to the User shall be rendered in United States Dollars and shall be
                  payable in full by the User together with any Value Added Tax (if applicable) prior to the provision
                  of Services. Where payment for the provision of the services is late, the Sharelook reserves the right
                  to suspend the provision of the Services.
                </li>
              </ol>
            </p>
          </li>

          <li>
            <p>
              <h2>Warranties</h2>
              <ol>
                <li>Sharelook warrants that it owns, or is the licensee to, all right, title and interest in and to the
                  Sites and the Services provided, including all rights under patent, copyright, trade secret,
                  trademark, or unfair competition law, and any and all other proprietary rights, renewals, extensions
                  and restorations thereof. Other company, product, or service names mentioned in Sites and the App may
                  be trademarks of their respective companies.
                </li>
                <li>Sharelook has the right to provide the Services and that the Services will operate to provide the
                  facilities and functions implemented by the Sharelook.
                </li>
                <li>The Service(s) provided are on a &ldquo;as is&rdquo; and &ldquo;as available&rdquo; basis. Sharelook
                  expressly disclaims to the fullest extent permitted by law all express, implied and statutory
                  warranties and conditions, including without limitation the warranties of merchantability, fitness for
                  a particular purpose and non-infringement of proprietary rights, and any warranties regarding the
                  security, reliability, timeliness and performance of the Services provided. You download and use the
                  Sites and the Services at your own discretion and risk, and you are solely responsible for any damages
                  to your hardware device(s) or loss of data that results from the download or use of the Sites and/or
                  Services. Further, no warranty is made regarding the results of usage of the Services or that the
                  functionality of the Services will meet the requirements of the User or that the Services will operate
                  uninterrupted or error free.
                </li>
                <li>The foregoing warranties do not (i) cover deficiencies or damage caused by or relating to any third
                  party components or Content not furnished by the Sharelook; or (ii) any third party provided
                  connectivity necessary for the provision or use of the Services.
                </li>
                <li>In the event of a breach of the warranties under this clause 5, Sharelook shall have no liability or
                  obligations to the User other than to reimburse the Fees for the Services.
                </li>
                <li>User warrants and undertakes not modify, adapt, translate, prepare derivative works from, decompile,
                  reverse-engineer, disassemble or otherwise attempt to derive source code from the Sites and the App
                  and will not remove, obscure or alter Sharelook&rsquo;s copyright notice, trademarks or other
                  proprietary rights notices affixed to, contained within or accessed in conjunction with the Sites
                  and/or the Services.
                </li>
                <li>This clause shall survive the termination of this Agreement.</li>
              </ol>
            </p>
          </li>

          <li>
            <p>
              <h2>Limitation of liability</h2>
              <ol>
                <li>Except as otherwise stated in this Agreement, Sharelook is not liable to the User for any harm
                  resulting from any use or misuse of the Sites or any of the Services provided by Sharelook. Such
                  exclusion of liability: (a) includes direct, indirect, incidental, consequential, special, exemplary
                  and punitive damages, whether such claim is based on warranty, contract, tort or otherwise (even if
                  Sharelook has been advised of the possibility of such damages); (b) applies whether damages arise from
                  use or misuse of and reliance on the Sites or the Services, from inability to use the Sites or
                  Services, or from the interruption, suspension, or termination of the Services or use of the Sites
                  (including any damages incurred by third parties); (c) applies notwithstanding a failure of the
                  essential purpose of any limited remedy and to the fullest extent permitted by law; and (d) includes
                  damages arising from misrepresentation; and (e) damages arising for Consequential
                  Loss. &lsquo;Consequential Loss&rsquo; shall for the purposes of this clause&nbsp;mean (i) pure
                  economic loss; (ii) losses incurred by any client of the User or other third party; (iii) loss of
                  profits (whether categorised as direct or indirect); (iv) losses arising from business interruption;
                  (v) loss of business revenue, goodwill or anticipated savings; (vi) losses whether or not occurring in
                  the normal course of business, wasted management or staff time and; (vii) loss or corruption of data.
                </li>
                <li>Subject to clause 6.1, the total liability of Sharelook (whether in contract, tort or otherwise)
                  under or in connection with this Agreement or based on any claim for indemnity or contribution shall
                  not exceed the Fees paid by the injured User in respect of the Services.
                </li>
                <li>ln no event shall the User raise any claim under this Agreement more than two (2) years after (i)
                  the discovery of the circumstances giving rise to such claim; or (ii) the effective date of the
                  termination of this Agreement. This clause shall survive the termination of this Agreement.
                </li>
                <li>The User hereby acknowledges and agrees that in entering into this Agreement, the User had recourse
                  to its own skill and judgement and has not relied on any representations made by Sharelook, any
                  employees or agents ofSharelook.
                </li>
              </ol>
            </p>
          </li>

          <li>
            <p>
              <h2>Intellectual property indemnification</h2>
              <ol>
                <li>Sharelook at its own expense shall:
                  <ol type='a'>
                    <li>defend, or at its option, settle any claim or suit brought against the User by a third party on
                      the basis of infringement of any Intellectual property Rights by the Services (excluding any claim
                      or suit deriving from any User provided item); and
                    </li>
                    <li>pay any final judgement entered against the User on such issue or any settlement thereof,
                      provided that: (i) the User notifies Sharelook promptly of each such claim or suit; (ii) Sharelook
                      is given sole control of the defence and/or settlement; and (iii) User fully co-operates and
                      provides all reasonable assistance to the Sharelook in the defence or settlement.
                    </li>
                  </ol>
                </li>
                <li>If all or any part of the Services becomes, or in the opinion of Sharelook may become, the subject
                  of a claim or suit of infringement, Sharelook at its own expense and sole discretion may:
                  <ol type='a'>
                    <li>procure for the User the right to continue to use the Services or the affected part thereof;
                      or
                    </li>
                    <li>replace the Services or affected part with other suitable non-infringing service(s); or</li>
                    <li>modify the Services or affected part to make the same non-infringing.</li>
                  </ol>
                </li>
                <li>Sharelook shall have no obligations under this clause 7 to the extent that a claim is based on:
                  <ol type='a'>
                    <li>the combination, operation or use of the Services with other services or software not provided
                      by Sharelook, if such infringement would have been avoided in the absence of such combination,
                      operation or use; or
                    </li>
                    <li>use of the Services in any manner inconsistent with this Agreement; or</li>
                    <li>the negligence or wilful misconduct of the User.</li>
                  </ol>
                </li>
                <li>The User shall indemnify and hold Sharelook and its suppliers or agents harmless from and against
                  any cost, losses, liabilities and expenses, including reasonable legal costs arising from any claim
                  relating to or resulting directly or indirectly from (i) any claimed infringement or violation by the
                  User of any Intellectual Property Rights with respect to the User's use of the Services outside the
                  scope of this Agreement; (ii) any access to or use of the Services by a third party, (iii) any loss
                  damage expense or injury caused by Content provided by the User as a Content Provider and (iv) use by
                  Sharelook of any User provided data or item, including Content.
                </li>
              </ol>
            </p>
          </li>

          <li>
            <p>
              <h2>Indemnity</h2>
              <ol>
                <li>Subject to clause 7, each party ('the first party') indemnifies and undertakes to keep indemnified
                  the other party, its officers, servants and agents ('the second party') against any costs or expenses
                  (including the cost of any settlement) arising out of any claim, action, proceeding or demand that may
                  be brought, made or prosecuted against the second party by any person arising out of or as a
                  consequence of an unlawful or negligent act or omission of the first party, its officers, servants or
                  agents in any way connected with this Agreement whether arising from any failure by the first party to
                  comply with the terms of this Agreement or otherwise.
                </li>
                <li>The indemnity contained in clause 8.1 above extends to and includes all costs, damages and expenses
                  (including legal fees and expenses) reasonably incurred by the second party in defending any such
                  action, proceeding claim or demands.
                </li>
              </ol>
            </p>
          </li>

          <li>
            <p>
              <h2>Termination</h2>
              <ol>
                <li>Sharelook may immediately terminate this Agreement or the provision of any Services provided
                  pursuant to this Agreement if the User has used or permitted the use of the Services otherwise than in
                  accordance with this Agreement.
                </li>
                <li>The User shall be entitled to terminate this Agreement at any time without notice if Sharelook is
                  prohibited, under the laws of Singapore or otherwise, from providing the Services.
                </li>
                <li>Either party shall be entitled to terminate this Agreement at any time without notice if:
                  <ol type='a'>
                    <li>the other party ceases or threatens to cease to carry on business; or</li>
                    <li>the other party is unable to pay its debts or enters into compulsory or voluntary liquidation
                      (other than for the purpose of effecting a reconstruction or amalgamation in such manner that the
                      company resulting from such reconstruction or amalgamation shall be bound by and assumeSharelook's
                      obligations hereunder); or
                    </li>
                    <li>the other party compounds with or convenes a meeting of its creditors or has a receiver, manager
                      or similar official appointed in respect of its assets; or
                    </li>
                    <li>the other party has an administrator appointed or documents are filed with the court for the
                      appointment of an administrator or notice is given of an intention to appoint an administrator by
                      such party or its directors or by its creditors; or
                    </li>
                    <li>any similar event occurs under the law of any other jurisdiction in respect of that party.</li>
                  </ol>
                </li>
                <li>Either party shall be entitled to terminate this Agreement on written notice to the other party if
                  the other party commits a material breach of any term of this Agreement which, in the case of a breach
                  capable of remedy, shall not have been remedied within five (5) business days of receipt by the other
                  party of a notice from the non-defaulting party specifying the breach and requiring it to be remedied.
                </li>
                <li>On request, and in any event on termination of this Agreement for whatever reason, each party shall
                  deliver up all Confidential Information and (to the extent comprised therein) all correspondence,
                  documents and other property belonging or relating to the other party, and neither party shall,
                  without the prior written consent of the other, make or retain copies of any such documents.
                </li>
                <li>Termination of this Agreement for whatever reason shall not affect the accrued rights of the parties
                  arising in any way out of this Agreement as at the date of termination and, in particular but without
                  limitation, the right to recover damages against the other. Clauses 4, 7, 8, 9, 10, 11 and 12 shall,
                  for the avoidance of doubt, survive the expiration or sooner termination of this Agreement and shall
                  remain in force and effect.
                </li>
              </ol>
            </p>
          </li>

          <li>
            <p>
              <h2>Confidential Information</h2>
              <ol>
                <li>Each party may use the Confidential Information of a disclosing party only for the purposes of this
                  Agreement and must keep confidential all Confidential Information of each disclosing party except to
                  the extent (if any) the recipient of any confidential Information is required by law to disclose the
                  Confidential Information.
                </li>
                <li>Either party may disclose the Confidential Information of the other party to those of its employees
                  and agents who have a need to know the Confidential Information for the purposes of this Agreement .
                </li>
                <li>Both parties agree to return all documents and other materials containing Confidential Information
                  immediately upon completion of the Services.
                </li>
                <li>The obligations of confidentiality under this Agreement do not extend to information that:
                  <ol type='a'>
                    <li>was rightfully in the possession of the receiving party before the negotiations leading to this
                      Agreement;
                    </li>
                    <li>is, or after the day this Agreement is signed, becomes public knowledge (otherwise than as
                      aresult of a breach of this Agreement); or
                    </li>
                    <li>is required by law to be disclosed.</li>
                  </ol>
                </li>
              </ol>
            </p>
          </li>

          <li>
            <p>
              <h2>Data protection</h2>
              <p>Each party undertakes to comply with its obligations under the data protection laws of Singapore and
                the European Economic Area. The Privacy Statement (as amended from time to time) shall form part of this
                Agreement.</p>
            </p>
          </li>

          <li>
            <p>
              <h2>Copyright</h2>
              <p>User accepts and acknowledges that the contents of the Sites are the exclusive property of Sharelook
                Pte Ltd and/or its third party licensors and is protected by Intellectual Property laws, including but
                not limited to, copyright and trademark laws. User undertakes that no part of any Sites or Content may
                be reproduced, distributed, republished, commercially exploited, displayed, broadcast, hyperlinked or
                transmitted in any manner or by any means stored in any information retrieval system without the prior
                written consent of Sharelook with the exception that the contents may be downloaded and printed for
                personal, non commercial use without modification and with all copyright and other proprietory notices
                intact.</p>
            </p>
          </li>

          <li>
            <p>
              <h2>Third parties</h2>
              <p>Nothing contained in this Agreement or in any instrument or document executed by any party in
                connection with the provision of the Services is intended to be enforceable pursuant to the The
                Contracts (Rights of Third Parties) Act 2001 of Singapore.</p>
            </p>
          </li>

          <li>
            <p>
              <h2>Amendment / modification of Agreement</h2>
              <p>Sharelook reserves the right to unilaterally amend or modify the terms of this Agreement from time to
                time by posting an amended /modified version of this Agreement on the Sites. Such amendment or
                modification shall take effect forthwith from the time that it is posted on the Sites. Continued use of
                the Sites and/or the Services after such amendment(s) and/or modification(s) shall constitute acceptance
                by the User of the terms of the amended/modified Agreement.</p>
            </p>
          </li>

          <li>
            <p>
              <h2>Force Majeure</h2>
              <ol>
                <li>Except with respect to obligations to pay the Fees or other charges, 'Force Majeure' means anything
                  outside the reasonable control of a party, including but not limited to, acts of God, fire, storm,
                  flood, earthquake, explosion, accident, acts of the public enemy, war, rebellion, insurrection,
                  sabotage, epidemic, quarantine restriction, labour dispute, labour shortage, power shortage, including
                  without limitation where Sharelook ceases to be entitled to access the Internet for whatever reason,
                  server crashes, deletion, corruption, loss or removal of datai, transportation embargo, failure or
                  delay in transportation, any act or omission (including laws, regulations, disapprovals or failures to
                  approve) of any government or government agency.
                </li>
                <li>If a party is wholly or partially precluded from complying with its obligations under this Agreement
                  by Force Majeure, then that party's obligation to perform in accordance with this Agreement will be
                  suspended for the duration of the Force Majeure.
                </li>
                <li>As soon as practicable after an event of Force Majeure arises, the party affected by Force Majeure
                  must notify the other party of the extent to which the notifying party is unable to perform its
                  obligations under this Agreement.
                </li>
              </ol>
            </p>
          </li>

          <li>
            <p>
              <h2>Governing law and jurisdiction Dispute resolution procedure</h2>
              <ol>
                <li>This Agreement shall be governed by the laws of Singapore. Any disputes, controversy or claim
                  arising out of or in relation to this Agreement and subsequent amendments of this Agreement will be
                  resolved by way of arbitration at the Singapore International Arbitration Centre, in accordance with
                  its rules.
                </li>
                <li>The User agrees that any dispute resolution proceedings will be conducted only on an individual
                  basis and not in a class, consolidated or representative action.
                </li>
              </ol>
            </p>
          </li>

          <li>
            <p>
              <h2>Web Analytics</h2>
              <p>The&nbsp;Sites use Flurry Analytics and Google Analytics, web analytics services. Both Flurry Analytics
                and Google Analytics use &ldquo;cookies&rdquo;, which are text files placed on the Users computer, to
                help the website analyze how Users use the Sites. The information generated by the cookie about the
                Users use of the website (including Users&rsquo; IP addressess) will be transmitted to and stored on
                servers in the United States. The User accepts and consents to the placement
                of &ldquo;cookies&rdquo; for the aforesaid purpose.</p>
            </p>
          </li>
        </ol>
        <p>Last updated: 12 March 2019</p>
      </div>
    </div>
  )
}
