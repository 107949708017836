import React, { Component } from 'react'
import { Form, Select, Switch, Alert } from 'antd'
import _ from 'lodash'
import { transcribeKeys } from '../../../../../constants'
const { Option } = Select

export default class SwitchSettings extends Component {
  render() {
    const {
      t,
      current,
      App: {
        checkFinish,
        handleParam,
        handleChangeLocale,
        handleCheckChange,
        handleCheckTranscribe,
        handlePoll,
        handleCheckBreakRoomChange,
        onEnableAI,
        clearAlert
      },
      form: { getFieldDecorator },
      user,
      tag,
      is_ai_enabled,
      error_msg_ai
    } = this.props

    const isLiveAssessment = tag.value === 'live_assessment'

    const isZoom = tag.value === 'zoom_meeting'

    return (
      <div style={{ marginLeft: '14px' }}>
        {checkFinish(current.start_date_time, current.duration) && (
          <Form.Item
            label={t('labels:settings')}
            colon={false}
            required={false}
          >
            {!isZoom &&
              getFieldDecorator('is_chat', {
                valuePropName: 'checked',
                initialValue: isLiveAssessment ? false : current.is_chat
              })(
                <Switch
                  name="is_chat"
                  checkedChildren={t('general:chat')}
                  unCheckedChildren={t('general:chat')}
                  style={{ marginRight: '10px' }}
                  onChange={handleParam}
                  disabled={isLiveAssessment}
                />
              )}
            {tag.value === 'conference' &&
              user.info &&
              user.info.organization &&
              !isZoom &&
              getFieldDecorator('has_breakout_room', {
                valuePropName: 'checked',
                initialValue: current.has_breakout_room
              })(
                <Switch
                  name="has_breakout_room"
                  checkedChildren={'Breakout'}
                  unCheckedChildren={'Breakout'}
                  style={{ marginRight: '10px' }}
                  onChange={e => {
                    handleCheckBreakRoomChange(e)
                  }}
                />
              )}
            {getFieldDecorator('is_recording', {
              valuePropName: 'checked',
              initialValue:
                tag.value === 'live_assessment' ? true : current.is_recording
            })(
              <Switch
                name="is_recording"
                checkedChildren={t('v4:is_record')}
                unCheckedChildren={t('v4:is_record')}
                style={{ marginRight: '10px' }}
                onChange={e => {
                  handleCheckChange('is_recording', e)
                }}
                disabled={tag.value === 'live_assessment'}
              />
            )}
            {!isZoom && (
              <>
                {getFieldDecorator('is_poll', {
                  valuePropName: 'checked',
                  initialValue: isLiveAssessment ? false : current.is_poll
                })(
                  <Switch
                    name="is_poll"
                    checkedChildren={t('general:poll')}
                    unCheckedChildren={t('general:poll')}
                    onChange={handlePoll}
                    style={{ marginRight: '10px' }}
                    disabled={isLiveAssessment}
                  />
                )}
              </>
            )}
            {getFieldDecorator('is_transcribed', {
              valuePropName: 'checked',
              initialValue: current.is_transcribed
            })(
              <Switch
                name="is_transcribed"
                checkedChildren={t('v4:transcribe')}
                unCheckedChildren={t('v4:transcribe')}
                onChange={handleCheckTranscribe}
                disabled={isLiveAssessment ? false : !current.is_recording}
                style={{ marginRight: '10px' }}
              />
            )}

            {/* {current.is_transcribed && ( */}
            <Select
              showSearch
              style={{ width: 200, marginRight: '10px' }}
              value={current.transcribe_locale}
              placeholder={t('v2:transcribe_to')}
              optionFilterProp="children"
              onChange={e => handleChangeLocale(e)}
              filterOption={(input, option) =>
                !_.isArray(option.props.children) &&
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              disabled={!current.is_transcribed}
            >
              {transcribeKeys.map((item, index) => (
                <Option id={index} key={item.code} value={item.code}>
                  {item.name}
                </Option>
              ))}
            </Select>
            {/* )} */}
            {user.info &&
              user.info.organization &&
              user.info.organization.enable_ai &&
              getFieldDecorator('is_ai_enabled', {
                valuePropName: 'checked',
                initialValue: is_ai_enabled
              })(
                <Switch
                  name="is_ai_enabled"
                  checkedChildren={'AI Enable'}
                  unCheckedChildren={'AI Enable'}
                  onChange={e => {
                    onEnableAI(e)
                  }}
                  disabled={
                    isLiveAssessment
                      ? !current.is_transcribed
                      : !current.is_transcribed || !current.is_recording
                  }
                  style={{ marginRight: '10px' }}
                />
              )}

            {error_msg_ai && (
              <div className="date_time_error">
                <Alert
                  message={t('v2:error')}
                  description={error_msg_ai}
                  type="error"
                  closable
                  showIcon
                  banner
                  onClose={clearAlert}
                />
              </div>
            )}
          </Form.Item>
        )}
      </div>
    )
  }
}
