import { connect } from 'react-redux'

import actions from '../../../../store/actions'

import Users from './Users'

export default connect(
  state => ({
    organizations: state.organizations,
    organizationsAutoComplete: state.organizationsAutoComplete,
    user: state.user.info,
  }),
  dispatch => ({
    getUsersByOrganizationId: id => dispatch(actions.organizations.getUsersByOrganizationId(id)),
    getRoles: () => dispatch(actions.roles.getRoles()),
    getOrganizationsAutoComplete: (data) => dispatch(actions.organizations.getAutoComplete(data)),
    resetUsersByOrganizationId: () => dispatch(actions.organizations.resetUsersByOrganizationId()),
    deleteOrganizationUser: (orgId, id) => dispatch(actions.organizations.deleteOrganizationUser(orgId, id)),
    // searchOrgUser: (id, searchType, searchValue) => dispatch(actions.organizations.searchUsers(id, searchType, searchValue))
  })
)(Users)