import React from 'react'
import { Badge, Popover, Icon } from 'antd'
import { Notifications } from './styled'
import { withTranslation } from 'react-i18next'

const _Notifications = (props) => {
  const { count, t } = props

  return (
    <Notifications>
      <Popover content={
        <div style={{ padding: '6rem 8rem', textAlign: 'center' }}>
          <Icon type='frown' style={{ fontSize: '2.4rem' }}/>
          <div>{t('general:empty')}</div>
        </div>
      } trigger='click'>
        <Notifications.Button>
          <Notifications.Badge>
            <Badge count={count}/>
          </Notifications.Badge>
          <Notifications.Icon src={'/images/icons/menu/bell.svg'}/>
        </Notifications.Button>
      </Popover>
    </Notifications>
  )
}

export default withTranslation()(_Notifications)
