import TrainerSection from './TrainerSection'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import actions from '../../store/actions'

const mapStateToProps = state => ({
  user: state.user
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      upload: actions.library.upload,
      updateUser: actions.user.updateUser
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(TrainerSection)
