import styled, { css } from 'styled-components'
import Dropzone from 'react-dropzone'
import { Icon } from 'antd'

import { media } from '../../../../../styles'
import { styleguide } from '../../../../../constants'

const { colors, typography } = styleguide

export const DropzoneWrapper = styled(Dropzone)`
  && {
    position: relative;
    width: 100%;
    height: 55rem;
    border-width: .1rem;
    border-color: #979797;
    border-style: dashed;
    border-radius: 0;
    cursor: pointer;

    ${props =>
      props.cover &&
      css`
        background: no-repeat url(${props.cover}) center;
        background-size: cover;
      `}

    ${props =>
      props.error &&
      css`
        border-color: ${colors.red};
      `}

    ${media.md`
      height: 20rem;
    `}

    ${props =>
      props.mode &&
      props.mode === 'builder' &&
      `
      border-color: #979797 !important;
      width: 100% !important;
    `}

    ${props =>
      props.disabled &&
      css`
        opacity: 0.5;
        pointer-events: none;
      `}
  }
`

export const DropzoneLoading = styled(Icon)`
  && {
    font-size: 3.6rem;
    color: ${colors.blue};
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -1.8rem;
    margin-top: -1.8rem;
  }
`

export const DropzoneInner = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 14rem;
  height: 15rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`

export const DropzoneText = styled.span`
  color: ${colors.darkGreyOne};
  font-weight: ${typography.h1FontWeight};
  font-size: 1.1rem;
  margin-left: 1rem;
`
