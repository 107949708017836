import {
  ADD_NOTE, ADD_NOTE_SUCCESS, ADD_NOTE_FAIL,
  CLEAR_NOTES,
  FETCH_NOTES,
  FETCH_NOTES_SUCCESS,
  FETCH_NOTES_FAIL,
  EDIT_NOTE, EDIT_NOTE_SUCCESS,
  EDIT_NOTE_FAIL,
  DELETE_NOTE,
  DELETE_NOTE_FAIL, DELETE_NOTE_SUCCESS,
  EDITING_NOTES, DONE_EDITING_NOTES
} from '../types'
import { api } from '../../services'

export const fetchNotes = params => async dispatch => {
  dispatch({
    type: FETCH_NOTES
  })
  try {
    const { data } = await api.notes.getByEvent(params.id, params.type)
    dispatch({
      type: FETCH_NOTES_SUCCESS,
      payload: data
    })
  } catch (error) {
    dispatch({
      type: FETCH_NOTES_FAIL,
      payload: error
    })
  }
}

export const clearNotes = () => ({
  type: CLEAR_NOTES
})

export const editingNotes = () => ({
  type: EDITING_NOTES
})

export const stopEditingNotes = () => ({
  type: DONE_EDITING_NOTES
})

export const addNote = params => async dispatch => {
  dispatch({
    type: ADD_NOTE,
    payload: true
  })

  try {
    const { data } = await api.notes.add(params.id, params.type, {
      content: params.body,
      parentId: params.parentId
    })
    dispatch({
      type: ADD_NOTE_SUCCESS,
      payload: data
    })
  } catch (error) {
    dispatch({
      type: ADD_NOTE_FAIL,
      payload: error
    })
  }
}

export const deleteNote = noteId => async dispatch => {
  dispatch({
    type: DELETE_NOTE,
    payload: true
  })

  try {
    const response = await api.notes.deleteById(noteId)
    if (response) {
      dispatch({
        type: DELETE_NOTE_SUCCESS,
        payload: noteId
      })
    }
  } catch (error) {
    dispatch({
      type: DELETE_NOTE_FAIL,
      payload: false
    })
  }
}

export const editNote = data => async dispatch => {
  dispatch({
    type: EDIT_NOTE,
    payload: true
  })

  try {
    const response = await api.notes.editById(data.noteId, { content: data.content })
    if (response) {
      dispatch({
        type: EDIT_NOTE_SUCCESS,
        payload: data
      })
    }
  } catch (error) {
    dispatch({
      type: EDIT_NOTE_FAIL,
      payload: error
    })
  }
}
