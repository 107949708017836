import React, { Fragment } from 'react'
import ViewContentFooter from './View-content-footer'
import {
  CourseSection,
  HeaderSection,
  CourseOwnerSection
} from './internal/SubComponent'

import './styles.scss'

const ViewContent = ({ App, isOwner }) => {
  const {
    course: {
      item: { title, organiser },
      item
    },
    t
  } = App.props

  const topics = (item.topic_list || []).map(topic => topic.title).join(', ')

  return (
    <Fragment>
      <HeaderSection title={title} topics={topics} />

      {organiser && <CourseOwnerSection organiser={organiser} t={t} />}

      <CourseSection App={App} t={t} isOwner={isOwner} />

      <ViewContentFooter App={App} isOwner={isOwner} />
    </Fragment>
  )
}

export default ViewContent
