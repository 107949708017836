import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import './ViewChannelDisplay.scss'

function ViewChannelDisplay({ description, previewFile, isVideo = false }) {
  const fileLink = useMemo(
    () =>
      previewFile && previewFile.type === 'youtube'
        ? `https://www.youtube.com/embed/${previewFile.id}`
        : previewFile && previewFile.link,
    [previewFile]
  )
  const { t } = useTranslation()

  return (
    <div className="view-channel-display">
      {isVideo ? (
        <iframe
          title="iframe title"
          className="view-channel-display-file"
          allowfullscreen="true"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          src={fileLink + '?autoplay=1'}
          allow="autoplay"
        />
      ) : (
        <img
          className="view-channel-display-file"
          src={fileLink}
          alt="Preview File"
        />
      )}
      {description && (
        <>
          <div className="ssl-channel-description">{t('v3:video_des')}</div>
          <div className="view-channel-descption">
            <p>{description}</p>
          </div>
          <div style={{ height: '30px' }}></div>
        </>
      )}
    </div>
  )
}

export default ViewChannelDisplay
