import React, { Component } from 'react'
import { Button } from 'antd'
import { withTranslation } from 'react-i18next'

// let timer = null

class SaveLesson extends Component {
  // componentDidUpdate (prevProps) {
  //   const { fetch } = this.props.lesson
  //   // Why prevProps and this.props always equal???
  //   if (JSON.stringify(prevProps.lesson.pages) !== JSON.stringify(this.props.lesson.pages)) {
  //     console.log(prevProps.lesson.pages)
  //     !fetch && this.addLesson()
  //   } else {
  //     console.log('equal')
  //   }
  // }

  // componentDidMount () {
  //   const { fetch } = this.props.lesson

  //   timer = setInterval(() => {
  //     !fetch && this.addLesson()
  //   }, 10000)
  // }

  // componentWillUnmount () {
  //   clearInterval(timer)
  // }

  addLesson = () => {
    const { lesson, courseId, lessonBuilderActions } = this.props

    console.log('saving lesson', lesson)

    const data = {
      title: lesson.lessonTitle,
      description: lesson.lessonDescription,
      cover_id: lesson.lessonImgId,
      topics: lesson.lessonTags || [],
      pages: lesson.pages,
      pages_landscape: lesson.pages_landscape
    }
    if (lesson.lessonId) {
      lessonBuilderActions.updateLesson(data, lesson.lessonId)
    } else {
      lessonBuilderActions.createLesson(data, courseId)
    }
  }

  render () {
    const { t } = this.props
    const { lessonId, saving } = this.props.lesson
    // const { enabled } = this.props

    return (
      <Button className='rounded'
        onClick={this.addLesson}
        type='primary'
        loading={saving}
        // disabled={!enabled || false}
      >
        {(lessonId ? t('buttons:update') : t('buttons:add')) + ` ${t('general:lesson')}`}
      </Button>
    )
  }
}

export default withTranslation()(SaveLesson)
