import React from 'react'

import { ResultQuestion } from './styled'
import { withTranslation } from 'react-i18next'

const EResultQuestion = (props) => {
  const { title, children, t } = props

  return (
    <ResultQuestion>
      <ResultQuestion.Title>{title}</ResultQuestion.Title>

      <ResultQuestion.Answer>
        {t('quizview_answer')}:
        {children}
      </ResultQuestion.Answer>
    </ResultQuestion>
  )
}

export default withTranslation('coursepage')(EResultQuestion)
