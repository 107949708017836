import styled from 'styled-components'
import ReactSVG from 'react-svg'

import { media } from '../../../../../styles'
import { styleguide } from '../../../../../constants'

const { colors } = styleguide

const ChoiceWrapper = styled.section`
  margin-top: 8rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  ${media.md`
    margin-top: 4rem;
    flex-direction: column;
  `}
`

const Choice = styled.div`
  border: .1rem solid ${colors.black};
  color: ${colors.black};
  width: 100%;
  max-width: 35rem;
  text-align: center;
  cursor: pointer;
  height: 280px;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;

  &:hover {
    border-color: ${colors.blue};
    color: ${colors.blue};

    svg {
      path {
        fill: ${colors.blue};
      }
    }
  }
`

Choice.Divider = styled.div`
  margin: 0 4rem;
  font-weight: 600;
`

Choice.Title = styled.div`
  font-size: 1.6rem;
  margin-top: 2rem;
  text-transform: uppercase;
`

Choice.SubTitle = styled.div`
  font-size: 1.4rem;
  padding: 2rem;
`

Choice.IconChoise = styled(ReactSVG)`
  svg {
    height: 5rem;

    path {
      fill: ${colors.darkGreyOne};
    }
  }
`
// const Main = styled.div`
//   .title{
//     font-style: normal;
//     font-weight: 300;
//     font-size: 18px;
//   }
//   .subtitle{
//     font-style: normal;
//     font-weight: 300;
//     font-size: 14px;
//   }
// `

export {
  ChoiceWrapper,
  Choice
}
