import React, { useMemo, useState } from 'react'
import ReactEcharts from 'echarts-for-react'
import _ from 'lodash'
import { report } from '../../../../constants'
import './styles.scss'
import { Button, Modal, Tag } from 'antd'
import { useTranslation } from 'react-i18next'

const colors = [
  '#FFB37C',
  '#A4EFA7',
  '#8DC3F8',
  '#246A9C',
  '#B95EF0',
  '#E1D917',
  '#FAC858'
]

const black = { color: 'black' }

const modalTypes = {
  badge: 'Badge',
  certificate: 'Certificate'
}

function OverviewGraph(props) {
  const {
    monthXAxis,
    eventsData,
    usersData,
    courseData,
    liveAssessmentData,
    mazesData,
    quizesData,
    skillJourneysData,
    isUserReport,
    badges = [],
    certificates = [],
    enableMaze,
    triviaData
  } = props
  const [showModalType, setShowModalType] = useState(null)

  const isBadgeActive = useMemo(() => showModalType === modalTypes.badge, [
    showModalType
  ])
  const isCertificateActive = useMemo(
    () => showModalType === modalTypes.certificate,
    [showModalType]
  )

  const emptyData = monthXAxis.map(item => (item = 0))
  const purpleData = isUserReport ? report.QUIZ : report.USERS
  const purpleDataList = isUserReport ? quizesData : usersData

  const filteredBadges = useMemo(() => {
    return badges.filter(item => item.badge !== null)
  }, [badges])

  const downloadReward = item => {
    const itemUrl = isBadgeActive
      ? item.link
      : item.custom_data && item.custom_data.pdf_link
    const itemName = `${showModalType} - ${item.title}`
    const link = document.createElement('a')
    link.href = itemUrl
    if (isCertificateActive) {
      link.target = '__blank'
    }
    link.download = itemName
    link.click()
  }
  const { t } = useTranslation()
  return (
    <div className="overview-graph">
      <ReactEcharts
        option={{
          tooltip: {},
          legend: {
            icon: 'circle',
            x: 'left',
            left: '20px',
            data: [
              report.COURSE,
              report.EVENT,
              report.LIVE_ASSESSMENT,
              isUserReport ? purpleData : null,
              enableMaze && report.MAZE,
              report.SKILL_JOURNEY,
              report.TRIVIA
            ]
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: {
            type: 'category',
            data: monthXAxis
          },
          yAxis: {
            type: 'value'
          },
          color: colors,
          series: [
            {
              name: report.COURSE,
              data: _.isEmpty(courseData) ? emptyData : courseData,
              type: 'line',
              smooth: 'true'
            },
            {
              name: report.EVENT,
              data: _.isEmpty(eventsData) ? emptyData : eventsData,
              type: 'line',
              smooth: 'true'
            },
            {
              name: report.LIVE_ASSESSMENT,
              data: _.isEmpty(liveAssessmentData)
                ? emptyData
                : liveAssessmentData,
              type: 'line',
              smooth: 'true'
            },
            {
              name: purpleData,
              data: _.isEmpty(purpleDataList) ? emptyData : purpleDataList,
              type: 'line',
              smooth: 'true'
            },
            {
              name: enableMaze && report.MAZE,
              data:
                enableMaze && (_.isEmpty(mazesData) ? emptyData : mazesData),
              type: 'line',
              smooth: 'true'
            },
            {
              name: report.SKILL_JOURNEY,
              data: _.isEmpty(skillJourneysData)
                ? emptyData
                : skillJourneysData,
              type: 'line',
              smooth: 'true'
            },
            {
              name: report.TRIVIA,
              data: _.isEmpty(triviaData) ? emptyData : triviaData,
              type: 'line',
              smooth: 'true'
            }
          ]
        }}
      />
      {isUserReport && (
        <div className="badge-certificate-menus">
          <div
            className="menu-item"
            style={{
              background: '#ffb37c'
            }}
            onClick={() =>
              filteredBadges.length > 0
                ? setShowModalType(modalTypes.badge)
                : null
            }
          >
            <span style={black}>{filteredBadges.length}</span>
            <h3 style={black}>{t('v2:badges')}</h3>
          </div>
          <div
            className="menu-item"
            style={{
              background: '#1294F2'
            }}
            onClick={() =>
              certificates.length > 0
                ? setShowModalType(modalTypes.certificate)
                : null
            }
          >
            <span>{certificates.length}</span>
            <h3>{t('v2:certificates')}</h3>
          </div>
        </div>
      )}

      <Modal
        width="1229px"
        title={
          isBadgeActive
            ? t('v2:badges')
            : isCertificateActive
            ? t('v2:certificates')
            : t('v4:closing')
        }
        visible={showModalType}
        onCancel={() => setShowModalType(null)}
        footer={false}
      >
        {(() => {
          const items = isBadgeActive
            ? filteredBadges
            : isCertificateActive
            ? certificates
            : []

          return (
            <div className="report-reward-container">
              {items &&
                items.map(item => {
                  const imageItem = isBadgeActive
                    ? item.badge && item.badge
                    : item.certificate && item.certificate
                  return (
                    <div className="report-reward-item">
                      <img
                        src={imageItem && imageItem.link}
                        alt="user-report-reward-img"
                      />

                      <div className="report-reward-item-info">
                        <div className="about">
                          <h3>{imageItem && imageItem.title}</h3>
                          <p>{imageItem && imageItem.description} </p>
                        </div>
                        <div className="action">
                          {isCertificateActive ? (
                            <Button
                              type="primary"
                              onClick={() => downloadReward(imageItem)}
                            >
                              {t('buttons:download')} {showModalType}
                            </Button>
                          ) : (
                            <>
                              {t('general:course')} :{' '}
                              <Tag> {item.course_title} </Tag>{' '}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  )
                })}
            </div>
          )
        })()}
      </Modal>
    </div>
  )
}

export default OverviewGraph
