import styled from 'styled-components'

import { Menu } from 'antd'

import { styleguide } from '../../constants'

const { colors } = styleguide

const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  // margin-bottom: 2rem;
  width: 100%;
  padding: 1rem 0rem;

  ${props =>
    props.bordered &&
    `
    border: .1rem solid #ddd;
    padding: 1.5rem 2rem;
  `}

  ${props =>
    props.noIndent &&
    `
    margin-bottom: 0;
  `}
`

FilterContainer.Wrapper = styled.div`
  display: flex;
  align-items: center;
`

FilterContainer.Label = styled.span`
  display: inline-block;
  font-size: 1.1rem;
  margin-right: 1.2rem;
  text-transform: uppercase;
`

FilterContainer.Menu = styled(Menu)`
  && {
    min-width: 18.2rem;
    border: 0.1rem solid ${colors.darkGreyThree};
    border-radius: 0;
    box-shadow: none;
    padding: 0;
    top: 1.5rem;
    right: -2.1rem;
  }
`

FilterContainer.Item = styled(Menu.Item)`
  && {
    padding: 0;

    &:not(:last-child) {
      border-bottom: 0.1rem solid ${colors.darkGreyThree};
    }
  }
`

FilterContainer.Link = styled.a`
  && {
    width: 100%;
    font-weight: 600;
    display: inline-block;
    padding: 1.1rem 1.3rem;
    margin: 0;

    &:hover {
      background-color: ${colors.darkGreyThree};
    }
  }
`

FilterContainer.Selected = styled.span`
  min-width: 12.2rem;
  display: block;
  color: ${colors.textDark};
  font-weight: 600;
`

export { FilterContainer }
