import React, { Component, Fragment } from 'react'
import moment from 'moment'
import _ from 'lodash'
import { Page } from './styled'
import { Field } from '../../../styles'
import {
  DatePicker,
  PrivacySelector,
  OrganizationPicker,
  GroupPicker
} from './components'
import {
  Input,
  Checkbox,
  Button,
  Spin,
  message,
  Tabs,
  Alert,
  Modal,
  Switch,
  Select
} from 'antd'
import history from '../../../history'
import { withTranslation, Trans } from 'react-i18next'
import { api, i18n } from '../../../services'
import './styles.scss'
import { general, transcribeKeys } from '../../../constants'
import ReactSVG from 'react-svg'
import debounce from 'lodash/debounce'

const { Option } = Select

class Settings extends Component {
  constructor(props) {
    super(props)

    this.lastFetchId = 0

    this.state = {
      selectedType: 0,
      eventTime: null,
      eventDuration: null,
      selectedOrganizations: [],
      selectedGroups: [],
      emails: [],
      checkedTerms: false,
      isFetching: false,
      error: null,
      error_message: '',
      isCheckModal: false,
      isNotExist: [],
      isNotInOrg: [],
      current: {
        id: null,
        title: '',
        email: '',
        description: '',
        topics: [],
        topic_list: [],
        is_chat: true,
        is_recording: false,
        is_poll: true,
        poll: {
          question: '',
          answers: ['']
        },
        encoder: '',
        host_emails: [],
        host_ids: [],
        attachments: [],
        is_transcribed: false,
        transcribe_locale: ''
      },
      isDisable: true,
      isPublish: false,
      isOverLimit: false,
      errorData: {
        title: '',
        subTitle: ''
      },
      isAdminOnly: false,
      isSendMail: false,
      isSpecific: false,
      is_host_only: false,
      emailList: [],
      selectedValue: '',
      fetching: true,
      toTranscribe: false,
      transcribeLocale: '',
      isMarketPlaceEnabled: false
    }

    this.handleInsertUsers = _.debounce(this.handleInsertUsers, 1000)
    this.fetchUserEmails = debounce(this.fetchUserEmails, 1000)
  }

  componentDidMount() {
    const { get } = this.props
    const id = this.props.match.params.id || null

    if (get) {
      get()
    }

    this.setState({
      isFetching: true
    })

    api.events.getById(id).then(({ data }) => {
      this.setState(
        {
          current: data,
          eventTime: data.start_date_time ? moment(data.start_date_time) : null,
          eventDuration: data.duration || 20,
          isFetching: false,
          toTranscribe: data.is_transcribed,
          transcribeLocale: data.transcribe_locale
        },
        () => {
          this.getOldSetting(data)
          this.checkEnableMarketPlace()
        }
      )
    })
  }

  getOldSetting = courseById => {
    const { setting } = courseById
    const {
      current: { encoder }
    } = this.state
    if (setting) {
      const {
        group_ids,
        publish_option,
        organization_ids,
        emails,
        is_admin_only: isAdminOnly,
        is_host_only
      } = setting
      if (publish_option === general.ORGANIZATION) {
        this.handleSelectedType(2)
      } else if (publish_option === general.ORGANIZATION_GROUP) {
        this.handleSelectedType(3)
      } else if (publish_option === general.SPECIFIC_USER) {
        if (encoder !== 'peer2peer') {
          this.handleSelectedType(4)
        } else {
          this.handleSpecificType()
        }
      } else if (publish_option === general.PUBLIC) {
        this.handleSelectedType(1)
      }
      this.handleInsertUsers(_.uniq(emails).join('\n'))
      this.setState({
        selectedOrganizations: organization_ids,
        selectedGroups: group_ids,
        emails,
        selectedValue: emails,
        isAdminOnly,
        is_host_only: is_host_only
      })
    }
  }

  checkFinish = (startDataTime, duration) => {
    const isAllowPermission = this.getUserRole()
    const {
      current: { start_date_time, setting_id }
    } = this.state
    if (isAllowPermission) {
      if (startDataTime) {
        const newTime = moment(startDataTime).format('LLLL')
        const diffMinutes = moment().diff(newTime, 'minutes')
        const newDuration = duration || 10
        const checkInterval = diffMinutes - newDuration
        const scheduledTime = moment(start_date_time).format('LLLL')
        const isPastEvent = moment().diff(scheduledTime, 'minutes') > 0
        if (!setting_id) {
          return true
        } else {
          if (isPastEvent) {
            return checkInterval <= 0
          } else {
            return true
          }
        }
      }
      return true
    } else {
      return false
    }
  }
  getUserRole = () => {
    const {
      user: {
        info: { isAdmin, isSuperAdmin }
      }
    } = this.props
    const { is_finished: isFinished } = this.state.current
    if (!isFinished) {
      return isAdmin || isSuperAdmin
    } else {
      return !isFinished
    }
  }

  getLastOption = () => {
    const { selectedType } = this.state
    if (selectedType === 1) {
      return general.PUBLIC
    } else if (selectedType === 2) {
      return general.ORGANIZATION
    } else if (selectedType === 3) {
      return general.ORGANIZATION_GROUP
    } else if (selectedType === 4) {
      return general.SPECIFIC_USER
    } else {
      return general.MARKETPLACE
    }
  }

  handlePublish = () => {
    const { t } = this.props
    const {
      selectedType,
      selectedOrganizations,
      selectedGroups,
      emails,
      eventTime,
      eventDuration,
      current,
      current: { marketplace },
      isAdminOnly: is_admin_only,
      isSendMail,
      is_host_only,
      toTranscribe,
      transcribeLocale
    } = this.state
    const nowPlus10 = moment().add(10, 'minutes')
    const nowPlus1 = moment().add(1, 'minutes')
    const removedSpaceEmail = _.map(emails, email => email.trim().toLowerCase())
    // we need plus 10 minutes after current time to make sure wowza is completly ready to live
    if (this.checkFinish(eventTime, eventDuration)) {
      if (
        current.encoder !== 'chime_conference' ||
        (current.encoder === 'peer2peer' && current.is_recording)
      ) {
        if (eventTime < nowPlus10) {
          this.handleTimeError('date_time')
          return
        }
      }

      if (
        (current.encoder === 'peer2peer' && !current.is_recording) ||
        current.encoder === 'chime_conference'
      ) {
        if (eventTime < nowPlus1) {
          this.handleTimeError('date_time_peer')
          return
        }
      }

      /*if (current.encoder === 'peer2peer') {
        if(!current.is_recording && eventTime < nowPlus1){
          this.handleTimeError('date_time_peer')
        } else {
          this.handleTimeError('date_time')
        }
        return
      }*/

      if (
        current.encoder !== 'peer2peer' &&
        selectedType === 2 &&
        _.isEmpty(selectedOrganizations)
      ) {
        message.error(i18n.t('errors:no_org'))
        return
      }

      if (
        current.encoder !== 'peer2peer' &&
        selectedType === 3 &&
        _.isEmpty(selectedGroups)
      ) {
        message.error(i18n.t('errors:no_org_group'))
        return
      }

      if (!eventDuration) {
        this.handleTimeError('duration')
        return
      }
      if (!eventTime) {
        message.error(i18n.t('errors:start_date'))
        return
      }

      if (!eventDuration) {
        message.error(i18n.t('errors:duration'))
        return
      }
      if (toTranscribe && !transcribeLocale) {
        message.error(i18n.t('v4:select_transcribe_locale'))
        return
      }
    }

    if (_.isEmpty(emails) && current.encoder === 'peer2peer') {
      message.error(t('emails_validation_error'))
      return
    }

    let privacy = {
      is_public: selectedType === 1,
      is_admin_only: selectedType === 1 ? is_admin_only : false,
      organization_ids: selectedOrganizations,
      group_ids: selectedGroups,
      emails: _.uniq([...removedSpaceEmail]),
      publish_option: this.getLastOption(),
      is_host_only: is_host_only,
      is_marketplace: selectedType === 5
    }

    const data = {
      title: current.title,
      description: current.description,
      topics: current.topics,
      cover_id: current.cover_id,
      is_chat: current.is_chat,
      is_poll: current.is_poll,
      is_recording: current.is_recording,
      start_date_time: eventTime,
      duration: Number(eventDuration),
      no_of_people_conf: Number(current.no_of_people_conf),
      isSendMail,
      privacy,
      marketplace: {
        category_id: marketplace.category_id,
        price: marketplace.price,
        is_free: marketplace.is_free,
        video_link: marketplace.video_link
      },
      isSendMailToHost: true,
      encoder_type: current.encoder,
      host_emails: current.host_emails,
      host_ids: current.host_ids,
      attachments: current.attachments,
      is_transcribed: toTranscribe,
      transcribe_locale: transcribeLocale
    }

    console.log('data log: ', data)

    if (!this.checkFinish(eventTime, eventDuration)) {
      delete data.start_date_time
      delete data.duration
    }

    const eventId = this.props.match.params.id || current.id || null

    this.checkedPublishButtonDisabling()

    api.events
      .updateById(eventId, data)
      .then(() => {
        history.push('/workshop')
      })
      .catch(({ response }) => {
        const {
          status,
          data: { errorMessage }
        } = response
        if (status === 422) {
          const message = errorMessage.split(':')
          const splitMessage = _.last(message).split('.')
          this.setState({
            isDisable: true,
            isPublish: false,
            errorData: {
              title: splitMessage[0],
              subTitle: splitMessage[1]
            },
            isOverLimit: true
          })
        }
      })
  }

  getDurationValue = value => {
    if (value <= 0) return null
    if (value > 300) return 300
    return value
  }

  handleSelectedDate = (field, value) => {
    this.setState({
      [field]: field === 'eventDuration' ? this.getDurationValue(value) : value
    })
  }

  handleSelectedType = id => {
    this.setState({
      selectedType: id
    })
  }

  handleSelectedOrganizations = id => {
    const { selectedOrganizations } = this.state

    if (selectedOrganizations.includes(id)) {
      this.setState({
        selectedOrganizations: selectedOrganizations.filter(
          organizationId => organizationId !== id
        )
      })
    } else {
      this.setState({
        selectedOrganizations: [...selectedOrganizations, id]
      })
    }
  }

  handleSelectedGroups = (groupId, orgId) => {
    const { selectedGroups } = this.state
    this.setState({
      selectedGroups: selectedGroups.includes(groupId)
        ? selectedGroups.filter(i => i !== groupId)
        : selectedGroups.concat([groupId]),
      selectedOrganizations: [orgId]
    })
  }

  handleSelectedAllOrganizations = id => {
    const { list } = this.props
    const { selectedGroups } = this.state

    const { groups = [] } = list.find(i => i.id === id)
    const groupIds = groups.map(i => i.id)

    const selectedOrgGroups = groupIds.filter(i => selectedGroups.includes(i))

    if (groupIds.length === selectedOrgGroups.length) {
      this.setState({
        selectedGroups: selectedGroups.filter(
          i => !selectedOrgGroups.includes(i)
        ),
        selectedOrganizations: [id]
      })
    } else {
      this.setState({
        selectedGroups: selectedGroups
          .filter(i => !groupIds.includes(i))
          .concat(groupIds),
        selectedOrganizations: [id]
      })
    }
  }

  handleChangeTerms = () => {
    const { isPublish } = this.state
    if (!isPublish) {
      this.setState(state => ({
        checkedTerms: !state.checkedTerms,
        isDisable: !state.isDisable
      }))
    }
  }

  handleChangePrivacy = value => {
    const {
      user: {
        info: { active_organization_id }
      }
    } = this.props
    this.setState({ is_host_only: value }, () => {
      this.setState(state => {
        if (state.is_host_only) {
          return {
            selectedOrganizations: [active_organization_id],
            selectedGroups: []
          }
        } else {
          return { selectedOrganizations: [] }
        }
      })
    })
  }

  checkEnableMarketPlace = () => {
    const {
      current: {
        marketplace: { category_id, is_free, price, video_link }
      },
      current
    } = this.state

    console.log('current', current)

    let data = {
      title: current.title,
      category_id: category_id,
      is_free: is_free,
      location: current.broadcast_location,
      price: price,
      encoder: current.encoder,
      video_link: video_link
    }
    let errorData = Object.keys(data).filter(k => {
      if (data[k] === '' || data[k] === undefined || data[k] === null) {
        return true
      } else {
        return false
      }
    })

    console.log(errorData)

    this.setState({
      isMarketPlaceEnabled: errorData.length ? false : true
    })
  }

  handleCheckTranscribe = value => {
    this.setState(
      () => {
        if (!value) {
          this.clearAlert()
          return {
            toTranscribe: value,
            transcribeLocale: ''
          }
        }
        return { toTranscribe: value }
      },
      () => {
        const {
          current,
          isSendMail,
          toTranscribe,
          transcribeLocale,
          eventTime,
          eventDuration
        } = this.state
        if (this.checkFinish(eventTime, eventDuration)) {
          if (!eventTime) {
            message.error(i18n.t('errors:start_date'))
            return
          }

          if (!eventDuration) {
            message.error(i18n.t('errors:duration'))
            return
          }
        }
        const data = {
          title: current.title,
          description: current.description,
          topics: current.topics,
          cover_id: current.cover_id,
          is_chat: current.is_chat,
          is_poll: current.is_poll,
          start_date_time: eventTime,
          duration: Number(eventDuration),
          isSendMail,
          isSendMailToHost: true,
          encoder_type: current.encoder,
          host_emails: current.host_emails,
          host_ids: current.host_ids,
          attachments: current.attachments,
          is_transcribed: toTranscribe,
          transcribe_locale: transcribeLocale
        }
        const eventId = this.props.match.params.id || current.id || null
        api.events
          .updateById(eventId, data)
          .then(() => {})
          .catch(({ response }) => {
            const {
              status,
              data: { errorMessage }
            } = response
            if (status === 422) {
              const message = errorMessage.split(':')
              const splitMessage = _.last(message).split('.')
              this.setState({
                isDisable: true,
                isPublish: false,
                errorData: {
                  title: splitMessage[0],
                  subTitle: splitMessage[1]
                },
                isOverLimit: true
              })
            }
          })
      }
    )
  }

  checkedPublishButtonDisabling = () => {
    this.setState({
      isDisable: true,
      isPublish: true
    })
  }

  handleInsertUsers = value => {
    const {
      state: {
        current: { encoder }
      },
      props: { t }
    } = this
    const emails = value.replace(/(\r\n|\n|\r)/gm, ',').split(',')
    const check = _.map(_.compact(emails), email =>
      /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email.trim())
    )
    const isSetValid = _.filter(check, item => item)

    if (isSetValid.length === check.length) {
      if (encoder === 'peer2peer' && emails.length > 1) {
        message.error(t('specific_email_validation_error'))
      } else {
        this.setState({
          emails: value
            .trim()
            .split('\n')
            .filter(i => !!i)
        })
      }
    } else {
      this.setState({
        emails: value
          .trim()
          .split('\n')
          .filter(i => !!i)
      })
    }
  }

  disabledDate = current => current && current < moment().startOf('day')

  handleTimeError = type => {
    this.setState(
      {
        error: true,
        error_message:
          type === 'date_time'
            ? 'date_time_error'
            : 'date_time_peer'
            ? 'date_time_error_peer'
            : 'duration_error'
      },
      () => {
        setTimeout(() => {
          if (this.state.error) {
            this.clearAlert()
          }
        }, 10000)
      }
    )
  }

  clearAlert = () => {
    this.setState({
      error: null,
      error_message: null,
      isOverLimit: false
      // isDisable: false
    })
  }

  onSendEmail = () => {
    this.setState(state => ({
      isSendMail: !state.isSendMail
    }))
  }

  /* checkPersonalSpace = (activeOrganization, isNotExist, isNotInOrg) => {
    if (activeOrganization) {
      if (_.isEmpty(isNotExist) && _.isEmpty(isNotInOrg)) {
        this.handlePublish()
      } else {
        this.setState({
          isCheckModal: true,
          isNotExist,
          isNotInOrg,
          isLoading: false
        })
      }
    } else {
      if (_.isEmpty(isNotExist)) {
        this.handlePublish()
      } else {
        this.setState({
          isCheckModal: true,
          isNotExist,
          isNotInOrg: [],
          isLoading: false
        })
      }
    }
  } */

  handleSendEmail = () => {
    this.setState(
      {
        isCheckModal: false,
        isNotExist: [],
        isNotInOrg: []
      },
      () => {
        this.handlePublish()
      }
    )
  }

  closeCheckModal = () => {
    const inputHost = document.getElementById('emails')
    if (inputHost) {
      inputHost.focus()
    }
    this.setState({
      isCheckModal: false
    })
  }

  renderError = (t, errorData) => {
    return (
      <div>
        <div>{errorData.title}.</div>
        {errorData.subTitle && <div>{errorData.subTitle}.</div>}
        <Trans i18nKey="labels:contact_admin">
          <span style={{ justifyContent: 'center' }}>
            If you wish to change your monthly live-streaming limit please
            contact
          </span>
          <a
            style={{ justifyContent: 'center' }}
            href="mailto:admin@sharelook.com"
          >
            admin@sharelook.com
          </a>
        </Trans>
      </div>
    )
  }
  onAdminOnly = () => {
    this.setState(state => ({
      isAdminOnly: !state.isAdminOnly
    }))
  }

  handleSpecificType = () => {
    console.log('i m called')
    this.setState({ isSpecific: true })
  }

  fetchUserEmails = value => {
    const {
      user: {
        info: { active_organization_id }
      }
    } = this.props
    console.log('fetching user', value)
    this.lastFetchId += 1
    const fetchId = this.lastFetchId
    this.setState({ emailList: [], fetching: true })
    api.organizations
      .getUsersByOrganizationId(active_organization_id)
      .then(res => {
        console.log('response org users: ', res.data)
        if (fetchId !== this.lastFetchId) {
          // for fetch callback order
          return null
        }
        res.data.forEach(val => {
          if (val.user) {
            console.log('user check', val.user.email)
            const dataValue = {
              text: val.user.email,
              value: val.user.email
            }
            this.setState(state => {
              if (dataValue.value.includes(value)) {
                const emailList = [...state.emailList, dataValue]
                return {
                  emailList,
                  fetching: false
                }
              }
            })
          }
        })
      })
  }

  handleChange = selectedValue => {
    console.log('selectedValue check', selectedValue)
    this.setState({
      selectedValue,
      emails: [selectedValue.key],
      emailList: [],
      fetching: false
    })
  }

  handleChangeLocale = value => {
    console.log('locale', value)
    this.setState({
      transcribeLocale: value
    })
  }

  render() {
    const {
      list,
      isFetching,
      events: { isSaving },
      user: {
        info: { isAdmin, isSuperAdmin }
      },
      t,
      match: {
        params: { id }
      }
    } = this.props
    const {
      selectedType,
      selectedOrganizations,
      selectedGroups,
      eventTime,
      eventDuration,
      checkedTerms,
      isFetching: isEventFetching,
      emails,
      error,
      error_message,
      isDisable,
      isCheckModal,
      isNotExist,
      isNotInOrg,
      isOverLimit,
      errorData,
      isAdminOnly,
      isSendMail,
      current: { encoder, is_recording },
      isSpecific,
      is_host_only,
      selectedValue,
      fetching,
      emailList,
      toTranscribe,
      transcribeLocale
    } = this.state

    console.log('isDisable: ', isDisable)
    console.log('transcribeLocale render: ', transcribeLocale)

    const rootPath = `/events/${id}/edit`
    const privacyTypes = [
      isSuperAdmin && {
        id: 1,
        name: 'Public',
        description: 'PublicDescription'
      },
      (isSuperAdmin || isAdmin) && {
        id: 2,
        name: t('v4:organisations'),
        description: 'OrganisationsDescription'
      },
      (isSuperAdmin || isAdmin) && {
        id: 3,
        name: t('v4:org_groups'),
        description: 'OrganisationGroupsDescription',
        disabled: is_host_only
      },
      {
        id: 4,
        name: t('v4:specific_user'),
        description: 'SpecificUserDescription',
        disabled: encoder !== 'peer2peer' ? true : false
      },
      {
        id: 5,
        name: t('labels:Marketplace'),
        description: 'MarketPlaceDescription',
        disabled: !this.state.isMarketPlaceEnabled
      }
    ].filter(i => !!i)

    const specificTypes = [
      {
        id: 1,
        name: t('v4:specific_user'),
        description: 'SpecificUserDescription'
      }
    ]

    const filteredList =
      !_.isEmpty(list) &&
      list.filter(i => isSuperAdmin || (i.role && i.role.name === 'Admin'))

    return (
      <Fragment>
        <div className="p-tabs">
          <Tabs activeKey={'settings'}>
            <Tabs.TabPane tab={t('tabs:details')} key="details" disabled />
            <Tabs.TabPane tab={t('tabs:settings')} key="settings" disabled />
          </Tabs>
        </div>

        <section className="section">
          <div className="head head_nopadding">
            <div className="head__title">{t('title')}</div>
            <div className="head__subtitle">{t('event_subtitle')}</div>
          </div>
        </section>

        <section className="section section_divider">
          <Spin spinning={isFetching || isEventFetching}>
            <Page style={{ maxWidth: '120rem' }}>
              <Page.Field>
                <DatePicker
                  onChange={this.handleSelectedDate}
                  eventTime={eventTime}
                  eventDuration={eventDuration}
                  disabledDate={this.disabledDate}
                  isShowNoted
                  disabled={!this.checkFinish(eventTime, eventDuration)}
                  t={t}
                />
              </Page.Field>
              {error && (
                <div className="date_time_error">
                  <Alert
                    message="Error"
                    description={general[error_message]}
                    type="error"
                    closable
                    showIcon
                    banner
                    onClose={this.clearAlert}
                  />
                </div>
              )}
              {isOverLimit && (
                <div className="date_time_error">
                  <Alert
                    message="Error"
                    description={this.renderError(t, errorData)}
                    type="error"
                    closable
                    showIcon
                    banner
                    onClose={this.clearAlert}
                  />
                </div>
              )}

              <Page.Field>
                {encoder !== 'peer2peer' && (
                  <PrivacySelector
                    selected={selectedType}
                    types={privacyTypes}
                    isAdmin={isSuperAdmin || isAdmin}
                    onChange={this.handleSelectedType}
                  />
                )}
                {encoder === 'peer2peer' && (
                  <PrivacySelector
                    selected={selectedType}
                    types={specificTypes}
                    isAdmin={isSuperAdmin || isAdmin}
                    onChange={this.handleSpecificType}
                  />
                )}
              </Page.Field>
              {(selectedType === 2 || selectedType === 3) &&
                (encoder === 'conference' ||
                  encoder === 'chime_conference') && (
                  <Page.Field>
                    <Field>
                      <Field.Label>{t('labels:event_privacy')}</Field.Label>
                      <Field.Control>
                        <Switch
                          checkedChildren={t('v4:is_private')}
                          unCheckedChildren={t('v4:is_private')}
                          style={{ marginRight: '10px' }}
                          onChange={this.handleChangePrivacy}
                          checked={is_host_only}
                        />
                      </Field.Control>
                    </Field>
                  </Page.Field>
                )}
              {encoder === 'wowza_gocoder' ||
                encoder === 'stream_from_phone' ||
                ((encoder === 'other_webrtc' ||
                  (encoder === 'conference' && is_recording) ||
                  (encoder === 'chime_conference' && is_recording) ||
                  (encoder === 'peer2peer' && is_recording)) && (
                  <Page.Field>
                    <Field>
                      <Field.Label>{t('v4:transcribe')}</Field.Label>
                      <Field.Control>
                        <Switch
                          checkedChildren={t('v4:transcribe')}
                          unCheckedChildren={t('v4:transcribe')}
                          style={{ marginRight: '10px' }}
                          onChange={this.handleCheckTranscribe}
                          checked={toTranscribe}
                        />
                        {toTranscribe && (
                          <Select
                            showSearch
                            style={{ width: 200 }}
                            defaultValue={transcribeLocale}
                            placeholder="Transcribe to"
                            optionFilterProp="children"
                            onChange={e => this.handleChangeLocale(e)}
                            filterOption={(input, option) =>
                              !_.isArray(option.props.children) &&
                              option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {transcribeKeys.map(item => (
                              <Option key={item.code} value={item.code}>
                                {item.name}
                              </Option>
                            ))}
                          </Select>
                        )}
                      </Field.Control>
                    </Field>
                  </Page.Field>
                ))}
              {selectedType === 2 && (
                <Page.Field>
                  <OrganizationPicker
                    list={filteredList}
                    selectedOrganizations={selectedOrganizations}
                    onChange={this.handleSelectedOrganizations}
                    encoder={encoder}
                    isPrivate={is_host_only}
                  />
                </Page.Field>
              )}
              {selectedType === 3 && !is_host_only && (
                <Page.Field>
                  <GroupPicker
                    list={filteredList}
                    selectedGroups={selectedGroups}
                    onChange={this.handleSelectedGroups}
                    onChangeAll={this.handleSelectedAllOrganizations}
                  />
                </Page.Field>
              )}
              {selectedType === 4 && (
                <Page.Field>
                  <Field>
                    <Field.Label>{t('labels:users_emails')}</Field.Label>
                    <Field.Control>
                      <Input.TextArea
                        id="emails"
                        rows={3}
                        onChange={e => this.handleInsertUsers(e.target.value)}
                        defaultValue={emails.join('\n')}
                      />
                    </Field.Control>
                    <span>{t('labels:description_specifiction_email')}</span>
                  </Field>
                </Page.Field>
              )}
              {isSpecific && (
                <Page.Field>
                  <Field>
                    <Field.Label>{t('labels:users_emails')}</Field.Label>
                    <Field.Control>
                      <Select
                        showSearch
                        labelInValue
                        value={selectedValue}
                        placeholder="Select user's email"
                        notFoundContent={
                          fetching ? <Spin size="small" /> : null
                        }
                        filterOption={false}
                        onSearch={this.fetchUserEmails}
                        onChange={this.handleChange}
                        style={{ width: '50%' }}
                      >
                        {emailList.map(d => (
                          <Option key={d.value}>{d.text}</Option>
                        ))}
                      </Select>
                    </Field.Control>
                  </Field>
                </Page.Field>
              )}

              {(selectedType > 0 || isSpecific) && (
                <Page.Field>
                  <Field>
                    <Field.Label>{t('labels:disclaimer')}</Field.Label>
                    <Field.Control>
                      <Input.TextArea
                        disabled
                        rows={6}
                        style={{ resize: 'none' }}
                        value={t('events:disclaimer')}
                      />
                    </Field.Control>
                  </Field>
                  <Field>
                    <Field.Label>
                      <Checkbox
                        value={checkedTerms}
                        onChange={this.handleChangeTerms}
                      >
                        <Trans i18nKey="labels:terms">
                          {t('i_agree_to_these')}
                          <a href="/pages/terms-conditions" target="_blank">
                            {t('terms_and_conditions')}
                          </a>
                        </Trans>
                      </Checkbox>
                    </Field.Label>
                  </Field>
                </Page.Field>
              )}
            </Page>
          </Spin>
        </section>
        {isCheckModal && (
          <Modal
            visible={isCheckModal}
            centered
            title={
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <ReactSVG src="/images/icons/menu/bells.svg" />
                <div style={{ paddingLeft: '2rem' }}>
                  {t('labels:check_user')}
                </div>
              </div>
            }
            onCancel={this.closeCheckModal}
            footer={[
              <Button key="back" shape="round" onClick={this.closeCheckModal}>
                {t('v4:no')}
              </Button>,
              <Button
                key="submit"
                shape="round"
                type="primary"
                onClick={this.handleSendEmail}
              >
                {t('v4:yes')}
              </Button>
            ]}
          >
            {!_.isEmpty(isNotExist) && (
              <div style={{ marginBottom: '1rem' }}>
                <p style={{ color: '#333333' }}>{t('labels:user_not_exist')}</p>
                <div
                  style={{
                    backgroundColor: '#f1f1f1',
                    borderRadius: '.5rem',
                    padding: '1rem'
                  }}
                >
                  {!_.isEmpty(isNotExist) &&
                    isNotExist.map((item, index) => (
                      <div className="selectedMedia">
                        <div className="titleMedia">
                          {index + 1}. {item.email}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            )}
            {!_.isEmpty(isNotInOrg) && (
              <div>
                <p style={{ color: '#333333' }}>
                  {t('labels:user_not_exist_in_org')}
                </p>
                <div
                  style={{
                    backgroundColor: '#f1f1f1',
                    borderRadius: '.5rem',
                    padding: '1rem'
                  }}
                >
                  {!_.isEmpty(isNotInOrg) &&
                    isNotInOrg.map((item, index) => (
                      <div className="selectedMedia">
                        <div className="titleMedia">
                          {index + 1}. {item.email}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            )}
          </Modal>
        )}
        <div className="p-footer">
          <div className="p-footer__inner">
            {selectedType === 1 && (
              <div className="p-footer__item1">
                <Checkbox onChange={this.onAdminOnly} checked={isAdminOnly}>
                  {t('v4:admin_only')}
                </Checkbox>
              </div>
            )}
            {(selectedType === 2 || selectedType === 3) && (
              <div className="p-footer__item1">
                <Checkbox onChange={this.onSendEmail} checked={isSendMail}>
                  {t('v4:send_email')}
                </Checkbox>
              </div>
            )}
            <div className="p-footer__item">
              <Button
                className="rounded"
                onClick={() => history.push(`${rootPath}`)}
              >
                {t('buttons:back_to_details')}
              </Button>
            </div>
            <div className="p-footer__item">
              <Button
                className="rounded"
                type="green"
                loading={isSaving}
                onClick={this.handlePublish}
                disabled={isDisable}
              >
                {t('buttons:publish_event')}
              </Button>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default withTranslation('publishsettings')(Settings)
