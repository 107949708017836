import React from 'react'

export default () => {
  return (
    <div className='static-page'>
      <div style={{ padding: '0 2rem', maxWidth: '128rem', margin: '0 auto' }}>
        <ol>
          <li>
            <h2>OVERVIEW</h2>
            <p>
              <p>Sharelook has prepared this Privacy Statement to explain how we collect, retain, process, share and
                transfer your Personal Data when you visit our Sites or use our Services and software applications. This
                Privacy Statement does not apply to online websites or services that we do not own or control, including
                websites or services of other SHARELOOK USERS.</p>
              <p>This Privacy Statement is to give you information about our privacy practice and to help you understand
                your privacy options when you use our Sites and Services.</p>
              <p>Please contact us if you have questions about our privacy practices that are not addressed in this
                Privacy Statement.</p>
              <p>The following are definitions of terms used in this Privacy Statement:</p>
              <p><strong>Data</strong> means personal information which can be used to identify and individual person.
                It can include name, address, telephone number, email, payment and financial account information,
                national identification information.</p>
              <p><strong>Services</strong> means any products, services, content, features, technologies, or functions,
                and all related websites, applications and services offered to you by SHARELOOK in connection with an
                account or transaction.</p>
              <p><strong>Sites&nbsp;</strong>means websites, mobile apps or social media platforms used by SHARELOOK to
                offer Services and which posts or links to this Privacy Statement.</p>
              <p><strong>SHARELOOK </strong>means Sharelook Pte Ltd, a company incorporated in Singapore, its
                subsidiaries and affiliates.</p>
              <p><strong>User</strong>&nbsp;means an individual who uses the Services or accesses the Sites and has
                established a relationship with SHARELOOK by registering to use the Sites and/or Services.</p>
            </p>
          </li>

          <li>
            <h2>INFORMATION AND CONSENT</h2>
            <p>
              <p>By reading this Privacy Policy, you are informed about the way in which SHARELOOK collect, retain,
                process, share and transfer your personal data provided through the forms of our Site and Services.</p>
              <p>You the User must carefully read this Privacy Policy, which has been written clearly and simply, in
                order to help your understanding, and in order to determine freely and voluntarily if you wish to
                provide your personal data to SHARELOOK.</p>
            </p>
          </li>

          <li>
            <h2>OBLIGATION TO PROVIDE THE PERSONAL DATA</h2>
            <p>Data requested in the forms of our Sites and Services are in general mandatory in order to meet the
              stated purposes (unless in the required field specified otherwise). Therefore, if these are not provided
              or are not provided correctly, the service/demand required won&rsquo;t be able to be provided, you being
              only able to visual freely the content of our Sites and Services.</p>
          </li>

          <li>
            <h2>WITH WHAT PURPOSE WILL THE PERSONAL DATA OF THE USER BE PROCESSED AND FOR HOW LONG?</h2>
            <p>The Data provided to our Sites and Services will be processed by SHARELOOK, according to the following
              purposes:
              <ul>
                <li>Provide you with personalized information about products, services, events, courses, programs,
                  promotions and relevant news, by sending commercial communications through electronic and traditional
                  means.
                </li>
                <li>Manage the inscription in the corresponding event, when the User has provided their data for this
                  purpose. In this regard, the User is informed that SHARELOOK reserves the right to take photographs
                  and make videos of Users who attend organized events or events where there is participation of
                  SHARELOOK, in order to provide its Services. If the User does not want their data to be processed in
                  accordance with said purpose, please do not expose to the photographic or audiovisual screenshots
                  during the event.
                </li>
                <li>Manage the admission&rsquo;s procedure, which may include arrangements withofficial and/or necessary
                  organizations including the User&rsquo;s employers, and assessments and tests of Users who are
                  students, which will even include the creation of profiles, in the event the User initiates utilises
                  the Services offered by SHARELOOK.
                </li>
                <li>Manage the evaluation, review and assessment of Users who are students participating in the Services
                  provided by SHARELOOK.
                </li>
                <li>The User's Data will be kept by SHARELOOK during a period necessary to comply with the
                  aforementioned purposes or until the data subjects opposes or revokes his/her consent.
                </li>
              </ul>
            </p>
          </li>

          <li>
            <h2>WHAT DATA ARE WE GOING TO PROCESS?</h2>
            <p>SHARELOOK may collect the following categories of user data, depending on the request made through its
              Sites or for its Services.
              <ul>
                <li><h2>Identifying data.</h2></li>
                <li><h2>Contact details.</h2></li>
                <li><h2>Personal characteristics data.</h2></li>
                <li><h2>Transactional data</h2></li>
                <li><h2>Employment details data.</h2></li>
                <li><h2>Academic and professional data.</h2></li>
              </ul>
              <p>In the event the User provides Data from third persons, he / she must have the consent of these people
                and declares to communicate the information contained in this Privacy Policy, exonerating SHARELOOK from
                any liability in this sense whatsoever. However, SHARELOOK may carry out periodic verifications to
                certify this fact, adopting the due diligence measures which correspond, according to the data
                protection regulation currently in force.</p>
            </p>
          </li>

          <li>
            <h2>WHICH IS THE LAWFULNESS OF THE PROCESSING OF USER DATA?</h2>
            <p>
              <p>SHARELOOK requires to process Data to provide the Services and to fulfil its legal and regulatory
                obligations. The processing of User data by SHARELOOK is based on consent given and can be withdrawn at
                any time. However, if you withdraw your consent, this will not affect the lawfulness of the processings
                carried out previously.</p>
              <p>The consents obtained for the aforementioned purposes are independent. So if the User revokes only one
                of them, it won&rsquo;t affect the others.</p>
            </p>
          </li>

          <li>
            <h2>TO WHOM WILL WE COMMUNICATE THE USER'S DATA?</h2>
            <p>
              <p>Your personal data may only be disclosed to companies within the SHARELOOK, its subsidiaries and
                affiliates, as well as to service providers of SHARELOOK, only for the aforementioned purposes.</p>
              <p>The recipients indicated in this section may be located within or outside the European Economic Area
                (EEA), being duly legitimated international data transfers.</p>
              <p>Likewise, in the case your residence is outside the EEA, your data may be communicated to SHARELOOK, as
                well as to your employers, which may imply an international data transfer, in order to manage and carry
                out your request for information, registration and admission.</p>
            </p>
          </li>

          <li>
            <h2>RESPONSIBILITY OF THE USER</h2>
            <p>The User:
              <ul>
                <li>Guarantees he/she is over 16 years old and the data he/she provides to SHARELOOK are true, exact,
                  complete and updated. In this sense, the User is responsible for the truthfulness of all the data he /
                  she communicates and will provide updated information, in a way that responds to his / her actual
                  situation.
                </li>
                <li>Guarantees you have informed third persons whose data is provided, if any, of the aspects contained
                  in this document. You also guarantee you have obtained his/her authorization to provide their data to
                  SHARELOOK for the purposes indicated.
                </li>
                <li>Will be responsible for false or inaccurate information provided through the Sites and for direct or
                  indirect damages which may be caused to SHARELOOK or to third parties.
                </li>
              </ul>
            </p>
          </li>

          <li>
            <h2>COMMERCIAL AND PROMOTIONAL COMMUNICATIONS.</h2>
            <p>One of the purposes for which SHARELOOK will process the personal data provided by the Users is to send
              them personalized electronic communications with information regarding products, services, events,
              courses, programs, promotions and relevant news for Users. If any communication of this type is made, it
              will be addressed exclusively to those Users who have not previously expressed their refusal to receive
              them. To carry out this purpose, a profile based on your preferences will be developed, based on the
              information provided by the user or obtained even from external sources. In the event the User wishes to
              stop receiving commercial or promotional communications, he / she may unsubscribe from these services by
              sending an email to the following email address:&nbsp;[ ]&nbsp;or through any of the communications
              received.</p>
          </li>

          <li>
            <h2>EXERCISE OF RIGHTS</h2>
            <p>The User can send a letter to&nbsp;[email address ], enclosing a photocopy of your identity document, to:
              <ul>
                <li>Revoke the consents granted.</li>
                <li>Obtain confirmation as to whether SHARELOOK is processing personal data related to the User or
                  not.
                </li>
                <li>Access your personal data.</li>
                <li>Rectify inaccurate or incomplete data.</li>
                <li>Request the erasure of your data when, among other reasons, the data is no longer necessary for the
                  purposes for which they were collected in the first place.
                </li>
                <li>Obtain from SHARELOOK the restriction of the processing of the data, if any of the conditions
                  stipulated in the data protection regulation is fulfilled.
                </li>
                <li>Request the portability of your data.</li>
              </ul>
              <p>Likewise, User may contact the data protection officer of SHARELOOKwhose contact details are :
                T.Smart@sharelook.com if he/she considers SHARELOOK has violated the rights which are recognized in the
                applicable data protection regulation.</p>
            </p>
          </li>

          <li><h2>SECURITY MEASURES</h2>
            <p>The User's data will be processed at all times in an absolutely confidential manner and keeping the
              mandatory duty of confidentiality, in accordance with the provisions of the applicable regulation,
              adopting the necessary technical and organizational measures to guarantee the security of your data and
              avoid its alteration, loss, unauthorized processing or access, taking into account the state of the art,
              the nature of the stored data and the risks to which they are exposed.</p>
          </li>
        </ol>
        <p>Last update: 8 March 2019</p>
      </div>
    </div>
  )
}
