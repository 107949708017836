import http from './http'

function createChannel(data) {
  return http({
    method: 'POST',
    url: '/collections',
    data
  })
}

function updateChannel(channelId, data) {
  return http({
    method: 'PUT',
    url: `/collections/${channelId}`,
    data
  })
}

function getChannel(channelId) {
  return http({
    method: 'GET',
    url: `/collections/${channelId}`
  })
}

function listChannels(params) {
  return http({
    method: 'GET',
    url: `/collections?mode=${params.mode}&limit=${params.limit}&offset=${params.offset}${params.search ? "&search=" + params.search : ""}`
  })
}

function deleteChannel(channelId) {
  return http({
    method: 'DELETE',
    url: `/collections/${channelId}`
  })
}

function getVideoListFromChannel(channelId) {
  return http({
    method: 'GET',
    url: `/collections/${channelId}/videos`
  })
}

function deleteVideoFromChannel(channelId, videoId) {
  return http({
    method: 'DELETE',
    url: `/collections/${channelId}/videos/${videoId}`
  })
}

function listSubscriptions({ mode, limit, offset }) {
  return http({
    method: 'GET',
    url: `/collections?mode=${mode}&limit=${limit}&offset=${offset}`
  })
}

const deleteSubscription = channelId => {
  return http({
    method: 'DELETE',
    url: `/marketplace/checkout/unsubscribe/${channelId}`
  })
}

const updateSubscription = ({ channelId, data }) => {
  return http({
    method: 'PUT',
    url: `/marketplace/checkout/unsubscribe/${channelId}`,
    data
  })
}

export {
  createChannel,
  updateChannel,
  getChannel,
  listChannels,
  getVideoListFromChannel,
  deleteVideoFromChannel,
  deleteChannel,
  listSubscriptions,
  deleteSubscription,
  updateSubscription
}
