import React from 'react'

import { default as ReactCreatableSelect } from 'react-select/creatable'

const CreatableSelect = ({
  fieldKey,
  updateOptionValue,
  options,
  placeholder = ''
}) => {
  return (
    <ReactCreatableSelect
      isClearable
      onChange={newValue =>
        updateOptionValue(newValue ? newValue.value : '', fieldKey)
      }
      options={options.map(value => ({ value, label: value }))}
      placeholder={placeholder}
    />
  )
}

export default CreatableSelect
