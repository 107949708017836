import React from 'react'
import QuizView from '../QuizView'
import AnnotationView from '../AnnotationView'

import { Preview } from '../../../../../components/Builder'
import { builder } from '../../../../../constants'

const { types } = builder

const getWidth = (width, x) => {
  if (width > 768) {
    return 768
  }
  if (width >= 640 && x >= 100) {
    return 768
  }
  if (width >= 700 && width <= 710) {
    return 768
  }
  return width
}

const checkX = (x, width) => {
  if (width >= 640 && x >= 100) {
    return 0
  }

  if (width > 768) {
    return 0
  }

  return x
}

export default props => {
  const {
    node,
    node: {
      type,
      params,
      params: { width, height, x, y }
    },
    ids,
    isAnnotationLandscape
  } = props

  const style = {
    position: 'absolute',
    width: getWidth(width, x),
    height,
    top: y,
    left: checkX(x, width),
    zIndex: 5,
    overflow:isAnnotationLandscape?'hidden':'auto'
  }

  return (
    <div style={style}>
      {type === types.BACKGROUND_IMAGE && <img
        style={{ maxWidth: '100%' }}
        src={params.content}
        alt=""
      />}
      {type === types.TEXT && <Preview node={node} isView/>}
      {type === types.IMAGE && <Preview node={node}/>}
      {type === types.VIDEO && <Preview node={node}/>}
      {type === types.AUDIO && <Preview elementHeight={32} elementWidth={width < 199 ? 45 : width} node={node}/>}
      {type === types.VIDEO_STREAM && <Preview node={node}/>}
      {type === types.ANNOTATION && (
        <AnnotationView content={params.content} ids={ids}/>
      )}
      {type === types.QUIZ && <QuizView content={params} ids={ids}/>}
    </div>
  )
}
