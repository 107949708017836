import {
  SHOW_TEXT_EDIT_MODAL,
  SHOW_IMAGE_EDIT_MODAL,
  SHOW_VIDEO_EDIT_MODAL,
  SHOW_ANNOTATION_IMAGE_MODAL,
  SHOW_ANNOTATION_TEXT_MODAL,
  SHOW_ANNOTATION_VIDEO_MODAL,
  SHOW_ANNOTATION_AUDIO_MODAL,
  SHOW_ANNOTATION_MODAL,
  SHOW_QUIZ_EDIT_MODAL,
  SHOW_TEXT_MODAL,
  SHOW_INSERT_VIDEO_MODAL,
  SHOW_CREATE_AUDIO_MODAL,
  SHOW_SCENARIO_MODAL
} from '../types'

export const showEditTextModal = data => dispatch => {
  dispatch({
    type: SHOW_TEXT_EDIT_MODAL,
    payload: data
  })
}

export const showEditImageModal = data => dispatch => {
  dispatch({
    type: SHOW_IMAGE_EDIT_MODAL,
    payload: data
  })
}

export const showEditVideoModal = data => dispatch => {
  dispatch({
    type: SHOW_VIDEO_EDIT_MODAL,
    payload: data
  })
}

export const showAnnotationImageModal = data => dispatch => {
  dispatch({
    type: SHOW_ANNOTATION_IMAGE_MODAL,
    payload: data
  })
}

export const showAnnotationTextModal = data => dispatch => {
  dispatch({
    type: SHOW_ANNOTATION_TEXT_MODAL,
    payload: data
  })
}

export const showAnnotationVideoModal = data => dispatch => {
  dispatch({
    type: SHOW_ANNOTATION_VIDEO_MODAL,
    payload: data
  })
}

export const showAnnotationAudioModal = data => dispatch => {
  dispatch({
    type: SHOW_ANNOTATION_AUDIO_MODAL,
    payload: data
  })
}

export const showAnnotationModal = data => dispatch => {
  dispatch({
    type: SHOW_ANNOTATION_MODAL,
    payload: data
  })
}

export const showEditQuizModal = data => dispatch => {
  dispatch({
    type: SHOW_QUIZ_EDIT_MODAL,
    payload: data
  })
}

export const showTextModal = data => dispatch => {
  dispatch({
    type: SHOW_TEXT_MODAL,
    payload: data
  })
}

export const showInsertVideoModal = data => dispatch => {
  dispatch({
    type: SHOW_INSERT_VIDEO_MODAL,
    payload: data
  })
}

export const showCreateAudioModal = data => dispatch => {
  dispatch({
    type: SHOW_CREATE_AUDIO_MODAL,
    payload: data
  })
}

export const showScenarioModal = data => dispatch => {
  dispatch({
    type: SHOW_SCENARIO_MODAL,
    payload: data
  })
}
