import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import actions from '../../../../../../../../../../store/actions'
import EditText from './EditText'

const mapStateToProps = state => ({
  audioSynthesizer: state.audioSynthesizer,
  pageBuilder: state.pageBuilder
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      createAudioSynthesizer: actions.audioSynthesizer.createAudioSynthesizer,
      getSelectedTextVoiceType:
      actions.audioSynthesizer.getSelectedTextVoiceType,
      showEditAudioModal: actions.audioSynthesizer.showEditAudioModal
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(EditText)
