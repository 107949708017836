import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import actions from '../../../../../../../../store/actions/index'

import { QuizByOverall } from './QuizByOverall'

export default connect(
  state => ({
    data: state.reports
  }),
  dispatch => ({
    handlingData: bindActionCreators(actions.reports.handlingData, dispatch),
    getQuizReport: bindActionCreators(actions.reports.getQuizReport, dispatch)
  })
)(QuizByOverall)
