import React, { Component } from 'react'
import { Checkbox, Form, Icon, Input, Modal, Select } from 'antd'
import CreatableSelect from 'react-select/creatable'
import debounce from 'lodash/debounce'
import { Row, Col } from '../../../../../styles'
import { SearchOutlined, PaperClipOutlined } from '@ant-design/icons'
import _ from 'lodash'
import ShowContactsModal from './ShowContactsModal'
import { api } from '../../../../../services'
import { general } from '../../../../../constants'
import { CollapseContainer } from '../../styled'
import { bytesToSize } from '../../../../../utils'
import Attachment from '../Attachment'

const { Option } = Select

class Detail extends Component {
  constructor(props) {
    super(props)
    this.loadOptions = _.debounce(this.loadOptions, 500)
    this.lastFetchId = 0
    this.fetchUserEmails = debounce(this.fetchUserEmails, 1000)
    this.state = {
      isTopicLoading: false,
      isTopicsMax: false,
      fetching: false,
      attendeeNum: 'uptoTwoHundredFifty',
      participantNum: 'uptoTen',
      options: [],
      value: [],
      inputValue: '',
      emails: [],
      emailList: [],
      showContactModal: false,
      attendee_email: '',
      orgUsers: []
    }
  }

  componentDidMount() {
    const {
      user: {
        info: { active_organization_id }
      },
      current,
      topicValue
    } = this.props
    console.log('didmountdata', current, topicValue)
    api.organizations
      .getUsersByOrganizationId(active_organization_id)
      .then(res => {
        this.setState({
          orgUsers: res.data
        })
      })
  }

  componentDidUpdate(prevProps) {
    const {
      emails = [],
      current: { host_emails = [] },
      topicValue,
      attendee_email
    } = this.props
    console.log('didupdatedata', topicValue)
    if (prevProps !== this.props) {
      console.log(
        'here',
        prevProps.emails,
        emails,
        prevProps.current.host_emails,
        host_emails
      )

      const isEmailChanged = prevProps.current.host_emails !== host_emails

      console.log('isEmailChanged', isEmailChanged)
      if (
        prevProps.emails !== emails ||
        isEmailChanged ||
        ((host_emails.length > 0 || emails.length > 0) &&
          this.state.emails.length < 1)
      ) {
        console.log('inside')
        this.setState(
          {
            emails: host_emails || emails
          },
          () => {
            const {
              form: { setFieldsValue }
            } = this.props
            setFieldsValue({ host_emails: this.state.emails })
          }
        )
      }

      if (prevProps.attendee_email !== attendee_email) {
        this.setState({ attendee_email })
      }

      if (
        (topicValue && topicValue !== prevProps.topicValue) ||
        (topicValue && topicValue.length > 0 && this.state.value.length < 1)
      ) {
        this.setState({
          value: topicValue
        })
      }
      // if (prevProps.current !== this.props.current && current) {
      //   const no_of_people_conf = current.host_emails.length ? current.host_emails.length > 0 && current.host_emails.length === 10 ? 'uptoTen'
      //       : current.host_emails.length > 10 || current.host_emails.length === 20 ? 'uptoTwenty'
      //         : current.host_emails.length > 20 || current.host_emails.length === 25 ? 'uptoTwentyFive'
      //         : 'uptoTen' : 'uptoTen'
      //   this.setState({
      //     participantNum: no_of_people_conf,
      //     // emails: current.host_emails
      //   })
      // }
    }
  }

  createOptionList = list => {
    const options = list.map(topic => this.props.App.createOption(topic))
    this.setState({
      options,
      isTopicLoading: false
    })
  }

  handleChange = (newValue, actionMeta) => {
    if (actionMeta.removedValue) {
      this.setState(
        {
          value: newValue,
          inputValue: '',
          isTopicLoading: false,
          isTopicsMax: false
        },
        () => {
          this.props.App.handleTopicChange(this.state.value)
        }
      )
    } else if (!_.isEmpty(newValue) && newValue.length > 5) {
      this.setState({
        isTopicsMax: true
      })
    } else {
      this.setState(
        {
          value: newValue,
          inputValue: '',
          isTopicLoading: false,
          isTopicsMax: false
        },
        () => {
          this.props.App.handleTopicChange(this.state.value)
        }
      )
    }
    this.isModalSelectOpen()
  }

  handleCreate = inputValue => {
    this.setState({ isTopicLoading: true })
    const { options, value } = this.state
    const topic = { title: inputValue }
    if (!_.isEmpty(value) && value.length >= 3) {
      this.setState({
        isTopicsMax: true,
        isTopicLoading: false
      })
    } else {
      api.topics.createTopics(topic).then(({ data }) => {
        if (!_.isEmpty(data[0])) {
          const newOption = this.props.App.createOption(data[0])
          this.props.updateTopicList(data[0])
          this.setState(
            {
              isTopicLoading: false,
              isTopicsMax: false,
              options: [...options, newOption],
              value: [...value, newOption],
              inputValue: ''
            },
            () => {
              this.props.App.handleParam()
            }
          )
        }
      })
    }
    this.isModalSelectOpen()
  }

  loadOptions = inputValue => {
    if (inputValue.length > 0 && inputValue.length <= 50) {
      this.setState({
        isTopicLoading: true
      })
      const params = {
        search: inputValue.trim(),
        limit: 10,
        offset: 0
      }
      api.topics.getTopics(params).then(({ data }) => {
        this.setState({
          inputValue
        })
        this.createOptionList(data)
      })
    }
  }

  LoadingIndicator = () => (
    <Icon style={{ paddingRight: '10px' }} type="loading" />
  )

  fetchUserEmails = value => {
    const {
      // user: {
      //   info: { active_organization_id }
      // },
      attendee_email
    } = this.props
    const { emails, orgUsers } = this.state
    this.lastFetchId += 1
    const fetchId = this.lastFetchId
    console.log(`orgUsers`, orgUsers)
    this.setState({ emailList: [], fetching: true })
    // api.organizations
    //   .getUsersByOrganizationId(active_organization_id)
    //   .then(res => {
    if (fetchId !== this.lastFetchId) {
      return
    }
    orgUsers.forEach(val => {
      if (val.user) {
        const dataValue = {
          text: val.user.email,
          value: val.user.email,
          id: val.user.id
        }
        this.setState(state => {
          if (
            this.props.tag.value === 'live_assessment' &&
            ((emails && emails.includes(dataValue.value)) ||
              (attendee_email && attendee_email.includes(dataValue.value)))
          ) {
            console.log(
              `returnworked`,
              'emails',
              emails,
              'dataValue',
              dataValue,
              'attendee_email',
              attendee_email
            )
            return null
          } else if (
            this.props.tag.value === 'webinar' &&
            ((emails && emails.includes(dataValue.value)) ||
              (attendee_email && attendee_email.includes(dataValue.value)))
          ) {
            console.log(
              `returnworkedother`,
              'emails',
              emails,
              'dataValue',
              dataValue,
              'attendee_email',
              attendee_email
            )
            return null
          }
          if (dataValue.value.includes(value.toLowerCase())) {
            const emailList = [...state.emailList, dataValue]
            return {
              emailList,
              fetching: false
            }
          }
        })
      }
    })
    // })
  }

  handleEmailChange = selectedValue => {
    console.log(`handleemailchangedworked`, selectedValue)
    if (
      this.props.tag.value === 'live_assessment' &&
      selectedValue.length > 4
    ) {
      return
    } else if (
      this.props.tag.value === 'agora_conference' &&
      selectedValue.length > 9
    ) {
      return
    } else if (this.props.tag.value === 'webinar' && selectedValue.length > 4) {
      return
    } else if (
      this.props.tag.value === 'conference' &&
      selectedValue.length > 24
    ) {
      return
    }

    this.setState(
      prevState => {
        if (prevState.showContactModal) {
          return {
            emailList: [],
            emails: selectedValue,
            fetching: false,
            showContactModal: false
          }
        }
        return {
          emailList: [],
          emails: selectedValue,
          fetching: false
        }
      },
      () => {
        const {
          form: { setFieldsValue }
        } = this.props
        setFieldsValue({ host_emails: this.state.emails })
        this.props.App.handleAttendeeEmailChange(this.state.emails)
      }
    )
  }

  handleSpecificEmailChange = selectedValue => {
    this.setState(
      {
        emailList: [],
        emails: [selectedValue],
        fetching: false
      },
      () => {
        const {
          form: { setFieldsValue }
        } = this.props
        setFieldsValue({ host_emails: this.state.emails })
        this.props.App.handleAttendeeEmailChange(this.state.emails)
      }
    )
  }

  toggleContactModal = () => {
    this.setState(prevState => ({
      showContactModal: !prevState.showContactModal
    }))
  }

  handleHostEmailChange = selectedValue => {
    const {
      form: { setFieldsValue }
    } = this.props
    const filterEmail = this.state.emails.filter(item => item === selectedValue)
    if (filterEmail.length === 0) {
      // setFieldsValue({ host_emails: filterEmail })
      setFieldsValue({ host: selectedValue })
    }
    this.setState(
      {
        emailList: [],
        fetching: false
      },
      () => {
        this.props.App.handleParam()
      }
    )
  }

  isModalSelectOpen = () => {
    const { inputValue, value, isTopicsMax } = this.state
    return !_.isEmpty(value) && value.length >= 3 && isTopicsMax
      ? false
      : inputValue.length > 0
  }

  handleClickAttatch = e => {
    e.preventDefault()
    this.props.App.toggleFileModal()
    return
  }

  handleAttendeeEmailChange = value => {
    const { emailList } = this.state
    const email =
      emailList &&
      emailList.filter(item => {
        return item.value === value
      })
    const obj = {
      id: email[0].id,
      email: email[0].value
    }
    this.setState(
      {
        emailList: [],
        fetching: false
      },
      () => {
        this.props.App.handleAttendeeOfAssessment(obj)
      }
    )
  }

  render() {
    const {
      fetching,
      isTopicsMax,
      value,
      emails,
      emailList,
      showContactModal,
      isTopicLoading,
      options,
      orgUsers
    } = this.state
    const {
      validate,
      isFetching,
      App: {
        handleParam,
        checkFinish,
        checkedCompletUpload,
        onHandleDelete,
        handleNonMarketplaceFields
      },
      form: { getFieldDecorator, getFieldValue },
      t,
      user,
      organizations: { rows },
      current,
      attachmentIds,
      library: { uploadProgress, uploadSuccess },
      filenames,
      pdfFiles,
      encoderType,
      is_host_only,
      tag,
      attendee_id,
      attendee_email,
      shouldHideNonMarketplaceFields
    } = this.props

    console.log('detail state', this.state, this.props)
    const customStyles = {
      control: provided => {
        return {
          ...provided,
          borderRadius: '0px',
          borderColor: _.isEmpty(value) && validate ? '#FF4B55' : '#D8D8D8',
          boxShadow: 0,
          transition: 'transparent 0.3s cubic-bezier(0.215, 0.61, 0.355, 1)',
          marginBottom:
            (_.isEmpty(value) && validate) || isTopicsMax ? '0px' : '24px'
        }
      },
      menu: provided => ({
        ...provided,
        maxHeight: '185px'
      }),
      menuList: provided => ({
        ...provided,
        maxHeight: '185px'
      })
    }
    if (isFetching) {
      return null
    }

    const groupOptions = rows
      ? rows.filter(o => o.id === user.info.active_organization_id)[0]
      : {}

    const isCoaching = encoderType === 'peer2peer'
    return (
      <>
        {isCoaching && (
          <Checkbox
            style={{ marginBottom: '2rem' }}
            checked={shouldHideNonMarketplaceFields}
            onChange={e => handleNonMarketplaceFields(e.target.checked)}
          >
            {' '}
            Hide Non-Marketplace Fields
          </Checkbox>
        )}

        {!isCoaching ? (
          <Row>
            {!is_host_only &&
            encoderType !== 'live_assessment' &&
            encoderType !== 'zoom_meeting' ? (
              encoderType === 'agora_conference' ? (
                <>
                  <Col md={6}>
                    <p>{t('v2:support_upto_fivethousand_participants')}</p>
                  </Col>
                  <Col md={6}>
                    <p>{t('v2:support_upto_ten_participants')}</p>
                  </Col>
                </>
              ) : (
                <>
                  <Col md={6}>
                    <p>{t('v2:support_upto_twohundred_participants')}</p>
                    {/* <Form.Item
                    label={
                      t('v4:no_of_people_in_conference')
                    }
                    colon={false}
                    required={false}
                  >
                    {getFieldDecorator('participantNum', {
                      rules: [
                        {
                          required: true,
                          message: t('v4:team_size')
                        }
                      ],
                      initialValue: participantNum
                    })(
                      <Select
                        optionFilterProp="children"
                        placeholder={t('v4:no_of_people_placeholder')}
                        notFoundContent={
                          fetching ? <Spin size='small' /> : null
                        }
                        filterOption={false}
                        onChange={handleParam}
                        style={{ width: '100%' }}
                      >
                        {Object.keys(general.NUMBER_OF_PEOPLE_CONF).map(d => (
                          <Option key={d} value={d}>{t(`conferenceTeamOptions:${d}`)}</Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item> */}
                  </Col>
                  <Col md={6}>
                    <p>{t('v2:supprt_upto_five_participants')}</p>
                    {/* <Form.Item
                  label={
                    t('v4:no_of_people_in_conference')
                  }
                  colon={false}
                  required={false}
                >
                  {getFieldDecorator('participantNum', {
                    rules: [
                      {
                        required: true,
                        message: t('v4:team_size')
                      }
                    ],
                    initialValue: participantNum
                  })(
                    <Select
                      optionFilterProp="children"
                      placeholder={t('v4:no_of_people_placeholder')}
                      notFoundContent={
                        fetching ? <Spin size='small' /> : null
                      }
                      filterOption={false}
                      onChange={handleParam}
                      style={{ width: '100%' }}
                    >
                      {Object.keys(general.NUMBER_OF_PEOPLE_CONF).map(d => (
                        <Option key={d} value={d}>{t(`conferenceTeamOptions:${d}`)}</Option>
                      ))}
                    </Select>
                  )}
                </Form.Item> */}
                  </Col>
                </>
              )
            ) : encoderType === 'live_assessment' ||
              encoderType === 'zoom_meeting' ? null : (
              <Col md={12}>
                <p>{t('v2:support_upto_twentyfive_participants')}</p>
                {/* <Form.Item
                  label={
                    t('v4:no_of_people_in_conference')
                  }
                  colon={false}
                  required={false}
                >
                  {getFieldDecorator('participantNum', {
                    rules: [
                      {
                        required: true,
                        message: t('v4:team_size')
                      }
                    ],
                    initialValue: participantNum
                  })(
                    <Select
                      optionFilterProp="children"
                      placeholder={t('v4:no_of_people_placeholder')}
                      notFoundContent={
                        fetching ? <Spin size='small' /> : null
                      }
                      filterOption={false}
                      onChange={handleParam}
                      style={{ width: '100%' }}
                    >
                      {Object.keys(general.NUMBER_OF_PEOPLE_CONF).map(d => (
                        <Option key={d} value={d}>{t(`conferenceTeamOptions:${d}`)}</Option>
                      ))}
                    </Select>
                  )}
                </Form.Item> */}
              </Col>
            )}
          </Row>
        ) : (
          <Row>
            <Col md={12}>
              <p>{t('v2:two_live_video_participants')}</p>
            </Col>
          </Row>
        )}
        {shouldHideNonMarketplaceFields ? null : (
          <>
            {tag.value === 'conference' ? (
              <Form.Item
                label={t('labels:attendee_emails')}
                colon={false}
                required={false}
              >
                {getFieldDecorator('host_emails', {
                  rules: [
                    {
                      validator: (rule, value, callback) => {
                        if (tag.value === 'conference' && value.length > 24) {
                          callback(
                            t('v2:you_can_have_upto_twentyfive_participants')
                          )
                        } else {
                          callback()
                        }
                      }
                    },
                    {
                      required: true,
                      message: t('errors:attendee_email_require')
                    }
                  ],
                  initialValue: emails
                })(
                  <Select
                    mode="multiple"
                    placeholder={t('placeholders:email')}
                    notFoundContent={fetching ? 'No Data' : null}
                    filterOption={false}
                    onSearch={this.fetchUserEmails}
                    onChange={this.handleEmailChange}
                    style={{ width: '100%' }}
                    // loading={fetching}
                  >
                    {emailList.map(d => (
                      <Option key={d.value}>{d.text}</Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            ) : tag.value === 'webinar' ? (
              <Form.Item
                label={t('labels:attendee_emails')}
                colon={false}
                required={false}
              >
                {getFieldDecorator('host_emails', {
                  rules: [
                    {
                      validator: (rule, value, callback) => {
                        if (tag.value === 'webinar' && value.length > 5) {
                          callback(t('v2:you_can_have_upto_five_participants'))
                        } else {
                          callback()
                        }
                      }
                    },
                    {
                      required: true,
                      message: t('errors:attendee_email_require')
                    }
                  ],
                  initialValue: emails
                })(
                  <Select
                    mode="multiple"
                    placeholder={t('placeholders:email')}
                    notFoundContent={fetching ? 'No Data' : null}
                    filterOption={false}
                    onSearch={this.fetchUserEmails}
                    onChange={this.handleEmailChange}
                    style={{ width: '100%' }}
                    // loading={fetching}
                  >
                    {emailList.map(d => (
                      <Option key={d.value}>{d.text}</Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            ) : tag.value === 'agora_conference' ? (
              <Form.Item
                label={t('labels:attendee_emails')}
                colon={false}
                required={false}
              >
                {getFieldDecorator('host_emails', {
                  rules: [
                    {
                      validator: (rule, value, callback) => {
                        if (
                          tag.value === 'agora_conference' &&
                          value.length > 10
                        ) {
                          callback(t('v2:you_can_have_upto_ten_participants'))
                        } else {
                          callback()
                        }
                      }
                    },
                    {
                      required: true,
                      message: t('errors:attendee_email_require')
                    }
                  ],
                  initialValue: emails
                })(
                  <Select
                    mode="multiple"
                    placeholder={t('placeholders:email')}
                    notFoundContent={fetching ? 'No Data' : null}
                    filterOption={false}
                    onSearch={this.fetchUserEmails}
                    onChange={this.handleEmailChange}
                    style={{ width: '100%' }}
                    // loading={fetching}
                  >
                    {emailList.map(d => (
                      <Option key={d.value}>{d.text}</Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            ) : tag.value === 'peer2peer' ? (
              <Form.Item
                label={t('labels:attendee_emails')}
                colon={false}
                required={false}
              >
                {getFieldDecorator('host_emails', {
                  rules: [
                    {
                      required: false,
                      message: t('errors:attendee_email_require')
                    }
                  ],
                  initialValue: emails
                })(
                  <Select
                    showSearch
                    // labelInValue
                    placeholder={t('placeholders:email')}
                    notFoundContent={fetching ? 'No Data' : null}
                    filterOption={false}
                    onSearch={this.fetchUserEmails}
                    onChange={this.handleSpecificEmailChange}
                    style={{ width: '100%' }}
                  >
                    {emailList.map(d => (
                      <Option key={d.value}>{d.text}</Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            ) : (
              <Form.Item
                label={t('labels:trainer_assessor_attendee_emails')}
                colon={false}
                required={false}
              >
                {getFieldDecorator('host_emails', {
                  rules: [
                    {
                      validator: (rule, value, callback) => {
                        if (
                          tag.value === 'live_assessment' &&
                          value.length > 4
                        ) {
                          callback(t('v2:you_can_have_upto_five_examinees'))
                        } else {
                          callback()
                        }
                      }
                    },
                    {
                      required: true,
                      message: t('errors:attendee_email_require')
                    }
                  ],
                  initialValue: emails
                })(
                  <Select
                    mode="multiple"
                    placeholder={t('placeholders:email')}
                    notFoundContent={fetching ? 'No Data' : null}
                    filterOption={false}
                    onSearch={this.fetchUserEmails}
                    onChange={this.handleEmailChange}
                    style={{ width: '100%' }}
                  >
                    {emailList.map(d => (
                      <Option key={d.value}>{d.text}</Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            )}

            <span
              role="button"
              style={{
                position: 'relative',
                top: '-53px',
                float: 'right',
                paddingRight: '10px',
                cursor: 'pointer'
              }}
              onClick={() => this.toggleContactModal()}
            >
              <SearchOutlined />
            </span>
          </>
        )}

        {tag.value === 'live_assessment' && (
          <>
            <Form.Item
              label={'TRAINEE/ASSESSEE'}
              colon={false}
              required={false}
            >
              {getFieldDecorator('assessment_user', {
                rules: [
                  {
                    required: true,
                    message: t('v4:user_email_require')
                  }
                ],
                initialValue: attendee_email
              })(
                <Select
                  showSearch
                  placeholder={t('placeholders:email')}
                  notFoundContent={fetching ? 'No Data' : null}
                  filterOption={false}
                  onSearch={this.fetchUserEmails}
                  onChange={this.handleAttendeeEmailChange}
                  style={{ width: '100%' }}
                >
                  {emailList.map(d => (
                    <Option key={d.value}>{d.text}</Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </>
        )}
        <Form.Item label={t('labels:title')} colon={false} required={false}>
          {getFieldDecorator('title', {
            rules: [
              {
                required: true,
                message: t('errors:title_event'),
                max: 320
              }
            ],
            initialValue: current.title
          })(
            <Input
              name="title"
              placeholder={t('placeholders:title')}
              size="large"
              maxLength={100}
              onChange={handleParam}
            />
          )}
        </Form.Item>

        <div style={{ marginBottom: '4px', fontSize: '1.2rem' }}>
          {t('labels:topics').toUpperCase()}
        </div>
        <CreatableSelect
          styles={customStyles}
          components={{
            ClearIndicator: null,
            DropdownIndicator: null,
            LoadingIndicator: this.LoadingIndicator
          }}
          isMulti
          placeholder={t('labels:topics')}
          inputId="react-select-2-input"
          isLoading={isTopicLoading}
          menuIsOpen={this.isModalSelectOpen()}
          onBlur={() => this.setState({ inputValue: '' })}
          onChange={this.handleChange}
          onCreateOption={this.handleCreate}
          onInputChange={this.loadOptions}
          options={options}
          value={value}
        />
        {_.isEmpty(value) && validate && (
          <div className="error_message">{t('errors:live_topics')}</div>
        )}
        {isTopicsMax && (
          <div className="error_message">{t('errors:topics_max')}</div>
        )}
        <Form.Item label={t('labels:host')} colon={false} required={false}>
          {getFieldDecorator('host', {
            rules: [
              {
                required: true,
                message: t('errors:host')
              }
            ],
            initialValue:
              current.host && current.host.email
                ? current.host.email
                : user.info.email
                ? user.info.email
                : emails[0]
          })(
            <Select
              showSearch
              placeholder={t('v2:select_hosts_email')}
              notFoundContent={fetching ? 'No Data' : null}
              filterOption={false}
              disabled={
                !checkFinish(current.start_date_time, current.duration) ||
                tag.value === 'zoom_meeting'
              }
              onSearch={this.fetchUserEmails}
              onChange={this.handleHostEmailChange}
              style={{ width: '100%' }}
            >
              {emailList.map(d => (
                <Option key={d.value}>{d.text}</Option>
              ))}
            </Select>
          )}
        </Form.Item>

        <Form.Item label={t('v4:file_attached')} colon={false} required={false}>
          {getFieldDecorator('file_pdf', {
            initialValue: current.attachments
          })(
            <CollapseContainer
              bordered={false}
              accordion={true}
              expandIconPosition={'right'}
              className="attach_file_panel"
            >
              <CollapseContainer.Panel
                header={
                  <div
                    className="attach_file_button"
                    onClick={e => {
                      this.handleClickAttatch(e)
                    }}
                  >
                    <span role="button">
                      <PaperClipOutlined />
                    </span>
                    <span>
                      {t('v4:to_attach')}{' '}
                      {attachmentIds.length > 0 && (
                        <>({attachmentIds.length})</>
                      )}
                    </span>
                  </div>
                }
              >
                {filenames.length > 0 && getFieldValue('file_pdf') && (
                  <Attachment
                    data={filenames}
                    t={t}
                    handleDelete={onHandleDelete}
                  />
                )}
              </CollapseContainer.Panel>
            </CollapseContainer>
          )}
          {uploadProgress.length > 0 &&
            uploadSuccess.length !== uploadProgress.length &&
            uploadProgress.map((item, ind) =>
              item.percent < 100 ? (
                <div key={item.uid} className="upload-modal__item">
                  <div className="upload-modal-item">
                    <div className="upload-modal-item__percent-border">
                      <div
                        className={`upload-modal-item__percent ${
                          item.status === general.CANCELED
                            ? 'upload-modal-item__percent__cancel'
                            : ''
                        }`}
                        style={{ width: item.percent + '%' }}
                      />
                    </div>
                  </div>

                  {item.status !== general.CANCELED && (
                    <div className="upload-modal-item__weight">
                      {checkedCompletUpload(item) && (
                        <span>
                          {bytesToSize(item.loaded)} {t('of')}{' '}
                          {bytesToSize(item.total)}
                        </span>
                      )}
                      {!checkedCompletUpload(item) && (
                        <span className="upload-modal-item__weight__complete">
                          {pdfFiles[ind] && pdfFiles[ind].removed
                            ? t('general:removed')
                            : t('general:completed')}
                        </span>
                      )}
                    </div>
                  )}
                </div>
              ) : null
            )}
        </Form.Item>

        <Modal
          visible={showContactModal}
          onCancel={this.toggleContactModal}
          width={700}
          footer={null}
        >
          <ShowContactsModal
            active_organization_id={user.info.active_organization_id}
            groupOptions={groupOptions}
            t={t}
            addEmails={this.handleEmailChange}
            selectedEmails={getFieldValue('host_emails')}
            encoderType={encoderType}
            orgUsers={orgUsers}
            tagValue={tag.value}
          />
        </Modal>
      </>
    )
  }
}

export default Detail
