import React, { Component, Fragment } from 'react'
import ReactPlayer from 'react-player'
import { withTranslation, Trans } from 'react-i18next'

import { Progress, Tooltip, Icon, Spin } from 'antd'
import { Filter } from '../../../components'
import { Accordion } from './internal'
import { getPercent } from '../../../utils'

import {
  Page
} from './styled'

import { styleguide } from '../../../constants'
import { i18n } from '../../../services'

const { colors } = styleguide

const filterData = [
  { value: 7, text: 'filters:days7' },
  { value: 15, text: 'filters:days15' },
  { value: 30, text: 'filters:days30' },
  { value: 365, text: 'filters:days365' }
]

const accountFields = [
  { key: 'username', title: i18n.t('labels:username') },
  { key: 'name', title: i18n.t('labels:name') },
  { key: 'email', title: i18n.t('labels:email') },
  { key: 'position', title: i18n.t('labels:position') },
  { key: 'address', title: i18n.t('labels:location') },
  { key: 'phone_number', title: i18n.t('labels:phone') },
  { key: 'topics', title: i18n.t('labels:topics') },
  { key: 'avatar_id', title: i18n.t('labels:photo') }
]

class Dashboard extends Component {
  state = {
    activeFilterValue: filterData[0].value
  }

  componentDidMount () {
    this.props.getDashboardCourses()
  }

  onFirstShow = (e) => {
    if (e) {
      e.preventDefault()
    }

    const { userActions, user: { info } } = this.props

    userActions.updateUser(info.id, null, { is_first_show: true })
  }

  onFilter = value => {
    this.setState({
      activeFilterValue: value
    })
  }

  render () {
    const {
      user: {
        info: {
          is_first_show: isFirstShow
        },
        info,
        isFetchingUserAttributes
      },
      dashboardCourses: {
        list,
        fetch
      },
      t
    } = this.props

    const completedFieldsCount = accountFields
      .filter(i => !!info[i.key])
      .length

    const uncompletedText = accountFields
      .filter(i => !info[i.key])
      .map(i => i.title)
      .join(', ')

    const completedPercent = getPercent(completedFieldsCount, accountFields.length)

    const { activeFilterValue } = this.state

    return (
      <Page>
        {completedPercent !== 100 && <Page.Container dark>
          <Page.Head>
            <Page.Head.Title>{t('profile_title')}</Page.Head.Title>

            {isFirstShow
              ? <Page.Head.SubTitle>
                <Trans i18nKey='dashboard:profile_add_info'>Add your <Page.Head.SubTitle.Link
                  to='/Onboarding'>{{ uncompletedText }}</Page.Head.SubTitle.Link> to complete your profile.</Trans>
              </Page.Head.SubTitle>
              : <Page.Head.SubTitle>
                <Trans i18nKey='dashboard:profile_build'>Build your profile <Page.Head.SubTitle.Link
                  to='/Onboarding'>here</Page.Head.SubTitle.Link>, let us know your interests and skills.</Trans>
              </Page.Head.SubTitle>}
          </Page.Head>

          <Progress
            width={45}
            strokeLinecap='square'
            strokeWidth={22}
            strokeColor={colors.blue}
            type='circle'
            showInfo={false}
            percent={completedPercent}
          />
        </Page.Container>
        }

        {isFirstShow
          ? <Fragment>
            <Page.Container>
              <Page.Head.Wrapper>
                <h2>{t('page_title')}</h2>
                <h3>{t('page_subtitle')}</h3>
              </Page.Head.Wrapper>

              <Filter
                data={filterData}
                defaultValue={filterData[0].text}
                callback={item => this.onFilter(item.value)}
                label={t('labels:show')}
                noIndent
              />
            </Page.Container>
            <Spin spinning={fetch}>
              <Accordion list={list} activeFilterValue={activeFilterValue}/>
            </Spin>
          </Fragment>
          : <Spin tip={t('general:loading')} spinning={isFetchingUserAttributes}>
            <Page.Video>
              <Page.Video.Item>
                <ReactPlayer
                  url='https://youtu.be/Ag3-g47pKow'
                  height='100%'
                  width='100%'
                  controls='true'
                  onEnded={this.onFirstShow}
                />
                <Tooltip
                  placement='top'
                  title={t('tooltip_title')}
                >
                  <Page.Video.Close onClick={(e) => this.onFirstShow(e)}>
                    <Icon type='close'/>
                  </Page.Video.Close>
                </Tooltip>
              </Page.Video.Item>
            </Page.Video>
          </Spin>}
      </Page>
    )
  }
}

export default withTranslation('dashboard')(Dashboard)
