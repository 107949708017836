import React, { Component, Fragment } from 'react'
import moment from 'moment'
import { isSafari } from 'react-device-detect'
import { Tabs, message, Modal, notification } from 'antd'
import ReactPlayer from 'react-player'
import { withTranslation } from 'react-i18next'

import { TopPageLink, Chat, Spinner } from '../../../components'
import { Poll, CurrentPoll } from './components/Poll'
import './Preview.scss'
import _, { intersection } from 'lodash'
import { api } from '../../../services'
import { config, general } from '../../../constants'
import { AntPlayer } from './components/AntMediaService'
import { SlickCarousel } from './components/SlickCarousel'
import online from './assets/online.svg'
import liveNow from './assets/liveNow.svg'
import liveIcon from './assets/liveIcon.svg'
import liveNowPrivate from './assets/liveNowPrivate.svg'
import nowLivePublic from './assets/nowLivePublic.svg'
import thaiLiveNow from './assets/Thai-LiveNow.svg'
import thaiPrivate from './assets/Thai-Private.svg'
import thaiPublic from './assets/Thai-Public.svg'
import herbrewLiveNow from './assets/Hebrew_LiveNow.svg'
import herbrewPrivate from './assets/Hebrew_Private.svg'
import herbrewPublic from './assets/Hebrew_Public.svg'
import YellowHandRaise from './assets/yellowHandRaise.svg'
import DiscussionIcon from './assets/discussion.svg'
import PollIcon from './assets/poll.svg'
import { ChimeConference } from './components/AmazonChime'
import AgoraCall from './components/Agora/AgoraVideoCall/AgoraCall'
import LiveAssessment from './components/LiveAssessment'
import ZoomSection from './components/ZoomSection'
import PreviewHeader from './components/PreviewHeader'
import PreviewStatus from './components/PreviewStatus'
import SidebarModal from './components/SidebarModal'
import EmptyMessage from '../../../components/EmptyMessage/EmptyMessage'

const { TabPane } = Tabs

message.config({
  top: 100,
  duration: 5,
  maxCount: 1
})

const ITEMS = {
  OVERVIEW: 'OVERVIEW',
  HOST: 'HOST',
  ATTACHMENT: 'ATTACHMENT',
  NOTES: 'NOTES',
  PARTICIPANTS: 'PARTICIPANTS',
  ROOM: 'ROOM',
  RATING: 'RATING',
  DISCUSSION: 'DISCUSSION'
}

const showItems = {
  overview: false,
  hosts: false,
  attachments: false,
  notes: false,
  participants: false,
  rooms: false,
  ratings: false,
  discussion: false
}
class Preview extends Component {
  _isMounted = false
  playerRef = null
  player = null
  socket = null
  state = {
    isLoading: true,
    isStreaming: false,
    event: null,
    currentPlayTime: 0,
    videoDuration: null,
    messages: [],
    userId: null,
    isEnd: false,
    pollValues: [],
    userPollValues: [],
    isNoProfile: false,
    isRefresh: false,
    isScrollBottom: true,
    isChatAble: false,
    playerRef: null,
    countDown: false,
    graphDuration: [],
    spaceGraphValue: 0,
    isConference: false,
    broadcast: false,
    isStreamStopped: false,
    coachingDurationToWarn: 'no',
    isDurationExceed: 'no',
    estimatedTimeToStop: null,
    isWebStream: null,
    liveDuration: null,
    hasStreamStarted: false,
    totalViewers: 0,
    controlVideos: [],
    isCoachingSession: false,
    estimatedTimeToWarn: null,
    hostEmails: [],
    isProcessing: false,
    countStream: '',
    isSocketClose: false,
    isPastEvent: false,
    showConfirmModal: false,
    isPlay: false,
    showJoinButton: false,
    webinarHostId: '',
    allowJoin: false,
    isEventCrash: false,
    showBadConnectionMsg: false,
    isPreparingConf: false,
    userGroup: [],
    canVote: true,
    isHandRaised: false,
    raiseHandRequesting: false,
    raiseHandAllowed: [],
    requestedPassiveUsers: [],
    currentAskedPoll: null,
    askedPolls: [],
    rooms: [],
    activeKey: '1',
    show: showItems,
    participantData: {},
    createRoom: false,
    isEventJoined: false,
    isBreakoutHost: false,
    isInvite: false,
    allParticipants: [],
    previewStreamList: [],
    previewLocalStream: {},
    hasHostJoined: false,
    timer: null
  }

  setPlayerRef = node => {
    this.playerRef = node
  }

  componentDidMount() {
    this._isMounted = true
    const {
      user: {
        info: { is_reload }
      },
      updateUser,
      getDiscussionLog
    } = this.props
    const { id } = this.props.match.params
    if (id && this._isMounted) {
      this.interval = setInterval(() => {
        this.handleShowChat()
      }, 10000)
      this.getEventById(id)
    }
    if (getDiscussionLog) {
      const type = 'event'
      getDiscussionLog({ id, type })
    }

    if (is_reload) {
      updateUser(id, null, { is_reload: false }).then(() => {
        setTimeout(() => {
          window.location.reload(true)
        }, 5000)
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      broadcast,
      event,
      estimatedTimeToStop,
      estimatedTimeToWarn,
      isSocketClose,
      requestedPassiveUsers
    } = this.state
    const {
      user: {
        info: { id: userId }
      },
      organizations: { rows }
    } = this.props

    if (prevState.isSocketClose !== isSocketClose && isSocketClose) {
      this.initSocket(userId)
    }

    if (prevState.event !== event && this._isMounted && userId && rows) {
      this.checkAndSwitchOrg(event, rows)
    }

    if (
      prevProps.organizations.rows !== rows &&
      this._isMounted &&
      userId &&
      rows
    ) {
      this.checkAndSwitchOrg(event, rows)
    }

    if (
      broadcast &&
      prevState.estimatedTimeToStop === estimatedTimeToStop &&
      estimatedTimeToStop &&
      prevState.estimatedTimeToWarn === estimatedTimeToWarn &&
      estimatedTimeToWarn
    ) {
      this.checkEventStatus()
    }

    if (
      prevState.requestedPassiveUsers !== requestedPassiveUsers &&
      requestedPassiveUsers
    ) {
      const myId = requestedPassiveUsers.filter(i => i.userId === userId)
      const allowedUsersArr = requestedPassiveUsers.filter(i => {
        if (i.status === 'approved') {
          return true
        }
        return false
      })
      const allowedUsers = allowedUsersArr.map(i => {
        return i.userId
      })
      if (myId.length > 0) {
        this.setState(() => {
          if (myId[0].status === 'waiting') {
            return {
              isHandRaised: true,
              raiseHandRequesting: true
            }
          }
          if (myId[0].status === 'approved') {
            return {
              isHandRaised: true,
              raiseHandRequesting: false,
              raiseHandAllowed: [myId[0].userId]
            }
          }
          if (myId[0].status === 'declined') {
            return {
              isHandRaised: false,
              raiseHandRequesting: false,
              raiseHandAllowed: []
            }
          }
        })
      } else {
        if (userId !== event.host_id) {
          return this.setState({
            isHandRaised: false,
            raiseHandRequesting: false,
            raiseHandAllowed: []
          })
        } else {
          this.setState({ raiseHandAllowed: allowedUsers })
        }
      }
    }
  }

  checkAndSwitchOrg = (event, rows) => {
    const {
      user: {
        info: { id: userId, active_organization_id: userOrg }
      },
      updateUser
    } = this.props

    if (event && event.purchased_from_marketplace) return

    const userOrgs = rows.map(e => e.id)
    if (event && event.setting) {
      const commonIds = intersection(event.setting.organization_ids, userOrgs)
      if (commonIds.length > 0) {
        const assignedOrg = _.findIndex(commonIds, function(o) {
          return o === userOrg
        })
        if (assignedOrg === -1) {
          updateUser(userId, null, {
            active_organization_id: commonIds[0]
          }).then(() => {
            window.location.reload(true)
          })
        }
      }
    }
  }

  checkEventStatus = () => {
    const { isDurationExceed, coachingDurationToWarn } = this.state
    if (isDurationExceed === 'no') {
      this.checkDurationExceed('stop')
    }
    if (isDurationExceed === 'yes') {
      this.setState({
        isDurationExceed: 'inactive',
        estimatedTimeToStop: null,
        estimatedTimeToWarn: null
      })
      return
    }
    if (coachingDurationToWarn === 'no') {
      this.checkDurationExceed('warn')
    }
    if (coachingDurationToWarn === 'yes') {
      this.setState({
        coachingDurationToWarn: 'inactive',
        estimatedTimeToStop: null,
        estimatedTimeToWarn: null
      })
    }
  }

  checkDurationExceed = type => {
    const { estimatedTimeToStop, estimatedTimeToWarn } = this.state
    if (type === 'stop') {
      const diffMinutes = moment().diff(estimatedTimeToStop, 'minutes')
      if (diffMinutes === 0) {
        this.setState({
          isDurationExceed: 'yes'
        })
      }
    } else if (type === 'warn') {
      const diffMinutes = moment().diff(estimatedTimeToWarn, 'minutes')
      if (diffMinutes === 0) {
        this.setState({
          coachingDurationToWarn: 'yes'
        })
      }
    }
  }

  handleChatAble = ({ start_date_time: startDataTime }) => {
    const newTime = moment(startDataTime).format('LLLL')
    const diffMinutes = moment().diff(newTime, 'minutes')
    return diffMinutes >= -10
  }

  handleConferenceButton = ({ start_date_time: startDataTime }) => {
    const newTime = moment(startDataTime).format('LLLL')
    const diffMinutes = moment().diff(newTime, 'minutes')
    return diffMinutes >= 10
  }

  getEventById = id => {
    const { viewEvent } = this.props

    api.events
      .getById(id)
      .then(({ data }) => {
        if (this._isMounted) {
          const isConference =
            data.encoder === 'conference' ||
            data.encoder === 'chime_conference' ||
            data.encoder === 'live_assessment' ||
            data.encoder === 'agora_conference'
          const isCoachingSession = data.encoder === 'peer2peer'
          const isPastEvent = data.is_finished
          const canVote = !data.is_finished ? true : false
          const hostEmails = [{ id: data.host_id, email: data.host.email }]
          if (data.host_ids && data.host_ids.length > 0) {
            data.host_ids.forEach((h, i) => {
              data.host_emails.forEach((e, j) => {
                if (i === j) {
                  const item = { id: h, email: e }
                  hostEmails.push({ ...item })
                }
              })
            })
          }

          if (data.encoder === 'live_assessment')
            hostEmails.push({
              id: data.attendee_id,
              email: data.attendee_email
            })

          this.setState({
            event: data,
            isLoading: false,
            isChatAble: this.handleChatAble(data),
            isConference,
            hostEmails,
            isPastEvent,
            isCoachingSession: isCoachingSession,
            canVote
          })
          this.addToParticipants(data.hosts)
          this.getUserInfo()
          data && viewEvent(data.id)
        }
      })
      .catch(() => {
        this.setState({
          isLoading: false
        })
      })
  }

  componentWillUnmount() {
    this.stopStream()
    clearInterval(this.interval)
    clearInterval(this.getConnectedInterval)
    clearInterval(this.pollingInterval)
    this._isMounted = false
    this.props.clearNotes()
    this.props.clearDiscussion()
  }

  getUserInfo = () => {
    api.user
      .getUser()
      .then(response => {
        const { id: userId, email, active_organization_id: orgId } = response
        const {
          event: {
            setting: { group_ids }
          }
        } = this.state
        this.setState(
          {
            userId
          },
          () => {
            if (group_ids && group_ids.length > 0) {
              this.getUserGroupInOrg(orgId, email)
            }
            this.initSocket(userId)
          }
        )
      })
      .catch(error => console.log('error', error))
  }

  getUserGroupInOrg = (orgId, userEmail) => {
    api.organizations
      .searchUsersInOrganization({
        orgId,
        searchType: 'searchByemail',
        searchValue: userEmail
      })
      .then(response => {
        const { data } = response
        const groups =
          data.length > 0 ? data[0].user && data[0].user.groups : []
        const userGroup =
          groups.length > 0
            ? groups.map(group => {
                return group.id
              })
            : []
        this.setState({
          userGroup
        })
      })
  }

  addToParticipants = arr =>
    this.setState({
      allParticipants: arr
    })

  initSocket = userId => {
    const { id } = this.props.match.params
    const {
      user: { info }
    } = this.props
    const { event: eventState } = this.state
    const user_id = info.id || userId
    const userName = eventState && eventState.host && eventState.host.username
    const isEventFinished = eventState && eventState.is_finished

    const isChime =
      eventState &&
      eventState.organization.media_service === 'CHIME' &&
      eventState &&
      eventState.event_type !== 'public_conference'
    const hasRooms =
      eventState && eventState.breakout_rooms
        ? eventState.breakout_rooms.length > 0
          ? true
          : false
        : false
    this.socket = new window.WebSocket(
      `${config.socketURL}?channel_type=EVENT&channel_id=${id}&user_id=${user_id}&user_name=${userName}`
    )

    this.socket.onopen = () => {
      this.getChatHistory()
      this.getAllowedUsers()
      // if (!isChime) {
      //   this.getAntInfo()
      // }
      if (hasRooms) {
        this.getBreakoutInfo()
      }
      this.getVoteStatus()
      this.getAskVoteStatus()
      if (!isEventFinished) {
        this.pollingInterval = setInterval(() => {
          this.getVoteStatus()
          this.getAskVoteStatus()
        }, 20000)
      }
      this.getConnectedInterval = setInterval(() => {
        this.socket.send(
          JSON.stringify({
            action: 'pingPong',
            msg: 'ping'
          })
        )
      }, 10000)
      this.setState({ isSocketClose: false })
    }

    this.socket.onmessage = event => {
      const data = JSON.parse(event.data)
      if (data.messages) {
        _.each(data.messages, item => {
          item.isReadMore = item.content && item.content.length >= 200
        })
      } else {
        if (data.content) {
          data.isReadMore = data.content.length >= 200
        }
      }

      if (data.event === 'sendMessage' && data.channel_id === id) {
        this.setState(state => ({
          messages: [...state.messages, data]
        }))
      }

      if (data.event === 'breakoutRoom' && data.channel_id === id) {
        this.setState(() => ({
          rooms: data.breakout_room
        }))
      }

      if (data.event === 'raiseHand') {
        this.setState({
          requestedPassiveUsers: data.message
        })
      }

      if (data.event === 'raiseHandNotification') {
        const msg = data.message
        if (msg.userId !== userId) {
          this.raisedHandNotification(msg)
        }
      }

      if (data.event === 'getRaisedHands') {
        this.setState({
          requestedPassiveUsers: data.message
        })
      }

      if (data.event === 'changeRaisedHandStatus') {
        this.setState({
          requestedPassiveUsers: data.message
        })
      }

      if (data.event === 'getAskedPolls' && data.channel_id === id.toString()) {
        this.setState({
          currentAskedPoll: data.content.currentPollIndex,
          askedPolls: data.content.askedPolls
        })
      }

      if (data.event === 'askPoll' && data.channel_id === id.toString()) {
        this.setState({
          currentAskedPoll: data.content.currentPollIndex,
          askedPolls: data.content.askedPolls
        })
      }

      if (
        data.event === 'checkPollVote' &&
        data.channel_id === id.toString() &&
        data.user_id === user_id.toString()
      ) {
        if (data.all_voted_result && data.all_voted_result.length > 0) {
          const result = data.all_voted_result.map(a => {
            if (a.q) {
              a.q = JSON.parse(a.q)
              a.is_voted = a.is_voted ? a.is_voted : false
            }

            return a
          })

          this.setState(() => ({
            pollValues: result,
            userPollValues: data && data.voted && data.voted
          }))
        }
        if (data.is_voted) {
          this.setState({
            canVote: false
          })
        }
      }

      const isZoom = eventState.encoder === 'zoom_meeting'
      if (data.event === 'endConference' && data.channel_id === id) {
        const newEvent = this.state.event && this.state.event
        newEvent.is_finished = true
        this.setState(
          {
            event: newEvent
          },
          () => {
            clearInterval(this.getConnectedInterval)
            clearInterval(this.pollingInterval)
            if (isChime || isZoom) {
              this.setState({ isStreamStopped: true, show: showItems })
            }
          }
        )
        // allowJoin: false,
        //       isEventCrash: false,
        //       isStreaming: false,
        //       isPastEvent: true
      }

      if (data.event === 'antmediaInfo') {
        if (data.status === 'CREATING' || data.status === 'CREATED') {
          this.setState({
            isPreparingConf: true
          })
        } else if (data.status === 'READY') {
          this.setState({
            allowJoin: true,
            isPreparingConf: false,
            isEventCrash: false
          })
        } else if (data.status === 'FAILED') {
          this.setState({
            isEventCrash: true,
            allowJoin: false,
            isPreparingConf: false,
            show: showItems
          })
        }
      }

      if (data.event === 'sendCount' && data.channel_id === id) {
        this.setState({
          totalViewers: data.views
        })
      }

      if (data.event === 'pollVote' && data.channel_id === id) {
        const pollData = this.state.pollValues
        const isVoted = user_id === data.user_id ? true : false
        const result =
          pollData.length > 0
            ? pollData.map(p => {
                p.is_voted = p.q === data.result.q ? isVoted : p.is_voted
                return p
              })
            : [
                {
                  q: data.result.q,
                  votes: data.result.votes,
                  is_voted: isVoted
                }
              ]
        this.setState({
          pollValues: [...result]
        })
      }

      if (data.event === 'startStream' && data.channel_id === id) {
        this.liveCountInterval = setInterval(() => {
          this.getLiveCount()
        }, 5000)

        if (eventState && eventState.encoder === 'wowza_gocoder') {
          this.initStream()
        } else {
          this.setState({
            isStreaming: true,
            isWebStream: true
          })
        }
      }

      if (data.event === 'stopStream' && data.channel_id === id) {
        if (eventState && eventState.encoder === 'wowza_gocoder') {
          this.stopStream()
        } else {
          clearInterval(this.liveCountInterval)
          clearInterval(this.getConnectedInterval)
          clearInterval(this.pollingInterval)
          const newEvent = this.state.event && this.state.event
          newEvent.is_finished = true
          this.setState({
            isStreaming: false,
            event: newEvent,
            isPastEvent: true
          })
        }
      }

      if (data.event === 'historyChannel' && data.channel_id === id) {
        this.setState({
          messages: data.messages
        })

        if (eventState && !eventState.is_finished && data.live) {
          if (eventState && eventState.encoder === 'wowza_gocoder') {
            this.initStream()
          } else {
            this.setState({
              isStreaming: true,
              isWebStream: true
            })
          }
        }
      }
    }

    this.socket.onerror = error => {
      console.log('Error ' + error.message)
    }

    this.socket.onclose = () => {
      console.log('on this socket close')
      this.setState({ isSocketClose: true })
    }
  }

  raisedHandNotification = info => {
    notification.open({
      message: `${info.userName} has raised their hand`,
      placement: 'bottomLeft',
      icon: <img alt="" src={YellowHandRaise} />
    })
  }

  stopStream = () => {
    const newEvent = this.state.event
    if (newEvent) {
      newEvent.is_finished = true
    }
    const myPlayer = window.WowzaPlayer
      ? window.WowzaPlayer.get('stream')
      : null

    if (window.player) {
      window.player.destroy()
    }

    if (myPlayer !== null) {
      myPlayer.finish()
      myPlayer.destroy()

      this.setState({
        isStreaming: false,
        event: newEvent
      })
    } else {
      this.setState({
        isStreaming: false,
        event: newEvent,
        isWebStream: null
      })
    }
  }

  initStream = () => {
    const { event } = this.state
    this.setState({
      isStreaming: true
    })

    const streamUrl = event.stream ? event.stream.player_hls_playback_url : ''

    window.WowzaPlayer.create('stream', {
      license: config.wowza.license,
      sourceURL: streamUrl,
      autoPlay: true,
      mute: false,
      loop: false,
      audioOnly: false,
      uiShowQuickRewind: true,
      uiQuickRewindSeconds: '30',
      withCredentials: false
    })
  }

  sendHandRaise = () => {
    const {
      user: { info },
      match: {
        params: { id }
      }
    } = this.props
    const user_id = info.id
    const user_name = info.name ? info.name : info.username
    const { event } = this.state

    const mediaServer =
      event &&
      event.organization.media_service === 'CHIME' &&
      event.event_type !== 'public_conference'
        ? 'CHIME'
        : 'AGORA'

    this.socket.send(
      JSON.stringify({
        action: 'raiseHand',
        channel_id: id,
        channel_type: 'EVENT',
        mediaServer,
        userId: user_id,
        title: `event_${id}`,
        userName: user_name
      })
    )
  }

  allowToAsk = userId => {
    const {
      match: {
        params: { id }
      }
    } = this.props
    const { event } = this.state

    const mediaServer =
      event &&
      event.organization.media_service === 'CHIME' &&
      event.event_type !== 'public_conference'
        ? 'CHIME'
        : 'AGORA'

    this.socket.send(
      JSON.stringify({
        action: 'changeRaisedHandStatus',
        channel_id: id,
        channel_type: 'EVENT',
        mediaServer,
        status: 'approved',
        title: `event_${id}`,
        userId
      })
    )
  }

  declineToAsk = userId => {
    const {
      match: {
        params: { id }
      }
    } = this.props
    const { event } = this.state

    const mediaServer =
      event &&
      event.organization.media_service === 'CHIME' &&
      event.event_type !== 'public_conference'
        ? 'CHIME'
        : 'AGORA'

    this.socket.send(
      JSON.stringify({
        action: 'changeRaisedHandStatus',
        channel_id: id,
        channel_type: 'EVENT',
        mediaServer,
        status: 'declined',
        title: `event_${id}`,
        userId
      })
    )
  }

  getAllowedUsers = () => {
    const {
      match: {
        params: { id }
      }
    } = this.props
    const { event } = this.state

    const mediaServer =
      event &&
      event.organization.media_service === 'CHIME' &&
      event.event_type !== 'public_conference'
        ? 'CHIME'
        : 'AGORA'

    this.socket.send(
      JSON.stringify({
        action: 'getRaisedHands',
        channel_id: id,
        channel_type: 'EVENT',
        mediaServer,
        title: `event_${id}`
      })
    )
  }

  getChatHistory = () => {
    const { id } = this.props.match.params

    this.socket.send(
      JSON.stringify({
        action: 'historyChannel',
        channel_id: id,
        channel_type: 'EVENT'
      })
    )
  }

  getVoteStatus = () => {
    const {
      match: {
        params: { id }
      },
      user: {
        info: { id: userId }
      }
    } = this.props

    this.socket.send(
      JSON.stringify({
        action: 'checkPollVote',
        user_id: userId,
        channel_id: id,
        channel_type: 'EVENT'
      })
    )
  }

  getAskVoteStatus = () => {
    const {
      match: {
        params: { id }
      },
      user: {
        info: { id: userId }
      }
    } = this.props

    this.socket.send(
      JSON.stringify({
        action: 'getAskedPolls',
        user_id: userId,
        channel_id: id,
        channel_type: 'EVENT'
      })
    )
  }

  endChimeEvent = () => {
    const {
      match: {
        params: { id }
      }
    } = this.props

    this.socket.send(
      JSON.stringify({
        action: 'endConference',
        stream_id: `event_${id}`,
        channel_type: 'EVENT',
        channel_id: id
      })
    )
  }

  getAntInfo = () => {
    const { id } = this.props.match.params

    this.socket.send(
      JSON.stringify({
        action: 'getAntmediaInfo',
        channel_id: id,
        channel_type: 'EVENT'
      })
    )
  }

  getBreakoutInfo = () => {
    const { id } = this.props.match.params

    this.socket.send(
      JSON.stringify({
        action: 'breakoutRoom',
        channel_id: id,
        channel_type: 'EVENT',
        type: 'get'
      })
    )
  }

  getLiveCount = () => {
    const { id } = this.props.match.params
    const { webinarHostId } = this.state
    this.socket.send(
      JSON.stringify({
        action: 'sendCount',
        channel_type: 'EVENT',
        channel_id: id,
        stream_id: webinarHostId
      })
    )
  }

  clearAlert = () => {
    this.setState({
      isNoProfile: false
    })
  }

  sendMessage = content => {
    const {
      user: { info }
    } = this.props

    if (_.isEmpty(content)) return false
    // if (!info.name) {
    //   this.setState(
    //     {
    //       isNoProfile: true
    //     },
    //     () => {
    //       setTimeout(() => {
    //         if (this.state.isNoProfile) {
    //           this.clearAlert()
    //         }
    //       }, 5000)
    //     }
    //   )
    //   return
    // }

    const { userId, currentPlayTime } = this.state
    const { id } = this.props.match.params
    const user_id = info.id || userId

    this.socket.send(
      JSON.stringify({
        action: 'sendMessage',
        channel_type: 'EVENT',
        channel_id: id,
        user_id,
        user_name: info.name || info.username,
        time: currentPlayTime || 0,
        content
      })
    )
  }

  pollVote = (parentIdx, index) => {
    const { user } = this.props
    const { userId } = this.state
    const { id } = this.props.match.params
    const user_id = user.info.id || userId
    const {
      event: { poll_data_id: pollDataId }
    } = this.state

    this.setState({ canVote: false }, () => {
      this.socket.send(
        JSON.stringify({
          action: 'pollVote',
          channel_type: 'EVENT',
          channel_id: id,
          user_id,
          uid: pollDataId,
          time: 0,
          vote: {
            q: parentIdx,
            a: [index]
          }
        })
      )
    })
  }

  onAskQuestion = questIndex => {
    const { id } = this.props.match.params
    this.socket.send(
      JSON.stringify({
        action: 'askPoll',
        channel_type: 'EVENT',
        channel_id: id,
        currentPollIndex: questIndex
      })
    )
  }

  handleVideoDuration = e => {
    this.setState({
      videoDuration: e || 0
    })
  }

  handleVideoProgress = e => {
    this.setState({
      currentPlayTime: e.playedSeconds
    })
  }

  checkPastVideo = event => {
    if (event.is_live) {
      return false
    } else if (event.video_links && event.video_links.length > 0) {
      return event.is_finished
    }
    return false
  }

  handlSeekOrEnd = (isEnd = false) => {
    if (isEnd) {
      this.setState({
        isEnd: true
      })
    } else {
      this.setState({
        isEnd: false
      })
    }
  }

  handleStartVideo = (isPlay = false) => {
    if (isPlay) {
      this.setState({
        isPlay: true
      })
    } else {
      this.setState({
        isPlay: false
      })
    }
  }

  handleScroll = e => {
    const isScrollBottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight
    this.setState({
      isScrollBottom
    })
  }

  onCountDownFinish = () => {
    this.setState({
      isChatAble: true,
      showJoinButton: true
    })
  }

  onConferenceCountDownFinish = () => {
    this.setState({
      showJoinButton: true
    })
  }

  handleShowChat = () => {
    const { event } = this.state
    if (!event) return

    const now = new Date()
    const timeTillDate = moment(event.start_date_time).diff(now, 'minute')
    if (timeTillDate <= 10) {
      this.setState({
        isChatAble: true
      })
    }
  }

  handleShowJoinButton = () => {
    const { event } = this.state
    if (!event) return

    const now = new Date()
    const timeTillDate = moment(now).diff(event.start_date_time, 'minute')
    if (timeTillDate > -9 || timeTillDate === 0 || timeTillDate > 0) {
      this.setState({
        showJoinButton: true
      })
    }
  }

  displaySchedule = ({
    start_date_time: startDataTime,
    encoder,
    is_finished
  }) => {
    if (!startDataTime) return
    const eventStartTime = moment(startDataTime).format('lll')
    const format = moment(eventStartTime).format('DD MMM YYYY, HH: mm')
    if (
      !this.state.showJoinButton &&
      (encoder === 'chime_conference' ||
        encoder === 'live_assessment' ||
        encoder === 'agora_conference' ||
        encoder === 'peer2peer' ||
        encoder === 'zoom_meeting') &&
      !is_finished
    ) {
      this.handleShowJoinButton()
    }
    return `Schedule for ${format}`
  }

  displayCountdownTime = ({ start_date_time: startDataTime }) => {
    if (!startDataTime) return false
    const now = new Date()
    const timeTillDate = moment(startDataTime).diff(now, 'minute')

    return timeTillDate <= 1440
  }

  displayConferenceCountdownTime = ({ start_date_time: startDataTime }) => {
    if (!startDataTime) return false
    const now = new Date()
    // const timeToStart = moment().add(10, 'minute')
    const timeTillDate = moment(startDataTime).diff(now, 'minute')
    return timeTillDate <= -10
  }

  handleReadmore = (e, index) => {
    e.preventDefault()
    e.stopPropagation()
    const { messages, isRefresh } = this.state
    messages[index].isReadMore = !messages[index].isReadMore
    this.setState({
      messages,
      isRefresh: !isRefresh
    })
  }

  onChartClick = (...params) => {
    const { spaceGraphValue } = this.state
    const value = params[0].name - Math.ceil(spaceGraphValue)
    this.setState({
      currentPlayTime: value
    })
    this.playerRef.seekTo(value)
  }

  onStartBroadcast = () => {
    this.setState(prevState => {
      if (!prevState.broadcast) {
        window.addEventListener('beforeunload', e => {
          e.preventDefault()
          e.returnValue = ''
        })
        return {
          broadcast: true
        }
      } else {
        const { isConference, isCoachingSession } = prevState
        if (!isConference && !isCoachingSession) {
          this.onStreamStopped()
        }
        this.removelistener()
        return {
          showJoinButton: false,
          broadcast: false
        }
      }
    })
  }

  handleAgoraBroadcast = () => {
    this.setState({
      broadcast: false
    })
  }

  handleEndWebinar = () => {
    this.setState({ showConfirmModal: true })
  }

  handleCancelFinish = () => {
    this.setState({ broadcast: true, showConfirmModal: false })
  }

  handleConfirmFinish = () => {
    this.setState({ showConfirmModal: false, broadcast: false })
    this.onStreamStopped()
    this.removelistener()
  }

  removelistener = () => {
    window.removeEventListener('beforeunload', e => {
      e.preventDefault()
      e.returnValue = ''
    })
  }

  // calculate duration for single broadcast
  calculateDurationOnBroadcast = () => {
    const { event, isDurationExceed } = this.state
    const duration = event && event.duration
    const estimatedDuration = duration + 1
    const estimatedTimeToStop = moment()
      .add(estimatedDuration, 'm')
      .toDate()

    if (isDurationExceed === 'no') {
      this.setState({ estimatedTimeToStop })
    }
  }

  // calculate duration for coaching
  calculateStopDurationOnCoaching = () => {
    const { event, isDurationExceed } = this.state
    const duration = event && event.duration
    const estimatedTimeToStop = moment()
      .add(duration, 'm')
      .toDate()

    if (isDurationExceed === 'no') {
      this.setState({ estimatedTimeToStop })
    }
  }

  // calculate duration in coaching to show warn msg
  calculateWarnDurationOnCoaching = () => {
    const { event, coachingDurationToWarn } = this.state
    const duration = event && event.duration
    const estimatedDuration = duration - 2
    const estimatedTimeToWarn = moment()
      .add(estimatedDuration, 'm')
      .toDate()

    if (coachingDurationToWarn === 'no') {
      this.setState({ estimatedTimeToWarn })
    }
  }

  // calculate duration for conference to stop event
  calculateStopDurationOnConference = () => {
    const { event, isDurationExceed } = this.state
    const duration = event && event.duration
    const estimatedDuration = duration + 1
    const estimatedTimeToStop = moment(event.start_date_time)
      .add(estimatedDuration, 'm')
      .toDate()

    if (isDurationExceed === 'no') {
      this.setState({ estimatedTimeToStop })
    }
  }

  // calculate duration in conference to show warn msg
  calculateWarnDurationOnConference = () => {
    const { event, coachingDurationToWarn } = this.state
    const duration = event && event.duration
    const estimatedDuration = duration - 2
    const estimatedTimeToWarn = moment(event.start_date_time)
      .add(estimatedDuration, 'm')
      .toDate()

    if (coachingDurationToWarn === 'no') {
      this.setState({ estimatedTimeToWarn })
    }
  }

  onStreamStopped = () => this.setState({ isStreamStopped: true })

  handleVideoStatus = id => {
    this.setState(prevState => {
      const prevArr = prevState.controlVideos
      if (prevArr.length === 0) {
        return {
          controlVideos: [{ videoid: id, play: true }]
        }
      } else {
        let prevId = null
        prevArr.map(i => {
          prevId = i.videoid === id
          return prevId
        })
        if (!prevId) {
          const newItem = { videoid: id, play: true }
          return {
            controlVideos: [...prevState.controlVideos, { ...newItem }]
          }
        } else {
          const newArr = prevArr.filter(i => i.videoid !== id)
          return {
            controlVideos: [...newArr, { videoid: id, play: false }]
          }
        }
      }
    })
  }

  toggleEventProcessing = () => {
    this.setState(({ isProcessing }) => ({ isProcessing: !isProcessing }))
  }

  onStreamCount = count => this.setState({ countStream: count })

  setWebinarHost = id => this.setState({ webinarHostId: id })

  fineConnectionState = () => this.setState({ showBadConnectionMsg: false })

  badConnectionState = () => this.setState({ showBadConnectionMsg: true })

  handleRaiseHand = () =>
    this.setState({ isHandRaised: true, raiseHandRequesting: true }, () => {
      this.sendHandRaise()
    })

  onEventTime = val => {
    const { eventStartTime } = this.state
    if (!eventStartTime) {
      this.setState(
        {
          hasHostJoined: true,
          eventStartTime: val
        },
        () => {
          var to = moment(new Date())
          var from = moment(val)
          var duration = moment
            .duration(to.diff(from))
            .format('HH:mm:ss', { trim: false })

          this.setState(
            {
              timer: duration
            },
            () => {
              var startTime = duration
              setInterval(() => {
                const dn = moment
                  .duration(startTime)
                  .add(1, 'second')
                  .format('HH:mm:ss', { trim: false })
                startTime = dn
                this.setState({
                  timer: dn
                })
              }, 1000)
            }
          )
        }
      )
    }
  }

  onHandleAllowRaiseHand = passiveId => {
    this.setState(
      prevState => {
        const prevAllowedUsers = prevState.raiseHandAllowed
        if (
          _.findLastIndex(prevAllowedUsers, function(o) {
            return o === passiveId
          }) === -1
        ) {
          return {
            raiseHandAllowed: [...prevAllowedUsers, passiveId],
            raiseHandRequesting: false
          }
        }
      },
      () => {
        this.allowToAsk(passiveId)
      }
    )
  }

  onHandleDeclineRaiseHand = passiveId => {
    this.setState(
      prevState => {
        const newAllowedUsers = prevState.raiseHandAllowed.filter(
          r => r !== passiveId
        )
        return {
          raiseHandAllowed: newAllowedUsers,
          raiseHandRequesting: false,
          isHandRaised: false
        }
      },
      () => {
        this.declineToAsk(passiveId)
      }
    )
  }

  onHeaderItemClick = params => {
    const show = this.state.show
    switch (params) {
      case ITEMS.ATTACHMENT:
        this.setState({
          show: { ...showItems, attachments: !show.attachments }
        })
        return
      case ITEMS.OVERVIEW:
        this.setState({ show: { ...showItems, overview: !show.overview } })
        return
      case ITEMS.HOST:
        this.setState({ show: { ...showItems, hosts: !show.hosts } })
        return
      case ITEMS.NOTES:
        this.setState({ show: { ...showItems, notes: !show.notes } })
        return
      case ITEMS.PARTICIPANTS:
        this.setState({
          show: { ...showItems, participants: !show.participants }
        })
        return
      case ITEMS.ROOM:
        this.setState({
          show: { ...showItems, rooms: !show.rooms }
        })
        return
      case ITEMS.RATING:
        this.setState({
          show: { ...showItems, ratings: !show.ratings }
        })
        return
      case ITEMS.DISCUSSION:
        this.setState({
          show: { ...showItems, discussion: !show.discussion }
        })
        return
      default:
        this.setState({
          show: showItems
        })
        return
    }
  }

  checkShowItem = () => {
    const { show } = this.state
    var exists = Object.keys(show).some(function(k) {
      return show[k]
    })
    return exists
  }

  onParticipants = params => {
    this.setState({
      participantData: params
    })
  }

  onRoomCreate = () => {
    console.log('create room clicked on preview page')
    this.setState({
      createRoom: true
    })
  }

  onBreakoutRoom = isHost => {
    this.setState({
      isBreakoutHost: isHost
    })
  }

  onInvite = () =>
    this.setState({
      isInvite: !this.state.isInvite
    })

  onEventJoin = () => this.setState({ isEventJoined: true })

  handleAgoraMute = childData => {
    this.setState({
      previewStreamList: childData
    })
  }

  handleLocalMute = childData => {
    this.setState({
      previewLocalStream: childData
    })
  }

  render() {
    const {
      t,
      match: {
        params: { id }
      },
      // location: { search },
      user: {
        info: {
          id: userId,
          email: userEmail,
          active_organization_id: userOrg,
          name,
          username,
          organization
        }
      },
      i18n: { language },
      history
    } = this.props
    const {
      isLoading,
      event,
      messages,
      pollValues,
      userPollValues,
      isStreaming,
      isNoProfile,
      currentPlayTime,
      isEnd,
      isRefresh,
      isScrollBottom,
      isChatAble,
      broadcast,
      isStreamStopped,
      isDurationExceed,
      isWebStream,
      totalViewers,
      controlVideos,
      isCoachingSession,
      hostEmails,
      isConference,
      countStream,
      isPastEvent,
      showConfirmModal,
      isPlay,
      showBadConnectionMsg,
      coachingDurationToWarn,
      webinarHostId,
      allowJoin,
      isEventCrash,
      isPreparingConf,
      userGroup,
      canVote,
      isHandRaised,
      raiseHandRequesting,
      raiseHandAllowed,
      requestedPassiveUsers,
      currentAskedPoll,
      askedPolls,
      showJoinButton,
      rooms,
      activeKey,
      show,
      participantData,
      isEventJoined,
      isBreakoutHost,
      allParticipants,
      isInvite,
      previewStreamList,
      previewLocalStream,
      timer
    } = this.state

    const isHost = event
      ? userId === event.host_id && event.encoder === 'other_webrtc'
      : false

    const isZoomHost = event
      ? userId === event.host_id && event.encoder === 'zoom_meeting'
      : false

    const isFinished = event && event.is_finished
    const canStream =
      event && event.is_finished === false
        ? event.video_links.length === 0
          ? true
          : true
        : false

    const showOverlay =
      event && event.encoder
        ? event.encoder === 'other_webrtc' ||
          event.encoder === 'conference' ||
          event.encoder === 'chime_conference' ||
          event.encoder === 'peer2peer' ||
          event.encoder === 'stream_from_phone' ||
          event.encoder === 'live_assessment'
        : false

    const hasOrg = !!(
      event &&
      event.setting &&
      isConference &&
      _.findIndex(event.setting.organization_ids, o => {
        return o === userOrg || o === 1
      }) > -1
    )

    const isConferenceHost =
      event && event.setting && event.setting.organization_ids.length > 0
        ? hasOrg
          ? hostEmails && hostEmails.filter(i => i.id === userId).length > 0
          : false
        : false

    const hasGroup = !!(
      event &&
      !isConferenceHost &&
      event.setting &&
      isConference &&
      intersection(event.setting.group_ids, userGroup).length > 0
    )
    // const isConferenceHost = event &&
    //   event.setting && event.setting.group_ids.length > 0
    //   ? hasOrg
    //     ? hostEmails.filter(i => i.id === userId).length > 0
    //     : false
    //   : false

    // const hostEmailIDs = hostEmails.filter(i => i.id === userId).length > 0

    const confCreator = event ? userId === event.host_id && isConference : false

    const isCoachingCreator = !!(
      event &&
      userId === event.host_id &&
      isCoachingSession
    )

    const isCoachingParticipant = !!(
      event &&
      event.setting &&
      userEmail === event.setting.emails[0] &&
      isCoachingSession
    )

    const conferencePublic =
      event && event.setting && !event.setting.is_host_only
    const isConferenceParticipant =
      event && event.setting && event.setting.group_ids.length > 0
        ? hasGroup
          ? !!(conferencePublic && hasGroup && !isConferenceHost)
          : false
        : !!(conferencePublic && hasOrg && !isConferenceHost)
    const participantName = name ? name : username

    let preparingMsg

    if (event && event.encoder === 'other_webrtc') {
      preparingMsg = 'Hold on webinar starting shortly . . . '
    } else if (event && event.encoder === 'conference') {
      preparingMsg = 'Hold on conference starting shortly . . . '
    } else if (event && event.encoder === 'peer2peer') {
      preparingMsg = 'Hold on Coaching session starting shortly . . . '
    } else {
      preparingMsg = null
    }

    const isAssessment = event && event.encoder === 'live_assessment'
    const isAssessmentHost =
      (event && event.host_ids && event.host_ids.includes(userId)) ||
      userId === (event && event.host_id)

    const blueSvg =
      'invert(27%) sepia(66%) saturate(3016%) hue-rotate(211deg) brightness(101%) contrast(97%)'

    const videos = event && event.video_links

    const skillJourney =
      this.props.location.state && this.props.location.state.skillJourney
    const skillJourneyId =
      this.props.location.state && this.props.location.state.skillJourneyId

    const hasBreakoutRoom =
      this.props.location.state && this.props.location.state.hasBreakoutRoom
    const breakoutRoomId =
      this.props.location.state && this.props.location.state.eventId
    const isChime =
      event &&
      event.organization.media_service === 'CHIME' &&
      event.event_type !== 'public_conference'
    return (
      <Fragment>
        {(skillJourney || hasBreakoutRoom) && (
          <TopPageLink
            page={hasBreakoutRoom ? t('v4:main_event') : t('v4:skill')}
            path={
              hasBreakoutRoom
                ? `/events/${breakoutRoomId}`
                : `/skill-journey/${skillJourneyId}`
            }
            hasBreakoutRoom
          />
        )}
        {isLoading && <Spinner />}

        {!isLoading && !event && (
          <EmptyMessage
            title={t('v2:sorry') + ' ' + t('errors:not_found_event')}
            buttonTitle={t('general:back_to') + ' ' + t('navigation:training')}
            onClickAction={() => history.push('/training')}
          />
        )}

        {coachingDurationToWarn === 'yes' &&
          message.warn(`${config.confDurationExceed}`)}

        {isPreparingConf && preparingMsg && message.success(`${preparingMsg}`)}

        {showBadConnectionMsg && message.warn('Trying to reconnect again...')}

        {isEventCrash &&
          message.warn(
            'This event is crashed. You need to create new event. Sorry for the inconvenience.'
          )}

        {isDurationExceed === 'yes' &&
          message.error(t('events:duration_exceed_message'))}

        {!isLoading && event && (
          <section className="section eve-wrap">
            <div className="event-head">
              <div className="event-back">
                <TopPageLink page="Training" path="/training" />
              </div>
            </div>
            <div className="event" ref={() => {}}>
              <div className="event__inner">
                <div className="event__content__container">
                  <div className="event__stream__container">
                    <PreviewHeader
                      event={event}
                      userId={userId}
                      rooms={rooms}
                      ITEMS={ITEMS}
                      show={show}
                      onHeaderItemClick={this.onHeaderItemClick}
                    />
                    {!this.checkPastVideo(event) && !broadcast && (
                      <>
                        <div
                          className="event-stream__cover-pic"
                          style={{
                            backgroundImage: `url(${event.cover &&
                              event.cover.link})`
                          }}
                        >
                          {event.start_date_time && (
                            <div className="__schedule">
                              {this.displaySchedule(event)}
                            </div>
                          )}
                        </div>
                        <PreviewStatus
                          App={this}
                          isStreamStopped={isStreamStopped}
                          allowJoin={allowJoin}
                          broadcast={broadcast}
                          showJoinButton={showJoinButton}
                          onJoin={this.onEventJoin}
                          userId={userId}
                          isCoachingSession={isCoachingSession}
                          isConferencePublic={conferencePublic}
                          hasBreakoutRoom={hasBreakoutRoom}
                        />
                      </>
                    )}

                    <Fragment>
                      {broadcast && !isCoachingSession && !isConference && (
                        <div
                          className={
                            isSafari ? 'live__info_safari' : 'live__info'
                          }
                        >
                          <div className="live__block">
                            <img src={online} alt="viewers" />
                            <span style={{ color: '#EC2729' }}>
                              {totalViewers}
                            </span>
                          </div>
                          <div className="live__block">
                            {language === 'en-US' && (
                              <img
                                src={liveNow}
                                alt="live-now"
                                style={{ width: '90px', margin: '0px' }}
                              />
                            )}
                            {language === 'thai' && (
                              <img
                                src={thaiLiveNow}
                                alt="live-now"
                                style={{ width: '90px', margin: '0px' }}
                              />
                            )}
                            {language === 'hebrew' && (
                              <img
                                src={herbrewLiveNow}
                                alt="live-now"
                                style={{ width: '90px', margin: '0px' }}
                              />
                            )}
                          </div>
                          <span className="white fz18 lh20">
                            {event.title}{' '}
                          </span>
                        </div>
                      )}

                      {broadcast && event && isConference && (
                        <div
                          className={
                            isSafari ? 'live__info_safari' : 'live__info'
                          }
                        >
                          {language !== 'thai' && language !== 'hebrew' && (
                            <div className="live__block">
                              <img
                                src={liveIcon}
                                alt="live-now"
                                style={{ height: '20px' }}
                              />
                              {conferencePublic ? (
                                <img
                                  src={nowLivePublic}
                                  alt="live-public"
                                  style={{ width: '90px' }}
                                />
                              ) : (
                                <img
                                  src={liveNowPrivate}
                                  alt="live-private"
                                  style={{ width: '90px' }}
                                />
                              )}

                              {conferencePublic && (
                                <span
                                  style={{
                                    color: '#EC2729',
                                    marginLeft: '8px'
                                  }}
                                >
                                  {countStream}
                                </span>
                              )}
                              {!conferencePublic && (
                                <span
                                  style={{
                                    color: '#EC2729',
                                    marginLeft: '4px'
                                  }}
                                >
                                  {countStream}
                                </span>
                              )}
                              {timer && <b className="timer">{timer}</b>}
                            </div>
                          )}
                          {language === 'thai' && (
                            <div className="live__block">
                              <img
                                src={liveIcon}
                                alt="live-now"
                                style={{ height: '20px' }}
                              />
                              {conferencePublic ? (
                                <img
                                  src={thaiPublic}
                                  alt="live-public"
                                  style={{ width: '90px' }}
                                />
                              ) : (
                                <img
                                  src={thaiPrivate}
                                  alt="live-private"
                                  style={{ width: '90px' }}
                                />
                              )}
                              {conferencePublic && (
                                <span
                                  style={{
                                    color: '#EC2729',
                                    marginLeft: '8px'
                                  }}
                                >
                                  {countStream}
                                </span>
                              )}
                              {!conferencePublic && (
                                <span
                                  style={{
                                    color: '#EC2729',
                                    marginLeft: '4px'
                                  }}
                                >
                                  {countStream}
                                </span>
                              )}
                              {timer && <b className="timer">{timer}</b>}
                            </div>
                          )}
                          {language === 'hebrew' && (
                            <div className="live__block">
                              {conferencePublic ? (
                                <img
                                  src={herbrewPublic}
                                  alt="live-public"
                                  style={{ width: '90px' }}
                                />
                              ) : (
                                <img
                                  src={herbrewPrivate}
                                  alt="live-private"
                                  style={{ width: '90px' }}
                                />
                              )}
                              {conferencePublic && (
                                <span
                                  style={{
                                    color: '#FF4B55',
                                    marginLeft: '8px'
                                  }}
                                >
                                  {countStream}
                                </span>
                              )}
                              {!conferencePublic && (
                                <span
                                  style={{
                                    color: '#FF4B55',
                                    marginLeft: '4px'
                                  }}
                                >
                                  {countStream}
                                </span>
                              )}
                              {timer && <b className="timer">{timer}</b>}
                            </div>
                          )}
                        </div>
                      )}
                      {isStreaming && !isHost ? (
                        <div>
                          <div className="event-stream-item">
                            {!isWebStream && (
                              <div
                                className="event-stream__video"
                                id="stream"
                              />
                            )}
                            {showOverlay && (
                              <AntPlayer
                                streamId={webinarHostId}
                                t={t}
                                userId={userId}
                                orgId={userOrg}
                                eventId={id}
                                setHostId={this.setWebinarHost}
                                handleBroadcastChange={this.onStartBroadcast}
                              />
                            )}
                          </div>
                        </div>
                      ) : (
                        <Fragment>
                          <div className="event-stream-item">
                            {/* {broadcast && event && (
                              <Counter
                                t={t}
                                event={event}
                                isSafari={isSafari}
                                eventStartTime={eventStartTime}
                                totalCount={countStream || totalViewers}
                              />
                            )} */}
                            {/* if past live event have streaming videos will display instead of thumnail image */}
                            {this.checkPastVideo(event) && !broadcast && (
                              <>
                                <SlickCarousel
                                  handleChangeSlide={this.handleVideoStatus}
                                  videos={videos}
                                >
                                  {event.is_vimeo
                                    ? videos.map(video => {
                                        let play = false
                                        controlVideos.length > 0 &&
                                          controlVideos.map(c => {
                                            if (c.videoid === video.id) {
                                              play = c.play
                                            }
                                            return play
                                          })
                                        return (
                                          <ReactPlayer
                                            className="react-vid-player"
                                            ref={rp => {
                                              this.setPlayerRef(rp)
                                            }}
                                            url={video.link}
                                            height="480px"
                                            controls
                                            playing={play}
                                            onDuration={e => {
                                              this.handleVideoDuration(e)
                                            }}
                                            onProgress={e => {
                                              this.handleVideoProgress(e)
                                            }}
                                            onEnded={() => {
                                              this.handlSeekOrEnd(true)
                                            }}
                                            onSeek={() => {
                                              this.handlSeekOrEnd(false)
                                            }}
                                            onPlay={() => {
                                              this.handleVideoStatus(video.id)
                                            }}
                                            onPause={() => {
                                              this.handleVideoStatus(video.id)
                                            }}
                                            light
                                          />
                                        )
                                      })
                                    : videos.map(video => (
                                        <ReactPlayer
                                          className="react-vid-player"
                                          ref={rp => {
                                            this.setPlayerRef(rp)
                                          }}
                                          url={video}
                                          width="100%"
                                          onDuration={e => {
                                            this.handleVideoDuration(e)
                                          }}
                                          controls
                                          playing={isPlay}
                                          onProgress={e => {
                                            this.handleVideoProgress(e)
                                          }}
                                          onEnded={() => {
                                            this.handlSeekOrEnd(true)
                                          }}
                                          onSeek={() => {
                                            this.handlSeekOrEnd(false)
                                          }}
                                          onReady={() => {
                                            this.handleStartVideo(true)
                                          }}
                                          onPause={() => {
                                            this.handleStartVideo(false)
                                          }}
                                          light
                                        />
                                      ))}
                                </SlickCarousel>
                                <PreviewStatus
                                  App={this}
                                  isStreamStopped={isStreamStopped}
                                  allowJoin={false}
                                  broadcast={broadcast}
                                  showJoinButton={false}
                                  userId={userId}
                                  isCoachingSession={isCoachingSession}
                                  isConferencePublic={conferencePublic}
                                />
                              </>
                            )}
                            {event.encoder === 'zoom_meeting' && (
                              <ZoomSection
                                onJoin={isEventJoined}
                                allowJoin={showJoinButton}
                                isStreamStopped={isStreamStopped}
                                hostURL={
                                  event.zoom_meeting_start_url
                                    ? event.zoom_meeting_start_url
                                    : ''
                                }
                                participantURL={
                                  event.zoom_meeting_join_url
                                    ? event.zoom_meeting_join_url
                                    : ''
                                }
                                isHost={isZoomHost}
                                isParticipant={!isZoomHost}
                              />
                            )}

                            {isChime && canStream && (
                              <ChimeConference
                                t={t}
                                App={this}
                                eventId={id}
                                event={event}
                                userId={userId}
                                userOrg={userOrg}
                                history={history}
                                onJoin={isEventJoined}
                                isHandRaised={isHandRaised}
                                allowJoin={showJoinButton}
                                username={participantName}
                                isConfCreator={confCreator || isCoachingCreator}
                                organization={organization}
                                hostEmails={event.host_emails}
                                allowedUser={raiseHandAllowed}
                                isStreamStopped={isStreamStopped}
                                isConferenceHost={
                                  isConferenceHost ||
                                  isCoachingCreator ||
                                  isCoachingParticipant
                                }
                                isConferenceParticipant={
                                  isConferenceParticipant || !isCoachingSession
                                }
                                count={countStream || totalViewers}
                                raiseHandRequesting={raiseHandRequesting}
                                onEventTime={this.onEventTime}
                                stopstream={this.stopStream}
                                streamCount={this.onStreamCount}
                                endChimeEvent={this.endChimeEvent}
                                onStreamStopped={this.onStreamStopped}
                                handleBroadcastChange={this.onStartBroadcast}
                                onLeaveEvent={() =>
                                  this.setState({ isEventJoined: false })
                                }
                                hasBreakoutRoom={hasBreakoutRoom}
                              />
                            )}
                            {/* {isRecording &&
                              false &&
                              canStream &&
                              sessionRole && (
                                <OneTwoOne
                                  conferenceId={'room_' + id}
                                  eventId={id}
                                  orgId={userOrg}
                                  allowJoin={allowJoin}
                                  streamCount={this.onStreamCount}
                                  countStream={countStream}
                                  handleBroadcastChange={this.onStartBroadcast}
                                  onStreamStopped={this.onStreamStopped}
                                  isStreamStopped={isStreamStopped}
                                  stopstream={this.stopStream}
                                  isConferenceHost={
                                    isCoachingParticipant || isCoachingCreator
                                  }
                                  t={t}
                                  isConfCreator={isCoachingCreator}
                                  userId={userId}
                                  initSocket={this.initSocket}
                                  isSocketClose={isSocketClose}
                                  isBroadcast={broadcast}
                                  name={participantName}
                                  calculateStopTime={
                                    this.calculateStopDurationOnConference
                                  }
                                  calculateWarnTime={
                                    this.calculateWarnDurationOnConference
                                  }
                                  badConnectionState={this.badConnectionState}
                                  fineConnectionState={this.fineConnectionState}
                                  hostEmails={event.host_emails}
                                  userOrg={userOrg}
                                  event={event}
                                  isJoined={isEventJoined}
                                />
                              )} */}

                            {!isChime && canStream && (
                              <AgoraCall
                                t={t}
                                App={this}
                                eventId={id}
                                event={event}
                                userId={userId}
                                userOrg={userOrg}
                                onJoin={isEventJoined}
                                isInvite={isInvite}
                                isConferenceHost={
                                  isConferenceHost || isCoachingSession
                                }
                                isConfCreator={confCreator || isCoachingCreator}
                                allowJoin={showJoinButton}
                                username={participantName}
                                organization={organization}
                                isHandRaised={isHandRaised}
                                allowedUser={raiseHandAllowed}
                                hostEmails={event.host_emails}
                                isStreamStopped={isStreamStopped}
                                isConferenceParticipant={
                                  isConferenceParticipant || !isCoachingSession
                                }
                                raiseHandRequesting={raiseHandRequesting}
                                onStreamStopped={this.onStreamStopped}
                                handleBroadcastChange={this.onStartBroadcast}
                                stopstream={this.stopStream}
                                endChimeEvent={this.endChimeEvent}
                                onEventTime={this.onEventTime}
                                streamCount={this.onStreamCount}
                                onLeaveEvent={() =>
                                  this.setState({ isEventJoined: false })
                                }
                                handleAgoraMute={this.handleAgoraMute}
                                handleLocalMute={this.handleLocalMute}
                                handleAgoraBroadcast={this.handleAgoraBroadcast}
                              />
                            )}
                          </div>
                        </Fragment>
                      )}
                    </Fragment>
                  </div>
                </div>
                <div className="event__sidebar">
                  <div className="event-sidebar__section">
                    {this.checkShowItem() && (
                      <SidebarModal
                        userId={userId}
                        ITEMS={ITEMS}
                        event={event}
                        controller={show}
                        rooms={rooms}
                        isChime={isChime}
                        allParticipants={allParticipants}
                        isBreakoutHost={isBreakoutHost}
                        participantData={participantData}
                        onClose={this.onHeaderItemClick}
                        onRoomCreate={this.onRoomCreate}
                        onInvite={this.onInvite}
                        isConferenceParticipant={isConferenceParticipant}
                        showPassiveUsers={isConferenceHost && broadcast}
                        participantSize={
                          requestedPassiveUsers
                            ? requestedPassiveUsers.length
                            : 0
                        }
                        participants={
                          requestedPassiveUsers ? requestedPassiveUsers : []
                        }
                        handleAllow={this.onHandleAllowRaiseHand}
                        handleDecline={this.onHandleDeclineRaiseHand}
                        raiseHandAllowed={
                          raiseHandAllowed ? raiseHandAllowed : []
                        }
                        previewStreamList={previewStreamList}
                        previewLocalStream={previewLocalStream}
                      />
                    )}
                    <div className="card-container">
                      {!isAssessment && (
                        <Tabs
                          type="card"
                          activeKey={activeKey}
                          onChange={activeKey => this.setState({ activeKey })}
                        >
                          <TabPane
                            tab={
                              <span className="event-sidebar__section_tab-head">
                                <img
                                  alt=""
                                  src={DiscussionIcon}
                                  style={{
                                    filter:
                                      activeKey === '2'
                                        ? 'brightness(0) saturate(100%)'
                                        : null,
                                    marginRight: '1rem'
                                  }}
                                />
                                {t('v2:live_discussion')}
                              </span>
                            }
                            key="1"
                            disabled={isAssessment}
                          >
                            <Chat
                              available={event.is_chat}
                              messages={messages}
                              sendMessage={this.sendMessage}
                              currentPlayTime={currentPlayTime}
                              isPast={isPastEvent}
                              handleReadmore={this.handleReadmore}
                              handleScroll={this.handleScroll}
                              isEnd={isEnd}
                              isRefresh={isRefresh}
                              isScrollBottom={isScrollBottom}
                              isChatAble={isChatAble}
                            />
                          </TabPane>
                          <TabPane
                            tab={
                              <span className="event-sidebar__section_tab-head">
                                <img
                                  alt=""
                                  src={PollIcon}
                                  style={{
                                    filter: activeKey === '1' ? null : blueSvg,
                                    marginRight: '1rem'
                                  }}
                                />
                                {t('v2:poll')}
                              </span>
                            }
                            disabled={isAssessment}
                            key="2"
                          >
                            {userId === event.host_id ? (
                              <Poll
                                available={event.is_poll && event.poll}
                                data={event.poll}
                                pollValues={pollValues}
                                onClick={this.pollVote}
                                canVote={canVote}
                                onAskQuestion={this.onAskQuestion}
                                currentAskedPoll={currentAskedPoll}
                                askedPolls={askedPolls}
                              />
                            ) : (
                              <CurrentPoll
                                available={event.is_poll && event.poll}
                                data={event.poll}
                                pollValues={pollValues}
                                userPollValues={userPollValues}
                                onClick={this.pollVote}
                                canVote={canVote}
                                currentAskedPoll={currentAskedPoll}
                                askedPolls={askedPolls}
                                isFinished={isFinished}
                              />
                            )}
                          </TabPane>
                          {isNoProfile && (
                            <div className="wrap_no_profile">
                              <span>{general.no_profile}</span>
                            </div>
                          )}
                        </Tabs>
                      )}
                      {(isAssessmentHost ||
                        (!isAssessmentHost && event.is_finished) ||
                        userId === event.host_id) &&
                        isAssessment && (
                          <Tabs type="card" activeKey="1">
                            <TabPane tab="LIVE ASSESSMENT" key="1">
                              <LiveAssessment
                                id={event.id}
                                event={event}
                                socket={this.socket}
                                assessments={event.live_assessments}
                                isFinished={event.is_finished}
                                logs={event.assessment_duration}
                                isHost={isAssessmentHost}
                                scores={event.assessment_score_info || {}}
                                {...this.props}
                                broadcast={broadcast}
                                seekVideoToTime={time =>
                                  this.playerRef.seekTo(time)
                                }
                              />
                            </TabPane>
                          </Tabs>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}

        {showConfirmModal && (
          <Modal
            title={t('labels:warning')}
            visible={showConfirmModal}
            onOk={this.handleConfirmFinish}
            onCancel={this.handleCancelFinish}
          >
            <h3>{t('events:confirm_message')}</h3>
          </Modal>
        )}
      </Fragment>
    )
  }
}

export default withTranslation()(Preview)
