import { connect } from 'react-redux'
import DiscussionListen from './DiscussionListen'
import { withRouter } from 'react-router-dom';

const mapStateToProps = state => {
    return {
        user: state.user,
    };
};


export default withRouter(connect(mapStateToProps)(DiscussionListen));
