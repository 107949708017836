import React, { useState, useEffect } from 'react'
import { Steps } from 'antd'
import { withRouter } from 'react-router'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import './style.scss'
import NewHeader from '../../../../../components/NewHeader'

const { Step } = Steps

const tabsMatrix = t => ({
  edit: [
    {
      tab: t('tabs:course'),
      key: '/courses/:id/edit'
    },
    {
      tab: t('tabs:publish'),
      key: '/courses/:id/edit/publish'
    }
  ],
  create: [
    {
      tab: t('tabs:course'),
      key: '/course-create'
    },
    {
      tab: t('tabs:publish'),
      key: '/course-create/publish'
    }
  ]
})

const CourseCreateTopPanel = ({ location, history, id, mode }) => {
  const { t } = useTranslation()

  const forceUpdate = React.useReducer(() => ({}), {})[1]
  const [visitedSteps, setVisitedSteps] = useState([])

  useEffect(() => {
    const refresh = () => forceUpdate()
    window.addEventListener('resize', refresh)
    return () => {
      window.removeEventListener('resize', refresh)
    }
  }, [forceUpdate])

  const getActiveIndex = (index, activeKey) => {
    const tabs = tabsMatrix(t)[mode] || []
    const activeIndex = _.findIndex(tabs, tab => tab.key === activeKey)
    return index <= activeIndex
  }

  let { pathname } = location
  let activeKey = pathname

  if (pathname.includes('/course-create/lesson')) {
    activeKey = '/course-create/lesson'
  } else if (pathname.includes('/course-create/page')) {
    activeKey = '/course-create/page-builder'
  }

  if (pathname.includes('/edit')) {
    activeKey = '/courses/:id/edit'
  }

  if (pathname.includes('/edit/lesson')) {
    activeKey = '/courses/:id/edit/lesson'
  } else if (pathname.includes('/edit/page')) {
    activeKey = '/courses/:id/edit/page-builder'
  } else if (pathname.includes('/edit/publish')) {
    activeKey = '/courses/:id/edit/publish'
  }

  const tabs = tabsMatrix(t)[mode] || []
  const currentStepIndex = _.findIndex(tabs, tab => tab.key === activeKey)

  useEffect(() => {
    if (!visitedSteps.includes(currentStepIndex) && currentStepIndex !== -1) {
      setVisitedSteps(prev => [...prev, currentStepIndex])
    }
  }, [currentStepIndex, visitedSteps])

  return (
    <div className="course-header">
      {tabs.length > 0 && (
        <Steps
          current={currentStepIndex}
          onChange={key => {
            if (key <= currentStepIndex) {
              history.push(tabs[key].key.replace(':id', id))
            }
          }}
        >
          {tabs.map((i, index) => (
            <Step
              title={
                <span
                  className={getActiveIndex(index, activeKey) ? 'finished' : ''}
                >
                  {i.tab}
                </span>
              }
              key={i.key}
              disabled={
                index !== currentStepIndex && !visitedSteps.includes(index)
              }
            />
          ))}
        </Steps>
      )}
      <NewHeader />
    </div>
  )
}

export default withRouter(CourseCreateTopPanel)
