import React, { Component, Fragment } from 'react'
import { Audio, Stream } from './components'
import { builder } from '../../../../constants'
import './Preview.scss'
import { pdfReader, pptImage, textIcon } from '../../internal/Gallery/images'

const { types } = builder
const ImagePreview = React.forwardRef((props, ref) => {
  const {
    library: { link }
  } = props

  return (
    <div
      className="preview preview_image"
      style={{ backgroundImage: 'url(' + link + ')' }}
      ref={ref}
    />
  )
})

const { IMAGE, ARIMAGE, BADGE, CERTIFICATE, LOGO, SIGNATURE, ICON } = types
const imageTypes = [IMAGE, ARIMAGE, BADGE, CERTIFICATE, LOGO, SIGNATURE, ICON]

const VideoPreview = props => {
  const { link } = props.library
  const isVimeo = link.indexOf('vimeo') > 0

  if (isVimeo) {
    return (
      <div className="embed-container">
        <iframe
          title="video"
          src={link + '?color=0068FF&title=0&byline=0&portrait=0'}
          frameBorder="0"
          webkitAllowFullScreen
          mozallowfullscreen
          allowFullScreen
        />
      </div>
    )
  }

  return (
    <div className="preview preview_video embed-container">
      <video controls style={{ width: '100%', height: '100%' }}>
        <source src={link} />
      </video>
    </div>
  )
}

class Preview extends Component {
  constructor() {
    super()
    this.state = { text: '' }
  }

  componentDidMount() {
    const { library } = this.props
    if (library.media_format === types.TEXT && library.link) {
      this.fetchTextFile(library.link)
    }
  }

  fetchTextFile = transcribedLink => {
    fetch(transcribedLink)
      .then(response => {
        response.text().then(text => {
          this.setState({ text: text })
        })
      })
      .catch(err => {
        this.setState({ text: 'Text preview is not available.' })
      })
  }

  checkIsPPT = (fileMimeType = '') =>
    fileMimeType.includes('powerpoint') || fileMimeType.includes('presentation')

  checkIsDocx = (fileMimeType = '') =>
    fileMimeType.includes(
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    )

  render() {
    const { library } = this.props
    return (
      <div style={{ height: '500px' }}>
        {imageTypes.some(item => item === library.media_format) && (
          <ImagePreview library={library} ref={this.props.innerRef} />
        )}
        {(library.media_format === types.VIDEO ||
          library.media_format === types.VIDEO_ANTMEDIA) && (
          <VideoPreview library={library} />
        )}
        {library.media_format === types.AUDIO && <Audio library={library} />}
        {library.media_format === types.VIDEO_STREAM && (
          <Stream library={library} />
        )}
        {library.media_format === types.APPLICATION && (
          <div className="preview">
            <a href={library.link} target="_blank" rel="noopener noreferrer">
              <img
                rel="noreferrer"
                src={
                  this.checkIsDocx(library.media_mime_type)
                    ? textIcon
                    : this.checkIsPPT(library.media_mime_type)
                    ? pptImage
                    : pdfReader
                }
                alt="library link"
              />
            </a>
          </div>
        )}
        {library.media_format === types.TEXT && (
          <Fragment>
            <div className="preview text-content">{this.state.text}</div>
          </Fragment>
        )}
        {/* {library.media_format === types.PDF && (
          <Fragment>
            {
              <Modal title="Basic Modal" visible>
                <PDFViewer
                  document={{
                    // url: 'https://arxiv.org/pdf/quant-ph/0410100.pdf'
                    url: library.link
                  }}
                />
              </Modal>
            }
          </Fragment>
        )} */}
      </div>
    )
  }
}

export default React.forwardRef((props, ref) => (
  <Preview innerRef={ref} {...props} />
))
