import http from './http'

function getBadgesById(id) {
  return http({
    method: 'GET',
    url: '/users/' + id + '/badges'
  })
}

export { getBadgesById }
