import React, { Component } from 'react'
import { Button, Checkbox, Modal } from 'antd'
import _ from 'lodash'
import { Field } from '../../../../../../../styles'
import { Organization, Groups } from './styled'
import { withTranslation } from 'react-i18next'
import './styles.scss'

class GroupPicker extends Component {
  state = {
    modalVisible: false,
    modalOrganization: null
  }

  handleChange = id => {
    const { onChange } = this.props
    if (onChange) {
      onChange(id)
    }
  }

  handleSelectAll = organizationId => {
    const { onChangeAll } = this.props
    if (onChangeAll) {
      onChangeAll(organizationId)
    }
  }

  getFirstLetter = title => {
    return title ? title.charAt(0).toUpperCase() : ''
  }

  openModal = organization => {
    this.setState({
      modalVisible: true,
      modalOrganization: organization
    })
  }

  closeModal = () => {
    this.setState({
      modalVisible: false,
      modalOrganization: null
    })
  }
  handleConfirm = () => {
    this.closeModal()
  }

  render() {
    const { list, selectedGroups, t } = this.props
    const { modalVisible, modalOrganization } = this.state

    let filteredList = []
    if (!_.isEmpty(list)) {
      filteredList = list.filter(i => (i.groups || []).length)
    }

    return (
      <Field>
        <Field.Label>{t('labels:select_organization')}</Field.Label>
        <Field.Control>
          <div className="org-group-row">
            {filteredList.map(organization => {
              const { groups = [], id, logo, title } = organization
              const selectedOrgGroups = groups.filter(i =>
                selectedGroups.includes(i.id)
              )
              const checkedOrganization =
                selectedOrgGroups.length === groups.length
              const indeterminateOrganizationsStatus =
                selectedOrgGroups.length &&
                selectedOrgGroups.length < groups.length

              return (
                <div className="org-group-list" key={id}>
                  <div
                    className={`org-group-btn`}
                    onClick={() => this.openModal(organization)}
                  >
                    <Organization.Checkbox>
                      <Checkbox
                        checked={checkedOrganization}
                        indeterminate={indeterminateOrganizationsStatus}
                      />
                    </Organization.Checkbox>
                    {logo ? (
                      <Organization.Image src={logo.link} alt={title} />
                    ) : (
                      <div className="org-group-initial">
                        {this.getFirstLetter(title)}
                      </div>
                    )}
                    <Organization.Name>{title}</Organization.Name>
                  </div>

                  {modalVisible &&
                    modalOrganization &&
                    modalOrganization.id === organization.id && (
                      <Modal
                        title={`Groups for ${modalOrganization.title}`}
                        visible={modalVisible}
                        onCancel={this.closeModal}
                        className="org-of-marketplace"
                        footer={[
                          <Button key="cancel" onClick={this.closeModal}>
                            Cancel
                          </Button>,
                          <Button
                            key="confirm"
                            type="primary"
                            onClick={this.handleConfirm}
                          >
                            Confirm
                          </Button>
                        ]}
                      >
                        <div className="org-all-list">
                          {groups.map(group => (
                            <Groups.Item key={group.id}>
                              <div
                                className="org-group-item"
                                onClick={() => this.handleChange(group.id)}
                              >
                                <Checkbox
                                  checked={selectedGroups.includes(group.id)}
                                />
                                <span>{group.title}</span>
                              </div>
                            </Groups.Item>
                          ))}
                        </div>
                      </Modal>
                    )}
                </div>
              )
            })}
          </div>
        </Field.Control>
      </Field>
    )
  }
}

export default withTranslation()(GroupPicker)
