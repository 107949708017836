import React from 'react'
import { Input, Form, Col, Select } from 'antd'

import './index.css'

const { Option } = Select

const TopSection = ({ props, onChangeAuthor }) => {
  const {
    t,
    form: { getFieldDecorator },
    mazeBuilder: { mazeOrganiser, mazeId }
  } = props
  return (
    <div className="mp-top_section_row">
      <Col md={7}>
        <Form.Item colon={false} required={false} label={t('labels:maze id')}>
          {getFieldDecorator('maze_id', {
            initialValue: mazeId
          })(<Input size="large" maxLength={100} type="number" disabled />)}
        </Form.Item>
      </Col>

      <Col md={7}>
        <Form.Item label={t('labels:status')} colon={false} required={false}>
          {/* {getFieldDecorator('status', {
            initialValue: 'pending'
          })( */}
          <Select showSearch size="large" disabled value="pending">
            <Option value="pending">Pending</Option>
            <Option value="published">Published</Option>
          </Select>
          {/* )} */}
        </Form.Item>
      </Col>

      <Col md={7}>
        <Form.Item label={t('labels:author')} colon={false} required={false}>
          {getFieldDecorator('organiser', {
            rules: [{ required: true, message: t('v4:author_name') }],
            initialValue: mazeOrganiser
          })(
            <Input
              addonBefore={t('general:by')}
              size="large"
              maxLength={100}
              placeholder={t('placeholders:organiser')}
              onChange={e => onChangeAuthor(e.target.value)}
            />
          )}
        </Form.Item>
      </Col>
    </div>
  )
}

export { TopSection }
