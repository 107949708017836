import _ from 'lodash'
import React, { useEffect, useMemo, useState, useCallback } from 'react'
import { Icon } from 'antd'
import CreatableSelect from 'react-select/creatable'
import { useTranslation } from 'react-i18next'
import { api } from '../../../services'
import actions from '../../../store/actions'
import { useDispatch } from 'react-redux'

const TopicsInput = ({ onTopicsChange, defaultTopics = [] }) => {
  const [inputValue, setInputValue] = useState('')
  const [value, setValue] = useState([])
  const [isTopicLoading, setIsTopicLoading] = useState(false)
  const [isTopicsMax, setIsTopicsMax] = useState(false)
  const [options, setOptions] = useState([])

  const dispatch = useDispatch()
  const { t } = useTranslation()

  useEffect(() => {
    if (defaultTopics.length > 0) {
      const newValue = defaultTopics.map(createOption)
      setValue(newValue)
    }
  }, [defaultTopics])

  const customStyles = {
    control: provided => ({
      ...provided,
      borderRadius: '0px',
      transition: 'transparent 0.3s cubic-bezier(0.215, 0.61, 0.355, 1)',
      marginBottom: isTopicsMax ? '0px' : '24px'
    }),
    menu: provided => ({
      ...provided,
      maxHeight: '185px'
    }),
    menuList: provided => ({
      ...provided,
      maxHeight: '185px'
    })
  }

  const isModalSelectOpen = useCallback(() => {
    return !_.isEmpty(value) && value.length >= 3 && isTopicsMax
      ? false
      : inputValue.length > 0
  }, [value, isTopicsMax, inputValue])

  const createOption = useCallback(
    topic => ({
      label: topic.title || topic.label,
      value: topic.title || topic.value,
      id: topic.id
    }),
    []
  )

  const handleCreate = useCallback(
    inputValue => {
      setIsTopicLoading(true)
      const topic = { title: inputValue }
      if (!_.isEmpty(value) && value.length >= 5) {
        setIsTopicsMax(true)
        setIsTopicLoading(false)
      } else {
        api.topics.createTopics(topic).then(({ data }) => {
          if (!_.isEmpty(data[0])) {
            const newOption = createOption(data[0])
            dispatch(actions.topics.updateTopicList(newOption))
            setOptions(prevOptions => [...prevOptions, newOption])
            setIsTopicLoading(false)
            setIsTopicsMax(false)
            setValue(prevValue => [...prevValue, newOption])
            onTopicsChange(prevValue => [...prevValue, newOption])
            setInputValue('')
          }
        })
      }
    },
    [value, createOption, dispatch, onTopicsChange]
  )

  const createOptionList = useCallback(
    async list => {
      const newOptions = await Promise.all(list.map(createOption))
      setOptions(newOptions)
      setIsTopicLoading(false)
    },
    [createOption]
  )

  const loadOptions = useCallback(
    userInput => {
      if (userInput.length > 0 && userInput.length <= 50) {
        setIsTopicLoading(true)
        const params = {
          search: userInput.trim(),
          limit: 10,
          offset: 0
        }
        api.topics.getTopics(params).then(({ data }) => {
          setInputValue(userInput)
          createOptionList(data)
        })
      }
    },
    [createOptionList]
  )

  const onInputChange = useMemo(() => _.debounce(loadOptions, 1000), [
    loadOptions
  ])

  const handleChange = useCallback(
    (newValue, actionMeta) => {
      if (actionMeta.removedValue) {
        setValue(newValue)
        setInputValue('')
        setIsTopicLoading(false)
        setIsTopicsMax(false)
        onTopicsChange(newValue)
      } else if (!_.isEmpty(newValue) && newValue.length > 5) {
        setIsTopicsMax(true)
        setInputValue('')
      } else {
        setValue(newValue)
        setInputValue('')
        setIsTopicLoading(false)
        setIsTopicsMax(false)
        onTopicsChange(newValue)
      }
    },
    [onTopicsChange]
  )

  const LoadingIndicator = () => (
    <Icon style={{ paddingRight: '10px' }} type="loading" />
  )

  return (
    <>
      <div style={{ marginBottom: '4px', fontSize: '1.2rem' }}>
        {t('labels:topics').toUpperCase()}
      </div>
      <CreatableSelect
        styles={customStyles}
        components={{
          ClearIndicator: null,
          DropdownIndicator: null,
          LoadingIndicator
        }}
        isMulti
        placeholder=""
        inputId="react-select-2-input"
        isLoading={isTopicLoading}
        menuIsOpen={isModalSelectOpen()}
        onBlur={() => setInputValue('')}
        onChange={handleChange}
        onCreateOption={handleCreate}
        onInputChange={onInputChange}
        options={options}
        value={value}
      />
      {isTopicsMax && (
        <div className="error_message">{t('errors:topics_max')}</div>
      )}
    </>
  )
}

export default TopicsInput
