/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-08-09 16:41:54
 * @LastEditTime: 2019-12-19 10:53:49
 * @LastEditTime: 2019-09-09 11:43:43
 * @LastEditors: Please set LastEditors
 */

export default {
  general: {
    save: 'αποθήκευση',
    autosaved: 'αποθηκεύτηκε αυτόματα',
    back_to: 'πίσω σε',
    by: 'από',
    chat: 'συνομιλία',
    completed: 'ολοκληρώθηκε',
    canceled: 'Ακυρώθηκε',
    component: 'Συνιστώσα',
    confirm: 'Επιβεβαίωση',
    course: 'Διάλεξη',
    date: 'Ημερομηνία',
    details: 'Λεπτομέρειες',
    empty: 'Κανένα δεδομένο',
    enrolled: 'Εγγεγραμμένος',
    free: 'Δωρεάν',
    graph: 'Γράφημα',
    loading: 'Φορτώνει...',
    lesson: 'μάθημα',
    lessons: 'μαθήματα',
    live_assessment: 'Ζωντανή αξιολόγηση',
    live_event: 'Ζωντανό δρώμενο',
    no: 'Όχι',
    of: 'Του',
    or: 'Ή',
    organization: 'Οργανισμός',
    page: 'Σελίδα',
    pages: 'Σελίδες',
    personal: 'Προσωπικά',
    poll: 'Ψηφοφορία',
    rating: 'Βαθμολόγηση',
    reset: 'Επαναφορά',
    signin: 'Σύνδεση',
    signup: 'Εγγραφή',
    soon: 'Σύντομα',
    type: 'Τύπος',
    uploading: 'Ανεβαίνει...',
    yes: 'Ναι',
    something_went_wrong: 'Κάτι πήγε στραβά!',
    view_all: 'Προβολή όλων',
    upload_successful: 'Επιτυχές μεταφόρτωση',
    upload_successful_message:
      'Τα αρχεία excel σας έχουν μεταφορτωθεί επιτυχώς και η διαδικασία συνεχίζεται. Αυτό μπορεί να πάρει λίγη ώρα.',
    check_email_upload_successful:
      'Παρακαλούμε <0>ελέγξτε τη διεύθυνση ηλεκτρονικού ταχυδρομείου σας</0> για την <1>αναφορά</1> της ανεβασμένης λίστας χρηστών.',
    is_private: 'Ιδιωτικό'
  },

  account: {
    title: 'Λογαριασμός',
    subtitle:
      'Διαχειριστείτε τις ρυθμίσεις σας και την πληροφορία του λογαριασμού σας.',
    saved: 'Ο λογαριασμός άλλαξε επιτυχώς'
  },

  assessments: {
    title: 'Αξιολογήσεις',
    subtitle: 'Δημιουργήστε μία αξιολόγηση',
    finished: 'Οι αξιολογήσεις έχουν ολοκληρωθεί',
    countdown: 'Οι Ζωντανές Αξιολογήσεις θα μεταδοθούν σε ',
    create: 'Δημιουργήστε Αξιολόγηση',
    list_title: 'Οι Αξιολογήσεις σας',
    delete_warning: 'Είστε σίγουροι ότι θέλετε να διαγράψετε αυτό το στοιχείο;',
    details_title: 'Προσθέστε Πληροφορίες Αξιολογήσεων',
    details_subtitle:
      'Ονομάστε την Αξιολόγηση σας και πείτε στο κοινό σας τι θα πρέπει να αναμένει να μάθει από αυτή.',
    disclaimer: `Εγγυώμαι ότι η καταγραμμένη αξιολόγηση δεν θα:
    - καταπατήσει τα δικαιώματα οποιουδήποτε τρίτου μέρους
    - περιέχει οποιαδήποτε παρανομία, συμπεριλαμβανομένου δυσφημιστικού, άσεμνου, πορνογραφικό, βίαιο, καταχρηστικό, προσβλητικό, απειλητικό ή ρατσιστικό περιεχόμενο, ακόμη και σημειωμένο με αστερίσκους 
   - περιέχει οποιεσδήποτε προσωπικές ατάκες σε άλλα άτομα
    - περιέχει οποιαδήποτε μίμηση με άλλους συμμετέχοντες, δημόσια πρόσωπα ή διάσημους
    - περιέχει οποιαδήποτε καταγραμμένη ή προσωπική πληροφορία οποιουδήποτε ιδιώτη χωρίς τη συγκατάθεση του και δεν παραβιάζει κανέναν κανονισμό προστασίας δεδομένων σε οποιοδήποτε μέρος του κόσμου 
    -περιέχει οποιαδήποτε εμπορική προσέλκυση ή οποιαδήποτε μορφή "ανεπιθύμητου" περιεχομένου `,
    assessment_details: 'Λεπτομέρειες Αξιολόγησης',

    assessment_settings: 'Ρυθμίσεις Αξιολόγησης'
  },

  buildersettings: {
    title: 'Δημοσίευση Ρυθμίσεων',
    subtitle:
      'Προσθέστε ρυθμίσεις στο δρώμενο σας, ποιος μπορεί να παρακολουθήσει και να αλληλεπιδράσει σε αυτό'
  },

  buttons: {
    market_place: 'Market Place',
    back_to_normal: 'Back To Normal',
    add: 'Προσθήκη',
    add_page: 'Προσθήκη σελίδας',
    add_lesson: 'Προσθήκη μαθημάτων',
    add_answer: 'Προσθήκη νέας απάντησης',
    add_group: 'Προσθήκη Ομάδας',
    add_organization: 'Προσθήκη Οργανισμού',
    add_question: 'Προσθήκη ερώτησης',
    add_quiz: 'Προσθήκη Κουίζ',
    add_users: 'Προσθήκη Χρηστών',
    back: 'Πίσω',
    back_to_course: 'Πίσω στη Σύνοψη Διάλεξης',
    back_to_lesson: 'Πίσω στη σύνοψη μαθήματος',
    back_to_media: 'Πίσω στη Βιβλιοθήκη Μέσων Κοινωνικής Δικτύωσης',
    back_to_details: 'Πίσω στις λεπτομέρειες',
    cancel: 'Ακύρωση',
    clear: 'Καθαρισμός',
    close: 'Κλείσιμο',
    clone: 'Κλώνος',
    close_lesson: 'Τερματισμός Μαθήματος',
    clone_course: 'Κλώνος Διάλεξη',
    confirm: 'Επιβεβαίωση',
    create: 'Δημιουργία',
    download: 'Κατέβασμα',
    download_mass_user_tamplate: 'Κατεβάστε Υπόδειγμα',
    add_mass_users: 'Προσθήκη Χρηστών Μαζικά',
    upload_excel_list: 'Μεταφόρτωση Λίστας Excel',
    delete: 'Διαγραφή Μέσων Κοινωνικής Δικτύωσης',
    edit: 'Επεξεργασία',
    edit_course: 'Επεξεργασία Διάλεξης',
    enroll: 'Εγγραφή',
    finish: 'Τερματισμός',
    upload_image: 'Μεταφόρτωση εικόνας',
    upload_image_video: 'Μεταφόρτωση εικόνας ή βίντεο',
    select_media_library: 'Επιλέξτε τη βιβλιοθήκη μέσων κοινωνικής δικτύωσης',
    make_public: 'Δημοσιοποιήστε',
    make_private: 'Κάντε Ιδιωτικό',
    make_org: 'Δημιουργήστε Οργανισμό',
    media: 'Βιβλιοθήκη Μέσων Κοινωνικής Δικτύωσης',
    my_theme: 'Το θέμα μου',
    next: 'Επόμενο',
    next_lesson: 'Επόμενο Μάθημα',
    next_step: 'Επόμενο βήμα',
    previous_step: 'Προηγούμενο βήμα',
    publish: 'Δημοσίευση',
    publish_assessment: 'Δημοσιοποιήστε την αξιολόγηση σας',
    publish_course: 'Δημοσιοποιήστε τη διάλεξη σας',
    publish_event: 'Δημοσιοποιήστε το δρώμενο σας',
    reset: 'Επαναφορά',
    reset_elements: 'Επαναφορά στοιχείων',
    save: 'Αποθήκευση',
    creating: 'Δημιουργείται...',
    updating: 'Ενημερώνεται...',
    saved: 'Αποθηκεύτηκε',
    save_for_later: 'Αποθήκευση για αργότερα',
    save_settings: 'Αποθήκευση ρυθμίσεων',
    select_files: 'Επιλογή αρχείων',
    select_theme: 'Επιλογή θέματος',
    start_lesson: 'Έναρξη Μαθήματος',
    update: 'Ενημέρωση',
    upload: 'Μεταφόρτωση',
    upload_audio: 'Μεταφόρτωση Ήχου',
    upload_photo: 'Μεταφόρτωση φωτογραφίας προφίλ',
    upload_video: 'Μεταφόρτωση Βίντεο',
    enrolled: 'Εγγεγραμμένος',
    unenroll: 'Απεγγραφή',
    from_library: 'Media Library',
    remove: 'Αφαίρεση',
    removed: 'Αφαιρέθηκε',
    start_broadcast: 'Έναρξη Μετάδοσης',
    end_broadcast: 'Τερματισμός Μετάδοσης',
    camera: 'Κάμερα',
    screen: 'Οθόνη',
    screen_with_camera: 'Οθόνη με Κάμερα',
    insert: 'Εισαγωγή',
    inserting: 'Εισάγεται'
  },

  card: {
    quick_add: 'Γρήγορη προσθήκη',
    add: 'Προσθήκη',
    clone: 'Κλώνος',
    completed: 'ολοκληρωμένα μαθήματα'
  },

  course: {
    upcoming_course: 'Ανερχόμενη Διάλεξη',
    unPublishEvent: 'Κατάργηση δημοσίευσης Δρώμενου',
    unPublishAssessment: 'Κατάργηση δημοσίευσης Αξιολόγησης',
    pastCourse: 'Εκπαιδευτικό Σεμινάριο',
    upcoming: 'Ανερχόμενο Ζωντανό δρώμενο',
    pastEvent: 'Προηγούμενο Ζωντανό Δρώμενο',
    pastAssessment: 'Προηγούμενη Ζωντανή Αξιολόγηση',
    upcomingAssessment: 'Ανερχόμενη Ζωντανή Αξιολόγηση',
    created: 'Ημερομηνία Δημιουργίας:',
    assignees: 'Εγγεγραμμένοι',
    organisations:
      ' {{ οργανισμοί }} Οργανισμοί, <2> {{ χρήστες }} χρήστες</2>',
    event_date: 'Ημερομηνία Ζωντανού δρώμενος <1> {{ ημερομηνία }} </1>',
    delete_warning: 'Είστε σίγουροι ότι θέλετε να το διαγράψετε;',
    clonning_no_lesson:
      'Παρακαλούμε προσθέστε τουλάχιστον ένα Μάθημα ώστε να δημιουργήσετε ένα κλώνο αυτής της Διάλεξης.',
    clone_warning: 'Είστε σίγουροι ότι θέλετε να κλείσετε αυτό τον κλώνο;',
    clone_success: 'είναι σε διαδικασία τερματισμού',
    disclaimer: `Εγγυώμαι ότι η διάλεξη μου δεν:
- καταπατά τα δικαιώματα οποιουδήποτε τρίτου μέρους
- περιέχει οποιαδήποτε παρανομία, συμπεριλαμβανομένου δυσφημιστικού, άσεμνου, πορνογραφικό, βίαιο, καταχρηστικό, προσβλητικό, απειλητικό ή ρατσιστικό περιεχόμενο, ακόμη και σημειωμένο με αστερίσκους
- περιέχει οποιεσδήποτε προσωπικές ατάκες σε άλλα άτομα
- περιέχει οποιαδήποτε μίμηση με άλλους συμμετέχοντες, δημόσια πρόσωπα ή διάσημους
- περιέχει οποιαδήποτε καταγραμμένη ή προσωπική πληροφορία οποιουδήποτε ιδιώτη χωρίς τη συγκατάθεση του και δεν παραβιάζει κανέναν κανονισμό προστασίας δεδομένων σε οποιοδήποτε μέρος του κόσμου
-περιέχει οποιαδήποτε εμπορική προσέλκυση ή οποιαδήποτε μορφή "ανεπιθύμητου" περιεχομένου`
  },

  coursecreate: {
    dropzone_text: 'Εισαγωγή μέσων κοινωνικής δικτύωσης',
    sidebar_text: 'Επιλέξτε ένα θέμα <1/> για τα <3/> μαθήματα σας',
    delete_warning: 'Είστε σίγουροι ότι θέλετε να διαγράψετε αυτό το μάθημα;'
  },

  coursepage: {
    quizview_loading: 'Γίνεται έλεγχος απαντήσεων. Παρακαλούμε περιμένετε...',
    quizview_answer: 'Η Απάντηση σας'
  },

  coursequiz: {
    title: 'Κουίζ σε όλα τα Μαθήματα'
  },

  dashboard: {
    tooltip_title: 'Να μην εμφανιστεί αυτό το βίντεο ξανά',
    page_title: 'Τα στατιστικά των Διαλέξεων σας',
    page_subtitle: 'Κατάσταση των διαλέξεων που δημιουργήσατε',
    profile_add_info:
      'Προσθέστε το <1>{{μη ολοκληρωμένο Κείμενο}}</1> για να ολοκληρώσετε το προφίλ σας.',
    profile_build:
      'Χτίστε το προφίλ σας <1>εδώ</1>, ενημερώστε μας για τα ενδιαφέροντα και τις ικανότητες σας.',
    profile_title: 'Δύναμη Προφίλ',
    more_details: 'Περισσότερες Λεπτομέρειες'
  },

  errors: {
    answer: 'Παρακαλούμε εισάγετε απάντηση',
    answer_correct: 'Επιλέξτε τη σωστή απάντηση',
    code: 'Παρακαλούμε εισάγετε τον κωδικό σας',
    criterion: 'Παρακαλούμε εισάγετε κριτήριο',
    duration: 'Η Διάρκεια είναι υποχρεωτική',
    element: 'Παρακαλούμε εισάγετε στοιχείο',
    email: 'Παρακαλούμε εισάγετε διεύθυνση ηλεκτρονικού ταχυδρομείου',
    email_invalid: 'Η διεύθυνση ηλεκτρονικού ταχυδρομείου δεν είναι έγκυρη',
    feedback_correct: 'Παρακαλούμε εισάγετε σωστή ανατροφοδότηση',
    feedback_incorrect: 'Παρακαλούμε εισάγετε ανατροφοδότηση λάθος',
    host: 'Παρακαλούμε εισάγετε τον οικοδεσπότη του δρώμενος',
    host_assessment: 'Παρακαλούμε εισάγετε τον οικοδεσπότη αξιολόγησης',
    image: 'Παρακαλούμε ανεβάστε μία εικόνα',
    location: 'Παρακαλούμε εισάγετε την τοποθεσία σας',
    live_location: 'Παρακαλούμε επιλέξτε μία τοποθεσία μετάδοσης',
    limit: 'Παρακαλούμε εισάγετε τα λεπτά ζωντανής μετάδοσης',
    min_3char: '3 χαρακτήρες ελάχιστο',
    number: 'παρακαλούμε εισάγετε θετικό νούμερο.',
    host_email_require: `Οι διευθύνσεις ηλεκτρονικού ταχυδρομείου του οικοδεσπότη είναι υποχρεωτικές.`,
    attendee_email_require: `Οι διευθύνσεις ηλεκτρονικού ταχυδρομείου των εγγεγραμμένων είναι υποχρεωτικές.`,
    name: 'Παρακαλούμε εισάγετε το όνομα σας',
    not_found_course: 'Η διάλεξη δεν βρέθηκε',
    not_found_event: 'Το δρώμενο δε βρέθηκε',
    not_found_assessment: 'Η αξιολόγηση δε βρέθηκε',
    password: 'Παρακαλούμε εισάγετε τον κωδικό πρόσβασης σας',
    password_digit: 'Τουλάχιστον ένα ψηφίο',
    password_short:
      'Οι κωδικοί πρόσβασης είναι πολύ σύντομοι, 10 χαρακτήρες το λιγότερο',
    password_special: 'Τουλάχιστον ένας ειδικός χαρακτήρας',
    password_uppercase: 'Τουλάχιστον ένας κεφαλαίος χαρακτήρας',
    password_lowercase: 'Τουλάχιστον ένας πεζός χαρακτήρας',
    phone: 'Παρακαλούμε εισάγετε τον αριθμό τηλεφώνου σας',
    phone_invalid: 'Άκυρος αριθμός τηλεφώνου',
    position: 'Παρακαλούμε εισάγετε την τοποθεσία σας',
    question: 'Παρακαλούμε εισάγετε ερώτηση',
    start_date: 'Ημερομηνία & Ώρα Έναρξης απαιτούνται',
    tag: 'Παρακαλούμε επιλέξτε μία ετικέτα',
    topics: 'Παρακαλούμε επιλέξτε τουλάχιστον ένα θέμα.',
    live_topics: 'Παρακαλούμε προσθέστε θέματα',
    title: 'Παρακαλούμε εισάγετε τίτλο',
    title_assessment: 'Παρακαλούμε εισάγετε τίτλο αξιολόγησης',
    title_course: 'Παρακαλούμε εισάγετε τίτλο διάλεξης',
    title_empty: 'Ο τίτλος δεν μπορεί να είναι κενός',
    title_event: 'Παρακαλούμε εισάγετε τίτλο δρώμενου',
    title_lesson: 'Παρακαλούμε εισάγετε τίτλο μαθήματος',
    title_quiz: 'Παρακαλούμε εισάγετε όνομα κουίζ',
    username: 'Παρακαλούμε εισάγετε το όνομα χρήστη σας',
    upload_error: 'Σφάλμα – Το αρχείο δεν είναι συμβατό!',
    upload_more_than_10: 'Μπορείτε να ανεβάσετε το πολύ 10 αρχεία τη φορά.',
    topics_max: 'Παρακαλούμε προσθέστε το πολύ 3 θέματα.',
    upload_excel:
      'Παρακαλούμε σιγουρευτείτε ότι έχετε ανεβάσει το σωστό αρχείο.',
    title_excel: 'Σφάλμα μεταφόρτωσης υποδείγματος:',
    file_size_exceed: 'Το μέγεθος αρχείου έχει υπερβεί το όριο',
    subtitle_excel:
      'Παρακαλούμε σιγουρευτείτε ότι το υπόδειγμα και οι στήλες είναι συμπληρωμένες σωστά.',
    select_broadcast_option:
      'Παρακαλούμε επιλέξτε μία επιλογή για να μεταδώσετε το δρώμενο αυτό',
    multi_hosts_number_error:
      'Ο αριθμός των παρουσιαστών δεν μπορεί να υπερβαίνει τον καθορισμένο για το δρώμενο.',
    conference_people_number_error: `Ο αριθμός των παρουσιαστών δεν μπορεί να υπερβαίνει τον καθορισμένο για το δρώμενο.`,
    select_max_host: 'Επιλέξτε τουλάχιστον 1 παρουσιαστή για το δρώμενο.',
    select_max_conference:
      'Επιλέξτε τουλάχιστον 1 εγγεγραμμένο για το δρώμενο.',
    stream_stop_error: 'Η Μετάδοση Τερματίστηκε Απετυχώς!',
    unsecure_context:
      'Σφάλμα: Το πρόγραμμα περιήγησης δεν έχει πρόσβαση στην κάμερα και στο μικρόφωνο λόγω μη ασφαλούς περιεχομένου. Παρακαλούμε εγκαταστήστεSSL και αποκτήστε πρόσβαση μέσω https',
    ws_not_supported:
      'Σφάλμα: Το WebSocket δεν υποστηρίζεται σε αυτό το πρόγραμμα περιήγησης',
    no_org: 'Επιλέξτε τουλάχιστον 1 οργανισμό για να προχωρήσετε.',
    no_org_group: 'Επιλέξτε τουλάχιστον 1 ομάδα οργάνωσης για να προχωρήσετε.',
    waiting_to_join: 'περιμένω να εγγραφείτε'
  },

  events: {
    title: 'Προσθέστε Λεπτομέρειες Ζωντανού Δρώμενος',
    change_host: 'Αλλαγή Οικοδεσπότη',
    finished: 'Το δρώμενο έχει ολοκληρωθεί',
    countdown: 'Το ζωντανό δρώμενο θα μεταδοθεί σε ',
    subtitle:
      'Ονομάστε το Ζωντανό δρώμενο σας και πείτε στο κοινό σας τι θα πρέπει να αναμένει ότι θα μάθει από αυτό.',
    poll_disabled: 'Η ψηφοφορία δεν είναι διαθέσιμη σε αυτό το δρώμενο',
    disclaimer: `Εγγυώμαι ότι το καταγραμμένο δρώμενο δε θα:
    - καταπατά τα δικαιώματα οποιουδήποτε τρίτου μέρους
    - περιέχει οποιαδήποτε παρανομία, συμπεριλαμβανομένου δυσφημιστικού, άσεμνου, πορνογραφικό, βίαιο, καταχρηστικό, προσβλητικό, απειλητικό ή ρατσιστικό περιεχόμενο, ακόμη και σημειωμένο με αστερίσκους
    - περιέχει οποιεσδήποτε προσωπικές ατάκες σε άλλα άτομα
    - περιέχει οποιαδήποτε μίμηση με άλλους συμμετέχοντες, δημόσια πρόσωπα ή διάσημους
    - περιέχει οποιαδήποτε καταγραμμένη ή προσωπική πληροφορία οποιουδήποτε ιδιώτη χωρίς τη συγκατάθεση του και δεν παραβιάζει κανέναν κανονισμό προστασίας δεδομένων σε οποιοδήποτε μέρος του κόσμου 
    -περιέχει οποιαδήποτε εμπορική προσέλκυση ή οποιαδήποτε μορφή "ανεπιθύμητου" περιεχομένου`,
    prompt_message:
      'Εάν εγκαταλείψετε τη σελίδα θα τερματίσετε το δρώμενο. Είστε σίγουροι ότι θέλετε να εγκαταλείψετε τη σελίδα;',
    duration_exceed_message:
      'Ο χρόνος μετάδοσης έχει υπερβεί το όριο, η Εφαρμογή πρόκειται να σταματήσει τη μετάδοση.',
    before_schedule_message:
      'Πρόκειται να ξεκινήσετε τη μετάδοση πριν την προγραμματισμένη ώρα. Παρακαλούμε σημειώστε ότι σταματώντας τη μετάδοση, το δρώμενο θα ολοκληρωθεί.',
    browser_unsupportive_message:
      'Το πρόγραμμα περιήγησης σας δεν υποστηρίζει την κοινοποίηση οθόνης. Μπορείτε να βρείτε υποστηριζόμενα προγράμματα περιήγησης σε αυτό το σύνδεσμο.',
    cancel_switch_host_message:
      'Ο οικοδεσπότης δεν είναι διαθέσιμος. Το δρώμενο θα συνεχιστεί μετά το τέλος.',
    coaching_prompt_message:
      'YΔεν μπορείτε να κλείσετε τη σελίδα μέχρι το δρώμενο να τερματιστεί.',
    event_modal_warn: 'Θέλετε να αποχωρήσετε από το δρώμενο;',
    confirm_message: 'Το δρώμενο σας θα τερματιστεί εάν πατήσετε ΕΝΤΑΞΕΙ.',
    screen_share_denied: 'Αρνηθήκατε την κοινοποίηση οθόνης',
    media_not_found:
      'Η Κάμερα ή το Μικρόφωνο σας δεν βρέθηκαν ή δεν είναι επιτρεπτά από την συσκευή σας.',
    media_not_access:
      'Η Κάμερα ή το Μικρόφωνο σας χρησιμοποιείται από άλλη διαδικασία που δεν επιτρέπει την ανάγνωση συσκευής.',
    constraint_error:
      'Δεν βρέθηκε συσκευή που να ταιριάζει με το βίντεο και τους περιορισμούς ήχου. Μπορείτε να αλλάξετε το βίντεο και τοθς περιορισμούς ήχου.',
    media_access_denied:
      'Δεν επιτρέπεται η πρόσβαση στην κάμερα και το μικρόφωνο.'
  },

  filters: {
    days7: 'Τελευταίες 7 ημέρες',
    days15: 'Τελευταίες 15 ημέρες',
    days30: 'Τελευταίες 30 ημέρες',
    days365: 'Τελευταίες 365 ημέρες',
    date: 'Ημερομηνία δημιουργίας',
    relevance: 'Συνάφεια',
    popularity: 'Δημοσιότητα',
    name: 'Όνομα',
    group: 'Ομάδα',
    Newest_to_Oldest: 'Νεότερο προς Παλαιότερο',
    Oldest_to_Newest: 'Παλαιότερο προς Νεότερο',
    A_Z: 'A έως Ω',
    Z_A: 'Ω έως A'
  },

  helpers: {
    password:
      'Για να επιβεβαιώσετε την ασφάλεια του λογαριασμού σας απαιτείται τουλάχιστον ένας πεζός χαρακτήρας και ένα νούμερο στον κωδικό πρόσβασης σας. Επίσης, χρειάζεται να έχει το λιγότερο 10 χαρακτήρες.',
    phone:
      'Παρακαλούμε συμπεριλάβετε τον κωδικό τηλεφώνου της χώρας σας, για παράδειγμα: +65',
    username:
      'Το όνομα χρήστη σας χρησιμοποιείται ως δημόσια πληροφορία και κοινοποιείται με άλλους χρήστες όταν χρειάζεται. Για αυτό το λόγο, σας προτείνουμε να μη βάζετε προσωπικές πληροφορίες στο όνομα χρήστη σας',
    public_description_normal:
      'Το <0><0/></0> έχει αποθηκευτεί και θεωρείται Ιδιωτικό από προεπιλογή. Αυτό σημαίνει ότι μόνο εσείς μπορείτε να δείτε το περιεχόμενο. Για να δημοσιεύσετε αυτό το περιεχόμενο μπορείτε να επιλέξετε μία από τις παρακάτω επιλογές. Δημοσιεύοντας τη διάλεξη σημαίνει ότι θα είναι διαθέσιμη σε όλους τους χρήστες στην πλατφόρμα. Η επιλογή Συγκεκριμένοι Χρήστες θα σας επιτρέψουν να κοινοποιήσετε τη διάλεξη σας με συγκεκριμένους χρήστες χρησιμοποιώντας τη διεύθυνση ηλεκτρονικού ταχυδρομείου που συνδέεται με το λογαριασμό Sharelook τους.',
    public_description_admin:
      'Το <0><0/></0> έχει αποθηκευτεί και θεωρείται Ιδιωτικό από προεπιλογή. Αυτό σημαίνει ότι μόνο εσείς (και ο δημιουργός του περιεχομένου) μπορείτε να δείτε το περιεχόμενο. Για να δημοσιεύσετε αυτό το περιεχόμενο μπορείτε να επιλέξετε μία από τις παρακάτω επιλογές. Δημοσιεύοντας τη διάλεξη σημαίνει ότι θα είναι διαθέσιμη σε όλους τους χρήστες στην πλατφόρμα – συμπεριλαμβανομένων εκείνων εκτός του οργανισμού. Δημοσιεύοντας τη στον οργανισμό σημαίνει ότι όλοι οι χρήστες μέσα στον οργανισμό θα μπορούν να τη δουν. Επίσης, μπορείτε να τη δημοσιεύσετε σε 1 ή περισσότερες ομάδες, που σημαίνει ότι μόνο οι χρήστες μέσα στις ομάδες θ μπορούν να τη δουν. Τέλος, έχετε τη δυνατότητα να τη δημοσιεύσετε σε συγκεκριμένους χρήστες εντός και εκτός του οργανισμού χρησιμοποιώντας τη διεύθυνση ηλεκτρονικού ταχυδρομείου του λογαριασμού Sharelook τους.'
  },

  labels: {
    add: 'Προσθήκη',
    portrait: 'Crop Portrait',
    landscape: 'Crop Landscape',
    answer_type: 'Τύπος απάντησης',
    answers: 'Απαντήσεις',
    back_to_quiz: 'Πίσω στη λίστα Κουίζ',
    back_to_question: 'Πίσω στη λίστα ερωτήσεων',
    chat: 'Συνομιλία',
    chat_available: 'Η συνομιλία δεν είναι διαθέσιμη σε αυτό το δρώμενο.',
    chat_report: 'Αναφορά Συνομιλίας',
    choice_single: 'Μονή επιλογή',
    choice_multiple: 'Πολλαπλή επιλογή',
    choice_free: '"Ελεύθερη" επιλογή',
    choice_sorting: '"Ταξινόμηση" επιλογής',
    choice_matrix: '"Matrix Sorting" choice',
    clone_lesson_permission: 'Άδεια καταγραφής',
    code: 'Κωδικός',
    correct: 'Σωστό',
    created: 'Ημερομηνία δημιουργίας',
    clone_modal: 'Διάλεξη καταγραμμένη',
    cloning_course: 'Καταγραφή διάλεξης...',
    cloning: 'Καταγραφή...',
    cloning_course_desc:
      'Αυτή η διαδικασία θα πάρει λίγη ώρα. Παρακαλούμε κάντε υπομονή',
    cloning_success: 'Η διάλεξη "<0/>" έχει καταγραφεί επιτυχώς!',
    description: 'Περιγραφή',
    disclaimer: 'Αποποίηση ευθυνών',
    drop_file: 'Αφήστε το φάκελο εδώ...',
    duration: 'Διάρκεια',
    edit: 'Επεξεργασία',
    email: 'Διεύθυνση ηλεκτρονικού ταχυδρομείου',
    event: 'Δρώμενο',
    event_all: 'Όλα τα Δρώμενα του',
    event_date: 'Live Event Local Date/Time',
    event_this: 'Αυτό το Δρώμενο',
    expire: 'Λήγει σε',
    expiry_date: 'Ημερομηνία λήξης',
    forgot: 'Ξεχάσατε τον κωδικό πρόσβασης;',
    group: 'Ομάδα',
    groups_list: 'Λίστα Ομάδων',
    host: 'Οικοδεσπότης',
    id_annotation: 'Ταυτότητα Σχολίου',
    id_quiz: 'Ταυτότητα Κουίζ',
    incorrect: 'Λάθος',
    interests: 'Ενδιαφέροντα',
    invited: 'Καλεσμένος',
    live_chat: 'Ζωντανή Συζήτηση',
    live_stream_limit: 'Λεπτά Ζωντανής Μετάδοσης',
    contact_text:
      'Για να αλλάξετε το όριο της μηνιαίας ζωντανής μετάδοσης σας, παρακαλούμε επικοινωνήστε μαζί μας.',
    contact_admin:
      '<0>Εάν επιθυμείτε να αλλάξετε το όριο της μηνιαίας ζωντανής μετάδοσης παρακαλούμε επικοινωνήστε εδώ</0><1>admin@sharelook.com</1>',
    limit_stream_text:
      'Εναπομείναντα λεπτά ζωντανής μετάδοσης<0>{{ limit }}</0> λεπτά <1/><2/><3>Για να αλλάξετε το όριο της μηνιαίας ζωντανής μετάδοσης παρακαλούμε επικοινωνήστε</3><4>admin@sharelook.com</4>',
    location: 'Τοποθεσία',
    lessons: 'Μαθήματα',
    location_broadcast: 'Τοποθεσία Οικοδεσπότη',
    location_broadcast_subtitle:
      'Προσθέστε την πιο κοντινή στην περιοχή του οικοδεσπότη της μετάδοσης τοποθεσία για να μειώσετε την καθυστέρηση ήχου και εικόνας',
    media_manager: 'Διαχειριστής μέσων κοινωνικής δικτύωσης',
    minutes: 'Λεπτά',
    name: 'Όνομα',
    online_quiz: 'Ηλεκτρονικό Κουίζ',
    organiser: 'Συγγραφέας',
    organization: 'Οργανισμός',
    overall_emotion: 'Συνολική Εμπειρία:',
    organizations_list: 'Λίστα Οργανισμών',
    password: 'Κωδικός Πρόσβασης',
    participants: 'Συμμετέχοντες',
    picture: 'Εικόνα (16:9)',
    phone: 'Τηλέφωνο',
    photo: 'Φωτογραφία',
    poll: 'Ψηφοφορία',
    position: 'Θέση',
    processing: 'Εκτελείται',
    privacy_settings: 'Ρυθμίσεις απορρήτου',
    question: 'Ερώτηση',
    quiz_create: 'Δημιουργία Νέου Κουίζ',
    quiz_name: 'Όνομα Κουίζ',
    quiz_update: 'Ενημέρωση Κουίζ',
    select_organization: 'Επιλέξτε έναν Οργανισμό',
    settings: 'Ρυθμίσεις',
    show: 'Εμφάνιση',
    show_graph: 'Εμφάνιση αποτελεσμάτων γραφημάτων',
    signin: 'Σύνδεση',
    signup: 'Εγγραφή',
    sort_by: 'Ταξινόμηση κατά',
    start_date: 'Ημερομηνία έναρξης',
    tags: 'Ετικέτες',
    terms: 'Συμφωνώ σε αυτούς<1> τους όρους και προυποθέσεις</1>.',
    title: 'Τίτλος',
    topics: 'Θέματα',
    topic: 'Θέμα',
    total_comments: 'Συνολικά σχόλια',
    username: 'Όνομα Χρήστη',
    users_list: 'Λίστα Χρηστών',
    users_emails:
      'Εισάγετε διευθύνσεις ηλεκτρονικού ταχυδρομείου συγκεκριμένων χρηστών',
    description_specifiction_email:
      'Παρακαλούμε εισάγετε 1 διεύθυνση ηλεκτρονικού ταχυδρομείου σε κάθε γραμμή για να το κοινοποιήσετε με πολλαπλούς συγκεκριμένους χρήστες.',
    message_specific_email:
      'Παρακαλούμε εισάγετε μία διεύθυνση ηλεκτρονικού ταχυδρομείου του συγκεκριμένου χρήστη.',
    viewers: 'Θεατές',
    edit_course: 'Επεξεργασία',
    edit_assessment: 'Επεξεργασία',
    edit_event: 'Επεξεργασία',
    delete_course: 'Διαγραφή',
    delete_event: 'Διαγραφή',
    delete_assessment: 'Διαγραφή',
    view_course: 'Προβολή',
    view_event: 'Προβολή',
    view_assessment: 'Προβολή',
    date_event: 'Ημερομηνία ζωντανού δρώμενος',
    date_assessment: 'Ημερομηνία ζωντανής αξιολόγησης',
    clone_of: 'Αντίγραφο',
    clone_course:
      'Δημιουργήστε ένα αντίγραφο αυτής της διάλεξης που μπορείτε να το επεξεργαστείτε ανεξάρτητα από το πρωτότυπο, συμπεριλαμβανομένων όλων των μαθημάτων και σελίδων.',
    make: 'Δημιουργώ',
    available_to: 'διαθέσιμο σε κάθε χρήστη του ShareLook',
    uploaded_by: 'Μεταφορτώθηκε από: ',
    course: 'Διάλεξη',
    assessment: 'Αξιολόγηση',
    user_not_exist:
      'Ο χρήστης δεν υπάρχει, θα θέλατε να στείλετε μία πρόσκληση;',
    check_user: 'Παρατήρηση',
    user_not_exist_in_org:
      'Ο χρήστης δεν υπάρχει σε αυτό τον οργανισμό, θα θέλατε να στείλετε μία πρόσκληση;',
    title_modal_stream: 'Αλλαγή Ορίου Ζωντανής Μετάδοσης Οργανισμού',
    des_warning_stream: 'Πρόκειται να προχωρήσετε σε αλλαγή',
    monthly_stream: 'μηνιαίο όριο ζωντανής μετάδοσης:',
    min_per_month: 'λεπτά/μήνα',
    broadcast_label: 'Θέλω να κάνω:',
    hosts_emails: `Εισάγετε διευθύνσεις ηλεκτρονικού ταχυδρομείου συγκεκριμένου οικοδεσπότη`,
    attendee_emails: `Εισάγετε διευθύνσεις ηλεκτρονικού ταχυδρομείου συγκεκριμένου εγγεγραμμένου`,
    description_host_specifiction_email:
      'Παρακαλούμε εισάγετε1 διεύθυνση ηλεκτρονικού ταχυδρομείου ανά γραμμή.',
    presenter_label: 'Παρουσιαστής',
    presenter_label_conference: 'Αριθμός Ατόμων',
    event_privacy: 'Τρόπος Διάσκεψης'
  },

  broadcastOptions: {
    stream_from_phone: 'Ζωντανή Μετάδοση με το τηλέφωνο μου',
    other_webrtc: 'Διαδικτυακό σεμινάριο από τον Η/Υ μου',
    multihost: 'Πολλαπλό διαδικτυακό σεμινάριο από τον H/Y μου',
    peer2peer: 'Εκπαιδευτική Συνεδρία',
    conference: 'Ζωντανή Διάσκεψη'
  },

  lessoncreate: {
    title: 'Προσθήκη λεπτομερειών μαθήματος',
    subtitle:
      'Ονομάστε το μάθημα σας και πείτε στο κοινό σας τι θα πρέπει να αναμένουν ότι θα μάθουν από αυτό.',
    input_label_title: 'Τίτλος μαθήματος',
    input_label_topics: 'Θέματα μαθήματος',
    input_label_description: 'Περιγραφή μαθήματος',
    delete_warning: 'Είστε σίγουροι ότι θέλετε να διαγράψετε αυτή τη σελίδα;'
  },

  lessonoverview: {
    title: 'Προσθήκη μαθήματος',
    subtitle: 'Προσθήκη υπάρχοντος μαθήματος ή δημιουργία ενός νέου',
    new_lesson: 'δημιουργήστε ένα νέο μάθημα',
    new_lesson_sub: 'Δημιουργήστε ένα νέο μάθημα από την αρχή!',
    existing_lesson: 'Επιλέξτε ένα υπάρχον μάθημα',
    existing_lesson_sub:
      'Συνδεθείτε σε ένα υπάρχον μάθημα, αλλαγές που ενδέχεται να έχουν γίνει στο μάθημα θα επηρεάσουν επίσης αυτό το μάθημα σε άλλες διαλέξεις.',
    clone_lesson: 'αντιγράψτε το υπάρχον μάθημα',
    clone_lesson_sub:
      'Δημιουργήστε ένα μοναδικό αντίγραφο του μαθήματος σε αυτή τη διάλεξη, αλλαγές που ενδέχεται να έχουν γίνει στο μάθημα θα επηρεάσουν επίσης αυτό το μάθημα σε άλλες διαλέξεις.',
    clone_lesson_modal_sub:
      'Παρακαλούμε πιέστε το κουμπί Καταγραφή κάτω από το μάθημα που θα θέλατε να προσθέσετε στη διάλεξη σας.'
  },

  management: {
    groups_title: 'Διαχειριστείτε τις Ομάδες',
    delete_warning: 'Είστε βέβαιοι;',
    organizations_title: 'Οι Οργανισμοί μου',
    organizations_subtitle: 'Προσθήκη οργανισμών στη λίστα σας',
    users_title: 'Διαχείριση Χρηστών',
    users_position: 'Ανώτερος Διαχειριστής Λογαριασμού',
    user_not_found: 'Ο Χρήστης Δεν Βρέθηκε',
    select_org:
      'Παρακαλούμε βρείτε και επιλέξτε ένα οργανισμό για να διαχειριστείτε τους χρήστες'
  },

  audio: {
    modal_title: 'Εισαγωγή ήχου',
    list_view: 'Προβολή Λίστας',
    create_audio_modal_title: 'Δημιουργία Ήχου',
    edit_audio_modal_title: 'Επεξεργασία Ήχου',
    public_audio_not_found: 'Ο ήχος δεν βρέθηκε.',
    private_audio_not_found:
      'Δεν έχετε ανεβάσει κάποιο ηχητικό αρχείο μέχρι τώρα',
    are_you_sure_to_delete: 'Είστε βέβαιοι ότι θέλετε να το διαγράψετε;',
    type_something: 'Πληκτρολογήστε κάτι',
    select_voice: 'Επιλέξτε',
    file_name: 'Όνομα φακέλου',
    description: 'Περιγραφή',
    topic: 'Θέμα',
    key_words_tag: 'Ετικέτα Λέξεις Κλειδιά',
    mp3_audio: 'MP3 Ήχος',
    created: 'Δημιουργήθηκε',
    duration: 'Διάρκεια',
    close: 'κλείσιμο'
  },

  image: {
    modal_title: 'Εισαγωγή εικόνας',
    image: 'Εικόνα',
    pixel: 'Πίξελ',
    dimension: 'Διάσταση',
    public_image_not_found: 'Η εικόνα δε βρέθηκε.',
    private_image_not_found: 'Δεν έχετε ανεβάσει εικόνα μέχρι τώρα'
  },

  annotation: {
    modal_title: 'Σχόλιο'
  },

  text: {
    modal_title: 'Επεξεργαστής Κειμένου'
  },

  video: {
    modal_title: 'Εισαγωγή Βίντεο',
    image: 'Βίντεο',
    mp4_video: 'MP4 Βίντεο',
    public_video_not_found: 'Το Βίντεο δε βρέθηκε.',
    private_video_not_found: 'Δεν έχετε ανεβάσει κάποιο βίντεο ακόμη'
  },

  media: {
    title: 'Βιβλιοθήκη Ηλεκτρονικών Μέσων',
    subtitle: 'Βιβλιοθήκη των αρχείων σας',
    modal_title: 'Μεταφόρτωση ηλεκτρονικών μέσων',
    delete_warning: 'Επιβεβαίωση διαγραφής',
    delete_message:
      'Τα διαγραμμένα ηλεκτρονικά μέσα θα συνεχίζουν να εμφανίζονται στις σελίδες που χρησιμοποιούνταν',
    dropzone_text: 'ή Σύρετε ή Αφήστε',
    delete_success: 'Το ηλεκτρονικό μέσο διαγράφηκε με επιτυχία',
    upload_file: 'Μεταφόρτωση αρχείου',
    drag_drop: 'ή Σύρετε και Αφήστε',
    import_file: 'Εισαγωγή αρχείου(ων) από τον υπολογιστή σας',
    no_media: 'Δεν έχετε ανεβάσει κάποιο ηλεκτρονικό μέσο προς το παρόν',
    media_type: 'Τύπος ηλεκτρονικού μέσου',
    video: 'Βίντεο',
    image: 'Εικόνα',
    audio: 'Ήχος',
    gif: 'GIF',
    transcribed_text: 'Transcribed Text',
    media_not_found: 'Το ηλεκτρονικό μέσο δε βρέθηκε.',
    of: 'του',
    disclaimer: `Εγγυώμαι ότι το ηλεκτρονικό μέσο μου δεν:
    - καταπατά τα δικαιώματα οποιουδήποτε τρίτου μέρους
  - περιέχει οποιαδήποτε παρανομία, συμπεριλαμβανομένου δυσφημιστικού, άσεμνου, πορνογραφικό, βίαιο, καταχρηστικό, προσβλητικό, απειλητικό ή ρατσιστικό περιεχόμενο, ακόμη και σημειωμένο με αστερίσκους
  - περιέχει οποιεσδήποτε προσωπικές ατάκες σε άλλα άτομα
  - περιέχει οποιαδήποτε μίμηση με άλλους συμμετέχοντες, δημόσια πρόσωπα ή διάσημους
  - περιέχει οποιαδήποτε καταγραμμένη ή προσωπική πληροφορία οποιουδήποτε ιδιώτη χωρίς τη συγκατάθεση του και δεν παραβιάζει κανέναν κανονισμό προστασίας δεδομένων σε οποιοδήποτε μέρος του κόσμου
  -περιέχει οποιαδήποτε εμπορική προσέλκυση ή οποιαδήποτε μορφή "ανεπιθύμητου" περιεχομένου`,
    my_media: 'τα Μέσα Κοινωνικής Δικτύωσης μου',
    public_library: 'Δημόσια Βιβλιοθήκη',
    organization_library: 'Βιβλιοθήκη Οργανισμού',
    upload_from_computer: 'Ανεβάστε από τον Υπολογιστή',
    create: 'Δημιουργία',
    all: 'Όλα',
    male: 'Άνδρας',
    female: 'Γυναίκα',
    processing_audio: 'Επεξεργασία Ήχου',
    create_annotation: 'Δημιουργία Σχολίου',
    popup_over_lesson_text:
      'Το σχόλιο θα εμφανιστεί στη σελίδα του μαθήματος μετά το κλικ',
    edit_detail: 'Επεξεργασία Λεπτομερειών',
    delete: 'Διαγραφή',
    go_create_audio: 'Δημιουργία Ήχου',
    show_more_details: 'εμφάνιση περισσότερων λεπτομερειών',
    show_less_details: 'εμφάνιση λιγότερων λεπτομερειών',
    drop_files: 'Σύρετε φακέλους εδώ για να ανεβάσετε',
    or: 'Ή',
    import_files: 'Εισαγωγή αρχείων από τον υπολογιστή'
  },

  navigation: {
    account: 'Λογαριασμός',
    assessments: 'Αξιολογήσεις',
    dashboard: 'Πίνακας',
    groups: 'Διαχείριση Ομάδας',
    logout: 'Αποσύνδεση',
    organisations: 'Οργανισμοί',
    organizations_users: 'Οργανισμοί & Χρήστες',
    reports: 'Αναφορές',
    schedule: 'Πρόγραμμα',
    training: 'Εκπαίδευση',
    users: 'Χρήστες',
    nowLive: 'αναμετάδοση',
    workshop: 'Το Εργαστήριο Μου'
  },

  onboarding: {
    step1_title: 'Βήμα 1 : Πείτε μας λίγα για τον εαυτό σας.',
    step1_subtitle:
      'Ποιο είναι το όνομα σας; Από πού κατάγεστε; Τι δουλειά κάνετε;',
    step2_title:
      'Βήμα 2 : Επιλέξτε Μερικές Ικανότητες Που Θα Μπορούσατε Να Βελτιώσετε',
    step2_subtitle:
      'Επόμενο, πείτε μας τι θα θέλατε να κατανοήσετε καλύτερα. Ίσως κάτι που έχετε καιρό να ασχοληθείτε; Ένας νέος τομέας σπουδών; Με τι ασχολείται ο αγαπημένος σας;'
  },

  pagebuilder: {
    title: 'Δημιουργία Μαθήματος',
    subtitle:
      'Ήρθε η ώρα να δημιουργήσετε το περιεχόμενο για το κοινό σας για να μάθει από εσάς, μπορείτε να προσθέσετε εικόνες, βίντεο και γραφήματα',
    select_layout: 'Επιλέξτε μία Διάταξη Σελίδας',
    template1: 'Κενό',
    template2: 'Τίτλος & Υπότιτλος',
    template3: 'Τίτλος & Κείμενο',
    template4: 'Τίτλος, Ηλεκτρονικά Μέσα & Κείμενο',
    template5: 'Τίτλος & Ηλεκτρονικά Μέσα',
    template6: 'Τίτλος & Κουίζ'
  },

  pageconstructor: {
    title: 'Δημιουργία και Καταγραφή Μαθήματος',
    subtitle:
      'Ήρθε η ώρα να δημιουργήσετε το περιεχόμενο για το κοινό σας για να μάθει από εσάς, μπορείτε να προσθέσετε εικόνες, βίντεο και γραφήματα'
  },

  passwordreset: {
    success_coded:
      'Σας έχουμε στείλει ένα κωδικό επιβεβαίωσης στην διεύθυνση ηλεκτρονικού ταχυδρομείου που μας δώσατε, παρακαλούμε αντιγράψτε αυτό τον κωδικό στο πεδίο κάτω από τον ορισμό νέου κωδικού πρόσβασης.',
    success_changed:
      'Έχετε ορίσει νέο κωδικό πρόσβασης επιτυχώς. Μπορείτε να συνδεθείτε τώρα.'
  },

  placeholders: {
    search: 'Αναζήτηση',
    answer: 'Απάντηση',
    chat: 'Πείτε κάτι...',
    code: 'Κωδικός',
    criterion: 'Κριτήριο',
    date_event: 'Επιλέξτε ημερομηνία & ώρα',
    date_start: 'Ημερομηνία έναρξης',
    date_expire: 'Ημερομηνίας λήξης',
    description: 'Περιγραφή',
    elements: 'Ταξινόμηση στοιχείων',
    email: 'Διεύθυνση ηλεκτρονικού ταχυδρομείου',
    feedback_correct: 'Ανατροφοδότηση Σωστή',
    feedback_incorrect: 'Ανατροφοδότηση Λάθος',
    host: 'Οικοδεσπότης',
    keywords: 'Αναζήτηση λέξη(εις) κλειδί(α)',
    lesson_description: 'Περιγραφή μαθήματος',
    location: 'Τοποθεσία',
    minutes: 'Λεπτά',
    name: 'Όνομα',
    organiser: 'Συγγραφέας',
    organization: 'Οργανισμός',
    password: 'Κωδικός Πρόσβασης',
    phone: 'Τηλέφωνο',
    photo: 'Φωτογραφία',
    picture: 'Εικόνα',
    point: 'Σημείο',
    position: 'Θέση',
    question: 'Ερώτηση',
    quiz: 'Όνομα Κουίζ',
    search_course: 'Αναζήτηση μέσω Διάλεξης',
    search_media: 'Αναζήτηση ηλεκτρονικού μέσου',
    search_name: 'Αναζήτηση μέσω Ονόματος',
    search_title: 'Αναζήτηση μέσω Τίτλου',
    search_training: 'Αναζήτηση Εκπαίδευσης',
    settings: 'Ρυθμίσεις',
    tags: 'Ετικέτες',
    title: 'Τίτλος',
    topics: 'Θέματα',
    username: 'Όνομα Χρήστη',
    keyword: 'Λέξεις Κλειδιά',
    max_500_characters: 'Το πολύ 500 Χαρακτήρες ',
    no_description: 'αυτός ο φάκελος δεν έχει περιγραφή',
    no_keyword: 'αυτός ο φάκελος δεν έχει λέξεις κλειδιά',
    no_topics: 'αυτός ο φάκελος δεν έχει καθορισμένο θέμα',
    feedback: 'Μήνυμα',
    contact: 'Πως μπορώ να βοηθήσω;'
  },

  publishsettings: {
    title: 'Δημοσίευση Ρυθμίσεων',
    event_subtitle:
      'Προσθήκη ρυθμίσεων στο δρώμενο σας, ποιος μπορεί να παρακολουθήσει και να αλληλεπιδράσει στο δρώμενο σας.',
    assessment_subtitile:
      'Ονομάστε την Αξιολόγηση σας και πείτε στο κοινό σας τι θα πρέπει να αναμένει ότι θα μάθει από αυτή.',
    course_subtitle:
      'Προσθήκη ρυθμίσεων στη διάλεξη σας, ποιος μπορεί να παρακολουθήσει και να αλληλεπιδράσει σε αυτή τη διάλεξη',
    disclaimer: `1.	Εγγραφή

  Κάνοντας εγγραφή ως χρήστης/ δημιουργώντας ένα λογαριασμό συμφωνείτε με τη [Συμφωνία Χρήστη] και γνωστοποιείτε την ανάγνωση της [Πολιτική Απορρήτου] μας

  2.	Δημιουργία διάλεξης

  Χρησιμοποιώντας τα εργαλεία σας και δημιουργώντας το περιεχόμενο της διάλεξης, συμφωνείτε με τη [Συμφωνία Χρήστη] και γνωστοποιείτε την ανάγνωση της [Πολιτική Απορρήτου] μας

  3.	Δημιουργία δρώμενου

  Χρησιμοποιώντας τα εργαλεία μας και δημιουργώντας ένα δρώμενο, συμφωνείτε με τη [Συμφωνία Χρήστη] μας και γνωστοποιείτε την ανάγνωση της [Πολιτική Απορρήτου] μας


  4.	Δημιουργία αξιολόγησης

  Χρησιμοποιώντας τα εργαλεία μας και δημιουργώντας ένα δρώμενο, συμφωνείτε με τη [Συμφωνία Χρήστη] μας και γνωστοποιείτε την ανάγνωση της [Πολιτική Απορρήτου] μας`,
    dates_validation_error: 'Πρέπει να συμπληρωθούν και οι δύο ημερομηνίες.',
    emails_validation_error:
      'Η λίστα διευθύνσεων ηλεκτρονικού ταχυδρομείου δεν είναι έγκυρες.',
    specific_email_validation_error:
      'Μόνο μία διεύθυνση ηλεκτρονικού ταχυδρομείου είναι επιτρεπτή.',
    i_agree_to_these: 'Συμφωνώ με αυτά ',
    terms_and_conditions: 'όροι και προϋποθέσεις.',
    PublicDescription: `Δωρεάν ανεξάρτητοι χρήστες: (ιδιωτικές και δημόσιες επιλογές) Η [Διάλεξη | Δρώμενο | Αξιολόγηση] έχει αποθηκευτεί και θεωρείται Ιδιωτική από προεπιλογή. Αυτό σημαίνει ότι μόνο εσείς μπορείτε να δείτε αυτό το περιεχόμενο. Για να δημοσιεύσετε αυτό το περιεχόμενο, μπορείτε να επιλέξετε να το κάνετε δημόσιο παρακάτω. Αυτό σημαίνει ότι η διάλεξη θα είναι διαθέσιμη σε όλους τους χρήστες της πλατφόρμας.`,
    OrganisationsDescription:
      'Χρήστες οργανισμού: (Αντί για "" Τέλος "" χρησιμοποιήστε "" Δημοσίευση "" και μεταφέρετέ τους στη σελίδα δημοσίευσης όπως άλλους χρήστες, αλλά χωρίς καμία από τις επιλογές δημοσίευσης.) Η [Διάλεξη | Δρώμενο | Αξιολόγηση] έχει αποθηκευτεί και είναι ιδιωτική. Αυτό σημαίνει ότι μόνο εσείς και οι Διαχειριστές στον οργανισμό μπορείτε να το δείτε. Ως χρήστης, δεν μπορείτε να δημοσιεύσετε το περιεχόμενό σας μόνοι σας στον οργανισμό, καθώς αυτό απαιτεί δικαιώματα διαχειριστή. Παρακαλούμε επικοινωνήστε με έναν από τους διαχειριστές και ζητήστε του να δημοσιεύσει το περιεχόμενό σας δημόσια, σε ολόκληρο τον οργανισμό, σε συγκεκριμένες ομάδες εντός του οργανισμού σας ή σε συγκεκριμένους χρήστες.',
    OrganisationGroupsDescription:
      'Διαχειριστές οργανισμού: (Για περιεχόμενο που δημιουργείται εντός του οργανισμού: Ιδιωτικός, δημόσιος, οργανισμός, ομάδες, συγκεκριμένοι χρήστες): Αυτή η [Διάλεξη | Δρώμενο | Αξιολόγηση] έχει αποθηκευτεί και θεωρείται απόρρητη από προεπιλογή. Αυτό σημαίνει ότι μόνο εσείς (και ο δημιουργός περιεχομένου) μπορείτε να δείτε αυτό το περιεχόμενο. Για να δημοσιεύσετε αυτό το περιεχόμενο, μπορείτε να ορίσετε μία από τις παρακάτω επιλογές. Η δημοσίευση της διάλεξης σας σημαίνει ότι θα είναι διαθέσιμη σε όλους τους χρήστες της πλατφόρμας - συμπεριλαμβανομένων εκείνων που δεν ανήκουν στον οργανισμό σας. Η δημοσίευσή στον οργανισμό σημαίνει ότι όλοι οι χρήστες του οργανισμού θα μπορούν να τη δουν. Μπορείτε επίσης να τη δημοσιεύσετε σε 1 ή περισσότερες ομάδες, κάτι που σημαίνει ότι μόνο οι χρήστες σε αυτές τις ομάδες θα μπορούν να τη δουν. Τέλος, έχετε την επιλογή να τη δημοσιεύσετε σε συγκεκριμένους χρήστες εντός και εκτός του οργανισμού σας χρησιμοποιώντας τη διεύθυνση ηλεκτρονικού ταχυδρομείου του λογαριασμού Sharelook.',
    SpecificUsersDescription:
      'Ανεξάρτητοι χρήστες επί πληρωμή: (ιδιωτικοί, δημόσιοι και συγκεκριμένοι χρήστες) Η [Διάλεξη | Δρώμενο | Αξιολόγηση] έχει αποθηκευτεί και θεωρείται απόρρητη από προεπιλογή. Αυτό σημαίνει ότι μόνο εσείς μπορείτε να δείτε αυτό το περιεχόμενο. Για να δημοσιεύσετε αυτό το περιεχόμενο, μπορείτε να επιλέξετε μία από τις παρακάτω επιλογές. Η δημοσίευση της διάλεξης σας σημαίνει ότι θα είναι διαθέσιμη σε όλους τους χρήστες της πλατφόρμας. Η επιλογή συγκεκριμένων χρηστών θα σας επιτρέψει να μοιραστείτε τη διάλεξη  με συγκεκριμένους χρήστες χρησιμοποιώντας τη διεύθυνση ηλεκτρονικού ταχυδρομείου που σχετίζεται με τον λογαριασμό τους στο Sharelook.'
  },

  quiz: {
    quiz_empty: 'Δεν έχετε δημιουργήσει ακόμη ένα κουίζ',
    questions_empty: 'Δεν έχετε δημιουργήσει ακόμη ερώτηση'
  },

  regions: {
    asia_pacific_australia: 'Αυστραλία',
    asia_pacific_india: 'Ινδία',
    asia_pacific_japan: 'Ιαπωνία',
    asia_pacific_s_korea: 'Νότια Κορέα',
    asia_pacific_singapore: 'Σιγκαπούρη',
    asia_pacific_taiwan: 'Ταιβάν',
    eu_belgium: 'Βέλγιο',
    eu_germany: 'Γερμανία',
    eu_ireland: 'Ιρλανδία',
    south_america_brazil: 'Βραζιλία',
    us_central_iowa: 'Iowa',
    us_east_s_carolina: 'Νότια Καρολίνα',
    us_east_virginia: 'Βιρτζίνια',
    us_west_california: 'Καλιφόρνια',
    us_west_oregon: 'Όρεγκον'
  },

  reports: {
    title: 'Αναφορές',
    workshop_report: 'Αναφορά Εργαστηρίου',
    user_report: 'Αναφορά Χρήστη',
    organization_workshop: 'Οργάνωση εργαστηρίου',
    course: 'Διάλεξη',
    live_event: 'Ζωντανό δρώμενο',
    workShop_graph: 'Γράφημα Εργαστηρίου',
    organization_account: 'Λογαριασμός Οργανισμού',
    all: 'Όλα',
    name: 'Όνομα',
    id_number: 'Αριθμός Α.Δ.Τ.',
    email: 'Διεύθυνση ηλεκτρονικού ταχυδρομείου',
    search_user_report: 'Αναζήτηση Αναφοράς Χρήστη',
    participant: 'Συμμετέχων',
    participants: 'Συμμετέχοντες',
    host: 'Οικοδεσπότης',
    username: 'Όνομα Χρήστη',
    event: 'Δρώμενο',
    quiz: 'Κουίζ',
    assessment: 'Αξιολόγηση',
    time: 'Ώρα',
    no_recent_activity: 'Καμία Πρόσφατη Δραστηριότητα',
    workshops: 'Εργαστήρια',
    courses: 'Διαλέξεις',
    live_events: 'Ζωντανά Δρώμενα',
    live_assessments: 'Ζωντανές Αξιολογήσεις',
    quizzes: 'Κουίζ',
    overview: 'Σύνοψη',
    by: 'Από',
    views: 'Προβολές',
    status: 'Κατάσταση',
    poll: 'Ψηφοφορία',
    comment: 'Σχόλιο',
    comments: 'Σχόλια',
    no_comments: 'Κανένα Σχόλιο',
    no_polls: 'Καμία Ψηφοφορία',
    live_date: 'Ημερομηνία Ζωντανής Μετάδοσης',
    score: 'Βαθμολογία',
    question: 'Ερώτηση',
    submit: 'Υποβολή',
    recent_activity: 'Πρόσφατη δραστηριότητα',
    ago: 'Πριν',
    no_data_found: 'Δεν βρέθηκαν δεδομένα',
    start_end_live: 'Έναρξη/Τερματισμός Ζωντανής Μετάδοσης',
    join_leave_date: 'Συμμετοχή/Αποχώρηση',
    watched_time: 'Ώρα Προβολής',
    complete: 'Ολοκλήρωση',
    in_complete: 'Σε Ολοκλήρωση',
    today: 'Σήμερα',
    seven_days: '7 Ημέρες',
    last_month: 'Τρέχον Μήνας',
    one_year: '1 Χρόνος',
    all_time: 'Πάντα',
    report: 'Αναφορά',
    from_date: 'Από Ημερομηνία',
    to_date: 'Έως Ημερομηνία',
    all_workshop: 'Όλα τα Εργαστήρια',
    all_participants: 'Όλοι οι Συμμετέχοντες',
    live_assessment: 'Ζωντανή Αξιολόγηση',
    users: 'Χρήστες',
    download: 'Κατεβάστε',
    recent: 'Πρόσφατα',
    WorkShop: 'Εργαστήριο',
    Activity: 'Δραστηριότητα',
    created_date: 'Ημερομηνία δημιουργίας',
    lessons: 'Μαθήματα',
    lesson: 'Μάθημα',
    completed: 'Ολοκληρωμένο',
    report_download: 'Κατέβασμα Αναφοράς',
    this_month: 'Αυτό το μήνα',
    search_course: 'Αναζήτηση Διάλεξης',
    participants_graph: 'Γράφημα Συμμετεχόντων',
    viewed: 'Προβλήθηκε',
    enrolled: 'Εγγεγραμμένος',
    description: 'Περιγραφή',
    topic: 'Θέμα',
    analytic: 'Αναλυτικό',
    quiz_list: 'Λίστα Κουίζ',
    select_one: 'Επιλέξτε Ένα',
    group_status: 'Κατάσταση Ομάδας',
    duration_min: 'Διάρκεια(λεπτά)',
    submit_poll: 'Υποβολή Ψηφοφορίας',
    download_events: 'Κατεβάστε τα Δρώμενα',
    search_event: 'Αναζήτηση δρώμενου',
    duration: 'Διάρκεια',
    total_participants: 'Συνολικοί Συμμετέχοντες',
    message: 'Μήνυμα',
    avg_message: 'Μέσο Μήνυμα'
  },

  signup: {
    success_step1:
      'Σας έχουμε στείλει ένα κωδικό επιβεβαίωσης στην διεύθυνση ηλεκτρονικού ταχυδρομείου που μας δώσατε, παρακαλούμε αντιγράψτε αυτό τον κωδικό στο πεδίο κάτω από τον ορισμό νέου κωδικού πρόσβασης.',
    success_step2: 'Ευχαριστούμε. Έχετε εγγραφεί επιτυχώς.',
    privacy: `Συμφωνώ με τους <1> όρους & προϋποθέσεις του Sharelook</1> και <3> πολιτική απορρήτου</3>`
  },

  stream: {
    emotion_impressive: 'Εντυπωσιακό',
    emotion_unimpressive: 'Αδιάφορο',
    message_not_started: 'Έρχεται σύντομα',
    message_running: 'Ζωντανά',
    message_processing:
      'Αυτή τη στιγμή εκτελούμε τη διαδικασία της ζωντανής καταγραφής. Παρακαλούμε ελέγξτε αργότερα για να προβάλετε το βίντεο και να συνεχίσετε να προσθέσετε βαθμολογία και ανατροφοδότηση'
  },

  tabs: {
    audience: 'Κοινό',
    course: 'Σύνοψη Διάλεξης',
    details: 'Λεπτομέρειες Ζωντανού Δρώμενου',
    description: 'Περιγραφή',
    host: 'Οικοδεσπότης',
    lesson: 'Σύνοψη Μαθήματος',
    lessons: 'Μαθήματα',
    overview: 'Σύνοψη',
    page: 'Δημιουργός Σελίδας',
    publish: 'Δημοσίευση',
    settings: 'Δημοσίευση Ρυθμίσεων',
    total_views: 'Συνολικές Προβολές',
    live_participates: 'Ζωντανά Συμμετέχοντες',
    live_chats: 'Ζωντανές Συνομιλίες',
    live_quiz: 'Ζωντανές Ψηφοφορίες',
    trainer_rating: 'Βαθμολογία Εκπαιδευτή',
    trainer_times: 'Ώρες Εκπαίδευσης',
    courses_quizzes: 'Κουίζ Διαλέξεις',
    user_list: 'Λίστα Χρηστών',
    discussion: 'Συζήτηση',
    notes: 'Οι Σημειώσεις μου'
  },

  topics: {
    SALES_TRAINING: 'Εκπαίδευση Πωλήσεων',
    NEGOTIATION_TRAINING: 'Εκπαίδευση Διαπραγμάτευσης',
    CROSS_CULTURAL_AWARENESS: 'Διαπολιτισμική ευαισθητοποίηση',
    NETWORKING_SKILLS: 'Ικανότητες ιντερνετικής δικτύωσης',
    SOFTWARE_ARCHITECTURE: 'Ανάπτυξη Λογισμικού',
    PROJECT_MANAGEMENT: 'Διαχείριση Έργων',
    DIGITAL_TRANSFORMATION: 'Ψηφιακή Αναδιαμόρφωση',
    IT_CONSULTING: 'Συμβουλευτική Πληροφορικής',
    MANAGEMENT_CONSULTING: 'Management Consulting',
    HR_CONSULTING: 'Συμβουλευτική Ανθρωπίνου Δυναμικού',
    INTERNET_OF_THINGS: 'To Διαδίκτυο των Πραγμάτων(IoT)',
    CYBERSECURITY: 'Κυβερνασφάλεια',
    PRIVACY_AND_GDRP: 'Απόρρητο και GDPR'
  },

  training: {
    title: 'Εκπαίδευση',
    title_assessment: 'Αξιολογήσεις',
    subtitle: 'Διαλέξεις και Ζωντανά Δρώμενα',
    enrolled_title: 'Εγγεγραμμένες Διαλέξεις και Ζωντανά Δρώμενα',
    enrolled_subtitle: 'Διαλέξεις και Ζωντανά Δρώμενα στα οποία εγγραφήκατε',
    enrolled_message:
      'Δεν είστε προς το παρόν εγγεγραμμένοι σε κάποια διάλεξη και δρώμενο.',
    past_live_event_title: 'Προηγούμενα Ζωντανά Δρώμενα',
    past_live_event_subTitle: 'Ολοκληρωμένα Ζωντανά Δρώμενα',
    past_live_event: 'Δεν υπάρχουν προς το παρόν προηγούμενα ζωντανά δρώμενα.',
    recomended_title: 'Προτεινόμενες Διαλέξεις και Ζωντανά Δρώμενα',
    now_live_event_title: 'Ζωντανά Τώρα',
    now_live_event_subTitle: 'Events that are live or will be very soon',
    now_live_event: 'Δεν υπάρχει προς το παρόν ζωντανό δρώμενο.',
    webinar_event_title: 'Κανάλι Διαδικτυακών Σεμιναρίων',
    webinar_event_subTitle: 'Ολοκληρωμένα Διαδικτυακά Σεμινάρια',
    webinar_event: 'Δεν υπάρχουν προς το παρόν διαδικτυακά σεμινάρια.',
    p2p_event_title: 'Εκπαίδευση',
    p2p_event_subTitle: 'Ολοκληρωμένες Εκπαιδεύσεις',
    p2p_event: 'Δεν υπάρχει προς το παρόν εκπαίδευση.',

    now_live_assessment_title: 'Ζωντανά Τώρα',
    now_live_assessment_subTitle:
      'AΑξιολογήσεις που είναι ζωντανές ή θα είναι πολύ σύντομα',
    now_live_assessment_message: 'Δεν υπάρχει τώρα ζωντανή αξιολόγηση.',

    upcoming_assessment_title: 'Ανερχόμενες Ζωντανές Αξιολογήσεις',
    upcoming_assessment_subtitle: 'Ανερχόμενες Ζωντανές Αξιολογήσεις',
    upcoming_assessment_message:
      'Δεν υπάρχει προς το παρόν επερχόμενη ζωντανή αξιολόγηση.',

    past_live_assessment_title: 'Προηγούμενες Ζωντανές Αξιολογήσεις',
    past_live_assessment_subTitle: 'Ολοκληρωμένες Ζωντανές Αξιολογήσεις',
    past_live_assessment_message:
      'Δεν υπάρχουν προς το παρόν προηγούμενες ζωντανές αξιολογήσεις.',

    /**
     * <0/> get index 0 of data from Trans component
     * exp -->
     * <Trans i18nKey='training:recomended_subtitle' >
     *    <Navlink>{...something}</Navlink> --> is index 0 of Trans component
     *    <h1>{...something}</h1> --> is index 1 of Trans component
     * </Trans>
     */
    recomended_subtitle:
      'Έχουμε προτείνει δρώμενα και ζωντανά δρώμενα βάσει <0><0/></0>',
    /**
     * and we also can get data from index to replace in recommended_subtitle by like above string.
     * for exp -->
     * at the end of the recomended_subtitle above will get data from index of component to display
     * <0><0/></0> mean get data index 0 of Trans component above = <Navlink> and <0/> get data index 0 from <Navlink> = {...something}
     */
    search: 'Αναζήτηση Εκπαίδευσης',
    search_assessment: 'Αναζήτηση Αξιολόγησης',
    recomended_message:
      'Δεν υπάρχουν προς το παρόν προτεινόμενες διαλέξεις και δρώμενα.'
  },

  warnings: {
    delete: 'Είστε σίγουροι;'
  },

  workshop: {
    title: 'Το Εργαστήριο μου',
    subtitle: 'Δημιουργία δρώμενου ή φιλοξενία ενός ζωντανού δρώμενος',
    new_course: 'Δημιουργία νέας διάλεξης',
    new_event: 'Δημιουργία νέου ζωντανού δρώμενος',
    new_assessment: 'Δημιουργία νέας ζωντανής αξιολόγησης',
    courses_title: 'Created Courses & Live Events',
    courses_subtitle:
      'Επεξεργασία & Δημοσίευση των διαλέξεων που έχετε δημιουργήσει και σχεδιάστε ζωντανά δρώμενα',
    tags_course: 'Διάλεξη',
    tags_live: 'Ζωντανό Δρώμενο',
    tags_assessment: 'Ζωντανή Αξιολόγηση'
  },

  help_center: {
    sidebar: {
      title: 'Πως μπορούμε να βοηθήσουμε;',
      back_to: 'Πίνακας'
    },
    menu: {
      help_center: 'Κέντρο Βοήθειας',
      whats_new: 'Τι νέο υπάρχει',
      feedback: 'Ανατροφοδότηση',
      feedback_title: 'Αποστολή ανατροφοδότησης στο sharelook',
      terms_and_conditions: 'Όροι & Προϋποθέσεις',
      privacy_policy: 'Πολιτική Απορρήτου',
      contact_us: 'Επικοινωνήστε μαζί μας'
    },
    get_started: {
      title: 'Ξεκινήστε με το share look',
      description: {
        main:
          'ΈΧΟΥΜΕ ΟΛΟΚΛΗΡΩΣΕΙ ΕΝΑ LIVE VIDEO ΓΙΑ ΜΙΑ ΕΥΚΟΛΗ-ΓΙΑ-ΧΡΗΣΗ ΔΗΜΙΟΥΡΓΙΑ ΔΙΑΛΕΞΗΣ',
        tagline:
          'Με το πατενταρισμένο σύστημα ζωντανής μετάδοσης του ShareLook, μπορείτε να δημιουργήσετε αφοσίωση, αυτόματη εκμάθηση και να μεγιστοποιήσετε την κοινή χρήση γνώσεων.'
      }
    },
    whats_new: {
      title: 'Ξεκινήστε με το share look',
      description: {
        main:
          'Γνωρίζατε ότι η δημιουργία εκμάθησης στυλ αφήγησης μπορεί να δημιουργηθεί σε σύντομο χρόνο και όχι τόσο ακριβά; Θα πρέπει να συνδυάσετε το πρόγραμμα δημιουργίας μαθημάτων ShareLook και αντίστοιχα ταλέντα. Μπορείτε να μας ρωτήσετε "ΠΩΣ"',
        tagline: ''
      }
    },
    create_workshop: 'Πώς να δημιουργήσετε ένα Εργαστήριο',
    create_course: 'Πώς να δημιουργήσετε μία Διάλεξη',
    create_user: 'Πώς να δημιουργήσετε ένα Χρήστη',
    create_group: 'Πώς να δημιουργήσετε μία Ομάδα',
    create_webinar: 'Πως να δημιουργήσετε ένα διαδικτυακό σεμινάριο',
    create_live_event: 'Πως να δημιουργήσετε ένα Ζωντανό Δρώμενο',
    create_live_assessment: 'Πως να δημιουργήσετε Ζωντανή Αξιολόγηση',
    create_live_on_mobile: 'Δημιουργία Ζωντανά στο Κινητό',
    personalize_profile: 'Κάντε πιο προσωπικό ένα προφίλ',
    manage_group: 'Διαχείριση ομάδας',
    report: 'Αναφορά',
    add_annotations: 'Πως Να προσθέσετε Σχόλια/Αναδυόμενα',
    add_audio: 'Πώς να Προσθέσετε Ήχο',
    add_header_and_footer: 'Adding Header & Footer',
    add_image: 'Πώς να Προσθέσετε Εικόνα',
    add_quiz_content: 'Πώς να προσθέσετε Περιεχόμενο Κουίζ',
    add_text_content: 'Πως να Προσθέσετε Περιεχόμενο Κειμένου',
    add_user: 'Πως να Προσθέσετε Χρήστη',
    add_video: 'Πως να Προσθέσετε Βίντεο',
    clone_course: 'Πως να Αντιγράψετε τη Διάλεξη',
    find_live_broadcast: 'Πώς να Βρείτε τη Ζωντανή Αξιολόγηση',
    contact_us: {
      title: 'Επικοινωνήστε μαζί μας',
      subject_placeholder: 'Εισάγετε ένα θέμα*',
      content_placeholder: 'Εισάγετε ένα μήνυμα*',
      subject_required: '*Απαιτείται θέμα',
      content_required: '*Απαιτείται μήνυμα',
      submit: 'Αποστολή',
      cancel: 'Ακύρωση',
      sent: 'Απεστάλη'
    },
    feedback: {
      title: 'Αποστολή ανατροφοδότησης στο sharelook',
      attach_title: 'Επισύναψη αρχείου',
      choose: 'Επιλέξτε αρχείο',
      detail: 'Το αρχείο θα κοινοποιηθεί με την ομάδα υποστήριξης',
      content_placeholder: 'Εισαγωγή μηνύματος*',
      content_required: '*Απαιτείται μήνυμα',
      submit: 'Αποστολή',
      cancel: 'Ακύρωση',
      sent: 'Απεστάλη'
    }
  },
  discussion: {
    join_discussion: 'Συμμετέχετε στη συζήτηση'
  },
  notes: {
    title: 'Οι Σημειώσεις μου',
    add_note: 'Προσθήκη νέας σημείωσης'
  }
}
