import React, { Component } from 'react'
import className from 'classnames'
import './Spinner.scss'

const sizes = {
  small: 'small'
}

class Spinner extends Component {
  render () {
    const { height, size } = this.props

    const classNames = className({
      'spin': true,
      'spin_small': size === sizes.small
    })

    return (
      <div className='spinner' style={{ height }}>
        <div className='spinner__spin'>
          <div className={classNames}>
            <div className='spin__bounce'/>
            <div className='spin__bounce'/>
            <div className='spin__bounce'/>
          </div>
        </div>
      </div>
    )
  }
}

export default Spinner
