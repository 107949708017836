import React from 'react'
import { AudioPlayerStyle } from './styled'

const AudioPlayer = props => {
  return (
    <AudioPlayerStyle>
      {props.autoPlay && (
        <audio
          id="audioId"
          style={{ width: props.width, height: props.height }}
          controls
          autoPlay
          controlsList="nodownload"
          preload="none"
        >
          <source src={props.src} type="audio/mp3" />
          Your browser does not support the audio tag.
        </audio>
      )}

      {!props.autoPlay && (
        <audio
          id="audioId"
          style={{ width: props.width, height: props.height }}
          controls
          controlsList="nodownload"
          preload="none"
        >
          <source src={props.src} type="audio/mp3" />
          Your browser does not support the audio tag.
        </audio>
      )}
    </AudioPlayerStyle>
  )
}

export default AudioPlayer
