import { api } from '../../services'

import { LIST_SKILL_JOURNEY, FETCHING_SKILL_JOURNEY } from '../types'

const listSkillJourney = params => dispatch => {
  const d = (type, payload) =>
    dispatch({
      type,
      payload
    })

  d(FETCHING_SKILL_JOURNEY, true)
  return api.skillJourney.listSkillJourneys(params).then(response => {
    d(LIST_SKILL_JOURNEY, response.data)
    d(FETCHING_SKILL_JOURNEY, false)
  })
}

export { listSkillJourney }
