import styled from 'styled-components'
import { styleguide } from '../../../../../constants'

const { colors } = styleguide

const Types = styled.div``

Types.Heading = styled.div`
  font-weight: 500;
  font-size: 2.8rem;
  margin: 4rem;
`

Types.List = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -1.6rem;
  margin-right: -1.6rem;
`

Types.Item = styled.div`
  width: 33.33%;
  padding: 0 1.6rem;
`

const Type = styled.div`
  padding-bottom: 3.2rem;
  cursor: pointer;
`

Type.Title = styled.div`
  font-weight: 300;
  font-size: 1.2rem;
  margin-top: 0.6rem;
  transition: color 0.2s;

  ${Type}:hover & {
    color: ${colors.blue};
  }
`

Type.Content = styled.div`
  border: 1.5px solid #e3e3e3;
  overflow: hidden;
  background: ${colors.white};
  transition: border-color 0.2s, box-shadow 0.2s;
  box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  height: 160px;
  padding: 50px;
  display: flex;
  align-items: center;

  ${Type}:hover & {
    border-color: ${colors.blue};
    box-shadow: 0 0.3rem 0.8rem rgba(0, 0, 0, 0.15);
  }
`

Type.Thumb = styled.img`
  display: block;
  width: 100%;
  height: auto;
`

export { Types, Type }
