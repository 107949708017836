import React, { Fragment, useEffect, useState } from 'react'
import {
  Form,
  Button,
  Input,
  Spin,
  Tooltip,
  InputNumber,
  Checkbox,
  Row
} from 'antd'

import { FormCreation } from './styled'
import { withTranslation } from 'react-i18next'
import { api } from '../../../../../../services'

const QuizCreation = props => {
  const [isQuizRestricted, setIsQuizRestricted] = useState(false)

  const {
    data,
    form,
    onCancel,
    handleInput,
    setCurrentQuiz,
    ids,
    onQuizSetupData
  } = props
  useEffect(() => {
    setIsQuizRestricted(props.data ? props.data.is_restricted : false)
  }, [props.data])

  const handleSubmit = e => {
    e.preventDefault()

    form.validateFields((err, values) => {
      if (!err) {
        values = {
          ...values,
          lesson_id: ids.lessonId,
          course_id: Number(ids.courseId),
          page_id: ids.pageId
        }
        if (data) {
          const newData = { ...values }
          if (data.questions && data.questions.length) {
            newData.questions = data.questions
          }
          api.quizes.quizUpdate(newData, data.id).then(() => {
            api.quizes.getQuizByIdAnswers(data.id).then(res => {
              handleInput(res.data)
              setCurrentQuiz(res.data)
            })
          })
        } else {
          api.quizes.quizCreate(values).then(res => {
            handleInput(res.data)
            setCurrentQuiz(res.data)
          })
        }

        form.resetFields()
        onCancel()
      }
    })
  }

  const {
    form: { getFieldDecorator },
    handleCloseQuizModal,
    isFetching,
    t,
    isAiCreation = false
  } = props

  console.log('data quiz', data)

  const titleAttempts = 'No.of attempts, a user can try'

  return (
    <FormCreation>
      {isFetching ? (
        <Row type="flex" justify="center" align="middle">
          <Spin spinning />
        </Row>
      ) : (
        <Form onSubmit={handleSubmit}>
          <Form.Item label={t('placeholders:quiz')} colon={false}>
            {getFieldDecorator('title', {
              rules: [
                {
                  required: true,
                  message: t('errors:title_quiz')
                }
              ],
              initialValue: data && data.title
            })(
              <Input
                type="text"
                size="large"
                placeholder="Leadership in a Nutshell"
              />
            )}
          </Form.Item>

          <Form.Item label={t('placeholders:feedback_correct')} colon={false}>
            {getFieldDecorator('feedback_correct', {
              rules: [
                {
                  required: true,
                  message: t('errors:feedback_correct')
                }
              ],
              initialValue: data && data.feedback_correct
            })(
              <Input
                type="text"
                size="large"
                placeholder="Yes, correct answer!"
              />
            )}
          </Form.Item>

          <Form.Item label={t('placeholders:feedback_incorrect')} colon={false}>
            {getFieldDecorator('feedback_incorrect', {
              rules: [
                {
                  required: true,
                  message: t('errors:feedback_incorrect')
                }
              ],
              initialValue: data && data.feedback_incorrect
            })(
              <Input
                type="text"
                size="large"
                placeholder="Incorrect! Sorry! "
              />
            )}
          </Form.Item>

          {!isQuizRestricted && (
            <Form.Item name="attempts_count" label="Attempts Count">
              <Fragment>
                <Tooltip
                  trigger={['focus']}
                  title={titleAttempts}
                  placement="topLeft"
                  overlayClassName="numeric-input"
                >
                  {getFieldDecorator('attempts_count', {
                    initialValue: (data && data.attempts_count) || 2
                  })(
                    <InputNumber
                      size="large"
                      min={0}
                      max={5}
                      defaultValue={2}
                      placeholder="2"
                    />
                  )}
                </Tooltip>
              </Fragment>
            </Form.Item>
          )}

          <Form.Item style={{ textAlign: 'left' }}>
            {getFieldDecorator('is_restricted', {
              rules: [
                {
                  required: false
                }
              ],
              initialValue: data && data.is_restricted,
              valuePropName: 'checked'
            })(
              <Checkbox
                onChange={e => {
                  setIsQuizRestricted(e.target.checked)
                }}
              >
                {t('v3:disable_skip_quiz').toUpperCase()}
              </Checkbox>
            )}
          </Form.Item>

          {!isAiCreation && (
            <FormCreation.Submit>
              <Button
                size="large"
                shape="round"
                onClick={() => handleCloseQuizModal()}
                style={{ marginRight: '1rem', padding: '0 4rem' }}
                className="shl-secondary-btn"
              >
                {t('buttons:cancel')}
              </Button>
              <Button
                htmlType="submit"
                shape="round"
                size="large"
                className="shl-primary-btn"
                style={{
                  padding: '0 4rem',
                  backgroundColor: '#12B903',
                  color: 'white'
                }}
              >
                {t('buttons:save')}
              </Button>
            </FormCreation.Submit>
          )}
        </Form>
      )}
    </FormCreation>
  )
}

export default Form.create({
  onValuesChange: (props, changedValues, allValues) => {
    const { isAiCreation, onQuizSetupData } = props

    if (isAiCreation) {
      onQuizSetupData(allValues)
    }
  }
})(withTranslation()(QuizCreation))
