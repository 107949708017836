import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Dropdown } from '../../components'

import { FilterContainer } from './styled'
import { withTranslation } from 'react-i18next'
import './style.scss'

class Filter extends Component {
  state = {
    selectedItem: this.props.defaultValue
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.defaultValue !== nextProps.defaultValue) {
      this.setState({
        selectedItem: nextProps.defaultValue
      })
    }
  }

  handleChangeItem(item) {
    const { callback, propKey = 'text' } = this.props

    this.setState({
      selectedItem: item[propKey]
    })

    if (callback) {
      callback(item)
    }
  }

  render() {
    const {
      data,
      bordered,
      children,
      label,
      noIndent,
      propKey = 'text',
      style,
      t
    } = this.props

    const { selectedItem } = this.state

    return (
      <FilterContainer noIndent={noIndent} style={style}>
        <FilterContainer.Wrapper>{children}</FilterContainer.Wrapper>
        <div className="shl-left-line"></div>
        <div className="sort-by-date-wrap">
          <FilterContainer.Wrapper>
            <FilterContainer.Label>{label}</FilterContainer.Label>

            <Dropdown
              list={
                <FilterContainer.Menu>
                  {data
                    .filter(item => item[propKey] !== selectedItem)
                    .map((item, index) => (
                      <FilterContainer.Item key={index}>
                        <FilterContainer.Link
                          className="date-sort-by"
                          onClick={() => this.handleChangeItem(item)}
                        >
                          {t(item[propKey])}
                        </FilterContainer.Link>
                      </FilterContainer.Item>
                    ))}
                </FilterContainer.Menu>
              }
            >
              <FilterContainer.Selected>
                {t(selectedItem)}
              </FilterContainer.Selected>
            </Dropdown>
          </FilterContainer.Wrapper>
        </div>
      </FilterContainer>
    )
  }
}

Filter.propTypes = {
  data: PropTypes.array,
  bordered: PropTypes.bool,
  children: PropTypes.node,
  defaultValue: PropTypes.string,
  propKey: PropTypes.string
}

export default withTranslation()(Filter)
