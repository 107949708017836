import styled from 'styled-components'
import { Scrollbars } from 'react-custom-scrollbars'

import { styleguide } from '../../constants'

const { colors } = styleguide

const CustomScrollbars = styled(Scrollbars)`
  ${props =>
    props.type === 'horizontal' &&
    `
    .track-vertical {
      display: none;
    }
  `}

  ${props =>
    props.type === 'vertical' &&
    `
    .track-horizontal {
      display: none;
    }
  `}

  ${props =>
    props.minimize &&
    `
    .track-horizontal {
      right: calc(100% - 570px) !important;
    }
  `}
`

CustomScrollbars.Thumb = styled.div`
  background-color: ${colors.blue};
  border-radius: 2rem;
  cursor: pointer;
`

CustomScrollbars.Track = styled.div`
  background-color: #ddd;
  border-radius: 2rem;
  right: 0;
  bottom: 0;

  &.track-vertical {
    top: 0;
  }

  &.track-horizontal {
    left: 0;
  }
`

export { CustomScrollbars }
