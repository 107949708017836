/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-07-19 19:34:23
 * @LastEditTime: 2019-08-12 17:58:49
 * @LastEditors: Please set LastEditors
 */
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Page from './Page'
import actions from '../../../store/actions'

const mapStateToProps = state => ({
  isEditElement: state.annotation.isEditElement
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      handleFileDropIn: actions.lessonBuilder.handleFileDropIn,
      clearUploadList: actions.library.clearUploadList
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(Page)
