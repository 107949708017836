const checkCover = cover => cover ? cover.resizes.medium_large : ''

const checkActiveLesson = activeLesson => activeLesson.cover ? activeLesson.cover.link : ''

const getThumbnail = cover => cover ? cover.resizes.thumbnail : ''

const getLessonItemClassName = (id, activeLessonId) => id === activeLessonId ? 'active' : null

const isPublish = (isAdmin, isOwner, isSuperAdmin) => false && !isOwner && (isAdmin || isSuperAdmin)

const handleEdit = (history, id) => history.push(`/courses/${id}/edit`)

const handlePublish = (history, id) => history.push(`/courses/${id}/edit/publish`)

export {
  checkCover,
  checkActiveLesson,
  getThumbnail,
  getLessonItemClassName,
  isPublish,
  handleEdit,
  handlePublish
}
