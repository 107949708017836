import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import actions from '../../../store/actions'

import Edit from './Edit'

export default connect(
  state => ({
    maze: state.maze,
    mazeBuilder: state.mazeBuilder
  }),
  dispatch => ({
    getMazeById: id => dispatch(actions.maze.getMazeById(id)),
    mazeBuilderActions: bindActionCreators(actions.mazeBuilder, dispatch)
  })
)(Edit)
