import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import MazeCreate from './MazeCreate'

import actions from '../../../../../store/actions'
import * as mazeBuilderActions from '../../../../../store/actions/mazeBuilder'

export default connect(
  state => ({
    maze: state.maze,
    mazeBuilder: state.mazeBuilder,
    audioSynthesizer: state.audioSynthesizer,
    topics: state.topics,
    user: state.user
  }),
  dispatch => ({
    mazeBuilderActions: bindActionCreators(mazeBuilderActions, dispatch),
    upload: (file, opts) => dispatch(actions.library.upload(file, opts)),
    updateUser: (id, data, dbData) =>
      dispatch(actions.user.updateUser(id, data, dbData)),
    createGroup: (orgId, data) =>
      dispatch(actions.organizations.createGroup(orgId, data)),
    updateTopicList: newTopic =>
      dispatch(actions.topics.updateTopicList(newTopic)),
    handleMazeData: data => dispatch(actions.maze.handleMazeData(data)),
    updateMaze: params => dispatch(actions.maze.updateMaze(params)),
    getVoices: bindActionCreators(
      actions.audioSynthesizer.listTTSAudioForMaze,
      dispatch
    ),
    postTTSVoices: bindActionCreators(
      actions.audioSynthesizer.postAudioSynthesizer,
      dispatch
    )
  })
)(MazeCreate)
