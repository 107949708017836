import React, { Fragment } from 'react'
import { Accordion } from './styled'
import { i18n } from '../../../../../services'

import Tabs from './Tabs'

export default (props) => {
  const {
    list,
    activeFilterValue,
    onChange
  } = props

  return (
    <Fragment>
      {!!list.length && <Accordion
        bordered={false}
        defaultActiveKey={`${list[0].id}`}
        onChange={onChange}
        accordion
      >
        {list.map(item => (
          <Accordion.Panel
            header={
              <Fragment>
                <Accordion.Title>{item.title}</Accordion.Title>
                <Accordion.Lessons>{item.lessons.length} {i18n.t('lessons')}</Accordion.Lessons>
                <Accordion.IconEvent/>
              </Fragment>
            }
            key={item.id}
            showArrow={false}
          >
            <Tabs item={item} activeFilterValue={activeFilterValue}/>
          </Accordion.Panel>
        ))}
      </Accordion>
      }
    </Fragment>
  )
}
