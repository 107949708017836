import http from './http'

function getCalendar() {
  return http({
    method: 'GET',
    url: 'calendar/settings/self'
  })
}

function updateCalendar(data) {
  return http({
    method: 'PUT',
    url: 'calendar/settings',
    data
  })
}

export { getCalendar, updateCalendar }
