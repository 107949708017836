import React, { Component } from 'react'
import { Checkbox } from 'antd'
import _ from 'lodash'
import { Field, Row, Col } from '../../../../../styles'
import { Organization } from './styled'
import { withTranslation } from 'react-i18next'

class OrganizationPicker extends Component {
  handleChange = id => {
    const { onChange } = this.props

    if (onChange) {
      onChange(id)
    }
  }

  render() {
    const { list, selectedOrganizations, t, encoder, isPrivate } = this.props

    return (
      <Field>
        <Field.Label>{t('labels:select_organization')}</Field.Label>
        <Field.Control>
          <Row style={{ marginBottom: '-3.2rem' }}>
            {!_.isEmpty(list) &&
              list.map(organization => {
                const { id, title, logo } = organization
                const isDisable =
                  !_.isEmpty(selectedOrganizations) &&
                  !selectedOrganizations.includes(id) &&
                  encoder === 'conference' &&
                  isPrivate
                    ? true
                    : false

                return (
                  <Col key={id} md={3}>
                    <Organization
                      active={
                        !_.isEmpty(selectedOrganizations) &&
                        selectedOrganizations.includes(id)
                      }
                      onClick={() => this.handleChange(id)}
                      disabled={isDisable}
                    >
                      <Organization.Checkbox>
                        <Checkbox
                          checked={selectedOrganizations.includes(id)}
                        />
                      </Organization.Checkbox>
                      <Organization.Image src={logo ? logo.link : ''} alt="" />
                      <Organization.Name>{title}</Organization.Name>
                    </Organization>
                  </Col>
                )
              })}
          </Row>
        </Field.Control>
      </Field>
    )
  }
}

export default withTranslation()(OrganizationPicker)
