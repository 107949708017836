import { call, put } from 'redux-saga/effects'

import * as maze from '../actions/mazeBuilder'
import { mazeProvider } from '../../services/api/maze'

export function* createMaze(action) {
  try {
    yield put(maze.handleMazeInput('saving', true))
    const response = yield call(mazeProvider, action.payload)
    if (response.status === 200) {
      yield put(maze.handleMazeInput('mazeId', response.data.data.id))
      yield put(maze.handleMazeInput('saving', false))
    }
  } catch (err) {
    yield put(maze.handleMazeInput('saving', true))
  }
}
