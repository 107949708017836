import React, { useState, useEffect, Fragment } from 'react'
import {
  useMeetingManager,
  useToggleLocalMute,
  useRosterState,
  // useAudioVideo,
  useActiveSpeakersState
  // useActiveSpeakerDetector
} from 'amazon-chime-sdk-component-library-react'
import http from '../../../../../services/api/http'
import { api } from '../../../../../services'
import { config } from '../../../../../constants'
let initSocketConf = null
let connectionInterval = null
let inConferenceInterval = null

const PodcastChime = props => {
  const {
    userOrg,
    eventId,
    isConfCreator,
    // isConferenceHost,
    isEnd,
    endEvent,
    isLive,
    joinStatus,
    isLeaving,
    isMicOff,
    hostEmails,
    user: { username, id: userId },
    onParticipants,
    changeJoinLoadingStatus,
    setLoading,
    setCurrentSpeakerIds
  } = props
  const meetingManager = useMeetingManager()
  // const { activeSpeaker } = useActiveSpeakerDetector()
  const reqURL = `events/${props.eventId}/chime/`
  const eventTitle = `event_${props.eventId}`
  const { roster } = useRosterState()
  // const audioVideo = useAudioVideo()
  const { toggleMute } = useToggleLocalMute()
  const activeSpeakers = useActiveSpeakersState()
  const activeTileId = activeSpeakers[0]
  const attendees = Object.values(roster)
  const [eventStartTime, setEventStartTIme] = useState(null)
  // const [stream, setStream] = useState(null)

  useEffect(() => {
    if (roster && attendees && attendees.length > 0) {
      let attendeeNum = 0
      attendees.forEach(attendee => {
        let { externalUserId } = attendee
        let nameIndex = externalUserId.indexOf('recording#User')
        // const name = externalUserId.split("#")[0];
        attendeeNum = nameIndex === -1 ? attendeeNum + 1 : attendeeNum
      })
      // streamCount(attendeeNum)

      onParticipants({
        roster: roster,
        attendees: attendees.filter(
          item => !item.externalUserId.includes('recording#User')
        ),
        streamCount: attendeeNum,
        hostEmails: hostEmails
      })
    }
  }, [roster])
  // useEffect(() => {
  //   if (!audioVideo) return

  //   const handleVolumeChange = (attendeeId, volume) => {
  //     // // Update attendee's audio status based on volume level
  //     setCurrentSpeakerIds(prevAttendees => {
  //       // If volume is greater than 0, return an array with the attendeeId
  //       if (volume > 0) {
  //         return [attendeeId]
  //       } else {
  //         // If volume is 0, return an empty array
  //         return []
  //       }
  //     })
  //   }

  //   // Subscribe to volume indicator changes for each attendee
  //   Object.keys(roster).forEach(attendeeId => {
  //     audioVideo.realtimeSubscribeToVolumeIndicator(
  //       attendeeId,
  //       handleVolumeChange
  //     )
  //   })

  //   // Cleanup
  //   return () => {
  //     Object.keys(roster).forEach(attendeeId => {
  //       audioVideo.realtimeUnsubscribeFromVolumeIndicator(attendeeId)
  //     })
  //   }
  // }, [roster, audioVideo, setCurrentSpeakerIds])

  // setting active speakers id
  useEffect(() => {
    setCurrentSpeakerIds([activeTileId])
  }, [activeTileId, activeSpeakers])
  // When join button is clicked
  useEffect(() => {
    if (isLive && joinStatus) {
      startPodcast()
    }
    // return () => {
    //   // Clean up by stopping the stream when component unmounts
    //   if (stream) {
    //     stream.getTracks().forEach(track => track.stop())
    //   }
    // }
  }, [joinStatus])
  // When end button is clicked
  useEffect(() => {
    if (joinStatus && isEnd && isLive) {
      endPodcast()
    }
  }, [isEnd])
  // When leave button is clicked
  useEffect(() => {
    if (isLive && joinStatus && isLeaving) {
      leavePodcast()
    }
  }, [isLeaving])
  // mute local mic
  useEffect(() => {
    if (initSocketConf) {
      toggleMute()
      // initSocketConf.send(
      //   JSON.stringify({
      //     action: 'muteUser',
      //     content: { user, hostList, isMicOff },
      //     channel_type: 'EVENT',
      //     channel_id: eventId
      //   })
      // )
    }
  }, [isMicOff])
  const startPodcast = async () => {
    // Fetch the meeting and attendee data from your server
    // const {
    //   userId,
    //   username,
    //   handleBroadcastChange,
    //   isConferenceHost,
    //   allowedUser,
    //   onStreamStopped,
    //   stopstream,
    //   eventId
    // } = props

    setLoading(true)
    // setIsJoined(true)
    // setShowPrompt(true)
    // handleBroadcastChange()

    const payload = {
      title: eventTitle,
      name: `${username}#${userId}`,
      region: 'us-east-1'
    }
    // Use the join API to create a meeting session
    const response = await http({
      method: 'PUT',
      url: `${reqURL}join`,
      data: payload
    }).catch(e => {
      console.log('Error while join: ', e.message)
      //   setConfFailed(true)
      //   setConfErrorMsg(e.message)
      //   onStreamStopped()
      //   stopstream()
      //   setShowModal(false)
      //   setShowPrompt(false)
      changeJoinLoadingStatus(false)
      //   prompt = null
      //   handleBroadcastChange()
      return
    })
    if (!response) {
      setLoading(false)
      return
    }
    if (response) {
      // request audio
      const getUserMediaConstraints = { audio: true, video: false }

      async function getMediaStream() {
        try {
          const mediaStream = await navigator.mediaDevices.getUserMedia(
            getUserMediaConstraints
          )
          // const stream = await navigator.mediaDevices.getUserMedia(
          //   getUserMediaConstraints
          // )
          // join meeting
          const { Meeting, Attendee } = response && response.data.JoinInfo
          const joinData = {
            meetingInfo: Meeting,
            attendeeInfo: Attendee,
            mediaStreamConstraints: { audio: mediaStream }
          }
          await meetingManager.join(joinData)
          // Disable video after joining
          await meetingManager.audioVideo.chooseVideoInputDevice(null)
          // At this point you can let users setup their devices, or start the session immediately
          await meetingManager.start()
        } catch (error) {
          console.error('Error accessing media devices:', error)
        }
      }

      getMediaStream()
    }

    // handleMeetingEvents()
    initializeSocket()
    inConferenceInterval = setInterval(() => {
      initSocketConf.send(
        JSON.stringify({
          action: 'inConference',
          userId: userId,
          channel_id: eventId,
          channel_type: 'EVENT'
        })
      )
    }, 60000)

    // if (!isConferenceHost && allowedUser && allowedUser.length === 0) {
    //   selectAudioInput(null)
    // }

    // if (isConferenceHost) {
    //   toggleVideoRef.current && toggleVideoRef.current.click()
    // }
  }
  const initializeSocket = () => {
    initSocketConf = new window.WebSocket(
      `${config.socketURL}?channel_type=EVENT&channel_id=${eventId}`
    )
    initSocketConf.onopen = () => {
      if (isConfCreator) {
        initSocketConf.send(
          JSON.stringify({
            action: 'startConference',
            stream_id: `event_${eventId}`,
            channel_type: 'EVENT',
            channel_id: eventId
          })
        )
      }

      initSocketConf.send(
        JSON.stringify({
          action: 'getEventStartDate',
          channel_type: 'EVENT',
          channel_id: eventId,
          user_id: userId
        })
      )

      initSocketConf.send(
        JSON.stringify({
          action: 'requestWhiteboardStatus',
          channel_id: eventId,
          userId
        })
      )

      initSocketConf.send(
        JSON.stringify({
          action: 'connectStream',
          channel_type: 'EVENT',
          channel_id: eventId,
          user_id: userId,
          org_id: userOrg
        })
      )
      connectionInterval = setInterval(() => {
        initSocketConf.send(
          JSON.stringify({
            action: 'pingPong',
            msg: 'ping'
          })
        )
      }, 20000)
    }

    initSocketConf.onmessage = event => {
      const data = JSON.parse(event.data)
      console.log('event log', event, data)
      if (
        data.event === 'muteUser'
        // &&  data.content.id === JSON.stringify(userId)
      ) {
        console.log('event log', event, data)
        // let { hostList, user, isMicOff } = data.content
        // changeParticipantsState('MUTE', user, hostList, isMicOff)
      }

      // if (data.event === 'updateParticipant') {
      //   setParticipants(data.participants)
      //   props.App.addToParticipants(data.participants)
      // }

      if (data.event === 'getEventStartDate') {
        console.log('event time received', eventStartTime)
        if (!eventStartTime) {
          console.log('event time inside the not only condition')
          setEventStartTIme(data.message)
        }
      }
      if (data.event === 'stopConference') {
        if (inConferenceInterval) {
          clearInterval(inConferenceInterval)
          inConferenceInterval = null
        }
      }
    }

    initSocketConf.onclose = () => {
      console.log('stop web socket ko')
      clearInterval(connectionInterval)
    }

    initSocketConf.onerror = error => {
      console.log('Error ' + error.message)
      clearInterval(connectionInterval)
    }
  }

  const stopSocket = () => {
    if (isConfCreator) {
      initSocketConf.send(
        JSON.stringify({
          action: 'stopConference',
          stream_id: `event_${eventId}`,
          channel_type: 'EVENT',
          channel_id: eventId,
          joinStatus: joinStatus
        })
      )
    }
    initSocketConf.send(
      JSON.stringify({
        action: 'disconnectStream',
        channel_type: 'EVENT',
        channel_id: eventId,
        user_id: userId,
        org_id: userOrg
      })
    )
    clearInterval(connectionInterval)
    connectionInterval = null
  }
  // const handleMeetingEvents = () => {
  //   meetingManager.audioVideo.addObserver({
  //     eventDidReceive: (name, attributes) => {
  //       const { meetingHistory, ...otherAttributes } = attributes
  //       switch (name) {
  //         case 'audioInputFailed':
  //         case 'videoInputFailed':
  //         case 'meetingStartFailed':
  //         case 'meetingFailed':
  //           meetingEvents.push({
  //             name,
  //             attributes: {
  //               ...otherAttributes,
  //               meetingHistory: meetingHistory.filter(({ timestampMs }) => {
  //                 return Date.now() - timestampMs < 5 * 60 * 1000
  //               })
  //             }
  //           })
  //           setConfFailed(true)
  //           if (name === 'meetingFailed') {
  //             setConfErrorMsg(
  //               'Unstable Internet Connection. Trying To Connect.'
  //             )
  //           } else if (name === 'audioInputFailed') {
  //             setConfErrorMsg(
  //               'Please Confirm Your Audio Device Working Properly.'
  //             )
  //           } else if (name === 'videoInputFailed') {
  //             setConfErrorMsg('Please Confirm Your Camera Working Properly.')
  //           } else if (name === 'meetingStartFailed') {
  //             setConfErrorMsg(
  //               'Failed To Join The Meeting. Please Try To Rejoin.'
  //             )
  //           } else {
  //             setConfErrorMsg('')
  //           }
  //           handleEndMeetingEvents()
  //           break
  //         default:
  //           meetingEvents.push({
  //             name,
  //             attributes: otherAttributes
  //           })
  //           setConfFailed(false)
  //           setConfErrorMsg('')
  //           break
  //       }
  //     }
  //   })
  // }
  const endPodcast = async () => {
    // const { handleBroadcastChange, endChimeEvent, App } = props

    const payload = {
      title: eventTitle
    }
    // App.onHeaderItemClick()
    // setShowEndModal(false)
    // setShowModal(false)
    // setShowVideo(false)
    // prompt = null
    // setShowPrompt(false)
    // setVideoTiles(false)
    // sendWhiteboardStatus(false)
    // await handleBroadcastChange()
    await http({
      method: 'PUT',
      url: `${reqURL}end`,
      data: payload
    })
    stopSocket()
    handleStopApi()
    setTimeout(() => {
      // handleEndMeetingEvents()
      endEvent()
      // checkEndCondition()
    }, 2000)
    // if (inConferenceInterval) {
    //   clearInterval(inConferenceInterval)
    //   inConferenceInterval = null
    // }
  }
  const handleStopApi = () => {
    // const { onStreamStopped, stopstream } = props
    api.events
      .stopLiveEvent(eventId)
      .then(res => {
        if (res.status === 200) {
          // onStreamStopped()
          // stopstream()
        }
      })
      .catch(err => {
        console.log('error in stopping event', err)
      })
  }
  const leavePodcast = async () => {
    if (meetingManager) {
      await meetingManager.leave()
      stopSocket()
    }
  }
  return <Fragment></Fragment>
}
export default PodcastChime
