import React, { Component, Fragment } from 'react'
import { Alert, message } from 'antd'
import _ from 'lodash'
import { TopPageLink, Spinner } from '../../../components'
import history from '../../../history'
import ModalScormContent from './internal/ModalScormContent'
import ViewContent from './View-content'
import { getPercent } from '../../../utils'
import api from '../../../services/api'
import { withTranslation } from 'react-i18next'
import { onBodyLoad } from '../components/Player'

const checkFetching = (isFetching, item, isOwner, t, App) => {
  if (isFetching) {
    return <Spinner height="40rem" />
  }

  if (item && item.hasOwnProperty('id')) {
    return <ViewContent App={App} isOwner={isOwner} />
  }

  return (
    <section className="section">
      <Alert type="error" message={t('errors:not_found_course')} />
    </section>
  )
}

class SingleCoursePage extends Component {
  state = {
    activeTab: 'course_overview',
    activeLessonId: null,
    modalVisible: false,
    courseCloneTemplate: null,
    isClone: false,
    isPortrait: true,
    userToken: '',
    scormPlayer: '',
    canPlay: false,
    scanStatus: '',
    checkfileStatus: ''
  }

  componentDidMount() {
    const { getDiscussionLog } = this.props
    const { id } = this.props.match.params
    this.getApiData()
    this.getScormStatus(id)
    this.getScormScanStatus(id)

    if (getDiscussionLog) {
      const type = 'course'
      getDiscussionLog({ id, type })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { scanStatus, checkfileStatus } = this.state
    const {
      course: {
        item: { is_new, id, publisher_id, creator_id }
      },
      user: {
        info: { id: userId }
      },
      viewCourse,
      t
    } = this.props
    if (
      (prevState.scanStatus !== scanStatus && scanStatus) ||
      (prevState.checkfileStatus !== checkfileStatus && checkfileStatus)
    ) {
      if (scanStatus === 'clean' && checkfileStatus === 'completed') {
        if (publisher_id !== null || userId === creator_id) {
          this.setState({ canPlay: true })
          this.enroll()
          if (is_new) {
            return viewCourse(id)
          }
        }
      }
      if (
        scanStatus !== 'clean' &&
        checkfileStatus !== 'completed' &&
        publisher_id !== null
      ) {
        message.error(t('v4:upload_file_corrupt'))
        return
      }
      if (publisher_id === null && publisher_id !== creator_id) {
        message.warning(t('v4:course_not_published'))
        return
      }
    }
  }

  componentWillUnmount() {
    const { resetCourseById, clearNotes, clearDiscussion } = this.props
    resetCourseById()
    clearNotes()
    clearDiscussion()
  }

  getApiData = () => {
    const { getCourseById, match } = this.props
    getCourseById(match.params.id).then(course => {
      if (course && course.is_new) {
        // this.enroll()
        // return viewCourse(course.id)
      }
    })
  }

  getScormStatus = courseId => {
    api.scorm
      .getScormStatus(courseId)
      .then(response => {
        if (response.data && response.data.status) {
          this.setState({ checkfileStatus: response.data.status })
          // if (response.data.status === 'completed') {
          // const {course: {
          //   item: { is_new }
          // },
          // viewCourse  } = this.props
          // if (is_new) {
          //   this.enroll()
          //   return viewCourse(courseId)
          // }
          // }
        }
      })
      .catch(err => {
        console.log('response err', err)
      })
  }

  getScormScanStatus = courseId => {
    api.scorm
      .getScormScanStatus(courseId)
      .then(response => {
        if (response.data && response.data.status) {
          this.setState({ scanStatus: response.data.status })
          // if (response.data.status === 'clean') {
          //   const {course: {
          //     item: { is_new }
          //   },
          //   viewCourse  } = this.props
          //   if (is_new) {
          //     this.enroll()
          //     return viewCourse(courseId)
          //   }
          // }
        }
      })
      .catch(err => {
        console.log('response err', err)
      })
  }

  handleChangeTab = activeTab => {
    const data = { activeTab }
    const { course } = this.props
    const { activeLessonId } = this.state

    if (!activeLessonId && course.item.courses_lessons.length) {
      data.activeLessonId = course.item.courses_lessons[0].id
    }

    this.setState(data)
  }

  handleChangeLesson = activeLessonId => {
    this.setState({
      activeLessonId,
      activeTab: 'lessons'
    })
  }

  toggleModal = (isGoBack = false) => {
    this.setState(
      state => ({
        modalVisible: !state.modalVisible
      }),
      () => {
        if (isGoBack) {
          this.goBack()
        }
      }
    )
  }

  goBack = () => {
    const {
      course: {
        item: { creator_id: creatorId }
      },
      user: {
        info: { id: userId }
      }
    } = this.props
    const isOwner = userId === creatorId
    const path = this.getPath(isOwner)
    if (path) {
      history.push(path)
    }
  }

  getLesson = id => {
    const {
      enrollCourse,
      getLessonById,
      course: {
        item: { id: courseId, publisher_id: publisherId }
      }
    } = this.props

    getLessonById(id).then(({ pages }) => {
      if (!publisherId) return
      enrollCourse(courseId, id, !!pages.length && pages[0].uid)
    })
  }

  getScormByCourseId = id => {
    const {
      user: {
        info: { id: userId }
      }
    } = this.props
    onBodyLoad(id, userId)
  }

  openLessonContent = () => {
    const {
      match: {
        params: { id: course_id }
      }
    } = this.props
    this.toggleModal()
    this.getScormByCourseId(course_id)
    this.postCheckInData()
  }

  postCheckInData = () => {
    const {
      match: {
        params: { id }
      }
    } = this.props

    const data = {
      course_id: id
      // lesson_id: lid
    }
    api.courses
      .checkInCourse(id, data)
      .then(({ data }) => {
        this.setState({
          userToken: data.user_token
        })
      })
      .catch(e => {
        console.log('response log checkin error', e)
      })
  }

  cloneCourse = data => {
    const { courses } = this.props

    if (courses.courseCloningProgress) return

    this.setState(
      {
        isClone: true
      },
      () => {
        this.getCourseById(data)
      }
    )
  }

  getCourseById = data => {
    const { getCourseById, initCloneSocket, user } = this.props
    const { id, title } = data

    initCloneSocket({ courseId: id, user })

    getCourseById(id).then(({ courses_lessons }) => {
      const courseLessons = _.map(courses_lessons, ({ title, id }) => {
        const newLesson = {
          title,
          id,
          isChecked: true
        }
        return newLesson
      })
      this.setState({
        courseCloneTemplate: {
          courseId: id,
          courseTitle: `Copy of ${title}`,
          courseLessons,
          totalLesson: courseLessons.length
        }
      })
    })
  }

  openNextLesson = id => {
    const {
      course: {
        item: { courses_lessons: lessons = [] }
      }
    } = this.props
    const activeLessonIndex = lessons.findIndex(i => i.id === id)

    if (activeLessonIndex < lessons.length - 1) {
      const nextLessonId = lessons[activeLessonIndex + 1].id

      this.getLesson(nextLessonId)

      this.setState({
        activeLessonId: nextLessonId
      })
    } else {
      this.toggleModal()
    }
  }

  enroll = () => {
    const {
      course: {
        item: { id }
      },
      enrollCourse
    } = this.props

    enrollCourse(id)
  }

  unenroll = () => {
    const {
      course: {
        item: { id }
      },
      unenrollCourse
    } = this.props

    unenrollCourse(id)
  }

  getPercent = lesson => {
    const {
      id,
      info: { pages_uids: uids = [] }
    } = lesson
    const { enrollment } = this.props.course.item
    if (!enrollment) {
      return 0
    }
    const { progress = {} } = enrollment
    const lessonProgress = progress[id]

    if (lessonProgress) {
      const completedPages = uids.filter(uid => lessonProgress.includes(uid))

      if (completedPages.length === uids.length) {
        return 100
      }
      return getPercent(completedPages.length, uids.length)
    }
    return 0
  }

  handleSwitchViewMode = () => {
    this.setState(state => ({ isPortrait: !state.isPortrait }))
  }

  getPath = isOwner => (isOwner ? '/training' : '/training')

  render() {
    const {
      course: {
        isFetching,
        item: { creator_id: creatorId },
        item
      },
      user: {
        info: { id: userId }
      },
      enrollCourse,
      t
    } = this.props

    const { activeLessonId, modalVisible, userToken } = this.state
    const isOwner = userId === creatorId
    const skillJourney =
      this.props.location.state && this.props.location.state.skillJourney
    const skillJourneyId =
      this.props.location.state && this.props.location.state.skillJourneyId
    console.log('view scorm mode', this.props, 'state', this.state)

    return (
      <Fragment>
        <TopPageLink
          page={skillJourney ? t('v4:skill') : t('navigation:training')}
          path={
            skillJourney
              ? `/skill-journey/${skillJourneyId}`
              : this.getPath(isOwner)
          }
        />

        {checkFetching(isFetching, item, isOwner, t, this)}

        {modalVisible && (
          <ModalScormContent
            modalVisible={modalVisible}
            toggleModal={this.toggleModal}
            openNextLesson={this.openNextLesson}
            enrollCourse={enrollCourse}
            course={item}
            userToken={userToken}
            activeLessonId={activeLessonId}
          />
        )}
        {/* {modalVisible && !isPortrait && (
          <ModalLessonLandscape
            modalVisible={modalVisible}
            toggleModal={this.toggleModal}
            openNextLesson={this.openNextLesson}
            enrollCourse={enrollCourse}
            course={item}
            userToken={userToken}
            activeLessonId={activeLessonId}
          />
        )} */}
        {/* {isClone && (
          <CloneModal
            width="800px"
            visible={isClone}
            data={courseCloneTemplate}
            app={this}
          />
        )} */}
      </Fragment>
    )
  }
}

export default withTranslation('coursepage')(SingleCoursePage)
