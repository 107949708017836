import React, { useEffect, useState } from 'react'
import { Canvas, Node, Edge } from 'reaflow'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import { Icon, Tooltip } from 'antd'
import './styles.scss'
import { getNestedChildren } from '../mazeHelper'

const MazeTree = ({
  data,
  showRightPanel,
  markDeadEnd,
  unmarkDeadEnd,
  goToTemplate
}) => {
  const [nodes, setNodes] = useState([])
  const [links, setLinks] = useState([])
  const [dimensions, setDimensions] = useState({ width: 2000, height: 2000 })

  useEffect(() => {
    if (data) {
      customizeTreeData(data)
    }
  }, [data])

  const setWidthHeight = layout => {
    const padding = 200 // Add padding for better visibility
    setDimensions({
      width: Math.max(layout.width + padding, window.innerWidth),
      height: Math.max(layout.height + padding, window.innerHeight)
    })
  }

  const handleGoToTemplate = (e, code) => {
    if (e.detail === 2) {
      goToTemplate(code)
    }
  }

  const customizeTreeData = data => {
    const nest = getNestedChildren(data)
    if (nest && nest[0]) {
      generateIdealPath(data, nest[0])
    }
  }

  const generateIdealPath = (data, JSON = {}) => {
    let idealPath = []
    const checkIdealCondition = arr => {
      if (!arr || arr.length === 0) return
      arr.forEach(item => {
        if (item.values.is_ideal_option) {
          const scenario = item.children
          delete item.children
          idealPath.push(item)
          if (scenario.length === 0) return
          const scenarioChildren = scenario[0].children
          delete scenario[0].children
          idealPath.push(scenario[0])
          checkIdealCondition(scenarioChildren)
        }
      })
    }

    const getIdealPath = () => {
      if (JSON.code === 1) {
        const children = JSON.children
        delete JSON.children
        idealPath.push(JSON)
        checkIdealCondition(children)
      }
    }

    getIdealPath()

    const ids = new Set(idealPath.map(d => d.code))
    const merged = [...idealPath, ...data.filter(d => !ids.has(d.code))]
    const formattedData = merged.map(item => {
      const isIdealPath = idealPath.find(e => e.code == item.code)
      return isIdealPath ? { ...item, pathType: 'IDEAL' } : item
    })

    const newTreeData = formattedData.sort((a, b) => a.code - b.code)
    setLinksAndNodes(newTreeData)
  }

  const setLinksAndNodes = (data = []) => {
    let myLinks = []
    const newNodes = data.map(item => ({
      id: item.code,
      type: item.type,
      content: item.values && item.values.content,
      pathType: item.pathType,
      values: item.values,
      noChildren: item.children && item.children.length < 1,
      isDeadEnd: item.is_dead_end,
      index: item.index,
      height: 125,
      width: 250
    }))

    data.forEach(item => {
      if (item && item.parent) {
        let isNormalIdealPath = false
        const otherChildItem = data.find(el => el.code == item.parent)
        if (otherChildItem.pathType == 'IDEAL' && item.pathType == 'IDEAL') {
          isNormalIdealPath = true
        }
        myLinks.push({
          from: item.parent,
          to: item.code,
          id: `${item.parent}-${item.code}`,
          isIdealPath: isNormalIdealPath
        })
      }

      if (item && item.possible_scenario_code) {
        const possibleScenarioItem = data.filter(
          item => item.type == 'Scenario'
        )[item.possible_scenario_code - 1]

        myLinks.push({
          to: possibleScenarioItem.code,
          from: item.code,
          id: `${item.code}-${possibleScenarioItem.code}`
        })
      }
    })

    setNodes(newNodes)
    setLinks(myLinks)
  }

  return (
    <div className="maze-tree-wrapper">
      <TransformWrapper
        initialScale={0.75}
        minScale={0.25}
        maxScale={2}
        limitToBounds={false}
        centerOnInit={true}
        wheel={{ step: 0.25 }}
        doubleClick={{ disabled: true }}
        panning={{ disabled: false }}
      >
        {({ zoomIn, zoomOut, resetTransform }) => (
          <>
            <div className="maze-tree-wrapper__controls">
              <Tooltip title="Zoom In">
                <Icon type="zoom-in" onClick={() => zoomIn(0.25)} />
              </Tooltip>
              <Tooltip title="Zoom Out">
                <Icon type="zoom-out" onClick={() => zoomOut(0.25)} />
              </Tooltip>
              <Tooltip title="Reset">
                <Icon type="reload" onClick={() => resetTransform()} />
              </Tooltip>
            </div>

            <TransformComponent
              wrapperStyle={{
                position: 'relative',
                width: '100%',
                height: '100%'
              }}
            >
              {showRightPanel && nodes.length > 0 && links.length > 0 && (
                <div
                  style={{
                    width: dimensions.width,
                    height: dimensions.height,
                    position: 'relative'
                  }}
                >
                  <Canvas
                    nodes={nodes}
                    edges={links}
                    maxWidth={dimensions.width}
                    maxHeight={dimensions.height}
                    direction="DOWN"
                    fit={false}
                    center={false}
                    node={nodeProps => (
                      <Node>
                        {event => (
                          <foreignObject
                            height={event.height}
                            width={event.width}
                            x={0}
                            y={0}
                          >
                            <div
                              className="main-node"
                              style={{
                                borderColor: nodeProps.properties.isDeadEnd
                                  ? 'red'
                                  : 'black',
                                background:
                                  nodeProps.properties.pathType === 'IDEAL'
                                    ? '#5057d5'
                                    : nodeProps.properties.type ===
                                        'Scenario' &&
                                      nodeProps.properties.content
                                    ? '#5057d5'
                                    : nodeProps.properties.type === 'Option' &&
                                      nodeProps.properties.values
                                        .is_ideal_option
                                    ? '#5057d5'
                                    : nodeProps.properties.content
                                    ? '#009d00'
                                    : 'white',
                                color:
                                  nodeProps.properties.pathType === 'IDEAL' ||
                                  nodeProps.properties.content
                                    ? 'white'
                                    : 'black'
                              }}
                              onClick={e =>
                                handleGoToTemplate(e, nodeProps.properties.id)
                              }
                            >
                              {nodeProps.properties.type}{' '}
                              {nodeProps.properties.index}
                              {nodeProps.properties.noChildren && (
                                <div
                                  className="deadend"
                                  style={{
                                    backgroundColor: nodeProps.properties
                                      .isDeadEnd
                                      ? 'red'
                                      : 'black'
                                  }}
                                  onClick={() =>
                                    nodeProps.properties.isDeadEnd
                                      ? unmarkDeadEnd(nodeProps.properties.id)
                                      : markDeadEnd(nodeProps.properties.id)
                                  }
                                >
                                  {nodeProps.properties.isDeadEnd
                                    ? 'Unmark as deadend'
                                    : 'Mark as deadend'}
                                </div>
                              )}
                            </div>
                          </foreignObject>
                        )}
                      </Node>
                    )}
                    edge={edgeProps => (
                      <Edge
                        style={{
                          stroke: edgeProps.properties.isIdealPath
                            ? '#5057d5'
                            : 'black',
                          strokeWidth: edgeProps.properties.isIdealPath
                            ? '3px'
                            : '1px'
                        }}
                      />
                    )}
                    onLayoutChange={layout => setWidthHeight(layout)}
                  />
                </div>
              )}
            </TransformComponent>
          </>
        )}
      </TransformWrapper>
    </div>
  )
}

export default MazeTree
