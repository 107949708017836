import {
  ORGANIZATIONS_SUCCESS,
  ORGANIZATIONS_FETCHING,
  ORGANIZATIONS_AUTOCOMPLETE_SUCCESS,
  ORGANIZATIONS_AUTOCOMPLETE_FETCHING,
  ORGANIZATIONS_FETCHING_BY_ID,
  ORGANIZATIONS_SUCCESS_BY_ID,
  USERS_SUCCESS_BY_ORGANIZATION_ID,
  RESET_USERS_SUCCESS_BY_ORGANIZATION_ID,
  USERS_FETCHING_BY_ORGANIZATION_ID,
  DELETE_ORGANIZATION_SUCCESS,
  DELETE_ORGANIZATION_USER_SUCCESS,
  DELETE_GROUP_SUCCESS,
  UPDATE_GROUP_SUCCESS,
  CREATE_GROUP_SUCCESS,
  USERS_SEARCH_ORGANIZATION,
  USERS_SEARCH_ORGANIZATION_FAIL,
  USERS_SEARCH__ORGANIZATION_SUCCESS,
  ORGANIZATIONS_FAILURE
} from '../types'

const initialState = {
  isFetching: false,
  list: [],
  autoComplete: [],
  organizationById: {
    item: null,
    isFetching: false
  },
  usersByOrganizationId: {
    list: [],
    isFetching: false
  },
  searchResults: {},
  isSearching: false,
  searchFailed: false,
  orgListFail: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        list: action.payload,
        orgListFail: false
      }

    case ORGANIZATIONS_FETCHING:
      return {
        ...state,
        isFetching: action.payload,
        orgListFail: false
      }
    
    case ORGANIZATIONS_FAILURE:
      return {
        ...state,
        orgListFail: true
      }

    case ORGANIZATIONS_AUTOCOMPLETE_SUCCESS:
      return {
        ...state,
        autoComplete: action.payload
      }

    case ORGANIZATIONS_AUTOCOMPLETE_FETCHING:
      return {
        ...state,
        isFetching: action.payload
      }

    case ORGANIZATIONS_SUCCESS_BY_ID:
      return {
        ...state,
        organizationById: {
          ...state.organizationById,
          item: action.payload
        }
      }

    case ORGANIZATIONS_FETCHING_BY_ID:
      return {
        ...state,
        organizationById: {
          ...state.organizationById,
          isFetching: action.payload
        }
      }

    case USERS_SUCCESS_BY_ORGANIZATION_ID:
      return {
        ...state,
        usersByOrganizationId: {
          ...state.usersByOrganizationId,
          list: action.payload
        }
      }

    case RESET_USERS_SUCCESS_BY_ORGANIZATION_ID:
      return {
        ...state,
        usersByOrganizationId: initialState.usersByOrganizationId
      }

    case USERS_FETCHING_BY_ORGANIZATION_ID:
      return {
        ...state,
        usersByOrganizationId: {
          ...state.usersByOrganizationId,
          isFetching: action.payload
        }
      }

    case DELETE_ORGANIZATION_SUCCESS:
      return {
        ...state,
        list: { rows: state.list.rows.filter(i => i.id !== action.payload) }
      }

    case DELETE_ORGANIZATION_USER_SUCCESS:
      return {
        ...state,
        usersByOrganizationId: {
          ...state.usersByOrganizationId,
          list: state.usersByOrganizationId.list.filter(
            i => i.user_id !== action.payload
          )
        }
      }

    case DELETE_GROUP_SUCCESS:
      let orgIndex = state.list.rows.findIndex(
        i => i.id === action.payload.orgId
      )
      let activeOrg = state.list.rows[orgIndex]
      return {
        ...state,
        list: {
          rows: [
            ...state.list.rows.slice(0, orgIndex),
            {
              ...activeOrg,
              groups: activeOrg.groups.filter(
                i => i.id !== action.payload.groupId
              )
            },
            ...state.list.rows.slice(orgIndex + 1)
          ]
        }
      }

    case CREATE_GROUP_SUCCESS:
      orgIndex = state.list.rows.findIndex(i => i.id === action.payload.orgId)
      activeOrg = state.list.rows[orgIndex]
      return {
        ...state,
        list: {
          rows: [
            ...state.list.rows.slice(0, orgIndex),
            {
              ...activeOrg,
              groups: activeOrg.groups.concat([action.payload.data])
            },
            ...state.list.rows.slice(orgIndex + 1)
          ]
        }
      }

    case UPDATE_GROUP_SUCCESS:
      orgIndex = state.list.rows.findIndex(i => i.id === action.payload.orgId)
      activeOrg = state.list.rows[orgIndex]
      const groupIndex = activeOrg.groups.findIndex(
        i => i.id === action.payload.id
      )
      return {
        ...state,
        list: {
          rows: [
            ...state.list.rows.slice(0, orgIndex),
            {
              ...activeOrg,
              groups: [
                ...activeOrg.groups.slice(0, groupIndex),
                action.payload.data,
                ...activeOrg.groups.slice(groupIndex + 1)
              ]
            },
            ...state.list.rows.slice(orgIndex + 1)
          ]
        }
      }

    case USERS_SEARCH_ORGANIZATION:
      return {
        ...state,
        isSearching: true
      }
    
    case USERS_SEARCH__ORGANIZATION_SUCCESS:
        return {
          // searchResults: action.payload
          ...state,
          isSearching: false,
          usersByOrganizationId: {
            ...state.usersByOrganizationId,
            list: action.payload
            }
        }

    case USERS_SEARCH_ORGANIZATION_FAIL:
      return {
        ...state,
        isSearching: false,
        searchFailed: true,
        usersByOrganizationId: initialState.usersByOrganizationId
      }

    default:
      return state
  }
}
