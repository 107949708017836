import styled from 'styled-components'
import { Pagination } from 'antd'

import { styleguide } from '../../constants'

const { colors } = styleguide

export const SlPagination = styled(Pagination)`
  && {
    color: ${colors.blue};

    .ant-pagination-item {
      background-color: ${colors.darkGreyThree};
      border-color: ${colors.darkGreyThree};
      border-radius: 50%;
      font-weight: bold;
      min-width: 1.8rem;
      height: 1.8rem;
      line-height: 1.6rem;

      a {
        margin: 0;
        height: 100%;
        font-size: 1.1rem;
        color: ${colors.darkGreyOne};
      }

      &.ant-pagination-item-active {
        background-color: ${colors.blue};
        border-color: ${colors.blue};

        a {
          color: ${colors.white};
        }
      }
    }
  }
`

export const Arrow = styled.div`
  color: ${colors.blue};
`
