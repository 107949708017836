import React, { Component, Fragment } from 'react'
import { Button, Modal, Spin, Switch } from 'antd'
import { Builder } from '../../../../components'
import nanoid from 'nanoid'
import { withTranslation } from 'react-i18next'
import { builder, general } from '../../../../constants'
import { annotationIcon } from './images'
import { api } from '../../../../services'

const { types } = builder

class EAnnotation extends Component {
  state = {
    visible: false,
    isAnnote: false
  }

  componentDidMount() {
    const {
      pageBuilder: { contentId },
      getById,
      setEditorDefaults
    } = this.props

    this.setState({
      visible: true
    })

    if (setEditorDefaults) {
      setEditorDefaults()
    }

    if (contentId && getById) {
      this.setState({
        loading: true
      })
      getById(contentId)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedItem, changeBackground } = this.props
    console.log('in eannotation', this.props)
    if (prevProps.selectedItem !== selectedItem) {
      if (selectedItem.length !== 0 && !changeBackground) {
        this.handleInsertItem(selectedItem)
      }
      if (selectedItem.length !== 0 && changeBackground) {
        this.handleInsertBackground()
      }
    }
  }

  handleInsertBackground = () => {
    const {
      changeBackground,
      selectedItem,
      emptySelectedItem,
      closeChangeBackground
    } = this.props
    if (selectedItem[0] && changeBackground) {
      const data = {
        uid: nanoid(8),
        type: types.BACKGROUND_IMAGE,
        params: {
          content: '',
          content_id: null
        }
      }

      data.params.content = selectedItem[0].link
      data.params.content_id = selectedItem[0].id

      const {
        annotation: { editor }
      } = this.props

      const pageStructure = editor.params.page.data

      if (!pageStructure.find(node => node.uid === data.uid)) {
        this.handleData([...pageStructure, data])
        closeChangeBackground()
      } else {
        // handleParams(data.uid, { content: link, content_id: id })
      }
      emptySelectedItem()
    }
  }

  handleData = (data, key = false) => {
    const { handleData } = this.props

    if (handleData) {
      handleData(data, key)
    }
  }

  /**
   * Don't use third parameter
   */
  handleParams = (uid, params, settings) => {
    const {
      annotation: { editor }
    } = this.props
    const pageStructure = editor.params.page.data

    console.log({
      uid,
      params,
      settings,
      pageStructure
    })
    const data = pageStructure.map(el =>
      el.uid === uid
        ? {
            ...el,
            params: {
              ...el.params,
              ...params
            },
            ...settings
          }
        : el
    )
    this.handleData(data)
  }

  handleSave = () => {
    const {
      pageBuilder: { contentId, annotationUid },
      annotation: { editor },
      updateById,
      add,
      handleCloseAnnotationModal,
      handleAddNewElement,
      handleUpdateAnnotation
    } = this.props

    const uid = nanoid(8)
    const data = {
      title: editor.params.title,
      rtl: false,
      page: editor.params.page
    }
    if (contentId) {
      updateById(contentId, data).then(() => {
        handleCloseAnnotationModal()
      })
    } else {
      add(data).then(id => {
        if (annotationUid) {
          handleUpdateAnnotation(id)
        } else {
          handleAddNewElement({ uid: uid, data: { content: id } })
        }
        handleCloseAnnotationModal()
      })
    }
  }

  handleShowAnnotationTextModal = () => {
    const { showAnnotationTextModal } = this.props
    showAnnotationTextModal({
      isShowAnnotationText: true
    })
  }

  handleShowAnnotationVideoModal = () => {
    const { showAnnotationVideoModal } = this.props
    showAnnotationVideoModal({
      isShowAnnotationVideo: true
    })
  }

  handleShowAnnotationAudioModal = () => {
    const { showAnnotationAudioModal } = this.props
    showAnnotationAudioModal({
      isShowAnnotationAudio: true
    })
  }

  handleShowAnnotationImageModal = () => {
    const { showAnnotationImageModal } = this.props
    showAnnotationImageModal({
      isShowAnnotationImage: true
    })
  }

  getType = ({ media_format }) => {
    if (media_format) {
      return media_format
    }
    return ''
  }

  handleInsertItem = content => {
    const { emptySelectedItem } = this.props
    const {
      pageBuilder: { uid },
      isStockImage
    } = this.props

    if (uid) {
      this.handleParams(uid, content[0])
    } else {
      if (content.length === 0) {
        return
      }
      const getType = this.getType(content[0])
      if (getType === types.TEXT) {
        this.addElement(content[0].content, getType)
      } else {
        if (isStockImage) {
          const params = {
            title: 'test',
            filename: 'what ever',
            key: 'what ever',
            description: 'test',
            link: content[0].link,
            contentId: content[0].id,
            customData: {},
            isFromPexelImage: true
          }
          api.library.upload(params).then(({ data }) => {
            let dataPxl = {
              content: data.link,
              content_id: data.id
            }
            this.addElement(dataPxl, getType)
          })
        } else {
          this.addElement(
            { content: content[0].link, content_id: content[0].id },
            getType
          )
        }
      }
    }
    emptySelectedItem()
  }

  addElement = (content, type) => {
    const { handleData } = this.props
    const width = type === types.TEXT ? 450 : 400

    const data = {
      uid: nanoid(8),
      type: type,
      params: {
        content: type === types.TEXT ? content : content.content,
        content_id: type === types.TEXT ? null : content.content_id,
        width,
        height: 200,
        x: 0,
        y: 0
      }
    }

    if (handleData) {
      handleData(data, true)
    }
  }

  onChangeAnnote = checked => {
    console.log('check annotate', checked)
    this.setState({
      isAnnote: checked
    })
  }

  render() {
    const { visible, isAnnote } = this.state
    const {
      pageBuilder: { annotationUid, contentId },
      annotation: { editor, isFetching },
      upload,
      t,
      handleCloseAnnotationModal,
      handleChangeBackground
    } = this.props

    console.log({ annotationProps: this.props })

    const pageStructure = editor.params.page.data

    const annotation_icon = (
      <div
        className="toolbar__item-icon"
        style={{ color: 'rgb(103, 104, 108)', fontWeight: 'normal' }}
      >
        <img style={{ marginBottom: '8px' }} src={annotationIcon} alt="" />{' '}
        <span
          style={{ fontSize: '28px', lineHeight: '40px', color: '#67686C' }}
        >
          {t('annotation:modal_title')}
        </span>
      </div>
    )

    const styleLandscape = {
      overflow: 'hidden',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0
    }

    return (
      <Fragment>
        <Modal
          className="custom-ant-modal custom-modal-audio insert-image-modal"
          transparent
          style={styleLandscape}
          title={annotation_icon}
          visible={visible}
          width={'50%'}
          // width="1134px"
          onCancel={() => handleCloseAnnotationModal()}
          okButtonProps={{
            icon: isFetching ? 'loading' : null,
            disabled: isFetching
          }}
          destroyOnClose
          maskClosable={false}
          centered
          footer={[
            <Button
              type="default"
              shape="round"
              size="default"
              className="ant-btn rounded"
              onClick={() => handleCloseAnnotationModal()}
            >
              {general.CANCEL}
            </Button>,
            <Button
              type="primary"
              shape="round"
              size="default"
              className="ant-btn rounded ant-btn-primary"
              onClick={() => this.handleSave()}
            >
              {contentId || annotationUid
                ? isFetching
                  ? t('buttons:updating')
                  : t('buttons:update')
                : isFetching
                ? t('buttons:creating')
                : t('buttons:create')}
            </Button>
          ]}
        >
          <Fragment>
            <Spin spinning={isFetching}>
              <div className="ant-row" style={{ marginBottom: '8px' }}>
                <div className="ant-col ant-col-6">
                  <div className="text-wrapper-block">
                    <div className="create-annotation">
                      {t('media:create_annotation')}
                    </div>
                    <div className="asterisk">
                      * {t('media:popup_over_lesson_text')}
                    </div>
                    <Switch onChange={this.onChangeAnnote} />
                  </div>
                </div>
                <div className="ant-col ant-col-18">
                  <Builder.Toolbar
                    mode={'ANNOTATION'}
                    handleChangeBackground={handleChangeBackground}
                    handleShowAnnotationTextModal={
                      this.handleShowAnnotationTextModal
                    }
                    handleShowAnnotationVideoModal={
                      this.handleShowAnnotationVideoModal
                    }
                    handleShowAnnotationAudioModal={
                      this.handleShowAnnotationAudioModal
                    }
                    handleShowAnnotationImageModal={
                      this.handleShowAnnotationImageModal
                    }
                    upload={upload}
                    handleData={this.handleData}
                    handleParams={this.handleParams}
                    pageStructure={pageStructure}
                  />
                </div>
              </div>
              <div className="ant-row">
                <Builder.Page
                  handleData={this.handleData}
                  isAnnote={isAnnote}
                  handleParams={this.handleParams}
                  pageStructure={pageStructure}
                />
              </div>
            </Spin>
          </Fragment>
        </Modal>
      </Fragment>
    )
  }
}

export default withTranslation()(EAnnotation)
