import React, { Component, createRef } from 'react'
import _ from 'lodash'

import { Page } from './styled'
import {
  Row,
  Col,
  AutoComplete,
  Input,
  Modal,
  Button,
  message as Message
} from 'antd'

import Dropzone from '../../../../../../Courses/Create/internal/CourseDropzone'
import { config, general } from '../../../../../../../constants'
import { Editor } from '../../../../../../../components/ImageEditor'
import { PopupMedia } from '../../../../../../../views/Library/internal'
import {
  getUserList,
  sendEmailToUsers
} from '../../../../../../../services/api/organizations'
import ImageCropContainer from '../../../../../../../components/ImageCrop'

const { ROW, Title, Select } = Page
const Option = Select.Option

export default class Sendmail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modalOpen: false,
      selectedItem: '',
      image: null,
      filteredOrgs: props.organizations,
      groups: [],
      filteredEmails: [],
      sendingEmail: false,
      emailsLoading: false,
      selectedOrgName: ''
    }
    this.imageRef = createRef()
  }

  componentDidMount() {
    const { orgId } = this.props
    if (orgId) {
      this.handleOrganizationChange(orgId)
    }
  }

  useCanva = () => {
    var script = document.createElement('script')
    const url = 'https://sdk.canva.com/designbutton/v2/api.js'
    script.src = url
    script.onload = () => {
      if (window.Canva && window.Canva.DesignButton) {
        window.Canva.DesignButton.initialize({
          apiKey: config.canvaKey
        }).then(api => {
          api.createDesign({
            design: {
              type: 'Poster'
            },
            onDesignPublish: ({ exportUrl, designTitle }) => {
              this.handleImageUrl(exportUrl, designTitle)
            }
          })
        })
      }
    }
    document.body.appendChild(script)
  }

  handleImageUrl = async (url, title = 'userImageForEmail') => {
    let blob = await fetch(url).then(r => r.blob())
    let data = await new Promise(resolve => {
      let reader = new FileReader()
      reader.onload = () => resolve(reader.result)
      reader.readAsDataURL(blob)
    })
    const body = {
      croppedImage: data,
      lastModifiedDate: new Date(),
      name: `${title}.${blob.type.slice(6)}`,
      size: blob.size,
      type: blob.type
    }
    this.uploadImage(body)
  }

  hideModal = () => this.setState({ modalOpen: false })

  handleInsertData = (privateMedia, publicMedia, orgMedia) => {
    let tempData = []
    if (privateMedia) {
      const { rows: privateData } = privateMedia
      privateData.forEach(data => {
        tempData.push(data)
      })
    }

    if (publicMedia) {
      const { rows: publicData } = publicMedia
      publicData.forEach(publicData => {
        tempData.push(publicData)
      })
    }

    if (orgMedia) {
      const { rows: orgData } = orgMedia
      orgData.forEach(orgData => {
        tempData.push(orgData)
      })
    }

    const selectedItem = _.head(tempData.filter(value => value.isSelected))

    this.setState({
      selectedItem
    })
  }

  hidePreviewModal = () => {
    this.setState({
      selectedItem: null
    })
  }

  onDrop = file => {
    const image = URL.createObjectURL(file)
    this.setState({
      image,
      file
    })
  }

  handleFileInsert = item => {
    const imageEditorInst = this.imageRef.current.imageEditorInst
    const data = imageEditorInst.toDataURL()
    const isEdited = !imageEditorInst.isEmptyUndoStack()
    const body = {
      croppedImage: data,
      lastModifiedDate: new Date(),
      name: item.filename,
      size: item.file_size,
      type: item.media_mime_type
    }
    this.setState(
      {
        modalOpen: false,
        selectedItem: null
      },
      () => {
        if (isEdited === true) {
          this.uploadImage(body)
        } else {
          this.setState({
            isImageLoading: false,
            selectedImage: item
          })
        }
      }
    )
  }

  uploadImage = data => {
    this.setState(
      {
        isImageLoading: true
      },
      () => {
        const { upload } = this.props
        const options = { isPublic: true }
        upload(data, options).then(res => {
          this.setState({
            isImageLoading: false,
            selectedImage: res
          })
        })
      }
    )
  }

  onSearch = text => {
    const { organizations } = this.props
    this.setState({
      filteredOrgs: organizations.filter(obj =>
        obj.text.toLowerCase().includes(text.toLowerCase())
      )
    })
  }

  handleUserTypeChange = val => {
    this.setState({ userType: val }, () => this.handleEmails())
  }

  handleEmails = () => {
    const {
      userType,
      signupUsers,
      nonSignupUsers,
      activeUsers,
      totalUsers
    } = this.state
    if (userType === 'signupUsers') {
      this.setState({ filteredEmails: signupUsers })
    } else if (userType === 'nonSignupUsers') {
      this.setState({ filteredEmails: nonSignupUsers })
    } else if (userType === 'activeUsers') {
      this.setState({ filteredEmails: activeUsers })
    } else if (userType === 'all') {
      this.setState({ filteredEmails: totalUsers })
    }
  }

  handleGroupSelect = id => {
    this.setState({ selectedGroupId: id })
  }

  handleOrganizationChange = (ID, param) => {
    const { orgName } = this.props
    const name = param ? param.props.children : orgName
    const id = Number(ID)
    const { response } = this.props
    const groups = response
      .filter(item => item.id === id)
      .map(item => item.groups)[0]
    this.setState(
      { selectedOrg: id, selectedOrgName: name, groups, emailsLoading: true },
      () => this.getOrganizationUsers()
    )
  }

  getOrganizationUsers = () => {
    const { selectedOrg } = this.state
    getUserList(selectedOrg).then(res => {
      const data = res.data.rows[0]
      const totalUsers = [
        ...(data && data.sign_up_users),
        ...(data && data.non_signup_users)
      ]
      this.setState(
        {
          emailsLoading: false,
          signupUsers: data.sign_up_users,
          nonSignupUsers: data.non_signup_users,
          activeUsers: data.active_users,
          filteredEmails: totalUsers.map(item => ({
            id: item.id,
            email: item.email
          })),
          totalUsers
        },
        () => {
          if (this.state.userType) {
            this.handleEmails()
          }
        }
      )
    })
  }

  handleClosePopup = type => {
    if (type === general.SAVE) {
      this.setState({
        image: null,
        isImageLoading: true
      })
    } else {
      this.setState({
        image: null,
        file: null,
        isImageLoading: false
      })
    }
  }

  send = () => {
    const {
      subject,
      message,
      selectedImage,
      selectedOrg,
      emails,
      selectedGroupId
    } = this.state

    var emailsToSendMsg
    if (emails === undefined || emails.length === 0) {
      emailsToSendMsg = this.state.filteredEmails.map(item => ({
        email: item.email
      }))
    } else {
      emailsToSendMsg = [...emails]
    }

    if (emailsToSendMsg.length === 0) {
      return Message.error(`No users of the selected type to send email.`)
    }

    const body = {
      subject,
      message,
      path: (selectedImage && selectedImage.path) || null,
      organization_id: selectedOrg,
      group_id: selectedGroupId || null,
      users: emailsToSendMsg,
      public: true
    }
    this.setState({ sendingEmail: true }, () => {
      sendEmailToUsers(body)
        .then(() => {
          this.setState({ sendingEmail: false })
          const { onSend } = this.props
          onSend()
          Message.success('Email successfully sent !')
        })
        .catch(err => {
          Message.error('Error occured. Try again !')
          this.setState({ sendingEmail: false })
        })
    })
  }

  handleEmailSelection = arr => {
    this.setState({ emails: arr.map(item => ({ email: item })) })
  }

  render() {
    const {
      selectedItem,
      modalOpen,
      selectedImage,
      isImageLoading,
      filteredOrgs,
      groups,
      selectedOrg,
      selectedOrgName,
      filteredEmails,
      image,
      file,
      sendingEmail,
      emailsLoading
    } = this.state
    const { t } = this.props
    return (
      <Page>
        <Title>{t('v3:send_email')}</Title>
        <Col>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <AutoComplete
                value={selectedOrgName}
                dataSource={filteredOrgs}
                placeholder="Organization"
                style={{ width: '100%' }}
                onSearch={this.onSearch}
                onChange={val => this.setState({ selectedOrgName: val })}
                onSelect={this.handleOrganizationChange}
              />
            </Col>
            <Col span={12}>
              <Select
                placeholder="Group"
                disabled={!selectedOrg}
                onChange={this.handleGroupSelect}
              >
                {groups &&
                  groups.map(group => (
                    <Option key={group.id} value={group.id}>
                      {group.title}
                    </Option>
                  ))}
              </Select>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Select defaultValue="all" onChange={this.handleUserTypeChange}>
                <Option value="all">{t('media:all')}</Option>
                <Option value="activeUsers">{t('v3:active_users')}</Option>
                <Option value="signupUsers">{t('v3:sign_up_user')}</Option>
                <Option value="nonSignupUsers">
                  {t('v3:non_sign_up_user')}
                </Option>
              </Select>
            </Col>
            <Col span={12}>
              <Select
                showSearch
                mode="multiple"
                placeholder="Email"
                disabled={!filteredEmails || !selectedOrg}
                ref={select => (this.emailSelect = select)}
                onChange={val => {
                  this.handleEmailSelection(val)
                  this.emailSelect.blur()
                }}
                loading={emailsLoading}
              >
                {filteredEmails &&
                  filteredEmails.map((item, index) => (
                    <Option key={index} value={item.email}>
                      {item.email}
                    </Option>
                  ))}
              </Select>
            </Col>
          </Row>
          <ROW>
            <Input
              placeholder="Subject (required)"
              onChange={e => this.setState({ subject: e.target.value })}
            />
          </ROW>
          <ROW>
            {' '}
            <Input.TextArea
              rows="4"
              autoSize={false}
              placeholder="Message (required)"
              onChange={e => this.setState({ message: e.target.value })}
            />{' '}
          </ROW>
          <ROW>
            <Dropzone
              app={this}
              onDrop={this.onDrop}
              disabled={isImageLoading}
              loadImage={isImageLoading}
              error={selectedImage ? 0 : 1}
              cover={selectedImage && selectedImage.link}
            />
          </ROW>
        </Col>
        <ROW>
          <Button
            type="primary"
            shape="round"
            onClick={this.send}
            style={{ margin: '1rem auto' }}
            loading={sendingEmail}
            disabled={
              !this.state.message ||
              !this.state.subject ||
              !selectedOrg ||
              !selectedImage
            }
          >
            Send
          </Button>
        </ROW>
        {modalOpen && (
          <Modal
            width={'80%'}
            footer={null}
            visible={modalOpen}
            onCancel={this.hideModal}
          >
            <div className="wrap_modal_tabs">
              <PopupMedia
                types="images"
                isSendMail
                isPageBuilder
                handleInsertData={this.handleInsertData}
              />
            </div>
            {selectedItem && (
              <Modal
                width="70%"
                footer={null}
                visible={selectedItem}
                onCancel={this.hidePreviewModal}
              >
                <Editor library={selectedItem} ref={this.imageRef} />

                <div className="wrap_btn_actions">
                  <Button
                    size="large"
                    type="danger"
                    className="action_button"
                    onClick={this.hidePreviewModal}
                  >
                    {general.CANCEL}
                  </Button>

                  <Button
                    size="large"
                    type="primary"
                    className="action_button"
                    onClick={() => this.handleFileInsert(selectedItem)}
                  >
                    {general.INSERT}
                  </Button>
                </div>
              </Modal>
            )}
          </Modal>
        )}
        {image && (
          <ImageCropContainer
            circle
            image={image}
            handleUploadImage={this.uploadImage}
            handleClosePopup={this.handleClosePopup}
            file={file}
            t={t}
          />
        )}
      </Page>
    )
  }
}
