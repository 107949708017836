import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import actions from '../../../store/actions'

import Scorm from './View'

const { getCourseById, getScormByCourseId, getLessonById, enrollCourse, unenrollCourse, viewCourse, resetCourseById, initCloneSocket } = actions.courses
const { clearNotes } = actions.notes
const { getDiscussionLog, clearDiscussion } = actions.discussion

const mapStateToProps = state => ({
  courses: state.courses,
  course: state.courses.courseById,
  scorm: state.courses.scormById,
  isCourseEnrollment: state.courses.isCourseEnrollment,
  user: state.user,
  topics: state.topics,
  log: state.discussion.log
})

const mapDispatchToProps = dispatch => bindActionCreators({
  getCourseById,
  getScormByCourseId,
  getLessonById,
  resetCourseById,
  enrollCourse,
  unenrollCourse,
  viewCourse,
  initCloneSocket,
  clearNotes,
  getDiscussionLog,
  clearDiscussion
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Scorm)
