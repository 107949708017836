/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-08-22 10:35:38
 * @LastEditTime: 2019-08-22 10:35:38
 * @LastEditors: your name
 */
import nanoid from 'nanoid'
import { builder } from '../../../../../constants'

const { types } = builder

const templates = () => {
  return {
    template1: {
      size: {
        width: 1024,
        height: 768
      },
      orientation: 'landscape',
      data: []
    },
    template2: {
      size: {
        width: 1024,
        height: 768
      },
      orientation: 'landscape',
      data: [
        {
          type: types.TEXT,
          uid: nanoid(10),
          params: {
            content: '',
            x: 0,
            y: 0,
            width: 1024,
            height: 150
          }
        },
        {
          type: types.TEXT,
          uid: nanoid(10),
          params: {
            content: '',
            x: 0,
            y: 180,
            width: 1024,
            height: 150
          }
        }
      ]
    },
    template3: {
      size: {
        width: 1024,
        height: 768
      },
      orientation: 'landscape',
      data: [
        {
          type: types.TEXT,
          uid: nanoid(10),
          params: {
            content: '',
            x: 0,
            y: 0,
            width: 1024,
            height: 150
          }
        },
        {
          type: types.TEXT,
          uid: nanoid(10),
          params: {
            content: '',
            x: 0,
            y: 180,
            width: 1024,
            height: 300
          }
        }
      ]
    },
    template4: {
      size: {
        width: 1024,
        height: 768
      },
      orientation: 'landscape',
      data: [
        {
          type: types.TEXT,
          uid: nanoid(10),
          params: {
            content: '',
            x: 0,
            y: 0,
            width: 1024,
            height: 150
          }
        },
        {
          type: types.IMAGE,
          uid: nanoid(10),
          params: {
            content: '',
            x: 0,
            y: 180,
            width: 1024,
            height: 200
          }
        },
        {
          type: types.TEXT,
          uid: nanoid(10),
          params: {
            content: '',
            x: 0,
            y: 410,
            width: 1024,
            height: 200
          }
        }
      ]
    },
    template5: {
      size: {
        width: 1024,
        height: 768
      },
      orientation: 'landscape',
      data: [
        {
          type: types.TEXT,
          uid: nanoid(10),
          params: {
            content: '',
            x: 0,
            y: 0,
            width: 1024,
            height: 150
          }
        },
        {
          type: types.IMAGE,
          uid: nanoid(10),
          params: {
            content: '',
            x: 0,
            y: 180,
            width: 1024,
            height: 200
          }
        }
      ]
    },
    template6: {
      size: {
        width: 1024,
        height: 768
      },
      orientation: 'landscape',
      data: [
        {
          type: types.TEXT,
          uid: nanoid(10),
          params: {
            content: '',
            x: 0,
            y: 0,
            width: 1024,
            height: 150
          }
        },
        {
          type: types.QUIZ,
          uid: nanoid(10),
          params: {
            content: '',
            x: 0,
            y: 180,
            width: 400,
            height: 200
          }
        },
        {
          type: types.TEXT,
          uid: nanoid(10),
          params: {
            content: '',
            x: 0,
            y: 410,
            width: 1024,
            height: 150
          }
        }
      ]
    }
  }
}

export default templates
