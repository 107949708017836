import Annotation from './annotation.svg'
import ArImage from './ar.svg'
import Audio from './audio.svg'
import AutoBackground from './auto-background.svg'
import Background from './background.svg'
import Grid from './grid.svg'
import Quiz from './quiz.svg'
import Text from './text.svg'
import Video from './video.svg'
import Image from './image.svg'

export default {
  Annotation,
  ArImage,
  Audio,
  AutoBackground,
  Background,
  Grid,
  Quiz,
  Text,
  Video,
  Image
}
