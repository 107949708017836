/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-07-08 11:28:55
 * @LastEditTime: 2019-08-29 20:20:01
 * @LastEditors: Please set LastEditors
 */
import {
  GET_COURSE_BY_ID,
  FETCH_COURSE_BY_ID,
  LOAD_SCORM_COURSE_BY_ID,
  GET_SCORM_COURSE_BY_ID,
  LOAD_COURSES,
  REMOVE_COURSE_FROM_LIST,
  GET_LESSON_DATA_BY_ID,
  FETCH_LESSON_DATA_BY_ID,
  RESET_COURSE_BY_ID,
  USER_COURSES_SUCCESS,
  USER_COURSES_FETCHING,
  ENROLL_COURSE_SUCCESS,
  ENROLL_COURSE_FETCHING,
  UNENROLL_COURSE_SUCCESS,
  UNENROLL_COURSE_FETCHING,
  DASHBOARD_COURSES_FETCHING,
  DASHBOARD_COURSES_SUCCESS,
  ENROLLED_COURSES_SUCCESS,
  ENROLLED_COURSES_FETCHING,
  TRAINING_COURSES_FETCHING,
  TRAINING_COURSES_SUCCESS,
  TRAINING_COURSES_HAS_MORE,
  WORKSHOP_COURSES_SUCCESS,
  WORKSHOP_COURSES_FETCHING,
  WORKSHOP_COURSES_HAS_MORE,
  COURSE_CLOINING_PROGRESS,
  SHOW_POP_OVER
} from '../types'

const defaultState = {
  list: [],
  fetch: false,
  hasMoreItems: true,
  courseById: {
    item: {},
    isFetching: false
  },
  scormById: {
    item: {},
    isFetching: false
  },
  trainingCourses: {
    list: [],
    fetch: false,
    hasMore: true
  },
  enrolledCourses: {
    list: [],
    fetch: false
  },
  dashboardCourses: {
    list: [],
    fetch: false
  },
  workshopCourses: {
    list: [],
    fetch: false,
    hasMore: true
  },
  userCourses: {
    list: [],
    fetch: false,
    hasMore: true
  },
  lessonById: {
    item: {},
    fetch: false
  },
  isCourseEnrollment: false,
  courseCloningProgress: null,
  isShowPopover: false,
  courseCloneTitle: '',
  courseCloneId: ''
}

const courses = (state = defaultState, action) => {
  switch (action.type) {
    case LOAD_COURSES:
      return {
        ...state,
        list: action.list,
        fetch: action.fetch,
        hasMoreItems: action.hasMoreItems
      }
    case GET_COURSE_BY_ID:
      return {
        ...state,
        courseById: {
          ...state.courseById,
          item: action.payload
        }
      }
    case RESET_COURSE_BY_ID:
      return {
        ...state,
        courseById: defaultState.courseById
      }
    case FETCH_COURSE_BY_ID:
      return {
        ...state,
        courseById: {
          ...state.courseById,
          isFetching: action.payload
        }
      }
    case LOAD_SCORM_COURSE_BY_ID:
      return {
        ...state,
        scormById: {
          ...state.scormById,
          isFetching: action.payload
        }
      }
    case GET_SCORM_COURSE_BY_ID:
      return {
        ...state,
        scormById: {
          ...state.scormById,
          isFetching: action.payload
        }
      }
    case REMOVE_COURSE_FROM_LIST:
      return {
        ...state,
        workshopCourses: {
          ...state.workshopCourses,
          list: state.workshopCourses.list.filter(i => i.id !== action.id)
        }
      }

    case FETCH_LESSON_DATA_BY_ID:
      return {
        ...state,
        lessonById: {
          ...state.lessonById,
          fetch: action.payload
        }
      }

    case GET_LESSON_DATA_BY_ID:
      return {
        ...state,
        lessonById: {
          ...state.lessonById,
          item: action.payload
        }
      }

    case TRAINING_COURSES_SUCCESS:
      return {
        ...state,
        trainingCourses: {
          ...state.trainingCourses,
          list: action.payload
        }
      }
    case TRAINING_COURSES_FETCHING:
      return {
        ...state,
        trainingCourses: {
          ...state.trainingCourses,
          fetch: action.payload
        }
      }
    case TRAINING_COURSES_HAS_MORE:
      return {
        ...state,
        trainingCourses: {
          ...state.trainingCourses,
          hasMore: action.payload
        }
      }
    case ENROLLED_COURSES_SUCCESS:
      return {
        ...state,
        enrolledCourses: {
          ...state.enrolledCourses,
          list: action.payload
        }
      }
    case ENROLLED_COURSES_FETCHING:
      return {
        ...state,
        enrolledCourses: {
          ...state.enrolledCourses,
          fetch: action.payload
        }
      }

    case DASHBOARD_COURSES_SUCCESS:
      return {
        ...state,
        dashboardCourses: {
          ...state.dashboardCourses,
          list: action.payload
        }
      }
    case DASHBOARD_COURSES_FETCHING:
      return {
        ...state,
        dashboardCourses: {
          ...state.dashboardCourses,
          fetch: action.payload
        }
      }

    case USER_COURSES_SUCCESS:
      return {
        ...state,
        userCourses: {
          ...state.userCourses,
          list: action.payload
        }
      }
    case USER_COURSES_FETCHING:
      return {
        ...state,
        userCourses: {
          ...state.userCourses,
          fetch: action.payload
        }
      }

    case WORKSHOP_COURSES_SUCCESS:
      return {
        ...state,
        workshopCourses: {
          ...state.workshopCourses,
          list: action.payload
        }
      }
    case WORKSHOP_COURSES_FETCHING:
      return {
        ...state,
        workshopCourses: {
          ...state.workshopCourses,
          fetch: action.payload
        }
      }
    case WORKSHOP_COURSES_HAS_MORE:
      return {
        ...state,
        workshopCourses: {
          ...state.workshopCourses,
          hasMore: action.payload
        }
      }

    case COURSE_CLOINING_PROGRESS:
      return {
        ...state,
        courseCloningProgress: action.payload
      }

    case SHOW_POP_OVER:
      return {
        ...state,
        isShowPopover: action.payload.isShow,
        courseCloneTitle: action.payload.courseTitle,
        courseCloneId: action.payload.courseId
      }

    case ENROLL_COURSE_SUCCESS:
      return {
        ...state,
        courseById: {
          ...state.courseById,
          item: {
            ...state.courseById.item,
            enrollment: {
              ...(state.courseById.item.enrollment || {}),
              progress: action.payload
            }
          }
        }
      }

    case UNENROLL_COURSE_SUCCESS:
      return {
        ...state,
        courseById: {
          ...state.courseById,
          item: {
            ...state.courseById.item,
            enrollment: null
          }
        }
      }

    case UNENROLL_COURSE_FETCHING:
    case ENROLL_COURSE_FETCHING:
      return {
        ...state,
        isCourseEnrollment: action.payload
      }
    default:
      return state
  }
}

export default courses
