import List from './List'
import Edit from './Edit'
import View from './View'
import ViewGraph from './ViewGraph'
import ListSection from './ListSection'

export default {
  List,
  Edit,
  View,
  ViewGraph,
  ListSection
}
